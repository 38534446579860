.flag {
  display: inline-block;
  background-repeat: no-repeat;
}

.flag.flag-24 {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url(../../static/media/sprite-flags-24x24.7ed12ca4.png);
  background-repeat: no-repeat;
}

.flag.flag-24.flag-abkhazia {
  background-position: -0px -0px;
}

.flag.flag-24.flag-basque-country {
  background-position: -24px -0px;
}

.flag.flag-24.flag-british-antarctic-territory {
  background-position: -48px -0px;
}

.flag.flag-24.flag-commonwealth {
  background-position: -72px -0px;
}

.flag.flag-24.flag-england {
  background-position: -96px -0px;
}

.flag.flag-24.flag-gosquared {
  background-position: -120px -0px;
}

.flag.flag-24.flag-kosovo {
  background-position: -144px -0px;
}

.flag.flag-24.flag-mars {
  background-position: -168px -0px;
}

.flag.flag-24.flag-nagorno-karabakh {
  background-position: -192px -0px;
}

.flag.flag-24.flag-nato {
  background-position: -216px -0px;
}

.flag.flag-24.flag-northern-cyprus {
  background-position: -240px -0px;
}

.flag.flag-24.flag-olympics {
  background-position: -264px -0px;
}

.flag.flag-24.flag-red-cross {
  background-position: -288px -0px;
}

.flag.flag-24.flag-scotland {
  background-position: -312px -0px;
}

.flag.flag-24.flag-somaliland {
  background-position: -336px -0px;
}

.flag.flag-24.flag-south-ossetia {
  background-position: -360px -0px;
}

.flag.flag-24.flag-united-nations {
  background-position: -384px -0px;
}

.flag.flag-24.flag-unknown {
  background-position: -0px -24px;
}

.flag.flag-24.flag-wales {
  background-position: -24px -24px;
}

.flag.flag-24.flag-ad {
  background-position: -48px -24px;
}

.flag.flag-24.flag-ae {
  background-position: -72px -24px;
}

.flag.flag-24.flag-af {
  background-position: -96px -24px;
}

.flag.flag-24.flag-ag {
  background-position: -120px -24px;
}

.flag.flag-24.flag-ai {
  background-position: -144px -24px;
}

.flag.flag-24.flag-al {
  background-position: -168px -24px;
}

.flag.flag-24.flag-am {
  background-position: -192px -24px;
}

.flag.flag-24.flag-an {
  background-position: -216px -24px;
}

.flag.flag-24.flag-ao {
  background-position: -240px -24px;
}

.flag.flag-24.flag-aq {
  background-position: -264px -24px;
}

.flag.flag-24.flag-ar {
  background-position: -288px -24px;
}

.flag.flag-24.flag-as {
  background-position: -312px -24px;
}

.flag.flag-24.flag-at {
  background-position: -336px -24px;
}

.flag.flag-24.flag-au {
  background-position: -360px -24px;
}

.flag.flag-24.flag-aw {
  background-position: -384px -24px;
}

.flag.flag-24.flag-ax {
  background-position: -0px -48px;
}

.flag.flag-24.flag-az {
  background-position: -24px -48px;
}

.flag.flag-24.flag-ba {
  background-position: -48px -48px;
}

.flag.flag-24.flag-bb {
  background-position: -72px -48px;
}

.flag.flag-24.flag-bd {
  background-position: -96px -48px;
}

.flag.flag-24.flag-be {
  background-position: -120px -48px;
}

.flag.flag-24.flag-bf {
  background-position: -144px -48px;
}

.flag.flag-24.flag-bg {
  background-position: -168px -48px;
}

.flag.flag-24.flag-bh {
  background-position: -192px -48px;
}

.flag.flag-24.flag-bi {
  background-position: -216px -48px;
}

.flag.flag-24.flag-bj {
  background-position: -240px -48px;
}

.flag.flag-24.flag-bl {
  background-position: -264px -48px;
}

.flag.flag-24.flag-bm {
  background-position: -288px -48px;
}

.flag.flag-24.flag-bn {
  background-position: -312px -48px;
}

.flag.flag-24.flag-bo {
  background-position: -336px -48px;
}

.flag.flag-24.flag-br {
  background-position: -360px -48px;
}

.flag.flag-24.flag-bs {
  background-position: -384px -48px;
}

.flag.flag-24.flag-bt {
  background-position: -0px -72px;
}

.flag.flag-24.flag-bw {
  background-position: -24px -72px;
}

.flag.flag-24.flag-by {
  background-position: -48px -72px;
}

.flag.flag-24.flag-bz {
  background-position: -72px -72px;
}

.flag.flag-24.flag-ca {
  background-position: -96px -72px;
}

.flag.flag-24.flag-cc {
  background-position: -120px -72px;
}

.flag.flag-24.flag-cd {
  background-position: -144px -72px;
}

.flag.flag-24.flag-cf {
  background-position: -168px -72px;
}

.flag.flag-24.flag-cg {
  background-position: -192px -72px;
}

.flag.flag-24.flag-ch {
  background-position: -216px -72px;
}

.flag.flag-24.flag-ci {
  background-position: -240px -72px;
}

.flag.flag-24.flag-ck {
  background-position: -264px -72px;
}

.flag.flag-24.flag-cl {
  background-position: -288px -72px;
}

.flag.flag-24.flag-cm {
  background-position: -312px -72px;
}

.flag.flag-24.flag-cn {
  background-position: -336px -72px;
}

.flag.flag-24.flag-co {
  background-position: -360px -72px;
}

.flag.flag-24.flag-cr {
  background-position: -384px -72px;
}

.flag.flag-24.flag-cu {
  background-position: -0px -96px;
}

.flag.flag-24.flag-cv {
  background-position: -24px -96px;
}

.flag.flag-24.flag-cw {
  background-position: -48px -96px;
}

.flag.flag-24.flag-cx {
  background-position: -72px -96px;
}

.flag.flag-24.flag-cy {
  background-position: -96px -96px;
}

.flag.flag-24.flag-cz {
  background-position: -120px -96px;
}

.flag.flag-24.flag-de {
  background-position: -144px -96px;
}

.flag.flag-24.flag-dj {
  background-position: -168px -96px;
}

.flag.flag-24.flag-dk {
  background-position: -192px -96px;
}

.flag.flag-24.flag-dm {
  background-position: -216px -96px;
}

.flag.flag-24.flag-do {
  background-position: -240px -96px;
}

.flag.flag-24.flag-dz {
  background-position: -264px -96px;
}

.flag.flag-24.flag-ec {
  background-position: -288px -96px;
}

.flag.flag-24.flag-ee {
  background-position: -312px -96px;
}

.flag.flag-24.flag-eg {
  background-position: -336px -96px;
}

.flag.flag-24.flag-eh {
  background-position: -360px -96px;
}

.flag.flag-24.flag-er {
  background-position: -384px -96px;
}

.flag.flag-24.flag-es {
  background-position: -0px -120px;
}

.flag.flag-24.flag-et {
  background-position: -24px -120px;
}

.flag.flag-24.flag-eu {
  background-position: -48px -120px;
}

.flag.flag-24.flag-fi {
  background-position: -72px -120px;
}

.flag.flag-24.flag-fj {
  background-position: -96px -120px;
}

.flag.flag-24.flag-fk {
  background-position: -120px -120px;
}

.flag.flag-24.flag-fm {
  background-position: -144px -120px;
}

.flag.flag-24.flag-fo {
  background-position: -168px -120px;
}

.flag.flag-24.flag-fr {
  background-position: -192px -120px;
}

.flag.flag-24.flag-ga {
  background-position: -216px -120px;
}

.flag.flag-24.flag-gb {
  background-position: -240px -120px;
}

.flag.flag-24.flag-gd {
  background-position: -264px -120px;
}

.flag.flag-24.flag-ge {
  background-position: -288px -120px;
}

.flag.flag-24.flag-gg {
  background-position: -312px -120px;
}

.flag.flag-24.flag-gh {
  background-position: -336px -120px;
}

.flag.flag-24.flag-gi {
  background-position: -360px -120px;
}

.flag.flag-24.flag-gl {
  background-position: -384px -120px;
}

.flag.flag-24.flag-gm {
  background-position: -0px -144px;
}

.flag.flag-24.flag-gn {
  background-position: -24px -144px;
}

.flag.flag-24.flag-gq {
  background-position: -48px -144px;
}

.flag.flag-24.flag-gr {
  background-position: -72px -144px;
}

.flag.flag-24.flag-gs {
  background-position: -96px -144px;
}

.flag.flag-24.flag-gt {
  background-position: -120px -144px;
}

.flag.flag-24.flag-gu {
  background-position: -144px -144px;
}

.flag.flag-24.flag-gw {
  background-position: -168px -144px;
}

.flag.flag-24.flag-gy {
  background-position: -192px -144px;
}

.flag.flag-24.flag-hk {
  background-position: -216px -144px;
}

.flag.flag-24.flag-hn {
  background-position: -240px -144px;
}

.flag.flag-24.flag-hr {
  background-position: -264px -144px;
}

.flag.flag-24.flag-ht {
  background-position: -288px -144px;
}

.flag.flag-24.flag-hu {
  background-position: -312px -144px;
}

.flag.flag-24.flag-ic {
  background-position: -336px -144px;
}

.flag.flag-24.flag-id {
  background-position: -360px -144px;
}

.flag.flag-24.flag-ie {
  background-position: -384px -144px;
}

.flag.flag-24.flag-il {
  background-position: -0px -168px;
}

.flag.flag-24.flag-im {
  background-position: -24px -168px;
}

.flag.flag-24.flag-in {
  background-position: -48px -168px;
}

.flag.flag-24.flag-iq {
  background-position: -72px -168px;
}

.flag.flag-24.flag-ir {
  background-position: -96px -168px;
}

.flag.flag-24.flag-is {
  background-position: -120px -168px;
}

.flag.flag-24.flag-it {
  background-position: -144px -168px;
}

.flag.flag-24.flag-je {
  background-position: -168px -168px;
}

.flag.flag-24.flag-jm {
  background-position: -192px -168px;
}

.flag.flag-24.flag-jo {
  background-position: -216px -168px;
}

.flag.flag-24.flag-jp {
  background-position: -240px -168px;
}

.flag.flag-24.flag-ke {
  background-position: -264px -168px;
}

.flag.flag-24.flag-kg {
  background-position: -288px -168px;
}

.flag.flag-24.flag-kh {
  background-position: -312px -168px;
}

.flag.flag-24.flag-ki {
  background-position: -336px -168px;
}

.flag.flag-24.flag-km {
  background-position: -360px -168px;
}

.flag.flag-24.flag-kn {
  background-position: -384px -168px;
}

.flag.flag-24.flag-kp {
  background-position: -0px -192px;
}

.flag.flag-24.flag-kr {
  background-position: -24px -192px;
}

.flag.flag-24.flag-kw {
  background-position: -48px -192px;
}

.flag.flag-24.flag-ky {
  background-position: -72px -192px;
}

.flag.flag-24.flag-kz {
  background-position: -96px -192px;
}

.flag.flag-24.flag-la {
  background-position: -120px -192px;
}

.flag.flag-24.flag-lb {
  background-position: -144px -192px;
}

.flag.flag-24.flag-lc {
  background-position: -168px -192px;
}

.flag.flag-24.flag-li {
  background-position: -192px -192px;
}

.flag.flag-24.flag-lk {
  background-position: -216px -192px;
}

.flag.flag-24.flag-lr {
  background-position: -240px -192px;
}

.flag.flag-24.flag-ls {
  background-position: -264px -192px;
}

.flag.flag-24.flag-lt {
  background-position: -288px -192px;
}

.flag.flag-24.flag-lu {
  background-position: -312px -192px;
}

.flag.flag-24.flag-lv {
  background-position: -336px -192px;
}

.flag.flag-24.flag-ly {
  background-position: -360px -192px;
}

.flag.flag-24.flag-ma {
  background-position: -384px -192px;
}

.flag.flag-24.flag-mc {
  background-position: -0px -216px;
}

.flag.flag-24.flag-md {
  background-position: -24px -216px;
}

.flag.flag-24.flag-me {
  background-position: -48px -216px;
}

.flag.flag-24.flag-mf {
  background-position: -72px -216px;
}

.flag.flag-24.flag-mg {
  background-position: -96px -216px;
}

.flag.flag-24.flag-mh {
  background-position: -120px -216px;
}

.flag.flag-24.flag-mk {
  background-position: -144px -216px;
}

.flag.flag-24.flag-ml {
  background-position: -168px -216px;
}

.flag.flag-24.flag-mm {
  background-position: -192px -216px;
}

.flag.flag-24.flag-mn {
  background-position: -216px -216px;
}

.flag.flag-24.flag-mo {
  background-position: -240px -216px;
}

.flag.flag-24.flag-mp {
  background-position: -264px -216px;
}

.flag.flag-24.flag-mq {
  background-position: -288px -216px;
}

.flag.flag-24.flag-mr {
  background-position: -312px -216px;
}

.flag.flag-24.flag-ms {
  background-position: -336px -216px;
}

.flag.flag-24.flag-mt {
  background-position: -360px -216px;
}

.flag.flag-24.flag-mu {
  background-position: -384px -216px;
}

.flag.flag-24.flag-mv {
  background-position: -0px -240px;
}

.flag.flag-24.flag-mw {
  background-position: -24px -240px;
}

.flag.flag-24.flag-mx {
  background-position: -48px -240px;
}

.flag.flag-24.flag-my {
  background-position: -72px -240px;
}

.flag.flag-24.flag-mz {
  background-position: -96px -240px;
}

.flag.flag-24.flag-na {
  background-position: -120px -240px;
}

.flag.flag-24.flag-nc {
  background-position: -144px -240px;
}

.flag.flag-24.flag-ne {
  background-position: -168px -240px;
}

.flag.flag-24.flag-nf {
  background-position: -192px -240px;
}

.flag.flag-24.flag-ng {
  background-position: -216px -240px;
}

.flag.flag-24.flag-ni {
  background-position: -240px -240px;
}

.flag.flag-24.flag-nl {
  background-position: -264px -240px;
}

.flag.flag-24.flag-no {
  background-position: -288px -240px;
}

.flag.flag-24.flag-np {
  background-position: -312px -240px;
}

.flag.flag-24.flag-nr {
  background-position: -336px -240px;
}

.flag.flag-24.flag-nu {
  background-position: -360px -240px;
}

.flag.flag-24.flag-nz {
  background-position: -384px -240px;
}

.flag.flag-24.flag-om {
  background-position: -0px -264px;
}

.flag.flag-24.flag-pa {
  background-position: -24px -264px;
}

.flag.flag-24.flag-pe {
  background-position: -48px -264px;
}

.flag.flag-24.flag-pf {
  background-position: -72px -264px;
}

.flag.flag-24.flag-pg {
  background-position: -96px -264px;
}

.flag.flag-24.flag-ph {
  background-position: -120px -264px;
}

.flag.flag-24.flag-pirate-black {
  background-position: -144px -264px;
}

.flag.flag-24.flag-pirate-white {
  background-position: -168px -264px;
}

.flag.flag-24.flag-pk {
  background-position: -192px -264px;
}

.flag.flag-24.flag-pl {
  background-position: -216px -264px;
}

.flag.flag-24.flag-pn {
  background-position: -240px -264px;
}

.flag.flag-24.flag-pr {
  background-position: -264px -264px;
}

.flag.flag-24.flag-ps {
  background-position: -288px -264px;
}

.flag.flag-24.flag-pt {
  background-position: -312px -264px;
}

.flag.flag-24.flag-pw {
  background-position: -336px -264px;
}

.flag.flag-24.flag-py {
  background-position: -360px -264px;
}

.flag.flag-24.flag-qa {
  background-position: -384px -264px;
}

.flag.flag-24.flag-ro {
  background-position: -0px -288px;
}

.flag.flag-24.flag-rs {
  background-position: -24px -288px;
}

.flag.flag-24.flag-ru {
  background-position: -48px -288px;
}

.flag.flag-24.flag-rw {
  background-position: -72px -288px;
}

.flag.flag-24.flag-sa {
  background-position: -96px -288px;
}

.flag.flag-24.flag-sb {
  background-position: -120px -288px;
}

.flag.flag-24.flag-sc {
  background-position: -144px -288px;
}

.flag.flag-24.flag-sd {
  background-position: -168px -288px;
}

.flag.flag-24.flag-se {
  background-position: -192px -288px;
}

.flag.flag-24.flag-sg {
  background-position: -216px -288px;
}

.flag.flag-24.flag-sh {
  background-position: -240px -288px;
}

.flag.flag-24.flag-si {
  background-position: -264px -288px;
}

.flag.flag-24.flag-sk {
  background-position: -288px -288px;
}

.flag.flag-24.flag-sl {
  background-position: -312px -288px;
}

.flag.flag-24.flag-sm {
  background-position: -336px -288px;
}

.flag.flag-24.flag-sn {
  background-position: -360px -288px;
}

.flag.flag-24.flag-so {
  background-position: -384px -288px;
}

.flag.flag-24.flag-sr {
  background-position: -0px -312px;
}

.flag.flag-24.flag-ss {
  background-position: -24px -312px;
}

.flag.flag-24.flag-st {
  background-position: -48px -312px;
}

.flag.flag-24.flag-sv {
  background-position: -72px -312px;
}

.flag.flag-24.flag-sy {
  background-position: -96px -312px;
}

.flag.flag-24.flag-sz {
  background-position: -120px -312px;
}

.flag.flag-24.flag-tc {
  background-position: -144px -312px;
}

.flag.flag-24.flag-td {
  background-position: -168px -312px;
}

.flag.flag-24.flag-tf {
  background-position: -192px -312px;
}

.flag.flag-24.flag-tg {
  background-position: -216px -312px;
}

.flag.flag-24.flag-th {
  background-position: -240px -312px;
}

.flag.flag-24.flag-tj {
  background-position: -264px -312px;
}

.flag.flag-24.flag-tk {
  background-position: -288px -312px;
}

.flag.flag-24.flag-tl {
  background-position: -312px -312px;
}

.flag.flag-24.flag-tm {
  background-position: -336px -312px;
}

.flag.flag-24.flag-tn {
  background-position: -360px -312px;
}

.flag.flag-24.flag-to {
  background-position: -384px -312px;
}

.flag.flag-24.flag-tr {
  background-position: -0px -336px;
}

.flag.flag-24.flag-tt {
  background-position: -24px -336px;
}

.flag.flag-24.flag-tv {
  background-position: -48px -336px;
}

.flag.flag-24.flag-tw {
  background-position: -72px -336px;
}

.flag.flag-24.flag-tz {
  background-position: -96px -336px;
}

.flag.flag-24.flag-ua {
  background-position: -120px -336px;
}

.flag.flag-24.flag-ug {
  background-position: -144px -336px;
}

.flag.flag-24.flag-us {
  background-position: -168px -336px;
}

.flag.flag-24.flag-uy {
  background-position: -192px -336px;
}

.flag.flag-24.flag-uz {
  background-position: -216px -336px;
}

.flag.flag-24.flag-va {
  background-position: -240px -336px;
}

.flag.flag-24.flag-vc {
  background-position: -264px -336px;
}

.flag.flag-24.flag-ve {
  background-position: -288px -336px;
}

.flag.flag-24.flag-vg {
  background-position: -312px -336px;
}

.flag.flag-24.flag-vi {
  background-position: -336px -336px;
}

.flag.flag-24.flag-vn {
  background-position: -360px -336px;
}

.flag.flag-24.flag-vu {
  background-position: -384px -336px;
}

@charset "UTF-8";

@font-face {
  font-family: "gaxon";
  src: url(../../static/media/gaxon.32cebc42.eot);
  src: url(../../static/media/gaxon.32cebc42.eot?#iefix) format("embedded-opentype"),
  url(../../static/media/gaxon.36251c7b.woff) format("woff"),
  url(../../static/media/gaxon.00fcca8e.ttf) format("truetype"),
  url(../../static/media/gaxon.b1a63de8.svg#gaxon) format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "gaxon" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "gaxon" !important;
  font-style: normal !important;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal !important;
          font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "a";
}

.icon-add-circle:before {
  content: "b";
}

.icon-affix:before {
  content: "c";
}

.icon-alert:before {
  content: "d";
}

.icon-alert-new:before {
  content: "e";
}

.icon-all-contacts:before {
  content: "f";
}

.icon-amchart:before {
  content: "g";
}

.icon-anchor:before {
  content: "h";
}

.icon-apps:before {
  content: "i";
}

.icon-apps-new:before {
  content: "j";
}

.icon-arrow-left:before {
  content: "k";
}

.icon-arrow-right:before {
  content: "l";
}

.icon-attachment:before {
  content: "m";
}

.icon-auth-screen:before {
  content: "n";
}

.icon-autocomplete:before {
  content: "o";
}

.icon-avatar:before {
  content: "p";
}

.icon-backtop:before {
  content: "q";
}

.icon-badge:before {
  content: "r";
}

.icon-basic-calendar:before {
  content: "s";
}

.icon-birthday:before {
  content: "t";
}

.icon-birthday-new:before {
  content: "u";
}

.icon-bitcoin:before {
  content: "v";
}

.icon-breadcrumb:before {
  content: "w";
}

.icon-burger:before {
  content: "x";
}

.icon-button:before {
  content: "y";
}

.icon-calendar:before {
  content: "z";
}

.icon-calendar-new:before {
  content: "A";
}

.icon-callout:before {
  content: "B";
}

.icon-camera:before {
  content: "C";
}

.icon-camera-2:before {
  content: "D";
}

.icon-card:before {
  content: "E";
}

.icon-cards-list-view:before {
  content: "F";
}

.icon-carousel:before {
  content: "G";
}

.icon-cascader:before {
  content: "H";
}

.icon-chart:before {
  content: "I";
}

.icon-chart-area:before {
  content: "J";
}

.icon-chart-area-new:before {
  content: "K";
}

.icon-chart-bar:before {
  content: "L";
}

.icon-chart-composed:before {
  content: "M";
}

.icon-chart-line:before {
  content: "N";
}

.icon-chart-pie:before {
  content: "O";
}

.icon-chart-radar:before {
  content: "P";
}

.icon-chart-radial:before {
  content: "Q";
}

.icon-chart-scatter:before {
  content: "R";
}

.icon-chart-tree:before {
  content: "S";
}

.icon-charvlet-down:before {
  content: "T";
}

.icon-charvlet-left:before {
  content: "U";
}

.icon-charvlet-right:before {
  content: "V";
}

.icon-charvlet-up:before {
  content: "W";
}

.icon-chat:before {
  content: "X";
}

.icon-chat-bubble:before {
  content: "Y";
}

.icon-chat-new:before {
  content: "Z";
}

.icon-check:before {
  content: "0";
}

.icon-check-circle-o:before {
  content: "1";
}

.icon-check-cricle:before {
  content: "2";
}

.icon-check-square:before {
  content: "3";
}

.icon-check-square-o:before {
  content: "4";
}

.icon-chevron-down:before {
  content: "5";
}

.icon-chevron-left:before {
  content: "6";
}

.icon-chevron-right:before {
  content: "7";
}

.icon-chevron-up:before {
  content: "8";
}

.icon-circle:before {
  content: "9";
}

.icon-circle-o:before {
  content: "!";
}

.icon-ckeditor:before {
  content: "\"";
}

.icon-close:before {
  content: "#";
}

.icon-close-circle:before {
  content: "$";
}

.icon-collapse:before {
  content: "%";
}

.icon-company:before {
  content: "&";
}

.icon-components:before {
  content: "'";
}

.icon-compose:before {
  content: "(";
}

.icon-contacts:before {
  content: ")";
}

.icon-copy:before {
  content: "*";
}

.icon-crm:before {
  content: "+";
}

.icon-crypto:before {
  content: ",";
}

.icon-culture-calendar:before {
  content: "-";
}

.icon-custom-view:before {
  content: ".";
}

.icon-dasbhoard:before {
  content: "/";
}

.icon-data-display:before {
  content: ":";
}

.icon-data-entry:before {
  content: ";";
}

.icon-datepicker:before {
  content: "<";
}

.icon-default-timeline:before {
  content: "=";
}

.icon-diamond:before {
  content: ">";
}

.icon-divider:before {
  content: "?";
}

.icon-donut:before {
  content: "@";
}

.icon-down:before {
  content: "[";
}

.icon-draft:before {
  content: "]";
}

.icon-drag-and-drop:before {
  content: "^";
}

.icon-dropdown:before {
  content: "_";
}

.icon-edit:before {
  content: "`";
}

.icon-editor:before {
  content: "{";
}

.icon-ellipse-h:before {
  content: "|";
}

.icon-ellipse-v:before {
  content: "}";
}

.icon-email:before {
  content: "~";
}

.icon-error:before {
  content: "\\";
}

.icon-error-404:before {
  content: "\E000";
}

.icon-error-500:before {
  content: "\E001";
}

.icon-etherium:before {
  content: "\E002";
}

.icon-exclamation:before {
  content: "\E003";
}

.icon-expand:before {
  content: "\E004";
}

.icon-extensions:before {
  content: "\E005";
}

.icon-extra-components:before {
  content: "\E006";
}

.icon-eye:before {
  content: "\E007";
}

.icon-facebook:before {
  content: "\E008";
}

.icon-family:before {
  content: "\E009";
}

.icon-feedback:before {
  content: "\E00A";
}

.icon-files:before {
  content: "\E00B";
}

.icon-filter:before {
  content: "\E00C";
}

.icon-filter-circle:before {
  content: "\E00D";
}

.icon-folder:before {
  content: "\E00E";
}

.icon-folder-o:before {
  content: "\E00F";
}

.icon-font:before {
  content: "\E010";
}

.icon-forgot-password:before {
  content: "\E011";
}

.icon-forward:before {
  content: "\E012";
}

.icon-forward-o:before {
  content: "\E013";
}

.icon-frequent:before {
  content: "\E014";
}

.icon-geo-location:before {
  content: "\E015";
}

.icon-graduation:before {
  content: "\E016";
}

.icon-growth:before {
  content: "\E017";
}

.icon-home:before {
  content: "\E018";
}

.icon-hotel-booking:before {
  content: "\E019";
}

.icon-icon:before {
  content: "\E01A";
}

.icon-image:before {
  content: "\E01B";
}

.icon-important:before {
  content: "\E01C";
}

.icon-important-o:before {
  content: "\E01D";
}

.icon-inbox:before {
  content: "\E01E";
}

.icon-inbuilt-apps:before {
  content: "\E01F";
}

.icon-input:before {
  content: "\E020";
}

.icon-inputnumber:before {
  content: "\E021";
}

.icon-invert-color:before {
  content: "\E022";
}

.icon-keyboard:before {
  content: "\E023";
}

.icon-like:before {
  content: "\E024";
}

.icon-like-o:before {
  content: "\E025";
}

.icon-link:before {
  content: "\E026";
}

.icon-lising-dbrd:before {
  content: "\E027";
}

.icon-list-select-o:before {
  content: "\E028";
}

.icon-listing-dbrd:before {
  content: "\E029";
}

.icon-litcoin:before {
  content: "\E02A";
}

.icon-localeprovider:before {
  content: "\E02B";
}

.icon-location:before {
  content: "\E02C";
}

.icon-lock-screen:before {
  content: "\E02D";
}

.icon-long-arrow:before {
  content: "\E02E";
}

.icon-long-arrow-down:before {
  content: "\E02F";
}

.icon-long-arrow-left:before {
  content: "\E030";
}

.icon-long-arrow-right:before {
  content: "\E031";
}

.icon-long-arrow-up:before {
  content: "\E032";
}

.icon-mail-open:before {
  content: "\E033";
}

.icon-map-clustering:before {
  content: "\E034";
}

.icon-map-directions:before {
  content: "\E035";
}

.icon-map-drawing:before {
  content: "\E036";
}

.icon-map-event-listener:before {
  content: "\E037";
}

.icon-map-google:before {
  content: "\E038";
}

.icon-map-km-layer:before {
  content: "\E039";
}

.icon-map-overlay:before {
  content: "\E03A";
}

.icon-map-popup-info:before {
  content: "\E03B";
}

.icon-map-selectable:before {
  content: "\E03C";
}

.icon-map-simple:before {
  content: "\E03D";
}

.icon-map-street-view:before {
  content: "\E03E";
}

.icon-map-styled:before {
  content: "\E03F";
}

.icon-map-traffic-layer:before {
  content: "\E040";
}

.icon-megaphone:before {
  content: "\E041";
}

.icon-mention:before {
  content: "\E042";
}

.icon-menu:before {
  content: "\E043";
}

.icon-menu-down:before {
  content: "\E044";
}

.icon-menu-fold:before {
  content: "\E045";
}

.icon-menu-left:before {
  content: "\E046";
}

.icon-menu-lines:before {
  content: "\E047";
}

.icon-menu-right:before {
  content: "\E048";
}

.icon-menu-select:before {
  content: "\E049";
}

.icon-menu-unfold:before {
  content: "\E04A";
}

.icon-menu-up:before {
  content: "\E04B";
}

.icon-message:before {
  content: "\E04C";
}

.icon-mic:before {
  content: "\E04D";
}

.icon-modal:before {
  content: "\E04E";
}

.icon-navigation:before {
  content: "\E04F";
}

.icon-noodles:before {
  content: "\E050";
}

.icon-notification:before {
  content: "\E051";
}

.icon-notification-new:before {
  content: "\E052";
}

.icon-orders:before {
  content: "\E053";
}

.icon-pagination:before {
  content: "\E054";
}

.icon-phone:before {
  content: "\E055";
}

.icon-picker:before {
  content: "\E056";
}

.icon-pizza:before {
  content: "\E057";
}

.icon-plain-list-divider:before {
  content: "\E058";
}

.icon-plain-list-view:before {
  content: "\E059";
}

.icon-popconfirm:before {
  content: "\E05A";
}

.icon-popover:before {
  content: "\E05B";
}

.icon-popup-calendar:before {
  content: "\E05C";
}

.icon-pricing-table:before {
  content: "\E05D";
}

.icon-product-grid:before {
  content: "\E05E";
}

.icon-product-list:before {
  content: "\E05F";
}

.icon-profile:before {
  content: "\E060";
}

.icon-profile2:before {
  content: "\E061";
}

.icon-progress:before {
  content: "\E062";
}

.icon-queries:before {
  content: "\E063";
}

.icon-question-circle:before {
  content: "\E064";
}

.icon-quote-backward:before {
  content: "\E065";
}

.icon-quote-forward:before {
  content: "\E066";
}

.icon-radiobutton:before {
  content: "\E067";
}

.icon-refer:before {
  content: "\E068";
}

.icon-rendaring-calendar:before {
  content: "\E069";
}

.icon-reply:before {
  content: "\E06A";
}

.icon-reply-o:before {
  content: "\E06B";
}

.icon-reset-password:before {
  content: "\E06C";
}

.icon-revenue-new:before {
  content: "\E06D";
}

.icon-ripple:before {
  content: "\E06E";
}

.icon-schedule:before {
  content: "\E06F";
}

.icon-search:before {
  content: "\E070";
}

.icon-search-new:before {
  content: "\E071";
}

.icon-select:before {
  content: "\E072";
}

.icon-selectable-map:before {
  content: "\E073";
}

.icon-sent:before {
  content: "\E074";
}

.icon-setting:before {
  content: "\E075";
}

.icon-shopping-cart:before {
  content: "\E076";
}

.icon-shuffle:before {
  content: "\E077";
}

.icon-signin:before {
  content: "\E078";
}

.icon-signup:before {
  content: "\E079";
}

.icon-slider:before {
  content: "\E07A";
}

.icon-social:before {
  content: "\E07B";
}

.icon-spam:before {
  content: "\E07C";
}

.icon-spin:before {
  content: "\E07D";
}

.icon-star:before {
  content: "\E07E";
}

.icon-star-half:before {
  content: "\E07F";
}

.icon-star-o:before {
  content: "\E080";
}

.icon-stats:before {
  content: "\E081";
}

.icon-steps:before {
  content: "\E082";
}

.icon-styled-map:before {
  content: "\E083";
}

.icon-sweet-alert:before {
  content: "\E084";
}

.icon-switch:before {
  content: "\E085";
}

.icon-tab:before {
  content: "\E086";
}

.icon-table:before {
  content: "\E087";
}

.icon-table-data:before {
  content: "\E088";
}

.icon-table-general:before {
  content: "\E089";
}

.icon-tag:before {
  content: "\E08A";
}

.icon-tag-new:before {
  content: "\E08B";
}

.icon-tag-o:before {
  content: "\E08C";
}

.icon-tasks:before {
  content: "\E08D";
}

.icon-team:before {
  content: "\E08E";
}

.icon-testimonial:before {
  content: "\E08F";
}

.icon-thumb-up:before {
  content: "\E090";
}

.icon-thumbs-down:before {
  content: "\E091";
}

.icon-ticket-new:before {
  content: "\E092";
}

.icon-tickets:before {
  content: "\E093";
}

.icon-timeline:before {
  content: "\E094";
}

.icon-timeline-left-align:before {
  content: "\E095";
}

.icon-timeline-new:before {
  content: "\E096";
}

.icon-timeline-with-icons:before {
  content: "\E097";
}

.icon-timepicker:before {
  content: "\E098";
}

.icon-tooltip:before {
  content: "\E099";
}

.icon-transfer:before {
  content: "\E09A";
}

.icon-translation:before {
  content: "\E09B";
}

.icon-trash:before {
  content: "\E09C";
}

.icon-tree:before {
  content: "\E09D";
}

.icon-treeselect:before {
  content: "\E09E";
}

.icon-uncheck-squire:before {
  content: "\E09F";
}

.icon-uncheck-squire-o:before {
  content: "\E0A0";
}

.icon-upload:before {
  content: "\E0A1";
}

.icon-user:before {
  content: "\E0A2";
}

.icon-user-o:before {
  content: "\E0A3";
}

.icon-view:before {
  content: "\E0A4";
}

.icon-view-o:before {
  content: "\E0A5";
}

.icon-visits:before {
  content: "\E0A6";
}

.icon-wall:before {
  content: "\E0A7";
}

.icon-widgets:before {
  content: "\E0A8";
}

.icon-wysiwyg:before {
  content: "\E0A9";
}

@font-face {
  font-family: 'NoirPro';
  src: url(../../static/media/NoirPro-Light.defbb591.eot?#iefix) format('embedded-opentype'),
  url(../../static/media/NoirPro-Light.bbd56c69.woff) format('woff'),
  url(../../static/media/NoirPro-Light.25188312.woff2) format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url(../../static/media/NoirPro-Regular.752c13e1.eot?#iefix) format('embedded-opentype'),
  url(../../static/media/NoirPro-Regular.83b98671.woff) format('woff'),
  url(../../static/media/NoirPro-Regular.fe706631.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url(../../static/media/NoirPro-Medium.6d74487d.eot?#iefix) format('embedded-opentype'),
  url(../../static/media/NoirPro-Medium.04b5d842.woff) format('woff'),
  url(../../static/media/NoirPro-Medium.3d82e791.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url(../../static/media/NoirPro-SemiBold.ac97525f.eot?#iefix) format('embedded-opentype'),
  url(../../static/media/NoirPro-SemiBold.1bc66a4b.woff) format('woff'),
  url(../../static/media/NoirPro-SemiBold.e928d2a2.woff2) format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url(../../static/media/NoirPro-Bold.d9565816.eot?#iefix) format('embedded-opentype'),
  url(../../static/media/NoirPro-Bold.9dbcc95d.woff) format('woff'),
  url(../../static/media/NoirPro-Bold.bb9d0cc6.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NoirPro';
  src: url(../../static/media/NoirPro-Heavy.7194b3b5.eot?#iefix) format('embedded-opentype'),
  url(../../static/media/NoirPro-Heavy.a302e546.woff) format('woff'),
  url(../../static/media/NoirPro-Heavy.873c8fa8.woff2) format('woff2');
  font-weight: 900;
  font-style: normal;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/*
Author         : G-axon
Template Name  : Wieldy Antd React - Admin Template
Version        : 1.0
*/
/*=============================================================
    Table of Contents:
    =============================
    A. Globals
        01. Custom Animation
        02. Colors Class

    B. Base
        01. Base Styles
        02. Row Col Styles
        03. Margin Padding Styles
        04. Typography Styles
        05. Flex Styles

    C. Layouts
        01. Ant Layout Style
        02. Header Style
        03. Sidebar Style
        04. Navbar(Ant Menu) Style
        05. Footer Styles
        06. Customizer Style
        07. Drawer Style

    D. UI Components
        01. Alert Styles
        02. Avatar Styles
        03. BackTop Styles
        04. Badges Styles
        05. Button Styles
        06. Cards Styles
        07. Cascader Styles
        08. Chart Styles
        09. Form Styles
        10. Icon Styles
        11. Input Styles
        12. List Styles
        13. Loader Styles
        14. Pagination Styles
        15. Pickers Styles
        16. Progress Styles
        17. Slider Styles
        18. Steps Styles
        19. Tables Styles
        20. Tabs Styles
        21. Time Lines Style

    E. Apps
        01. Apps Style
        02. Mails Apps Styles
        03. Chat Apps Styles
        04. Contact Apps Styles
        05. Calendar Apps Style
        06. Profile App Style
        07. Wall App Style

    F. Pages
        01. Callout Styles
        02. E-commerce Styles
        03. Pricing Tables Styles
        04. Login Styles
        05. Dashboard Styles
        06. Error Styles
        07. Editor Styles
        08. Testimonial Styles
        09. Algolia Styles

  =============================================================*/
/* stylelint-disable at-rule-no-unknown */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  color: #545454;
  font-size: 14px;
  font-family: 'NoirPro', sans-serif;
  font-variant: tabular-nums;
  line-height: 1.3;
  background-color: #f5f5f5;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: #535353;
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #038fde;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #28aaeb;
}
a:active {
  color: #006eb8;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: #595959;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::selection {
  color: #fff;
  background: #038fde;
}
.clearfix {
  zoom: 1;
}
.clearfix::before,
.clearfix::after {
  display: table;
  content: '';
}
.clearfix::after {
  clear: both;
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.fade-enter,
.fade-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.fade-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.fade-enter.fade-enter-active,
.fade-appear.fade-appear-active {
  -webkit-animation-name: antFadeIn;
          animation-name: antFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.fade-leave.fade-leave-active {
  -webkit-animation-name: antFadeOut;
          animation-name: antFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.fade-enter,
.fade-appear {
  opacity: 0;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.fade-leave {
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.move-up-enter,
.move-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-up-enter.move-up-enter-active,
.move-up-appear.move-up-appear-active {
  -webkit-animation-name: antMoveUpIn;
          animation-name: antMoveUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-up-leave.move-up-leave-active {
  -webkit-animation-name: antMoveUpOut;
          animation-name: antMoveUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-up-enter,
.move-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-down-enter,
.move-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-down-enter.move-down-enter-active,
.move-down-appear.move-down-appear-active {
  -webkit-animation-name: antMoveDownIn;
          animation-name: antMoveDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-down-leave.move-down-leave-active {
  -webkit-animation-name: antMoveDownOut;
          animation-name: antMoveDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-down-enter,
.move-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-left-enter,
.move-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-left-enter.move-left-enter-active,
.move-left-appear.move-left-appear-active {
  -webkit-animation-name: antMoveLeftIn;
          animation-name: antMoveLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-left-leave.move-left-leave-active {
  -webkit-animation-name: antMoveLeftOut;
          animation-name: antMoveLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-left-enter,
.move-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-right-enter,
.move-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.move-right-enter.move-right-enter-active,
.move-right-appear.move-right-appear-active {
  -webkit-animation-name: antMoveRightIn;
          animation-name: antMoveRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.move-right-leave.move-right-leave-active {
  -webkit-animation-name: antMoveRightOut;
          animation-name: antMoveRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.move-right-enter,
.move-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@-webkit-keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownIn {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveDownOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftIn {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightIn {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightOut {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpIn {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpOut {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #038fde;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #038fde;
  box-shadow: 0 0 0 0 #038fde;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
          animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@-webkit-keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #038fde;
    box-shadow: 0 0 0 6px #038fde;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #038fde;
    box-shadow: 0 0 0 6px #038fde;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@-webkit-keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.slide-up-enter,
.slide-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-up-enter.slide-up-enter-active,
.slide-up-appear.slide-up-appear-active {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-up-leave.slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-up-enter,
.slide-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-down-enter,
.slide-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-down-enter.slide-down-enter-active,
.slide-down-appear.slide-down-appear-active {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-down-leave.slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-down-enter,
.slide-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-left-enter,
.slide-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-left-enter.slide-left-enter-active,
.slide-left-appear.slide-left-appear-active {
  -webkit-animation-name: antSlideLeftIn;
          animation-name: antSlideLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-left-leave.slide-left-leave-active {
  -webkit-animation-name: antSlideLeftOut;
          animation-name: antSlideLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-left-enter,
.slide-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-right-enter,
.slide-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.slide-right-enter.slide-right-enter-active,
.slide-right-appear.slide-right-appear-active {
  -webkit-animation-name: antSlideRightIn;
          animation-name: antSlideRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.slide-right-leave.slide-right-leave-active {
  -webkit-animation-name: antSlideRightOut;
          animation-name: antSlideRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.slide-right-enter,
.slide-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideLeftOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightIn {
  0% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightOut {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}
.swing-enter,
.swing-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.swing-enter.swing-enter-active,
.swing-appear.swing-appear-active {
  -webkit-animation-name: antSwingIn;
          animation-name: antSwingIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes antSwingIn {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  20% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  60% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  80% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}
@keyframes antSwingIn {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  20% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  40% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  60% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
  }
  80% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
}
.zoom-enter,
.zoom-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-enter.zoom-enter-active,
.zoom-appear.zoom-appear-active {
  -webkit-animation-name: antZoomIn;
          animation-name: antZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-leave.zoom-leave-active {
  -webkit-animation-name: antZoomOut;
          animation-name: antZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-enter,
.zoom-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-enter,
.zoom-big-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-enter.zoom-big-enter-active,
.zoom-big-appear.zoom-big-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-big-leave.zoom-big-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-big-enter,
.zoom-big-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-fast-leave {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-big-fast-enter.zoom-big-fast-enter-active,
.zoom-big-fast-appear.zoom-big-fast-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-big-fast-leave.zoom-big-fast-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-fast-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-up-enter,
.zoom-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-up-enter.zoom-up-enter-active,
.zoom-up-appear.zoom-up-appear-active {
  -webkit-animation-name: antZoomUpIn;
          animation-name: antZoomUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-up-leave.zoom-up-leave-active {
  -webkit-animation-name: antZoomUpOut;
          animation-name: antZoomUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-up-enter,
.zoom-up-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-down-enter,
.zoom-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-down-enter.zoom-down-enter-active,
.zoom-down-appear.zoom-down-appear-active {
  -webkit-animation-name: antZoomDownIn;
          animation-name: antZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-down-leave.zoom-down-leave-active {
  -webkit-animation-name: antZoomDownOut;
          animation-name: antZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-down-enter,
.zoom-down-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-left-enter,
.zoom-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-left-enter.zoom-left-enter-active,
.zoom-left-appear.zoom-left-appear-active {
  -webkit-animation-name: antZoomLeftIn;
          animation-name: antZoomLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-left-leave.zoom-left-leave-active {
  -webkit-animation-name: antZoomLeftOut;
          animation-name: antZoomLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-left-enter,
.zoom-left-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-right-enter,
.zoom-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.zoom-right-enter.zoom-right-enter-active,
.zoom-right-appear.zoom-right-appear-active {
  -webkit-animation-name: antZoomRightIn;
          animation-name: antZoomRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.zoom-right-leave.zoom-right-leave-active {
  -webkit-animation-name: antZoomRightOut;
          animation-name: antZoomRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.zoom-right-enter,
.zoom-right-appear {
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@-webkit-keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@-webkit-keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomUpOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}
@-webkit-keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomLeftOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}
@-webkit-keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownIn {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@-webkit-keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
}
@keyframes antZoomDownOut {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-affix {
  position: fixed;
  z-index: 10;
}
.ant-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding: 8px 15px 8px 37px;
  word-wrap: break-word;
  border-radius: 6px;
}
.ant-alert.ant-alert-no-icon {
  padding: 8px 15px;
}
.ant-alert.ant-alert-closable {
  padding-right: 30px;
}
.ant-alert-icon {
  position: absolute;
  top: 10.1px;
  left: 16px;
}
.ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.ant-alert-success .ant-alert-icon {
  color: #52c41a;
}
.ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}
.ant-alert-info .ant-alert-icon {
  color: #1890ff;
}
.ant-alert-warning {
  background-color: #fff7e6;
  border: 1px solid #ffd591;
}
.ant-alert-warning .ant-alert-icon {
  color: #fa8c16;
}
.ant-alert-error {
  background-color: #fff1f0;
  border: 1px solid #ffa39e;
}
.ant-alert-error .ant-alert-icon {
  color: #f5222d;
}
.ant-alert-close-icon {
  position: absolute;
  top: 8px;
  right: 16px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 22px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.ant-alert-close-icon .anticon-close {
  color: #595959;
  transition: color 0.3s;
}
.ant-alert-close-icon .anticon-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-close-text {
  color: #595959;
  transition: color 0.3s;
}
.ant-alert-close-text:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-with-description {
  position: relative;
  padding: 15px 15px 15px 64px;
  color: #545454;
  line-height: 1.3;
  border-radius: 6px;
}
.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px;
}
.ant-alert-with-description .ant-alert-icon {
  position: absolute;
  top: 16px;
  left: 24px;
  font-size: 24px;
}
.ant-alert-with-description .ant-alert-close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 14px;
  cursor: pointer;
}
.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: #535353;
  font-size: 16px;
}
.ant-alert-message {
  color: #535353;
}
.ant-alert-with-description .ant-alert-description {
  display: block;
}
.ant-alert.ant-alert-closing {
  height: 0 !important;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-alert-slide-up-leave {
  -webkit-animation: antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation: antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
@-webkit-keyframes antAlertSlideUpIn {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antAlertSlideUpIn {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antAlertSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antAlertSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 0;
  }
}
.ant-anchor {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding-left: 2px;
}
.ant-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: #fff;
}
.ant-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.ant-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #e8e8e8;
  content: ' ';
}
.ant-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #038fde;
  border-radius: 8px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  transition: top 0.3s ease-in-out;
}
.ant-anchor-ink-ball.visible {
  display: inline-block;
}
.ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}
.ant-anchor-link {
  padding: 7px 0 7px 16px;
  line-height: 1.143;
}
.ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 6px;
  overflow: hidden;
  color: #545454;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-anchor-link-title:only-child {
  margin-bottom: 0;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: #038fde;
}
.ant-anchor-link .ant-anchor-link {
  padding-top: 5px;
  padding-bottom: 5px;
}
.ant-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
}
.ant-select-auto-complete.ant-select .ant-select-selection {
  border: 0;
  box-shadow: none;
}
.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  height: 100%;
  margin-right: 0;
  margin-left: 0;
  line-height: 36px;
}
.ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
  margin-right: 12px;
  margin-left: 12px;
}
.ant-select-auto-complete.ant-select .ant-select-selection--single {
  height: auto;
}
.ant-select-auto-complete.ant-select .ant-select-search--inline {
  position: static;
  float: left;
}
.ant-select-auto-complete.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 0 !important;
}
.ant-select-auto-complete.ant-select .ant-input {
  height: 36px;
  line-height: 1.3;
  background: transparent;
  border-width: 1px;
}
.ant-select-auto-complete.ant-select .ant-input:focus,
.ant-select-auto-complete.ant-select .ant-input:hover {
  border-color: #28aaeb;
  border-right-width: 1px !important;
}
.ant-select-auto-complete.ant-select .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
  background-color: transparent;
}
.ant-select-auto-complete.ant-select .ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-select-auto-complete.ant-select-lg .ant-select-selection__rendered {
  line-height: 40px;
}
.ant-select-auto-complete.ant-select-lg .ant-input {
  height: 40px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.ant-select-auto-complete.ant-select-sm .ant-select-selection__rendered {
  line-height: 24px;
}
.ant-select-auto-complete.ant-select-sm .ant-input {
  height: 24px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.ant-input-group > .ant-select-auto-complete .ant-select-search__field.ant-input-affix-wrapper {
  display: inline;
  float: none;
}
.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
.ant-avatar-image {
  background: transparent;
}
.ant-avatar-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
          transform-origin: 0 center;
}
.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}
.ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
          transform-origin: 0 center;
}
.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}
.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  -webkit-transform-origin: 0 center;
          transform-origin: 0 center;
}
.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}
.ant-avatar-square {
  border-radius: 6px;
}
.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: #595959;
  border-radius: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-back-top-content:hover {
  background-color: #545454;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-back-top-icon {
  width: 14px;
  height: 16px;
  margin: 12px auto;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAAABGdBTUEAALGPC/xhBQAAAbtJREFUWAntmMtKw0AUhhMvS5cuxILgQlRUpIggIoKIIoigG1eC+AA+jo+i6FIXBfeuXIgoeKVeitVWJX5HWhhDksnUpp3FDPyZk3Nm5nycmZKkXhAEOXSA3lG7muTeRzmfy6HneUvIhnYkQK+Q9NhAA0Opg0vBEhjBKHiyb8iGMyQMOYuK41BcBSypAL+MYXSKjtFAW7EAGEO3qN4uMQbbAkXiSfRQJ1H6a+yhlkKRcAoVFYiweYNjtCVQJJpBz2GCiPt7fBOZQpFgDpUikse5HgnkM4Fi4QX0Fpc5wf9EbLqpUCy4jMoJSXWhFwbMNgWKhVbRhy5jirhs9fy/oFhgHVVTJEs7RLZ8sSEoJm6iz7SZDMbJ+/OKERQTttCXQRLToRUmrKWCYuA2+jbN0MB4OQobYShfdTCgn/sL1K36M7TLrN3n+758aPy2rrpR6+/od5E8tf/A1uLS9aId5T7J3CNYihkQ4D9PiMdMC7mp4rjB9kjFjZp8BlnVHJBuO1yFXIV0FdDF3RlyFdJVQBdv5AxVdIsq8apiZ2PyYO1EVykesGfZEESsCkweyR8MUW+V8uJ1gkYipmpdP1pm2aJVPEGzAAAAAElFTkSuQmCC) 100%/100% no-repeat;
}
@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
}
.ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  color: unset;
  line-height: 1;
}
.ant-badge-count {
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #f5222d;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count a,
.ant-badge-count a:hover {
  color: #fff;
}
.ant-badge-multiple-words {
  padding: 0 8px;
}
.ant-badge-dot {
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
}
.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.ant-badge-status-success {
  background-color: #52c41a;
}
.ant-badge-status-processing {
  position: relative;
  background-color: #038fde;
}
.ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #038fde;
  border-radius: 50%;
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
          animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
.ant-badge-status-default {
  background-color: #d9d9d9;
}
.ant-badge-status-error {
  background-color: #f5222d;
}
.ant-badge-status-warning {
  background-color: #fa8c16;
}
.ant-badge-status-pink {
  background: #eb2f96;
}
.ant-badge-status-magenta {
  background: #eb2f96;
}
.ant-badge-status-red {
  background: #f5222d;
}
.ant-badge-status-volcano {
  background: #fa541c;
}
.ant-badge-status-orange {
  background: #fa8c16;
}
.ant-badge-status-yellow {
  background: #fadb14;
}
.ant-badge-status-gold {
  background: #faad14;
}
.ant-badge-status-cyan {
  background: #13c2c2;
}
.ant-badge-status-lime {
  background: #a0d911;
}
.ant-badge-status-green {
  background: #52c41a;
}
.ant-badge-status-blue {
  background: #1890ff;
}
.ant-badge-status-geekblue {
  background: #2f54eb;
}
.ant-badge-status-purple {
  background: #722ed1;
}
.ant-badge-status-text {
  margin-left: 8px;
  color: #545454;
  font-size: 14px;
}
.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
  -webkit-animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-badge-zoom-leave {
  -webkit-animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
          animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}
.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
}
.ant-badge-not-a-wrapper .ant-badge-count {
  -webkit-transform: none;
          transform: none;
}
@-webkit-keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(2.4);
            transform: scale(2.4);
    opacity: 0;
  }
}
@keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(2.4);
            transform: scale(2.4);
    opacity: 0;
  }
}
.ant-scroll-number {
  overflow: hidden;
}
.ant-scroll-number-only {
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: 20px;
  margin: 0;
}
.ant-scroll-number-symbol {
  vertical-align: top;
}
@-webkit-keyframes antZoomBadgeIn {
  0% {
    -webkit-transform: scale(0) translate(50%, -50%);
            transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1) translate(50%, -50%);
            transform: scale(1) translate(50%, -50%);
  }
}
@keyframes antZoomBadgeIn {
  0% {
    -webkit-transform: scale(0) translate(50%, -50%);
            transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1) translate(50%, -50%);
            transform: scale(1) translate(50%, -50%);
  }
}
@-webkit-keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translate(50%, -50%);
            transform: scale(1) translate(50%, -50%);
  }
  100% {
    -webkit-transform: scale(0) translate(50%, -50%);
            transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
@keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translate(50%, -50%);
            transform: scale(1) translate(50%, -50%);
  }
  100% {
    -webkit-transform: scale(0) translate(50%, -50%);
            transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
.ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  color: #595959;
  font-size: 14px;
}
.ant-breadcrumb .anticon {
  font-size: 14px;
}
.ant-breadcrumb a {
  color: #595959;
  transition: color 0.3s;
}
.ant-breadcrumb a:hover {
  color: #28aaeb;
}
.ant-breadcrumb > span:last-child {
  color: #545454;
}
.ant-breadcrumb > span:last-child a {
  color: #545454;
}
.ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
  display: none;
}
.ant-breadcrumb-separator {
  margin: 0 8px;
  color: #595959;
}
.ant-breadcrumb-link > .anticon + span {
  margin-left: 4px;
}
.ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}
.ant-btn {
  line-height: 1.299;
  position: relative;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
          user-select: none;
  touch-action: manipulation;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: solid 1px #d9d9d9;
  padding: 0 15px;
  height: 36px;
  font-size: 14px;
  line-height: 34px;
  border-radius: 4px;
  margin-bottom: 15px;
  color: #545454;
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-btn.disabled,
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn.disabled > *,
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
}
.ant-btn-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 12px;
  border-radius: 4px;
}
.ant-btn:not(:last-child) {
  margin-right: 15px;
}
@media screen and (max-width: 575px) {
  .ant-btn:not(:last-child) {
    margin-right: 10px;
  }
}
@media screen and (max-width: 575px) {
  .ant-btn {
    margin-bottom: 10px;
  }
}
.ant-btn:focus,
.ant-btn:hover {
  text-decoration: none;
}
.ant-btn:focus,
.ant-btn.focus {
  outline: 0;
}
.ant-btn.disabled,
.ant-btn:disabled {
  opacity: 0.5;
}
.ant-btn:not([disabled]):not(.disabled) {
  cursor: pointer;
}
.gx-btn-list .ant-btn {
  margin-bottom: 6px;
}
.gx-sub-popover .ant-btn {
  border: 0 none;
  margin-bottom: 5px;
}
.gx-sub-popover .ant-btn:focus,
.gx-sub-popover .ant-btn:hover {
  background-color: #f5f5f5;
}
.gx-sub-popover .ant-btn:not(:last-child) {
  margin-right: 5px;
}
.gx-login-content .ant-btn {
  padding: 9px 20px !important;
  height: auto;
  line-height: 1;
}
.ant-btn > a:only-child {
  color: currentColor;
}
.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus {
  color: #28aaeb;
  background-color: #fff;
  border-color: #28aaeb;
}
.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentColor;
}
.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:active,
.ant-btn.active {
  color: #006eb8;
  background-color: #fff;
  border-color: #006eb8;
}
.ant-btn:active > a:only-child,
.ant-btn.active > a:only-child {
  color: currentColor;
}
.ant-btn:active > a:only-child::after,
.ant-btn.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-disabled,
.ant-btn.disabled,
.ant-btn[disabled],
.ant-btn-disabled:hover,
.ant-btn.disabled:hover,
.ant-btn[disabled]:hover,
.ant-btn-disabled:focus,
.ant-btn.disabled:focus,
.ant-btn[disabled]:focus,
.ant-btn-disabled:active,
.ant-btn.disabled:active,
.ant-btn[disabled]:active,
.ant-btn-disabled.active,
.ant-btn.disabled.active,
.ant-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-disabled > a:only-child,
.ant-btn.disabled > a:only-child,
.ant-btn[disabled] > a:only-child,
.ant-btn-disabled:hover > a:only-child,
.ant-btn.disabled:hover > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn-disabled:focus > a:only-child,
.ant-btn.disabled:focus > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn-disabled:active > a:only-child,
.ant-btn.disabled:active > a:only-child,
.ant-btn[disabled]:active > a:only-child,
.ant-btn-disabled.active > a:only-child,
.ant-btn.disabled.active > a:only-child,
.ant-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-disabled > a:only-child::after,
.ant-btn.disabled > a:only-child::after,
.ant-btn[disabled] > a:only-child::after,
.ant-btn-disabled:hover > a:only-child::after,
.ant-btn.disabled:hover > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn-disabled:focus > a:only-child::after,
.ant-btn.disabled:focus > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn-disabled:active > a:only-child::after,
.ant-btn.disabled:active > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after,
.ant-btn-disabled.active > a:only-child::after,
.ant-btn.disabled.active > a:only-child::after,
.ant-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  text-decoration: none;
  background: #fff;
}
.ant-btn > i,
.ant-btn > span {
  display: inline-block;
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  pointer-events: none;
}
.ant-btn-primary {
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  color: #ffffff !important;
  background-color: #038fde;
  border-color: #038fde;
}
.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #28aaeb;
  border-color: #28aaeb;
}
.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:active,
.ant-btn-primary.active {
  color: #fff;
  background-color: #006eb8;
  border-color: #006eb8;
}
.ant-btn-primary:active > a:only-child,
.ant-btn-primary.active > a:only-child {
  color: currentColor;
}
.ant-btn-primary:active > a:only-child::after,
.ant-btn-primary.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary-disabled,
.ant-btn-primary.disabled,
.ant-btn-primary[disabled],
.ant-btn-primary-disabled:hover,
.ant-btn-primary.disabled:hover,
.ant-btn-primary[disabled]:hover,
.ant-btn-primary-disabled:focus,
.ant-btn-primary.disabled:focus,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary-disabled:active,
.ant-btn-primary.disabled:active,
.ant-btn-primary[disabled]:active,
.ant-btn-primary-disabled.active,
.ant-btn-primary.disabled.active,
.ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary-disabled > a:only-child,
.ant-btn-primary.disabled > a:only-child,
.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary-disabled:hover > a:only-child,
.ant-btn-primary.disabled:hover > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary-disabled:focus > a:only-child,
.ant-btn-primary.disabled:focus > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary-disabled:active > a:only-child,
.ant-btn-primary.disabled:active > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-primary-disabled.active > a:only-child,
.ant-btn-primary.disabled.active > a:only-child,
.ant-btn-primary[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-primary-disabled > a:only-child::after,
.ant-btn-primary.disabled > a:only-child::after,
.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary-disabled:hover > a:only-child::after,
.ant-btn-primary.disabled:hover > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary-disabled:focus > a:only-child::after,
.ant-btn-primary.disabled:focus > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary-disabled:active > a:only-child::after,
.ant-btn-primary.disabled:active > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after,
.ant-btn-primary-disabled.active > a:only-child::after,
.ant-btn-primary.disabled.active > a:only-child::after,
.ant-btn-primary[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #ffffff !important;
  background-color: #038fde;
  border-color: #038fde;
}
.ant-btn-primary.disabled,
.ant-btn-primary:disabled {
  background-color: #038fde !important;
  border-color: #038fde !important;
}
.ant-btn-primary:not([disabled]):not(.disabled):active,
.ant-btn-primary:not([disabled]):not(.disabled).active {
  color: #ffffff;
  background-color: #038fde !important;
  border-color: #038fde !important;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #28aaeb;
  border-left-color: #28aaeb;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #28aaeb;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #28aaeb;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}
.ant-btn-ghost {
  color: #545454;
  background-color: transparent;
  border-color: #d9d9d9;
}
.ant-btn-ghost > a:only-child {
  color: currentColor;
}
.ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #28aaeb;
  background-color: transparent;
  border-color: #28aaeb;
}
.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:active,
.ant-btn-ghost.active {
  color: #006eb8;
  background-color: transparent;
  border-color: #006eb8;
}
.ant-btn-ghost:active > a:only-child,
.ant-btn-ghost.active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:active > a:only-child::after,
.ant-btn-ghost.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost-disabled,
.ant-btn-ghost.disabled,
.ant-btn-ghost[disabled],
.ant-btn-ghost-disabled:hover,
.ant-btn-ghost.disabled:hover,
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost-disabled:focus,
.ant-btn-ghost.disabled:focus,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost-disabled:active,
.ant-btn-ghost.disabled:active,
.ant-btn-ghost[disabled]:active,
.ant-btn-ghost-disabled.active,
.ant-btn-ghost.disabled.active,
.ant-btn-ghost[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-ghost-disabled > a:only-child,
.ant-btn-ghost.disabled > a:only-child,
.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost-disabled:hover > a:only-child,
.ant-btn-ghost.disabled:hover > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost-disabled:focus > a:only-child,
.ant-btn-ghost.disabled:focus > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost-disabled:active > a:only-child,
.ant-btn-ghost.disabled:active > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child,
.ant-btn-ghost-disabled.active > a:only-child,
.ant-btn-ghost.disabled.active > a:only-child,
.ant-btn-ghost[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost-disabled > a:only-child::after,
.ant-btn-ghost.disabled > a:only-child::after,
.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost-disabled:hover > a:only-child::after,
.ant-btn-ghost.disabled:hover > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost-disabled:focus > a:only-child::after,
.ant-btn-ghost.disabled:focus > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost-disabled:active > a:only-child::after,
.ant-btn-ghost.disabled:active > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after,
.ant-btn-ghost-disabled.active > a:only-child::after,
.ant-btn-ghost.disabled.active > a:only-child::after,
.ant-btn-ghost[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed {
  color: #545454;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: dashed;
}
.ant-btn-dashed > a:only-child {
  color: currentColor;
}
.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #28aaeb;
  background-color: #fff;
  border-color: #28aaeb;
}
.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:active,
.ant-btn-dashed.active {
  color: #006eb8;
  background-color: #fff;
  border-color: #006eb8;
}
.ant-btn-dashed:active > a:only-child,
.ant-btn-dashed.active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:active > a:only-child::after,
.ant-btn-dashed.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed-disabled,
.ant-btn-dashed.disabled,
.ant-btn-dashed[disabled],
.ant-btn-dashed-disabled:hover,
.ant-btn-dashed.disabled:hover,
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed-disabled:focus,
.ant-btn-dashed.disabled:focus,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed-disabled:active,
.ant-btn-dashed.disabled:active,
.ant-btn-dashed[disabled]:active,
.ant-btn-dashed-disabled.active,
.ant-btn-dashed.disabled.active,
.ant-btn-dashed[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed-disabled > a:only-child,
.ant-btn-dashed.disabled > a:only-child,
.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed-disabled:hover > a:only-child,
.ant-btn-dashed.disabled:hover > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed-disabled:focus > a:only-child,
.ant-btn-dashed.disabled:focus > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed-disabled:active > a:only-child,
.ant-btn-dashed.disabled:active > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child,
.ant-btn-dashed-disabled.active > a:only-child,
.ant-btn-dashed.disabled.active > a:only-child,
.ant-btn-dashed[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed-disabled > a:only-child::after,
.ant-btn-dashed.disabled > a:only-child::after,
.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed-disabled:hover > a:only-child::after,
.ant-btn-dashed.disabled:hover > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed-disabled:focus > a:only-child::after,
.ant-btn-dashed.disabled:focus > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed-disabled:active > a:only-child::after,
.ant-btn-dashed.disabled:active > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after,
.ant-btn-dashed-disabled.active > a:only-child::after,
.ant-btn-dashed.disabled.active > a:only-child::after,
.ant-btn-dashed[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger {
  color: #fff;
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  background-color: #ff7875;
  border-color: #ff7875;
}
.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-danger:hover > a:only-child::after,
.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:active,
.ant-btn-danger.active {
  color: #fff;
  background-color: #d9363e;
  border-color: #d9363e;
}
.ant-btn-danger:active > a:only-child,
.ant-btn-danger.active > a:only-child {
  color: currentColor;
}
.ant-btn-danger:active > a:only-child::after,
.ant-btn-danger.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger-disabled,
.ant-btn-danger.disabled,
.ant-btn-danger[disabled],
.ant-btn-danger-disabled:hover,
.ant-btn-danger.disabled:hover,
.ant-btn-danger[disabled]:hover,
.ant-btn-danger-disabled:focus,
.ant-btn-danger.disabled:focus,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger-disabled:active,
.ant-btn-danger.disabled:active,
.ant-btn-danger[disabled]:active,
.ant-btn-danger-disabled.active,
.ant-btn-danger.disabled.active,
.ant-btn-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger-disabled > a:only-child,
.ant-btn-danger.disabled > a:only-child,
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger-disabled:hover > a:only-child,
.ant-btn-danger.disabled:hover > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger-disabled:focus > a:only-child,
.ant-btn-danger.disabled:focus > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger-disabled:active > a:only-child,
.ant-btn-danger.disabled:active > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-danger-disabled.active > a:only-child,
.ant-btn-danger.disabled.active > a:only-child,
.ant-btn-danger[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-danger-disabled > a:only-child::after,
.ant-btn-danger.disabled > a:only-child::after,
.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger-disabled:hover > a:only-child::after,
.ant-btn-danger.disabled:hover > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger-disabled:focus > a:only-child::after,
.ant-btn-danger.disabled:focus > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger-disabled:active > a:only-child::after,
.ant-btn-danger.disabled:active > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after,
.ant-btn-danger-disabled.active > a:only-child::after,
.ant-btn-danger.disabled.active > a:only-child::after,
.ant-btn-danger[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link {
  color: #038fde;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #28aaeb;
  background-color: transparent;
  border-color: #28aaeb;
}
.ant-btn-link:hover > a:only-child,
.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-link:hover > a:only-child::after,
.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:active,
.ant-btn-link.active {
  color: #006eb8;
  background-color: transparent;
  border-color: #006eb8;
}
.ant-btn-link:active > a:only-child,
.ant-btn-link.active > a:only-child {
  color: currentColor;
}
.ant-btn-link:active > a:only-child::after,
.ant-btn-link.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link-disabled,
.ant-btn-link.disabled,
.ant-btn-link[disabled],
.ant-btn-link-disabled:hover,
.ant-btn-link.disabled:hover,
.ant-btn-link[disabled]:hover,
.ant-btn-link-disabled:focus,
.ant-btn-link.disabled:focus,
.ant-btn-link[disabled]:focus,
.ant-btn-link-disabled:active,
.ant-btn-link.disabled:active,
.ant-btn-link[disabled]:active,
.ant-btn-link-disabled.active,
.ant-btn-link.disabled.active,
.ant-btn-link[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link-disabled > a:only-child,
.ant-btn-link.disabled > a:only-child,
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link-disabled:hover > a:only-child,
.ant-btn-link.disabled:hover > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link-disabled:focus > a:only-child,
.ant-btn-link.disabled:focus > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link-disabled:active > a:only-child,
.ant-btn-link.disabled:active > a:only-child,
.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-link-disabled.active > a:only-child,
.ant-btn-link.disabled.active > a:only-child,
.ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-link-disabled > a:only-child::after,
.ant-btn-link.disabled > a:only-child::after,
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link-disabled:hover > a:only-child::after,
.ant-btn-link.disabled:hover > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link-disabled:focus > a:only-child::after,
.ant-btn-link.disabled:focus > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link-disabled:active > a:only-child::after,
.ant-btn-link.disabled:active > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-link-disabled.active > a:only-child::after,
.ant-btn-link.disabled.active > a:only-child::after,
.ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
}
.ant-btn-link-disabled,
.ant-btn-link.disabled,
.ant-btn-link[disabled],
.ant-btn-link-disabled:hover,
.ant-btn-link.disabled:hover,
.ant-btn-link[disabled]:hover,
.ant-btn-link-disabled:focus,
.ant-btn-link.disabled:focus,
.ant-btn-link[disabled]:focus,
.ant-btn-link-disabled:active,
.ant-btn-link.disabled:active,
.ant-btn-link[disabled]:active,
.ant-btn-link-disabled.active,
.ant-btn-link.disabled.active,
.ant-btn-link[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link-disabled > a:only-child,
.ant-btn-link.disabled > a:only-child,
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link-disabled:hover > a:only-child,
.ant-btn-link.disabled:hover > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link-disabled:focus > a:only-child,
.ant-btn-link.disabled:focus > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link-disabled:active > a:only-child,
.ant-btn-link.disabled:active > a:only-child,
.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-link-disabled.active > a:only-child,
.ant-btn-link.disabled.active > a:only-child,
.ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-link-disabled > a:only-child::after,
.ant-btn-link.disabled > a:only-child::after,
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link-disabled:hover > a:only-child::after,
.ant-btn-link.disabled:hover > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link-disabled:focus > a:only-child::after,
.ant-btn-link.disabled:focus > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link-disabled:active > a:only-child::after,
.ant-btn-link.disabled:active > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-link-disabled.active > a:only-child::after,
.ant-btn-link.disabled.active > a:only-child::after,
.ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-icon-only {
  width: 36px;
  height: 36px;
  padding: 0;
  font-size: 16px;
  border-radius: 4px;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 18px;
  border-radius: 4px;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0;
  font-size: 14px;
  border-radius: 4px;
}
.ant-btn-icon-only > i {
  vertical-align: middle;
}
.ant-btn-round {
  height: 36px;
  padding: 0 18px;
  font-size: 14px;
  border-radius: 36px;
}
.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 0 20px;
  font-size: 16px;
  border-radius: 40px;
}
.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 0 12px;
  font-size: 14px;
  border-radius: 24px;
}
.ant-btn-round.ant-btn-icon-only {
  width: auto;
}
.ant-btn-circle,
.ant-btn-circle-outline {
  min-width: 36px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-lg,
.ant-btn-circle-outline.ant-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-sm,
.ant-btn-circle-outline.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}
.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizeSpeed;
}
.ant-btn.ant-btn-loading {
  position: relative;
}
.ant-btn.ant-btn-loading:not([disabled]) {
  pointer-events: none;
}
.ant-btn.ant-btn-loading::before {
  display: block;
}
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 29px;
}
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon:not(:last-child) {
  margin-left: -14px;
}
.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 24px;
}
.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) .anticon {
  margin-left: -17px;
}
.ant-btn-group {
  position: relative;
  display: inline-block;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active,
.ant-btn-group > .ant-btn.active,
.ant-btn-group > span > .ant-btn.active {
  z-index: 2;
}
.ant-btn-group > .ant-btn:disabled,
.ant-btn-group > span > .ant-btn:disabled {
  z-index: 0;
}
.ant-btn-group > .ant-btn-icon-only {
  font-size: 14px;
}
.ant-btn-group-lg > .ant-btn,
.ant-btn-group-lg > span > .ant-btn {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 0;
  line-height: 38px;
}
.ant-btn-group-lg > .ant-btn.ant-btn-icon-only {
  width: 40px;
  height: 40px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-group-sm > .ant-btn,
.ant-btn-group-sm > span > .ant-btn {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 0;
  line-height: 22px;
}
.ant-btn-group-sm > .ant-btn > .anticon,
.ant-btn-group-sm > span > .ant-btn > .anticon {
  font-size: 14px;
}
.ant-btn-group-sm > .ant-btn.ant-btn-icon-only {
  width: 24px;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn-background-ghost {
  color: #fff;
  background: transparent !important;
  border-color: #fff;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #038fde;
  background-color: transparent;
  border-color: #038fde;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #28aaeb;
  background-color: transparent;
  border-color: #28aaeb;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:active,
.ant-btn-background-ghost.ant-btn-primary.active {
  color: #006eb8;
  background-color: transparent;
  border-color: #006eb8;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary-disabled,
.ant-btn-background-ghost.ant-btn-primary.disabled,
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary-disabled:hover,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary-disabled:active,
.ant-btn-background-ghost.ant-btn-primary.disabled:active,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active,
.ant-btn-background-ghost.ant-btn-primary-disabled.active,
.ant-btn-background-ghost.ant-btn-primary.disabled.active,
.ant-btn-background-ghost.ant-btn-primary[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger {
  color: #ff4d4f;
  background-color: transparent;
  border-color: #ff4d4f;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #ff7875;
  background-color: transparent;
  border-color: #ff7875;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:active,
.ant-btn-background-ghost.ant-btn-danger.active {
  color: #d9363e;
  background-color: transparent;
  border-color: #d9363e;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger-disabled,
.ant-btn-background-ghost.ant-btn-danger.disabled,
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger-disabled:hover,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger-disabled:active,
.ant-btn-background-ghost.ant-btn-danger.disabled:active,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active,
.ant-btn-background-ghost.ant-btn-danger-disabled.active,
.ant-btn-background-ghost.ant-btn-danger.disabled.active,
.ant-btn-background-ghost.ant-btn-danger[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link {
  color: #038fde;
  background-color: transparent;
  border-color: transparent;
  text-shadow: none;
  color: #fff;
}
.ant-btn-background-ghost.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-link:focus {
  color: #28aaeb;
  background-color: transparent;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link:active,
.ant-btn-background-ghost.ant-btn-link.active {
  color: #006eb8;
  background-color: transparent;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-link:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link.active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-link-disabled,
.ant-btn-background-ghost.ant-btn-link.disabled,
.ant-btn-background-ghost.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-link-disabled:hover,
.ant-btn-background-ghost.ant-btn-link.disabled:hover,
.ant-btn-background-ghost.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-link-disabled:focus,
.ant-btn-background-ghost.ant-btn-link.disabled:focus,
.ant-btn-background-ghost.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-link-disabled:active,
.ant-btn-background-ghost.ant-btn-link.disabled:active,
.ant-btn-background-ghost.ant-btn-link[disabled]:active,
.ant-btn-background-ghost.ant-btn-link-disabled.active,
.ant-btn-background-ghost.ant-btn-link.disabled.active,
.ant-btn-background-ghost.ant-btn-link[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-link-disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled]:active > a:only-child,
.ant-btn-background-ghost.ant-btn-link-disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-link.disabled.active > a:only-child,
.ant-btn-background-ghost.ant-btn-link[disabled].active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-link-disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled]:active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link-disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link.disabled.active > a:only-child::after,
.ant-btn-background-ghost.ant-btn-link[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  vertical-align: top;
}
a.ant-btn {
  padding-top: 0.1px;
  line-height: 34px;
}
a.ant-btn-lg {
  line-height: 38px;
}
a.ant-btn-sm {
  line-height: 22px;
}
.ant-fullcalendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  border-top: 1px solid #d9d9d9;
  outline: none;
}
.ant-select.ant-fullcalendar-year-select {
  min-width: 90px;
}
.ant-select.ant-fullcalendar-year-select.ant-select-sm {
  min-width: 70px;
}
.ant-select.ant-fullcalendar-month-select {
  min-width: 80px;
  margin-left: 8px;
}
.ant-select.ant-fullcalendar-month-select.ant-select-sm {
  min-width: 70px;
}
.ant-fullcalendar-header {
  padding: 11px 16px 11px 0;
  text-align: right;
}
.ant-fullcalendar-header .ant-select-dropdown {
  text-align: left;
}
.ant-fullcalendar-header .ant-radio-group {
  margin-left: 8px;
  text-align: left;
}
.ant-fullcalendar-header label.ant-radio-button {
  height: 22px;
  padding: 0 10px;
  line-height: 20px;
}
.ant-fullcalendar-date-panel {
  position: relative;
  outline: none;
}
.ant-fullcalendar-calendar-body {
  padding: 8px 12px;
}
.ant-fullcalendar table {
  width: 100%;
  max-width: 100%;
  height: 256px;
  background-color: transparent;
  border-collapse: collapse;
}
.ant-fullcalendar table,
.ant-fullcalendar th,
.ant-fullcalendar td {
  border: 0;
}
.ant-fullcalendar td {
  position: relative;
}
.ant-fullcalendar-calendar-table {
  margin-bottom: 0;
  border-spacing: 0;
}
.ant-fullcalendar-column-header {
  width: 33px;
  padding: 0;
  line-height: 18px;
  text-align: center;
}
.ant-fullcalendar-column-header .ant-fullcalendar-column-header-inner {
  display: block;
  font-weight: normal;
}
.ant-fullcalendar-week-number-header .ant-fullcalendar-column-header-inner {
  display: none;
}
.ant-fullcalendar-month,
.ant-fullcalendar-date {
  text-align: center;
  transition: all 0.3s;
}
.ant-fullcalendar-value {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  padding: 0;
  color: #545454;
  line-height: 24px;
  background: transparent;
  border-radius: 4px;
  transition: all 0.3s;
}
.ant-fullcalendar-value:hover {
  background: #e6faff;
  cursor: pointer;
}
.ant-fullcalendar-value:active {
  color: #fff;
  background: #038fde;
}
.ant-fullcalendar-month-panel-cell .ant-fullcalendar-value {
  width: 48px;
}
.ant-fullcalendar-today .ant-fullcalendar-value,
.ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value {
  box-shadow: 0 0 0 1px #038fde inset;
}
.ant-fullcalendar-selected-day .ant-fullcalendar-value,
.ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
  color: #fff;
  background: #038fde;
}
.ant-fullcalendar-disabled-cell-first-of-row .ant-fullcalendar-value {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.ant-fullcalendar-disabled-cell-last-of-row .ant-fullcalendar-value {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.ant-fullcalendar-last-month-cell .ant-fullcalendar-value,
.ant-fullcalendar-next-month-btn-day .ant-fullcalendar-value {
  color: rgba(0, 0, 0, 0.25);
}
.ant-fullcalendar-month-panel-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-fullcalendar-content {
  position: absolute;
  bottom: -9px;
  left: 0;
  width: 100%;
}
.ant-fullcalendar-fullscreen {
  border-top: 0;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-table {
  table-layout: fixed;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-header .ant-radio-group {
  margin-left: 16px;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-header label.ant-radio-button {
  height: 36px;
  line-height: 34px;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  display: block;
  height: 116px;
  margin: 0 4px;
  padding: 4px 8px;
  color: #545454;
  text-align: left;
  border-top: 2px solid #e8e8e8;
  transition: background 0.3s;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month:hover,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover {
  background: #e6faff;
  cursor: pointer;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month:active,
.ant-fullcalendar-fullscreen .ant-fullcalendar-date:active {
  background: #a6eaff;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-column-header {
  padding-right: 12px;
  padding-bottom: 5px;
  text-align: right;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-value {
  width: auto;
  text-align: right;
  background: transparent;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
  color: #545454;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date {
  background: transparent;
  border-top-color: #038fde;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value,
.ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
  box-shadow: none;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month,
.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background: #e6faff;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value,
.ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-value {
  color: #038fde;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-last-month-cell .ant-fullcalendar-date,
.ant-fullcalendar-fullscreen .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-date {
  color: rgba(0, 0, 0, 0.25);
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-content {
  position: static;
  width: auto;
  height: 88px;
  overflow-y: auto;
}
.ant-fullcalendar-disabled-cell .ant-fullcalendar-date,
.ant-fullcalendar-disabled-cell .ant-fullcalendar-date:hover {
  cursor: not-allowed;
}
.ant-fullcalendar-disabled-cell:not(.ant-fullcalendar-today) .ant-fullcalendar-date,
.ant-fullcalendar-disabled-cell:not(.ant-fullcalendar-today) .ant-fullcalendar-date:hover {
  background: transparent;
}
.ant-fullcalendar-disabled-cell .ant-fullcalendar-value {
  width: auto;
  color: rgba(0, 0, 0, 0.25);
  border-radius: 0;
  cursor: not-allowed;
}
.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  background: #fff;
  border-radius: 4px;
  transition: all 0.3s;
}
.ant-card-hoverable {
  cursor: pointer;
}
.ant-card-hoverable:hover {
  border-color: rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}
.ant-card-bordered {
  border: 1px solid #e8e8e8;
}
.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: #535353;
  font-weight: 500;
  font-size: 16px;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
  zoom: 1;
}
.ant-card-head::before,
.ant-card-head::after {
  display: table;
  content: '';
}
.ant-card-head::after {
  clear: both;
}
.ant-card-head::before,
.ant-card-head::after {
  display: table;
  content: '';
}
.ant-card-head::after {
  clear: both;
}
.ant-card-head-wrapper {
  display: flex;
  align-items: center;
}
.ant-card-head-title {
  display: inline-block;
  flex: 1 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-head .ant-tabs {
  clear: both;
  margin-bottom: -17px;
  color: #545454;
  font-weight: normal;
  font-size: 14px;
}
.ant-card-head .ant-tabs-bar {
  border-bottom: 1px solid #e8e8e8;
}
.ant-card-extra {
  float: right;
  margin-left: auto;
  padding: 16px 0;
  color: #545454;
  font-weight: normal;
  font-size: 14px;
}
.ant-card-body {
  padding: 24px;
  zoom: 1;
}
.ant-card-body::before,
.ant-card-body::after {
  display: table;
  content: '';
}
.ant-card-body::after {
  clear: both;
}
.ant-card-body::before,
.ant-card-body::after {
  display: table;
  content: '';
}
.ant-card-body::after {
  clear: both;
}
.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.ant-card-grid {
  float: left;
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, 1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
  transition: all 0.3s;
}
.ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
  padding-bottom: 0;
}
.ant-card-cover > * {
  display: block;
  width: 100%;
}
.ant-card-cover img {
  border-radius: 4px 4px 0 0;
}
.ant-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
  zoom: 1;
}
.ant-card-actions::before,
.ant-card-actions::after {
  display: table;
  content: '';
}
.ant-card-actions::after {
  clear: both;
}
.ant-card-actions::before,
.ant-card-actions::after {
  display: table;
  content: '';
}
.ant-card-actions::after {
  clear: both;
}
.ant-card-actions > li {
  float: left;
  margin: 12px 0;
  color: #595959;
  text-align: center;
}
.ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}
.ant-card-actions > li > span:hover {
  color: #038fde;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn),
.ant-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: #595959;
  line-height: 22px;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
  color: #038fde;
}
.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #e8e8e8;
}
.ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}
.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}
.ant-card-meta {
  margin: -4px 0;
  zoom: 1;
}
.ant-card-meta::before,
.ant-card-meta::after {
  display: table;
  content: '';
}
.ant-card-meta::after {
  clear: both;
}
.ant-card-meta::before,
.ant-card-meta::after {
  display: table;
  content: '';
}
.ant-card-meta::after {
  clear: both;
}
.ant-card-meta-avatar {
  float: left;
  padding-right: 16px;
}
.ant-card-meta-detail {
  overflow: hidden;
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.ant-card-meta-title {
  overflow: hidden;
  color: #535353;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-meta-description {
  color: #595959;
}
.ant-card-loading {
  overflow: hidden;
}
.ant-card-loading .ant-card-body {
  -webkit-user-select: none;
          user-select: none;
}
.ant-card-loading-content p {
  margin: 0;
}
.ant-card-loading-block {
  height: 14px;
  margin: 4px 0;
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size: 600% 600%;
  border-radius: 4px;
  -webkit-animation: card-loading 1.4s ease infinite;
          animation: card-loading 1.4s ease infinite;
}
@-webkit-keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
.ant-card-small > .ant-card-body {
  padding: 12px;
}
.ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
}
.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.ant-carousel .slick-list:focus {
  outline: none;
}
.ant-carousel .slick-list.dragging {
  cursor: pointer;
}
.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}
.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
  visibility: hidden;
}
.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
  visibility: visible;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: '';
}
.ant-carousel .slick-track::after {
  clear: both;
}
.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .ant-carousel .slick-slide {
  float: right;
}
.ant-carousel .slick-slide img {
  display: block;
}
.ant-carousel .slick-slide.slick-loading img {
  display: none;
}
.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
  display: block;
}
.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
.ant-carousel .slick-prev {
  left: -25px;
}
.ant-carousel .slick-prev::before {
  content: '\2190';
}
.ant-carousel .slick-next {
  right: -25px;
}
.ant-carousel .slick-next::before {
  content: '\2192';
}
.ant-carousel .slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  margin: 0;
  padding: 0;
  text-align: center;
  list-style: none;
}
.ant-carousel .slick-dots-bottom {
  bottom: 12px;
}
.ant-carousel .slick-dots-top {
  top: 12px;
}
.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 2px;
  padding: 0;
  text-align: center;
  vertical-align: top;
}
.ant-carousel .slick-dots li button {
  display: block;
  width: 16px;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.ant-carousel .slick-dots li.slick-active button {
  width: 24px;
  background: #fff;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active button:hover,
.ant-carousel .slick-dots li.slick-active button:focus {
  opacity: 1;
}
.ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  width: 3px;
  height: auto;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots-left {
  left: 12px;
}
.ant-carousel-vertical .slick-dots-right {
  right: 12px;
}
.ant-carousel-vertical .slick-dots li {
  margin: 0 2px;
  vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
.ant-cascader {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
}
.ant-cascader-input.ant-input {
  position: static;
  width: 100%;
  padding-right: 24px;
  background-color: transparent !important;
  cursor: pointer;
}
.ant-cascader-picker-show-search .ant-cascader-input.ant-input {
  position: relative;
}
.ant-cascader-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 6px;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-cascader-picker-with-value .ant-cascader-picker-label {
  color: transparent;
}
.ant-cascader-picker-disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-cascader-picker-disabled .ant-cascader-input {
  cursor: not-allowed;
}
.ant-cascader-picker:focus .ant-cascader-input {
  border-color: #28aaeb;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
}
.ant-cascader-picker-show-search.ant-cascader-picker-focused {
  color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-picker-label {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px;
  margin-top: -10px;
  padding: 0 20px 0 12px;
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-cascader-picker-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 2;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
}
.ant-cascader-picker-clear:hover {
  color: #595959;
}
.ant-cascader-picker:hover .ant-cascader-picker-clear {
  opacity: 1;
}
.ant-cascader-picker-arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.ant-cascader-picker-arrow.ant-cascader-picker-arrow-expand {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-cascader-picker-label:hover + .ant-cascader-input {
  border-color: #28aaeb;
  border-right-width: 1px !important;
}
.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-small .ant-cascader-picker-arrow {
  right: 8px;
}
.ant-cascader-menus {
  position: absolute;
  z-index: 1050;
  font-size: 14px;
  white-space: nowrap;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-cascader-menus ul,
.ant-cascader-menus ol {
  margin: 0;
  list-style: none;
}
.ant-cascader-menus-empty,
.ant-cascader-menus-hidden {
  display: none;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-cascader-menu {
  display: inline-block;
  min-width: 111px;
  height: 180px;
  margin: 0;
  padding: 4px 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #e8e8e8;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.ant-cascader-menu:first-child {
  border-radius: 6px 0 0 6px;
}
.ant-cascader-menu:last-child {
  margin-right: -1px;
  border-right-color: transparent;
  border-radius: 0 6px 6px 0;
}
.ant-cascader-menu:only-child {
  border-radius: 6px;
}
.ant-cascader-menu-item {
  padding: 5px 12px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-cascader-menu-item:hover {
  background: #e6faff;
}
.ant-cascader-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-cascader-menu-item-disabled:hover {
  background: transparent;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #fafafa;
}
.ant-cascader-menu-item-expand {
  position: relative;
  padding-right: 24px;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  position: absolute;
  right: 12px;
  color: #595959;
}
:root .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
:root .ant-cascader-menu-item-loading-icon {
  font-size: 12px;
}
.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: #f5222d;
}
@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #038fde;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #038fde;
  border-radius: 4px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
          transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #038fde;
  border-color: #038fde;
}
.ant-checkbox-disabled {
  cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden;
}
.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #038fde;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 6px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 6px 6px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
  color: #535353;
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  left: 16px;
  display: inline-block;
  font-size: 12px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow > * {
  line-height: 1;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  display: inline-block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow::before {
  display: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow-icon {
  display: block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: -webkit-transform 0.24s;
  transition: transform 0.24s;
  transition: transform 0.24s, -webkit-transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: right;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: 16px;
  left: auto;
}
.ant-collapse-anim-active {
  transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-collapse-content {
  overflow: hidden;
  color: #545454;
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse-content-inactive {
  display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 6px 6px;
}
.ant-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-comment {
  position: relative;
}
.ant-comment-inner {
  display: flex;
  padding: 16px 0;
}
.ant-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
.ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.ant-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
.ant-comment-content-author {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}
.ant-comment-content-author > a,
.ant-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}
.ant-comment-content-author-name {
  color: #595959;
  font-size: 14px;
  transition: color 0.3s;
}
.ant-comment-content-author-name > * {
  color: #595959;
}
.ant-comment-content-author-name > *:hover {
  color: #595959;
}
.ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}
.ant-comment-content-detail p {
  white-space: pre-wrap;
}
.ant-comment-actions {
  margin-top: 12px;
  padding-left: 0;
}
.ant-comment-actions > li {
  display: inline-block;
  color: #595959;
}
.ant-comment-actions > li > span {
  padding-right: 10px;
  color: #595959;
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-comment-actions > li > span:hover {
  color: #595959;
}
.ant-comment-nested {
  margin-left: 44px;
}
.ant-calendar-picker-container {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1050;
  font-family: 'NoirPro', sans-serif;
}
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topRight,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomRight,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomLeft,
.ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-calendar-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  outline: none;
  cursor: text;
  transition: opacity 0.3s;
}
.ant-calendar-picker-input {
  outline: none;
}
.ant-calendar-picker-input.ant-input {
  line-height: 1.3;
}
.ant-calendar-picker-input.ant-input-sm {
  padding-top: 0;
  padding-bottom: 0;
}
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #28aaeb;
}
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #28aaeb;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
}
.ant-calendar-picker-clear,
.ant-calendar-picker-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  font-size: 12px;
  line-height: 14px;
  transition: all 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-calendar-picker-clear {
  z-index: 2;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
}
.ant-calendar-picker-clear:hover {
  color: #595959;
}
.ant-calendar-picker:hover .ant-calendar-picker-clear {
  opacity: 1;
  pointer-events: auto;
}
.ant-calendar-picker-icon {
  display: inline-block;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1;
}
.ant-input-disabled + .ant-calendar-picker-icon {
  cursor: not-allowed;
}
.ant-calendar-picker-small .ant-calendar-picker-clear,
.ant-calendar-picker-small .ant-calendar-picker-icon {
  right: 8px;
}
.ant-calendar {
  position: relative;
  width: 280px;
  font-size: 14px;
  line-height: 1.3;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #fff;
  border-radius: 6px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-calendar-input-wrap {
  height: 34px;
  padding: 6px 10px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-calendar-input {
  width: 100%;
  height: 22px;
  color: #545454;
  background: #fff;
  border: 0;
  outline: 0;
  cursor: auto;
}
.ant-calendar-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-week-number {
  width: 286px;
}
.ant-calendar-week-number-cell {
  text-align: center;
}
.ant-calendar-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
          user-select: none;
}
.ant-calendar-header a:hover {
  color: #28aaeb;
}
.ant-calendar-header .ant-calendar-century-select,
.ant-calendar-header .ant-calendar-decade-select,
.ant-calendar-header .ant-calendar-year-select,
.ant-calendar-header .ant-calendar-month-select {
  display: inline-block;
  padding: 0 2px;
  color: #535353;
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-header .ant-calendar-century-select-arrow,
.ant-calendar-header .ant-calendar-decade-select-arrow,
.ant-calendar-header .ant-calendar-year-select-arrow,
.ant-calendar-header .ant-calendar-month-select-arrow {
  display: none;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-prev-month-btn,
.ant-calendar-header .ant-calendar-next-month-btn,
.ant-calendar-header .ant-calendar-prev-year-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: #595959;
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-prev-century-btn::before,
.ant-calendar-header .ant-calendar-prev-decade-btn::before,
.ant-calendar-header .ant-calendar-prev-year-btn::before,
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-prev-century-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-decade-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-year-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-century-btn:hover::after,
.ant-calendar-header .ant-calendar-prev-decade-btn:hover::after,
.ant-calendar-header .ant-calendar-prev-year-btn:hover::after {
  border-color: #545454;
}
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-prev-century-btn::after,
.ant-calendar-header .ant-calendar-prev-decade-btn::after,
.ant-calendar-header .ant-calendar-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before,
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-next-century-btn:hover::before,
.ant-calendar-header .ant-calendar-next-decade-btn:hover::before,
.ant-calendar-header .ant-calendar-next-year-btn:hover::before,
.ant-calendar-header .ant-calendar-next-century-btn:hover::after,
.ant-calendar-header .ant-calendar-next-decade-btn:hover::after,
.ant-calendar-header .ant-calendar-next-year-btn:hover::after {
  border-color: #545454;
}
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before,
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-header .ant-calendar-next-century-btn::before,
.ant-calendar-header .ant-calendar-next-decade-btn::before,
.ant-calendar-header .ant-calendar-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-header .ant-calendar-next-century-btn::after,
.ant-calendar-header .ant-calendar-next-decade-btn::after,
.ant-calendar-header .ant-calendar-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-header .ant-calendar-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-prev-month-btn::before,
.ant-calendar-header .ant-calendar-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-prev-month-btn:hover::before,
.ant-calendar-header .ant-calendar-prev-month-btn:hover::after {
  border-color: #545454;
}
.ant-calendar-header .ant-calendar-prev-month-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-header .ant-calendar-next-month-btn::before,
.ant-calendar-header .ant-calendar-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-header .ant-calendar-next-month-btn:hover::before,
.ant-calendar-header .ant-calendar-next-month-btn:hover::after {
  border-color: #545454;
}
.ant-calendar-header .ant-calendar-next-month-btn::after {
  display: none;
}
.ant-calendar-header .ant-calendar-next-month-btn::before,
.ant-calendar-header .ant-calendar-next-month-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-body {
  padding: 8px 12px;
}
.ant-calendar table {
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
}
.ant-calendar table,
.ant-calendar th,
.ant-calendar td {
  text-align: center;
  border: 0;
}
.ant-calendar-calendar-table {
  margin-bottom: 0;
  border-spacing: 0;
}
.ant-calendar-column-header {
  width: 33px;
  padding: 6px 0;
  line-height: 18px;
  text-align: center;
}
.ant-calendar-column-header .ant-calendar-column-header-inner {
  display: block;
  font-weight: normal;
}
.ant-calendar-week-number-header .ant-calendar-column-header-inner {
  display: none;
}
.ant-calendar-cell {
  height: 30px;
  padding: 3px 0;
}
.ant-calendar-date {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  padding: 0;
  color: #545454;
  line-height: 22px;
  text-align: center;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: background 0.3s ease;
}
.ant-calendar-date-panel {
  position: relative;
  outline: none;
}
.ant-calendar-date:hover {
  background: #e6faff;
  cursor: pointer;
}
.ant-calendar-date:active {
  color: #fff;
  background: #28aaeb;
}
.ant-calendar-today .ant-calendar-date {
  color: #038fde;
  font-weight: bold;
  border-color: #038fde;
}
.ant-calendar-selected-day .ant-calendar-date {
  background: #a6eaff;
}
.ant-calendar-last-month-cell .ant-calendar-date,
.ant-calendar-next-month-btn-day .ant-calendar-date,
.ant-calendar-last-month-cell .ant-calendar-date:hover,
.ant-calendar-next-month-btn-day .ant-calendar-date:hover {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
}
.ant-calendar-disabled-cell .ant-calendar-date {
  position: relative;
  width: auto;
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: not-allowed;
}
.ant-calendar-disabled-cell .ant-calendar-date:hover {
  background: #f5f5f5;
}
.ant-calendar-disabled-cell.ant-calendar-selected-day .ant-calendar-date::before {
  position: absolute;
  top: -1px;
  left: 5px;
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  content: '';
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
  position: relative;
  padding-right: 5px;
  padding-left: 5px;
}
.ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
  position: absolute;
  top: -1px;
  left: 5px;
  width: 24px;
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  content: ' ';
}
.ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-calendar-footer {
  padding: 0 12px;
  line-height: 38px;
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-footer:empty {
  border-top: 0;
}
.ant-calendar-footer-btn {
  display: block;
  text-align: center;
}
.ant-calendar-footer-extra {
  text-align: left;
}
.ant-calendar .ant-calendar-today-btn,
.ant-calendar .ant-calendar-clear-btn {
  display: inline-block;
  margin: 0 0 0 8px;
  text-align: center;
}
.ant-calendar .ant-calendar-today-btn-disabled,
.ant-calendar .ant-calendar-clear-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-calendar .ant-calendar-today-btn:only-child,
.ant-calendar .ant-calendar-clear-btn:only-child {
  margin: 0;
}
.ant-calendar .ant-calendar-clear-btn {
  position: absolute;
  top: 7px;
  right: 5px;
  display: none;
  width: 20px;
  height: 20px;
  margin: 0;
  overflow: hidden;
  line-height: 20px;
  text-align: center;
  text-indent: -76px;
}
.ant-calendar .ant-calendar-clear-btn::after {
  display: inline-block;
  width: 20px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 1;
  text-indent: 43px;
  transition: color 0.3s ease;
}
.ant-calendar .ant-calendar-clear-btn:hover::after {
  color: #595959;
}
.ant-calendar .ant-calendar-ok-btn {
  position: relative;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
          user-select: none;
  touch-action: manipulation;
  display: inline-block;
  font-weight: 400;
  color: #545454;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: solid 1px #d9d9d9;
  padding: 0 15px;
  height: 36px;
  line-height: 34px;
  border-radius: 4px;
  margin-bottom: 15px;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  color: #ffffff !important;
  background-color: #038fde;
  border-color: #038fde;
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 6px;
  line-height: 22px;
}
.ant-calendar .ant-calendar-ok-btn > .anticon {
  line-height: 1;
}
.ant-calendar .ant-calendar-ok-btn,
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn:focus {
  outline: 0;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled] {
  cursor: not-allowed;
}
.ant-calendar .ant-calendar-ok-btn.disabled > *,
.ant-calendar .ant-calendar-ok-btn[disabled] > * {
  pointer-events: none;
}
.ant-calendar .ant-calendar-ok-btn-lg {
  height: 40px;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
}
.ant-calendar .ant-calendar-ok-btn-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 12px;
  border-radius: 4px;
}
.ant-calendar .ant-calendar-ok-btn:not(:last-child) {
  margin-right: 15px;
}
@media screen and (max-width: 575px) {
  .ant-calendar .ant-calendar-ok-btn:not(:last-child) {
    margin-right: 10px;
  }
}
@media screen and (max-width: 575px) {
  .ant-calendar .ant-calendar-ok-btn {
    margin-bottom: 10px;
  }
}
.ant-calendar .ant-calendar-ok-btn:focus,
.ant-calendar .ant-calendar-ok-btn:hover {
  text-decoration: none;
}
.ant-calendar .ant-calendar-ok-btn:focus,
.ant-calendar .ant-calendar-ok-btn.focus {
  outline: 0;
}
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn:disabled {
  opacity: 0.5;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):not(.disabled) {
  cursor: pointer;
}
.gx-btn-list .ant-calendar .ant-calendar-ok-btn {
  margin-bottom: 6px;
}
.gx-sub-popover .ant-calendar .ant-calendar-ok-btn {
  border: 0 none;
  margin-bottom: 5px;
}
.gx-sub-popover .ant-calendar .ant-calendar-ok-btn:focus,
.gx-sub-popover .ant-calendar .ant-calendar-ok-btn:hover {
  background-color: #f5f5f5;
}
.gx-sub-popover .ant-calendar .ant-calendar-ok-btn:not(:last-child) {
  margin-right: 5px;
}
.gx-login-content .ant-calendar .ant-calendar-ok-btn {
  padding: 9px 20px !important;
  height: auto;
  line-height: 1;
}
.ant-calendar .ant-calendar-ok-btn > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn:hover,
.ant-calendar .ant-calendar-ok-btn:focus {
  color: #fff;
  background-color: #28aaeb;
  border-color: #28aaeb;
}
.ant-calendar .ant-calendar-ok-btn:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn:focus > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn:active,
.ant-calendar .ant-calendar-ok-btn.active {
  color: #fff;
  background-color: #006eb8;
  border-color: #006eb8;
}
.ant-calendar .ant-calendar-ok-btn:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn-disabled,
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn-disabled:hover,
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover,
.ant-calendar .ant-calendar-ok-btn-disabled:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn-disabled:active,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn-disabled.active,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar .ant-calendar-ok-btn:hover,
.ant-calendar .ant-calendar-ok-btn:focus {
  color: #ffffff !important;
  background-color: #038fde;
  border-color: #038fde;
}
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn:disabled {
  background-color: #038fde !important;
  border-color: #038fde !important;
}
.ant-calendar .ant-calendar-ok-btn:not([disabled]):not(.disabled):active,
.ant-calendar .ant-calendar-ok-btn:not([disabled]):not(.disabled).active {
  color: #ffffff;
  background-color: #038fde !important;
  border-color: #038fde !important;
}
.ant-calendar .ant-calendar-ok-btn-disabled,
.ant-calendar .ant-calendar-ok-btn.disabled,
.ant-calendar .ant-calendar-ok-btn[disabled],
.ant-calendar .ant-calendar-ok-btn-disabled:hover,
.ant-calendar .ant-calendar-ok-btn.disabled:hover,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover,
.ant-calendar .ant-calendar-ok-btn-disabled:focus,
.ant-calendar .ant-calendar-ok-btn.disabled:focus,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus,
.ant-calendar .ant-calendar-ok-btn-disabled:active,
.ant-calendar .ant-calendar-ok-btn.disabled:active,
.ant-calendar .ant-calendar-ok-btn[disabled]:active,
.ant-calendar .ant-calendar-ok-btn-disabled.active,
.ant-calendar .ant-calendar-ok-btn.disabled.active,
.ant-calendar .ant-calendar-ok-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child {
  color: currentColor;
}
.ant-calendar .ant-calendar-ok-btn-disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child::after,
.ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-calendar-range-picker-input {
  width: 44%;
  height: 99%;
  text-align: center;
  background-color: transparent;
  border: 0;
  outline: 0;
}
.ant-calendar-range-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-range-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range-picker-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-range-picker-input[disabled] {
  cursor: not-allowed;
}
.ant-calendar-range-picker-separator {
  display: inline-block;
  min-width: 10px;
  height: 100%;
  color: #595959;
  white-space: nowrap;
  text-align: center;
  vertical-align: top;
  pointer-events: none;
}
.ant-calendar-range {
  width: 552px;
  overflow: hidden;
}
.ant-calendar-range .ant-calendar-date-panel::after {
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
  content: '.';
}
.ant-calendar-range-part {
  position: relative;
  width: 50%;
}
.ant-calendar-range-left {
  float: left;
}
.ant-calendar-range-left .ant-calendar-time-picker-inner {
  border-right: 1px solid #e8e8e8;
}
.ant-calendar-range-right {
  float: right;
}
.ant-calendar-range-right .ant-calendar-time-picker-inner {
  border-left: 1px solid #e8e8e8;
}
.ant-calendar-range-middle {
  position: absolute;
  left: 50%;
  z-index: 1;
  height: 34px;
  margin: 1px 0 0 0;
  padding: 0 200px 0 0;
  color: #595959;
  line-height: 34px;
  text-align: center;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  pointer-events: none;
}
.ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: -90px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-range-middle {
  padding: 0 10px 0 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ant-calendar-range .ant-calendar-today :not(.ant-calendar-disabled-cell) :not(.ant-calendar-last-month-cell) :not(.ant-calendar-next-month-btn-day) .ant-calendar-date {
  color: #038fde;
  background: #a6eaff;
  border-color: #038fde;
}
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  color: #fff;
  background: #038fde;
  border: 1px solid transparent;
}
.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover {
  background: #038fde;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-range-right .ant-calendar-date-input-wrap {
  margin-left: 0;
}
.ant-calendar-range .ant-calendar-input-wrap {
  position: relative;
  height: 34px;
}
.ant-calendar-range .ant-calendar-input,
.ant-calendar-range .ant-calendar-time-picker-input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 36px;
  padding: 4px 11px;
  color: #545454;
  font-size: 14px;
  line-height: 1.3;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
  line-height: 24px;
  border: 0;
  box-shadow: none;
}
.ant-calendar-range .ant-calendar-input::-moz-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input:-ms-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range .ant-calendar-input::-webkit-input-placeholder,
.ant-calendar-range .ant-calendar-time-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-calendar-range .ant-calendar-input:placeholder-shown,
.ant-calendar-range .ant-calendar-time-picker-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-calendar-range .ant-calendar-input:hover,
.ant-calendar-range .ant-calendar-time-picker-input:hover {
  border-color: #28aaeb;
  border-right-width: 1px !important;
}
.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
  border-color: #28aaeb;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
}
.ant-calendar-range .ant-calendar-input-disabled,
.ant-calendar-range .ant-calendar-time-picker-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input-disabled:hover,
.ant-calendar-range .ant-calendar-time-picker-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-calendar-range .ant-calendar-input[disabled],
.ant-calendar-range .ant-calendar-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-calendar-range .ant-calendar-input[disabled]:hover,
.ant-calendar-range .ant-calendar-time-picker-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-calendar-range .ant-calendar-input,
textarea.ant-calendar-range .ant-calendar-time-picker-input {
  max-width: 100%;
  height: auto;
  min-height: 36px;
  line-height: 1.3;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-calendar-range .ant-calendar-input-lg,
.ant-calendar-range .ant-calendar-time-picker-input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-calendar-range .ant-calendar-input-sm,
.ant-calendar-range .ant-calendar-time-picker-input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-calendar-range .ant-calendar-input:focus,
.ant-calendar-range .ant-calendar-time-picker-input:focus {
  box-shadow: none;
}
.ant-calendar-range .ant-calendar-time-picker-icon {
  display: none;
}
.ant-calendar-range.ant-calendar-week-number {
  width: 574px;
}
.ant-calendar-range.ant-calendar-week-number .ant-calendar-range-part {
  width: 286px;
}
.ant-calendar-range .ant-calendar-year-panel,
.ant-calendar-range .ant-calendar-month-panel,
.ant-calendar-range .ant-calendar-decade-panel {
  top: 34px;
}
.ant-calendar-range .ant-calendar-month-panel .ant-calendar-year-panel {
  top: 0;
}
.ant-calendar-range .ant-calendar-decade-panel-table,
.ant-calendar-range .ant-calendar-year-panel-table,
.ant-calendar-range .ant-calendar-month-panel-table {
  height: 208px;
}
.ant-calendar-range .ant-calendar-in-range-cell {
  position: relative;
  border-radius: 0;
}
.ant-calendar-range .ant-calendar-in-range-cell > div {
  position: relative;
  z-index: 1;
}
.ant-calendar-range .ant-calendar-in-range-cell::before {
  position: absolute;
  top: 4px;
  right: 0;
  bottom: 4px;
  left: 0;
  display: block;
  background: #e6faff;
  border: 0;
  border-radius: 0;
  content: '';
}
.ant-calendar-range .ant-calendar-footer-extra {
  float: left;
}
div.ant-calendar-range-quick-selector {
  text-align: left;
}
div.ant-calendar-range-quick-selector > a {
  margin-right: 8px;
}
.ant-calendar-range .ant-calendar-header,
.ant-calendar-range .ant-calendar-month-panel-header,
.ant-calendar-range .ant-calendar-year-panel-header,
.ant-calendar-range .ant-calendar-decade-panel-header {
  border-bottom: 0;
}
.ant-calendar-range .ant-calendar-body,
.ant-calendar-range .ant-calendar-month-panel-body,
.ant-calendar-range .ant-calendar-year-panel-body,
.ant-calendar-range .ant-calendar-decade-panel-body {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker {
  top: 68px;
  z-index: 2;
  width: 100%;
  height: 207px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-panel {
  height: 267px;
  margin-top: -34px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-inner {
  height: 100%;
  padding-top: 40px;
  background: none;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-combobox {
  display: inline-block;
  height: 100%;
  background-color: #fff;
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select {
  height: 100%;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select ul {
  max-height: 100%;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  margin-right: 8px;
}
.ant-calendar-range.ant-calendar-time .ant-calendar-today-btn {
  height: 22px;
  margin: 8px 12px;
  line-height: 22px;
}
.ant-calendar-range-with-ranges.ant-calendar-time .ant-calendar-time-picker {
  height: 233px;
}
.ant-calendar-range.ant-calendar-show-time-picker .ant-calendar-body {
  border-top-color: transparent;
}
.ant-calendar-time-picker {
  position: absolute;
  top: 40px;
  width: 100%;
  background-color: #fff;
}
.ant-calendar-time-picker-panel {
  position: absolute;
  z-index: 1050;
  width: 100%;
}
.ant-calendar-time-picker-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  outline: none;
}
.ant-calendar-time-picker-combobox {
  width: 100%;
}
.ant-calendar-time-picker-column-1,
.ant-calendar-time-picker-column-1 .ant-calendar-time-picker-select {
  width: 100%;
}
.ant-calendar-time-picker-column-2 .ant-calendar-time-picker-select {
  width: 50%;
}
.ant-calendar-time-picker-column-3 .ant-calendar-time-picker-select {
  width: 33.33%;
}
.ant-calendar-time-picker-column-4 .ant-calendar-time-picker-select {
  width: 25%;
}
.ant-calendar-time-picker-input-wrap {
  display: none;
}
.ant-calendar-time-picker-select {
  position: relative;
  float: left;
  height: 226px;
  overflow: hidden;
  font-size: 14px;
  border-right: 1px solid #e8e8e8;
}
.ant-calendar-time-picker-select:hover {
  overflow-y: auto;
}
.ant-calendar-time-picker-select:first-child {
  margin-left: 0;
  border-left: 0;
}
.ant-calendar-time-picker-select:last-child {
  border-right: 0;
}
.ant-calendar-time-picker-select ul {
  width: 100%;
  max-height: 206px;
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-calendar-time-picker-select li {
  width: 100%;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  list-style: none;
  cursor: pointer;
  transition: all .3s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-calendar-time-picker-select li:last-child::after {
  display: block;
  height: 202px;
  content: '';
}
.ant-calendar-time-picker-select li:hover {
  background: #e6faff;
}
.ant-calendar-time-picker-select li:focus {
  color: #038fde;
  font-weight: 600;
  outline: none;
}
li.ant-calendar-time-picker-select-option-selected {
  font-weight: 600;
  background: #f5f5f5;
}
li.ant-calendar-time-picker-select-option-disabled {
  color: rgba(0, 0, 0, 0.25);
}
li.ant-calendar-time-picker-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
.ant-calendar-time .ant-calendar-day-select {
  display: inline-block;
  padding: 0 2px;
  color: #535353;
  font-weight: 500;
  line-height: 34px;
}
.ant-calendar-time .ant-calendar-footer {
  position: relative;
  height: auto;
}
.ant-calendar-time .ant-calendar-footer-btn {
  text-align: right;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
  float: left;
  margin: 0;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
  display: inline-block;
  margin-right: 8px;
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
}
.ant-calendar-month-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  border-radius: 6px;
  outline: none;
}
.ant-calendar-month-panel > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ant-calendar-month-panel-hidden {
  display: none;
}
.ant-calendar-month-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
}
.ant-calendar-month-panel-header a:hover {
  color: #28aaeb;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: #535353;
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-century-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-arrow,
.ant-calendar-month-panel-header .ant-calendar-month-panel-month-select-arrow {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: #595959;
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn:hover::after {
  border-color: #545454;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn:hover::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn:hover::after {
  border-color: #545454;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn::after,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn:hover::after {
  border-color: #545454;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:hover::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn:hover::after {
  border-color: #545454;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::after {
  display: none;
}
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::before,
.ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-month-panel-body {
  flex: 1 1;
}
.ant-calendar-month-panel-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-month-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-month-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  color: #fff;
  background: #038fde;
}
.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
  color: #fff;
  background: #038fde;
}
.ant-calendar-month-panel-cell {
  text-align: center;
}
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month,
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month:hover {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-calendar-month-panel-month {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 8px;
  color: #545454;
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 4px;
  transition: background 0.3s ease;
}
.ant-calendar-month-panel-month:hover {
  background: #e6faff;
  cursor: pointer;
}
.ant-calendar-year-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  border-radius: 6px;
  outline: none;
}
.ant-calendar-year-panel > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ant-calendar-year-panel-hidden {
  display: none;
}
.ant-calendar-year-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
}
.ant-calendar-year-panel-header a:hover {
  color: #28aaeb;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: #535353;
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-century-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-year-select-arrow,
.ant-calendar-year-panel-header .ant-calendar-year-panel-month-select-arrow {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: #595959;
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:hover::after {
  border-color: #545454;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn:hover::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:hover::after {
  border-color: #545454;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn::after,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn:hover::after {
  border-color: #545454;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:hover::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn:hover::after {
  border-color: #545454;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  display: none;
}
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::before,
.ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-year-panel-body {
  flex: 1 1;
}
.ant-calendar-year-panel-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-year-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-year-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-year-panel-cell {
  text-align: center;
}
.ant-calendar-year-panel-year {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 8px;
  color: #545454;
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 4px;
  transition: background 0.3s ease;
}
.ant-calendar-year-panel-year:hover {
  background: #e6faff;
  cursor: pointer;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
  color: #fff;
  background: #038fde;
}
.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
  color: #fff;
  background: #038fde;
}
.ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
.ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year {
  color: rgba(0, 0, 0, 0.25);
  -webkit-user-select: none;
          user-select: none;
}
.ant-calendar-decade-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  outline: none;
}
.ant-calendar-decade-panel-hidden {
  display: none;
}
.ant-calendar-decade-panel-header {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
}
.ant-calendar-decade-panel-header a:hover {
  color: #28aaeb;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select {
  display: inline-block;
  padding: 0 2px;
  color: #535353;
  font-weight: 500;
  line-height: 40px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select-arrow,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select-arrow {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  position: absolute;
  top: 0;
  display: inline-block;
  padding: 0 5px;
  color: #595959;
  font-size: 16px;
  font-family: Arial, 'Hiragino Sans GB', 'Microsoft Yahei', 'Microsoft Sans Serif', sans-serif;
  line-height: 40px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
  left: 7px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn:hover::after {
  border-color: #545454;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn::after {
  position: relative;
  left: -3px;
  display: inline-block;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  right: 7px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn:hover::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn:hover::after {
  border-color: #545454;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::before {
  position: relative;
  left: 3px;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn::after,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn::after {
  display: inline-block;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn {
  left: 29px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn:hover::after {
  border-color: #545454;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn {
  right: 29px;
  height: 100%;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::after {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border: 0 solid #aaa;
  border-width: 1.5px 0 0 1.5px;
  border-radius: 1px;
  -webkit-transform: rotate(-45deg) scale(0.8);
          transform: rotate(-45deg) scale(0.8);
  transition: all 0.3s;
  content: '';
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:hover::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn:hover::after {
  border-color: #545454;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::after {
  display: none;
}
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::before,
.ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn::after {
  -webkit-transform: rotate(135deg) scale(0.8);
          transform: rotate(135deg) scale(0.8);
}
.ant-calendar-decade-panel-body {
  flex: 1 1;
}
.ant-calendar-decade-panel-footer {
  border-top: 1px solid #e8e8e8;
}
.ant-calendar-decade-panel-footer .ant-calendar-footer-extra {
  padding: 0 12px;
}
.ant-calendar-decade-panel-table {
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.ant-calendar-decade-panel-cell {
  white-space: nowrap;
  text-align: center;
}
.ant-calendar-decade-panel-decade {
  display: inline-block;
  height: 24px;
  margin: 0 auto;
  padding: 0 6px;
  color: #545454;
  line-height: 24px;
  text-align: center;
  background: transparent;
  border-radius: 4px;
  transition: background 0.3s ease;
}
.ant-calendar-decade-panel-decade:hover {
  background: #e6faff;
  cursor: pointer;
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
  color: #fff;
  background: #038fde;
}
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
  color: #fff;
  background: #038fde;
}
.ant-calendar-decade-panel-last-century-cell .ant-calendar-decade-panel-decade,
.ant-calendar-decade-panel-next-century-cell .ant-calendar-decade-panel-decade {
  color: rgba(0, 0, 0, 0.25);
  -webkit-user-select: none;
          user-select: none;
}
.ant-calendar-month .ant-calendar-month-header-wrap {
  position: relative;
  height: 288px;
}
.ant-calendar-month .ant-calendar-month-panel,
.ant-calendar-month .ant-calendar-year-panel {
  top: 0;
  height: 100%;
}
.ant-calendar-week-number-cell {
  opacity: 0.5;
}
.ant-calendar-week-number .ant-calendar-body tr {
  cursor: pointer;
  transition: all 0.3s;
}
.ant-calendar-week-number .ant-calendar-body tr:hover {
  background: #e6faff;
}
.ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
  font-weight: bold;
  background: #a6eaff;
}
.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day .ant-calendar-date,
.ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day:hover .ant-calendar-date {
  color: #545454;
  background: transparent;
}
.ant-descriptions-title {
  margin-bottom: 20px;
  color: #535353;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.3;
}
.ant-descriptions-view {
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
}
.ant-descriptions-view table {
  width: 100%;
  table-layout: fixed;
}
.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 16px;
}
.ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-item-label {
  color: #535353;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.3;
}
.ant-descriptions-item-label::after {
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
  content: ' ';
}
.ant-descriptions-item-colon::after {
  content: ':';
}
.ant-descriptions-item-no-label::after {
  margin: 0;
  content: '';
}
.ant-descriptions-item-content {
  display: table-cell;
  color: #545454;
  font-size: 14px;
  line-height: 1.3;
}
.ant-descriptions-item {
  padding-bottom: 0;
}
.ant-descriptions-item > span {
  display: inline-block;
}
.ant-descriptions-middle .ant-descriptions-row > th,
.ant-descriptions-middle .ant-descriptions-row > td {
  padding-bottom: 12px;
}
.ant-descriptions-small .ant-descriptions-row > th,
.ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 8px;
}
.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #e8e8e8;
}
.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: auto;
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px 24px;
  border-right: 1px solid #e8e8e8;
}
.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-right: none;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}
.ant-descriptions-bordered .ant-descriptions-item-label::after {
  display: none;
}
.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #e8e8e8;
}
.ant-descriptions-bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 12px 24px;
}
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 8px 16px;
}
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  background: #e8e8e8;
}
.ant-divider,
.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  width: 1px;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
}
.ant-divider-horizontal {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
  margin: 24px 0;
}
.ant-divider-horizontal.ant-divider-with-text-center,
.ant-divider-horizontal.ant-divider-with-text-left,
.ant-divider-horizontal.ant-divider-with-text-right {
  display: table;
  margin: 16px 0;
  color: #535353;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  background: transparent;
}
.ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-left::before,
.ant-divider-horizontal.ant-divider-with-text-right::before,
.ant-divider-horizontal.ant-divider-with-text-center::after,
.ant-divider-horizontal.ant-divider-with-text-left::after,
.ant-divider-horizontal.ant-divider-with-text-right::after {
  position: relative;
  top: 50%;
  display: table-cell;
  width: 50%;
  border-top: 1px solid #e8e8e8;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  content: '';
}
.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text,
.ant-divider-horizontal.ant-divider-with-text-right .ant-divider-inner-text {
  display: inline-block;
  padding: 0 10px;
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 5%;
}
.ant-divider-horizontal.ant-divider-with-text-left::after {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::before {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::after {
  top: 50%;
  width: 5%;
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 24px;
}
.ant-divider-dashed {
  background: none;
  border-color: #e8e8e8;
  border-style: dashed;
  border-width: 1px 0 0;
}
.ant-divider-horizontal.ant-divider-with-text-center.ant-divider-dashed,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed {
  border-top: 0;
}
.ant-divider-horizontal.ant-divider-with-text-center.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text-center.ant-divider-dashed::after,
.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed::after,
.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed::after {
  border-style: dashed none none;
}
.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px;
}
.ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  transition: height 0s ease 0.3s, width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s, -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer > * {
  transition: box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-content-wrapper {
  position: absolute;
}
.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}
.ant-drawer-left,
.ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-left.ant-drawer-open.no-mask,
.ant-drawer-right.ant-drawer-open.no-mask {
  width: 0%;
}
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer-right {
  right: 0;
}
.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer-right.ant-drawer-open.no-mask {
  right: 1px;
  -webkit-transform: translateX(1px);
          transform: translateX(1px);
}
.ant-drawer-top,
.ant-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}
.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}
.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
  transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-top.ant-drawer-open.no-mask,
.ant-drawer-bottom.ant-drawer-open.no-mask {
  height: 0%;
}
.ant-drawer-top {
  top: 0;
}
.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer-bottom {
  bottom: 0;
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}
.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 1px;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 1;
  transition: none;
  -webkit-animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
          animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-title {
  margin: 0;
  color: #535353;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}
.ant-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  width: 56px;
  height: 56px;
  padding: 0;
  color: #595959;
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: #545454;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 6px 6px 0 0;
}
.ant-drawer-header-no-title {
  color: #545454;
  background: #fff;
}
.ant-drawer-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.3;
  word-wrap: break-word;
}
.ant-drawer-wrapper-body {
  height: 100%;
  overflow: auto;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  filter: alpha(opacity=45);
  transition: opacity 0.3s linear, height 0s ease 0.3s;
}
.ant-drawer-open-content {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
@-webkit-keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-dropdown::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: -7px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 12px;
}
.ant-dropdown-wrap .anticon-down::before {
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down::before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden {
  display: none;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 6px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate3d(0, 0, 0);
}
.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: #595959;
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
}
.ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none;
}
.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
  padding: 0;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: #545454;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
}
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  display: block;
  margin: -5px -12px;
  padding: 5px 12px;
  color: #545454;
  transition: all 0.3s;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #038fde;
  background-color: #e6faff;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #e6faff;
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: #595959;
  font-style: normal;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
:root .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  font-size: 12px;
}
.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 26px;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #038fde;
}
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-trigger > .anticon.anticon-down,
:root .ant-dropdown-link > .anticon.anticon-down {
  font-size: 12px;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-dropdown-button .anticon.anticon-down {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-dropdown-button .anticon.anticon-down {
  font-size: 12px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #fffffe;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover {
  color: #fff;
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #038fde;
}
.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}
.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.ant-empty-image img {
  height: 100%;
}
.ant-empty-image svg {
  height: 100%;
  margin: auto;
}
.ant-empty-description {
  margin: 0;
}
.ant-empty-footer {
  margin-top: 16px;
}
.ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-normal .ant-empty-image {
  height: 40px;
}
.ant-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-small .ant-empty-image {
  height: 35px;
}
.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
}
.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: #595959;
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}
.ant-form label {
  font-size: 14px;
}
.ant-form input[type='search'] {
  box-sizing: border-box;
}
.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
  line-height: normal;
}
.ant-form input[type='file'] {
  display: block;
}
.ant-form input[type='range'] {
  display: block;
  width: 100%;
}
.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}
.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ant-form output {
  display: block;
  padding-top: 15px;
  color: #545454;
  font-size: 14px;
  line-height: 1.3;
}
.ant-form-item-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-form-hide-required-mark .ant-form-item-required::before {
  display: none;
}
.ant-form-item-label > label {
  color: #535353;
}
.ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' ';
}
.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  margin-bottom: 20px;
  vertical-align: top;
}
.ant-form-item label {
  position: relative;
}
.ant-form-item label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.ant-form-item-control {
  position: relative;
  line-height: 40px;
  zoom: 1;
}
.ant-form-item-control::before,
.ant-form-item-control::after {
  display: table;
  content: '';
}
.ant-form-item-control::after {
  clear: both;
}
.ant-form-item-control::before,
.ant-form-item-control::after {
  display: table;
  content: '';
}
.ant-form-item-control::after {
  clear: both;
}
.ant-form-item-children {
  position: relative;
}
.ant-form-item-with-help {
  margin-bottom: 2px;
}
.ant-form-item-label {
  display: inline-block;
  overflow: hidden;
  line-height: 39.9999px;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.ant-form-item-label-left {
  text-align: left;
}
.ant-form-item .ant-switch {
  margin: 2px 0 4px;
}
.ant-form-explain,
.ant-form-extra {
  clear: both;
  min-height: 19px;
  margin-top: 0px;
  color: #595959;
  font-size: 14px;
  line-height: 1.3;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-form-explain {
  margin-bottom: -1px;
}
.ant-form-extra {
  padding-top: 4px;
}
.ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-split {
  display: block;
  text-align: center;
}
form .has-feedback .ant-input {
  padding-right: 30px;
}
form .has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 18px;
}
form .has-feedback .ant-input-affix-wrapper .ant-input {
  padding-right: 49px;
}
form .has-feedback .ant-input-affix-wrapper.ant-input-affix-wrapper-input-with-clear-btn .ant-input {
  padding-right: 68px;
}
form .has-feedback > .ant-select .ant-select-arrow,
form .has-feedback > .ant-select .ant-select-selection__clear,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection__clear {
  right: 30px;
}
form .has-feedback > .ant-select .ant-select-selection-selected-value,
form .has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 42px;
}
form .has-feedback .ant-cascader-picker-arrow {
  margin-right: 19px;
}
form .has-feedback .ant-cascader-picker-clear {
  right: 30px;
}
form .has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: 30px;
}
form .has-feedback .ant-calendar-picker-icon,
form .has-feedback .ant-time-picker-icon,
form .has-feedback .ant-calendar-picker-clear,
form .has-feedback .ant-time-picker-clear {
  right: 30px;
}
form .ant-mentions,
form textarea.ant-input {
  height: auto;
  margin-bottom: 4px;
}
form .ant-upload {
  background: transparent;
}
form input[type='radio'],
form input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
form .ant-radio-inline,
form .ant-checkbox-inline {
  display: inline-block;
  margin-left: 8px;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}
form .ant-radio-inline:first-child,
form .ant-checkbox-inline:first-child {
  margin-left: 0;
}
form .ant-checkbox-vertical,
form .ant-radio-vertical {
  display: block;
}
form .ant-checkbox-vertical + .ant-checkbox-vertical,
form .ant-radio-vertical + .ant-radio-vertical {
  margin-left: 0;
}
form .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
form .ant-input-number-handler-wrap {
  z-index: 2;
}
form .ant-select,
form .ant-cascader-picker {
  width: 100%;
}
form .ant-input-group .ant-select,
form .ant-input-group .ant-cascader-picker {
  width: auto;
}
form :not(.ant-input-group-wrapper) > .ant-input-group,
form .ant-input-group-wrapper {
  display: inline-block;
  vertical-align: middle;
}
form:not(.ant-form-vertical) :not(.ant-input-group-wrapper) > .ant-input-group,
form:not(.ant-form-vertical) .ant-input-group-wrapper {
  position: relative;
  top: -1px;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  display: block;
  margin: 0;
  padding: 0 0 8px;
  line-height: 1.3;
  white-space: initial;
  text-align: left;
}
.ant-form-vertical .ant-form-item-label label::after,
.ant-col-24.ant-form-item-label label::after,
.ant-col-xl-24.ant-form-item-label label::after {
  display: none;
}
.ant-form-vertical .ant-form-item {
  padding-bottom: 8px;
}
.ant-form-vertical .ant-form-item-control {
  line-height: 1.3;
}
.ant-form-vertical .ant-form-explain {
  margin-top: 2px;
  margin-bottom: -5px;
}
.ant-form-vertical .ant-form-extra {
  margin-top: 2px;
  margin-bottom: -4px;
}
@media (max-width: 575px) {
  .ant-form-item-label,
  .ant-form-item-control-wrapper {
    display: block;
    width: 100%;
  }
  .ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.3;
    white-space: initial;
    text-align: left;
  }
  .ant-form-item-label label::after {
    display: none;
  }
  .ant-col-xs-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.3;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xs-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.3;
    white-space: initial;
    text-align: left;
  }
  .ant-col-sm-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.3;
    white-space: initial;
    text-align: left;
  }
  .ant-col-md-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.3;
    white-space: initial;
    text-align: left;
  }
  .ant-col-lg-24.ant-form-item-label label::after {
    display: none;
  }
}
@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    display: block;
    margin: 0;
    padding: 0 0 8px;
    line-height: 1.3;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xl-24.ant-form-item-label label::after {
    display: none;
  }
}
.ant-form-inline .ant-form-item {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 20px;
}
.ant-form-inline .ant-form-item > .ant-form-item-control-wrapper,
.ant-form-inline .ant-form-item > .ant-form-item-label {
  display: inline-block;
  vertical-align: top;
}
.ant-form-inline .ant-form-text {
  display: inline-block;
}
.ant-form-inline .has-feedback {
  display: inline-block;
}
.has-success.has-feedback .ant-form-item-children-icon,
.has-warning.has-feedback .ant-form-item-children-icon,
.has-error.has-feedback .ant-form-item-children-icon,
.is-validating.has-feedback .ant-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 36px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
          animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.has-success.has-feedback .ant-form-item-children-icon svg,
.has-warning.has-feedback .ant-form-item-children-icon svg,
.has-error.has-feedback .ant-form-item-children-icon svg,
.is-validating.has-feedback .ant-form-item-children-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.has-success.has-feedback .ant-form-item-children-icon {
  color: #52c41a;
  -webkit-animation-name: diffZoomIn1 !important;
          animation-name: diffZoomIn1 !important;
}
.has-warning .ant-form-explain,
.has-warning .ant-form-split {
  color: #fa8c16;
}
.has-warning .ant-input,
.has-warning .ant-input:hover {
  background-color: #fff;
  border-color: #fa8c16;
}
.has-warning .ant-input:focus {
  border-color: #ffa940;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 140, 22, 0.2);
}
.has-warning .ant-input:not([disabled]):hover {
  border-color: #fa8c16;
}
.has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ffa940;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 140, 22, 0.2);
}
.has-warning .ant-input-affix-wrapper .ant-input,
.has-warning .ant-input-affix-wrapper .ant-input:hover {
  background-color: #fff;
  border-color: #fa8c16;
}
.has-warning .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ffa940;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 140, 22, 0.2);
}
.has-warning .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #fa8c16;
}
.has-warning .ant-input-prefix {
  color: #fa8c16;
}
.has-warning .ant-input-group-addon {
  color: #fa8c16;
  background-color: #fff;
  border-color: #fa8c16;
}
.has-warning .has-feedback {
  color: #fa8c16;
}
.has-warning.has-feedback .ant-form-item-children-icon {
  color: #fa8c16;
  -webkit-animation-name: diffZoomIn3 !important;
          animation-name: diffZoomIn3 !important;
}
.has-warning .ant-select-selection {
  border-color: #fa8c16;
}
.has-warning .ant-select-selection:hover {
  border-color: #fa8c16;
}
.has-warning .ant-select-open .ant-select-selection,
.has-warning .ant-select-focused .ant-select-selection {
  border-color: #ffa940;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 140, 22, 0.2);
}
.has-warning .ant-calendar-picker-icon::after,
.has-warning .ant-time-picker-icon::after,
.has-warning .ant-picker-icon::after,
.has-warning .ant-select-arrow,
.has-warning .ant-cascader-picker-arrow {
  color: #fa8c16;
}
.has-warning .ant-input-number,
.has-warning .ant-time-picker-input {
  border-color: #fa8c16;
}
.has-warning .ant-input-number-focused,
.has-warning .ant-time-picker-input-focused,
.has-warning .ant-input-number:focus,
.has-warning .ant-time-picker-input:focus {
  border-color: #ffa940;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 140, 22, 0.2);
}
.has-warning .ant-input-number:not([disabled]):hover,
.has-warning .ant-time-picker-input:not([disabled]):hover {
  border-color: #fa8c16;
}
.has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ffa940;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(250, 140, 22, 0.2);
}
.has-warning .ant-cascader-picker:hover .ant-cascader-input {
  border-color: #fa8c16;
}
.has-error .ant-form-explain,
.has-error .ant-form-split {
  color: #f5222d;
}
.has-error .ant-input,
.has-error .ant-input:hover {
  background-color: #fff;
  border-color: #f5222d;
}
.has-error .ant-input:focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input:not([disabled]):hover {
  border-color: #f5222d;
}
.has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input-affix-wrapper .ant-input,
.has-error .ant-input-affix-wrapper .ant-input:hover {
  background-color: #fff;
  border-color: #f5222d;
}
.has-error .ant-input-affix-wrapper .ant-input:focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #f5222d;
}
.has-error .ant-input-prefix {
  color: #f5222d;
}
.has-error .ant-input-group-addon {
  color: #f5222d;
  background-color: #fff;
  border-color: #f5222d;
}
.has-error .has-feedback {
  color: #f5222d;
}
.has-error.has-feedback .ant-form-item-children-icon {
  color: #f5222d;
  -webkit-animation-name: diffZoomIn2 !important;
          animation-name: diffZoomIn2 !important;
}
.has-error .ant-select-selection {
  border-color: #f5222d;
}
.has-error .ant-select-selection:hover {
  border-color: #f5222d;
}
.has-error .ant-select-open .ant-select-selection,
.has-error .ant-select-focused .ant-select-selection {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: #f5222d;
}
.has-error .ant-input-group-addon .ant-select-selection {
  border-color: transparent;
  box-shadow: none;
}
.has-error .ant-calendar-picker-icon::after,
.has-error .ant-time-picker-icon::after,
.has-error .ant-picker-icon::after,
.has-error .ant-select-arrow,
.has-error .ant-cascader-picker-arrow {
  color: #f5222d;
}
.has-error .ant-input-number,
.has-error .ant-time-picker-input {
  border-color: #f5222d;
}
.has-error .ant-input-number-focused,
.has-error .ant-time-picker-input-focused,
.has-error .ant-input-number:focus,
.has-error .ant-time-picker-input:focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-input-number:not([disabled]):hover,
.has-error .ant-time-picker-input:not([disabled]):hover {
  border-color: #f5222d;
}
.has-error .ant-mention-wrapper .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
  border-color: #f5222d;
}
.has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
.has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ff4d4f;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}
.has-error .ant-cascader-picker:hover .ant-cascader-input {
  border-color: #f5222d;
}
.has-error .ant-transfer-list {
  border-color: #f5222d;
}
.has-error .ant-transfer-list-search:not([disabled]) {
  border-color: #d9d9d9;
}
.has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #28aaeb;
  border-right-width: 1px !important;
}
.has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #28aaeb;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
}
.is-validating.has-feedback .ant-form-item-children-icon {
  display: inline-block;
  color: #038fde;
}
.ant-advanced-search-form .ant-form-item {
  margin-bottom: 20px;
}
.ant-advanced-search-form .ant-form-item-with-help {
  margin-bottom: 2px;
}
.show-help-enter,
.show-help-appear {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.show-help-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.show-help-enter.show-help-enter-active,
.show-help-appear.show-help-appear-active {
  -webkit-animation-name: antShowHelpIn;
          animation-name: antShowHelpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.show-help-leave.show-help-leave-active {
  -webkit-animation-name: antShowHelpOut;
          animation-name: antShowHelpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.show-help-enter,
.show-help-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.show-help-leave {
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
@-webkit-keyframes antShowHelpIn {
  0% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes antShowHelpIn {
  0% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes antShowHelpOut {
  to {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
}
@keyframes antShowHelpOut {
  to {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    opacity: 0;
  }
}
@-webkit-keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.ant-row {
  position: relative;
  height: auto;
  margin-right: -16px;
  margin-left: -16px;
  zoom: 1;
  display: block;
  box-sizing: border-box;
}
.ant-row::before,
.ant-row::after {
  display: table;
  content: '';
}
.ant-row::after {
  clear: both;
}
.ant-row::before,
.ant-row::after {
  display: table;
  content: '';
}
.ant-row::after {
  clear: both;
}
.ant-row + .ant-row::before {
  clear: both;
}
.ant-row-flex {
  display: flex;
  flex-flow: row wrap;
}
.ant-row-flex::before,
.ant-row-flex::after {
  display: flex;
}
.ant-row-flex-start {
  justify-content: flex-start;
}
.ant-row-flex-center {
  justify-content: center;
}
.ant-row-flex-end {
  justify-content: flex-end;
}
.ant-row-flex-space-between {
  justify-content: space-between;
}
.ant-row-flex-space-around {
  justify-content: space-around;
}
.ant-row-flex-top {
  align-items: flex-start;
}
.ant-row-flex-middle {
  align-items: center;
}
.ant-row-flex-bottom {
  align-items: flex-end;
}
.ant-col {
  position: relative;
  min-height: 1px;
}
.ant-col-1, .ant-col-xs-1, .ant-col-sm-1, .ant-col-md-1, .ant-col-lg-1, .ant-col-2, .ant-col-xs-2, .ant-col-sm-2, .ant-col-md-2, .ant-col-lg-2, .ant-col-3, .ant-col-xs-3, .ant-col-sm-3, .ant-col-md-3, .ant-col-lg-3, .ant-col-4, .ant-col-xs-4, .ant-col-sm-4, .ant-col-md-4, .ant-col-lg-4, .ant-col-5, .ant-col-xs-5, .ant-col-sm-5, .ant-col-md-5, .ant-col-lg-5, .ant-col-6, .ant-col-xs-6, .ant-col-sm-6, .ant-col-md-6, .ant-col-lg-6, .ant-col-7, .ant-col-xs-7, .ant-col-sm-7, .ant-col-md-7, .ant-col-lg-7, .ant-col-8, .ant-col-xs-8, .ant-col-sm-8, .ant-col-md-8, .ant-col-lg-8, .ant-col-9, .ant-col-xs-9, .ant-col-sm-9, .ant-col-md-9, .ant-col-lg-9, .ant-col-10, .ant-col-xs-10, .ant-col-sm-10, .ant-col-md-10, .ant-col-lg-10, .ant-col-11, .ant-col-xs-11, .ant-col-sm-11, .ant-col-md-11, .ant-col-lg-11, .ant-col-12, .ant-col-xs-12, .ant-col-sm-12, .ant-col-md-12, .ant-col-lg-12, .ant-col-13, .ant-col-xs-13, .ant-col-sm-13, .ant-col-md-13, .ant-col-lg-13, .ant-col-14, .ant-col-xs-14, .ant-col-sm-14, .ant-col-md-14, .ant-col-lg-14, .ant-col-15, .ant-col-xs-15, .ant-col-sm-15, .ant-col-md-15, .ant-col-lg-15, .ant-col-16, .ant-col-xs-16, .ant-col-sm-16, .ant-col-md-16, .ant-col-lg-16, .ant-col-17, .ant-col-xs-17, .ant-col-sm-17, .ant-col-md-17, .ant-col-lg-17, .ant-col-18, .ant-col-xs-18, .ant-col-sm-18, .ant-col-md-18, .ant-col-lg-18, .ant-col-19, .ant-col-xs-19, .ant-col-sm-19, .ant-col-md-19, .ant-col-lg-19, .ant-col-20, .ant-col-xs-20, .ant-col-sm-20, .ant-col-md-20, .ant-col-lg-20, .ant-col-21, .ant-col-xs-21, .ant-col-sm-21, .ant-col-md-21, .ant-col-lg-21, .ant-col-22, .ant-col-xs-22, .ant-col-sm-22, .ant-col-md-22, .ant-col-lg-22, .ant-col-23, .ant-col-xs-23, .ant-col-sm-23, .ant-col-md-23, .ant-col-lg-23, .ant-col-24, .ant-col-xs-24, .ant-col-sm-24, .ant-col-md-24, .ant-col-lg-24 {
  position: relative;
  padding-right: 16px;
  padding-left: 16px;
}
.ant-col-1, .ant-col-xs-1, .ant-col-sm-1, .ant-col-md-1, .ant-col-lg-1, .ant-col-2, .ant-col-xs-2, .ant-col-sm-2, .ant-col-md-2, .ant-col-lg-2, .ant-col-3, .ant-col-xs-3, .ant-col-sm-3, .ant-col-md-3, .ant-col-lg-3, .ant-col-4, .ant-col-xs-4, .ant-col-sm-4, .ant-col-md-4, .ant-col-lg-4, .ant-col-5, .ant-col-xs-5, .ant-col-sm-5, .ant-col-md-5, .ant-col-lg-5, .ant-col-6, .ant-col-xs-6, .ant-col-sm-6, .ant-col-md-6, .ant-col-lg-6, .ant-col-7, .ant-col-xs-7, .ant-col-sm-7, .ant-col-md-7, .ant-col-lg-7, .ant-col-8, .ant-col-xs-8, .ant-col-sm-8, .ant-col-md-8, .ant-col-lg-8, .ant-col-9, .ant-col-xs-9, .ant-col-sm-9, .ant-col-md-9, .ant-col-lg-9, .ant-col-10, .ant-col-xs-10, .ant-col-sm-10, .ant-col-md-10, .ant-col-lg-10, .ant-col-11, .ant-col-xs-11, .ant-col-sm-11, .ant-col-md-11, .ant-col-lg-11, .ant-col-12, .ant-col-xs-12, .ant-col-sm-12, .ant-col-md-12, .ant-col-lg-12, .ant-col-13, .ant-col-xs-13, .ant-col-sm-13, .ant-col-md-13, .ant-col-lg-13, .ant-col-14, .ant-col-xs-14, .ant-col-sm-14, .ant-col-md-14, .ant-col-lg-14, .ant-col-15, .ant-col-xs-15, .ant-col-sm-15, .ant-col-md-15, .ant-col-lg-15, .ant-col-16, .ant-col-xs-16, .ant-col-sm-16, .ant-col-md-16, .ant-col-lg-16, .ant-col-17, .ant-col-xs-17, .ant-col-sm-17, .ant-col-md-17, .ant-col-lg-17, .ant-col-18, .ant-col-xs-18, .ant-col-sm-18, .ant-col-md-18, .ant-col-lg-18, .ant-col-19, .ant-col-xs-19, .ant-col-sm-19, .ant-col-md-19, .ant-col-lg-19, .ant-col-20, .ant-col-xs-20, .ant-col-sm-20, .ant-col-md-20, .ant-col-lg-20, .ant-col-21, .ant-col-xs-21, .ant-col-sm-21, .ant-col-md-21, .ant-col-lg-21, .ant-col-22, .ant-col-xs-22, .ant-col-sm-22, .ant-col-md-22, .ant-col-lg-22, .ant-col-23, .ant-col-xs-23, .ant-col-sm-23, .ant-col-md-23, .ant-col-lg-23, .ant-col-24, .ant-col-xs-24, .ant-col-sm-24, .ant-col-md-24, .ant-col-lg-24 {
  padding-left: 16px;
  padding-right: 16px;
  float: none !important;
}
.ant-col-1, .ant-col-2, .ant-col-3, .ant-col-4, .ant-col-5, .ant-col-6, .ant-col-7, .ant-col-8, .ant-col-9, .ant-col-10, .ant-col-11, .ant-col-12, .ant-col-13, .ant-col-14, .ant-col-15, .ant-col-16, .ant-col-17, .ant-col-18, .ant-col-19, .ant-col-20, .ant-col-21, .ant-col-22, .ant-col-23, .ant-col-24 {
  flex: 0 0 auto;
  float: left;
}
.ant-col-24 {
  display: block;
  box-sizing: border-box;
  width: 100%;
}
.ant-col-push-24 {
  left: 100%;
}
.ant-col-pull-24 {
  right: 100%;
}
.ant-col-offset-24 {
  margin-left: 100%;
}
.ant-col-order-24 {
  order: 24;
}
.ant-col-23 {
  display: block;
  box-sizing: border-box;
  width: 95.83333333%;
}
.ant-col-push-23 {
  left: 95.83333333%;
}
.ant-col-pull-23 {
  right: 95.83333333%;
}
.ant-col-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-order-23 {
  order: 23;
}
.ant-col-22 {
  display: block;
  box-sizing: border-box;
  width: 91.66666667%;
}
.ant-col-push-22 {
  left: 91.66666667%;
}
.ant-col-pull-22 {
  right: 91.66666667%;
}
.ant-col-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-order-22 {
  order: 22;
}
.ant-col-21 {
  display: block;
  box-sizing: border-box;
  width: 87.5%;
}
.ant-col-push-21 {
  left: 87.5%;
}
.ant-col-pull-21 {
  right: 87.5%;
}
.ant-col-offset-21 {
  margin-left: 87.5%;
}
.ant-col-order-21 {
  order: 21;
}
.ant-col-20 {
  display: block;
  box-sizing: border-box;
  width: 83.33333333%;
}
.ant-col-push-20 {
  left: 83.33333333%;
}
.ant-col-pull-20 {
  right: 83.33333333%;
}
.ant-col-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-order-20 {
  order: 20;
}
.ant-col-19 {
  display: block;
  box-sizing: border-box;
  width: 79.16666667%;
}
.ant-col-push-19 {
  left: 79.16666667%;
}
.ant-col-pull-19 {
  right: 79.16666667%;
}
.ant-col-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-order-19 {
  order: 19;
}
.ant-col-18 {
  display: block;
  box-sizing: border-box;
  width: 75%;
}
.ant-col-push-18 {
  left: 75%;
}
.ant-col-pull-18 {
  right: 75%;
}
.ant-col-offset-18 {
  margin-left: 75%;
}
.ant-col-order-18 {
  order: 18;
}
.ant-col-17 {
  display: block;
  box-sizing: border-box;
  width: 70.83333333%;
}
.ant-col-push-17 {
  left: 70.83333333%;
}
.ant-col-pull-17 {
  right: 70.83333333%;
}
.ant-col-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-order-17 {
  order: 17;
}
.ant-col-16 {
  display: block;
  box-sizing: border-box;
  width: 66.66666667%;
}
.ant-col-push-16 {
  left: 66.66666667%;
}
.ant-col-pull-16 {
  right: 66.66666667%;
}
.ant-col-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-order-16 {
  order: 16;
}
.ant-col-15 {
  display: block;
  box-sizing: border-box;
  width: 62.5%;
}
.ant-col-push-15 {
  left: 62.5%;
}
.ant-col-pull-15 {
  right: 62.5%;
}
.ant-col-offset-15 {
  margin-left: 62.5%;
}
.ant-col-order-15 {
  order: 15;
}
.ant-col-14 {
  display: block;
  box-sizing: border-box;
  width: 58.33333333%;
}
.ant-col-push-14 {
  left: 58.33333333%;
}
.ant-col-pull-14 {
  right: 58.33333333%;
}
.ant-col-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-order-14 {
  order: 14;
}
.ant-col-13 {
  display: block;
  box-sizing: border-box;
  width: 54.16666667%;
}
.ant-col-push-13 {
  left: 54.16666667%;
}
.ant-col-pull-13 {
  right: 54.16666667%;
}
.ant-col-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-order-13 {
  order: 13;
}
.ant-col-12 {
  display: block;
  box-sizing: border-box;
  width: 50%;
}
.ant-col-push-12 {
  left: 50%;
}
.ant-col-pull-12 {
  right: 50%;
}
.ant-col-offset-12 {
  margin-left: 50%;
}
.ant-col-order-12 {
  order: 12;
}
.ant-col-11 {
  display: block;
  box-sizing: border-box;
  width: 45.83333333%;
}
.ant-col-push-11 {
  left: 45.83333333%;
}
.ant-col-pull-11 {
  right: 45.83333333%;
}
.ant-col-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-order-11 {
  order: 11;
}
.ant-col-10 {
  display: block;
  box-sizing: border-box;
  width: 41.66666667%;
}
.ant-col-push-10 {
  left: 41.66666667%;
}
.ant-col-pull-10 {
  right: 41.66666667%;
}
.ant-col-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-order-10 {
  order: 10;
}
.ant-col-9 {
  display: block;
  box-sizing: border-box;
  width: 37.5%;
}
.ant-col-push-9 {
  left: 37.5%;
}
.ant-col-pull-9 {
  right: 37.5%;
}
.ant-col-offset-9 {
  margin-left: 37.5%;
}
.ant-col-order-9 {
  order: 9;
}
.ant-col-8 {
  display: block;
  box-sizing: border-box;
  width: 33.33333333%;
}
.ant-col-push-8 {
  left: 33.33333333%;
}
.ant-col-pull-8 {
  right: 33.33333333%;
}
.ant-col-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-order-8 {
  order: 8;
}
.ant-col-7 {
  display: block;
  box-sizing: border-box;
  width: 29.16666667%;
}
.ant-col-push-7 {
  left: 29.16666667%;
}
.ant-col-pull-7 {
  right: 29.16666667%;
}
.ant-col-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-order-7 {
  order: 7;
}
.ant-col-6 {
  display: block;
  box-sizing: border-box;
  width: 25%;
}
.ant-col-push-6 {
  left: 25%;
}
.ant-col-pull-6 {
  right: 25%;
}
.ant-col-offset-6 {
  margin-left: 25%;
}
.ant-col-order-6 {
  order: 6;
}
.ant-col-5 {
  display: block;
  box-sizing: border-box;
  width: 20.83333333%;
}
.ant-col-push-5 {
  left: 20.83333333%;
}
.ant-col-pull-5 {
  right: 20.83333333%;
}
.ant-col-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-order-5 {
  order: 5;
}
.ant-col-4 {
  display: block;
  box-sizing: border-box;
  width: 16.66666667%;
}
.ant-col-push-4 {
  left: 16.66666667%;
}
.ant-col-pull-4 {
  right: 16.66666667%;
}
.ant-col-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-order-4 {
  order: 4;
}
.ant-col-3 {
  display: block;
  box-sizing: border-box;
  width: 12.5%;
}
.ant-col-push-3 {
  left: 12.5%;
}
.ant-col-pull-3 {
  right: 12.5%;
}
.ant-col-offset-3 {
  margin-left: 12.5%;
}
.ant-col-order-3 {
  order: 3;
}
.ant-col-2 {
  display: block;
  box-sizing: border-box;
  width: 8.33333333%;
}
.ant-col-push-2 {
  left: 8.33333333%;
}
.ant-col-pull-2 {
  right: 8.33333333%;
}
.ant-col-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-order-2 {
  order: 2;
}
.ant-col-1 {
  display: block;
  box-sizing: border-box;
  width: 4.16666667%;
}
.ant-col-push-1 {
  left: 4.16666667%;
}
.ant-col-pull-1 {
  right: 4.16666667%;
}
.ant-col-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-order-1 {
  order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-offset-0 {
  margin-left: 0;
}
.ant-col-order-0 {
  order: 0;
}
.ant-col-xs-1, .ant-col-xs-2, .ant-col-xs-3, .ant-col-xs-4, .ant-col-xs-5, .ant-col-xs-6, .ant-col-xs-7, .ant-col-xs-8, .ant-col-xs-9, .ant-col-xs-10, .ant-col-xs-11, .ant-col-xs-12, .ant-col-xs-13, .ant-col-xs-14, .ant-col-xs-15, .ant-col-xs-16, .ant-col-xs-17, .ant-col-xs-18, .ant-col-xs-19, .ant-col-xs-20, .ant-col-xs-21, .ant-col-xs-22, .ant-col-xs-23, .ant-col-xs-24 {
  flex: 0 0 auto;
  float: left;
}
.ant-col-xs-24 {
  display: block;
  box-sizing: border-box;
  width: 100%;
}
.ant-col-xs-push-24 {
  left: 100%;
}
.ant-col-xs-pull-24 {
  right: 100%;
}
.ant-col-xs-offset-24 {
  margin-left: 100%;
}
.ant-col-xs-order-24 {
  order: 24;
}
.ant-col-xs-23 {
  display: block;
  box-sizing: border-box;
  width: 95.83333333%;
}
.ant-col-xs-push-23 {
  left: 95.83333333%;
}
.ant-col-xs-pull-23 {
  right: 95.83333333%;
}
.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-xs-order-23 {
  order: 23;
}
.ant-col-xs-22 {
  display: block;
  box-sizing: border-box;
  width: 91.66666667%;
}
.ant-col-xs-push-22 {
  left: 91.66666667%;
}
.ant-col-xs-pull-22 {
  right: 91.66666667%;
}
.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-xs-order-22 {
  order: 22;
}
.ant-col-xs-21 {
  display: block;
  box-sizing: border-box;
  width: 87.5%;
}
.ant-col-xs-push-21 {
  left: 87.5%;
}
.ant-col-xs-pull-21 {
  right: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
.ant-col-xs-order-21 {
  order: 21;
}
.ant-col-xs-20 {
  display: block;
  box-sizing: border-box;
  width: 83.33333333%;
}
.ant-col-xs-push-20 {
  left: 83.33333333%;
}
.ant-col-xs-pull-20 {
  right: 83.33333333%;
}
.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-xs-order-20 {
  order: 20;
}
.ant-col-xs-19 {
  display: block;
  box-sizing: border-box;
  width: 79.16666667%;
}
.ant-col-xs-push-19 {
  left: 79.16666667%;
}
.ant-col-xs-pull-19 {
  right: 79.16666667%;
}
.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-xs-order-19 {
  order: 19;
}
.ant-col-xs-18 {
  display: block;
  box-sizing: border-box;
  width: 75%;
}
.ant-col-xs-push-18 {
  left: 75%;
}
.ant-col-xs-pull-18 {
  right: 75%;
}
.ant-col-xs-offset-18 {
  margin-left: 75%;
}
.ant-col-xs-order-18 {
  order: 18;
}
.ant-col-xs-17 {
  display: block;
  box-sizing: border-box;
  width: 70.83333333%;
}
.ant-col-xs-push-17 {
  left: 70.83333333%;
}
.ant-col-xs-pull-17 {
  right: 70.83333333%;
}
.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-xs-order-17 {
  order: 17;
}
.ant-col-xs-16 {
  display: block;
  box-sizing: border-box;
  width: 66.66666667%;
}
.ant-col-xs-push-16 {
  left: 66.66666667%;
}
.ant-col-xs-pull-16 {
  right: 66.66666667%;
}
.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-xs-order-16 {
  order: 16;
}
.ant-col-xs-15 {
  display: block;
  box-sizing: border-box;
  width: 62.5%;
}
.ant-col-xs-push-15 {
  left: 62.5%;
}
.ant-col-xs-pull-15 {
  right: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
.ant-col-xs-order-15 {
  order: 15;
}
.ant-col-xs-14 {
  display: block;
  box-sizing: border-box;
  width: 58.33333333%;
}
.ant-col-xs-push-14 {
  left: 58.33333333%;
}
.ant-col-xs-pull-14 {
  right: 58.33333333%;
}
.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-xs-order-14 {
  order: 14;
}
.ant-col-xs-13 {
  display: block;
  box-sizing: border-box;
  width: 54.16666667%;
}
.ant-col-xs-push-13 {
  left: 54.16666667%;
}
.ant-col-xs-pull-13 {
  right: 54.16666667%;
}
.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-xs-order-13 {
  order: 13;
}
.ant-col-xs-12 {
  display: block;
  box-sizing: border-box;
  width: 50%;
}
.ant-col-xs-push-12 {
  left: 50%;
}
.ant-col-xs-pull-12 {
  right: 50%;
}
.ant-col-xs-offset-12 {
  margin-left: 50%;
}
.ant-col-xs-order-12 {
  order: 12;
}
.ant-col-xs-11 {
  display: block;
  box-sizing: border-box;
  width: 45.83333333%;
}
.ant-col-xs-push-11 {
  left: 45.83333333%;
}
.ant-col-xs-pull-11 {
  right: 45.83333333%;
}
.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-xs-order-11 {
  order: 11;
}
.ant-col-xs-10 {
  display: block;
  box-sizing: border-box;
  width: 41.66666667%;
}
.ant-col-xs-push-10 {
  left: 41.66666667%;
}
.ant-col-xs-pull-10 {
  right: 41.66666667%;
}
.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-xs-order-10 {
  order: 10;
}
.ant-col-xs-9 {
  display: block;
  box-sizing: border-box;
  width: 37.5%;
}
.ant-col-xs-push-9 {
  left: 37.5%;
}
.ant-col-xs-pull-9 {
  right: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
.ant-col-xs-order-9 {
  order: 9;
}
.ant-col-xs-8 {
  display: block;
  box-sizing: border-box;
  width: 33.33333333%;
}
.ant-col-xs-push-8 {
  left: 33.33333333%;
}
.ant-col-xs-pull-8 {
  right: 33.33333333%;
}
.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-xs-order-8 {
  order: 8;
}
.ant-col-xs-7 {
  display: block;
  box-sizing: border-box;
  width: 29.16666667%;
}
.ant-col-xs-push-7 {
  left: 29.16666667%;
}
.ant-col-xs-pull-7 {
  right: 29.16666667%;
}
.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-xs-order-7 {
  order: 7;
}
.ant-col-xs-6 {
  display: block;
  box-sizing: border-box;
  width: 25%;
}
.ant-col-xs-push-6 {
  left: 25%;
}
.ant-col-xs-pull-6 {
  right: 25%;
}
.ant-col-xs-offset-6 {
  margin-left: 25%;
}
.ant-col-xs-order-6 {
  order: 6;
}
.ant-col-xs-5 {
  display: block;
  box-sizing: border-box;
  width: 20.83333333%;
}
.ant-col-xs-push-5 {
  left: 20.83333333%;
}
.ant-col-xs-pull-5 {
  right: 20.83333333%;
}
.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-xs-order-5 {
  order: 5;
}
.ant-col-xs-4 {
  display: block;
  box-sizing: border-box;
  width: 16.66666667%;
}
.ant-col-xs-push-4 {
  left: 16.66666667%;
}
.ant-col-xs-pull-4 {
  right: 16.66666667%;
}
.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-xs-order-4 {
  order: 4;
}
.ant-col-xs-3 {
  display: block;
  box-sizing: border-box;
  width: 12.5%;
}
.ant-col-xs-push-3 {
  left: 12.5%;
}
.ant-col-xs-pull-3 {
  right: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
.ant-col-xs-order-3 {
  order: 3;
}
.ant-col-xs-2 {
  display: block;
  box-sizing: border-box;
  width: 8.33333333%;
}
.ant-col-xs-push-2 {
  left: 8.33333333%;
}
.ant-col-xs-pull-2 {
  right: 8.33333333%;
}
.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-xs-order-2 {
  order: 2;
}
.ant-col-xs-1 {
  display: block;
  box-sizing: border-box;
  width: 4.16666667%;
}
.ant-col-xs-push-1 {
  left: 4.16666667%;
}
.ant-col-xs-pull-1 {
  right: 4.16666667%;
}
.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-xs-order-1 {
  order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-xs-push-0 {
  left: auto;
}
.ant-col-xs-pull-0 {
  right: auto;
}
.ant-col-xs-offset-0 {
  margin-left: 0;
}
.ant-col-xs-order-0 {
  order: 0;
}
@media (min-width: 576px) {
  .ant-col-sm-1, .ant-col-sm-2, .ant-col-sm-3, .ant-col-sm-4, .ant-col-sm-5, .ant-col-sm-6, .ant-col-sm-7, .ant-col-sm-8, .ant-col-sm-9, .ant-col-sm-10, .ant-col-sm-11, .ant-col-sm-12, .ant-col-sm-13, .ant-col-sm-14, .ant-col-sm-15, .ant-col-sm-16, .ant-col-sm-17, .ant-col-sm-18, .ant-col-sm-19, .ant-col-sm-20, .ant-col-sm-21, .ant-col-sm-22, .ant-col-sm-23, .ant-col-sm-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-sm-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-sm-push-24 {
    left: 100%;
  }
  .ant-col-sm-pull-24 {
    right: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  .ant-col-sm-order-24 {
    order: 24;
  }
  .ant-col-sm-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-sm-order-23 {
    order: 23;
  }
  .ant-col-sm-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-sm-order-22 {
    order: 22;
  }
  .ant-col-sm-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-sm-push-21 {
    left: 87.5%;
  }
  .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-sm-order-21 {
    order: 21;
  }
  .ant-col-sm-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-sm-order-20 {
    order: 20;
  }
  .ant-col-sm-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-sm-order-19 {
    order: 19;
  }
  .ant-col-sm-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-sm-push-18 {
    left: 75%;
  }
  .ant-col-sm-pull-18 {
    right: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  .ant-col-sm-order-18 {
    order: 18;
  }
  .ant-col-sm-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-sm-order-17 {
    order: 17;
  }
  .ant-col-sm-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-sm-order-16 {
    order: 16;
  }
  .ant-col-sm-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-sm-push-15 {
    left: 62.5%;
  }
  .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-sm-order-15 {
    order: 15;
  }
  .ant-col-sm-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-sm-order-14 {
    order: 14;
  }
  .ant-col-sm-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-sm-order-13 {
    order: 13;
  }
  .ant-col-sm-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-sm-push-12 {
    left: 50%;
  }
  .ant-col-sm-pull-12 {
    right: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  .ant-col-sm-order-12 {
    order: 12;
  }
  .ant-col-sm-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-sm-order-11 {
    order: 11;
  }
  .ant-col-sm-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-sm-order-10 {
    order: 10;
  }
  .ant-col-sm-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-sm-push-9 {
    left: 37.5%;
  }
  .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-sm-order-9 {
    order: 9;
  }
  .ant-col-sm-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-sm-order-8 {
    order: 8;
  }
  .ant-col-sm-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-sm-order-7 {
    order: 7;
  }
  .ant-col-sm-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-sm-push-6 {
    left: 25%;
  }
  .ant-col-sm-pull-6 {
    right: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  .ant-col-sm-order-6 {
    order: 6;
  }
  .ant-col-sm-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-sm-order-5 {
    order: 5;
  }
  .ant-col-sm-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-sm-order-4 {
    order: 4;
  }
  .ant-col-sm-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-sm-push-3 {
    left: 12.5%;
  }
  .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-sm-order-3 {
    order: 3;
  }
  .ant-col-sm-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-sm-order-2 {
    order: 2;
  }
  .ant-col-sm-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-sm-order-1 {
    order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-sm-push-0 {
    left: auto;
  }
  .ant-col-sm-pull-0 {
    right: auto;
  }
  .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  .ant-col-sm-order-0 {
    order: 0;
  }
}
@media (min-width: 768px) {
  .ant-col-md-1, .ant-col-md-2, .ant-col-md-3, .ant-col-md-4, .ant-col-md-5, .ant-col-md-6, .ant-col-md-7, .ant-col-md-8, .ant-col-md-9, .ant-col-md-10, .ant-col-md-11, .ant-col-md-12, .ant-col-md-13, .ant-col-md-14, .ant-col-md-15, .ant-col-md-16, .ant-col-md-17, .ant-col-md-18, .ant-col-md-19, .ant-col-md-20, .ant-col-md-21, .ant-col-md-22, .ant-col-md-23, .ant-col-md-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-md-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-md-push-24 {
    left: 100%;
  }
  .ant-col-md-pull-24 {
    right: 100%;
  }
  .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  .ant-col-md-order-24 {
    order: 24;
  }
  .ant-col-md-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-md-order-23 {
    order: 23;
  }
  .ant-col-md-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-md-order-22 {
    order: 22;
  }
  .ant-col-md-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-md-push-21 {
    left: 87.5%;
  }
  .ant-col-md-pull-21 {
    right: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-md-order-21 {
    order: 21;
  }
  .ant-col-md-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-md-order-20 {
    order: 20;
  }
  .ant-col-md-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-md-order-19 {
    order: 19;
  }
  .ant-col-md-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-md-push-18 {
    left: 75%;
  }
  .ant-col-md-pull-18 {
    right: 75%;
  }
  .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  .ant-col-md-order-18 {
    order: 18;
  }
  .ant-col-md-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-md-order-17 {
    order: 17;
  }
  .ant-col-md-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-md-order-16 {
    order: 16;
  }
  .ant-col-md-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-md-push-15 {
    left: 62.5%;
  }
  .ant-col-md-pull-15 {
    right: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-md-order-15 {
    order: 15;
  }
  .ant-col-md-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-md-order-14 {
    order: 14;
  }
  .ant-col-md-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-md-order-13 {
    order: 13;
  }
  .ant-col-md-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-md-push-12 {
    left: 50%;
  }
  .ant-col-md-pull-12 {
    right: 50%;
  }
  .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  .ant-col-md-order-12 {
    order: 12;
  }
  .ant-col-md-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-md-order-11 {
    order: 11;
  }
  .ant-col-md-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-md-order-10 {
    order: 10;
  }
  .ant-col-md-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-md-push-9 {
    left: 37.5%;
  }
  .ant-col-md-pull-9 {
    right: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-md-order-9 {
    order: 9;
  }
  .ant-col-md-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-md-order-8 {
    order: 8;
  }
  .ant-col-md-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-md-order-7 {
    order: 7;
  }
  .ant-col-md-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-md-push-6 {
    left: 25%;
  }
  .ant-col-md-pull-6 {
    right: 25%;
  }
  .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  .ant-col-md-order-6 {
    order: 6;
  }
  .ant-col-md-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-md-order-5 {
    order: 5;
  }
  .ant-col-md-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-md-order-4 {
    order: 4;
  }
  .ant-col-md-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-md-push-3 {
    left: 12.5%;
  }
  .ant-col-md-pull-3 {
    right: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-md-order-3 {
    order: 3;
  }
  .ant-col-md-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-md-order-2 {
    order: 2;
  }
  .ant-col-md-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-md-order-1 {
    order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-md-push-0 {
    left: auto;
  }
  .ant-col-md-pull-0 {
    right: auto;
  }
  .ant-col-md-offset-0 {
    margin-left: 0;
  }
  .ant-col-md-order-0 {
    order: 0;
  }
}
@media (min-width: 992px) {
  .ant-col-lg-1, .ant-col-lg-2, .ant-col-lg-3, .ant-col-lg-4, .ant-col-lg-5, .ant-col-lg-6, .ant-col-lg-7, .ant-col-lg-8, .ant-col-lg-9, .ant-col-lg-10, .ant-col-lg-11, .ant-col-lg-12, .ant-col-lg-13, .ant-col-lg-14, .ant-col-lg-15, .ant-col-lg-16, .ant-col-lg-17, .ant-col-lg-18, .ant-col-lg-19, .ant-col-lg-20, .ant-col-lg-21, .ant-col-lg-22, .ant-col-lg-23, .ant-col-lg-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-lg-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-lg-push-24 {
    left: 100%;
  }
  .ant-col-lg-pull-24 {
    right: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  .ant-col-lg-order-24 {
    order: 24;
  }
  .ant-col-lg-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-lg-order-23 {
    order: 23;
  }
  .ant-col-lg-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-lg-order-22 {
    order: 22;
  }
  .ant-col-lg-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-lg-push-21 {
    left: 87.5%;
  }
  .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-lg-order-21 {
    order: 21;
  }
  .ant-col-lg-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-lg-order-20 {
    order: 20;
  }
  .ant-col-lg-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-lg-order-19 {
    order: 19;
  }
  .ant-col-lg-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-lg-push-18 {
    left: 75%;
  }
  .ant-col-lg-pull-18 {
    right: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  .ant-col-lg-order-18 {
    order: 18;
  }
  .ant-col-lg-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-lg-order-17 {
    order: 17;
  }
  .ant-col-lg-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-lg-order-16 {
    order: 16;
  }
  .ant-col-lg-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-lg-push-15 {
    left: 62.5%;
  }
  .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-lg-order-15 {
    order: 15;
  }
  .ant-col-lg-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-lg-order-14 {
    order: 14;
  }
  .ant-col-lg-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-lg-order-13 {
    order: 13;
  }
  .ant-col-lg-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-lg-push-12 {
    left: 50%;
  }
  .ant-col-lg-pull-12 {
    right: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  .ant-col-lg-order-12 {
    order: 12;
  }
  .ant-col-lg-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-lg-order-11 {
    order: 11;
  }
  .ant-col-lg-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-lg-order-10 {
    order: 10;
  }
  .ant-col-lg-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-lg-push-9 {
    left: 37.5%;
  }
  .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-lg-order-9 {
    order: 9;
  }
  .ant-col-lg-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-lg-order-8 {
    order: 8;
  }
  .ant-col-lg-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-lg-order-7 {
    order: 7;
  }
  .ant-col-lg-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-lg-push-6 {
    left: 25%;
  }
  .ant-col-lg-pull-6 {
    right: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  .ant-col-lg-order-6 {
    order: 6;
  }
  .ant-col-lg-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-lg-order-5 {
    order: 5;
  }
  .ant-col-lg-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-lg-order-4 {
    order: 4;
  }
  .ant-col-lg-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-lg-push-3 {
    left: 12.5%;
  }
  .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-lg-order-3 {
    order: 3;
  }
  .ant-col-lg-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-lg-order-2 {
    order: 2;
  }
  .ant-col-lg-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-lg-order-1 {
    order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-lg-push-0 {
    left: auto;
  }
  .ant-col-lg-pull-0 {
    right: auto;
  }
  .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  .ant-col-lg-order-0 {
    order: 0;
  }
}
@media (min-width: 1200px) {
  .ant-col-xl-1, .ant-col-xl-2, .ant-col-xl-3, .ant-col-xl-4, .ant-col-xl-5, .ant-col-xl-6, .ant-col-xl-7, .ant-col-xl-8, .ant-col-xl-9, .ant-col-xl-10, .ant-col-xl-11, .ant-col-xl-12, .ant-col-xl-13, .ant-col-xl-14, .ant-col-xl-15, .ant-col-xl-16, .ant-col-xl-17, .ant-col-xl-18, .ant-col-xl-19, .ant-col-xl-20, .ant-col-xl-21, .ant-col-xl-22, .ant-col-xl-23, .ant-col-xl-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-xl-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-xl-push-24 {
    left: 100%;
  }
  .ant-col-xl-pull-24 {
    right: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xl-order-24 {
    order: 24;
  }
  .ant-col-xl-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xl-order-23 {
    order: 23;
  }
  .ant-col-xl-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xl-order-22 {
    order: 22;
  }
  .ant-col-xl-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-xl-push-21 {
    left: 87.5%;
  }
  .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xl-order-21 {
    order: 21;
  }
  .ant-col-xl-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xl-order-20 {
    order: 20;
  }
  .ant-col-xl-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xl-order-19 {
    order: 19;
  }
  .ant-col-xl-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-xl-push-18 {
    left: 75%;
  }
  .ant-col-xl-pull-18 {
    right: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xl-order-18 {
    order: 18;
  }
  .ant-col-xl-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xl-order-17 {
    order: 17;
  }
  .ant-col-xl-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xl-order-16 {
    order: 16;
  }
  .ant-col-xl-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-xl-push-15 {
    left: 62.5%;
  }
  .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xl-order-15 {
    order: 15;
  }
  .ant-col-xl-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xl-order-14 {
    order: 14;
  }
  .ant-col-xl-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xl-order-13 {
    order: 13;
  }
  .ant-col-xl-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-xl-push-12 {
    left: 50%;
  }
  .ant-col-xl-pull-12 {
    right: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xl-order-12 {
    order: 12;
  }
  .ant-col-xl-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xl-order-11 {
    order: 11;
  }
  .ant-col-xl-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xl-order-10 {
    order: 10;
  }
  .ant-col-xl-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-xl-push-9 {
    left: 37.5%;
  }
  .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xl-order-9 {
    order: 9;
  }
  .ant-col-xl-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xl-order-8 {
    order: 8;
  }
  .ant-col-xl-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xl-order-7 {
    order: 7;
  }
  .ant-col-xl-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-xl-push-6 {
    left: 25%;
  }
  .ant-col-xl-pull-6 {
    right: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xl-order-6 {
    order: 6;
  }
  .ant-col-xl-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xl-order-5 {
    order: 5;
  }
  .ant-col-xl-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xl-order-4 {
    order: 4;
  }
  .ant-col-xl-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-xl-push-3 {
    left: 12.5%;
  }
  .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xl-order-3 {
    order: 3;
  }
  .ant-col-xl-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xl-order-2 {
    order: 2;
  }
  .ant-col-xl-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xl-order-1 {
    order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xl-push-0 {
    left: auto;
  }
  .ant-col-xl-pull-0 {
    right: auto;
  }
  .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xl-order-0 {
    order: 0;
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-1, .ant-col-xxl-2, .ant-col-xxl-3, .ant-col-xxl-4, .ant-col-xxl-5, .ant-col-xxl-6, .ant-col-xxl-7, .ant-col-xxl-8, .ant-col-xxl-9, .ant-col-xxl-10, .ant-col-xxl-11, .ant-col-xxl-12, .ant-col-xxl-13, .ant-col-xxl-14, .ant-col-xxl-15, .ant-col-xxl-16, .ant-col-xxl-17, .ant-col-xxl-18, .ant-col-xxl-19, .ant-col-xxl-20, .ant-col-xxl-21, .ant-col-xxl-22, .ant-col-xxl-23, .ant-col-xxl-24 {
    flex: 0 0 auto;
    float: left;
  }
  .ant-col-xxl-24 {
    display: block;
    box-sizing: border-box;
    width: 100%;
  }
  .ant-col-xxl-push-24 {
    left: 100%;
  }
  .ant-col-xxl-pull-24 {
    right: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xxl-order-24 {
    order: 24;
  }
  .ant-col-xxl-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%;
  }
  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xxl-order-23 {
    order: 23;
  }
  .ant-col-xxl-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%;
  }
  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xxl-order-22 {
    order: 22;
  }
  .ant-col-xxl-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xxl-order-21 {
    order: 21;
  }
  .ant-col-xxl-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%;
  }
  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xxl-order-20 {
    order: 20;
  }
  .ant-col-xxl-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%;
  }
  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xxl-order-19 {
    order: 19;
  }
  .ant-col-xxl-18 {
    display: block;
    box-sizing: border-box;
    width: 75%;
  }
  .ant-col-xxl-push-18 {
    left: 75%;
  }
  .ant-col-xxl-pull-18 {
    right: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xxl-order-18 {
    order: 18;
  }
  .ant-col-xxl-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%;
  }
  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xxl-order-17 {
    order: 17;
  }
  .ant-col-xxl-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%;
  }
  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xxl-order-16 {
    order: 16;
  }
  .ant-col-xxl-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xxl-order-15 {
    order: 15;
  }
  .ant-col-xxl-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%;
  }
  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xxl-order-14 {
    order: 14;
  }
  .ant-col-xxl-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%;
  }
  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xxl-order-13 {
    order: 13;
  }
  .ant-col-xxl-12 {
    display: block;
    box-sizing: border-box;
    width: 50%;
  }
  .ant-col-xxl-push-12 {
    left: 50%;
  }
  .ant-col-xxl-pull-12 {
    right: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xxl-order-12 {
    order: 12;
  }
  .ant-col-xxl-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%;
  }
  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xxl-order-11 {
    order: 11;
  }
  .ant-col-xxl-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%;
  }
  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xxl-order-10 {
    order: 10;
  }
  .ant-col-xxl-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xxl-order-9 {
    order: 9;
  }
  .ant-col-xxl-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%;
  }
  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xxl-order-8 {
    order: 8;
  }
  .ant-col-xxl-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%;
  }
  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xxl-order-7 {
    order: 7;
  }
  .ant-col-xxl-6 {
    display: block;
    box-sizing: border-box;
    width: 25%;
  }
  .ant-col-xxl-push-6 {
    left: 25%;
  }
  .ant-col-xxl-pull-6 {
    right: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xxl-order-6 {
    order: 6;
  }
  .ant-col-xxl-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%;
  }
  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xxl-order-5 {
    order: 5;
  }
  .ant-col-xxl-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%;
  }
  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xxl-order-4 {
    order: 4;
  }
  .ant-col-xxl-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xxl-order-3 {
    order: 3;
  }
  .ant-col-xxl-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%;
  }
  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xxl-order-2 {
    order: 2;
  }
  .ant-col-xxl-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%;
  }
  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xxl-order-1 {
    order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xxl-push-0 {
    left: auto;
  }
  .ant-col-xxl-pull-0 {
    right: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xxl-order-0 {
    order: 0;
  }
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  height: 36px;
  padding: 4px 11px;
  color: #545454;
  font-size: 14px;
  line-height: 1.3;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s;
}
.ant-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:hover {
  border-color: #28aaeb;
  border-right-width: 1px !important;
}
.ant-input:focus {
  border-color: #28aaeb;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
}
.ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 36px;
  line-height: 1.3;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: #545454;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
  margin: -5px -11px;
}
.ant-input-group-addon .ant-select .ant-select-selection {
  margin: -1px;
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selection,
.ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: #038fde;
}
.ant-input-group-addon > i:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selection,
.ant-input-group-addon:first-child .ant-select .ant-select-selection {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selection,
.ant-input-group-addon:last-child .ant-select .ant-select-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  height: 24px;
  padding: 1px 7px;
}
.ant-input-group-lg .ant-select-selection--single {
  height: 40px;
}
.ant-input-group-sm .ant-select-selection--single {
  height: 24px;
}
.ant-input-group .ant-input-affix-wrapper {
  display: table-cell;
  float: left;
  width: 100%;
}
.ant-input-group.ant-input-group-compact {
  display: block;
  zoom: 1;
}
.ant-input-group.ant-input-group-compact::before,
.ant-input-group.ant-input-group-compact::after {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  clear: both;
}
.ant-input-group.ant-input-group-compact::before,
.ant-input-group.ant-input-group-compact::after {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  clear: both;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection:hover,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:hover,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection:focus,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor:focus,
.ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:first-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:first-child .ant-time-picker-input {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selection,
.ant-input-group.ant-input-group-compact > .ant-calendar-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-mention-wrapper:last-child .ant-mention-editor,
.ant-input-group.ant-input-group-compact > .ant-time-picker:last-child .ant-time-picker-input {
  border-right-width: 1px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.ant-input-affix-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: start;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #28aaeb;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper .ant-input {
  position: relative;
  text-align: inherit;
}
.ant-input-affix-wrapper .ant-input-prefix,
.ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  z-index: 2;
  display: flex;
  align-items: center;
  color: #545454;
  line-height: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
.ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
  line-height: 1.3;
}
.ant-input-affix-wrapper .ant-input-disabled ~ .ant-input-suffix .anticon {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-input-affix-wrapper .ant-input-prefix {
  left: 12px;
}
.ant-input-affix-wrapper .ant-input-suffix {
  right: 12px;
}
.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 30px;
}
.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-input-with-clear-btn .ant-input:not(:last-child) {
  padding-right: 49px;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input {
  padding-right: 22px;
}
.ant-input-affix-wrapper .ant-input {
  min-height: 100%;
}
.ant-input-password-icon {
  color: #595959;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-password-icon:hover {
  color: #333;
}
.ant-input-clear-icon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  vertical-align: 0;
}
.ant-input-clear-icon:hover {
  color: #595959;
}
.ant-input-clear-icon:active {
  color: #545454;
}
.ant-input-clear-icon + i {
  margin-left: 6px;
}
.ant-input-textarea-clear-icon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px 8px 0 0;
}
.ant-input-textarea-clear-icon:hover {
  color: #595959;
}
.ant-input-textarea-clear-icon:active {
  color: #545454;
}
.ant-input-textarea-clear-icon + i {
  margin-left: 6px;
}
.ant-input-search-icon {
  color: #595959;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-search-icon:hover {
  color: rgba(0, 0, 0, 0.8);
}
.ant-input-search-enter-button input {
  border-right: 0;
}
.ant-input-search-enter-button + .ant-input-group-addon,
.ant-input-search-enter-button input + .ant-input-group-addon {
  padding: 0;
  border: 0;
}
.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button,
.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  width: 100%;
  height: 36px;
  padding: 4px 11px;
  color: #545454;
  font-size: 14px;
  line-height: 1.3;
  background-color: #fff;
  background-image: none;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
.ant-input-number::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input-number:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number:hover {
  border-color: #28aaeb;
  border-right-width: 1px !important;
}
.ant-input-number:focus {
  border-color: #28aaeb;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 36px;
  line-height: 1.3;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-input-number-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: #595959;
  font-weight: bold;
  line-height: 0;
  text-align: center;
  transition: all 0.1s linear;
}
.ant-input-number-handler:active {
  background: #f4f4f4;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #28aaeb;
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: #595959;
  line-height: 12px;
  transition: all 0.1s linear;
  -webkit-user-select: none;
          user-select: none;
}
.ant-input-number-handler-up-inner > *,
.ant-input-number-handler-down-inner > * {
  line-height: 1;
}
.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: inline-block;
}
.ant-input-number-handler-up-inner::before,
.ant-input-number-handler-down-inner::before {
  display: none;
}
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}
.ant-input-number:hover {
  border-color: #28aaeb;
  border-right-width: 1px !important;
}
.ant-input-number-focused {
  border-color: #28aaeb;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  width: 100%;
  height: 34px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 6px;
  outline: 0;
  transition: all 0.3s linear;
  -moz-appearance: textfield !important;
}
.ant-input-number-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-input-number-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-input[type='number']::-webkit-inner-spin-button,
.ant-input-number-input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}
.ant-input-number-lg input {
  height: 38px;
}
.ant-input-number-sm {
  padding: 0;
}
.ant-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}
.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #d9d9d9;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  display: inline-block;
  font-size: 12px;
  font-size: 7px \9;
  -webkit-transform: scale(0.58333333) rotate(0deg);
          transform: scale(0.58333333) rotate(0deg);
  min-width: auto;
  margin-right: 0;
}
:root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
:root .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  font-size: 12px;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-number-handler-up {
  border-top-right-radius: 6px;
  cursor: pointer;
}
.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.ant-input-number-handler-up:hover {
  height: 60% !important;
}
.ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom-right-radius: 6px;
  cursor: pointer;
}
.ant-input-number-handler-down-inner {
  top: 50%;
  margin-top: -6px;
  text-align: center;
}
.ant-input-number-handler-down:hover {
  height: 60% !important;
}
.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f5f5f5;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: hidden;
}
.ant-layout-header,
.ant-layout-footer {
  flex: 0 0 auto;
}
.ant-layout-header {
  height: 72px;
  padding: 0 32px;
  line-height: 72px;
  background: #fefefe;
}
.ant-layout-footer {
  padding: 14px 32px;
  color: #545454;
  font-size: 14px;
  background: #fffffe;
}
.ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #fffffe;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 30px;
}
.ant-layout-sider-right {
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 30px;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 72px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 28px;
  color: #ffffff;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  background: #fffffe;
  border-radius: 0 6px 6px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger:hover {
  background: #fffffe;
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 6px 0 0 6px;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: #545454;
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: #545454;
  background: #fff;
}
.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
}
.ant-list * {
  outline: none;
}
.ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.ant-list-pagination .ant-pagination-options {
  text-align: left;
}
.ant-list-more {
  margin-top: 12px;
  text-align: center;
}
.ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-list-spin {
  min-height: 40px;
  text-align: center;
}
.ant-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
}
.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}
.ant-list-item-content {
  color: #545454;
}
.ant-list-item-meta {
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  font-size: 0;
}
.ant-list-item-meta-avatar {
  margin-right: 16px;
}
.ant-list-item-meta-content {
  flex: 1 0;
}
.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: #545454;
  font-size: 14px;
  line-height: 22px;
}
.ant-list-item-meta-title > a {
  color: #545454;
  transition: all 0.3s;
}
.ant-list-item-meta-title > a:hover {
  color: #038fde;
}
.ant-list-item-meta-description {
  color: #595959;
  font-size: 14px;
  line-height: 22px;
}
.ant-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: #595959;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}
.ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #e8e8e8;
}
.ant-list-header {
  background: transparent;
}
.ant-list-footer {
  background: transparent;
}
.ant-list-header,
.ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-list-empty {
  padding: 16px 0;
  color: #595959;
  font-size: 12px;
  text-align: center;
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}
.ant-list-split .ant-list-header {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-lg .ant-list-item {
  padding-top: 16px;
  padding-bottom: 16px;
}
.ant-list-sm .ant-list-item {
  padding-top: 8px;
  padding-bottom: 8px;
}
.ant-list-vertical .ant-list-item {
  align-items: initial;
}
.ant-list-vertical .ant-list-item-main {
  display: block;
  flex: 1 1;
}
.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}
.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}
.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 12px;
  color: #535353;
  font-size: 16px;
  line-height: 24px;
}
.ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}
.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-grid .ant-col > .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ant-list-item-no-flex {
  display: block;
}
.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}
.ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
.ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-item {
  padding-right: 24px;
  padding-left: 24px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding-right: 16px;
  padding-left: 16px;
}
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  .ant-list-item-action {
    margin-left: 24px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-item {
    flex-wrap: wrap;
  }
  .ant-list-item-action {
    margin-left: 12px;
  }
  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }
  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-mentions-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.ant-mentions-wrapper .ant-mentions-editor {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 36px;
  padding: 4px 11px;
  color: #545454;
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s;
  display: block;
  height: auto;
  min-height: 36px;
  padding: 0;
  line-height: 1.3;
}
.ant-mentions-wrapper .ant-mentions-editor::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-mentions-wrapper .ant-mentions-editor:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions-wrapper .ant-mentions-editor::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions-wrapper .ant-mentions-editor:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions-wrapper .ant-mentions-editor:hover {
  border-color: #28aaeb;
  border-right-width: 1px !important;
}
.ant-mentions-wrapper .ant-mentions-editor:focus {
  border-color: #28aaeb;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
}
.ant-mentions-wrapper .ant-mentions-editor-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-wrapper .ant-mentions-editor-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-mentions-wrapper .ant-mentions-editor[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-wrapper .ant-mentions-editor[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-mentions-wrapper .ant-mentions-editor {
  max-width: 100%;
  height: auto;
  min-height: 36px;
  line-height: 1.3;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-mentions-wrapper .ant-mentions-editor-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-mentions-wrapper .ant-mentions-editor-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-mentions-wrapper .ant-mentions-editor-wrapper {
  height: auto;
  overflow-y: auto;
}
.ant-mentions-wrapper.ant-mentions-active:not(.disabled) .ant-mentions-editor {
  border-color: #28aaeb;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
}
.ant-mentions-wrapper.disabled .ant-mentions-editor {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-wrapper.disabled .ant-mentions-editor:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-mentions-wrapper .public-DraftEditorPlaceholder-root {
  position: absolute;
  pointer-events: none;
}
.ant-mentions-wrapper .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
  height: auto;
  padding: 5px 11px;
  color: #bfbfbf;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  opacity: 1;
}
.ant-mentions-wrapper .DraftEditor-editorContainer .public-DraftEditor-content {
  height: auto;
  padding: 5px 11px;
}
.ant-mentions-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  min-width: 120px;
  max-height: 250px;
  margin-top: 1.5em;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 6px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-mentions-dropdown-placement-top {
  margin-top: -0.1em;
}
.ant-mentions-dropdown-notfound.ant-mentions-dropdown-item {
  color: rgba(0, 0, 0, 0.25);
}
.ant-mentions-dropdown-notfound.ant-mentions-dropdown-item .anticon-loading {
  display: block;
  color: #038fde;
  text-align: center;
}
.ant-mentions-dropdown-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: #545454;
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s;
}
.ant-mentions-dropdown-item:hover {
  background-color: #e6faff;
}
.ant-mentions-dropdown-item.focus,
.ant-mentions-dropdown-item-active {
  background-color: #e6faff;
}
.ant-mentions-dropdown-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-mentions-dropdown-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mentions-dropdown-item-selected,
.ant-mentions-dropdown-item-selected:hover {
  color: #545454;
  font-weight: bold;
  background-color: #f5f5f5;
}
.ant-mentions-dropdown-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-mentions {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  width: 100%;
  height: 36px;
  padding: 4px 11px;
  color: #545454;
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
  height: auto;
  padding: 0;
  overflow: hidden;
  line-height: 1.3;
  white-space: pre-wrap;
  vertical-align: bottom;
}
.ant-mentions::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-mentions:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions:hover {
  border-color: #28aaeb;
  border-right-width: 1px !important;
}
.ant-mentions:focus {
  border-color: #28aaeb;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
}
.ant-mentions-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-mentions[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-mentions {
  max-width: 100%;
  height: auto;
  min-height: 36px;
  line-height: 1.3;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-mentions-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-mentions-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-mentions-disabled > textarea {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled > textarea:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-mentions-focused {
  border-color: #28aaeb;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
}
.ant-mentions > textarea,
.ant-mentions-measure {
  min-height: 34px;
  margin: 0;
  padding: 4px 11px;
  overflow: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  -webkit-font-feature-settings: inherit;
          font-feature-settings: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  tab-size: inherit;
}
.ant-mentions > textarea {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}
.ant-mentions > textarea::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-mentions > textarea:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions > textarea::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-mentions > textarea:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions > textarea:read-only {
  cursor: default;
}
.ant-mentions-measure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  pointer-events: none;
}
.ant-mentions-measure > span {
  display: inline-block;
  min-height: 1em;
}
.ant-mentions-dropdown {
  margin: 0;
  padding: 0;
  color: #545454;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum", ;
          font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 6px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-mentions-dropdown-hidden {
  display: none;
}
.ant-mentions-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-mentions-dropdown-menu-item {
  position: relative;
  display: block;
  min-width: 100px;
  padding: 5px 12px;
  overflow: hidden;
  color: #545454;
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-mentions-dropdown-menu-item:hover {
  background-color: #e6faff;
}
.ant-mentions-dropdown-menu-item:first-child {
  border-radius: 6px 6px 0 0;
}
.ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 6px 6px;
}
.ant-mentions-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-selected {
  color: #545454;
  font-weight: 600;
  background-color: #fafafa;
}
.ant-mentions-dropdown-menu-item-active {
  background-color: #e6faff;
}
.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  margin-bottom: 0;
  padding-left: 0;
  color: #545454;
  line-height: 0;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: background 0.3s, width 0.2s;
  zoom: 1;
}
.ant-menu::before,
.ant-menu::after {
  display: table;
  content: '';
}
.ant-menu::after {
  clear: both;
}
.ant-menu::before,
.ant-menu::after {
  display: table;
  content: '';
}
.ant-menu::after {
  clear: both;
}
.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-menu-hidden {
  display: none;
}
.ant-menu-item-group-title {
  padding: 8px 16px;
  color: #545454;
  font-size: 14px;
  line-height: 1.3;
  transition: all 0.3s;
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-selected {
  color: #038fde;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #e6faff;
}
.ant-menu-submenu .ant-menu-sub {
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item > a {
  display: block;
  color: #545454;
}
.ant-menu-item > a:hover {
  color: #038fde;
}
.ant-menu-item > a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.ant-menu-item > .ant-badge > a {
  color: #545454;
}
.ant-menu-item > .ant-badge > a:hover {
  color: #038fde;
}
.ant-menu-item-divider {
  height: 1px;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #038fde;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #038fde;
}
.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: #038fde;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e6faff;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #e8e8e8;
}
.ant-menu-vertical-right {
  border-left: 1px solid #e8e8e8;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  padding: 0;
  border-right: 0;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 10px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu > .ant-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  padding: 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: #fff;
  border-radius: 6px;
}
.ant-menu-submenu-popup .submenu-title-wrapper {
  padding-right: 20px;
}
.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.0001;
  content: ' ';
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 6px;
}
.ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background: #fff;
  background: #545454 \9;
  background-image: linear-gradient(to right, #545454, #545454);
  background-image: none \9;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(45deg) translateY(-2px);
          transform: rotate(45deg) translateY(-2px);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(-45deg) translateY(2px);
          transform: rotate(-45deg) translateY(2px);
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: linear-gradient(to right, #038fde, #038fde);
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(-45deg) translateX(2px);
          transform: rotate(-45deg) translateX(2px);
}
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(45deg) translateX(-2px);
          transform: rotate(45deg) translateX(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  -webkit-transform: rotate(-45deg) translateX(-2px);
          transform: rotate(-45deg) translateX(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  -webkit-transform: rotate(45deg) translateX(2px);
          transform: rotate(45deg) translateX(2px);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #038fde;
}
.ant-menu-vertical .ant-menu-submenu-selected > a,
.ant-menu-vertical-left .ant-menu-submenu-selected > a,
.ant-menu-vertical-right .ant-menu-submenu-selected > a {
  color: #038fde;
}
.ant-menu-horizontal {
  line-height: 46px;
  white-space: nowrap;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
  box-shadow: none;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #038fde;
  border-bottom: 2px solid #038fde;
}
.ant-menu-horizontal > .ant-menu-item > a {
  display: block;
  color: #545454;
}
.ant-menu-horizontal > .ant-menu-item > a:hover {
  color: #038fde;
}
.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected > a {
  color: #038fde;
}
.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: ' ';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #038fde;
  -webkit-transform: scaleY(0.0001);
          transform: scaleY(0.0001);
  opacity: 0;
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 42px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  font-size: 14px;
  line-height: 42px;
  text-overflow: ellipsis;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.02px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 42px;
  line-height: 42px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  opacity: 1;
  transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 32px !important;
  text-overflow: clip;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 42px;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 42px;
  line-height: 42px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  border-color: transparent !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > a,
.ant-menu-submenu-disabled > a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: #038fdd;
  background: #fffffe;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #038fdd;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  border-color: #fffffe;
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a {
  color: #038fdd;
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fa8c15;
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  background: #fa8c15;
}
.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fa8c15;
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > a:hover {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #ffffff;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: fixed;
  top: 16px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice:first-child {
  margin-top: -8px;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: all;
}
.ant-message-success .anticon {
  color: #52c41a;
}
.ant-message-error .anticon {
  color: #f5222d;
}
.ant-message-warning .anticon {
  color: #fa8c16;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #1890ff;
}
.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.ant-message-notice.move-up-leave.move-up-leave-active {
  overflow: hidden;
  -webkit-animation-name: MessageMoveOut;
          animation-name: MessageMoveOut;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
}
@-webkit-keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 100px;
  width: auto;
  margin: 0 auto;
  padding-bottom: 24px;
  pointer-events: none;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-modal-title {
  margin: 0;
  color: #535353;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: auto;
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: #595959;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  color: #545454;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 6px 6px 0 0;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.3;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 6px 6px;
}
.ant-modal-footer button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal.zoom-enter,
.ant-modal.zoom-appear {
  -webkit-transform: none;
          transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  filter: alpha(opacity=50);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(84vw);
    margin: 8px auto;
  }
  .ant-modal-centered .ant-modal {
    flex: 1 1;
  }
}
.ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-close {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper {
  zoom: 1;
}
.ant-modal-confirm-body-wrapper::before,
.ant-modal-confirm-body-wrapper::after {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  clear: both;
}
.ant-modal-confirm-body-wrapper::before,
.ant-modal-confirm-body-wrapper::after {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  clear: both;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: #535353;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: #545454;
  font-size: 14px;
}
.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}
.ant-modal-confirm .ant-modal-confirm-btns button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #f5222d;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #fa8c16;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #1890ff;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
}
.ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: fixed;
  z-index: 1010;
  width: 384px;
  max-width: calc(100vw - 32px);
  margin-right: 24px;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationLeftFadeIn;
          animation-name: NotificationLeftFadeIn;
}
.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.ant-notification-notice {
  position: relative;
  margin-bottom: 16px;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.ant-notification-notice-message {
  display: inline-block;
  margin-bottom: 8px;
  color: #535353;
  font-size: 16px;
  line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
.ant-notification-notice-description {
  font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
  color: #52c41a;
}
.anticon.ant-notification-notice-icon-info {
  color: #1890ff;
}
.anticon.ant-notification-notice-icon-warning {
  color: #fa8c16;
}
.anticon.ant-notification-notice-icon-error {
  color: #f5222d;
}
.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: #595959;
  outline: none;
}
.ant-notification-notice-close:hover {
  color: #353535;
}
.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification .notification-fade-effect {
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
  opacity: 0;
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-notification-fade-leave {
  -webkit-animation-duration: 0.24s;
          animation-duration: 0.24s;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
  -webkit-animation-name: NotificationFadeIn;
          animation-name: NotificationFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  -webkit-animation-name: NotificationFadeOut;
          animation-name: NotificationFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
@-webkit-keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@-webkit-keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@-webkit-keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px 24px;
    padding-bottom: 16px 24px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    padding-top: 16px 24px;
    padding-bottom: 16px 24px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}
.ant-page-header-ghost {
  background-color: inherit;
}
.ant-page-header.has-breadcrumb {
  padding-top: 12px;
}
.ant-page-header.has-footer {
  padding-bottom: 0;
}
.ant-page-header-back {
  float: left;
  margin: 8px 0;
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.ant-page-header-back-button {
  color: #038fde;
  text-decoration: none;
  outline: none;
  transition: color 0.3s;
  color: #000;
  cursor: pointer;
}
.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
  color: #28aaeb;
}
.ant-page-header-back-button:active {
  color: #006eb8;
}
.ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}
.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px;
}
.ant-page-header-heading {
  width: 100%;
  overflow: hidden;
}
.ant-page-header-heading-title {
  display: block;
  float: left;
  margin-bottom: 0;
  padding-right: 12px;
  color: #535353;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}
.ant-page-header-heading .ant-avatar {
  float: left;
  margin-right: 12px;
}
.ant-page-header-heading-sub-title {
  float: left;
  margin: 5px 0;
  margin-right: 12px;
  color: #595959;
  font-size: 14px;
  line-height: 22px;
}
.ant-page-header-heading-tags {
  float: left;
  margin: 4px 0;
}
.ant-page-header-heading-extra {
  float: right;
}
.ant-page-header-heading-extra > * {
  margin-left: 8px;
}
.ant-page-header-heading-extra > *:first-child {
  margin-left: 0;
}
.ant-page-header-content {
  padding-top: 12px;
  overflow: hidden;
}
.ant-page-header-footer {
  margin-top: 16px;
}
.ant-page-header-footer .ant-tabs-bar {
  margin-bottom: 1px;
  border-bottom: 0;
}
.ant-page-header-footer .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
  padding: 8px;
  font-size: 16px;
}
@media (max-width: 576px) {
  .ant-page-header-heading-extra {
    display: block;
    float: unset;
    width: 100%;
    padding-top: 12px;
    overflow: hidden;
  }
}
.ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: #545454;
  transition: none;
}
.ant-pagination-item a:hover {
  text-decoration: none;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #038fde;
  transition: all 0.3s;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #038fde;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #038fde;
}
.ant-pagination-item-active a {
  color: #038fde;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #28aaeb;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #28aaeb;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  display: inline-block;
  font-size: 12px;
  font-size: 12px \9;
  -webkit-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
  color: #038fde;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
:root .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
:root .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  font-size: 12px;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: #545454;
  font-family: Arial;
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-pagination-prev,
.ant-pagination-next {
  outline: 0;
}
.ant-pagination-prev a,
.ant-pagination-next a {
  color: #545454;
  -webkit-user-select: none;
          user-select: none;
}
.ant-pagination-prev:hover a,
.ant-pagination-next:hover a {
  border-color: #28aaeb;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  height: 100%;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  outline: none;
  transition: all 0.3s;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #038fde;
  border-color: #038fde;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
  cursor: not-allowed;
}
.ant-pagination-disabled a,
.ant-pagination-disabled:hover a,
.ant-pagination-disabled:focus a,
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
  margin-right: 8px;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 36px;
  line-height: 36px;
  vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 36px;
  padding: 4px 11px;
  color: #545454;
  font-size: 14px;
  line-height: 1.3;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s;
  width: 50px;
  margin: 0 8px;
}
.ant-pagination-options-quick-jumper input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #28aaeb;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus {
  border-color: #28aaeb;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
}
.ant-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 36px;
  line-height: 1.3;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-pagination-options-quick-jumper input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  border: 0;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  outline: none;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #038fde;
}
.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper input {
  height: 24px;
  padding: 1px 7px;
  width: 44px;
}
.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #dbdbdb;
  border-color: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: #fff;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link:hover,
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link:focus {
  color: #595959;
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 0;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination.ant-pagination-disabled .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 1;
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}
.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
          user-select: text;
}
.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.ant-popover-hidden {
  display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 10px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 10px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 10px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 10px;
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */
  }
  .ant-popover-inner {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
}
.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: #535353;
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
}
.ant-popover-inner-content {
  padding: 12px 16px;
  color: #545454;
}
.ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: #545454;
  font-size: 14px;
}
.ant-popover-message > .anticon {
  position: absolute;
  top: 8px;
  color: #fa8c16;
  font-size: 14px;
}
.ant-popover-message-title {
  padding-left: 22px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.ant-popover-buttons button {
  margin-left: 8px;
}
.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  left: 6px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #fff;
  border-left-color: #fff;
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(45deg);
          transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  right: 6px;
  border-top-color: #fff;
  border-right-color: #fff;
  border-bottom-color: transparent;
  border-left-color: transparent;
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(45deg);
          transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
.ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
.ant-progress-circle-trail {
  stroke: #f5f5f5;
}
.ant-progress-circle-path {
  -webkit-animation: ant-progress-appear 0.3s;
          animation: ant-progress-appear 0.3s;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #038fde;
}
.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #038fde;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #52c41a;
}
.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: #595959;
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.ant-progress-text .anticon {
  font-size: 14px;
}
.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  -webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
          animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: #f5222d;
}
.ant-progress-status-exception .ant-progress-text {
  color: #f5222d;
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #f5222d;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #52c41a;
}
.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #52c41a;
}
.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #545454;
  line-height: 1;
  white-space: normal;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #f5222d;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #52c41a;
}
@-webkit-keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
@keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  margin-right: 8px;
  white-space: nowrap;
  cursor: pointer;
}
.ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: sub;
  outline: none;
  cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #038fde;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(3, 143, 222, 0.08);
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #038fde;
  border-radius: 50%;
  visibility: hidden;
  -webkit-animation: antRadioEffect 0.36s ease-in-out;
          animation: antRadioEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  content: '';
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color: #038fde;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #038fde;
}
.ant-radio-checked .ant-radio-inner::after {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 36px;
  margin: 0;
  padding: 0 15px;
  color: #545454;
  line-height: 34px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s;
}
.ant-radio-button-wrapper a {
  color: #545454;
}
.ant-radio-button-wrapper > .ant-radio-button {
  display: block;
  width: 0;
  height: 0;
  margin-left: 0;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: 0;
  left: -1px;
  display: block;
  width: 1px;
  height: 100%;
  background-color: #d9d9d9;
  content: '';
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 6px 0 0 6px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 6px 6px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 6px;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: #038fde;
}
.ant-radio-button-wrapper:focus-within {
  outline: 3px solid rgba(3, 143, 222, 0.06);
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #038fde;
  background: #fff;
  border-color: #038fde;
  box-shadow: -1px 0 0 0 #038fde;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #038fde !important;
  opacity: 0.1;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #038fde;
  box-shadow: none !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #28aaeb;
  border-color: #28aaeb;
  box-shadow: -1px 0 0 0 #28aaeb;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #006eb8;
  border-color: #006eb8;
  box-shadow: -1px 0 0 0 #006eb8;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: 3px solid rgba(3, 143, 222, 0.06);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #038fde;
  border-color: #038fde;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #28aaeb;
  border-color: #28aaeb;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #006eb8;
  border-color: #006eb8;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  outline: 3px solid rgba(3, 143, 222, 0.06);
}
.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: #fff;
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  box-shadow: none;
}
@-webkit-keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1.6);
            transform: scale(1.6);
    opacity: 0;
  }
}
@supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
  .ant-radio {
    vertical-align: text-bottom;
  }
}
.ant-rate {
  box-sizing: border-box;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}
.ant-rate-disabled .ant-rate-star {
  cursor: default;
}
.ant-rate-disabled .ant-rate-star:hover {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.ant-rate-star {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  color: inherit;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-rate-star:not(:last-child) {
  margin-right: 8px;
}
.ant-rate-star > div:focus {
  outline: 0;
}
.ant-rate-star > div:hover,
.ant-rate-star > div:focus {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.ant-rate-star-first,
.ant-rate-star-second {
  color: #e8e8e8;
  transition: all 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: middle;
}
.ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}
.ant-rate-text {
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
}
.ant-result {
  padding: 48px 32px;
}
.ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
}
.ant-result-error .ant-result-icon > .anticon {
  color: #f5222d;
}
.ant-result-info .ant-result-icon > .anticon {
  color: #1890ff;
}
.ant-result-warning .ant-result-icon > .anticon {
  color: #fa8c16;
}
.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}
.ant-result-icon {
  margin-bottom: 24px;
  text-align: center;
}
.ant-result-icon > .anticon {
  font-size: 72px;
}
.ant-result-title {
  color: #535353;
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}
.ant-result-subtitle {
  color: #595959;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}
.ant-result-extra {
  margin-top: 32px;
  text-align: center;
}
.ant-result-extra > * {
  margin-right: 8px;
}
.ant-result-extra > *:last-child {
  margin-right: 0;
}
.ant-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  outline: 0;
}
.ant-select ul,
.ant-select ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-select > ul > li > a {
  padding: 0;
  background-color: #fff;
}
.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.ant-select-arrow > * {
  line-height: 1;
}
.ant-select-arrow svg {
  display: inline-block;
}
.ant-select-arrow::before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
.ant-select-arrow .ant-select-arrow-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-selection {
  display: block;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-radius: 6px;
  outline: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
          user-select: none;
}
.ant-select-selection:hover {
  border-color: #28aaeb;
  border-right-width: 1px !important;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #28aaeb;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
}
.ant-select-selection__clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-selection__clear::before {
  display: block;
}
.ant-select-selection__clear:hover {
  color: #595959;
}
.ant-select-selection:hover .ant-select-selection__clear {
  opacity: 1;
}
.ant-select-selection-selected-value {
  float: left;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-no-arrow .ant-select-selection-selected-value {
  padding-right: 0;
}
.ant-select-disabled {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-disabled .ant-select-selection {
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-disabled .ant-select-selection:hover,
.ant-select-disabled .ant-select-selection:focus,
.ant-select-disabled .ant-select-selection:active {
  border-color: #d9d9d9;
  box-shadow: none;
}
.ant-select-disabled .ant-select-selection__clear {
  display: none;
  visibility: hidden;
  pointer-events: none;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice {
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.33);
  background: #f5f5f5;
}
.ant-select-disabled .ant-select-selection--multiple .ant-select-selection__choice__remove {
  display: none;
}
.ant-select-selection--single {
  position: relative;
  height: 36px;
  cursor: pointer;
}
.ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 24px;
}
.ant-select-no-arrow .ant-select-selection__rendered {
  margin-right: 11px;
}
.ant-select-selection__rendered {
  position: relative;
  display: block;
  margin-right: 11px;
  margin-left: 11px;
  line-height: 34px;
}
.ant-select-selection__rendered::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '.';
  pointer-events: none;
}
.ant-select-lg {
  font-size: 16px;
}
.ant-select-lg .ant-select-selection--single {
  height: 40px;
}
.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px;
}
.ant-select-lg .ant-select-selection--multiple {
  min-height: 40px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 32px;
  line-height: 32px;
}
.ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-lg .ant-select-selection--multiple .ant-select-arrow {
  top: 20px;
}
.ant-select-sm .ant-select-selection--single {
  height: 24px;
}
.ant-select-sm .ant-select-selection__rendered {
  margin-left: 7px;
  line-height: 22px;
}
.ant-select-sm .ant-select-selection--multiple {
  min-height: 24px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
  height: 16px;
  line-height: 14px;
}
.ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-sm .ant-select-selection--multiple .ant-select-arrow {
  top: 12px;
}
.ant-select-sm .ant-select-selection__clear,
.ant-select-sm .ant-select-arrow {
  right: 8px;
}
.ant-select-disabled .ant-select-selection__choice__remove {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
}
.ant-select-disabled .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-search__field__wrap {
  position: relative;
  display: inline-block;
}
.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  position: absolute;
  top: 50%;
  right: 9px;
  left: 0;
  max-width: 100%;
  height: 20px;
  margin-top: -10px;
  overflow: hidden;
  color: #bfbfbf;
  line-height: 20px;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
}
.ant-select-search__field__placeholder {
  left: 12px;
}
.ant-select-search__field__mirror {
  position: absolute;
  top: 0;
  left: 0;
  white-space: pre;
  opacity: 0;
  pointer-events: none;
}
.ant-select-search--inline {
  position: absolute;
  width: 100%;
  height: 100%;
}
.ant-select-search--inline .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-search--inline .ant-select-search__field {
  width: 100%;
  height: 100%;
  font-size: 100%;
  line-height: 1;
  background: transparent;
  border-width: 0;
  border-radius: 6px;
  outline: 0;
}
.ant-select-search--inline > i {
  float: right;
}
.ant-select-selection--multiple {
  min-height: 36px;
  padding-bottom: 3px;
  cursor: text;
  zoom: 1;
}
.ant-select-selection--multiple::before,
.ant-select-selection--multiple::after {
  display: table;
  content: '';
}
.ant-select-selection--multiple::after {
  clear: both;
}
.ant-select-selection--multiple::before,
.ant-select-selection--multiple::after {
  display: table;
  content: '';
}
.ant-select-selection--multiple::after {
  clear: both;
}
.ant-select-selection--multiple .ant-select-search--inline {
  position: static;
  float: left;
  width: auto;
  max-width: 100%;
  padding: 0;
}
.ant-select-selection--multiple .ant-select-search--inline .ant-select-search__field {
  width: 0.75em;
  max-width: 100%;
  padding: 1px;
}
.ant-select-selection--multiple .ant-select-selection__rendered {
  height: auto;
  margin-bottom: -3px;
  margin-left: 5px;
}
.ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-left: 6px;
}
.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 28px;
  margin-top: 3px;
  line-height: 26px;
}
.ant-select-selection--multiple .ant-select-selection__choice {
  position: relative;
  float: left;
  max-width: 99%;
  margin-right: 4px;
  padding: 0 20px 0 10px;
  overflow: hidden;
  color: #545454;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  cursor: default;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__disabled {
  padding: 0 10px;
}
.ant-select-selection--multiple .ant-select-selection__choice__content {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  color: #595959;
  font-weight: bold;
  line-height: inherit;
  cursor: pointer;
  transition: all 0.3s;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
.ant-select-selection--multiple .ant-select-selection__choice__remove > * {
  line-height: 1;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove svg {
  display: inline-block;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove::before {
  display: none;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove .ant-select-selection--multiple .ant-select-selection__choice__remove-icon {
  display: block;
}
:root .ant-select-selection--multiple .ant-select-selection__choice__remove {
  font-size: 12px;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-select-selection--multiple .ant-select-selection__clear,
.ant-select-selection--multiple .ant-select-arrow {
  top: 18px;
}
.ant-select-allow-clear .ant-select-selection--multiple .ant-select-selection__rendered,
.ant-select-show-arrow .ant-select-selection--multiple .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-open .ant-select-arrow-icon svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.ant-select-open .ant-select-selection {
  border-color: #28aaeb;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
}
.ant-select-combobox .ant-select-arrow {
  display: none;
}
.ant-select-combobox .ant-select-search--inline {
  float: none;
  width: 100%;
  height: 100%;
}
.ant-select-combobox .ant-select-search__field__wrap {
  width: 100%;
  height: 100%;
}
.ant-select-combobox .ant-select-search__field {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
}
.ant-select-combobox.ant-select-allow-clear .ant-select-selection:hover .ant-select-selection__rendered,
.ant-select-combobox.ant-select-show-arrow .ant-select-selection:hover .ant-select-selection__rendered {
  margin-right: 20px;
}
.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: #545454;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum", ;
          font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 6px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding: 4px 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-select-dropdown-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  padding-left: 20px;
}
.ant-select-dropdown-menu-item-group-title {
  height: 32px;
  padding: 0 12px;
  color: #595959;
  font-size: 12px;
  line-height: 32px;
}
.ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:first-child:not(:last-child),
.ant-select-dropdown-menu-item-group:not(:last-child) .ant-select-dropdown-menu-item-group-list .ant-select-dropdown-menu-item:last-child {
  border-radius: 0;
}
.ant-select-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: #545454;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6faff;
}
.ant-select-dropdown-menu-item-selected {
  color: #545454;
  font-weight: 600;
  background-color: #fafafa;
}
.ant-select-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6faff;
}
.ant-select-dropdown-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 32px;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  color: transparent;
  font-weight: bold;
  font-size: 12px;
  text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: all 0.2s;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item:hover .ant-select-selected-icon {
  color: rgba(0, 0, 0, 0.87);
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-disabled .ant-select-selected-icon {
  display: none;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected .ant-select-selected-icon,
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item-selected:hover .ant-select-selected-icon {
  display: inline-block;
  color: #038fde;
}
.ant-select-dropdown--empty.ant-select-dropdown--multiple .ant-select-dropdown-menu-item {
  padding-right: 12px;
}
.ant-select-dropdown-container-open .ant-select-dropdown,
.ant-select-dropdown-open .ant-select-dropdown {
  display: block;
}
.ant-skeleton {
  display: table;
  width: 100%;
}
.ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: #f2f2f2;
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  margin-top: 16px;
  background: #f2f2f2;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}
.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: #f2f2f2;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
          animation: ant-skeleton-loading 1.4s ease infinite;
}
@-webkit-keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  height: 12px;
  margin: 14px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-bottom: -7px;
  margin-left: -5px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  left: 2px;
  margin-bottom: -4px;
}
.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}
.ant-slider-with-marks {
  margin-bottom: 28px;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 4px;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #7ddcff;
  border-radius: 6px;
  transition: background-color 0.3s;
}
.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #7ddcff;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle:focus {
  border-color: #35a5e5;
  outline: none;
  box-shadow: 0 0 0 5px rgba(3, 143, 222, 0.2);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #038fde;
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #52c6f7;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #52c6f7;
}
.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: #595959;
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
}
.ant-slider-mark-text-active {
  color: #545454;
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background-color: #fff;
  border: 2px solid #e8e8e8;
  border-radius: 50%;
  cursor: pointer;
}
.ant-slider-dot:first-child {
  margin-left: -4px;
}
.ant-slider-dot:last-child {
  margin-left: -4px;
}
.ant-slider-dot-active {
  border-color: #81c7ef;
}
.ant-slider-disabled {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: none;
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  display: none;
  color: #038fde;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.5;
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
}
.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: #595959;
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #038fde;
  border-radius: 100%;
  -webkit-transform: scale(0.75);
          transform: scale(0.75);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  opacity: 0.3;
  -webkit-animation: antSpinMove 1s infinite linear alternate;
          animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-animation: antRotate 1.2s infinite linear;
          animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
}
@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
}
.ant-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
}
.ant-statistic-title {
  margin-bottom: 4px;
  color: #595959;
  font-size: 14px;
}
.ant-statistic-content {
  color: #535353;
  font-size: 24px;
  font-family: 'NoirPro', sans-serif;
}
.ant-statistic-content-value-decimal {
  font-size: 16px;
}
.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
  display: inline-block;
}
.ant-statistic-content-prefix {
  margin-right: 4px;
}
.ant-statistic-content-suffix {
  margin-left: 4px;
  font-size: 16px;
}
.ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: flex;
  width: 100%;
  font-size: 0;
}
.ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1 1;
  overflow: hidden;
  vertical-align: top;
}
.ant-steps-item-container {
  outline: none;
}
.ant-steps-item:last-child {
  flex: none;
}
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-item-icon,
.ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  font-size: 16px;
  font-family: 'NoirPro', sans-serif;
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
.ant-steps-item-icon > .ant-steps-icon {
  position: relative;
  top: -1px;
  color: #038fde;
  line-height: 1;
}
.ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
.ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #e8e8e8;
  border-radius: 1px;
  transition: background 0.3s;
  content: '';
}
.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: #545454;
  font-size: 16px;
  line-height: 32px;
}
.ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #e8e8e8;
  content: '';
}
.ant-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  color: #595959;
  font-weight: normal;
  font-size: 14px;
}
.ant-steps-item-description {
  color: #595959;
  font-size: 14px;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #595959;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #595959;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #038fde;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #038fde;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #038fde;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #535353;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #545454;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: #038fde;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process .ant-steps-item-title {
  font-weight: 500;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #038fde;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #038fde;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #038fde;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #545454;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #038fde;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #595959;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #038fde;
}
.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #f5222d;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #f5222d;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #f5222d;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #f5222d;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #e8e8e8;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #f5222d;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #e8e8e8;
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #f5222d;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon {
  transition: color 0.3s;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #038fde;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #038fde;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #038fde;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 16px;
  white-space: nowrap;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
.ant-steps-item-custom .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}
.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  top: 0;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #038fde;
}
.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 12px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child {
  margin-right: 0;
}
.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
.ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}
.ant-steps-small .ant-steps-item-description {
  color: #595959;
  font-size: 14px;
}
.ant-steps-small .ant-steps-item-tail {
  top: 8px;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  -webkit-transform: none;
          transform: none;
}
.ant-steps-vertical {
  display: block;
}
.ant-steps-vertical .ant-steps-item {
  display: block;
  overflow: visible;
}
.ant-steps-vertical .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}
.ant-steps-vertical .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
.ant-steps-vertical .ant-steps-item-title {
  line-height: 32px;
}
.ant-steps-vertical .ant-steps-item-description {
  padding-bottom: 12px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 12px;
  padding: 30px 0 6px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px;
}
@media (max-width: 480px) {
  .ant-steps-horizontal.ant-steps-label-horizontal {
    display: block;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: block;
    overflow: visible;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    float: left;
    margin-right: 16px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
    display: block;
    min-height: 48px;
    overflow: hidden;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-title {
    line-height: 32px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-description {
    padding-bottom: 12px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 16px;
    width: 1px;
    height: 100%;
    padding: 38px 0 6px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
    width: 1px;
    height: 100%;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
    display: block;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    display: none;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 12px;
    padding: 30px 0 6px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
    line-height: 24px;
  }
}
.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}
.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px;
}
.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 42px;
}
.ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-label-vertical .ant-steps-item-title::after {
  display: none;
}
.ant-steps-label-vertical .ant-steps-item-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 1.3;
}
.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 46px;
}
.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.3;
}
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}
.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
  /* expand hover area */
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}
.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 140px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  width: 10px;
  height: 10px;
  line-height: 10px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon-dot {
  top: -1px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 8px;
  margin-left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 2px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  left: -2px;
}
.ant-steps-navigation {
  padding-top: 12px;
}
.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: -12px;
}
.ant-steps-navigation .ant-steps-item {
  overflow: visible;
  text-align: center;
}
.ant-steps-navigation .ant-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  text-align: left;
  transition: opacity 0.3s;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: auto;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
  opacity: 0.85;
}
.ant-steps-navigation .ant-steps-item:last-child {
  flex: 1 1;
}
.ant-steps-navigation .ant-steps-item:last-child::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item::after {
  position: absolute;
  top: 50%;
  left: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-left: none;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  content: '';
}
.ant-steps-navigation .ant-steps-item::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 3px;
  background-color: #038fde;
  transition: width 0.3s, left 0.3s;
  transition-timing-function: ease-out;
  content: '';
}
.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  left: 0;
  width: 100%;
}
@media (max-width: 480px) {
  .ant-steps-navigation > .ant-steps-item {
    margin-right: 0 !important;
  }
  .ant-steps-navigation > .ant-steps-item::before {
    display: none;
  }
  .ant-steps-navigation > .ant-steps-item.ant-steps-item-active::before {
    top: 0;
    right: 0;
    left: unset;
    display: block;
    width: 3px;
    height: calc(76%);
  }
  .ant-steps-navigation > .ant-steps-item::after {
    position: relative;
    top: -2px;
    left: 50%;
    display: block;
    width: 8px;
    height: 8px;
    margin-bottom: 8px;
    text-align: center;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  .ant-steps-navigation > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    visibility: hidden;
  }
}
.ant-steps-flex-not-supported.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
  margin-left: -16px;
  padding-left: 16px;
  background: #fff;
}
.ant-steps-flex-not-supported.ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item {
  margin-left: -12px;
  padding-left: 12px;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item:last-child {
  overflow: hidden;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item:last-child .ant-steps-icon-dot::after {
  right: -200px;
  width: 200px;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::before,
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::after {
  position: absolute;
  top: 0;
  left: -10px;
  width: 10px;
  height: 8px;
  background: #fff;
  content: '';
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item .ant-steps-icon-dot::after {
  right: -10px;
  left: auto;
}
.ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ccc;
}
.ant-switch {
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 20px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.36s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-switch-inner {
  display: block;
  margin-right: 6px;
  margin-left: 24px;
  color: #fff;
  font-size: 12px;
}
.ant-switch-loading-icon,
.ant-switch::after {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 18px;
  cursor: pointer;
  transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-switch::after {
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
}
.ant-switch:not(.ant-switch-disabled):active::before,
.ant-switch:not(.ant-switch-disabled):active::after {
  width: 24px;
}
.ant-switch-loading-icon {
  z-index: 1;
  display: none;
  font-size: 12px;
  background: transparent;
}
.ant-switch-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-switch-loading .ant-switch-loading-icon {
  display: inline-block;
  color: #545454;
}
.ant-switch-checked.ant-switch-loading .ant-switch-loading-icon {
  color: #038fde;
}
.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
}
.ant-switch:focus:hover {
  box-shadow: none;
}
.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 14px;
}
.ant-switch-small .ant-switch-inner {
  margin-right: 3px;
  margin-left: 18px;
  font-size: 12px;
}
.ant-switch-small::after {
  width: 12px;
  height: 12px;
}
.ant-switch-small:active::before,
.ant-switch-small:active::after {
  width: 16px;
}
.ant-switch-small .ant-switch-loading-icon {
  width: 12px;
  height: 12px;
}
.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin-right: 18px;
  margin-left: 3px;
}
.ant-switch-small.ant-switch-checked .ant-switch-loading-icon {
  left: 100%;
  margin-left: -13px;
}
.ant-switch-small.ant-switch-loading .ant-switch-loading-icon {
  font-weight: bold;
  -webkit-transform: scale(0.66667);
          transform: scale(0.66667);
}
.ant-switch-checked {
  background-color: #038fde;
}
.ant-switch-checked .ant-switch-inner {
  margin-right: 24px;
  margin-left: 6px;
}
.ant-switch-checked::after {
  left: 100%;
  margin-left: -1px;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.ant-switch-checked .ant-switch-loading-icon {
  left: 100%;
  margin-left: -19px;
}
.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.ant-switch-loading *,
.ant-switch-disabled * {
  cursor: not-allowed;
}
.ant-switch-loading::before,
.ant-switch-disabled::before,
.ant-switch-loading::after,
.ant-switch-disabled::after {
  cursor: not-allowed;
}
@-webkit-keyframes AntSwitchSmallLoadingCircle {
  0% {
    -webkit-transform: rotate(0deg) scale(0.66667);
            transform: rotate(0deg) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: rotate(360deg) scale(0.66667);
            transform: rotate(360deg) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
}
@keyframes AntSwitchSmallLoadingCircle {
  0% {
    -webkit-transform: rotate(0deg) scale(0.66667);
            transform: rotate(0deg) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: rotate(360deg) scale(0.66667);
            transform: rotate(360deg) scale(0.66667);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
}
.ant-table-wrapper {
  zoom: 1;
}
.ant-table-wrapper::before,
.ant-table-wrapper::after {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  clear: both;
}
.ant-table-wrapper::before,
.ant-table-wrapper::after {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  clear: both;
}
.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  clear: both;
}
.ant-table-body {
  transition: opacity 0.3s;
}
.ant-table-empty .ant-table-body {
  overflow-x: auto !important;
  overflow-y: hidden !important;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 6px 6px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table-layout-fixed table {
  table-layout: fixed;
}
.ant-table-thead > tr > th {
  color: #535353;
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 100%;
  color: #bfbfbf;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-thead > tr > th .anticon-filter > svg,
.ant-table-thead > tr > th .ant-table-filter-icon > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5px;
  margin-left: -6px;
}
.ant-table-thead > tr > th .ant-table-filter-selected.anticon {
  color: #038fde;
}
.ant-table-thead > tr > th .ant-table-column-sorter {
  display: table-cell;
  vertical-align: middle;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
  height: 1em;
  margin-top: 0.35em;
  margin-left: 0.57142857em;
  color: #bfbfbf;
  line-height: 1em;
  text-align: center;
  transition: all 0.3s;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down {
  display: inline-block;
  font-size: 12px;
  font-size: 11px \9;
  -webkit-transform: scale(0.91666667) rotate(0deg);
          transform: scale(0.91666667) rotate(0deg);
  display: block;
  height: 1em;
  line-height: 1em;
  transition: all 0.3s;
}
:root .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up,
:root .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down {
  font-size: 12px;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
  color: #038fde;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full {
  margin-top: -0.15em;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-up,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-down {
  height: 0.5em;
  line-height: 0.5em;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full .ant-table-column-sorter-down {
  margin-top: 0.125em;
}
.ant-table-thead > tr > th.ant-table-column-has-actions {
  position: relative;
  background-clip: padding-box;
  /* stylelint-disable-next-line */
  -webkit-background-clip: border-box;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
  padding-right: 30px !important;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
  color: #595959;
  background: #e5e5e5;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover {
  color: #595959;
  background: #e5e5e5;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:active,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:active {
  color: #545454;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters {
  cursor: pointer;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
  background: #f2f2f2;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
  background: #f2f2f2;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-up:not(.on),
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:active .ant-table-column-sorter-down:not(.on) {
  color: #595959;
}
.ant-table-thead > tr > th .ant-table-header-column {
  display: inline-block;
  max-width: 100%;
  vertical-align: top;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters {
  display: table;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters > .ant-table-column-title {
  display: table-cell;
  vertical-align: middle;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters > *:not(.ant-table-column-sorter) {
  position: relative;
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters:hover::before {
  background: rgba(0, 0, 0, 0.04);
}
.ant-table-thead > tr > th.ant-table-column-has-sorters {
  -webkit-user-select: none;
          user-select: none;
}
.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 6px;
}
.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 6px;
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #e8e8e8;
  transition: all 0.3s, border 0s;
}
.ant-table-thead > tr,
.ant-table-tbody > tr {
  transition: all 0.3s, height 0s;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #e6faff;
}
.ant-table-thead > tr.ant-table-row-selected > td.ant-table-column-sort,
.ant-table-tbody > tr.ant-table-row-selected > td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-thead > tr:hover.ant-table-row-selected > td,
.ant-table-tbody > tr:hover.ant-table-row-selected > td {
  background: #fafafa;
}
.ant-table-thead > tr:hover.ant-table-row-selected > td.ant-table-column-sort,
.ant-table-tbody > tr:hover.ant-table-row-selected > td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-thead > tr:hover {
  background: none;
}
.ant-table-footer {
  position: relative;
  padding: 16px 16px;
  color: #535353;
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 6px 6px;
}
.ant-table-footer::before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fafafa;
  content: '';
}
.ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #e8e8e8;
}
.ant-table-title {
  position: relative;
  top: 1px;
  padding: 16px 0;
  border-radius: 6px 6px 0 0;
}
.ant-table.ant-table-bordered .ant-table-title {
  padding-right: 16px;
  padding-left: 16px;
  border: 1px solid #e8e8e8;
}
.ant-table-title + .ant-table-content {
  position: relative;
  border-radius: 6px 6px 0 0;
}
.ant-table-bordered .ant-table-title + .ant-table-content,
.ant-table-bordered .ant-table-title + .ant-table-content table,
.ant-table-bordered .ant-table-title + .ant-table-content .ant-table-thead > tr:first-child > th {
  border-radius: 0;
}
.ant-table-without-column-header .ant-table-title + .ant-table-content,
.ant-table-without-column-header table {
  border-radius: 0;
}
.ant-table-without-column-header.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-top: 1px solid #e8e8e8;
  border-radius: 6px;
}
.ant-table-tbody > tr.ant-table-row-selected td {
  color: inherit;
  background: #fafafa;
}
.ant-table-thead > tr > th.ant-table-column-sort {
  background: #f5f5f5;
}
.ant-table-tbody > tr > td.ant-table-column-sort {
  background: rgba(0, 0, 0, 0.01);
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.ant-table-expand-icon-th,
.ant-table-row-expand-icon-cell {
  width: 50px;
  min-width: 50px;
  text-align: center;
}
.ant-table-header {
  overflow: hidden;
  background: #fafafa;
}
.ant-table-header table {
  border-radius: 6px 6px 0 0;
}
.ant-table-loading {
  position: relative;
}
.ant-table-loading .ant-table-body {
  background: #fff;
  opacity: 0.5;
}
.ant-table-loading .ant-table-spin-holder {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 20px;
  margin-left: -30px;
  line-height: 20px;
}
.ant-table-loading .ant-table-with-pagination {
  margin-top: -20px;
}
.ant-table-loading .ant-table-without-pagination {
  margin-top: 10px;
}
.ant-table-bordered .ant-table-header > table,
.ant-table-bordered .ant-table-body > table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table {
  border: 1px solid #e8e8e8;
  border-right: 0;
  border-bottom: 0;
}
.ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-header > table {
  border-bottom: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-header + .ant-table-body > table,
.ant-table-bordered.ant-table-fixed-header .ant-table-body-inner > table {
  border-top: 0;
}
.ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #e8e8e8;
}
.ant-table-placeholder {
  position: relative;
  z-index: 1;
  margin-top: -1px;
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 6px 6px;
}
.ant-table-pagination.ant-pagination {
  float: right;
  margin: 16px 0;
}
.ant-table-filter-dropdown {
  position: relative;
  min-width: 96px;
  margin-left: -8px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  border: 0;
  border-radius: 6px 6px 0 0;
  box-shadow: none;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
  padding-right: 0;
}
.ant-table-filter-dropdown .ant-dropdown-menu-sub {
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-submenu-contain-selected .ant-dropdown-menu-submenu-title::after {
  color: #038fde;
  font-weight: bold;
  text-shadow: 0 0 2px #a6eaff;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item {
  overflow: hidden;
}
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-item:last-child,
.ant-table-filter-dropdown > .ant-dropdown-menu > .ant-dropdown-menu-submenu:last-child .ant-dropdown-menu-submenu-title {
  border-radius: 0;
}
.ant-table-filter-dropdown-btns {
  padding: 7px 8px;
  overflow: hidden;
  border-top: 1px solid #e8e8e8;
}
.ant-table-filter-dropdown-link {
  color: #038fde;
}
.ant-table-filter-dropdown-link:hover {
  color: #28aaeb;
}
.ant-table-filter-dropdown-link:active {
  color: #006eb8;
}
.ant-table-filter-dropdown-link.confirm {
  float: left;
}
.ant-table-filter-dropdown-link.clear {
  float: right;
}
.ant-table-selection {
  white-space: nowrap;
}
.ant-table-selection-select-all-custom {
  margin-right: 4px !important;
}
.ant-table-selection .anticon-down {
  color: #bfbfbf;
  transition: all 0.3s;
}
.ant-table-selection-menu {
  min-width: 96px;
  margin-top: 5px;
  margin-left: -30px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-table-selection-menu .ant-action-down {
  color: #bfbfbf;
}
.ant-table-selection-down {
  display: inline-block;
  padding: 0;
  line-height: 1;
  cursor: pointer;
}
.ant-table-selection-down:hover .anticon-down {
  color: rgba(0, 0, 0, 0.6);
}
.ant-table-row-expand-icon {
  color: #038fde;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  display: inline-block;
  width: 17px;
  height: 17px;
  color: inherit;
  line-height: 13px;
  text-align: center;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #28aaeb;
}
.ant-table-row-expand-icon:active {
  color: #006eb8;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  border-color: currentColor;
}
.ant-table-row-expanded::after {
  content: '-';
}
.ant-table-row-collapsed::after {
  content: '+';
}
.ant-table-row-spaced {
  visibility: hidden;
}
.ant-table-row-spaced::after {
  content: '.';
}
.ant-table-row-cell-ellipsis,
.ant-table-row-cell-ellipsis .ant-table-column-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-table-row-cell-ellipsis .ant-table-column-title {
  display: block;
}
.ant-table-row-cell-break-word {
  word-wrap: break-word;
  word-break: break-word;
}
tr.ant-table-expanded-row,
tr.ant-table-expanded-row:hover {
  background: #fbfbfb;
}
tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -16px -16px -17px;
}
.ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 8px;
}
.ant-table-scroll {
  overflow: auto;
  overflow-x: hidden;
}
.ant-table-scroll table {
  min-width: 100%;
}
.ant-table-scroll table .ant-table-fixed-columns-in-body:not([colspan]) {
  color: transparent;
}
.ant-table-scroll table .ant-table-fixed-columns-in-body:not([colspan]) > * {
  visibility: hidden;
}
.ant-table-body-inner {
  height: 100%;
}
.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  position: relative;
  background: #fff;
}
.ant-table-fixed-header .ant-table-body-inner {
  overflow: scroll;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  margin-bottom: -20px;
  padding-bottom: 20px;
  overflow: scroll;
  opacity: 0.9999;
}
.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
  border: 1px solid #e8e8e8;
  border-width: 0 0 1px 0;
}
.ant-table-hide-scrollbar {
  scrollbar-color: transparent transparent;
  min-width: unset;
}
.ant-table-hide-scrollbar::-webkit-scrollbar {
  min-width: inherit;
  background-color: transparent;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-scroll .ant-table-header::-webkit-scrollbar {
  border: 1px solid #e8e8e8;
  border-width: 1px 1px 1px 0;
}
.ant-table-bordered.ant-table-fixed-header .ant-table-scroll .ant-table-header.ant-table-hide-scrollbar .ant-table-thead > tr:only-child > th:last-child {
  border-right-color: transparent;
}
.ant-table-fixed-left,
.ant-table-fixed-right {
  position: absolute;
  top: 0;
  z-index: 1;
  overflow: hidden;
  border-radius: 0;
  transition: box-shadow 0.3s ease;
}
.ant-table-fixed-left table,
.ant-table-fixed-right table {
  width: auto;
  background: #fff;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-outer .ant-table-fixed,
.ant-table-fixed-header .ant-table-fixed-right .ant-table-body-outer .ant-table-fixed {
  border-radius: 0;
}
.ant-table-fixed-left {
  left: 0;
  box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-left .ant-table-header {
  overflow-y: hidden;
}
.ant-table-fixed-left .ant-table-body-inner {
  margin-right: -20px;
  padding-right: 20px;
}
.ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
  padding-right: 0;
}
.ant-table-fixed-left,
.ant-table-fixed-left table {
  border-radius: 6px 0 0 0;
}
.ant-table-fixed-left .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 0;
}
.ant-table-fixed-right {
  right: 0;
  box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15);
}
.ant-table-fixed-right,
.ant-table-fixed-right table {
  border-radius: 0 6px 0 0;
}
.ant-table-fixed-right .ant-table-expanded-row {
  color: transparent;
  pointer-events: none;
}
.ant-table-fixed-right .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 0;
}
.ant-table.ant-table-scroll-position-left .ant-table-fixed-left {
  box-shadow: none;
}
.ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
  box-shadow: none;
}
.ant-table colgroup > col.ant-table-selection-col {
  width: 60px;
}
.ant-table-thead > tr > th.ant-table-selection-column-custom .ant-table-selection {
  margin-right: -15px;
}
.ant-table-thead > tr > th.ant-table-selection-column,
.ant-table-tbody > tr > td.ant-table-selection-column {
  text-align: center;
}
.ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper,
.ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
.ant-table-row[class*='ant-table-row-level-0'] .ant-table-selection-column > span {
  display: inline-block;
}
.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
/**
* Another fix of Firefox:
*/
@supports (-moz-appearance: meterbar) {
  .ant-table-thead > tr > th.ant-table-column-has-actions {
    background-clip: padding-box;
  }
}
.ant-table-middle > .ant-table-title,
.ant-table-middle > .ant-table-content > .ant-table-footer {
  padding: 12px 8px;
}
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 12px 8px;
}
.ant-table-middle tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -12px -8px -13px;
}
.ant-table-small {
  border: 1px solid #e8e8e8;
  border-radius: 6px;
}
.ant-table-small > .ant-table-title,
.ant-table-small > .ant-table-content > .ant-table-footer {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-title {
  top: 0;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content > .ant-table-footer {
  background-color: transparent;
  border-top: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content > .ant-table-footer::before {
  background-color: transparent;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  border: 0;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 8px 8px;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th {
  background-color: transparent;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr {
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th.ant-table-column-sort {
  background-color: rgba(0, 0, 0, 0.01);
}
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  padding: 0;
}
.ant-table-small > .ant-table-content .ant-table-header {
  background-color: transparent;
  border-radius: 6px 6px 0 0;
}
.ant-table-small > .ant-table-content .ant-table-placeholder,
.ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 0;
}
.ant-table-small.ant-table-bordered {
  border-right: 0;
}
.ant-table-small.ant-table-bordered .ant-table-title {
  border: 0;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-content {
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-footer {
  border: 0;
  border-top: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-footer::before {
  display: none;
}
.ant-table-small.ant-table-bordered .ant-table-placeholder {
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}
.ant-table-small.ant-table-bordered .ant-table-thead > tr > th.ant-table-row-cell-last,
.ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: none;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-thead > tr > th:last-child,
.ant-table-small.ant-table-bordered .ant-table-fixed-left .ant-table-tbody > tr > td:last-child {
  border-right: 1px solid #e8e8e8;
}
.ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
}
.ant-table-small tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: -8px -8px -9px;
}
.ant-table-small.ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  border-radius: 0 0 6px 6px;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
  height: 40px;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-ink-bar {
  visibility: hidden;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  height: 40px;
  margin: 0;
  margin-right: 2px;
  padding: 0 16px;
  line-height: 38px;
  background: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 6px 6px 0 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  height: 40px;
  color: #038fde;
  background: #fff;
  border-color: #e8e8e8;
  border-bottom: 1px solid #fff;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active::before {
  border-top: 2px solid transparent;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-disabled {
  color: #038fde;
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-inactive {
  padding: 0;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x {
  width: 16px;
  height: 16px;
  height: 14px;
  margin-right: -5px;
  margin-left: 3px;
  overflow: hidden;
  color: #595959;
  font-size: 12px;
  vertical-align: middle;
  transition: all 0.3s;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x:hover {
  color: #535353;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane,
.ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane {
  transition: none !important;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane-inactive,
.ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane-inactive {
  overflow: hidden;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab:hover .anticon-close {
  opacity: 1;
}
.ant-tabs-extra-content {
  line-height: 42.2px;
}
.ant-tabs-extra-content .ant-tabs-new-tab {
  position: relative;
  width: 20px;
  height: 20px;
  color: #545454;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-extra-content .ant-tabs-new-tab:hover {
  color: #038fde;
  border-color: #038fde;
}
.ant-tabs-extra-content .ant-tabs-new-tab svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-tabs.ant-tabs-large .ant-tabs-extra-content {
  line-height: 52.8px;
}
.ant-tabs.ant-tabs-small .ant-tabs-extra-content {
  line-height: 34.2px;
}
.ant-tabs.ant-tabs-card .ant-tabs-extra-content {
  line-height: 40px;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-nav-container {
  height: 100%;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
  margin-bottom: 8px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab-active {
  padding-bottom: 4px;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab:last-child,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab:last-child {
  margin-bottom: 8px;
}
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-new-tab,
.ant-tabs-vertical.ant-tabs-card .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-new-tab {
  width: 90%;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-nav-wrap {
  margin-right: 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab {
  margin-right: 1px;
  border-right: 0;
  border-radius: 6px 0 0 6px;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-left .ant-tabs-card-bar.ant-tabs-left-bar .ant-tabs-tab-active {
  margin-right: -1px;
  padding-right: 18px;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-left: 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab {
  margin-left: 1px;
  border-left: 0;
  border-radius: 0 6px 6px 0;
}
.ant-tabs-vertical.ant-tabs-card.ant-tabs-right .ant-tabs-card-bar.ant-tabs-right-bar .ant-tabs-tab-active {
  margin-left: -1px;
  padding-left: 18px;
}
.ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab {
  height: auto;
  border-top: 0;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 6px 6px;
}
.ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab-active {
  padding-top: 1px;
  padding-bottom: 0;
  color: #038fde;
}
.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  overflow: hidden;
  zoom: 1;
}
.ant-tabs::before,
.ant-tabs::after {
  display: table;
  content: '';
}
.ant-tabs::after {
  clear: both;
}
.ant-tabs::before,
.ant-tabs::after {
  display: table;
  content: '';
}
.ant-tabs::after {
  clear: both;
}
.ant-tabs-ink-bar {
  position: absolute;
  bottom: 1px;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  width: 0;
  height: 2px;
  background-color: #038fde;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.ant-tabs-bar {
  margin: 0 0 16px 0;
  border-bottom: 1px solid #e8e8e8;
  outline: none;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-nav-container {
  position: relative;
  box-sizing: border-box;
  margin-bottom: -1px;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.3;
  white-space: nowrap;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  zoom: 1;
}
.ant-tabs-nav-container::before,
.ant-tabs-nav-container::after {
  display: table;
  content: '';
}
.ant-tabs-nav-container::after {
  clear: both;
}
.ant-tabs-nav-container::before,
.ant-tabs-nav-container::after {
  display: table;
  content: '';
}
.ant-tabs-nav-container::after {
  clear: both;
}
.ant-tabs-nav-container-scrolling {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-tabs-bottom .ant-tabs-bottom-bar {
  margin-top: 16px;
  margin-bottom: 0;
  border-top: 1px solid #e8e8e8;
  border-bottom: none;
}
.ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-ink-bar {
  top: 1px;
  bottom: auto;
}
.ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-nav-container {
  margin-top: -1px;
  margin-bottom: 0;
}
.ant-tabs-tab-prev,
.ant-tabs-tab-next {
  position: absolute;
  z-index: 2;
  width: 0;
  height: 100%;
  color: #595959;
  text-align: center;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  opacity: 0;
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
}
.ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
.ant-tabs-tab-next.ant-tabs-tab-arrow-show {
  width: 32px;
  height: 100%;
  opacity: 1;
  pointer-events: auto;
}
.ant-tabs-tab-prev:hover,
.ant-tabs-tab-next:hover {
  color: #545454;
}
.ant-tabs-tab-prev-icon,
.ant-tabs-tab-next-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: bold;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  line-height: inherit;
  text-align: center;
  text-transform: none;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-tabs-tab-prev-icon-target,
.ant-tabs-tab-next-icon-target {
  display: block;
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
}
:root .ant-tabs-tab-prev-icon-target,
:root .ant-tabs-tab-next-icon-target {
  font-size: 12px;
}
.ant-tabs-tab-btn-disabled {
  cursor: not-allowed;
}
.ant-tabs-tab-btn-disabled,
.ant-tabs-tab-btn-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs-tab-next {
  right: 2px;
}
.ant-tabs-tab-prev {
  left: 0;
}
:root .ant-tabs-tab-prev {
  -webkit-filter: none;
          filter: none;
}
.ant-tabs-nav-wrap {
  margin-bottom: -1px;
  overflow: hidden;
}
.ant-tabs-nav-scroll {
  overflow: hidden;
  white-space: nowrap;
}
.ant-tabs-nav {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding-left: 0;
  list-style: none;
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-nav::before,
.ant-tabs-nav::after {
  display: table;
  content: ' ';
}
.ant-tabs-nav::after {
  clear: both;
}
.ant-tabs-nav .ant-tabs-tab {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 100%;
  margin: 0 32px 0 0;
  padding: 12px 16px;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-nav .ant-tabs-tab::before {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  border-top: 2px solid transparent;
  border-radius: 6px 6px 0 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tabs-nav .ant-tabs-tab:last-child {
  margin-right: 0;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #28aaeb;
}
.ant-tabs-nav .ant-tabs-tab:active {
  color: #006eb8;
}
.ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 8px;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #038fde;
  font-weight: 500;
}
.ant-tabs-nav .ant-tabs-tab-disabled,
.ant-tabs-nav .ant-tabs-tab-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tabs .ant-tabs-large-bar .ant-tabs-nav-container {
  font-size: 16px;
}
.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  padding: 16px;
}
.ant-tabs .ant-tabs-small-bar .ant-tabs-nav-container {
  font-size: 14px;
}
.ant-tabs .ant-tabs-small-bar .ant-tabs-tab {
  padding: 8px 16px;
}
.ant-tabs-content::before {
  display: block;
  overflow: hidden;
  content: '';
}
.ant-tabs .ant-tabs-top-content,
.ant-tabs .ant-tabs-bottom-content {
  width: 100%;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane {
  flex-shrink: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
  opacity: 1;
  transition: opacity 0.45s;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane-inactive,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane-inactive input,
.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
.ant-tabs .ant-tabs-top-content.ant-tabs-content-animated,
.ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated {
  display: flex;
  flex-direction: row;
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: margin-left;
}
.ant-tabs .ant-tabs-left-bar,
.ant-tabs .ant-tabs-right-bar {
  height: 100%;
  border-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-arrow-show,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-arrow-show {
  width: 100%;
  height: 32px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
  display: block;
  float: none;
  margin: 0 0 16px 0;
  padding: 8px 24px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab:last-child,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab:last-child {
  margin-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-extra-content,
.ant-tabs .ant-tabs-right-bar .ant-tabs-extra-content {
  text-align: center;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-scroll,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-scroll {
  width: auto;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  height: 100%;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container {
  margin-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container.ant-tabs-nav-container-scrolling {
  padding: 32px 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav,
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav {
  width: 100%;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar,
.ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
  top: 0;
  bottom: auto;
  left: auto;
  width: 2px;
  height: 0;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-next,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-next {
  right: 0;
  bottom: 0;
  width: 100%;
  height: 32px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab-prev,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab-prev {
  top: 0;
  width: 100%;
  height: 32px;
}
.ant-tabs .ant-tabs-left-content,
.ant-tabs .ant-tabs-right-content {
  width: auto;
  margin-top: 0 !important;
  overflow: hidden;
}
.ant-tabs .ant-tabs-left-bar {
  float: left;
  margin-right: -1px;
  margin-bottom: 0;
  border-right: 1px solid #e8e8e8;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: right;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container {
  margin-right: -1px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap {
  margin-right: -1px;
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
  right: 1px;
}
.ant-tabs .ant-tabs-left-content {
  padding-left: 24px;
  border-left: 1px solid #e8e8e8;
}
.ant-tabs .ant-tabs-right-bar {
  float: right;
  margin-bottom: 0;
  margin-left: -1px;
  border-left: 1px solid #e8e8e8;
}
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container {
  margin-left: -1px;
}
.ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
  margin-left: -1px;
}
.ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
  left: 1px;
}
.ant-tabs .ant-tabs-right-content {
  padding-right: 24px;
  border-right: 1px solid #e8e8e8;
}
.ant-tabs-top .ant-tabs-ink-bar-animated,
.ant-tabs-bottom .ant-tabs-ink-bar-animated {
  transition: width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-left .ant-tabs-ink-bar-animated,
.ant-tabs-right .ant-tabs-ink-bar-animated {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.no-flex > .ant-tabs-content > .ant-tabs-content-animated,
.ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-content-animated {
  margin-left: 0 !important;
  -webkit-transform: none !important;
          transform: none !important;
}
.no-flex > .ant-tabs-content > .ant-tabs-tabpane-inactive,
.ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.no-flex > .ant-tabs-content > .ant-tabs-tabpane-inactive input,
.ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
.ant-tabs-left-content > .ant-tabs-content-animated,
.ant-tabs-right-content > .ant-tabs-content-animated {
  margin-left: 0 !important;
  -webkit-transform: none !important;
          transform: none !important;
}
.ant-tabs-left-content > .ant-tabs-tabpane-inactive,
.ant-tabs-right-content > .ant-tabs-tabpane-inactive {
  height: 0;
  padding: 0 !important;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.ant-tabs-left-content > .ant-tabs-tabpane-inactive input,
.ant-tabs-right-content > .ant-tabs-tabpane-inactive input {
  visibility: hidden;
}
.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: default;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-tag:hover {
  opacity: 0.85;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: #545454;
}
.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.ant-tag .anticon-close {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  margin-left: 3px;
  color: #595959;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
:root .ant-tag .anticon-close {
  font-size: 12px;
}
.ant-tag .anticon-close:hover {
  color: #535353;
}
.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #038fde;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #038fde;
}
.ant-tag-checkable:active {
  background-color: #006eb8;
}
.ant-tag-hidden {
  display: none;
}
.ant-tag-pink {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-magenta {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-red {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}
.ant-tag-volcano {
  color: #fa541c;
  background: #fff2e8;
  border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.ant-tag-orange {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
.ant-tag-yellow {
  color: #fadb14;
  background: #feffe6;
  border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
.ant-tag-gold {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
.ant-tag-cyan {
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.ant-tag-lime {
  color: #a0d911;
  background: #fcffe6;
  border-color: #eaff8f;
}
.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.ant-tag-green {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
.ant-tag-blue {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-tag-geekblue {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
.ant-tag-purple {
  color: #722ed1;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}
.ant-time-picker-panel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1050;
  font-family: 'NoirPro', sans-serif;
}
.ant-time-picker-panel-inner {
  position: relative;
  left: -2px;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 6px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-time-picker-panel-input {
  width: 100%;
  max-width: 154px;
  margin: 0;
  padding: 0;
  line-height: normal;
  border: 0;
  outline: 0;
  cursor: auto;
}
.ant-time-picker-panel-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-time-picker-panel-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-panel-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-panel-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-time-picker-panel-input-wrap {
  position: relative;
  padding: 7px 2px 7px 12px;
  border-bottom: 1px solid #e8e8e8;
}
.ant-time-picker-panel-input-invalid {
  border-color: #f5222d;
}
.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: 112px;
}
.ant-time-picker-panel-select {
  position: relative;
  float: left;
  width: 56px;
  max-height: 192px;
  overflow: hidden;
  font-size: 14px;
  border-left: 1px solid #e8e8e8;
}
.ant-time-picker-panel-select:hover {
  overflow-y: auto;
}
.ant-time-picker-panel-select:first-child {
  margin-left: 0;
  border-left: 0;
}
.ant-time-picker-panel-select:last-child {
  border-right: 0;
}
.ant-time-picker-panel-select:only-child {
  width: 100%;
}
.ant-time-picker-panel-select ul {
  width: 56px;
  margin: 0;
  padding: 0 0 160px;
  list-style: none;
}
.ant-time-picker-panel-select li {
  width: 100%;
  height: 32px;
  margin: 0;
  padding: 0 0 0 12px;
  line-height: 32px;
  text-align: left;
  list-style: none;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-user-select: none;
          user-select: none;
}
.ant-time-picker-panel-select li:focus {
  color: #038fde;
  font-weight: 600;
  outline: none;
}
.ant-time-picker-panel-select li:hover {
  background: #e6faff;
}
li.ant-time-picker-panel-select-option-selected {
  font-weight: 600;
  background: #f5f5f5;
}
li.ant-time-picker-panel-select-option-selected:hover {
  background: #f5f5f5;
}
li.ant-time-picker-panel-select-option-disabled {
  color: rgba(0, 0, 0, 0.25);
}
li.ant-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
li.ant-time-picker-panel-select-option-disabled:focus {
  color: rgba(0, 0, 0, 0.25);
  font-weight: inherit;
}
.ant-time-picker-panel-combobox {
  zoom: 1;
}
.ant-time-picker-panel-combobox::before,
.ant-time-picker-panel-combobox::after {
  display: table;
  content: '';
}
.ant-time-picker-panel-combobox::after {
  clear: both;
}
.ant-time-picker-panel-combobox::before,
.ant-time-picker-panel-combobox::after {
  display: table;
  content: '';
}
.ant-time-picker-panel-combobox::after {
  clear: both;
}
.ant-time-picker-panel-addon {
  padding: 8px;
  border-top: 1px solid #e8e8e8;
}
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-time-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 128px;
  outline: none;
  cursor: text;
  transition: opacity 0.3s;
}
.ant-time-picker-input {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 36px;
  padding: 4px 11px;
  color: #545454;
  font-size: 14px;
  line-height: 1.3;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s;
}
.ant-time-picker-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-time-picker-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-time-picker-input:hover {
  border-color: #28aaeb;
  border-right-width: 1px !important;
}
.ant-time-picker-input:focus {
  border-color: #28aaeb;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
}
.ant-time-picker-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-time-picker-input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-time-picker-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-time-picker-input {
  max-width: 100%;
  height: auto;
  min-height: 36px;
  line-height: 1.3;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-time-picker-input-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-time-picker-input-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-time-picker-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-time-picker-input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-time-picker-open {
  opacity: 0;
}
.ant-time-picker-icon,
.ant-time-picker-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 14px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
          user-select: none;
}
.ant-time-picker-icon .ant-time-picker-clock-icon,
.ant-time-picker-clear .ant-time-picker-clock-icon {
  display: block;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
}
.ant-time-picker-clear {
  z-index: 2;
  background: #fff;
  opacity: 0;
  pointer-events: none;
}
.ant-time-picker-clear:hover {
  color: #595959;
}
.ant-time-picker:hover .ant-time-picker-clear {
  opacity: 1;
  pointer-events: auto;
}
.ant-time-picker-large .ant-time-picker-input {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-time-picker-small .ant-time-picker-input {
  height: 24px;
  padding: 1px 7px;
}
.ant-time-picker-small .ant-time-picker-icon,
.ant-time-picker-small .ant-time-picker-clear {
  right: 7px;
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .ant-input {
      line-height: 1.3;
    }
  }
}
.ant-timeline {
  box-sizing: border-box;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-timeline-item {
  position: relative;
  margin: 0;
  padding: 0 0 20px;
  font-size: 14px;
  list-style: none;
}
.ant-timeline-item-tail {
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(90%);
  border-left: 2px solid #e8e8e8;
}
.ant-timeline-item-pending .ant-timeline-item-head {
  font-size: 12px;
  background-color: transparent;
}
.ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
}
.ant-timeline-item-head-blue {
  color: #038fde;
  border-color: #038fde;
}
.ant-timeline-item-head-red {
  color: #f5222d;
  border-color: #f5222d;
}
.ant-timeline-item-head-green {
  color: #52c41a;
  border-color: #52c41a;
}
.ant-timeline-item-head-gray {
  color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-timeline-item-content {
  position: relative;
  top: -3.2px;
  margin: 0 0 0 18px;
  word-break: break-word;
}
.ant-timeline-item-last > .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
  left: 50%;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head {
  margin-left: -4px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
  margin-left: 1px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(46%);
  width: calc(36%);
  text-align: left;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(38%);
  margin: 0;
  text-align: right;
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: calc(94%);
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(82%);
}
.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  display: block;
  height: calc(86%);
  border-left: 2px dotted #e8e8e8;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  top: 15px;
  display: block;
  height: calc(85%);
  border-left: 2px dotted #e8e8e8;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}
.ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1060;
  display: block;
  max-width: 250px;
  visibility: visible;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 8px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 8px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 8px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-tooltip-arrow {
  position: absolute;
  display: block;
  width: 13.07106781px;
  height: 13.07106781px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-tooltip-arrow::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.75);
  content: '';
  pointer-events: auto;
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -5.07106781px;
}
.ant-tooltip-placement-top .ant-tooltip-arrow::before,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow::before,
.ant-tooltip-placement-topRight .ant-tooltip-arrow::before {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateY(-6.53553391px) rotate(45deg);
          transform: translateY(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: -5.07106781px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow::before,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow::before,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow::before {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateX(6.53553391px) rotate(45deg);
          transform: translateX(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: -5.07106781px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow::before,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow::before,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow::before {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateX(-6.53553391px) rotate(45deg);
          transform: translateX(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -5.07106781px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow::before,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow::before,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow::before {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  -webkit-transform: translateY(6.53553391px) rotate(45deg);
          transform: translateY(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-transfer-customize-list {
  display: flex;
}
.ant-transfer-customize-list .ant-transfer-operation {
  flex: none;
  align-self: center;
}
.ant-transfer-customize-list .ant-transfer-list {
  flex: auto;
  width: auto;
  height: auto;
  min-height: 200px;
}
.ant-transfer-customize-list .ant-transfer-list-body-with-search {
  padding-top: 0;
}
.ant-transfer-customize-list .ant-transfer-list-body-search-wrapper {
  position: relative;
  padding-bottom: 0;
}
.ant-transfer-customize-list .ant-transfer-list-body-customize-wrapper {
  padding: 12px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #fafafa;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #e8e8e8;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px;
}
.ant-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
}
.ant-transfer-disabled .ant-transfer-list {
  background: #f5f5f5;
}
.ant-transfer-list {
  position: relative;
  display: inline-block;
  width: 180px;
  height: 200px;
  padding-top: 40px;
  vertical-align: middle;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
.ant-transfer-list-with-footer {
  padding-bottom: 34px;
}
.ant-transfer-list-search {
  padding: 0 24px 0 8px;
}
.ant-transfer-list-search-action {
  position: absolute;
  top: 12px;
  right: 12px;
  bottom: 12px;
  width: 28px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 36px;
  text-align: center;
}
.ant-transfer-list-search-action .anticon {
  color: rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
}
.ant-transfer-list-search-action .anticon:hover {
  color: #595959;
}
span.ant-transfer-list-search-action {
  pointer-events: none;
}
.ant-transfer-list-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 9.4px 12px 10.4px;
  overflow: hidden;
  color: #545454;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 6px 6px 0 0;
}
.ant-transfer-list-header-title {
  position: absolute;
  right: 12px;
}
.ant-transfer-list-header .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
.ant-transfer-list-body {
  position: relative;
  height: 100%;
  font-size: 14px;
}
.ant-transfer-list-body-search-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 12px;
}
.ant-transfer-list-body-with-search {
  padding-top: 60px;
}
.ant-transfer-list-content {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
.ant-transfer-list-content > .LazyLoad {
  -webkit-animation: transferHighlightIn 1s;
          animation: transferHighlightIn 1s;
}
.ant-transfer-list-content-item {
  min-height: 32px;
  padding: 6px 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-transfer-list-content-item > span {
  padding-right: 0;
}
.ant-transfer-list-content-item-text {
  padding-left: 8px;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #e6faff;
  cursor: pointer;
}
.ant-transfer-list-content-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-transfer-list-body-not-found {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 0;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-transfer-list-body-with-search .ant-transfer-list-body-not-found {
  margin-top: 18px;
}
.ant-transfer-list-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 6px 6px;
}
.ant-transfer-operation {
  display: inline-block;
  margin: 0 8px;
  overflow: hidden;
  vertical-align: middle;
}
.ant-transfer-operation .ant-btn {
  display: block;
}
.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}
@-webkit-keyframes transferHighlightIn {
  0% {
    background: #a6eaff;
  }
  100% {
    background: transparent;
  }
}
@keyframes transferHighlightIn {
  0% {
    background: #a6eaff;
  }
  100% {
    background: transparent;
  }
}
.ant-tree.ant-tree-directory {
  position: relative;
}
.ant-tree.ant-tree-directory > li span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li span.ant-tree-switcher.ant-tree-switcher-noop,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher.ant-tree-switcher-noop {
  pointer-events: none;
}
.ant-tree.ant-tree-directory > li span.ant-tree-checkbox,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-checkbox {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper {
  border-radius: 0;
  -webkit-user-select: none;
          user-select: none;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
  background: #e6faff;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper::before {
  position: absolute;
  right: 0;
  left: 0;
  height: 24px;
  transition: all 0.3s;
  content: '';
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper > span,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper > span {
  position: relative;
  z-index: 1;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox .ant-tree-checkbox-inner {
  border-color: #038fde;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked::after {
  border-color: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background: #fff;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-checkbox.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: #038fde;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before {
  background: #038fde;
}
.ant-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #038fde;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #038fde;
  border-radius: 4px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}
.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
          transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #038fde;
  border-color: #038fde;
}
.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #038fde;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-tree {
  /* see https://github.com/ant-design/ant-design/issues/16259 */
  box-sizing: border-box;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  margin: 0;
  padding: 0;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 16.67%;
  left: 0;
  width: 100%;
  height: 66.67%;
}
.ant-tree ol,
.ant-tree ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-tree li {
  margin: 0;
  padding: 4px 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}
.ant-tree li span[draggable],
.ant-tree li span[draggable='true'] {
  line-height: 20px;
  border-top: 2px transparent solid;
  border-bottom: 2px transparent solid;
  -webkit-user-select: none;
          user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}
.ant-tree li.drag-over > span[draggable] {
  color: white;
  background-color: #038fde;
  opacity: 0.8;
}
.ant-tree li.drag-over-gap-top > span[draggable] {
  border-top-color: #038fde;
}
.ant-tree li.drag-over-gap-bottom > span[draggable] {
  border-bottom-color: #038fde;
}
.ant-tree li.filter-node > span {
  color: #f5222d !important;
  font-weight: 500 !important;
}
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon,
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  color: #038fde;
  font-size: 14px;
  -webkit-transform: none;
          transform: none;
}
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-loading-icon svg,
.ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
:root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_open::after,
:root .ant-tree li.ant-tree-treenode-loading span.ant-tree-switcher.ant-tree-switcher_close::after {
  opacity: 0;
}
.ant-tree li ul {
  margin: 0;
  padding: 0 0 0 18px;
}
.ant-tree li .ant-tree-node-content-wrapper {
  display: inline-block;
  height: 24px;
  margin: 0;
  padding: 0 5px;
  color: #545454;
  line-height: 24px;
  text-decoration: none;
  vertical-align: top;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tree li .ant-tree-node-content-wrapper:hover {
  background-color: #e6faff;
}
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #a6eaff;
}
.ant-tree li span.ant-tree-checkbox {
  top: initial;
  height: 24px;
  margin: 0 4px 0 2px;
  padding: 4px 0;
}
.ant-tree li span.ant-tree-switcher,
.ant-tree li span.ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
.ant-tree li span.ant-tree-iconEle:empty {
  display: none;
}
.ant-tree li span.ant-tree-switcher {
  position: relative;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
  cursor: default;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.ant-tree li:last-child > span.ant-tree-switcher::before,
.ant-tree li:last-child > span.ant-tree-iconEle::before {
  display: none;
}
.ant-tree > li:first-child {
  padding-top: 7px;
}
.ant-tree > li:last-child {
  padding-bottom: 7px;
}
.ant-tree-child-tree > li:first-child {
  padding-top: 8px;
}
.ant-tree-child-tree > li:last-child {
  padding-bottom: 0;
}
li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher),
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper,
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
}
.ant-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
}
.ant-tree.ant-tree-show-line li {
  position: relative;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  color: #595959;
  background: #fff;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher-noop .ant-select-switcher-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  display: inline-block;
  font-weight: normal;
  font-size: 12px;
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-tree.ant-tree-show-line li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-tree.ant-tree-show-line li:not(:last-child)::before {
  position: absolute;
  left: 12px;
  width: 1px;
  height: 100%;
  height: calc(78%);
  margin: 22px 0 0;
  border-left: 1px solid #d9d9d9;
  content: ' ';
}
.ant-tree.ant-tree-icon-hide .ant-tree-treenode-loading .ant-tree-iconEle {
  display: none;
}
.ant-tree.ant-tree-block-node li .ant-tree-node-content-wrapper {
  width: calc(100% - 24px);
}
.ant-tree.ant-tree-block-node li span.ant-tree-checkbox + .ant-tree-node-content-wrapper {
  width: calc(100% - 46px);
}
.ant-select-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #038fde;
}
.ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #038fde;
  border-radius: 4px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}
.ant-select-tree-checkbox:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
  visibility: visible;
}
.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
          transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
          transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #038fde;
  border-color: #038fde;
}
.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  -webkit-animation-name: none;
          animation-name: none;
}
.ant-select-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-select-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: inline-block;
}
.ant-select-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #038fde;
  border: 0;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-select-tree {
  box-sizing: border-box;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  margin: 0;
  margin-top: -4px;
  padding: 0 4px;
}
.ant-select-tree li {
  margin: 8px 0;
  padding: 0;
  white-space: nowrap;
  list-style: none;
  outline: 0;
}
.ant-select-tree li.filter-node > span {
  font-weight: 500;
}
.ant-select-tree li ul {
  margin: 0;
  padding: 0 0 0 18px;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper {
  display: inline-block;
  width: calc(100% - 24px);
  margin: 0;
  padding: 3px 5px;
  color: #545454;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: #e6faff;
}
.ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #a6eaff;
}
.ant-select-tree li span.ant-select-tree-checkbox {
  margin: 0 4px 0 0;
}
.ant-select-tree li span.ant-select-tree-checkbox + .ant-select-tree-node-content-wrapper {
  width: calc(100% - 46px);
}
.ant-select-tree li span.ant-select-tree-switcher,
.ant-select-tree li span.ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
.ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  color: #038fde;
  font-size: 14px;
  -webkit-transform: none;
          transform: none;
}
.ant-select-tree li span.ant-select-icon_loading .ant-select-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-select-tree li span.ant-select-tree-switcher {
  position: relative;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher-noop {
  cursor: auto;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-tree-switcher-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  display: inline-block;
  font-weight: bold;
}
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon {
  font-size: 12px;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-tree-switcher-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-icon svg {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  color: #038fde;
  font-size: 14px;
  -webkit-transform: none;
          transform: none;
}
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_open .ant-select-switcher-loading-icon svg,
.ant-select-tree li span.ant-select-tree-switcher.ant-select-tree-switcher_close .ant-select-switcher-loading-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-select-tree .ant-select-tree-treenode-loading .ant-select-tree-iconEle {
  display: none;
}
.ant-select-tree-child-tree {
  display: none;
}
.ant-select-tree-child-tree-open {
  display: block;
}
li.ant-select-tree-treenode-disabled > span:not(.ant-select-tree-switcher),
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper,
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-select-tree-icon__open {
  margin-right: 2px;
  vertical-align: top;
}
.ant-select-tree-icon__close {
  margin-right: 2px;
  vertical-align: top;
}
.ant-select-tree-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
}
.ant-select-tree-dropdown .ant-select-dropdown-search {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  display: block;
  padding: 4px;
  background: #fff;
}
.ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field__wrap {
  width: 100%;
}
.ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field {
  box-sizing: border-box;
  width: 100%;
  padding: 4px 7px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: none;
}
.ant-select-tree-dropdown .ant-select-dropdown-search.ant-select-search--hide {
  display: none;
}
.ant-select-tree-dropdown .ant-select-not-found {
  display: block;
  padding: 7px 16px;
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-typography {
  color: #545454;
}
.ant-typography.ant-typography-secondary {
  color: #595959;
}
.ant-typography.ant-typography-warning {
  color: #fa8c16;
}
.ant-typography.ant-typography-danger {
  color: #f5222d;
}
.ant-typography.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  -webkit-user-select: none;
          user-select: none;
}
div.ant-typography,
.ant-typography p {
  margin-bottom: 1em;
}
h1.ant-typography,
.ant-typography h1 {
  margin-bottom: 0.5em;
  color: #535353;
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}
h2.ant-typography,
.ant-typography h2 {
  margin-bottom: 0.5em;
  color: #535353;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
h3.ant-typography,
.ant-typography h3 {
  margin-bottom: 0.5em;
  color: #535353;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}
h4.ant-typography,
.ant-typography h4 {
  margin-bottom: 0.5em;
  color: #535353;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography {
  margin-top: 1.2em;
}
.ant-typography div + h1,
.ant-typography ul + h1,
.ant-typography li + h1,
.ant-typography p + h1,
.ant-typography h1 + h1,
.ant-typography h2 + h1,
.ant-typography h3 + h1,
.ant-typography h4 + h1,
.ant-typography div + h2,
.ant-typography ul + h2,
.ant-typography li + h2,
.ant-typography p + h2,
.ant-typography h1 + h2,
.ant-typography h2 + h2,
.ant-typography h3 + h2,
.ant-typography h4 + h2,
.ant-typography div + h3,
.ant-typography ul + h3,
.ant-typography li + h3,
.ant-typography p + h3,
.ant-typography h1 + h3,
.ant-typography h2 + h3,
.ant-typography h3 + h3,
.ant-typography h4 + h3,
.ant-typography div + h4,
.ant-typography ul + h4,
.ant-typography li + h4,
.ant-typography p + h4,
.ant-typography h1 + h4,
.ant-typography h2 + h4,
.ant-typography h3 + h4,
.ant-typography h4 + h4 {
  margin-top: 1.2em;
}
span.ant-typography-ellipsis {
  display: inline-block;
}
.ant-typography a {
  color: #038fde;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-typography a:focus,
.ant-typography a:hover {
  color: #28aaeb;
}
.ant-typography a:active {
  color: #006eb8;
}
.ant-typography a:active,
.ant-typography a:hover {
  text-decoration: none;
}
.ant-typography a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
.ant-typography code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 3px;
}
.ant-typography mark {
  padding: 0;
  background-color: #ffe58f;
}
.ant-typography u,
.ant-typography ins {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}
.ant-typography s,
.ant-typography del {
  text-decoration: line-through;
}
.ant-typography strong {
  font-weight: 600;
}
.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: #038fde;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 8px;
}
.ant-typography-expand:focus,
.ant-typography-edit:focus,
.ant-typography-copy:focus,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
  color: #28aaeb;
}
.ant-typography-expand:active,
.ant-typography-edit:active,
.ant-typography-copy:active {
  color: #006eb8;
}
.ant-typography-copy-success,
.ant-typography-copy-success:hover,
.ant-typography-copy-success:focus {
  color: #52c41a;
}
.ant-typography-edit-content {
  position: relative;
}
div.ant-typography-edit-content {
  left: -12px;
  margin-top: -5px;
  margin-bottom: calc(-5em);
}
.ant-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: #595959;
  pointer-events: none;
}
.ant-typography-edit-content textarea {
  -moz-transition: none;
}
.ant-typography ul,
.ant-typography ol {
  margin: 0 0 1em 0;
  padding: 0;
}
.ant-typography ul li,
.ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}
.ant-typography ul li {
  list-style-type: circle;
}
.ant-typography ul li li {
  list-style-type: disc;
}
.ant-typography ol li {
  list-style-type: decimal;
}
.ant-typography-ellipsis-single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-typography-ellipsis-multiple-line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  outline: 0;
}
.ant-upload p {
  margin: 0;
}
.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.ant-upload input[type='file'] {
  cursor: pointer;
}
.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-select-picture-card {
  display: table;
  float: left;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: table-cell;
  width: 100%;
  height: 100%;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #038fde;
}
.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #006eb8;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #28aaeb;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #28aaeb;
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: #535353;
  font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: #595959;
  font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: #595959;
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: #595959;
}
.ant-upload-picture-card-wrapper {
  zoom: 1;
  display: inline-block;
  width: 100%;
}
.ant-upload-picture-card-wrapper::before,
.ant-upload-picture-card-wrapper::after {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  clear: both;
}
.ant-upload-picture-card-wrapper::before,
.ant-upload-picture-card-wrapper::after {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  clear: both;
}
.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #545454;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.3;
  list-style: none;
  -webkit-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  zoom: 1;
}
.ant-upload-list::before,
.ant-upload-list::after {
  display: table;
  content: '';
}
.ant-upload-list::after {
  clear: both;
}
.ant-upload-list::before,
.ant-upload-list::after {
  display: table;
  content: '';
}
.ant-upload-list::after {
  clear: both;
}
.ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-right: 14px;
}
.ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-right: 28px;
}
.ant-upload-list-item {
  position: relative;
  height: 22px;
  margin-top: 8px;
  font-size: 14px;
}
.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-upload-list-item-name-icon-count-1 {
  padding-right: 14px;
}
.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
  opacity: 0;
}
.ant-upload-list-item-card-actions.picture {
  top: 25px;
  line-height: 1;
  opacity: 1;
}
.ant-upload-list-item-card-actions .anticon {
  padding-right: 6px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list-item-info {
  height: 100%;
  padding: 0 12px 0 4px;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-upload-list-item-info .anticon-loading,
.ant-upload-list-item-info .anticon-paper-clip {
  position: absolute;
  top: 5px;
  color: #595959;
  font-size: 14px;
}
.ant-upload-list-item .anticon-close {
  display: inline-block;
  font-size: 12px;
  font-size: 10px \9;
  -webkit-transform: scale(0.83333333) rotate(0deg);
          transform: scale(0.83333333) rotate(0deg);
  position: absolute;
  top: 6px;
  right: 4px;
  color: #595959;
  line-height: 0;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
}
:root .ant-upload-list-item .anticon-close {
  font-size: 12px;
}
.ant-upload-list-item .anticon-close:hover {
  color: #545454;
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #e6faff;
}
.ant-upload-list-item:hover .anticon-close {
  opacity: 1;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions {
  opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .anticon-paper-clip,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #f5222d;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions {
  opacity: 1;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #f5222d;
}
.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #f5222d;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 48px;
  height: 48px;
  font-size: 26px;
  line-height: 54px;
  text-align: center;
  opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 18px;
}
.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 36px;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  line-height: 28px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.ant-upload-list-picture .anticon-close,
.ant-upload-list-picture-card .anticon-close {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
  opacity: 1;
}
.ant-upload-list-picture-card.ant-upload-list::after {
  display: none;
}
.ant-upload-list-picture-card-container {
  float: left;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  float: left;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.3;
  text-align: center;
}
.ant-upload-list-picture-card .anticon-picture + .ant-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye-o,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading-text {
  margin-top: 18px;
  color: #595959;
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  padding-left: 0;
}
.ant-upload-list .ant-upload-success-icon {
  color: #52c41a;
  font-weight: bold;
}
.ant-upload-list .ant-upload-animate-enter,
.ant-upload-list .ant-upload-animate-leave,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-upload-list .ant-upload-animate-enter {
  -webkit-animation-name: uploadAnimateIn;
          animation-name: uploadAnimateIn;
}
.ant-upload-list .ant-upload-animate-leave {
  -webkit-animation-name: uploadAnimateOut;
          animation-name: uploadAnimateOut;
}
.ant-upload-list .ant-upload-animate-inline-enter {
  -webkit-animation-name: uploadAnimateInlineIn;
          animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
  -webkit-animation-name: uploadAnimateInlineOut;
          animation-name: uploadAnimateInlineOut;
}
@-webkit-keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateIn {
  from {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@-webkit-keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
/* color classes */
.gx-bg-primary {
  background-color: #038fde !important;
}
a.gx-bg-primary:hover,
a.gx-bg-primary:focus {
  background-color: #038fde !important;
}
.gx-bg-primary-light {
  background-color: #52c6f7 !important;
}
.gx-bg-secondary {
  background-color: #fa8c15 !important;
}
a.gx-bg-secondary:hover,
a.gx-bg-secondary:focus {
  background-color: #fa8c15 !important;
}
.gx-bg-success {
  background-color: #52c41a !important;
}
a.gx-bg-success:hover,
a.gx-bg-success:focus {
  background-color: #3f9714 !important;
}
.gx-bg-success-dark {
  background-color: #368011 !important;
}
a.gx-bg-success-dark:hover,
a.gx-bg-success-dark:focus {
  background-color: #2c6a0e !important;
}
.gx-bg-info {
  background-color: #1890ff !important;
}
a.gx-bg-info:hover,
a.gx-bg-info:focus {
  background-color: #0076e4 !important;
}
.gx-bg-warning {
  background-color: #fa8c16 !important;
}
a.gx-bg-warning:hover,
a.gx-bg-warning:focus {
  background-color: #d87205 !important;
}
.gx-bg-danger {
  background-color: #f44336 !important;
}
a.gx-bg-danger:hover,
a.gx-bg-danger:focus {
  background-color: #ea1c0d !important;
}
.gx-bg-light {
  background-color: #e8e8e8 !important;
}
a.gx-bg-light:hover,
a.gx-bg-light:focus {
  background-color: #d9d9d9 !important;
}
.gx-bg-dark {
  background-color: #000000 !important;
}
a.gx-bg-dark:hover,
a.gx-bg-dark:focus {
  background-color: #1a1a1a !important;
}
.gx-bg-white {
  background-color: #ffffff !important;
}
a.gx-bg-white:hover,
a.gx-bg-white:focus {
  background-color: #f2f2f2 !important;
}
.gx-bg-grey {
  background-color: #cccccc !important;
}
a.gx-bg-grey:hover,
a.gx-bg-grey:focus {
  background-color: #b2b2b2 !important;
}
.gx-bg-light-grey {
  background-color: #fafafa !important;
}
.gx-bg-transparent {
  background-color: transparent !important;
}
.gx-bg-pink {
  background-color: #eb2f96 !important;
}
a.gx-bg-pink:hover,
a.gx-bg-pink:focus {
  background-color: #d3147d !important;
}
.gx-bg-green {
  background-color: #52c41a !important;
}
a.gx-bg-green:hover,
a.gx-bg-green:focus {
  background-color: #3f9714 !important;
}
.gx-bg-green-light {
  background-color: #caf5b5 !important;
}
a.gx-bg-green-light:hover,
a.gx-bg-green-light:focus {
  background-color: #8ae95b !important;
}
.gx-bg-red {
  background-color: #f5222d !important;
}
a.gx-bg-red:hover,
a.gx-bg-red:focus {
  background-color: #da0a15 !important;
}
.gx-bg-amber {
  background-color: #fadb14 !important;
}
a.gx-bg-amber:hover,
a.gx-bg-amber:focus {
  background-color: #d6ba05 !important;
}
.gx-bg-amber-light {
  background-color: #fdf2aa !important;
}
a.gx-bg-amber-light:hover,
a.gx-bg-amber-light:focus {
  background-color: #fcea78 !important;
}
.gx-bg-blue {
  background-color: #1890ff !important;
}
a.gx-bg-blue:hover,
a.gx-bg-blue:focus {
  background-color: #0076e4 !important;
}
.gx-bg-light-blue {
  background-color: #329cff !important;
}
a.gx-bg-light-blue:hover,
a.gx-bg-light-blue:focus {
  background-color: #4ba9ff !important;
}
.gx-bg-indigo {
  background-color: #3c1991 !important;
}
a.gx-bg-indigo:hover,
a.gx-bg-indigo:focus {
  background-color: #2a1166 !important;
}
.gx-bg-purple {
  background-color: #722ed1 !important;
}
a.gx-bg-purple:hover,
a.gx-bg-purple:focus {
  background-color: #5b25a7 !important;
}
.gx-bg-orange {
  background-color: #fa8c16 !important;
}
a.gx-bg-orange:hover,
a.gx-bg-orange:focus {
  background-color: #d87205 !important;
}
.gx-bg-yellow {
  background-color: #fadb14 !important;
}
a.gx-bg-yellow:hover,
a.gx-bg-yellow:focus {
  background-color: #d6ba05 !important;
}
.gx-bg-teal {
  background-color: #20c997 !important;
}
a.gx-bg-teal:hover,
a.gx-bg-teal:focus {
  background-color: #199d76 !important;
}
.gx-bg-cyan {
  background-color: #13c2c2 !important;
}
a.gx-bg-cyan:hover,
a.gx-bg-cyan:focus {
  background-color: #0e9494 !important;
}
.gx-bg-sepia {
  background-color: #7c3535 !important;
}
a.gx-bg-sepia:hover,
a.gx-bg-sepia:focus {
  background-color: #582626 !important;
}
.gx-bg-geekblue {
  background-color: #030852 !important;
}
a.gx-bg-geekblue:hover,
a.gx-bg-geekblue:focus {
  background-color: #010321 !important;
}
.gx-text-white {
  color: #ffffff !important;
}
.gx-text-primary {
  color: #038fde !important;
}
a.gx-text-primary:hover,
a.gx-text-primary:focus {
  color: #038fde !important;
}
.gx-text-secondary {
  color: #fa8c15 !important;
}
a.gx-text-secondary:hover,
a.gx-text-secondary:focus {
  color: #fa8c15 !important;
}
.gx-text-success {
  color: #52c41a !important;
}
a.gx-text-success:hover,
a.gx-text-success:focus {
  color: #49ad17 !important;
}
.gx-text-success-dark {
  color: #368011 !important;
}
a.gx-text-success-dark:hover,
a.gx-text-success-dark:focus {
  color: #2c6a0e !important;
}
.gx-text-info {
  color: #1890ff !important;
}
a.gx-text-info:hover,
a.gx-text-info:focus {
  color: #0084fe !important;
}
.gx-text-warning {
  color: #fa8c16 !important;
}
a.gx-text-warning:hover,
a.gx-text-warning:focus {
  color: #f17f05 !important;
}
.gx-text-danger {
  color: #f44336 !important;
}
a.gx-text-danger:hover,
a.gx-text-danger:focus {
  color: #f32c1e !important;
}
.gx-text-light {
  color: #ababab !important;
}
a.gx-text-light:hover,
a.gx-text-light:focus {
  color: #787878 !important;
}
.gx-text-light-grey {
  color: #cccccc !important;
}
a.gx-text-light-grey:hover,
a.gx-text-light-grey:focus {
  color: #bfbfbf !important;
}
.gx-text-grey {
  color: #8c8c8c !important;
}
a.gx-text-grey:hover,
a.gx-text-grey:focus {
  color: #262626 !important;
}
.gx-text-dark {
  color: #595959 !important;
}
a.gx-text-dark:hover,
a.gx-text-dark:focus {
  color: #4c4c4c !important;
}
.gx-text-black {
  color: #000000 !important;
}
a.gx-text-black:hover,
a.gx-text-black:focus {
  color: #4c4c4c !important;
}
.gx-text-pink {
  color: #eb2f96 !important;
}
a.gx-text-pink:hover,
a.gx-text-pink:focus {
  color: #e9188a !important;
}
.gx-text-green {
  color: #52c41a !important;
}
a.gx-text-green:hover,
a.gx-text-green:focus {
  color: #49ad17 !important;
}
.gx-text-red {
  color: #f5222d !important;
}
a.gx-text-red:hover,
a.gx-text-red:focus {
  color: #f30b17 !important;
}
.gx-text-amber {
  color: #fadb14 !important;
}
a.gx-text-amber:hover,
a.gx-text-amber:focus {
  color: #efd005 !important;
}
.gx-text-blue {
  color: #1890ff !important;
}
a.gx-text-blue:hover,
a.gx-text-blue:focus {
  color: #0084fe !important;
}
.gx-text-indigo {
  color: #3c1991 !important;
}
a.gx-text-indigo:hover,
a.gx-text-indigo:focus {
  color: #33157b !important;
}
.gx-text-purple {
  color: #722ed1 !important;
}
a.gx-text-purple:hover,
a.gx-text-purple:focus {
  color: #6729bc !important;
}
.gx-text-orange {
  color: #fa8c16 !important;
}
a.gx-text-orange:hover,
a.gx-text-orange:focus {
  color: #f17f05 !important;
}
.gx-text-yellow {
  color: #fadb14 !important;
}
a.gx-text-yellow:hover,
a.gx-text-yellow:focus {
  color: #efd005 !important;
}
.gx-text-teal {
  color: #20c997 !important;
}
a.gx-text-teal:hover,
a.gx-text-teal:focus {
  color: #1cb386 !important;
}
.gx-text-cyan {
  color: #13c2c2 !important;
}
a.gx-text-cyan:hover,
a.gx-text-cyan:focus {
  color: #11abab !important;
}
.gx-text-muted {
  color: #8c8c8c !important;
}
.gx-text-geekblue {
  color: #030852 !important;
}
a.gx-text-geekblue:hover,
a.gx-text-geekblue:focus {
  color: #10239e !important;
}
.gx-text-sepia {
  color: #7c3535 !important;
}
a.gx-text-sepia:hover,
a.gx-text-sepia:focus {
  color: #7c3535 !important;
}
.gx-icon-white {
  color: #ffffff !important;
}
.gx-border-primary {
  border-color: #038fde !important;
}
.gx-border-secondary {
  border-color: #fa8c15 !important;
}
.gx-border-success {
  border-color: #52c41a !important;
}
.gx-border-success-dark {
  border-color: #368011 !important;
}
.gx-border-info {
  border-color: #1890ff !important;
}
.gx-border-warning {
  border-color: #fa8c16 !important;
}
.gx-border-danger {
  border-color: #f44336 !important;
}
.gx-border-light {
  border-color: #bfbfbf !important;
}
.gx-border-dark {
  border-color: #595959 !important;
}
.gx-border-white {
  border-color: #ffffff !important;
}
.gx-border-grey {
  border-color: #bfbfbf !important;
}
.gx-border-pink {
  border-color: #eb2f96 !important;
}
.gx-border-green {
  border-color: #52c41a !important;
}
.gx-border-red {
  border-color: #f5222d !important;
}
.gx-border-amber {
  border-color: #fadb14 !important;
}
.gx-border-blue {
  border-color: #1890ff !important;
}
.gx-border-indigo {
  border-color: #3c1991 !important;
}
.gx-border-purple {
  border-color: #722ed1 !important;
}
.gx-border-orange {
  border-color: #fa8c16 !important;
}
.gx-border-yellow {
  border-color: #fadb14 !important;
}
.gx-border-teal {
  border-color: #1890ff !important;
}
.gx-border-cyan {
  border-color: #13c2c2 !important;
}
.gx-border-sepia {
  border-color: #7c3535 !important;
}
.gx-border-geekblue {
  border-color: #030852 !important;
}
.gx-btn-primary,
a.gx-btn-primary,
.btn-primary {
  color: #ffffff !important;
  background-color: #038fde;
  border-color: #038fde;
}
.gx-btn-primary:hover,
a.gx-btn-primary:hover,
.btn-primary:hover,
.gx-btn-primary:focus,
a.gx-btn-primary:focus,
.btn-primary:focus {
  color: #ffffff !important;
  background-color: #038fde;
  border-color: #038fde;
}
.gx-btn-primary.disabled,
a.gx-btn-primary.disabled,
.btn-primary.disabled,
.gx-btn-primary:disabled,
a.gx-btn-primary:disabled,
.btn-primary:disabled {
  background-color: #038fde !important;
  border-color: #038fde !important;
}
.gx-btn-primary:not([disabled]):not(.disabled):active,
a.gx-btn-primary:not([disabled]):not(.disabled):active,
.btn-primary:not([disabled]):not(.disabled):active,
.gx-btn-primary:not([disabled]):not(.disabled).active,
a.gx-btn-primary:not([disabled]):not(.disabled).active,
.btn-primary:not([disabled]):not(.disabled).active {
  color: #ffffff;
  background-color: #038fde !important;
  border-color: #038fde !important;
}
.gx-btn-primary-light,
a.gx-btn-primary-light,
.btn-primary-light {
  color: #595959 !important;
  background-color: #c8ebfe !important;
  border-color: #c8ebfe !important;
}
.gx-btn-primary-light:hover,
a.gx-btn-primary-light:hover,
.btn-primary-light:hover,
.gx-btn-primary-light:focus,
a.gx-btn-primary-light:focus,
.btn-primary-light:focus {
  color: #595959 !important;
  background-color: #7dcffd !important;
  border-color: #7dcffd !important;
}
.gx-btn-primary-light.disabled,
a.gx-btn-primary-light.disabled,
.btn-primary-light.disabled,
.gx-btn-primary-light:disabled,
a.gx-btn-primary-light:disabled,
.btn-primary-light:disabled {
  background-color: #e1f4ff !important;
  border-color: #e1f4ff !important;
}
.gx-btn-primary-light:not([disabled]):not(.disabled):active,
a.gx-btn-primary-light:not([disabled]):not(.disabled):active,
.btn-primary-light:not([disabled]):not(.disabled):active,
.gx-btn-primary-light:not([disabled]):not(.disabled).active,
a.gx-btn-primary-light:not([disabled]):not(.disabled).active,
.btn-primary-light:not([disabled]):not(.disabled).active {
  color: #595959;
  background-color: #afe1fe !important;
  border-color: #afe1fe !important;
}
.gx-btn-secondary,
a.gx-btn-secondary {
  color: #ffffff !important;
  background-color: #fa8c15 !important;
  border-color: #fa8c15 !important;
}
.gx-btn-secondary:hover,
a.gx-btn-secondary:hover,
.gx-btn-secondary:focus,
a.gx-btn-secondary:focus {
  color: #ffffff;
  background-color: #fa8c15 !important;
  border-color: #fa8c15 !important;
}
.gx-btn-secondary.disabled,
a.gx-btn-secondary.disabled,
.gx-btn-secondary:disabled,
a.gx-btn-secondary:disabled {
  background-color: #fa8c15 !important;
  border-color: #fa8c15 !important;
}
.gx-btn-secondary:not([disabled]):not(.disabled):active,
a.gx-btn-secondary:not([disabled]):not(.disabled):active,
.gx-btn-secondary:not([disabled]):not(.disabled).active,
a.gx-btn-secondary:not([disabled]):not(.disabled).active {
  color: #ffffff;
  background-color: #fa8c15 !important;
  border-color: #fa8c15 !important;
}
.gx-btn-success,
a.gx-btn-success {
  color: #ffffff !important;
  background-color: #52c41a !important;
  border-color: #52c41a !important;
}
.gx-btn-success:hover,
a.gx-btn-success:hover,
.gx-btn-success:focus,
a.gx-btn-success:focus {
  color: #ffffff !important;
  background-color: #3f9714 !important;
  border-color: #3f9714 !important;
}
.gx-btn-success.disabled,
a.gx-btn-success.disabled,
.gx-btn-success:disabled,
a.gx-btn-success:disabled {
  background-color: #52c41a !important;
  border-color: #52c41a !important;
}
.gx-btn-success:not([disabled]):not(.disabled):active,
a.gx-btn-success:not([disabled]):not(.disabled):active,
.gx-btn-success:not([disabled]):not(.disabled).active,
a.gx-btn-success:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #3f9714 !important;
  border-color: #3f9714 !important;
}
.gx-btn-success-dark,
a.gx-btn-success-dark {
  color: #ffffff !important;
  background-color: #368011 !important;
  border-color: #368011 !important;
}
.gx-btn-success-dark:hover,
a.gx-btn-success-dark:hover,
.gx-btn-success-dark:focus,
a.gx-btn-success-dark:focus {
  color: #ffffff !important;
  background-color: #2c6a0e !important;
  border-color: #2c6a0e !important;
}
.gx-btn-success-dark.disabled,
a.gx-btn-success-dark.disabled,
.gx-btn-success-dark:disabled,
a.gx-btn-success-dark:disabled {
  background-color: #52c41a !important;
  border-color: #52c41a !important;
}
.gx-btn-success-dark:not([disabled]):not(.disabled):active,
a.gx-btn-success-dark:not([disabled]):not(.disabled):active,
.gx-btn-success-dark:not([disabled]):not(.disabled).active,
a.gx-btn-success-dark:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #2c6a0e !important;
  border-color: #2c6a0e !important;
}
.gx-btn-info,
a.gx-btn-info {
  color: #ffffff !important;
  background-color: #1890ff !important;
  border-color: #1890ff !important;
}
.gx-btn-info:hover,
a.gx-btn-info:hover,
.gx-btn-info:focus,
a.gx-btn-info:focus {
  color: #ffffff !important;
  background-color: #0076e4 !important;
  border-color: #0076e4 !important;
}
.gx-btn-info.disabled,
a.gx-btn-info.disabled,
.gx-btn-info:disabled,
a.gx-btn-info:disabled {
  background-color: #1890ff !important;
  border-color: #1890ff !important;
}
.gx-btn-info:not([disabled]):not(.disabled):active,
a.gx-btn-info:not([disabled]):not(.disabled):active,
.gx-btn-info:not([disabled]):not(.disabled).active,
a.gx-btn-info:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #0076e4 !important;
  border-color: #0076e4 !important;
}
.gx-btn-warning,
a.gx-btn-warning {
  color: #ffffff !important;
  background-color: #fa8c16 !important;
  border-color: #fa8c16 !important;
}
.gx-btn-warning:hover,
a.gx-btn-warning:hover,
.gx-btn-warning:focus,
a.gx-btn-warning:focus {
  color: #ffffff !important;
  background-color: #d87205 !important;
  border-color: #d87205 !important;
}
.gx-btn-warning.disabled,
a.gx-btn-warning.disabled,
.gx-btn-warning:disabled,
a.gx-btn-warning:disabled {
  background-color: #fa8c16 !important;
  border-color: #fa8c16 !important;
}
.gx-btn-warning:not([disabled]):not(.disabled):active,
a.gx-btn-warning:not([disabled]):not(.disabled):active,
.gx-btn-warning:not([disabled]):not(.disabled).active,
a.gx-btn-warning:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #d87205 !important;
  border-color: #d87205 !important;
}
.gx-btn-yellow,
a.gx-btn-yellow {
  color: #262626 !important;
  background-color: #fadb14 !important;
  border-color: #fadb14 !important;
}
.gx-btn-yellow:hover,
a.gx-btn-yellow:hover,
.gx-btn-yellow:focus,
a.gx-btn-yellow:focus {
  color: #ffffff !important;
  background-color: #d6ba05 !important;
  border-color: #d6ba05 !important;
}
.gx-btn-yellow.disabled,
a.gx-btn-yellow.disabled,
.gx-btn-yellow:disabled,
a.gx-btn-yellow:disabled {
  background-color: #fadb14 !important;
  border-color: #fadb14 !important;
}
.gx-btn-yellow:not([disabled]):not(.disabled):active,
a.gx-btn-yellow:not([disabled]):not(.disabled):active,
.gx-btn-yellow:not([disabled]):not(.disabled).active,
a.gx-btn-yellow:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #d6ba05 !important;
  border-color: #d6ba05 !important;
}
.gx-btn-danger,
a.gx-btn-danger,
.gx-btn-red,
a.gx-btn-red {
  color: #ffffff !important;
  background-color: #f44336 !important;
  border-color: #f44336 !important;
}
.gx-btn-danger:hover,
a.gx-btn-danger:hover,
.gx-btn-red:hover,
a.gx-btn-red:hover,
.gx-btn-danger:focus,
a.gx-btn-danger:focus,
.gx-btn-red:focus,
a.gx-btn-red:focus {
  color: #ffffff !important;
  background-color: #ea1c0d !important;
  border-color: #ea1c0d !important;
}
.gx-btn-danger.disabled,
a.gx-btn-danger.disabled,
.gx-btn-red.disabled,
a.gx-btn-red.disabled,
.gx-btn-danger:disabled,
a.gx-btn-danger:disabled,
.gx-btn-red:disabled,
a.gx-btn-red:disabled {
  background-color: #f44336 !important;
  border-color: #f44336 !important;
}
.gx-btn-danger:not([disabled]):not(.disabled):active,
a.gx-btn-danger:not([disabled]):not(.disabled):active,
.gx-btn-red:not([disabled]):not(.disabled):active,
a.gx-btn-red:not([disabled]):not(.disabled):active,
.gx-btn-danger:not([disabled]):not(.disabled).active,
a.gx-btn-danger:not([disabled]):not(.disabled).active,
.gx-btn-red:not([disabled]):not(.disabled).active,
a.gx-btn-red:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #ea1c0d !important;
  border-color: #ea1c0d !important;
}
.gx-btn-light,
a.gx-btn-light {
  color: #8c8c8c !important;
  background-color: #e8e8e8 !important;
  border-color: #e8e8e8 !important;
}
.gx-btn-light:hover,
a.gx-btn-light:hover,
.gx-btn-light:focus,
a.gx-btn-light:focus {
  color: #8c8c8c !important;
  background-color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
}
.gx-btn-light.disabled,
a.gx-btn-light.disabled,
.gx-btn-light:disabled,
a.gx-btn-light:disabled {
  background-color: #f5f5f5 !important;
  border-color: #f5f5f5 !important;
}
.gx-btn-light:not([disabled]):not(.disabled):active,
a.gx-btn-light:not([disabled]):not(.disabled):active,
.gx-btn-light:not([disabled]):not(.disabled).active,
a.gx-btn-light:not([disabled]):not(.disabled).active {
  color: #262626 !important;
  background-color: #e8e8e8 !important;
  border-color: #e8e8e8 !important;
}
.gx-btn-dark,
a.gx-btn-dark {
  color: #ffffff !important;
  background-color: #262626 !important;
  border-color: #262626 !important;
}
.gx-btn-dark:hover,
a.gx-btn-dark:hover,
.gx-btn-dark:focus,
a.gx-btn-dark:focus {
  color: #ffffff !important;
  background-color: #0c0c0c !important;
  border-color: #0c0c0c !important;
}
.gx-btn-dark.disabled,
a.gx-btn-dark.disabled,
.gx-btn-dark:disabled,
a.gx-btn-dark:disabled {
  background-color: #262626 !important;
  border-color: #262626 !important;
}
.gx-btn-dark:not([disabled]):not(.disabled):active,
a.gx-btn-dark:not([disabled]):not(.disabled):active,
.gx-btn-dark:not([disabled]):not(.disabled).active,
a.gx-btn-dark:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #0c0c0c !important;
  border-color: #0c0c0c !important;
}
.gx-btn-purple,
a.gx-btn-purple {
  color: #ffffff !important;
  background-color: #722ed1 !important;
  border-color: #722ed1 !important;
}
.gx-btn-purple:hover,
a.gx-btn-purple:hover,
.gx-btn-purple:focus,
a.gx-btn-purple:focus {
  color: #ffffff !important;
  background-color: #5b25a7 !important;
  border-color: #5b25a7 !important;
}
.gx-btn-purple.disabled,
a.gx-btn-purple.disabled,
.gx-btn-purple:disabled,
a.gx-btn-purple:disabled {
  background-color: #722ed1 !important;
  border-color: #722ed1 !important;
}
.gx-btn-purple:not([disabled]):not(.disabled):active,
a.gx-btn-purple:not([disabled]):not(.disabled):active,
.gx-btn-purple:not([disabled]):not(.disabled).active,
a.gx-btn-purple:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #5b25a7 !important;
  border-color: #5b25a7 !important;
}
.gx-btn-sepia,
a.gx-btn-sepia {
  color: #ffffff !important;
  background-color: #7c3535 !important;
  border-color: #7c3535 !important;
}
.gx-btn-sepia:hover,
a.gx-btn-sepia:hover,
.gx-btn-sepia:focus,
a.gx-btn-sepia:focus {
  color: #ffffff !important;
  background-color: #461e1e !important;
  border-color: #461e1e !important;
}
.gx-btn-sepia.disabled,
a.gx-btn-sepia.disabled,
.gx-btn-sepia:disabled,
a.gx-btn-sepia:disabled {
  background-color: #7c3535 !important;
  border-color: #7c3535 !important;
}
.gx-btn-sepia:not([disabled]):not(.disabled):active,
a.gx-btn-sepia:not([disabled]):not(.disabled):active,
.gx-btn-sepia:not([disabled]):not(.disabled).active,
a.gx-btn-sepia:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #461e1e !important;
  border-color: #461e1e !important;
}
.gx-btn-geekblue,
a.gx-btn-geekblue {
  color: #ffffff !important;
  background-color: #10239e !important;
  border-color: #10239e !important;
}
.gx-btn-geekblue:hover,
a.gx-btn-geekblue:hover,
.gx-btn-geekblue:focus,
a.gx-btn-geekblue:focus {
  color: #ffffff !important;
  background-color: #0b1970 !important;
  border-color: #0b1970 !important;
}
.gx-btn-geekblue.disabled,
a.gx-btn-geekblue.disabled,
.gx-btn-geekblue:disabled,
a.gx-btn-geekblue:disabled {
  background-color: #10239e !important;
  border-color: #10239e !important;
}
.gx-btn-geekblue:not([disabled]):not(.disabled):active,
a.gx-btn-geekblue:not([disabled]):not(.disabled):active,
.gx-btn-geekblue:not([disabled]):not(.disabled).active,
a.gx-btn-geekblue:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #091459 !important;
  border-color: #091459 !important;
}
.gx-btn-cyan,
a.gx-btn-cyan {
  color: #ffffff !important;
  background-color: #13c2c2 !important;
  border-color: #13c2c2 !important;
}
.gx-btn-cyan:hover,
a.gx-btn-cyan:hover,
.gx-btn-cyan:focus,
a.gx-btn-cyan:focus {
  color: #ffffff !important;
  background-color: #0e9494 !important;
  border-color: #0e9494 !important;
}
.gx-btn-cyan.disabled,
a.gx-btn-cyan.disabled,
.gx-btn-cyan:disabled,
a.gx-btn-cyan:disabled {
  background-color: #13c2c2 !important;
  border-color: #13c2c2 !important;
}
.gx-btn-cyan:not([disabled]):not(.disabled):active,
a.gx-btn-cyan:not([disabled]):not(.disabled):active,
.gx-btn-cyan:not([disabled]):not(.disabled).active,
a.gx-btn-cyan:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #0e8a8a !important;
  border-color: #0e8a8a !important;
}
.gx-btn-white,
a.gx-btn-white {
  color: #545454 !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}
.gx-btn-white:hover,
a.gx-btn-white:hover,
.gx-btn-white:focus,
a.gx-btn-white:focus {
  color: #545454 !important;
  background-color: #e6e6e6 !important;
  border-color: #e6e6e6 !important;
}
.gx-btn-white.disabled,
a.gx-btn-white.disabled,
.gx-btn-white:disabled,
a.gx-btn-white:disabled {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}
.gx-btn-white:not([disabled]):not(.disabled):active,
a.gx-btn-white:not([disabled]):not(.disabled):active,
.gx-btn-white:not([disabled]):not(.disabled).active,
a.gx-btn-white:not([disabled]):not(.disabled).active {
  color: #545454 !important;
  background-color: #e6e6e6 !important;
  border-color: #e6e6e6 !important;
}
.gx-btn-orange,
a.gx-btn-orange {
  color: #ffffff !important;
  background-color: #fa8c16 !important;
  border-color: #fa8c16 !important;
}
.gx-btn-orange:hover,
a.gx-btn-orange:hover,
.gx-btn-orange:focus,
a.gx-btn-orange:focus {
  color: #ffffff !important;
  background-color: #d87205 !important;
  border-color: #d87205 !important;
}
.gx-btn-orange.disabled,
a.gx-btn-orange.disabled,
.gx-btn-orange:disabled,
a.gx-btn-orange:disabled {
  background-color: #fa8c16 !important;
  border-color: #fa8c16 !important;
}
.gx-btn-orange:not([disabled]):not(.disabled):active,
a.gx-btn-orange:not([disabled]):not(.disabled):active,
.gx-btn-orange:not([disabled]):not(.disabled).active,
a.gx-btn-orange:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #ce6d04 !important;
  border-color: #ce6d04 !important;
}
.gx-btn-outline-primary,
a.gx-btn-outline-primary {
  color: #038fde !important;
  background-color: transparent !important;
  border-color: #038fde;
}
.gx-btn-outline-primary:hover,
a.gx-btn-outline-primary:hover,
.gx-btn-outline-primary:focus,
a.gx-btn-outline-primary:focus {
  color: #ffffff !important;
  background-color: #038fde !important;
  border-color: #038fde;
}
.gx-btn-outline-primary.disabled,
a.gx-btn-outline-primary.disabled,
.gx-btn-outline-primary:disabled,
a.gx-btn-outline-primary:disabled {
  color: #038fde !important;
  background-color: transparent !important;
}
.gx-btn-outline-secondary,
a.gx-btn-outline-secondary {
  color: #fa8c15 !important;
  background-color: transparent !important;
  border-color: #fa8c15 !important;
}
.gx-btn-outline-secondary:hover,
a.gx-btn-outline-secondary:hover,
.gx-btn-outline-secondary:focus,
a.gx-btn-outline-secondary:focus {
  color: #ffffff !important;
  background-color: #fa8c15 !important;
  border-color: #fa8c15 !important;
}
.gx-btn-outline-secondary.disabled,
a.gx-btn-outline-secondary.disabled,
.gx-btn-outline-secondary:disabled,
a.gx-btn-outline-secondary:disabled {
  color: #fa8c15 !important;
  background-color: transparent !important;
}
.gx-btn-outline-success,
a.gx-btn-outline-success {
  color: #52c41a !important;
  background-color: transparent !important;
  border-color: #52c41a !important;
}
.gx-btn-outline-success:hover,
a.gx-btn-outline-success:hover,
.gx-btn-outline-success:focus,
a.gx-btn-outline-success:focus {
  color: #ffffff !important;
  background-color: #52c41a !important;
  border-color: #52c41a !important;
}
.gx-btn-outline-success.disabled,
a.gx-btn-outline-success.disabled,
.gx-btn-outline-success:disabled,
a.gx-btn-outline-success:disabled {
  color: #52c41a !important;
  background-color: transparent !important;
}
.gx-btn-outline-success-dark,
a.gx-btn-outline-success-dark {
  color: #52c41a !important;
  background-color: transparent !important;
  border-color: #368011 !important;
}
.gx-btn-outline-success-dark:hover,
a.gx-btn-outline-success-dark:hover,
.gx-btn-outline-success-dark:focus,
a.gx-btn-outline-success-dark:focus {
  color: #ffffff !important;
  background-color: #2c6a0e !important;
  border-color: #2c6a0e !important;
}
.gx-btn-outline-success-dark.disabled,
a.gx-btn-outline-success-dark.disabled,
.gx-btn-outline-success-dark:disabled,
a.gx-btn-outline-success-dark:disabled {
  color: #2c6a0e !important;
  background-color: transparent !important;
}
.gx-btn-outline-info,
a.gx-btn-outline-info {
  color: #1890ff !important;
  background-color: transparent !important;
  border-color: #1890ff !important;
}
.gx-btn-outline-info:hover,
a.gx-btn-outline-info:hover,
.gx-btn-outline-info:focus,
a.gx-btn-outline-info:focus {
  color: #ffffff !important;
  background-color: #1890ff !important;
  border-color: #1890ff !important;
}
.gx-btn-outline-info.disabled,
a.gx-btn-outline-info.disabled,
.gx-btn-outline-info:disabled,
a.gx-btn-outline-info:disabled {
  color: #1890ff !important;
  background-color: transparent !important;
}
.gx-btn-outline-warning,
a.gx-btn-outline-warning {
  color: #fa8c16 !important;
  background-color: transparent !important;
  border-color: #fa8c16 !important;
}
.gx-btn-outline-warning:hover,
a.gx-btn-outline-warning:hover,
.gx-btn-outline-warning:focus,
a.gx-btn-outline-warning:focus {
  color: #262626 !important;
  background-color: #fa8c16 !important;
  border-color: #fa8c16 !important;
}
.gx-btn-outline-warning.disabled,
a.gx-btn-outline-warning.disabled,
.gx-btn-outline-warning:disabled,
a.gx-btn-outline-warning:disabled {
  color: #fa8c16 !important;
  background-color: transparent !important;
}
.gx-btn-outline-danger,
a.gx-btn-outline-danger,
.gx-btn-outline-red,
a.gx-btn-outline-red {
  color: #f44336 !important;
  background-color: transparent !important;
  border-color: #f44336 !important;
}
.gx-btn-outline-danger:hover,
a.gx-btn-outline-danger:hover,
.gx-btn-outline-red:hover,
a.gx-btn-outline-red:hover,
.gx-btn-outline-danger:focus,
a.gx-btn-outline-danger:focus,
.gx-btn-outline-red:focus,
a.gx-btn-outline-red:focus {
  color: #ffffff !important;
  background-color: #f44336 !important;
  border-color: #f44336 !important;
}
.gx-btn-outline-danger.disabled,
a.gx-btn-outline-danger.disabled,
.gx-btn-outline-red.disabled,
a.gx-btn-outline-red.disabled,
.gx-btn-outline-danger:disabled,
a.gx-btn-outline-danger:disabled,
.gx-btn-outline-red:disabled,
a.gx-btn-outline-red:disabled {
  color: #f44336 !important;
  background-color: transparent !important;
}
.gx-btn-outline-light,
a.gx-btn-outline-light {
  color: #8c8c8c !important;
  background-color: transparent !important;
  border-color: #d9d9d9 !important;
}
.gx-btn-outline-light:hover,
a.gx-btn-outline-light:hover,
.gx-btn-outline-light:focus,
a.gx-btn-outline-light:focus {
  color: #8c8c8c !important;
  background-color: #d9d9d9 !important;
  border-color: #d9d9d9 !important;
}
.gx-btn-outline-light.disabled,
a.gx-btn-outline-light.disabled,
.gx-btn-outline-light:disabled,
a.gx-btn-outline-light:disabled {
  color: #e8e8e8 !important;
  background-color: transparent !important;
}
.gx-btn-outline-dark,
a.gx-btn-outline-dark {
  color: #262626 !important;
  background-color: transparent !important;
  border-color: #262626 !important;
}
.gx-btn-outline-dark:hover,
a.gx-btn-outline-dark:hover,
.gx-btn-outline-dark:focus,
a.gx-btn-outline-dark:focus {
  color: #ffffff !important;
  background-color: #262626 !important;
  border-color: #262626 !important;
}
.gx-btn-outline-dark.disabled,
a.gx-btn-outline-dark.disabled,
.gx-btn-outline-dark:disabled,
a.gx-btn-outline-dark:disabled {
  color: #262626 !important;
  background-color: transparent !important;
}
.gx-btn-outline-sepia,
a.gx-btn-outline-sepia {
  color: #ffffff !important;
  background-color: transparent !important;
  border-color: #7c3535 !important;
}
.gx-btn-outline-sepia:hover,
a.gx-btn-outline-sepia:hover,
.gx-btn-outline-sepia:focus,
a.gx-btn-outline-sepia:focus {
  color: #ffffff !important;
  background-color: #7c3535 !important;
  border-color: #7c3535 !important;
}
.gx-btn-outline-sepia.disabled,
a.gx-btn-outline-sepia.disabled,
.gx-btn-outline-sepia:disabled,
a.gx-btn-outline-sepia:disabled {
  color: #7c3535 !important;
  background-color: transparent !important;
}
.gx-btn-outline-geekblue,
a.gx-btn-outline-geekblue {
  color: #ffffff !important;
  background-color: transparent !important;
  border-color: #030852 !important;
}
.gx-btn-outline-geekblue:hover,
a.gx-btn-outline-geekblue:hover,
.gx-btn-outline-geekblue:focus,
a.gx-btn-outline-geekblue:focus {
  color: #ffffff !important;
  background-color: #030852 !important;
  border-color: #030852 !important;
}
.gx-btn-outline-geekblue.disabled,
a.gx-btn-outline-geekblue.disabled,
.gx-btn-outline-geekblue:disabled,
a.gx-btn-outline-geekblue:disabled {
  color: #2f54eb !important;
  background-color: transparent !important;
}
.gx-btn-outline-orange,
a.gx-btn-outline-orange {
  color: #ffffff !important;
  background-color: transparent !important;
  border-color: #fa8c16 !important;
}
.gx-btn-outline-orange:hover,
a.gx-btn-outline-orange:hover,
.gx-btn-outline-orange:focus,
a.gx-btn-outline-orange:focus {
  color: #ffffff !important;
  background-color: #fa8c16 !important;
  border-color: #fa8c16 !important;
}
.gx-btn-outline-orange.disabled,
a.gx-btn-outline-orange.disabled,
.gx-btn-outline-orange:disabled,
a.gx-btn-outline-orange:disabled {
  color: #fa8c16 !important;
  background-color: transparent !important;
}
.gx-badge-primary {
  background-color: #038fde !important;
}
.gx-badge-primary-light {
  background-color: #52c6f7 !important;
}
.gx-badge-secondary {
  background-color: #fa8c15 !important;
}
.gx-badge-success {
  background-color: #52c41a !important;
}
.gx-badge-success-dark {
  background-color: #368011 !important;
}
.gx-badge-info {
  background-color: #1890ff !important;
}
.gx-badge-warning {
  background-color: #fa8c16 !important;
}
.gx-badge-danger {
  background-color: #f44336 !important;
}
.gx-badge-light {
  background-color: #e8e8e8 !important;
}
.gx-badge-dark {
  background-color: #000000 !important;
}
.gx-badge-white {
  background-color: #ffffff !important;
}
.gx-badge-grey {
  background-color: #bfbfbf !important;
}
.gx-badge-light-grey {
  background-color: #d9d9d9 !important;
}
.gx-badge-pink {
  background-color: #eb2f96 !important;
}
.gx-badge-green {
  background-color: #52c41a !important;
}
.gx-badge-green-light {
  background-color: #caf5b5 !important;
}
.gx-badge-red {
  background-color: #f5222d !important;
}
.gx-badge-amber {
  background-color: #fadb14 !important;
}
.gx-badge-amber-light {
  background-color: #fdf2aa !important;
}
.gx-badge-blue {
  background-color: #1890ff !important;
}
.gx-badge-light-blue {
  background-color: #329cff !important;
}
.gx-badge-indigo {
  background-color: #3c1991 !important;
}
.gx-badge-purple {
  background-color: #722ed1 !important;
}
.gx-badge-orange {
  background-color: #fa8c16 !important;
}
.gx-badge-yellow {
  background-color: #fadb14 !important;
}
.gx-badge-teal {
  background-color: #20c997 !important;
}
.gx-badge-cyan {
  background-color: #13c2c2 !important;
}
.gx-badge-sepia {
  background-color: #7c3535 !important;
}
.gx-badge-geekblue {
  background-color: #030852 !important;
}
/* Ripple magic */
.gx-ripple-effect {
  position: relative;
  overflow: hidden;
}
.gx-ripple-effect:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  -webkit-transform: scale(1, 1) translate(-50%);
          transform: scale(1, 1) translate(-50%);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.gx-ripple-effect:hover:after {
  -webkit-animation: ripple 1s ease-out;
          animation: ripple 1s ease-out;
}
@keyframes ripple {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: 0.5;
  }
  20% {
    -webkit-transform: scale(60, 60);
            transform: scale(60, 60);
    opacity: 0.3;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(100, 100);
            transform: scale(100, 100);
  }
}
@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: 0.5;
  }
  20% {
    -webkit-transform: scale(60, 60);
            transform: scale(60, 60);
    opacity: 0.3;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(100, 100);
            transform: scale(100, 100);
  }
}
.gx-pulse-effect {
  display: block;
  box-shadow: 0 0 0 rgba(244, 67, 54, 0.4);
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(244, 67, 54, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(244, 67, 54, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
  }
}
.gx-online-effect {
  display: block;
  box-shadow: 0 0 0 rgba(82, 196, 26, 0.4);
  -webkit-animation: online 2s infinite;
          animation: online 2s infinite;
}
@-webkit-keyframes online {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(82, 196, 26, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(82, 196, 26, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(82, 196, 26, 0);
  }
}
@keyframes online {
  0% {
    box-shadow: 0 0 0 0 rgba(82, 196, 26, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(82, 196, 26, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(82, 196, 26, 0);
  }
}
.gx-away-effect {
  display: block;
  box-shadow: 0 0 0 rgba(250, 219, 20, 0.4);
  -webkit-animation: away 2s infinite;
          animation: away 2s infinite;
}
@-webkit-keyframes away {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(250, 219, 20, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(250, 219, 20, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(250, 219, 20, 0);
  }
}
@keyframes away {
  0% {
    box-shadow: 0 0 0 0 rgba(250, 219, 20, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(250, 219, 20, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(250, 219, 20, 0);
  }
}
.gx-orange-effect {
  display: block;
  box-shadow: 0 0 0 rgba(250, 140, 22, 0.4);
  -webkit-animation: away 2s infinite;
          animation: away 2s infinite;
}
@-webkit-keyframes away {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(250, 140, 22, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(250, 140, 22, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(250, 140, 22, 0);
  }
}
@keyframes away {
  0% {
    box-shadow: 0 0 0 0 rgba(250, 140, 22, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(250, 140, 22, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(250, 140, 22, 0);
  }
}
@-webkit-keyframes fxicon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fxicon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.fxicon-hc-spin {
  -webkit-animation: fxicon-spin 1.5s infinite linear;
  animation: fxicon-spin 1.5s infinite linear;
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadein {
  -webkit-animation-name: fadein;
  animation-name: fadein;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fadeout {
  -webkit-animation-name: fadeout;
  animation-name: fadeout;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
::-webkit-scrollbar {
  width: 3px;
  /* This is more usable for users trying to click it. */
  background-color: rgba(100, 100, 100, 0.1);
  -webkit-border-radius: 100px;
}
/* hover effect for both scrollbar area, and scrollbar 'thumb' */
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}
/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb:vertical {
  /* This is the EXACT color of Mac OS scrollbars.
     Yes, I pulled out digital color meter */
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0, 0, 0, 0.61);
  /* Some darker color when you click it */
  -webkit-border-radius: 100px;
}
/*Base Styles*/
html,
body {
  /* Avoid ugly scrollbars */
  overflow: hidden;
  /* Reset default browser paddings etc */
  margin: 0;
  padding: 0;
  border: 0;
  /* 100% size */
  width: 100%;
  height: 100%;
}
body {
  margin-top: 1px;
  overflow: hidden;
  transition: all 0.3s ease-out;
  width: 100% !important;
  position: relative;
  font-weight: 100;
}
body:-webkit-full-screen {
  width: 100%;
  height: 100%;
}
body.framed-layout {
  padding: 20px;
  background-color: #038fde;
}
@media screen and (max-width: 991px) {
  body.framed-layout {
    padding: 0;
  }
}
body.boxed-layout {
  background-color: #038fde;
  max-width: 1280px;
  margin: 0 auto;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
}
@media screen and (min-width: 1600px) {
  body.boxed-layout {
    max-width: 1500px;
  }
}
body.full-scroll {
  -webkit-overflow-scrolling: touch !important;
}
#root {
  height: 100vh;
}
.framed-layout #root {
  height: calc(100vh - 40px);
  overflow: hidden;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
}
@media screen and (max-width: 991px) {
  .framed-layout #root {
    height: 100vh;
    box-shadow: none !important;
    border-radius: 0 !important;
  }
}
.gx-pointer,
.gx-link {
  cursor: pointer;
}
a,
.gx-link {
  color: #038fde;
}
a:hover,
.gx-link:hover,
a:focus,
.gx-link:focus {
  color: #038fde;
}
a:focus {
  text-decoration: none;
}
ol,
ul,
dl {
  padding-left: 30px;
  margin-bottom: 10px;
}
img {
  max-width: 100%;
}
.gx-w-25 {
  width: 25% !important;
}
.gx-w-50 {
  width: 50% !important;
}
.gx-w-75 {
  width: 75% !important;
}
.gx-w-100 {
  width: 100% !important;
}
.gx-h-25 {
  height: 25% !important;
}
.gx-h-50 {
  height: 50% !important;
}
.gx-h-75 {
  height: 75% !important;
}
.gx-h-100 {
  height: 100% !important;
}
.gx-mw-100 {
  max-width: 100% !important;
}
.gx-mh-100 {
  max-height: 100% !important;
}
.gx-minw50 {
  min-width: 50px;
}
.gx-border {
  border: solid 1px #e8e8e8;
}
.gx-border-2 {
  border: solid 2px #e8e8e8;
}
.gx-border-top {
  border-top: solid 1px #e8e8e8;
}
.gx-border-bottom {
  border-bottom: solid 1px #e8e8e8;
}
.gx-border-bottom-2 {
  border-bottom: solid 2px #e8e8e8;
}
.gx-border-left {
  border-left: solid 1px #e8e8e8;
}
.gx-border-right {
  border-right: solid 1px #e8e8e8;
}
.gx-border-0 {
  border: 0 none !important;
}
.gx-page-heading {
  margin-bottom: 32px;
}
@media screen and (max-width: 575px) {
  .gx-page-heading {
    margin-bottom: 20px;
  }
}
.gx-page-title {
  font-size: 20px;
  margin-bottom: 0;
}
.gx-size-200 {
  height: 200px !important;
  width: 200px !important;
  line-height: 200px !important;
}
.gx-size-150 {
  height: 150px !important;
  width: 150px !important;
  line-height: 150px !important;
}
.gx-size-120 {
  height: 120px !important;
  width: 120px !important;
  line-height: 120px !important;
}
.gx-size-100 {
  height: 100px !important;
  width: 100px !important;
  line-height: 100px !important;
}
.gx-size-90 {
  height: 90px !important;
  width: 90px !important;
  line-height: 90px !important;
}
.gx-size-80 {
  height: 80px !important;
  width: 80px !important;
  line-height: 80px !important;
}
.gx-size-60 {
  height: 60px !important;
  width: 60px !important;
  line-height: 60px !important;
}
.gx-size-50 {
  height: 50px !important;
  width: 50px !important;
  line-height: 50px !important;
}
.gx-size-40 {
  height: 40px !important;
  width: 40px !important;
  line-height: 40px !important;
}
.gx-size-36 {
  height: 36px !important;
  width: 36px !important;
  line-height: 36px !important;
}
.gx-size-30 {
  height: 30px !important;
  width: 30px !important;
  line-height: 30px !important;
}
.gx-size-24 {
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
}
.gx-size-20 {
  height: 20px !important;
  width: 20px !important;
  line-height: 20px !important;
}
.gx-size-15 {
  height: 15px !important;
  width: 15px !important;
  line-height: 14px !important;
}
.gx-size-10 {
  height: 10px !important;
  width: 10px !important;
  line-height: 10px !important;
}
.gx-size-8 {
  height: 8px !important;
  width: 8px !important;
  line-height: 8px !important;
}
.gx-width-175 {
  width: 175px !important;
}
.gx-img-fluid,
.gx-img-thumbnail {
  max-width: 100%;
  height: auto;
}
.gx-img-fluid-full {
  width: 100%;
}
/*Social Style*/
.gx-social-link {
  list-style: none;
  padding-left: 0;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  margin: 0 -5px 10px;
}
.gx-social-link li {
  padding: 0 5px;
  margin-bottom: 5px;
  line-height: 1;
}
.gx-social-link li a,
.gx-social-link li .gx-link,
.gx-social-link li i {
  cursor: pointer;
  width: 30px;
  height: 30px;
  line-height: 34px;
  font-size: 16px;
  text-align: center;
  color: #038fde;
  border: solid 1px #038fde;
  border-radius: 50%;
}
.gx-social-link li.active a,
.gx-social-link li.active .gx-link,
.gx-social-link li.active i,
.gx-social-link li a:hover,
.gx-social-link li .gx-link:hover,
.gx-social-link li i:hover,
.gx-social-link li a:focus,
.gx-social-link li .gx-link:focus,
.gx-social-link li i:focus {
  color: #ffffff;
  background: #038fde;
}
.gx-card-social .gx-social-link {
  margin-bottom: 0;
  -ms-justify-content: space-around;
  justify-content: space-around;
}
.gx-float-left {
  float: left !important;
}
.gx-float-right {
  float: right !important;
}
.gx-float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .gx-float-sm-left {
    float: left !important;
  }
  .gx-float-sm-right {
    float: right !important;
  }
  .gx-float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .gx-float-md-left {
    float: left !important;
  }
  .gx-float-md-right {
    float: right !important;
  }
  .gx-float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .gx-float-lg-left {
    float: left !important;
  }
  .gx-float-lg-right {
    float: right !important;
  }
  .gx-float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .gx-float-xl-left {
    float: left !important;
  }
  .gx-float-xl-right {
    float: right !important;
  }
  .gx-float-xl-none {
    float: none !important;
  }
}
.gx-position-static {
  position: static !important;
}
.gx-position-relative {
  position: relative !important;
}
.gx-position-absolute {
  position: absolute !important;
}
.gx-position-fixed {
  position: fixed !important;
}
.gx-position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.gx-fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.gx-fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
.gx-top-0 {
  top: 0 !important;
}
.gx-bottom-0 {
  bottom: 0 !important;
}
.gx-left-0 {
  left: 0 !important;
}
.gx-right-0 {
  right: 0 !important;
}
/*Embed Responsive Classes*/
.gx-embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.gx-embed-responsive:before {
  display: block;
  content: "";
}
.gx-embed-responsive .gx-embed-responsive-item,
.gx-embed-responsive iframe,
.gx-embed-responsive embed,
.gx-embed-responsive object,
.gx-embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.gx-embed-responsive-21by9:before {
  padding-top: 42.8571428571%;
}
.gx-embed-responsive-16by9:before {
  padding-top: 56.25%;
}
.gx-embed-responsive-4by3:before {
  padding-top: 75%;
}
.gx-embed-responsive-1by1:before {
  padding-top: 100%;
}
/*Media Object Classes*/
.gx-media {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: flex-start;
  align-items: flex-start;
}
.gx-media-body {
  flex: 1 1;
}
/*Overflow styles*/
.gx-overflow-auto {
  overflow: auto;
}
.gx-z-index-20 {
  position: relative;
  z-index: 20;
}
/*Hr style*/
hr {
  border-color: rgba(250, 250, 250, 0.2);
  margin: 15px 0;
}
/*Status style*/
.gx-status-pos {
  position: relative;
}
.gx-status-pos .gx-status {
  display: block;
  position: absolute;
  left: 0;
  top: 2px;
  z-index: 1;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.gx-status-pos .gx-status.gx-small {
  width: 8px;
  height: 8px;
}
.gx-status-pos .gx-status.gx-status-rtl {
  top: -4px;
  left: auto;
  right: -4px;
}
.gx-status-pos .gx-status.gx-online {
  background-color: #52c41a;
  display: block;
  box-shadow: 0 0 0 rgba(82, 196, 26, 0.4);
  -webkit-animation: online 2s infinite;
          animation: online 2s infinite;
}
.gx-status-pos .gx-status.gx-offline {
  background-color: #ffffff;
  border: solid 1px #52c41a;
  display: block;
  box-shadow: 0 0 0 rgba(82, 196, 26, 0.4);
  -webkit-animation: online 2s infinite;
          animation: online 2s infinite;
}
.gx-status-pos .gx-status.gx-away {
  background-color: #fadb14;
  display: block;
  box-shadow: 0 0 0 rgba(250, 219, 20, 0.4);
  -webkit-animation: away 2s infinite;
          animation: away 2s infinite;
}
.gx-status-pos .gx-status.gx-orange {
  background-color: #fa8c16;
  display: block;
  box-shadow: 0 0 0 rgba(250, 140, 22, 0.4);
  -webkit-animation: away 2s infinite;
          animation: away 2s infinite;
}
.gx-box-shadow {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
}
.gx-no-box-shadow {
  box-shadow: none;
}
.gx-rounded-xs {
  border-radius: 2px !important;
}
.gx-rounded-sm {
  border-radius: 4px !important;
}
.gx-rounded-base {
  border-radius: 6px !important;
}
.gx-rounded-lg {
  border-radius: 10px !important;
}
.gx-rounded-xxl {
  border-radius: 30px !important;
}
.gx-rounded-circle {
  border-radius: 50% !important;
}
.gx-border-radius-top-left-0 {
  -moz-border-top-left-radius: 0;
  border-top-left-radius: 0;
}
.gx-border-radius-top-right-0 {
  -moz-border-top-right-radius: 0;
  border-top-right-radius: 0;
}
.gx-border-radius-bottom-left-0 {
  -moz-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
}
.gx-border-radius-bottom-right-0 {
  -moz-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}
.gx-vertical-align-top {
  vertical-align: top;
}
.gx-vertical-align-middle {
  vertical-align: middle;
}
.gx-vertical-align-bottom {
  vertical-align: bottom;
}
.gx-separator {
  background-color: #038fde;
  width: 20px;
  height: 2px;
  margin: 0 auto 10px;
}
.gx-loader-400 {
  height: 400px;
}
@media screen and (max-width: 1199px) {
  .gx-loader-400 {
    height: 300px;
  }
}
.gx-object-cover {
  object-fit: cover;
}
.cke_editable,
.cke_editor_editor2 {
  font-family: 'NoirPro', sans-serif !important;
}
.gx-top2 {
  position: relative;
  top: 2px;
}
@media screen and (max-width: 1366px) {
  .gx-hide-1366 {
    display: none;
  }
}
* {
  -webkit-overflow-scrolling: touch !important;
}
body,
#root {
  -webkit-overflow-scrolling: touch !important;
}
/*flex Styles*/
.gx-d-flex {
  display: flex !important;
}
.gx-d-none {
  display: none !important;
}
.gx-d-inline-block {
  display: inline-block !important;
}
.gx-d-block {
  display: block !important;
}
.gx-d-inline-flex {
  display: inline-flex !important;
}
.gx-flex-1 {
  flex: 1 1 auto;
}
/*Display Flex Classes*/
.gx-flex-row {
  display: -ms-flex !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
}
.gx-flex-column {
  display: -ms-flex !important;
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: nowrap !important;
}
.gx-flex-nowrap {
  flex-wrap: nowrap !important;
}
.gx-flex-row-reverse {
  flex-direction: row-reverse !important;
}
.gx-flex-column-reverse {
  flex-direction: column-reverse !important;
}
.gx-justify-content-start {
  -ms-justify-content: flex-start !important;
  justify-content: flex-start !important;
}
.gx-justify-content-end {
  -ms-justify-content: flex-end !important;
  justify-content: flex-end !important;
}
.gx-justify-content-center {
  -ms-justify-content: center !important;
  justify-content: center !important;
}
.gx-justify-content-between {
  -ms-justify-content: space-between !important;
  justify-content: space-between !important;
}
.gx-justify-content-around {
  -ms-justify-content: space-around !important;
  justify-content: space-around !important;
}
.gx-align-items-start {
  -ms-align-items: flex-start !important;
  align-items: flex-start !important;
}
.gx-align-items-end {
  -ms-align-items: flex-end !important;
  align-items: flex-end !important;
}
.gx-align-items-center {
  -ms-align-items: center !important;
  align-items: center !important;
}
.gx-align-items-stretch {
  -ms-align-items: stretch !important;
  align-items: stretch !important;
}
.gx-align-content-start {
  -ms-align-content: flex-start !important;
  align-content: flex-start !important;
}
.gx-align-content-end {
  -ms-align-content: flex-end !important;
  align-content: flex-end !important;
}
.gx-align-content-center {
  -ms-align-content: center !important;
  align-content: center !important;
}
.gx-align-content-between {
  -ms-align-content: space-between !important;
  align-content: space-between !important;
}
.gx-align-content-around {
  -ms-align-content: space-around !important;
  align-content: space-around !important;
}
.gx-align-content-stretch {
  -ms-align-content: stretch !important;
  align-content: stretch !important;
}
.gx-align-self-auto {
  -ms-align-self: auto !important;
  align-self: auto !important;
}
.gx-align-self-start {
  -ms-align-self: flex-start !important;
  align-self: flex-start !important;
}
.gx-align-self-end {
  -ms-align-self: flex-end !important;
  align-self: flex-end !important;
}
.gx-align-self-center {
  -ms-align-self: center !important;
  align-self: center !important;
}
.gx-align-self-baseline {
  -ms-align-self: baseline !important;
  align-self: baseline !important;
}
.gx-align-self-stretch {
  -ms-align-self: stretch !important;
  align-self: stretch !important;
}
.gx-order-first {
  order: -1;
}
.gx-order-1 {
  order: 1;
}
.gx-order-2 {
  order: 2;
}
.gx-order-3 {
  order: 3;
}
.gx-order-4 {
  order: 4;
}
.gx-order-5 {
  order: 5;
}
.gx-order-6 {
  order: 6;
}
.gx-order-7 {
  order: 7;
}
.gx-order-8 {
  order: 8;
}
.gx-order-9 {
  order: 9;
}
.gx-order-10 {
  order: 10;
}
.gx-order-11 {
  order: 11;
}
.gx-order-12 {
  order: 12;
}
.gx-offset-1 {
  margin-left: 8.333333%;
}
.gx-offset-2 {
  margin-left: 16.666667%;
}
.gx-offset-3 {
  margin-left: 25%;
}
.gx-offset-4 {
  margin-left: 33.333333%;
}
.gx-offset-5 {
  margin-left: 41.666667%;
}
.gx-offset-6 {
  margin-left: 50%;
}
.gx-offset-7 {
  margin-left: 58.333333%;
}
.gx-offset-8 {
  margin-left: 66.666667%;
}
.gx-offset-9 {
  margin-left: 75%;
}
.gx-offset-10 {
  margin-left: 83.333333%;
}
.gx-offset-11 {
  margin-left: 91.666667%;
}
@media screen and (min-width: 576px) {
  .gx-d-sm-none {
    display: none !important;
  }
  .gx-d-sm-inline-block {
    display: inline-block !important;
  }
  .gx-d-sm-block {
    display: block !important;
  }
  .gx-d-sm-flex {
    display: flex !important;
  }
  .gx-d-sm-inline-flex {
    display: inline-flex !important;
  }
  .gx-flex-sm-row {
    flex-direction: row !important;
  }
  .gx-flex-sm-column {
    flex-direction: column !important;
  }
  .gx-flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .gx-flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .gx-flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .gx-flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .gx-flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gx-justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .gx-justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .gx-justify-content-sm-center {
    justify-content: center !important;
  }
  .gx-justify-content-sm-between {
    justify-content: space-between !important;
  }
  .gx-justify-content-sm-around {
    justify-content: space-around !important;
  }
  .gx-align-items-sm-start {
    align-items: flex-start !important;
  }
  .gx-align-items-sm-end {
    align-items: flex-end !important;
  }
  .gx-align-items-sm-center {
    align-items: center !important;
  }
  .gx-align-items-sm-baseline {
    align-items: baseline !important;
  }
  .gx-align-items-sm-stretch {
    align-items: stretch !important;
  }
  .gx-align-content-sm-start {
    align-content: flex-start !important;
  }
  .gx-align-content-sm-end {
    align-content: flex-end !important;
  }
  .gx-align-content-sm-center {
    align-content: center !important;
  }
  .gx-align-content-sm-between {
    align-content: space-between !important;
  }
  .gx-align-content-sm-around {
    align-content: space-around !important;
  }
  .gx-align-content-sm-stretch {
    align-content: stretch !important;
  }
  .gx-align-self-sm-auto {
    align-self: auto !important;
  }
  .gx-align-self-sm-start {
    align-self: flex-start !important;
  }
  .gx-align-self-sm-end {
    align-self: flex-end !important;
  }
  .gx-align-self-sm-center {
    align-self: center !important;
  }
  .gx-align-self-sm-baseline {
    align-self: baseline !important;
  }
  .gx-align-self-sm-stretch {
    align-self: stretch !important;
  }
  .gx-order-sm-first {
    order: -1;
  }
  .gx-order-sm-1 {
    order: 1;
  }
  .gx-order-sm-2 {
    order: 2;
  }
  .gx-order-sm-3 {
    order: 3;
  }
  .gx-order-sm-4 {
    order: 4;
  }
  .gx-order-sm-5 {
    order: 5;
  }
  .gx-order-sm-6 {
    order: 6;
  }
  .gx-order-sm-7 {
    order: 7;
  }
  .gx-order-sm-8 {
    order: 8;
  }
  .gx-order-sm-9 {
    order: 9;
  }
  .gx-order-sm-10 {
    order: 10;
  }
  .gx-order-sm-11 {
    order: 11;
  }
  .gx-order-sm-12 {
    order: 12;
  }
  .gx-offset-sm-0 {
    margin-left: 0;
  }
  .gx-offset-sm-1 {
    margin-left: 8.333333%;
  }
  .gx-offset-sm-2 {
    margin-left: 16.666667%;
  }
  .gx-offset-sm-3 {
    margin-left: 25%;
  }
  .gx-offset-sm-4 {
    margin-left: 33.333333%;
  }
  .gx-offset-sm-5 {
    margin-left: 41.666667%;
  }
  .gx-offset-sm-6 {
    margin-left: 50%;
  }
  .gx-offset-sm-7 {
    margin-left: 58.333333%;
  }
  .gx-offset-sm-8 {
    margin-left: 66.666667%;
  }
  .gx-offset-sm-9 {
    margin-left: 75%;
  }
  .gx-offset-sm-10 {
    margin-left: 83.333333%;
  }
  .gx-offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media screen and (min-width: 768px) {
  .gx-d-md-none {
    display: none !important;
  }
  .gx-d-md-inline-block {
    display: inline-block !important;
  }
  .gx-d-md-block {
    display: block !important;
  }
  .gx-d-md-flex {
    display: flex !important;
  }
  .gx-d-md-inline-flex {
    display: inline-flex !important;
  }
  .gx-flex-md-row {
    flex-direction: row !important;
  }
  .gx-flex-md-column {
    flex-direction: column !important;
  }
  .gx-flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .gx-flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .gx-flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .gx-flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .gx-flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gx-justify-content-md-start {
    justify-content: flex-start !important;
  }
  .gx-justify-content-md-end {
    justify-content: flex-end !important;
  }
  .gx-justify-content-md-center {
    justify-content: center !important;
  }
  .gx-justify-content-md-between {
    justify-content: space-between !important;
  }
  .gx-justify-content-md-around {
    justify-content: space-around !important;
  }
  .gx-align-items-md-start {
    align-items: flex-start !important;
  }
  .gx-align-items-md-end {
    align-items: flex-end !important;
  }
  .gx-align-items-md-center {
    align-items: center !important;
  }
  .gx-align-items-md-baseline {
    align-items: baseline !important;
  }
  .gx-align-items-md-stretch {
    align-items: stretch !important;
  }
  .gx-align-content-md-start {
    align-content: flex-start !important;
  }
  .gx-align-content-md-end {
    align-content: flex-end !important;
  }
  .gx-align-content-md-center {
    align-content: center !important;
  }
  .gx-align-content-md-between {
    align-content: space-between !important;
  }
  .gx-align-content-md-around {
    align-content: space-around !important;
  }
  .gx-align-content-md-stretch {
    align-content: stretch !important;
  }
  .gx-align-self-md-auto {
    align-self: auto !important;
  }
  .gx-align-self-md-start {
    align-self: flex-start !important;
  }
  .gx-align-self-md-end {
    align-self: flex-end !important;
  }
  .gx-align-self-md-center {
    align-self: center !important;
  }
  .gx-align-self-md-baseline {
    align-self: baseline !important;
  }
  .gx-align-self-md-stretch {
    align-self: stretch !important;
  }
  .gx-order-md-first {
    order: -1;
  }
  .gx-order-md-1 {
    order: 1;
  }
  .gx-order-md-2 {
    order: 2;
  }
  .gx-order-md-3 {
    order: 3;
  }
  .gx-order-md-4 {
    order: 4;
  }
  .gx-order-md-5 {
    order: 5;
  }
  .gx-order-md-6 {
    order: 6;
  }
  .gx-order-md-7 {
    order: 7;
  }
  .gx-order-md-8 {
    order: 8;
  }
  .gx-order-md-9 {
    order: 9;
  }
  .gx-order-md-10 {
    order: 10;
  }
  .gx-order-md-11 {
    order: 11;
  }
  .gx-order-md-12 {
    order: 12;
  }
  .gx-offset-md-0 {
    margin-left: 0;
  }
  .gx-offset-md-1 {
    margin-left: 8.333333%;
  }
  .gx-offset-md-2 {
    margin-left: 16.666667%;
  }
  .gx-offset-md-3 {
    margin-left: 25%;
  }
  .gx-offset-md-4 {
    margin-left: 33.333333%;
  }
  .gx-offset-md-5 {
    margin-left: 41.666667%;
  }
  .gx-offset-md-6 {
    margin-left: 50%;
  }
  .gx-offset-md-7 {
    margin-left: 58.333333%;
  }
  .gx-offset-md-8 {
    margin-left: 66.666667%;
  }
  .gx-offset-md-9 {
    margin-left: 75%;
  }
  .gx-offset-md-10 {
    margin-left: 83.333333%;
  }
  .gx-offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media screen and (min-width: 992px) {
  .gx-d-lg-none {
    display: none !important;
  }
  .gx-d-lg-inline-block {
    display: inline-block !important;
  }
  .gx-d-lg-block {
    display: block !important;
  }
  .gx-d-lg-flex {
    display: flex !important;
  }
  .gx-d-lg-inline-flex {
    display: inline-flex !important;
  }
  .gx-flex-lg-row {
    flex-direction: row !important;
  }
  .gx-flex-lg-column {
    flex-direction: column !important;
  }
  .gx-flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .gx-flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .gx-flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .gx-flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .gx-flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gx-justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .gx-justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .gx-justify-content-lg-center {
    justify-content: center !important;
  }
  .gx-justify-content-lg-between {
    justify-content: space-between !important;
  }
  .gx-justify-content-lg-around {
    justify-content: space-around !important;
  }
  .gx-align-items-lg-start {
    align-items: flex-start !important;
  }
  .gx-align-items-lg-end {
    align-items: flex-end !important;
  }
  .gx-align-items-lg-center {
    align-items: center !important;
  }
  .gx-align-items-lg-baseline {
    align-items: baseline !important;
  }
  .gx-align-items-lg-stretch {
    align-items: stretch !important;
  }
  .gx-align-content-lg-start {
    align-content: flex-start !important;
  }
  .gx-align-content-lg-end {
    align-content: flex-end !important;
  }
  .gx-align-content-lg-center {
    align-content: center !important;
  }
  .gx-align-content-lg-between {
    align-content: space-between !important;
  }
  .gx-align-content-lg-around {
    align-content: space-around !important;
  }
  .gx-align-content-lg-stretch {
    align-content: stretch !important;
  }
  .gx-align-self-lg-auto {
    align-self: auto !important;
  }
  .gx-align-self-lg-start {
    align-self: flex-start !important;
  }
  .gx-align-self-lg-end {
    align-self: flex-end !important;
  }
  .gx-align-self-lg-center {
    align-self: center !important;
  }
  .gx-align-self-lg-baseline {
    align-self: baseline !important;
  }
  .gx-align-self-lg-stretch {
    align-self: stretch !important;
  }
  .gx-order-lg-first {
    order: -1;
  }
  .gx-order-lg-1 {
    order: 1;
  }
  .gx-order-lg-2 {
    order: 2;
  }
  .gx-order-lg-3 {
    order: 3;
  }
  .gx-order-lg-4 {
    order: 4;
  }
  .gx-order-lg-5 {
    order: 5;
  }
  .gx-order-lg-6 {
    order: 6;
  }
  .gx-order-lg-7 {
    order: 7;
  }
  .gx-order-lg-8 {
    order: 8;
  }
  .gx-order-lg-9 {
    order: 9;
  }
  .gx-order-lg-10 {
    order: 10;
  }
  .gx-order-lg-11 {
    order: 11;
  }
  .gx-order-lg-12 {
    order: 12;
  }
  .gx-offset-lg-0 {
    margin-left: 0;
  }
  .gx-offset-lg-1 {
    margin-left: 8.333333%;
  }
  .gx-offset-lg-2 {
    margin-left: 16.666667%;
  }
  .gx-offset-lg-3 {
    margin-left: 25%;
  }
  .gx-offset-lg-4 {
    margin-left: 33.333333%;
  }
  .gx-offset-lg-5 {
    margin-left: 41.666667%;
  }
  .gx-offset-lg-6 {
    margin-left: 50%;
  }
  .gx-offset-lg-7 {
    margin-left: 58.333333%;
  }
  .gx-offset-lg-8 {
    margin-left: 66.666667%;
  }
  .gx-offset-lg-9 {
    margin-left: 75%;
  }
  .gx-offset-lg-10 {
    margin-left: 83.333333%;
  }
  .gx-offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media screen and (min-width: 1200px) {
  .gx-d-xl-none {
    display: none !important;
  }
  .gx-d-xl-inline-block {
    display: inline-block !important;
  }
  .gx-d-xl-block {
    display: block !important;
  }
  .gx-d-xl-flex {
    display: flex !important;
  }
  .gx-d-xl-inline-flex {
    display: inline-flex !important;
  }
  .gx-flex-xl-row {
    flex-direction: row !important;
  }
  .gx-flex-xl-column {
    flex-direction: column !important;
  }
  .gx-flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .gx-flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .gx-flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .gx-flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .gx-flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gx-justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .gx-justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .gx-justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .gx-justify-content-xl-around {
    justify-content: space-around !important;
  }
  .gx-align-items-xl-start {
    align-items: flex-start !important;
  }
  .gx-align-items-xl-end {
    align-items: flex-end !important;
  }
  .gx-align-items-xl-center {
    align-items: center !important;
  }
  .gx-align-items-xl-baseline {
    align-items: baseline !important;
  }
  .gx-align-items-xl-stretch {
    align-items: stretch !important;
  }
  .gx-align-content-xl-start {
    align-content: flex-start !important;
  }
  .gx-align-content-xl-end {
    align-content: flex-end !important;
  }
  .gx-align-content-xl-center {
    align-content: center !important;
  }
  .gx-align-content-xl-between {
    align-content: space-between !important;
  }
  .gx-align-content-xl-around {
    align-content: space-around !important;
  }
  .gx-align-content-xl-stretch {
    align-content: stretch !important;
  }
  .gx-align-self-xl-auto {
    align-self: auto !important;
  }
  .gx-align-self-xl-start {
    align-self: flex-start !important;
  }
  .gx-align-self-xl-end {
    align-self: flex-end !important;
  }
  .gx-align-self-xl-center {
    align-self: center !important;
  }
  .gx-align-self-xl-baseline {
    align-self: baseline !important;
  }
  .gx-align-self-xl-stretch {
    align-self: stretch !important;
  }
  .gx-order-xl-first {
    order: -1;
  }
  .gx-order-xl-1 {
    order: 1;
  }
  .gx-order-xl-2 {
    order: 2;
  }
  .gx-order-xl-3 {
    order: 3;
  }
  .gx-order-xl-4 {
    order: 4;
  }
  .gx-order-xl-5 {
    order: 5;
  }
  .gx-order-xl-6 {
    order: 6;
  }
  .gx-order-xl-7 {
    order: 7;
  }
  .gx-order-xl-8 {
    order: 8;
  }
  .gx-order-xl-9 {
    order: 9;
  }
  .gx-order-xl-10 {
    order: 10;
  }
  .gx-order-xl-11 {
    order: 11;
  }
  .gx-order-xl-12 {
    order: 12;
  }
  .gx-offset-xl-0 {
    margin-left: 0;
  }
  .gx-offset-xl-1 {
    margin-left: 8.333333%;
  }
  .gx-offset-xl-2 {
    margin-left: 16.666667%;
  }
  .gx-offset-xl-3 {
    margin-left: 25%;
  }
  .gx-offset-xl-4 {
    margin-left: 33.333333%;
  }
  .gx-offset-xl-5 {
    margin-left: 41.666667%;
  }
  .gx-offset-xl-6 {
    margin-left: 50%;
  }
  .gx-offset-xl-7 {
    margin-left: 58.333333%;
  }
  .gx-offset-xl-8 {
    margin-left: 66.666667%;
  }
  .gx-offset-xl-9 {
    margin-left: 75%;
  }
  .gx-offset-xl-10 {
    margin-left: 83.333333%;
  }
  .gx-offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.ant-row {
  margin-left: -16px;
  margin-right: -16px;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.ant-row > [class*=ant-col-] {
  margin: 0 -0.3px;
}
.gx-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (max-width: 480px) {
  .gx-col-full {
    width: 100%;
  }
}
/*Margin Padding Styles*/
.gx-mb-30 {
  margin-bottom: 30px !important;
}
.gx-mb-10 {
  margin-bottom: 10px !important;
}
.gx-m-0 {
  margin: 0 !important;
}
.gx-mt-0,
.gx-my-0 {
  margin-top: 0 !important;
}
.gx-mr-0,
.gx-mx-0 {
  margin-right: 0 !important;
}
.gx-mb-0,
.gx-my-0 {
  margin-bottom: 0 !important;
}
.gx-ml-0,
.gx-mx-0 {
  margin-left: 0 !important;
}
.gx-m-1 {
  margin: 0.25rem !important;
}
.gx-mt-1,
.gx-my-1 {
  margin-top: 0.25rem !important;
}
.gx-mr-1,
.gx-mx-1 {
  margin-right: 0.25rem !important;
}
.gx-mb-1,
.gx-my-1 {
  margin-bottom: 0.25rem !important;
}
.gx-ml-1,
.gx-mx-1 {
  margin-left: 0.25rem !important;
}
.gx-m-2 {
  margin: 0.5rem !important;
}
.gx-mt-2,
.gx-my-2 {
  margin-top: 0.5rem !important;
}
.gx-mr-2,
.gx-mx-2 {
  margin-right: 0.5rem !important;
}
.gx-mb-2,
.gx-my-2 {
  margin-bottom: 0.5rem !important;
}
.gx-ml-2,
.gx-mx-2 {
  margin-left: 0.5rem !important;
}
.gx-m-3 {
  margin: 1rem !important;
}
.gx-mt-3,
.gx-my-3 {
  margin-top: 1rem !important;
}
.gx-mr-3,
.gx-mx-3 {
  margin-right: 1rem !important;
}
.gx-mb-3,
.gx-my-3 {
  margin-bottom: 1rem !important;
}
.gx-ml-3,
.gx-mx-3 {
  margin-left: 1rem !important;
}
.gx-m-4 {
  margin: 1.5rem !important;
}
.gx-mt-4,
.gx-my-4 {
  margin-top: 1.5rem !important;
}
.gx-mr-4,
.gx-mx-4 {
  margin-right: 1.5rem !important;
}
.gx-mb-4,
.gx-my-4 {
  margin-bottom: 1.5rem !important;
}
.gx-ml-4,
.gx-mx-4 {
  margin-left: 1.5rem !important;
}
.gx-m-5 {
  margin: 3rem !important;
}
.gx-mt-5,
.gx-my-5 {
  margin-top: 3rem !important;
}
.gx-mr-5,
.gx-mx-5 {
  margin-right: 3rem !important;
}
.gx-mb-5,
.gx-my-5 {
  margin-bottom: 3rem !important;
}
.gx-ml-5,
.gx-mx-5 {
  margin-left: 3rem !important;
}
.gx-p-0 {
  padding: 0 !important;
}
.gx-pt-0,
.gx-py-0 {
  padding-top: 0 !important;
}
.gx-pr-0,
.gx-px-0 {
  padding-right: 0 !important;
}
.gx-pb-0,
.gx-py-0 {
  padding-bottom: 0 !important;
}
.gx-pl-0,
.gx-px-0 {
  padding-left: 0 !important;
}
.gx-p-1 {
  padding: 0.25rem !important;
}
.gx-pt-1,
.gx-py-1 {
  padding-top: 0.25rem !important;
}
.gx-pr-1,
.gx-px-1 {
  padding-right: 0.25rem !important;
}
.gx-pb-1,
.gx-py-1 {
  padding-bottom: 0.25rem !important;
}
.gx-pl-1,
.gx-px-1 {
  padding-left: 0.25rem !important;
}
.gx-p-2 {
  padding: 0.5rem !important;
}
.gx-pt-2,
.gx-py-2 {
  padding-top: 0.5rem !important;
}
.gx-pr-2,
.gx-px-2 {
  padding-right: 0.5rem !important;
}
.gx-pb-2,
.gx-py-2 {
  padding-bottom: 0.5rem !important;
}
.gx-pl-2,
.gx-px-2 {
  padding-left: 0.5rem !important;
}
.gx-p-3 {
  padding: 1rem !important;
}
.gx-pt-3,
.gx-py-3 {
  padding-top: 1rem !important;
}
.gx-pr-3,
.gx-px-3 {
  padding-right: 1rem !important;
}
.gx-pb-3,
.gx-py-3 {
  padding-bottom: 1rem !important;
}
.gx-pl-3,
.gx-px-3 {
  padding-left: 1rem !important;
}
.gx-p-4 {
  padding: 1.5rem !important;
}
.gx-pt-4,
.gx-py-4 {
  padding-top: 1.5rem !important;
}
.gx-pr-4,
.gx-px-4 {
  padding-right: 1.5rem !important;
}
.gx-pb-4,
.gx-py-4 {
  padding-bottom: 1.5rem !important;
}
.gx-pl-4,
.gx-px-4 {
  padding-left: 1.5rem !important;
}
.gx-p-5 {
  padding: 2rem !important;
}
.gx-pt-5,
.gx-py-5 {
  padding-top: 2rem !important;
}
.gx-pr-5,
.gx-px-5 {
  padding-right: 2rem !important;
}
.gx-pb-5,
.gx-py-5 {
  padding-bottom: 2rem !important;
}
.gx-pl-5,
.gx-px-5 {
  padding-left: 2rem !important;
}
.gx-m-auto {
  margin: auto !important;
}
.gx-mt-auto,
.gx-my-auto {
  margin-top: auto !important;
}
.gx-mr-auto,
.gx-mx-auto {
  margin-right: auto !important;
}
.gx-mb-auto,
.gx-my-auto {
  margin-bottom: auto !important;
}
.gx-ml-auto,
.gx-mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .gx-m-sm-0 {
    margin: 0 !important;
  }
  .gx-mt-sm-0,
  .gx-my-sm-0 {
    margin-top: 0 !important;
  }
  .gx-mr-sm-0,
  .gx-mx-sm-0 {
    margin-right: 0 !important;
  }
  .gx-mb-sm-0,
  .gx-my-sm-0 {
    margin-bottom: 0 !important;
  }
  .gx-ml-sm-0,
  .gx-mx-sm-0 {
    margin-left: 0 !important;
  }
  .gx-m-sm-1 {
    margin: 0.25rem !important;
  }
  .gx-mt-sm-1,
  .gx-my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .gx-mr-sm-1,
  .gx-mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .gx-mb-sm-1,
  .gx-my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .gx-ml-sm-1,
  .gx-mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .gx-m-sm-2 {
    margin: 0.5rem !important;
  }
  .gx-mt-sm-2,
  .gx-my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .gx-mr-sm-2,
  .gx-mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .gx-mb-sm-2,
  .gx-my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .gx-ml-sm-2,
  .gx-mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .gx-m-sm-3 {
    margin: 1rem !important;
  }
  .gx-mt-sm-3,
  .gx-my-sm-3 {
    margin-top: 1rem !important;
  }
  .gx-mr-sm-3,
  .gx-mx-sm-3 {
    margin-right: 1rem !important;
  }
  .gx-mb-sm-3,
  .gx-my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .gx-ml-sm-3,
  .gx-mx-sm-3 {
    margin-left: 1rem !important;
  }
  .gx-m-sm-4 {
    margin: 1.5rem !important;
  }
  .gx-mt-sm-4,
  .gx-my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .gx-mr-sm-4,
  .gx-mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .gx-mb-sm-4,
  .gx-my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .gx-ml-sm-4,
  .gx-mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .gx-m-sm-5 {
    margin: 3rem !important;
  }
  .gx-mt-sm-5,
  .gx-my-sm-5 {
    margin-top: 3rem !important;
  }
  .gx-mr-sm-5,
  .gx-mx-sm-5 {
    margin-right: 3rem !important;
  }
  .gx-mb-sm-5,
  .gx-my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .gx-ml-sm-5,
  .gx-mx-sm-5 {
    margin-left: 3rem !important;
  }
  .gx-p-sm-0 {
    padding: 0 !important;
  }
  .gx-pt-sm-0,
  .gx-py-sm-0 {
    padding-top: 0 !important;
  }
  .gx-pr-sm-0,
  .gx-px-sm-0 {
    padding-right: 0 !important;
  }
  .gx-pb-sm-0,
  .gx-py-sm-0 {
    padding-bottom: 0 !important;
  }
  .gx-pl-sm-0,
  .gx-px-sm-0 {
    padding-left: 0 !important;
  }
  .gx-p-sm-1 {
    padding: 0.25rem !important;
  }
  .gx-pt-sm-1,
  .gx-py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .gx-pr-sm-1,
  .gx-px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .gx-pb-sm-1,
  .gx-py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .gx-pl-sm-1,
  .gx-px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .gx-p-sm-2 {
    padding: 0.5rem !important;
  }
  .gx-pt-sm-2,
  .gx-py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .gx-pr-sm-2,
  .gx-px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .gx-pb-sm-2,
  .gx-py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .gx-pl-sm-2,
  .gx-px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .gx-p-sm-3 {
    padding: 1rem !important;
  }
  .gx-pt-sm-3,
  .gx-py-sm-3 {
    padding-top: 1rem !important;
  }
  .gx-pr-sm-3,
  .gx-px-sm-3 {
    padding-right: 1rem !important;
  }
  .gx-pb-sm-3,
  .gx-py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .gx-pl-sm-3,
  .gx-px-sm-3 {
    padding-left: 1rem !important;
  }
  .gx-p-sm-4 {
    padding: 1.5rem !important;
  }
  .gx-pt-sm-4,
  .gx-py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .gx-pr-sm-4,
  .gx-px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .gx-pb-sm-4,
  .gx-py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .gx-pl-sm-4,
  .gx-px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .gx-p-sm-5 {
    padding: 2rem !important;
  }
  .gx-pt-sm-5,
  .gx-py-sm-5 {
    padding-top: 2rem !important;
  }
  .gx-pr-sm-5,
  .gx-px-sm-5 {
    padding-right: 2rem !important;
  }
  .gx-pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2rem !important;
  }
  .gx-pl-sm-5,
  .gx-px-sm-5 {
    padding-left: 2rem !important;
  }
  .gx-m-sm-auto {
    margin: auto !important;
  }
  .gx-mt-sm-auto,
  .gx-my-sm-auto {
    margin-top: auto !important;
  }
  .gx-mr-sm-auto,
  .gx-mx-sm-auto {
    margin-right: auto !important;
  }
  .gx-mb-sm-auto,
  .gx-my-sm-auto {
    margin-bottom: auto !important;
  }
  .gx-ml-sm-auto,
  .gx-mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .gx-m-md-0 {
    margin: 0 !important;
  }
  .gx-mt-md-0,
  .gx-my-md-0 {
    margin-top: 0 !important;
  }
  .gx-mr-md-0,
  .gx-mx-md-0 {
    margin-right: 0 !important;
  }
  .gx-mb-md-0,
  .gx-my-md-0 {
    margin-bottom: 0 !important;
  }
  .gx-ml-md-0,
  .gx-mx-md-0 {
    margin-left: 0 !important;
  }
  .gx-m-md-1 {
    margin: 0.25rem !important;
  }
  .gx-mt-md-1,
  .gx-my-md-1 {
    margin-top: 0.25rem !important;
  }
  .gx-mr-md-1,
  .gx-mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .gx-mb-md-1,
  .gx-my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .gx-ml-md-1,
  .gx-mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .gx-m-md-2 {
    margin: 0.5rem !important;
  }
  .gx-mt-md-2,
  .gx-my-md-2 {
    margin-top: 0.5rem !important;
  }
  .gx-mr-md-2,
  .gx-mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .gx-mb-md-2,
  .gx-my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .gx-ml-md-2,
  .gx-mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .gx-m-md-3 {
    margin: 1rem !important;
  }
  .gx-mt-md-3,
  .gx-my-md-3 {
    margin-top: 1rem !important;
  }
  .gx-mr-md-3,
  .gx-mx-md-3 {
    margin-right: 1rem !important;
  }
  .gx-mb-md-3,
  .gx-my-md-3 {
    margin-bottom: 1rem !important;
  }
  .gx-ml-md-3,
  .gx-mx-md-3 {
    margin-left: 1rem !important;
  }
  .gx-m-md-4 {
    margin: 1.5rem !important;
  }
  .gx-mt-md-4,
  .gx-my-md-4 {
    margin-top: 1.5rem !important;
  }
  .gx-mr-md-4,
  .gx-mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .gx-mb-md-4,
  .gx-my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .gx-ml-md-4,
  .gx-mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .gx-m-md-5 {
    margin: 3rem !important;
  }
  .gx-mt-md-5,
  .gx-my-md-5 {
    margin-top: 3rem !important;
  }
  .gx-mr-md-5,
  .gx-mx-md-5 {
    margin-right: 3rem !important;
  }
  .gx-mb-md-5,
  .gx-my-md-5 {
    margin-bottom: 3rem !important;
  }
  .gx-ml-md-5,
  .gx-mx-md-5 {
    margin-left: 3rem !important;
  }
  .gx-p-md-0 {
    padding: 0 !important;
  }
  .gx-pt-md-0,
  .gx-py-md-0 {
    padding-top: 0 !important;
  }
  .gx-pr-md-0,
  .gx-px-md-0 {
    padding-right: 0 !important;
  }
  .gx-pb-md-0,
  .gx-py-md-0 {
    padding-bottom: 0 !important;
  }
  .gx-pl-md-0,
  .gx-px-md-0 {
    padding-left: 0 !important;
  }
  .gx-p-md-1 {
    padding: 0.25rem !important;
  }
  .gx-pt-md-1,
  .gx-py-md-1 {
    padding-top: 0.25rem !important;
  }
  .gx-pr-md-1,
  .gx-px-md-1 {
    padding-right: 0.25rem !important;
  }
  .gx-pb-md-1,
  .gx-py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .gx-pl-md-1,
  .gx-px-md-1 {
    padding-left: 0.25rem !important;
  }
  .gx-p-md-2 {
    padding: 0.5rem !important;
  }
  .gx-pt-md-2,
  .gx-py-md-2 {
    padding-top: 0.5rem !important;
  }
  .gx-pr-md-2,
  .gx-px-md-2 {
    padding-right: 0.5rem !important;
  }
  .gx-pb-md-2,
  .gx-py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .gx-pl-md-2,
  .gx-px-md-2 {
    padding-left: 0.5rem !important;
  }
  .gx-p-md-3 {
    padding: 1rem !important;
  }
  .gx-pt-md-3,
  .gx-py-md-3 {
    padding-top: 1rem !important;
  }
  .gx-pr-md-3,
  .gx-px-md-3 {
    padding-right: 1rem !important;
  }
  .gx-pb-md-3,
  .gx-py-md-3 {
    padding-bottom: 1rem !important;
  }
  .gx-pl-md-3,
  .gx-px-md-3 {
    padding-left: 1rem !important;
  }
  .gx-p-md-4 {
    padding: 1.5rem !important;
  }
  .gx-pt-md-4,
  .gx-py-md-4 {
    padding-top: 1.5rem !important;
  }
  .gx-pr-md-4,
  .gx-px-md-4 {
    padding-right: 1.5rem !important;
  }
  .gx-pb-md-4,
  .gx-py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .gx-pl-md-4,
  .gx-px-md-4 {
    padding-left: 1.5rem !important;
  }
  .gx-p-md-5 {
    padding: 2rem !important;
  }
  .gx-pt-md-5,
  .gx-py-md-5 {
    padding-top: 2rem !important;
  }
  .gx-pr-md-5,
  .gx-px-md-5 {
    padding-right: 2rem !important;
  }
  .gx-pb-md-5,
  .gx-py-md-5 {
    padding-bottom: 2rem !important;
  }
  .gx-pl-md-5,
  .gx-px-md-5 {
    padding-left: 2rem !important;
  }
  .gx-m-md-auto {
    margin: auto !important;
  }
  .gx-mt-md-auto,
  .gx-my-md-auto {
    margin-top: auto !important;
  }
  .gx-mr-md-auto,
  .gx-mx-md-auto {
    margin-right: auto !important;
  }
  .gx-mb-md-auto,
  .gx-my-md-auto {
    margin-bottom: auto !important;
  }
  .gx-ml-md-auto,
  .gx-mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .gx-m-lg-0 {
    margin: 0 !important;
  }
  .gx-mt-lg-0,
  .gx-my-lg-0 {
    margin-top: 0 !important;
  }
  .gx-mr-lg-0,
  .gx-mx-lg-0 {
    margin-right: 0 !important;
  }
  .gx-mb-lg-0,
  .gx-my-lg-0 {
    margin-bottom: 0 !important;
  }
  .gx-ml-lg-0,
  .gx-mx-lg-0 {
    margin-left: 0 !important;
  }
  .gx-m-lg-1 {
    margin: 0.25rem !important;
  }
  .gx-mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .gx-mr-lg-1,
  .gx-mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .gx-mb-lg-1,
  .gx-my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .gx-ml-lg-1,
  .gx-mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .gx-m-lg-2 {
    margin: 0.5rem !important;
  }
  .gx-mt-lg-2,
  .gx-my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .gx-mr-lg-2,
  .gx-mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .gx-mb-lg-2,
  .gx-my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .gx-ml-lg-2,
  .gx-mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .gx-m-lg-3 {
    margin: 1rem !important;
  }
  .gx-mt-lg-3,
  .gx-my-lg-3 {
    margin-top: 1rem !important;
  }
  .gx-mr-lg-3,
  .gx-mx-lg-3 {
    margin-right: 1rem !important;
  }
  .gx-mb-lg-3,
  .gx-my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .gx-ml-lg-3,
  .gx-mx-lg-3 {
    margin-left: 1rem !important;
  }
  .gx-m-lg-4 {
    margin: 1.5rem !important;
  }
  .gx-mt-lg-4,
  .gx-my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .gx-mr-lg-4,
  .gx-mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .gx-mb-lg-4,
  .gx-my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .gx-ml-lg-4,
  .gx-mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .gx-m-lg-5 {
    margin: 3rem !important;
  }
  .gx-mt-lg-5,
  .gx-my-lg-5 {
    margin-top: 3rem !important;
  }
  .gx-mr-lg-5,
  .gx-mx-lg-5 {
    margin-right: 3rem !important;
  }
  .gx-mb-lg-5,
  .gx-my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .gx-ml-lg-5,
  .gx-mx-lg-5 {
    margin-left: 3rem !important;
  }
  .gx-p-lg-0 {
    padding: 0 !important;
  }
  .gx-pt-lg-0,
  .gx-py-lg-0 {
    padding-top: 0 !important;
  }
  .gx-pr-lg-0,
  .gx-px-lg-0 {
    padding-right: 0 !important;
  }
  .gx-pb-lg-0,
  .gx-py-lg-0 {
    padding-bottom: 0 !important;
  }
  .gx-pl-lg-0,
  .gx-px-lg-0 {
    padding-left: 0 !important;
  }
  .gx-p-lg-1 {
    padding: 0.25rem !important;
  }
  .gx-pt-lg-1,
  .gx-py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .gx-pr-lg-1,
  .gx-px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .gx-pb-lg-1,
  .gx-py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .gx-pl-lg-1,
  .gx-px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .gx-p-lg-2 {
    padding: 0.5rem !important;
  }
  .gx-pt-lg-2,
  .gx-py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .gx-pr-lg-2,
  .gx-px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .gx-pb-lg-2,
  .gx-py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .gx-pl-lg-2,
  .gx-px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .gx-p-lg-3 {
    padding: 1rem !important;
  }
  .gx-pt-lg-3,
  .gx-py-lg-3 {
    padding-top: 1rem !important;
  }
  .gx-pr-lg-3,
  .gx-px-lg-3 {
    padding-right: 1rem !important;
  }
  .gx-pb-lg-3,
  .gx-py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .gx-pl-lg-3,
  .gx-px-lg-3 {
    padding-left: 1rem !important;
  }
  .gx-p-lg-4 {
    padding: 1.5rem !important;
  }
  .gx-pt-lg-4,
  .gx-py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .gx-pr-lg-4,
  .gx-px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .gx-pb-lg-4,
  .gx-py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .gx-pl-lg-4,
  .gx-px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .gx-p-lg-5 {
    padding: 2rem !important;
  }
  .gx-pt-lg-5,
  .gx-py-lg-5 {
    padding-top: 2rem !important;
  }
  .gx-pr-lg-5,
  .gx-px-lg-5 {
    padding-right: 2rem !important;
  }
  .gx-pb-lg-5,
  .gx-py-lg-5 {
    padding-bottom: 2rem !important;
  }
  .gx-pl-lg-5,
  .gx-px-lg-5 {
    padding-left: 2rem !important;
  }
  .gx-m-lg-auto {
    margin: auto !important;
  }
  .gx-mt-lg-auto,
  .gx-my-lg-auto {
    margin-top: auto !important;
  }
  .gx-mr-lg-auto,
  .gx-mx-lg-auto {
    margin-right: auto !important;
  }
  .gx-mb-lg-auto,
  .gx-my-lg-auto {
    margin-bottom: auto !important;
  }
  .gx-ml-lg-auto,
  .gx-mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .gx-m-xl-0 {
    margin: 0 !important;
  }
  .gx-mt-xl-0,
  .gx-my-xl-0 {
    margin-top: 0 !important;
  }
  .gx-mr-xl-0,
  .gx-mx-xl-0 {
    margin-right: 0 !important;
  }
  .gx-mb-xl-0,
  .gx-my-xl-0 {
    margin-bottom: 0 !important;
  }
  .gx-ml-xl-0,
  .gx-mx-xl-0 {
    margin-left: 0 !important;
  }
  .gx-m-xl-1 {
    margin: 0.25rem !important;
  }
  .gx-mt-xl-1,
  .gx-my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .gx-mr-xl-1,
  .gx-mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .gx-mb-xl-1,
  .gx-my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .gx-ml-xl-1,
  .gx-mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .gx-m-xl-2 {
    margin: 0.5rem !important;
  }
  .gx-mt-xl-2,
  .gx-my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .gx-mr-xl-2,
  .gx-mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .gx-mb-xl-2,
  .gx-my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .gx-ml-xl-2,
  .gx-mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .gx-m-xl-3 {
    margin: 1rem !important;
  }
  .gx-mt-xl-3,
  .gx-my-xl-3 {
    margin-top: 1rem !important;
  }
  .gx-mr-xl-3,
  .gx-mx-xl-3 {
    margin-right: 1rem !important;
  }
  .gx-mb-xl-3,
  .gx-my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .gx-ml-xl-3,
  .gx-mx-xl-3 {
    margin-left: 1rem !important;
  }
  .gx-m-xl-4 {
    margin: 1.5rem !important;
  }
  .gx-mt-xl-4,
  .gx-my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .gx-mr-xl-4,
  .gx-mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .gx-mb-xl-4,
  .gx-my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .gx-ml-xl-4,
  .gx-mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .gx-m-xl-5 {
    margin: 3rem !important;
  }
  .gx-mt-xl-5,
  .gx-my-xl-5 {
    margin-top: 3rem !important;
  }
  .gx-mr-xl-5,
  .gx-mx-xl-5 {
    margin-right: 3rem !important;
  }
  .gx-mb-xl-5,
  .gx-my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .gx-ml-xl-5,
  .gx-mx-xl-5 {
    margin-left: 3rem !important;
  }
  .gx-p-xl-0 {
    padding: 0 !important;
  }
  .gx-pt-xl-0,
  .gx-py-xl-0 {
    padding-top: 0 !important;
  }
  .gx-pr-xl-0,
  .gx-px-xl-0 {
    padding-right: 0 !important;
  }
  .gx-pb-xl-0,
  .gx-py-xl-0 {
    padding-bottom: 0 !important;
  }
  .gx-pl-xl-0,
  .gx-px-xl-0 {
    padding-left: 0 !important;
  }
  .gx-p-xl-1 {
    padding: 0.25rem !important;
  }
  .gx-pt-xl-1,
  .gx-py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .gx-pr-xl-1,
  .gx-px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .gx-pb-xl-1,
  .gx-py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .gx-pl-xl-1,
  .gx-px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .gx-p-xl-2 {
    padding: 0.5rem !important;
  }
  .gx-pt-xl-2,
  .gx-py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .gx-pr-xl-2,
  .gx-px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .gx-pb-xl-2,
  .gx-py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .gx-pl-xl-2,
  .gx-px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .gx-p-xl-3 {
    padding: 1rem !important;
  }
  .gx-pt-xl-3,
  .gx-py-xl-3 {
    padding-top: 1rem !important;
  }
  .gx-pr-xl-3,
  .gx-px-xl-3 {
    padding-right: 1rem !important;
  }
  .gx-pb-xl-3,
  .gx-py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .gx-pl-xl-3,
  .gx-px-xl-3 {
    padding-left: 1rem !important;
  }
  .gx-p-xl-4 {
    padding: 1.5rem !important;
  }
  .gx-pt-xl-4,
  .gx-py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .gx-pr-xl-4,
  .gx-px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .gx-pb-xl-4,
  .gx-py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .gx-pl-xl-4,
  .gx-px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .gx-p-xl-5 {
    padding: 2rem !important;
  }
  .gx-pt-xl-5,
  .gx-py-xl-5 {
    padding-top: 2rem !important;
  }
  .gx-pr-xl-5,
  .gx-px-xl-5 {
    padding-right: 2rem !important;
  }
  .gx-pb-xl-5,
  .gx-py-xl-5 {
    padding-bottom: 2rem !important;
  }
  .gx-pl-xl-5,
  .gx-px-xl-5 {
    padding-left: 2rem !important;
  }
  .gx-m-xl-auto {
    margin: auto !important;
  }
  .gx-mt-xl-auto,
  .gx-my-xl-auto {
    margin-top: auto !important;
  }
  .gx-mr-xl-auto,
  .gx-mx-xl-auto {
    margin-right: auto !important;
  }
  .gx-mb-xl-auto,
  .gx-my-xl-auto {
    margin-bottom: auto !important;
  }
  .gx-ml-xl-auto,
  .gx-mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1368px) {
  .gx-m-xxl-0 {
    margin: 0 !important;
  }
  .gx-mt-xxl-0,
  .gx-my-xxl-0 {
    margin-top: 0 !important;
  }
  .gx-mr-xxl-0,
  .gx-mx-xxl-0 {
    margin-right: 0 !important;
  }
  .gx-mb-xxl-0,
  .gx-my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .gx-ml-xxl-0,
  .gx-mx-xxl-0 {
    margin-left: 0 !important;
  }
  .gx-m-xxl-1 {
    margin: 0.25rem !important;
  }
  .gx-mt-xxl-1,
  .gx-my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .gx-mr-xxl-1,
  .gx-mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .gx-mb-xxl-1,
  .gx-my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .gx-ml-xxl-1,
  .gx-mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .gx-m-xxl-2 {
    margin: 0.5rem !important;
  }
  .gx-mt-xxl-2,
  .gx-my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .gx-mr-xxl-2,
  .gx-mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .gx-mb-xxl-2,
  .gx-my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .gx-ml-xxl-2,
  .gx-mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .gx-m-xxl-3 {
    margin: 1rem !important;
  }
  .gx-mt-xxl-3,
  .gx-my-xxl-3 {
    margin-top: 1rem !important;
  }
  .gx-mr-xxl-3,
  .gx-mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .gx-mb-xxl-3,
  .gx-my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .gx-ml-xxl-3,
  .gx-mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .gx-m-xxl-4 {
    margin: 1.5rem !important;
  }
  .gx-mt-xxl-4,
  .gx-my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .gx-mr-xxl-4,
  .gx-mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .gx-mb-xxl-4,
  .gx-my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .gx-ml-xxl-4,
  .gx-mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .gx-m-xxl-5 {
    margin: 3rem !important;
  }
  .gx-mt-xxl-5,
  .gx-my-xxl-5 {
    margin-top: 3rem !important;
  }
  .gx-mr-xxl-5,
  .gx-mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .gx-mb-xxl-5,
  .gx-my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .gx-ml-xxl-5,
  .gx-mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .gx-p-xxl-0 {
    padding: 0 !important;
  }
  .gx-pt-xxl-0,
  .gx-py-xxl-0 {
    padding-top: 0 !important;
  }
  .gx-pr-xxl-0,
  .gx-px-xxl-0 {
    padding-right: 0 !important;
  }
  .gx-pb-xxl-0,
  .gx-py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .gx-pl-xxl-0,
  .gx-px-xxl-0 {
    padding-left: 0 !important;
  }
  .gx-p-xxl-1 {
    padding: 0.25rem !important;
  }
  .gx-pt-xxl-1,
  .gx-py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .gx-pr-xxl-1,
  .gx-px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .gx-pb-xxl-1,
  .gx-py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .gx-pl-xxl-1,
  .gx-px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .gx-p-xxl-2 {
    padding: 0.5rem !important;
  }
  .gx-pt-xxl-2,
  .gx-py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .gx-pr-xxl-2,
  .gx-px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .gx-pb-xxl-2,
  .gx-py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .gx-pl-xxl-2,
  .gx-px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .gx-p-xxl-3 {
    padding: 1rem !important;
  }
  .gx-pt-xxl-3,
  .gx-py-xxl-3 {
    padding-top: 1rem !important;
  }
  .gx-pr-xxl-3,
  .gx-px-xxl-3 {
    padding-right: 1rem !important;
  }
  .gx-pb-xxl-3,
  .gx-py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .gx-pl-xxl-3,
  .gx-px-xxl-3 {
    padding-left: 1rem !important;
  }
  .gx-p-xxl-4 {
    padding: 1.5rem !important;
  }
  .gx-pt-xxl-4,
  .gx-py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .gx-pr-xxl-4,
  .gx-px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .gx-pb-xxl-4,
  .gx-py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .gx-pl-xxl-4,
  .gx-px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .gx-p-xxl-5 {
    padding: 2rem !important;
  }
  .gx-pt-xxl-5,
  .gx-py-xxl-5 {
    padding-top: 2rem !important;
  }
  .gx-pr-xxl-5,
  .gx-px-xxl-5 {
    padding-right: 2rem !important;
  }
  .gx-pb-xxl-5,
  .gx-py-xxl-5 {
    padding-bottom: 2rem !important;
  }
  .gx-pl-xxl-5,
  .gx-px-xxl-5 {
    padding-left: 2rem !important;
  }
  .gx-m-xxl-auto {
    margin: auto !important;
  }
  .gx-mt-xxl-auto,
  .gx-my-xxl-auto {
    margin-top: auto !important;
  }
  .gx-mr-xxl-auto,
  .gx-mx-xxl-auto {
    margin-right: auto !important;
  }
  .gx-mb-xxl-auto,
  .gx-my-xxl-auto {
    margin-bottom: auto !important;
  }
  .gx-ml-xxl-auto,
  .gx-mx-xxl-auto {
    margin-left: auto !important;
  }
}
/*Typography Styles*/
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #262626;
  font-weight: 400;
  line-height: 1.2;
}
h1,
.h1 {
  font-size: 22px;
}
.framed-layout h1,
.framed-layout .h1 {
  font-size: 20px;
}
@media screen and (max-width: 767px) {
  .framed-layout h1,
  .framed-layout .h1 {
    font-size: 18px;
  }
}
.boxed-layout h1,
.boxed-layout .h1 {
  font-size: 18px;
}
@media screen and (max-width: 767px) {
  .boxed-layout h1,
  .boxed-layout .h1 {
    font-size: 16px;
  }
}
@media screen and (min-width: 1600px) {
  h1,
  .h1 {
    font-size: 28px;
  }
  .boxed-layout h1,
  .boxed-layout .h1 {
    font-size: 18px;
  }
}
h2,
.h2 {
  font-size: 20px;
}
h3,
.h3 {
  font-size: 18px;
}
h4,
.h4 {
  font-size: 16px;
}
h5,
.h5 {
  font-size: 14px;
}
h6,
.h6 {
  font-size: 12px;
}
.gx-h1-lg {
  font-size: 28px;
}
.gx-text-strikethrough {
  text-decoration: line-through !important;
}
.gx-text-underline {
  text-decoration: underline !important;
}
/*Font size Classes*/
/*8px*/
.gx-fs-xxs {
  font-size: 8px;
}
/*10px*/
.gx-fs-xs {
  font-size: 10px;
}
/*11px*/
.gx-fs-11 {
  font-size: 11px;
}
/*12px*/
.gx-fs-sm {
  font-size: 12px;
  line-height: 1.5;
}
/*14px*/
.gx-fs-md {
  font-size: 14px;
  line-height: 1;
}
/*16px*/
.gx-fs-lg {
  font-size: 16px;
  line-height: 1;
}
/*20px*/
.gx-fs-xl {
  font-size: 20px;
  line-height: 1;
}
/*24px*/
.gx-fs-xxl {
  font-size: 24px;
  line-height: 1.3;
}
@media screen and (max-width: 1199px) {
  .gx-fs-xxl {
    font-size: 20px;
  }
}
/*28px*/
.gx-fs-2xl {
  font-size: 28px !important;
  line-height: 1;
}
@media screen and (max-width: 1199px) {
  .gx-fs-2xl {
    font-size: 20px !important;
  }
}
/*30px*/
.gx-fs-xxxl {
  font-size: 30px;
  line-height: 1;
}
@media screen and (max-width: 1366px) {
  .gx-fs-xxxl {
    font-size: 28px;
  }
}
@media screen and (max-width: 1199px) {
  .gx-fs-xxxl {
    font-size: 26px;
  }
}
@media screen and (max-width: 991px) {
  .gx-fs-xxxl {
    font-size: 24px;
  }
}
/*40px*/
.gx-fs-xlxl {
  font-size: 40px;
  line-height: 1;
}
@media screen and (max-width: 1199px) {
  .gx-fs-xlxl {
    font-size: 32px;
  }
}
@media screen and (max-width: 991px) {
  .gx-fs-xlxl {
    font-size: 28px;
  }
}
/*50px*/
.gx-fs-icon-lg {
  font-size: 50px;
  line-height: 1;
}
@media screen and (max-width: 1366px) {
  .gx-fs-icon-lg {
    font-size: 38px;
  }
}
@media screen and (max-width: 575px) {
  .gx-fs-icon-lg {
    font-size: 30px;
  }
}
/*54px*/
.gx-fs-iconcard {
  font-size: 54px;
  line-height: 1;
}
@media screen and (max-width: 1366px) {
  .gx-fs-iconcard {
    font-size: 34px;
  }
}
.gx-text-lowercase {
  text-transform: lowercase;
}
.gx-text-uppercase {
  text-transform: uppercase;
}
.gx-text-capitalize {
  text-transform: capitalize;
}
.gx-font-weight-light {
  font-weight: 300;
}
.gx-font-weight-normal {
  font-weight: 400;
}
.gx-font-weight-medium {
  font-weight: 500;
}
.gx-font-weight-semi-bold {
  font-weight: 600;
}
.gx-font-weight-bold {
  font-weight: 700;
}
.gx-font-weight-heavy {
  font-weight: 900;
}
.gx-font-italic {
  font-style: italic;
}
.gx-letter-spacing-base {
  letter-spacing: 3px;
}
.gx-letter-spacing-lg {
  letter-spacing: 6px;
}
.gx-letter-spacing-xl {
  letter-spacing: 8px;
}
.gx-text-justify {
  text-align: justify !important;
}
.gx-text-nowrap {
  white-space: nowrap !important;
}
.gx-text-left {
  text-align: left !important;
}
.gx-text-right {
  text-align: right !important;
}
.gx-text-center {
  text-align: center !important;
}
.gx-text-truncate {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* Ant Layout Style */
.ant-layout {
  position: relative;
  background: none;
}
.ant-layout > .gx-layout-content {
  overflow-x: hidden;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.full-scroll .ant-layout > .gx-layout-content {
  overflow-x: inherit;
}
.ant-layout.ant-layout-has-sider > .ant-layout {
  position: relative;
  background: #f5f5f5;
}
.framed-layout .ant-layout,
.boxed-layout .ant-layout {
  background: #f5f5f5;
}
.framed-layout .ant-layout,
.boxed-layout .ant-layout {
  background: #f5f5f5;
}
.ant-layout.gx-app-layout {
  height: 100vh;
}
.framed-layout .ant-layout.gx-app-layout {
  height: calc(100vh - 40px);
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout.gx-app-layout {
    height: 100vh;
  }
}
.framed-layout.full-scroll .ant-layout.gx-app-layout,
.boxed-layout.full-scroll .ant-layout.gx-app-layout {
  overflow-y: scroll;
}
.ant-layout.gx-app-layout > .ant-layout {
  height: 100vh;
}
.framed-layout .ant-layout.gx-app-layout > .ant-layout {
  height: calc(100vh - 40px);
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout.gx-app-layout > .ant-layout {
    height: 100vh;
  }
}
.full-scroll .ant-layout.ant-layout-has-sider > .ant-layout,
.full-scroll .ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: inherit;
}
.gx-main-content-wrapper {
  padding: 32px 32px 0;
  flex: 1 1;
}
@media screen and (max-width: 575px) {
  .gx-main-content-wrapper {
    padding: 20px 15px 0;
  }
}
.gx-container-wrap .gx-main-content-wrapper {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 920px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1100px;
  }
}
@media screen and (min-width: 1367px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 1400px;
  }
}
.gx-main-content {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1;
  height: 100%;
}
.gx-container {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .gx-container {
    width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .gx-container {
    width: 920px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container {
    width: 1100px;
  }
}
@media screen and (min-width: 1367px) {
  .gx-container {
    width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container {
    width: 1400px;
  }
}
/* Header Style */
.ant-layout-header {
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  -ms-justify-content: space-between;
  justify-content: space-between;
  line-height: 1;
  padding: 0 32px;
  height: 72px;
  position: relative;
  z-index: 10;
  color: #262626;
}
@media screen and (max-width: 991px) {
  .ant-layout-header {
    padding: 0 15px;
  }
}
@media screen and (max-width: 575px) {
  .ant-layout-header {
    height: 50px;
  }
}
.gx-nav-header {
  padding: 0 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  background-color: #fefefe;
}
.gx-nav-header .ant-menu-horizontal {
  margin-bottom: -1px;
  border-bottom-color: transparent;
}
.gx-nav-header-below {
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.28);
  position: relative;
  z-index: 999;
}
.ant-dropdown-menu-item {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
}
.gx-linebar {
  font-size: 20px;
}
.gx-header-horizontal-dark .gx-linebar {
  color: #ffffff;
}
.gx-header-notifications {
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
}
.gx-header-notifications > li {
  font-size: 18px;
}
.gx-header-notifications > li:not(:last-child) {
  margin-right: 42px;
}
.gx-inside-header-horizontal .gx-header-notifications > li:not(:last-child) {
  margin-right: 20px;
}
.gx-header-horizontal-main .gx-header-notifications > li:not(:last-child),
.gx-header-horizontal-nav .gx-header-notifications > li:not(:last-child) {
  margin-right: 30px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-header-notifications > li:not(:last-child),
  .gx-header-horizontal-nav .gx-header-notifications > li:not(:last-child) {
    margin-right: 16px;
  }
}
@media screen and (max-width: 991px) {
  .gx-header-notifications > li:not(:last-child) {
    margin-right: 16px;
  }
}
.gx-header-notifications > li.gx-notify {
  margin-right: 22px;
}
.gx-header-horizontal-main .gx-header-notifications > li.gx-notify {
  margin-right: 30px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-header-notifications > li.gx-notify {
    margin-right: 16px;
  }
}
.gx-inside-header-horizontal .gx-header-notifications > li.gx-notify {
  margin-right: 20px;
}
@media screen and (max-width: 991px) {
  .gx-header-notifications > li.gx-notify {
    margin-right: 16px;
  }
}
.gx-header-notifications > li.gx-language {
  font-size: 14px;
}
@media screen and (max-width: 575px) {
  .gx-header-notifications > li.gx-language .gx-language-name,
  .gx-header-notifications > li.gx-language .icon {
    display: none;
  }
  .gx-header-notifications > li.gx-language .flag.flag-24 {
    width: 20px;
    height: 20px;
    margin-top: -4px;
  }
}
@media screen and (max-width: 575px) {
  .gx-header-notifications > li.gx-notify,
  .gx-header-notifications > li.gx-msg {
    margin-top: 2px;
  }
  .gx-header-notifications > li.gx-language {
    margin-bottom: 2px;
  }
}
.gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
  margin-left: 50px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
    margin-left: 0;
  }
}
@media screen and (max-width: 1199px) {
  .gx-inside-header-horizontal .gx-header-horizontal-main .gx-header-notifications > li.gx-user-nav {
    margin-left: 0;
  }
}
.gx-header-horizontal-main .gx-header-notifications li > span {
  color: #545454;
}
.gx-header-horizontal-dark .gx-header-horizontal-main .gx-header-notifications li > span {
  color: #fa8c15;
}
.gx-popover-scroll {
  height: 280px !important;
  width: 300px !important;
  margin: 0 -16px;
}
@media screen and (max-width: 575px) {
  .gx-popover-scroll {
    height: 280px !important;
    width: 200px !important;
  }
}
.gx-popover-lang-scroll {
  height: 220px !important;
  width: 160px !important;
  margin: 0 -16px;
}
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 10px;
}
.gx-popover-header {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  -ms-justify-content: space-between;
  justify-content: space-between;
  border-bottom: solid 1px #e8e8e8;
  margin: 0 -16px;
  padding: 0 16px 12px;
}
.gx-sub-popover {
  list-style: none;
  margin: 0 16px;
  padding-left: 0;
}
.gx-sub-popover li {
  padding: 20px 0 14px;
}
.gx-popover-lang-scroll .gx-sub-popover li {
  padding: 5px 0;
}
.gx-sub-popover li:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
}
.gx-user-popover {
  margin: -12px -16px;
  padding: 7px 0;
  list-style: none;
}
.gx-user-popover li {
  cursor: pointer;
  padding: 3px 15px;
  width: 150px;
}
.gx-user-popover li:hover,
.gx-user-popover li:focus {
  background-color: #fafafa;
}
@media screen and (max-width: 575px) {
  .gx-user-popover li {
    width: 100%;
  }
}
.gx-header-horizontal {
  position: relative;
  color: #fa8c15;
  background-color: #ffffff;
}
.gx-header-horizontal-dark {
  position: relative;
  background-color: #fffffe;
  color: #fa8c15;
}
.gx-header-horizontal-dark:before {
  content: '';
  position: absolute;
  right: 169px;
  top: -212px;
  background-color: rgba(255, 255, 255, 0.2);
  width: 280px;
  height: 350px;
  border-radius: 0 0 135px 135px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 1;
}
@media screen and (max-width: 1049px) {
  .gx-header-horizontal-dark:before {
    right: 110px;
  }
}
@media screen and (max-width: 1199px) {
  .gx-header-horizontal-dark:before {
    top: -184px;
    right: 80px;
    height: 300px;
  }
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-dark:before {
    top: -154px;
    right: 140px;
    height: 240px;
    width: 200px;
  }
}
@media screen and (max-width: 575px) {
  .gx-header-horizontal-dark:before {
    right: 60px;
    display: none;
  }
}
.gx-header-horizontal-dark > div {
  position: relative;
  z-index: 2;
}
.gx-header-horizontal-top {
  padding: 12px 0;
  font-size: 14px;
}
.gx-header-horizontal-top-flex,
.gx-header-horizontal-nav-flex,
.gx-header-horizontal-main-flex {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
}
.gx-header-horizontal-top-left {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-right: 15px;
}
@media screen and (max-width: 767px) {
  .gx-header-horizontal-top-left {
    margin-right: 0;
    width: 100%;
  }
}
.gx-header-horizontal-main {
  display: -webkit-block;
  display: -ms-blockbox;
  display: -ms-block;
  display: block;
  flex-direction: row;
  flex-wrap: nowrap;
  box-shadow: none;
  padding: 25px 0 35px;
  height: auto;
  background-color: transparent;
}
@media screen and (max-width: 1199px) {
  .gx-header-horizontal-main {
    padding: 20px 0;
  }
  .gx-header-horizontal-main .gx-w-logo {
    padding: 7px 5px;
  }
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main {
    padding: 0;
  }
}
@media screen and (max-width: 575px) {
  .gx-header-horizontal-main .gx-w-logo {
    padding-right: 0;
  }
}
.gx-inside-header-horizontal .gx-header-horizontal-main {
  padding: 0;
}
.gx-header-horizontal-nav {
  background-color: #fffffe;
  color: #fffffd;
}
.gx-header-horizontal-dark .gx-header-horizontal-nav {
  background-color: transparent;
}
.gx-below-header-horizontal .gx-header-horizontal-nav,
.gx-above-header-horizontal .gx-header-horizontal-nav {
  background-color: #fefefe;
  color: #7a7a7a;
}
.gx-inside-header-horizontal .gx-logo {
  margin-right: 25px;
}
.gx-inside-header-horizontal:before {
  top: -230px;
}
@media screen and (max-width: 1199px) {
  .gx-inside-header-horizontal:before {
    top: -190px;
  }
}
@media screen and (max-width: 991px) {
  .gx-inside-header-horizontal:before {
    top: -154px;
  }
}
.gx-above-header-horizontal:before {
  top: -165px;
}
@media screen and (max-width: 991px) {
  .gx-above-header-horizontal:before {
    top: -154px;
  }
}
.gx-login-list {
  list-style: none;
  margin-left: auto;
  margin-bottom: 0;
}
.gx-login-list li {
  padding: 0 10px;
  text-transform: uppercase;
  color: #fa8c15;
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
  cursor: pointer;
}
.gx-login-list li:not(:first-child) {
  border-left: solid 1px #e8e8e8;
}
.gx-header-horizontal-dark .gx-login-list li:not(:first-child) {
  border: 0 none;
}
@media screen and (max-width: 767px) {
  .gx-login-list {
    display: none;
  }
}
.gx-header-search {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: 28px;
}
.gx-header-search .ant-select-selection {
  border-radius: 0 4px 4px 0;
  background-color: #fffffe;
  border-color: #fffffe;
  color: #fffffd;
  margin-bottom: 0;
}
.gx-header-search .ant-select-selection:focus {
  background-color: #fffffe;
  border-color: #fffffe;
  color: #fffffd;
}
.gx-header-horizontal-dark .gx-header-search .ant-select-selection {
  color: #fffffd;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
  margin-left: 1px;
}
.gx-header-horizontal-dark .gx-header-search .ant-select-selection:focus {
  color: #fffffd;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}
.gx-header-search .ant-select-arrow {
  color: #fffffd;
}
.gx-popover-horizantal .ant-btn {
  margin: 0 !important;
  border: 0 none;
  border-right: solid 1px #e8e8e8;
  border-radius: 0;
  height: 24px;
  line-height: 24px;
}
.gx-popover-horizantal .gx-popover-search-bar {
  margin: 0;
}
.gx-popover-horizantal .gx-popover-search-bar .ant-input {
  height: 24px;
  line-height: 24px;
}
@media screen and (max-width: 575px) {
  .gx-popover-horizantal {
    left: 0 !important;
    padding-top: 0;
    margin-top: -9px;
  }
  .gx-popover-horizantal .ant-popover-inner-content {
    width: 100vw;
  }
  .gx-popover-horizantal .ant-popover-arrow {
    display: none;
  }
  .gx-popover-horizantal .gx-popover-search-bar {
    width: 100%;
  }
  .gx-popover-horizantal .gx-popover-scroll,
  .gx-popover-horizantal .gx-popover-lang-scroll {
    width: 100% !important;
    margin: 0;
  }
  .gx-popover-horizantal .gx-sub-popover {
    margin: 0;
    padding-left: 5px;
  }
}
.gx-no-header-horizontal {
  padding: 14px 32px 13px 79px;
  text-align: center;
  position: relative;
}
.gx-no-header-horizontal-top {
  padding: 10px 20px;
  background-color: #d9f7be;
  color: #237804;
  border-radius: 4px;
  display: inline-block;
}
@media screen and (max-width: 575px) {
  .gx-no-header-horizontal-top {
    width: 100%;
    font-size: 12px;
  }
}
.gx-no-header-horizontal-top-center {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.gx-no-header-horizontal .gx-linebar {
  position: absolute;
  left: 32px;
  top: 15px;
  z-index: 1;
}
@media screen and (max-width: 575px) {
  .gx-no-header-horizontal .gx-linebar {
    left: 15px;
  }
}
@media screen and (max-width: 575px) {
  .gx-no-header-horizontal {
    padding: 14px 15px 13px 60px;
  }
}
/* Sidebar Style */
.gx-app-sidebar {
  transition: all 0.2s ease;
}
.ant-layout-sider {
  background-color: #fffffc;
  color: #545454;
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 10px !important;
  width: 280px !important;
  overflow: hidden;
}
@media screen and (max-width: 1366px) {
  .ant-layout-sider {
    width: 280px !important;
  }
}
.ant-layout-sider-collapsed {
  width: 80px !important;
}
@media screen and (max-width: 991px) {
  .ant-layout-sider-collapsed.gx-mini-custom-sidebar {
    width: 0 !important;
    min-width: 0 !important;
  }
}
.ant-layout-sider.gx-collapsed-sidebar {
  width: 0 !important;
  min-width: 0 !important;
}
@media screen and (max-width: 575px) {
  .ant-layout-sider {
    width: 0 !important;
    min-width: 0 !important;
  }
}
.gx-layout-sider-dark {
  background-color: #fffffe;
  color: #038fdd;
}
.gx-layout-sider-header {
  padding: 10px 30px 10px 70px;
  height: 72px;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 1;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  -ms-align-items: center;
  align-items: center;
}
@media screen and (max-width: 575px) {
  .gx-layout-sider-header {
    height: 50px;
  }
}
.gx-layout-sider-header .gx-site-logo {
  display: block;
}
.ant-layout-sider-collapsed .gx-layout-sider-header .gx-site-logo {
  display: none;
}
.gx-mini-custom-sidebar .gx-layout-sider-header .gx-site-logo {
  display: block;
}
.gx-layout-sider-header .gx-linebar {
  position: absolute;
  left: 20px;
  z-index: 1;
  top: 15px;
  transition: all 0.3s ease-out;
}
.ant-layout-sider-collapsed .gx-layout-sider-header .gx-linebar {
  left: 20px;
}
@media screen and (max-width: 991px) {
  .gx-layout-sider-header .gx-linebar {
    display: none;
  }
}
.ant-layout-sider-collapsed .gx-layout-sider-header {
  padding-left: 20px;
  padding-right: 20px;
}
.gx-drawer-sidebar .gx-layout-sider-header {
  padding-left: 30px;
}
.gx-drawer-sidebar-dark .gx-layout-sider-header {
  background-color: rgba(0, 0, 0, 0.3);
}
.gx-layout-sider-dark .gx-layout-sider-header {
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: none;
}
.gx-mini-custom-sidebar .gx-layout-sider-header {
  -ms-justify-content: center;
  justify-content: center;
  background-color: transparent;
}
.gx-custom-sidebar .gx-layout-sider-header {
  padding-left: 30px;
  background-color: transparent;
}
.gx-mini-custom-sidebar.ant-layout-sider-lite .gx-layout-sider-header,
.gx-custom-sidebar.ant-layout-sider-lite .gx-layout-sider-header {
  box-shadow: none;
  border-right: 1px solid #e8e8e8;
}
@media screen and (max-width: 991px) {
  .gx-layout-sider-header {
    padding-left: 30px;
  }
}
.gx-sidebar-content {
  border-right: 1px solid #e8e8e8;
}
.gx-layout-sider-dark .gx-sidebar-content,
.gx-drawer-sidebar-dark .gx-sidebar-content {
  border-right: 0 none;
}
.gx-layout-sider-scrollbar {
  height: calc(100vh - 117px) !important;
}
.framed-layout .gx-layout-sider-scrollbar {
  height: calc(100vh - 257px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
.ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
  height: calc(100vh - 163px) !important;
}
.framed-layout .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
  height: calc(100vh - 203px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 991px) {
  .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 575px) {
  .ant-layout-sider-collapsed .gx-layout-sider-scrollbar {
    height: calc(100vh - 50px) !important;
  }
}
.gx-mini-custom-sidebar .gx-layout-sider-scrollbar {
  height: calc(100vh - 278px) !important;
}
.framed-layout .gx-mini-custom-sidebar .gx-layout-sider-scrollbar {
  height: calc(100vh - 318px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-mini-custom-sidebar .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 991px) {
  .gx-layout-sider-scrollbar {
    height: calc(100vh - 72px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-layout-sider-scrollbar {
    height: calc(100vh - 50px) !important;
  }
}
@media screen and (max-width: 991px) {
  .gx-no-header-notifications + .gx-layout-sider-scrollbar {
    height: calc(100vh - 217px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-no-header-notifications + .gx-layout-sider-scrollbar {
    height: calc(100vh - 195px) !important;
  }
}
.gx-sidebar-notifications {
  padding: 30px 10px 10px;
  margin: 0 20px 10px;
  border-bottom: solid 1px #e8e8e8;
}
.ant-layout-sider-dark .gx-sidebar-notifications,
.gx-drawer-sidebar-dark .gx-sidebar-notifications {
  border-bottom-color: #038fdd;
}
@media screen and (max-width: 991px) {
  .gx-sidebar-notifications {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  .gx-sidebar-notifications.gx-no-header-notifications {
    display: block;
  }
}
.gx-app-nav {
  list-style: none;
  padding-left: 0;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  margin: 0 -20px;
  color: #038fde;
}
.ant-layout-sider-dark .gx-app-nav,
.gx-drawer-sidebar-dark .gx-app-nav {
  color: #038fdd;
}
.gx-app-nav li {
  padding: 0 20px;
  font-size: 20px;
}
.gx-app-nav li .icon {
  cursor: pointer;
}
.ant-layout-sider-collapsed .gx-app-nav {
  display: none;
}
.gx-mini-custom-sidebar .gx-app-nav {
  display: block;
  margin-top: 15px;
}
.gx-mini-custom-sidebar .gx-app-nav li:not(:last-child) {
  margin-bottom: 5px;
}
.gx-avatar-name {
  cursor: pointer;
}
.ant-layout-sider-collapsed .gx-avatar-name {
  display: none;
}
.ant-layout-sider-collapsed .gx-avatar-row {
  margin-bottom: 0 !important;
}
.gx-avatar-row .ant-avatar {
  transition: all 0.3s ease-out;
  position: relative;
  left: 0;
}
.ant-layout-sider-collapsed .gx-avatar-row .ant-avatar {
  margin: 0 !important;
  left: -10px;
}
.gx-menu-group .ant-menu-item-group-title {
  font-size: 16px;
  padding-top: 30px !important;
}
.gx-layouts-view .gx-pointer {
  display: block;
  margin-bottom: 15px;
}
.gx-layouts-view .gx-pointer img {
  width: 100%;
}
.gx-layouts-view .gx-pointer.active {
  box-shadow: 0 0 10px 0 #038fde;
  border-radius: 8px;
}
.sliders-wrapper {
  padding: 0 15px;
}
.causesSlider {
  background-color: inherit;
  border-radius: 0;
  border: 0px;
}
.causesSlider .ant-collapse-header {
  color: white !important;
  font-weight: 400;
}
.causesSlider .ant-collapse-content-active {
  background-color: inherit;
  color: white !important;
}
/* Ant Menu Style */
.ant-menu {
  color: #545454;
  background: #fff;
}
.ant-menu-horizontal {
  background: none;
}
.ant-menu-item-group-title {
  color: #545454;
  font-size: 11px;
  line-height: 1.3;
  padding: 10px 30px;
}
.ant-menu-submenu-popup .ant-menu-item-group-title {
  padding-left: 20px;
}
.ant-layout-sider .ant-menu-item-group-title {
  padding-top: 40px;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #e6faff;
}
.ant-menu-item .icon {
  min-width: 14px;
  margin-right: 20px;
}
.ant-menu-item .icon[class^="icon-"]::before,
.ant-menu-item .icon[class*=" icon-"]::before {
  position: relative;
  top: 2px;
}
.ant-menu-sub .ant-menu-item .icon {
  font-size: 12px;
}
.ant-menu-item > a {
  color: #545454;
}
.ant-menu-item > a:hover {
  color: #038fde;
}
.ant-menu-item-divider {
  background-color: #e8e8e8;
}
.ant-menu-submenu-inline {
  transition: all 0.3s ease-out;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #038fde;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #038fde;
}
.ant-menu-item-selected > a {
  color: #545454;
}
.ant-menu-item-selected > a:hover {
  color: #038fde;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e6faff;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #e8e8e8;
}
.ant-layout-sider .ant-menu-inline,
.ant-layout-sider .ant-menu-vertical,
.ant-layout-sider .ant-menu-vertical-left,
.gx-drawer-sidebar .ant-menu-inline,
.gx-drawer-sidebar .ant-menu-vertical,
.gx-drawer-sidebar .ant-menu-vertical-left {
  border-right: 0 none;
}
.ant-menu-vertical-right {
  border-left: 1px solid #e8e8e8;
}
.ant-menu-item,
.ant-menu-submenu-title {
  padding: 0 20px;
}
.gx-inside-header-horizontal .ant-menu-item,
.gx-inside-header-horizontal .ant-menu-submenu-title {
  padding: 0 10px;
}
@media screen and (max-width: 1199px) {
  .gx-inside-header-horizontal .ant-menu-item,
  .gx-inside-header-horizontal .ant-menu-submenu-title {
    padding: 0 6px;
  }
}
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  margin-right: 20px;
}
.ant-menu > .ant-menu-item-divider {
  background-color: #e8e8e8;
}
.ant-menu-sub > li > a {
  position: relative;
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 0;
}
.ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 54px !important;
}
.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item,
.ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 80px !important;
}
.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-sub .ant-menu-item,
.ant-drawer .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-sub .ant-menu-item {
  padding-left: 106px !important;
}
.ant-menu-submenu-popup {
  border-radius: 0;
  z-index: 1050 !important;
}
.ant-menu-submenu-popup .ant-menu-sub.ant-menu .ant-menu-item {
  padding-left: 20px !important;
}
.ant-menu-submenu-vertical .ant-menu-item:not(:last-child),
.ant-menu-submenu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-submenu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-submenu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
.gx-app-sidebar .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 22px;
  right: auto;
}
.ant-drawer .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 22px;
  right: auto;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  background-image: linear-gradient(to right, #545454, #545454);
}
.gx-app-sidebar .ant-menu-submenu-vertical .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-left .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-vertical-right .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.gx-app-sidebar .ant-menu-submenu-inline .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-left .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-right .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-inline .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 60px;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
  background: linear-gradient(to right, #038fde, #038fde);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #038fde;
}
.ant-menu-vertical .ant-menu-submenu-selected > a,
.ant-menu-vertical-left .ant-menu-submenu-selected > a,
.ant-menu-vertical-right .ant-menu-submenu-selected > a {
  color: #038fde;
}
.ant-menu-horizontal {
  border-bottom: 1px solid #e8e8e8;
  line-height: 46px;
  padding: 0;
}
.ant-layout-header .ant-menu-horizontal {
  border-bottom: 0 none;
  line-height: 70px;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 2px solid transparent;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid #038fde;
  color: #038fde;
}
.ant-menu-horizontal > .ant-menu-item > a,
.ant-menu-horizontal > .ant-menu-submenu > a {
  color: #545454;
}
.ant-menu-horizontal > .ant-menu-item > a:hover,
.ant-menu-horizontal > .ant-menu-submenu > a:hover {
  color: #038fde;
}
.ant-menu-horizontal > .ant-menu-item > a:before,
.ant-menu-horizontal > .ant-menu-submenu > a:before {
  bottom: -2px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
.ant-menu-vertical .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-inline .ant-menu-item:after {
  border-right: 3px solid #038fde;
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  padding: 0 30px 0 20px !important;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  text-overflow: inherit;
}
.ant-layout-sider .ant-menu-vertical .ant-menu-item,
.ant-layout-sider .ant-menu-vertical-left .ant-menu-item,
.ant-layout-sider .ant-menu-vertical-right .ant-menu-item,
.ant-layout-sider .ant-menu-inline .ant-menu-item,
.ant-layout-sider .ant-menu-vertical .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-right .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-inline .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical .ant-menu-item,
.ant-drawer .ant-menu-vertical-left .ant-menu-item,
.ant-drawer .ant-menu-vertical-right .ant-menu-item,
.ant-drawer .ant-menu-inline .ant-menu-item,
.ant-drawer .ant-menu-vertical .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-right .ant-menu-submenu-title,
.ant-drawer .ant-menu-inline .ant-menu-submenu-title {
  padding-left: 40px !important;
}
.ant-menu-vertical .ant-menu-item .icon,
.ant-menu-vertical-left .ant-menu-item .icon,
.ant-menu-vertical-right .ant-menu-item .icon,
.ant-menu-inline .ant-menu-item .icon,
.ant-menu-vertical .ant-menu-submenu-title .icon,
.ant-menu-vertical-left .ant-menu-submenu-title .icon,
.ant-menu-vertical-right .ant-menu-submenu-title .icon,
.ant-menu-inline .ant-menu-submenu-title .icon {
  min-width: 14px;
  margin-right: 20px;
}
.ant-menu-vertical .ant-menu-item .icon[class^="icon-"]::before,
.ant-menu-vertical-left .ant-menu-item .icon[class^="icon-"]::before,
.ant-menu-vertical-right .ant-menu-item .icon[class^="icon-"]::before,
.ant-menu-inline .ant-menu-item .icon[class^="icon-"]::before,
.ant-menu-vertical .ant-menu-submenu-title .icon[class^="icon-"]::before,
.ant-menu-vertical-left .ant-menu-submenu-title .icon[class^="icon-"]::before,
.ant-menu-vertical-right .ant-menu-submenu-title .icon[class^="icon-"]::before,
.ant-menu-inline .ant-menu-submenu-title .icon[class^="icon-"]::before,
.ant-menu-vertical .ant-menu-item .icon[class*=" icon-"]::before,
.ant-menu-vertical-left .ant-menu-item .icon[class*=" icon-"]::before,
.ant-menu-vertical-right .ant-menu-item .icon[class*=" icon-"]::before,
.ant-menu-inline .ant-menu-item .icon[class*=" icon-"]::before,
.ant-menu-vertical .ant-menu-submenu-title .icon[class*=" icon-"]::before,
.ant-menu-vertical-left .ant-menu-submenu-title .icon[class*=" icon-"]::before,
.ant-menu-vertical-right .ant-menu-submenu-title .icon[class*=" icon-"]::before,
.ant-menu-inline .ant-menu-submenu-title .icon[class*=" icon-"]::before {
  position: relative;
  top: 2px;
}
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item .icon + span,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title .icon + span,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title .icon + span,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title .icon + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title {
  padding: 0 32px !important;
}
.ant-card .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title {
  padding-left: 54px !important;
}
.ant-layout-sider .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title {
  padding-left: 80px !important;
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 30px !important;
}
.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 32px !important;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-inline-collapsed > .ant-menu-item .icon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .icon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .icon + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-menu-inline-collapsed-tooltip .icon {
  display: none;
}
.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-left: 4px;
  padding-right: 4px;
  display: none;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 42px;
  height: 42px;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding: 0 30px 0 80px;
  line-height: 42px;
  height: 42px;
  text-transform: uppercase;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-menu-item-disabled > a,
.ant-menu-submenu-disabled > a {
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #038fde;
}
.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: linear-gradient(to right, #038fde, #038fde);
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: #038fdd;
  background: #fffffe;
}
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: #038fdd;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: none;
  box-shadow: none;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom-color: #fffffe;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  border-color: #fffffe;
}
.ant-menu-dark .ant-menu-item .icon {
  min-width: 14px;
  margin-right: 20px;
}
.ant-menu-dark .ant-menu-item .icon[class^="icon-"]::before,
.ant-menu-dark .ant-menu-item .icon[class*=" icon-"]::before {
  position: relative;
  top: 2px;
}
.ant-menu-dark .ant-menu-item-group-title {
  color: #038fdd;
}
.ant-menu-dark .ant-menu-item {
  color: #038fdd;
}
.ant-menu-dark .ant-menu-item > a {
  color: #038fdd;
}
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a {
  color: #ffffff;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-item-selected > a {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > a {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
  background: #ffffff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before {
  background: #fa8c15;
}
.ant-menu-dark .ant-menu-submenu-open {
  color: #ffffff;
}
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open .ant-menu-submenu-title span,
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-submenu-active .ant-menu-submenu-title span {
  color: #ffffff;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fa8c15;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > a:hover {
  color: #fa8c15;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-title {
  color: #038fdd;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #ffffff;
}
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-submenu-title:hover {
  color: #fa8c15;
}
.ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #262626;
}
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow:before {
  background: #ffffff;
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-vertical:hover > .ant-menu-submenu-title {
  color: #ffffff;
}
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-submenu-title .icon,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-submenu-title .icon,
.ant-layout-sider .ant-menu .ant-menu-item .icon,
.ant-drawer .ant-menu .ant-menu-item .icon {
  font-size: 20px;
}
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title .icon,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title .icon,
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-item .icon,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-item .icon {
  font-size: 14px;
  margin-right: 12px;
}
.ant-layout-sider .ant-menu .ant-menu-submenu-title span,
.ant-drawer .ant-menu .ant-menu-submenu-title span {
  display: flex;
}
.ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background-image: linear-gradient(to right, #038fde, #038fde);
}
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  background: #ffffff;
}
.gx-navbar-nav {
  list-style: none;
  padding-left: 0;
  margin: 0 -5px;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.gx-navbar-nav li {
  padding: 0 5px;
}
.gx-navbar-nav li a {
  display: block;
  padding: 4px 10px;
  background-color: #038fde;
  color: #ffffff;
  border-radius: 4px;
}
.gx-profile-banner .gx-navbar-nav li a {
  background-color: transparent;
  color: #ffffff;
  border-radius: 0;
  padding: 0;
}
.gx-navbar-nav li a * {
  color: #bfbfbf;
}
.gx-navbar-nav li a:hover,
.gx-navbar-nav li a:focus {
  background-color: #026fac;
  color: #ffffff;
}
.gx-profile-banner .gx-navbar-nav li a:hover,
.gx-profile-banner .gx-navbar-nav li a:focus {
  background-color: transparent;
  color: #fa8c15;
}
.gx-profile-banner .gx-navbar-nav li {
  padding: 0 15px;
}
@media screen and (max-width: 575px) {
  .gx-profile-banner .gx-navbar-nav li {
    padding: 0 6px;
  }
}
.gx-profile-banner .gx-navbar-nav {
  margin: 0 -15px;
}
@media screen and (max-width: 575px) {
  .gx-profile-banner .gx-navbar-nav {
    -ms-justify-content: center;
    justify-content: center;
    margin: 0 -6px;
  }
}
.gx-header-horizontal-nav .ant-menu-horizontal {
  border-bottom: 0 none;
}
.gx-header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #ffffff;
}
.gx-below-header-horizontal .gx-header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title,
.gx-above-header-horizontal .gx-header-horizontal-nav .ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #7a7a7a;
}
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item:hover,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu:hover,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-active,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-active,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-open,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-open,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-selected,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom-color: #fa8c15;
  color: #fa8c15;
}
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title,
.gx-header-horizontal-nav .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #fa8c15;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 0 none;
  margin-top: 0;
  top: 0;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected {
  position: relative;
  border-bottom: 0 none;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title {
  position: relative;
}
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before {
  content: "\E049";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  text-align: center;
  font-family: "gaxon" !important;
  font-size: 31px;
  line-height: 5px;
}
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before {
  color: #f5f5f5;
}
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before {
  color: #fffffe;
}
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before {
  color: #fa8c15;
}
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before {
  color: #ffffff;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu {
  border-radius: 10px;
  margin-top: -7px;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu .ant-menu-item:first-child {
  border-radius: 10px 10px 0 0;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu .ant-menu-item:last-child {
  border-radius: 0 0 10px 10px;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu > .ant-menu {
  border-radius: 10px;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fa8c15;
  color: #fffffe;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a {
  color: #fffffe;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #fa8c15;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background-image: linear-gradient(to right, #fa8c15, #fa8c15);
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background-image: linear-gradient(to right, #fa8c15, #fa8c15);
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item > a:hover,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item:hover,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item-active,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-active,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-title:hover {
  color: #fa8c15;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-inside-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-below-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-above-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
  color: #fa8c15;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-inside-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-below-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a,
.gx-submenu-popup-curve.ant-menu-submenu-popup.gx-above-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a {
  color: #fa8c15;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed {
  padding-bottom: 10px;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 20px !important;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item > span,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item > span,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title > span {
  -ms-justify-content: center;
  justify-content: center;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item > a,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item > a {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item.ant-menu-item-selected > a,
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item.ant-menu-item-selected > a {
  color: #fffffe;
  background-color: #fa8c15;
  border-radius: 2px;
}
.gx-mini-custom-sidebar .ant-menu-inline-collapsed > .ant-menu-submenu.ant-menu-submenu-selected > .ant-menu-submenu-title > span {
  color: #fffffe;
  background-color: #fa8c15;
  border-radius: 2px;
}
.gx-mini-custom-sidebar .ant-menu-vertical .ant-menu-item .icon,
.gx-mini-custom-sidebar .ant-menu-vertical-left .ant-menu-item .icon,
.gx-mini-custom-sidebar .ant-menu-vertical-right .ant-menu-item .icon,
.gx-mini-custom-sidebar .ant-menu-inline .ant-menu-item .icon,
.gx-mini-custom-sidebar .ant-menu-vertical .ant-menu-submenu-title .icon,
.gx-mini-custom-sidebar .ant-menu-vertical-left .ant-menu-submenu-title .icon,
.gx-mini-custom-sidebar .ant-menu-vertical-right .ant-menu-submenu-title .icon,
.gx-mini-custom-sidebar .ant-menu-inline .ant-menu-submenu-title .icon {
  margin-right: 0;
}
.gx-no-header-submenu-popup .ant-menu-sub {
  border-radius: 4px;
}
/* Footer Style */
.ant-layout-footer {
  border-top: solid 1px #e8e8e8;
}
@media screen and (max-width: 575px) {
  .ant-layout-footer {
    padding: 14px 15px;
  }
}
.gx-container-wrap .ant-layout-footer {
  padding-left: 0;
  padding-right: 0;
}
.gx-container-wrap .gx-layout-footer-content {
  width: 94vw;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (min-width: 768px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 720px;
  }
}
@media screen and (min-width: 992px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 920px;
  }
}
@media screen and (min-width: 1200px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 1100px;
  }
}
@media screen and (min-width: 1367px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 1200px;
  }
}
@media screen and (min-width: 1600px) {
  .gx-container-wrap .gx-layout-footer-content {
    width: 1400px;
  }
}
/* Customizer Style */
.gx-customizer {
  height: calc(100vh - 30px) !important;
}
.gx-customizer-item:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.gx-customizer-item .ant-radio-group .ant-radio-button {
  display: none;
}
.gx-customizer-option {
  position: absolute;
  right: 0;
  top: 150px;
  z-index: 99;
}
.full-scroll .gx-customizer-option {
  position: fixed;
}
.full-scroll.boxed-layout .gx-customizer-option {
  position: absolute;
}
.full-scroll.framed-layout .gx-customizer-option {
  right: 20px;
}
@media screen and (max-width: 991px) {
  .full-scroll.framed-layout .gx-customizer-option {
    right: 0;
  }
}
.horizontal-layout.full-scroll .gx-customizer-option {
  top: 200px;
}
@media screen and (max-width: 575px) {
  .gx-customizer {
    width: 250px !important;
  }
  .gx-customizer .ant-radio-button-wrapper {
    padding: 0 10px;
  }
}
.gx-customizer .gx-list-inline {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.framed-layout .gx-customizer {
  height: calc(100vh - 70px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-customizer {
    height: calc(100vh - 30px) !important;
  }
}
.gx-color-option {
  margin-bottom: 0;
  padding-left: 0;
}
.gx-color-option li {
  font-size: 36px;
  line-height: 1;
}
.gx-color-option li .gx-link {
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}
.gx-color-option li .gx-link:before {
  font-family: "gaxon";
  font-size: 20px;
  content: "0";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: none;
}
.gx-color-option li .gx-link.active:before {
  display: block;
}
.gx-color-option li .gx-link:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 20px;
  height: 40px;
  background-color: #9283D4;
}
.gx-color-option li .gx-link.gx-color-red:after {
  background-color: #FF2B7A;
}
.gx-color-option li .gx-link.gx-color-blue:after {
  background-color: #3DA4E6;
}
.gx-color-option li .gx-link.gx-color-dark-blue:after {
  background-color: #0469B9;
}
.gx-color-option li .gx-link.gx-color-orange:after {
  background-color: #F18805;
}
.gx-color-option li .gx-link.gx-color-light-blue:after {
  background-color: #6A95FF;
}
.gx-color-option li .gx-link.gx-color-deep-orange:after {
  background-color: #F87060;
}
.gx-color-option li .gx-link.gx-color-light-purple1:after {
  background-color: #A172E7;
}
.gx-color-option li .gx-link.gx-color-light-purple2:after {
  background-color: #956FE7;
}
.gx-cus-customiz {
  padding-right: 20px;
}
.gx-layout-option li,
.gx-nav-option li {
  margin-bottom: 10px;
}
.gx-layout-option li span,
.gx-nav-option li span {
  position: relative;
  display: block;
}
.gx-layout-option li span:before,
.gx-nav-option li span:before {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  font-family: "gaxon" !important;
  content: "0";
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: none;
  width: 20px;
  font-size: 12px;
  height: 20px;
  border-radius: 50%;
  background-color: #038fde;
  text-align: center;
  padding: 2px;
  color: #ffffff;
}
.gx-layout-option li span.active:before,
.gx-nav-option li span.active:before {
  display: block;
}
/* Drawer Style */
.ant-drawer {
  overflow: hidden;
}
.ant-drawer-body {
  padding: 0;
}
.gx-drawer-sidebar-dark .ant-drawer-content {
  background-color: #fffffe;
  color: #038fdd;
}
@media screen and (max-width: 991px) {
  .gx-drawer-sidebar-dark .ant-drawer-content {
    background-color: rgba(255, 255, 255, 0.85);
  }
}
.ant-drawer-content-wrapper {
  width: 280px !important;
}
@media screen and (max-width: 991px) {
  .ant-drawer-content-wrapper {
    width: 280px !important;
  }
}
.framed-layout .ant-drawer-content-wrapper {
  top: 20px;
  bottom: 20px;
  height: calc(100vh - 40px);
  border-radius: 11px 0 0 11px;
  overflow: hidden;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer-content-wrapper {
    top: 0;
    bottom: 0;
    height: 100vh;
    border-radius: 0 !important;
  }
}
.ant-drawer-right .ant-drawer-content-wrapper {
  width: 370px !important;
  padding: 15px;
  background: #ffffff;
}
@media screen and (max-width: 991px) {
  .ant-drawer-right .ant-drawer-content-wrapper {
    width: 300px !important;
  }
}
@media screen and (max-width: 575px) {
  .ant-drawer-right .ant-drawer-content-wrapper {
    width: 280px !important;
  }
}
.framed-layout .ant-drawer-right .ant-drawer-content-wrapper {
  top: 20px;
  bottom: 20px;
  height: calc(100vh - 40px);
  border-radius: 0 11px 11px 0;
  overflow: hidden;
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer-right .ant-drawer-content-wrapper {
    top: 0;
    bottom: 0;
    height: 100vh;
    border-radius: 0 !important;
  }
}
.framed-layout .ant-drawer-content-wrapper,
.boxed-layout .ant-drawer-content-wrapper {
  position: absolute;
}
.boxed-layout .ant-drawer {
  max-width: 1280px;
  margin: 0 auto;
}
@media screen and (min-width: 1600px) {
  .boxed-layout .ant-drawer {
    max-width: 1500px;
  }
}
.framed-layout .ant-drawer-open {
  width: calc(100% - 40px);
}
@media screen and (max-width: 991px) {
  .framed-layout .ant-drawer-open {
    width: 100%;
  }
}
.ant-drawer-close {
  top: -11px;
}
.ant-drawer-close-x {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.ant-drawer-mask {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
/*Alert Styles*/
.gx-custom-sweet-alert h2 {
  color: #ffffff;
}
.notification {
  border-radius: 4px;
}
.notification .title {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}
.gx-sweet-alert-top-space {
  padding-top: 40px !important;
}
/*Avatar Styles*/
.gx-avatar-img {
  height: 50px;
  width: 50px;
  position: relative;
  border-radius: 50%;
  border: solid 1px #e8e8e8;
}
.gx-avatar-img-lg {
  height: 100px;
  width: 100px;
}
.ant-avatar {
  position: relative;
}
.ant-avatar.gx-size-150 > * {
  line-height: 150px;
}
.ant-avatar.gx-size-120 > * {
  line-height: 120px;
}
.ant-avatar.gx-size-100 > * {
  line-height: 100px;
}
.ant-avatar.gx-size-80 > * {
  line-height: 80px;
}
.ant-avatar.gx-size-60 > * {
  line-height: 60px;
}
.ant-avatar.gx-size-50 > * {
  line-height: 50px;
}
.ant-avatar.gx-size-40 > * {
  line-height: 40px;
}
.ant-avatar.gx-size-36 > * {
  line-height: 36px;
}
.ant-avatar.gx-size-30 > * {
  line-height: 30px;
}
.ant-avatar.gx-size-24 > * {
  line-height: 24px;
}
.ant-avatar.gx-size-20 > * {
  line-height: 20px;
}
.ant-avatar.gx-size-15 > * {
  line-height: 14px;
}
.gx-customers-list .ant-avatar {
  width: 70px;
  height: 70px;
}
@media (max-width: 1500px) {
  .gx-customers-list .ant-avatar {
    width: 60px;
    height: 60px;
  }
}
@media (max-width: 1366px) {
  .gx-customers-list .ant-avatar {
    width: 48px;
    height: 48px;
  }
}
@media (max-width: 1199px) {
  .gx-customers-list .ant-avatar {
    width: 60px;
    height: 60px;
  }
}
@media screen and (max-width: 400px) {
  .gx-customers-list .ant-avatar {
    width: 52px;
    height: 52px;
  }
}
.gx-avatar {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  -ms-justify-content: center;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  height: 50px;
  width: 50px;
  position: relative;
  border-radius: 50%;
  border: solid 1px #e8e8e8;
}
@media screen and (max-width: 991px) {
  .gx-user-nav .gx-avatar {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
}
@media screen and (max-width: 575px) {
  .gx-user-nav .gx-avatar {
    height: 30px;
    width: 30px;
    line-height: 30px;
  }
}
.gx-header-horizontal-main .gx-avatar {
  height: 40px;
  width: 40px;
  line-height: 40px;
}
@media screen and (max-width: 991px) {
  .gx-header-horizontal-main .gx-avatar {
    height: 30px;
    width: 30px;
    line-height: 30px;
  }
}
.gx-user-thumb {
  position: relative;
}
/*Badge Styles*/
.ant-badge {
  margin-bottom: 6px;
}
.ant-badge:not(:last-child) {
  margin-right: 12px;
}
.ant-tag {
  margin-bottom: 8px;
}
.gx-badge {
  display: inline-block;
  padding: 5px 8px 4px;
  font-size: 75%;
  margin-bottom: 6px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px;
}
.gx-badge:not(:last-child) {
  margin-right: 12px;
}
.gx-chat-date .gx-badge {
  margin-bottom: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 16px;
  padding: 2px;
}
.gx-user-thumb .gx-badge {
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 1;
  margin: 0;
  display: block;
  width: 16px;
  height: 16px;
  padding: 0;
  line-height: 16px;
}
.gx-badge-outline {
  background-color: transparent;
  border: solid 1px #e8e8e8;
}
.gx-badge-radius-sm .ant-badge-count {
  border-radius: 2px;
}
#components-back-top-demo-custom .ant-back-top {
  bottom: 100px;
}
#components-back-top-demo-custom .ant-back-top-inner {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #1088e9;
  color: #fff;
  text-align: center;
  font-size: 20px;
}
/*Button Styles*/
.ant-btn {
  line-height: 34px;
}
.ant-btn-sm {
  line-height: 24px;
}
.ant-btn-group > .ant-btn-sm {
  line-height: 24px;
}
.ant-btn-lg {
  line-height: 40px;
}
.ant-btn-group > .ant-btn-lg {
  line-height: 40px;
}
.ant-btn:not(:last-child) {
  margin-right: 15px;
}
@media screen and (max-width: 575px) {
  .ant-btn:not(:last-child) {
    margin-right: 10px;
  }
}
.ant-btn-group .ant-btn:not(:last-child),
.ant-transfer-operation .ant-btn:not(:last-child) {
  margin-right: 0;
}
@media (max-width: 1599px) {
  .gx-product-footer .ant-btn:not(:last-child) {
    margin-right: 6px;
  }
}
@media screen and (max-width: 575px) {
  .ant-btn {
    margin-bottom: 10px;
  }
}
.ant-input-search .ant-btn {
  margin-bottom: 0;
}
.gx-customizer-option .ant-btn {
  padding: 0 18px;
  font-size: 18px;
  height: 42px;
  line-height: 42px;
  margin-bottom: 0;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
  border-radius: 30px 0 0 30px;
  background-color: #fa8c16;
  background: -o-linear-gradient(left, #fa8c16, #f5222d);
  border: 0 none;
  color: #ffffff;
}
.gx-customizer-option .ant-btn:hover,
.gx-customizer-option .ant-btn:focus {
  background-color: #f5222d;
  background: -o-linear-gradient(left, #f5222d, #fa8c16);
}
.gx-customizer-option .ant-btn .icon:before {
  display: block;
}
.horizontal-layout.full-scroll .gx-customizer-option .ant-btn {
  margin-right: 0;
}
.gx-module-add-task .ant-btn {
  height: 36px;
}
.gx-module-add-task .ant-btn .icon:before {
  position: relative;
  top: 2px;
}
.ant-modal-footer .ant-btn,
.ant-modal-confirm-btns .ant-btn {
  margin-bottom: 0;
}
.gx-login-content .ant-btn {
  padding: 9px 20px !important;
  height: auto;
  line-height: 1;
}
.boxed-layout .ant-btn-group .ant-btn {
  padding: 0 12px;
}
@media (max-width: 1366px) {
  .ant-btn-group .ant-btn {
    padding: 0 12px;
  }
}
@media (max-width: 1366px) {
  .gx-product-footer .ant-btn {
    padding: 0 10px;
    height: 32px;
    font-size: 12px;
    line-height: 32px;
  }
}
.ant-btn-background-ghost.ant-btn-primary {
  border-color: #038fde;
  color: #038fde;
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  background-color: #038fde;
  border-color: #038fde;
  color: #ffffff !important;
}
.ant-btn-group:not(:last-child) {
  margin-right: 15px;
}
@media screen and (max-width: 575px) {
  .ant-btn-group:not(:last-child) {
    margin-right: 10px;
  }
}
.ant-radio-group .ant-radio-button-wrapper {
  margin-bottom: 6px;
}
button {
  outline: none;
}
.ant-confirm .ant-confirm-btns button + button {
  margin-bottom: 8px;
}
.ant-modal.ant-confirm .ant-confirm-btns button + button,
.ant-modal.ant-confirm .ant-btn {
  margin-bottom: 0;
}
.gx-btn,
.btn {
  display: inline-block;
  font-weight: 400;
  color: #545454;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: solid 1px #d9d9d9;
  padding: 0 15px;
  height: 36px;
  font-size: 14px;
  line-height: 34px;
  border-radius: 4px;
  margin-bottom: 15px;
}
.gx-btn:not(:last-child),
.btn:not(:last-child) {
  margin-right: 15px;
}
@media screen and (max-width: 575px) {
  .gx-btn:not(:last-child),
  .btn:not(:last-child) {
    margin-right: 10px;
  }
}
@media screen and (max-width: 575px) {
  .gx-btn,
  .btn {
    margin-bottom: 10px;
  }
}
.gx-btn:focus,
.btn:focus,
.gx-btn:hover,
.btn:hover {
  text-decoration: none;
}
.gx-btn:focus,
.btn:focus,
.gx-btn.focus,
.btn.focus {
  outline: 0;
}
.gx-btn.disabled,
.btn.disabled,
.gx-btn:disabled,
.btn:disabled {
  opacity: 0.5;
}
.gx-btn:not([disabled]):not(.disabled),
.btn:not([disabled]):not(.disabled) {
  cursor: pointer;
}
.gx-btn-list .gx-btn,
.gx-btn-list .btn {
  margin-bottom: 6px;
}
.gx-sub-popover .gx-btn,
.gx-sub-popover .btn {
  border: 0 none;
  margin-bottom: 5px;
}
.gx-sub-popover .gx-btn:focus,
.gx-sub-popover .btn:focus,
.gx-sub-popover .gx-btn:hover,
.gx-sub-popover .btn:hover {
  background-color: #f5f5f5;
}
.gx-sub-popover .gx-btn:not(:last-child),
.gx-sub-popover .btn:not(:last-child) {
  margin-right: 5px;
}
.gx-login-content .gx-btn,
.gx-login-content .btn {
  padding: 9px 20px !important;
  height: auto;
  line-height: 1;
}
.gx-btn-block {
  display: block;
  width: 100%;
}
a.gx-btn.disabled,
fieldset[disabled] a.gx-btn {
  pointer-events: none;
}
.gx-btn-rounded {
  border-radius: 50px !important;
}
.gx-btn-link {
  font-size: 14px;
  font-weight: 400;
  color: #038fde;
  background-color: transparent;
}
.gx-btn-link:hover {
  color: #038fde;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}
.gx-btn-link:focus,
.gx-btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
}
.gx-btn-link:disabled,
.gx-btn-link.disabled {
  color: #bfbfbf;
}
.gx-btn-lg,
.gx-btn-group-lg > .gx-btn {
  padding: 0 15px;
  font-size: 16px;
  line-height: 40px;
  border-radius: 6px;
  height: 40px;
}
.gx-btn-sm,
.gx-btn-group-sm > .gx-btn {
  padding: 0 7px;
  font-size: 12px;
  line-height: 24px;
  border-radius: 2px;
  height: 24px;
}
.gx-btn-xs {
  font-size: 10px;
  padding: 0 6px;
  height: 26px;
  line-height: 25px;
}
.gx-btn-block {
  display: block;
  width: 100%;
}
.gx-btn-block + .gx-btn-block {
  margin-top: 0.5rem;
}
.gx-icon-btn {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-justify-content: center;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  cursor: pointer;
}
.gx-icon-btn:focus,
.gx-icon-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.gx-layout-sider-dark .gx-icon-btn:focus,
.gx-layout-sider-dark .gx-icon-btn:hover {
  background-color: #fffffe;
}
.gx-popover-header .gx-icon-btn {
  width: 30px;
  height: 30px;
}
.gx-icon-btn .icon-star-o:before,
.gx-icon-btn .icon-star:before {
  position: relative;
  top: 3px;
}
@media screen and (max-width: 575px) {
  .gx-module-box-content .gx-icon-btn.icon {
    font-size: 16px;
  }
}
.gx-radio-group-link.ant-radio-group .ant-radio-button-wrapper {
  height: 24px;
  line-height: 24px;
  border: 0 none;
  border-radius: 2px;
  margin-left: 0;
}
.gx-radio-group-link.ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: #038fde;
  color: #ffffff;
}
.gx-radio-group-link.ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
@media screen and (max-width: 575px) {
  .gx-radio-group-link-news {
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 575px) {
  .gx-radio-group-link .ant-radio-button-wrapper {
    padding: 0 8px;
  }
}
.gx-radio-group-link-bg-light {
  margin-left: -5px;
  margin-right: -5px;
}
.gx-radio-group-link-bg-light.ant-radio-group .ant-radio-button-wrapper {
  padding: 0 10px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #e8e8e8;
}
.gx-radio-group-link-bg-light.ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: #038fde;
  color: #ffffff;
}
.gx-radio-group-link-bg-light.ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
/*Card Styles*/
.ant-card {
  margin-bottom: 32px;
}
.ant-card-head {
  background: none;
}
.ant-card-head-title {
  font-weight: 400;
}
.ant-card-grid {
  text-align: center;
  width: 25%;
}
@media screen and (max-width: 767px) {
  .ant-card-grid {
    width: 33.33%;
  }
}
@media screen and (max-width: 575px) {
  .ant-card-grid {
    width: 50%;
  }
}
@media screen and (max-width: 575px) {
  .ant-card {
    margin-bottom: 20px;
  }
}
.ant-card-body .ant-card-head {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.ant-card-type-inner .ant-card-head {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.ant-card-wider-padding .ant-card-body > :last-child {
  margin-bottom: 0;
}
.gx-card-center-vertical {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-card-img {
  position: relative;
  overflow: hidden;
}
.gx-card-img .ant-card-body {
  position: relative;
}
.gx-card-img .gx-badge-up {
  position: absolute;
  right: 60px;
  top: -20px;
  z-index: 1;
  padding: 5px;
  margin: 0;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  line-height: 36px;
  text-align: center;
}
.gx-card-img .gx-badge-up-right {
  right: 10px;
  top: -15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  line-height: 32px !important;
}
.gx-card-img-center .ant-card-cover {
  text-align: center;
}
.gx-card-img-center .ant-card-cover > * {
  width: auto;
  display: inline-block;
}
.gx-card-body-border-top .ant-card-cover {
  padding-top: 24px;
}
.gx-card-body-border-top .ant-card-body {
  border-top: solid 1px #e8e8e8;
  margin-top: 32px;
}
@media screen and (max-width: 575px) {
  .gx-card-body-border-top .ant-card-body {
    margin-bottom: 20px;
  }
}
.gx-ant-card-actions {
  list-style: none;
  margin: 0 -10px 10px;
  padding-left: 0;
}
.gx-ant-card-actions li {
  display: inline-block;
  vertical-align: top;
  padding: 0 10px;
}
.gx-ant-card-actions li:not(:last-child) {
  border-right: solid 1px #e8e8e8;
}
.gx-card {
  margin-bottom: 32px;
  background-color: #ffffff;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  position: relative;
  border-radius: 10px;
}
@media screen and (max-width: 575px) {
  .gx-card {
    margin-bottom: 20px;
  }
}
.gx-card-head {
  padding: 24px;
}
.gx-card-head .gx-card-title {
  margin-bottom: 0;
}
.gx-card-head + .gx-card-body {
  padding-top: 0;
}
.gx-card .ant-card-head {
  border-bottom: 0 none;
  min-height: 10px;
  padding: 0 24px;
}
@media screen and (max-width: 991px) {
  .gx-card .ant-card-head {
    padding: 0 24px;
  }
}
.gx-card .ant-card-head-title {
  padding: 25px 0 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
@media screen and (max-width: 991px) {
  .gx-card .ant-card-head-title {
    padding-top: 20px;
  }
}
.gx-card-body,
.gx-card .ant-card-body {
  padding: 24px;
}
@media screen and (max-width: 991px) {
  .gx-card-body,
  .gx-card .ant-card-body {
    padding: 24px;
  }
}
.gx-card.ant-card-bordered {
  border: 0 none;
}
.gx-card.ant-card-hoverable:hover {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.45);
}
.gx-card .ant-card-cover {
  padding: 24px;
  padding-bottom: 0;
}
@media screen and (max-width: 991px) {
  .gx-card .ant-card-cover {
    padding: 24px;
    padding-bottom: 0;
  }
}
.gx-card-metrics {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
}
.gx-card-metrics.ant-card-bordered {
  border: 0 none;
}
.gx-card-metrics .ant-card-head {
  border-bottom: 0 none;
  min-height: 10px;
}
.gx-card-metrics .ant-card-head-title {
  padding: 20px 0 0;
  text-transform: uppercase;
  font-size: 14px;
}
.gx-card-widget {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
}
.gx-card-widget.ant-card-bordered {
  border: 0 none;
}
.gx-card-widget .ant-card-head {
  border-bottom: 0 none;
  min-height: 24px;
  padding: 24px 24px 0;
}
.gx-card-widget .ant-card-head-wrapper {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
}
.gx-card-widget .ant-card-head-title {
  padding: 0;
  text-transform: uppercase;
  font-size: 14px;
}
.gx-card-widget.gx-ch-capitalize .ant-card-head-title {
  text-transform: capitalize;
  font-size: 14px;
}
.gx-card-widget .ant-card-extra {
  padding: 0;
}
.gx-card-widget .ant-card-extra .gx-btn {
  margin-bottom: 0;
}
.gx-card-widget .ant-card-body {
  padding: 24px;
}
.gx-card-equal-height {
  height: calc(100% - 32px);
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
@media screen and (max-width: 575px) {
  .gx-card-equal-height {
    height: calc(100% - 20px);
  }
}
.gx-card-equal-height .ant-card-body {
  flex: 1 1;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.gx-card-equal-height .ant-card-body .gx-rechart {
  flex: 1 1;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.gx-card-equal-height .ant-card-body .gx-rechart .recharts-responsive-container {
  flex: 1 1;
  display: flex;
}
.gx-card-sm-p .ant-card-head {
  min-height: 10px;
}
.gx-card-sm-p .ant-card-head-title {
  padding: 14px 14px 0;
}
.gx-card-sm-p .ant-card-body {
  padding: 14px;
}
.gx-card-sm-px .ant-card-head {
  min-height: 10px;
}
.gx-card-sm-px .ant-card-head-title {
  padding-left: 10px;
  padding-right: 10px;
}
.gx-card-sm-px .ant-card-body {
  padding-left: 10px;
  padding-right: 10px;
}
.gx-entry-header {
  margin-bottom: 32px;
}
@media screen and (max-width: 575px) {
  .gx-entry-header {
    margin-bottom: 20px;
  }
}
.gx-entry-header .gx-entry-heading {
  margin-bottom: 0;
}
.gx-entry-header .gx-entry-description {
  margin-bottom: 0;
  font-size: 13px;
}
.gx-card-full {
  overflow: hidden;
}
.gx-card-full .gx-card-body {
  padding: 0;
}
.gx-card-full .gx-card-body .recharts-legend-wrapper {
  padding: 0 24px;
}
.gx-card-full .ant-card-body {
  padding: 0;
}
.gx-card-full .ant-card-body .recharts-legend-wrapper {
  padding: 0 24px;
}
.gx-card-full .recharts-default-legend {
  margin: 0 -10px !important;
}
.gx-card-full .recharts-default-legend li {
  padding: 0 10px;
  margin: 0 !important;
  display: inline-block;
  vertical-align: top;
}
.gx-card-full .recharts-default-legend li .recharts-legend-item-text {
  margin-left: 10px;
}
.gx-card-overview {
  margin-bottom: 32px;
  background-color: #ffffff;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  position: relative;
  border-radius: 10px;
  padding: 24px;
}
@media screen and (max-width: 575px) {
  .gx-card-overview {
    margin-bottom: 20px;
  }
}
.gx-card-overview .gx-card-title {
  margin-bottom: 20px;
}
.gx-card-profile .ant-card-head {
  padding-top: 15px;
  padding-bottom: 16px;
}
.gx-card-profile.gx-card-tabs .ant-card-head {
  border-bottom: 1px solid #e8e8e8;
}
@media screen and (max-width: 575px) {
  .gx-card-profile.gx-card-tabs .ant-card-head {
    border-bottom: 0 none;
  }
}
.gx-card-profile .ant-card-body,
.gx-card-profile.gx-card-tabs .ant-card-body {
  padding-top: 0;
}
.gx-card-profile.gx-card-tabs .ant-tabs {
  margin-top: -49px;
}
@media screen and (max-width: 575px) {
  .gx-card-profile.gx-card-tabs .ant-tabs {
    margin-top: 0;
  }
}
.gx-card-profile .ant-tabs-tab {
  padding-top: 15px;
  padding-bottom: 18px;
}
.gx-card-profile .ant-card-head-title,
.gx-card-profile.gx-card-tabs .ant-card-head-title {
  font-size: 20px;
  text-transform: capitalize;
}
.gx-card-profile.gx-card-tabs .ant-tabs-nav-container {
  font-size: 14px;
  color: #8c8c8c;
}
.gx-card-profile-sm.gx-card-widget .ant-card-body {
  padding-top: 10px;
}
@media (max-width: 1366px) {
  .gx-card-1367-p .ant-card-head {
    min-height: 10px;
  }
  .gx-card-1367-p .ant-card-head-title {
    padding-left: 14px;
    padding-right: 14px;
  }
  .gx-card-1367-p .ant-card-body {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.ant-divider-horizontal.ant-divider-with-text,
.ant-divider-horizontal.ant-divider-with-text-left,
.ant-divider-horizontal.ant-divider-with-text-right {
  font-size: 14px;
}
/*Cascader Styles*/
.ant-cascader-picker {
  width: 100%;
}
/*Chart Styles*/
.recharts-default-tooltip {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.8) !important;
  color: #fa8c15;
}
.recharts-tooltip-item-list li {
  color: #038fde !important;
}
/*Form Styles*/
.ant-form .ant-row {
  margin-left: 0;
  margin-right: 0;
}
.ant-form-inline {
  margin-left: -8px;
  margin-right: -8px;
}
.ant-form-inline .ant-row {
  padding-left: 8px;
  padding-right: 8px;
}
.ant-form-inline .ant-form-item {
  margin-bottom: 10px;
}
@media screen and (max-width: 575px) {
  .ant-form-inline .ant-form-item {
    display: block;
  }
}
.ant-form-horizontal .ant-row {
  margin-left: -15px;
  margin-right: -15px;
}
.ant-form-vertical .ant-form-item {
  padding-bottom: 0;
}
.ant-form-vertical .ant-row {
  flex-direction: column;
}
.ant-form-extra {
  word-break: break-all;
}
@media (max-width: 575px) {
  .ant-form-item-label {
    padding-bottom: 0;
  }
}
.ant-form-item-with-help {
  margin-bottom: 20px;
}
.ant-advanced-search-form .gx-btn-link:hover {
  text-decoration: none;
}
.ant-form-item-control > .ant-form-item:last-child,
.ant-form-item [class^="ant-col-"] > .ant-form-item:only-child {
  margin-bottom: 0;
}
.gx-form-row0 .ant-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.gx-login-form {
  max-width: 350px;
}
.gx-login-form .ant-form-item-control-wrapper {
  width: 100%;
}
.ant-form-inline .gx-form-item-one-fourth {
  width: 25%;
}
@media (max-width: 575px) {
  .ant-form-inline .gx-form-item-one-fourth {
    width: 100%;
  }
}
.ant-form-inline .gx-form-item-two-fourth {
  width: 50%;
}
@media (max-width: 575px) {
  .ant-form-inline .gx-form-item-two-fourth {
    width: 100%;
  }
}
.gx-form-inline-currency {
  margin: 0 -8px;
}
.gx-form-inline-currency .ant-form-item {
  padding: 0 8px;
}
.gx-form-inline-currency .gx-form-item-one-fourth {
  width: 30%;
}
@media (max-width: 575px) {
  .gx-form-inline-currency .gx-form-item-one-fourth {
    width: 40%;
    display: inline-block;
  }
}
.gx-form-inline-currency .gx-form-item-two-fourth {
  width: 40%;
}
@media (max-width: 575px) {
  .gx-form-inline-currency .gx-form-item-two-fourth {
    width: 100%;
    display: block;
  }
}
@media (max-width: 1599px) {
  .ant-form-item-label[class*='ant-col-'] {
    padding: 0 15px;
  }
}
@media (max-width: 1199px) {
  .ant-form-item-label[class*='ant-col-'] {
    padding: 0 15px;
  }
  .ant-form-item,
  .ant-form-item-with-help {
    margin-bottom: 12px;
  }
  .ant-advanced-search-form .ant-form-item {
    margin-bottom: 12px;
  }
}
@media (max-width: 991px) {
  .ant-form-item-label[class*='ant-col-'] {
    padding: 0 15px;
  }
}
@media (max-width: 767px) {
  .ant-form-item-label[class*='ant-col-'] {
    padding: 0 15px;
  }
}
@media (max-width: 575px) {
  .ant-form-item-label[class*='ant-col-'] {
    padding: 0 15px;
    margin-bottom: 8px;
  }
  .ant-advanced-search-form .ant-btn,
  .ant-advanced-search-form .gx-btn-link {
    display: block;
    margin: 0 0 10px !important;
  }
}
/*Input Styles*/
.ant-input-search {
  margin-bottom: 16px;
}
.ant-input-search > .ant-input-suffix > .ant-input-search-button {
  margin-right: 0;
}
.ant-input-group > [class*="col-"] {
  padding-left: 0;
  float: left !important;
}
.ant-transfer-list {
  margin-bottom: 10px;
}
.gx-form-group {
  margin-bottom: 10px;
}
.gx-search-bar {
  position: relative;
}
.gx-search-bar .gx-form-group {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
}
.gx-search-bar input[type="search"] {
  padding-right: 35px;
  background: rgba(250, 250, 250, 0.1);
}
.ant-layout-header .gx-search-bar input[type="search"],
.ant-popover-content .gx-search-bar input[type="search"] {
  border: 0 none;
}
.gx-search-bar input[type="search"]:focus {
  box-shadow: none;
  background-color: rgba(250, 250, 250, 0.3);
}
.gx-search-bar .gx-search-icon {
  background-color: transparent;
  border: 0 none;
  color: #d9d9d9;
  height: 32px;
  width: 30px;
  line-height: 36px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
}
.gx-algolia-header .gx-search-bar .gx-search-icon {
  margin-top: 2px;
  text-align: left;
}
.gx-search-bar .ant-input-lg + .gx-search-icon {
  height: 40px;
  line-height: 45px;
}
.gx-lt-icon-search-bar input[type="search"] {
  padding-right: 16px;
  padding-left: 35px;
  border: 0 none;
}
.gx-lt-icon-search-bar .gx-search-icon {
  left: 0;
  right: auto;
}
.gx-algolia-header .gx-lt-icon-search-bar {
  margin-right: 10px;
}
@media screen and (max-width: 1199px) {
  .gx-algolia-header .gx-lt-icon-search-bar {
    width: 140px;
  }
}
@media screen and (max-width: 400px) {
  .gx-algolia-header .gx-lt-icon-search-bar {
    margin-right: 0;
    margin-bottom: 10px;
    width: auto;
  }
}
.gx-lt-icon-search-bar-lg {
  width: 350px;
}
.gx-lt-icon-search-bar-lg input[type="search"] {
  border-radius: 4px;
  height: 46px;
  padding: 10px 18px 10px 40px;
}
@media screen and (max-width: 991px) {
  .gx-lt-icon-search-bar-lg input[type="search"] {
    height: 38px;
    padding: 5px 18px 5px 40px;
  }
}
.gx-header-search .gx-lt-icon-search-bar-lg input[type="search"] {
  background-color: #f5f5f5;
  height: 36px;
  border-radius: 4px 0 0 4px;
  border: solid 1px #e8e8e8;
}
.gx-header-search .gx-lt-icon-search-bar-lg input[type="search"]:focus {
  background-color: #f2f2f2;
}
.gx-header-horizontal-dark .gx-header-search .gx-lt-icon-search-bar-lg input[type="search"] {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}
.gx-header-horizontal-dark .gx-header-search .gx-lt-icon-search-bar-lg input[type="search"]:focus {
  background-color: transparent;
}
.gx-lt-icon-search-bar-lg .gx-search-icon {
  width: 35px;
  height: 46px;
  line-height: 50px;
  font-size: 16px;
  left: 0;
  right: auto;
}
@media screen and (max-width: 991px) {
  .gx-lt-icon-search-bar-lg .gx-search-icon {
    height: 38px;
    line-height: 40px;
  }
}
.gx-header-search .gx-lt-icon-search-bar-lg .gx-search-icon {
  height: 36px;
  line-height: 40px;
}
.gx-inside-header-horizontal .gx-lt-icon-search-bar-lg {
  width: 200px;
  margin-right: 10px;
}
@media screen and (max-width: 1199px) {
  .gx-inside-header-horizontal .gx-lt-icon-search-bar-lg {
    width: 120px;
  }
}
@media screen and (max-width: 1199px) {
  .gx-lt-icon-search-bar-lg {
    width: 150px;
  }
}
.gx-popover-search-bar {
  width: 150px;
  margin: -10px -14px;
}
.gx-chat-search-bar {
  flex: 1 1 auto;
  min-width: 100px;
  width: auto;
}
.gx-chat-search-bar input[type="search"] {
  box-shadow: none;
  border-radius: 0;
  height: 40px;
  border: 0 none;
}
.gx-chat-search-bar input[type="search"]:focus {
  background-color: #ffffff;
}
.gx-chat-search-bar .gx-search-icon {
  height: 40px;
  line-height: 44px;
}
.ant-transfer-list {
  width: 150px;
}
@media screen and (max-width: 575px) {
  .ant-transfer-list {
    width: 100%;
  }
}
.gx-select-sm {
  font-size: 12px;
}
.gx-select-sm .ant-select-selection {
  border-radius: 4px;
}
.gx-select-sm .ant-select-selection--single {
  height: 24px;
}
.gx-select-sm .ant-select-selection__rendered {
  line-height: 22px;
  margin-left: 6px;
  margin-right: 6px;
}
.gx-select-sm .ant-select-selection-selected-value {
  padding-right: 20px;
}
.gx-select-sm .ant-select-arrow {
  right: 6px;
}
.gx-select-sm .ant-select-dropdown {
  font-size: 12px;
}
.gx-form-inline-label-up .ant-form-item > .ant-form-item-control-wrapper,
.gx-form-inline-label-up .ant-form-item > .ant-form-item-label {
  display: block;
}
.gx-form-inline-label-up .ant-form-item-label {
  text-align: left;
  color: #bfbfbf;
  line-height: 1;
  margin-bottom: 2px;
}
.gx-form-inline-label-up .ant-select-selection--single,
.gx-form-inline-label-up .ant-input {
  height: 30px;
}
.gx-form-inline-label-up .ant-select-selection__rendered {
  line-height: 28px;
}
@media screen and (max-width: 575px) {
  .gx-ant-transfer-width .ant-transfer-list {
    width: 100% !important;
  }
}
@media screen and (max-width: 475px) {
  .ant-input-group.ant-input-group-compact > * {
    border-radius: 4px !important;
    width: 100% !important;
    border-right-width: 1px;
  }
  .ant-input-group.ant-input-group-compact > *:not(:last-child) {
    margin-bottom: 10px;
  }
  .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selection,
  .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
  .ant-input-group.ant-input-group-compact > .ant-mention-wrapper .ant-mention-editor,
  .ant-input-group.ant-input-group-compact > .ant-time-picker .ant-time-picker-input {
    border-radius: 4px;
    border-right-width: 1px;
  }
  .ant-input-group.ant-input-group-compact .gx-border-lt-xs {
    border-left: 1px solid #d9d9d9 !important;
  }
}
/*Icon Styles*/
.gx-icon-views {
  position: relative;
  text-align: center;
  padding: 16px;
  border-radius: 10px;
  transition: all 0.3s ease-out;
  margin-bottom: 15px;
  height: calc(85%);
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-align-items: center;
  align-items: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-icon-views .icon {
  margin-bottom: 12px;
  font-size: 20px;
  transition: all 0.3s ease-out;
  line-height: 1;
  display: block;
}
.gx-icon-views:hover {
  background-color: #038fde;
  cursor: pointer;
  color: #ffffff;
}
.gx-icon-views:hover .icon {
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
}
.gx-icon-text {
  display: block;
  font-size: 12px;
}
/*List Styles*/
.ant-list-vertical .ant-list-item-main {
  min-width: 200px;
}
@media screen and (max-width: 480px) {
  .ant-list-vertical .ant-list-item-extra {
    margin-bottom: 10px;
  }
}
.gx-list-item .ant-list-item-extra-wrap {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1 1;
}
.gx-list-item .ant-list-item-extra-wrap .ant-list-item-extra {
  margin-left: 20px;
}
@media screen and (max-width: 575px) {
  .gx-list-item .ant-list-item-extra-wrap .ant-list-item-extra {
    margin-left: 0;
    margin-top: 10px;
  }
  .gx-list-item .ant-list-item-extra-wrap .ant-list-item-extra img {
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .gx-list-item .ant-list-item-extra-wrap {
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
.gx-user-list {
  width: 100%;
  padding-bottom: 10px;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.gx-user-list:not(:first-child) {
  padding-top: 20px;
}
@media screen and (max-width: 575px) {
  .gx-user-list:not(:first-child) {
    padding-top: 0;
  }
}
.gx-user-list.gx-card-list {
  padding: 30px;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 32px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
}
.gx-user-list.gx-card-list .gx-avatar-img {
  margin-right: 25px;
}
@media screen and (max-width: 767px) {
  .gx-user-list.gx-card-list {
    padding: 20px;
  }
}
@media screen and (max-width: 575px) {
  .gx-user-list.gx-card-list {
    margin-bottom: 20px;
  }
}
.gx-user-list .gx-avatar-img {
  margin-right: 20px;
  margin-bottom: 10px;
}
.gx-user-list h3 {
  font-weight: 500;
  margin-bottom: 8px;
}
.gx-user-list .gx-link[class*="gx-meta-"] {
  display: inline-block;
  color: #545454;
  font-size: 12px;
  font-weight: 500;
}
.gx-user-list .gx-link[class*="gx-meta-"] i {
  margin-right: 6px;
  vertical-align: middle;
}
.gx-user-list .gx-description {
  width: calc(100% - 120px);
}
@media screen and (max-width: 575px) {
  .gx-user-list .gx-description {
    width: 100%;
  }
}
.gx-user-list .gx-btn-list li:not(:last-child) {
  margin-right: 10px;
}
.gx-list-inline {
  margin-left: -5px;
  margin-right: -5px;
  padding-left: 0;
  list-style: none;
}
.gx-list-inline li {
  padding: 0 5px;
  display: inline-block;
}
.gx-list-inline-lg {
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 0;
  list-style: none;
}
.gx-list-inline-lg li {
  padding: 0 10px;
  display: inline-block;
}
.gx-card-strip {
  margin-bottom: 10px;
}
.gx-card-strip:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
}
.gx-card-list .gx-description {
  width: calc(100% - 230px);
  margin-top: 5px;
}
@media screen and (max-width: 767px) {
  .gx-card-list .gx-description {
    width: calc(100% - 80px);
  }
}
.gx-card-list .gx-card-list-footer {
  padding-left: 15px;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  -ms-justify-content: center;
  justify-content: center;
  min-width: 150px;
}
.gx-card-list .gx-card-list-footer .ant-btn {
  display: block;
  margin-right: 0;
  width: 120px;
}
.gx-card-list .gx-card-list-footer .ant-btn:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .gx-card-list .gx-card-list-footer {
    padding-left: 0;
    min-width: 10px;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .gx-card-list .gx-card-list-footer .ant-btn {
    width: auto;
    margin-right: 12px;
    margin-bottom: 0;
  }
}
.gx-list-group {
  list-style: none;
  padding-left: 0;
}
.gx-list-group li {
  margin-bottom: 6px;
}
.gx-list-group li i {
  margin-right: 12px;
}
.gx-dot-list {
  list-style: none;
  margin: 0 -3px 10px;
  padding-left: 0;
}
.gx-dot-list li {
  display: inline-block;
  vertical-align: top;
  padding: 0 3px;
}
@media screen and (max-width: 575px) {
  .gx-dot-list {
    margin-bottom: 5px;
  }
}
/*Loader Styles*/
.ant-spin {
  margin-right: 16px;
  margin-bottom: 10px;
}
.gx-loader-container {
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 30px 50px;
  margin-right: 0;
  display: block;
}
/*Pagination Styles*/
.ant-pagination > li {
  margin-bottom: 10px;
}
.ant-pagination > li.ant-pagination-options,
.ant-pagination > li.ant-pagination-total-text {
  margin-bottom: 0;
}
.ant-pagination.mini:not(:last-child) {
  margin-bottom: 10px;
}
.ant-pagination.mini li {
  margin-bottom: 5px !important;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  line-height: 30px;
}
/*Pickers Styles*/
.photoshop-picker,
.swatches-picker,
.alpha-picker,
.hue-picker {
  width: 100% !important;
}
.gx-alpha-pickers {
  position: relative;
  min-height: 250px;
}
.ant-calendar-footer .ant-calendar-ok-btn {
  margin-bottom: 0;
}
/*Progress Styles*/
.ant-progress-line {
  margin-right: 8px;
  margin-bottom: 8px;
}
.ant-progress-circle {
  margin-right: 8px;
  margin-bottom: 8px;
}
/*Steps Styles*/
.ant-steps-dot {
  flex-wrap: wrap;
  overflow: hidden;
  padding: 10px 0;
}
.ant-steps-dot .ant-steps-item {
  margin-bottom: 10px;
}
@media screen and (max-width: 575px) {
  .ant-steps-dot .ant-steps-item-description {
    display: none;
  }
}
/*Slider Styles*/
.ant-carousel h1,
.ant-carousel h2,
.ant-carousel h3,
.ant-carousel h4,
.ant-carousel h5,
.ant-carousel h6 {
  line-height: inherit;
  margin-bottom: 0;
}
.gx-vertical-slider {
  position: relative;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.gx-vertical-slider-item {
  width: 33.33%;
  padding: 0 15px;
  height: 300px;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-align-items: center;
  align-items: center;
}
@media screen and (max-width: 575px) {
  .gx-vertical-slider-item {
    width: 50%;
    margin-bottom: 15px;
  }
}
.ant-carousel-vertical .gx-vertical-slide .slick-slide {
  height: 160px;
}
.slick-slider {
  margin-bottom: 32px;
}
@media screen and (max-width: 575px) {
  .slick-slider {
    margin-bottom: 20px;
  }
}
.slick-slider .slick-prev {
  left: -10px;
}
.slick-slider .slick-prev:before {
  color: #000000;
}
.slick-slider .slick-next {
  right: -10px;
}
.slick-slider .slick-next:before {
  color: #000000;
}
.slick-slider img {
  width: 100%;
  object-fit: cover;
}
.slick-dots li button:before {
  display: none;
}
.gx-slick-slider .slick-dots {
  bottom: 26px;
  padding: 0 24px;
  text-align: left;
}
.gx-slick-slider .slick-dots li {
  width: 10px !important;
  height: 10px !important;
}
.gx-slick-slider .slick-dots li button {
  width: 10px !important;
  height: 10px !important;
}
.gx-slick-slider .slick-dots li button:before {
  width: 10px !important;
  height: 10px !important;
  line-height: 10px !important;
  color: transparent;
  border: solid 2px #000000;
  border-radius: 50%;
  display: block;
}
.gx-slick-slider .slick-dots li.slick-active button {
  width: 12px !important;
  height: 12px !important;
}
.gx-slick-slider .slick-dots li.slick-active button:before {
  color: transparent;
  border-color: #038fde;
  width: 12px !important;
  height: 12px !important;
  line-height: 12px !important;
}
.gx-slide-item {
  margin-left: 15px;
  margin-right: 15px;
}
@media screen and (max-width: 480px) {
  .gx-slide-item {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.gx-slider {
  position: relative;
}
.gx-slider-img {
  position: relative;
  padding-bottom: 24px;
}
.gx-slider-img .gx-img-up {
  position: absolute;
  left: 24px;
  bottom: -5px;
  z-index: 1;
  border-radius: 10px;
  width: auto;
  max-width: 64px;
}
.gx-slider-content {
  padding: 24px;
}
.gx-slick-slider {
  margin-bottom: 0;
  padding-bottom: 24px;
}
.gx-slick-slider-dot-top {
  padding-top: 70px;
  padding-right: 24px;
  padding-bottom: 58px;
}
@media (max-width: 1366px) {
  .gx-slick-slider-dot-top {
    padding-top: 40px;
    padding-bottom: 10px;
  }
}
.gx-slick-slider-dot-top .slick-dots {
  bottom: auto;
  top: 30px;
  padding-left: 0;
}
@media (max-width: 1366px) {
  .gx-slick-slider-dot-top .slick-dots {
    top: 10px;
  }
}
@media screen and (max-width: 575px) {
  .gx-slick-slider-dot-top {
    padding-left: 24px;
  }
}
.gx-slick-slider-lt-thumb {
  height: 100%;
  position: relative;
}
.gx-slick-slider-lt-thumb img {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
@media screen and (max-width: 575px) {
  .gx-slick-slider-lt-thumb img {
    position: relative;
    left: auto;
    top: auto;
  }
}
.ant-modal .slides {
  position: relative;
}
.ant-modal .slides .slick-prev,
.ant-modal .slides .slick-next {
  position: absolute;
  top: 50%;
  z-index: 1;
  margin-top: -10px;
}
.ant-modal .slides .slick-prev {
  left: 20px;
}
.ant-modal .slides .slick-next {
  right: 20px;
}
.ant-modal .slick-slider {
  margin-bottom: 0;
}
/*Tables Styles*/
.ant-table-small > .ant-table-content > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table,
.ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table {
  padding: 0;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  word-break: normal;
}
.gx-table-responsive .ant-table {
  min-height: .01%;
  overflow-x: auto;
}
@media screen and (max-width: 767px) {
  .gx-table-responsive .ant-table {
    width: 100%;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: solid 1px #e8e8e8;
  }
  .ant-card-bordered.gx-card-full .gx-table-responsive .ant-table {
    border: 0 none;
    border-bottom: solid 1px #e8e8e8;
  }
  .gx-table-responsive .ant-table .ant-table-tbody > tr:last-child > td {
    border-bottom: 0 none;
  }
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-header > table,
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-body > table,
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-fixed-left table,
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-fixed-right table {
    border-top: 0 none;
    border-left: 0 none;
  }
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-title {
    border: 0 none;
  }
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-footer {
    border: 0 none;
    border-top: solid 1px #e8e8e8;
  }
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-thead > tr > th:last-child,
  .gx-table-responsive .ant-table.ant-table-bordered .ant-table-tbody > tr > td:last-child {
    border-right: 0 none;
  }
  .gx-table-responsive .ant-table-body > table > thead > tr > th,
  .gx-table-responsive .ant-table-body > table > tbody > tr > th,
  .gx-table-responsive .ant-table-body > table > tfoot > tr > th,
  .gx-table-responsive .ant-table-body > table > thead > tr > td,
  .gx-table-responsive .ant-table-body > table > tbody > tr > td,
  .gx-table-responsive .ant-table-body > table > tfoot > tr > td {
    white-space: nowrap;
  }
}
.gx-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.gx-table th,
.gx-table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: solid 1px #e8e8e8;
}
.gx-table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e8e8e8;
}
.gx-table tbody + tbody {
  border-top: 2px solid #e8e8e8;
}
.gx-table-bordered {
  border: 1px solid #e8e8e8;
}
.gx-table-bordered th,
.gx-table-bordered td {
  border: 1px solid #e8e8e8;
}
.gx-table-bordered thead th,
.gx-table-bordered thead td {
  border-bottom-width: 2px;
}
.gx-table-no-bordered {
  border: 0 none;
}
.gx-table-no-bordered th,
.gx-table-no-bordered td {
  border: 0 none;
}
.gx-table-no-bordered thead th,
.gx-table-no-bordered thead td {
  border-bottom-width: 2px;
}
.gx-table-no-bordered .ant-table {
  border: 0 none;
  border-radius: 0;
}
.gx-table-no-bordered .ant-table-thead > tr > th {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 11px;
}
.gx-table-no-bordered .ant-table-tbody > tr > td {
  border-bottom: 0 none;
}
.gx-table-thead-uppercase thead th {
  text-transform: uppercase;
}
.gx-table-padding-lg thead th,
.gx-table-padding-lg tbody td {
  padding: 20px 30px !important;
}
@media screen and (max-width: 991px) {
  .gx-table-padding-lg thead th,
  .gx-table-padding-lg tbody td {
    padding: 15px 20px !important;
  }
}
@media screen and (max-width: 767px) {
  .gx-table-padding-lg thead th,
  .gx-table-padding-lg tbody td {
    padding: 10px !important;
  }
}
.gx-table-padding-lg .ant-table-pagination {
  float: none;
  text-align: center;
  margin: 22px 0;
}
@media screen and (max-width: 991px) {
  .gx-table-padding-lg .ant-table-pagination {
    margin: 16px 0;
  }
}
/*Time Lines Style*/
/*Antd Time Lines Style*/
.ant-timeline-item {
  padding: 0;
}
.ant-timeline-item:not(:last-child) {
  padding-bottom: 20px;
}
@media screen and (max-width: 575px) {
  .ant-timeline-item:not(:last-child) {
    padding-bottom: 15px;
  }
}
.gx-timeline-info {
  position: relative;
}
.gx-timeline-info:not(:last-child) {
  padding-bottom: 20px;
}
.gx-timeline-info-day {
  margin-bottom: 15px;
  font-size: 12px;
  color: #595959;
}
.gx-timeline-info .ant-timeline {
  padding-top: 0;
  padding-left: 0;
}
.gx-timeline-info .ant-timeline-item-head-custom {
  padding: 0;
  margin-right: 15px;
  position: relative;
  left: 0;
  top: 0;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.gx-timeline-info .ant-timeline-item {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: flex-start;
  align-items: flex-start;
}
.gx-timeline-info .ant-timeline-item-content {
  padding-left: 0;
  top: 0;
  margin-left: 0;
  flex: 1 1;
  color: #595959;
}
.gx-timeline-info .ant-timeline-item-content .gx-link {
  color: #262626;
}
.gx-timeline-info .ant-timeline-item-content .gx-link:hover,
.gx-timeline-info .ant-timeline-item-content .gx-link:focus {
  color: #038fde;
}
.gx-timeline-info .ant-timeline-item-tail {
  display: none;
}
.gx-img-more {
  width: 60px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 6px;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-align-items: center;
  align-items: center;
  -ms-justify-content: center;
  justify-content: center;
  border: solid 1px #e8e8e8;
}
/*Custom Time Lines Style*/
.gx-timeline-left {
  float: left;
  margin-right: 20px;
}
.gx-timeline-right {
  float: right;
  margin-left: 20px;
}
.gx-timeline-circle {
  border-radius: 50%;
}
.gx-timeline-section {
  margin-bottom: 25px;
  overflow: hidden;
}
.gx-timeline-item {
  padding-bottom: 25px;
  padding-left: 80px;
  position: relative;
}
.gx-timeline-item:before {
  border-left: solid 2px #e8e8e8;
  content: "";
  left: 25px;
  position: absolute;
  top: 25px;
  bottom: -20px;
  width: 2px;
  z-index: 1;
}
.gx-timeline-item:first-child:before {
  border-left-style: dashed;
}
.gx-timeline-item:last-child:before {
  border-left-style: dashed;
  bottom: 25px;
}
.gx-timeline-badge {
  height: 50px;
  width: 50px;
  position: absolute;
  left: 0;
  top: 16px;
  z-index: 2;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  border-radius: 50%;
}
.gx-timeline-badge.gx-timeline-img {
  background-color: transparent;
}
.gx-timeline-badge.gx-timeline-img:after {
  display: none;
}
.gx-timeline-badge:after {
  position: absolute;
  content: "";
  width: 42px;
  height: 42px;
  background-color: transparent;
  border: 4px solid #e8e8e8;
  border-radius: 50%;
  left: 4px;
  top: 4px;
  z-index: 1;
}
.gx-timeline-badge [class^="gx-"]:before,
.gx-timeline-badge [class*="gx-"]:before {
  line-height: 50px;
}
.gx-timeline-img > img,
.gx-timeline-header-img > img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.gx-timeline-panel {
  padding: 20px 30px;
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  border: solid 1px #e8e8e8;
}
.gx-timeline-panel:after,
.gx-timeline-panel:before {
  content: "";
  position: absolute;
  border-style: solid;
  display: inline-block;
  top: 30px;
}
.gx-timeline-panel:before {
  border-color: transparent #e8e8e8;
  border-width: 10px 17px 10px 0;
  left: -18px;
}
.gx-timeline-panel:after {
  border-color: transparent #ffffff;
  border-width: 10px 16px 10px 0;
  left: -16px;
}
.gx-timeline-no-padding {
  padding: 0;
}
.gx-timeline-panel-header {
  margin-bottom: 10px;
}
.gx-timeline-header-img {
  margin-bottom: 10px;
}
.gx-timeline-inverted .gx-timeline-left {
  float: right;
  margin-right: 0;
  margin-left: 20px;
}
.gx-timeline-heading {
  overflow: hidden;
  margin-bottom: 10px;
}
.gx-timeline-body {
  clear: both;
}
/*Gxtl Center Style*/
.gx-timeline-center .gx-timeline-item {
  clear: both;
  float: right;
  width: 50%;
  padding-left: 55px;
}
.gx-timeline-center .gx-timeline-item:before {
  left: 0;
}
.gx-timeline-center .gx-timeline-badge {
  left: -25px;
  right: auto;
}
.gx-timeline-center .gx-timeline-time {
  position: absolute;
  top: 32px;
  left: -150px;
  right: auto;
  z-index: 2;
}
.gx-timeline-center .gx-timeline-inverted {
  float: left;
  text-align: right;
  padding-left: 0;
  padding-right: 55px;
}
.gx-timeline-center .gx-timeline-inverted:before {
  border-left: 0 none;
  border-right: solid 2px #e8e8e8;
  left: auto;
  right: -2px;
}
.gx-timeline-center .gx-timeline-inverted .gx-timeline-badge {
  left: auto;
  right: -25px;
}
.gx-timeline-center .gx-timeline-inverted .gx-timeline-panel:before {
  border-width: 10px 0 10px 17px;
  left: auto;
  right: -18px;
}
.gx-timeline-center .gx-timeline-inverted .gx-timeline-panel:after {
  border-width: 10px 0 10px 16px;
  left: auto;
  right: -16px;
}
.gx-timeline-center .gx-timeline-inverted .gx-timeline-time {
  position: absolute;
  top: 32px;
  right: -150px;
  left: auto;
  z-index: 2;
}
/*Gxtl Zigzag Style*/
.gx-timeline-zigzag .gx-timeline-item {
  padding-left: 150px;
}
.gx-timeline-zigzag .gx-timeline-item + .gx-timeline-item {
  margin-top: -80px;
}
.gx-timeline-zigzag .gx-timeline-img {
  width: 90px;
  height: 90px;
  left: 5px;
}
.gx-timeline-zigzag .gx-timeline-img > img {
  width: 100%;
}
.gx-timeline-zigzag .gx-timeline-inverted .gx-timeline-img {
  right: 5px !important;
}
.gx-timeline-zigzag .gx-timeline-item:before {
  -webkit-transform: rotate(35deg);
  transform: rotate(35deg);
  top: 20px;
  bottom: 20px;
}
.gx-timeline-zigzag .gx-timeline-inverted {
  padding-left: 0;
  padding-right: 150px;
}
.gx-timeline-zigzag .gx-timeline-inverted:before {
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
}
.gx-timeline-zigzag .gx-timeline-item:first-child:before {
  border-left-style: solid;
}
.gx-timeline-zigzag .gx-timeline-item:last-child:before {
  display: none;
}
.gx-timeline-item:last-child:before {
  bottom: 25px;
}
@media screen and (max-width: 991px) {
  .gx-timeline-zigzag .gx-timeline-item + .gx-timeline-item {
    margin-top: -60px;
  }
  .gx-timeline-zigzag .gx-timeline-item:before {
    bottom: 0;
  }
  .gx-timeline-zigzag .gx-timeline-item {
    padding-left: 120px;
  }
  .gx-timeline-zigzag .gx-timeline-inverted {
    padding-right: 120px;
    padding-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .gx-timeline-center .gx-timeline-item {
    float: none;
    padding-left: 80px;
    width: auto;
  }
  .gx-timeline-center .gx-timeline-item:before {
    left: 25px;
  }
  .gx-timeline-center .gx-timeline-badge {
    left: 0;
  }
  .gx-timeline-center .gx-timeline-inverted {
    float: none;
    text-align: left;
    padding-right: 0;
  }
  .gx-timeline-center .gx-timeline-inverted:before {
    border-left: solid 2px #e8e8e8;
    border-right: 0 none;
    left: 24px;
    right: auto;
  }
  .gx-timeline-center .gx-timeline-inverted .gx-timeline-badge {
    right: auto;
    left: 0;
  }
  .gx-timeline-center .gx-timeline-inverted .gx-timeline-panel:before {
    border-width: 10px 17px 10px 0 !important;
    left: -18px;
    right: auto;
  }
  .gx-timeline-center .gx-timeline-inverted .gx-timeline-panel:after {
    border-width: 10px 16px 10px 0 !important;
    left: -16px;
    right: auto;
  }
  .gx-timeline-inverted .gx-timeline-panel-header {
    float: none;
  }
  .gx-timeline-inverted .gx-timeline-left {
    float: left;
    margin-left: 0;
    margin-right: 20px;
  }
  .gx-timeline-zigzag .gx-timeline-panel {
    max-height: none;
    min-height: 10px;
    overflow-y: visible;
  }
  .gx-timeline-zigzag .gx-timeline-item {
    padding-left: 100px;
  }
  .gx-timeline-zigzag .gx-timeline-item + .gx-timeline-item {
    margin-top: 0;
  }
  .gx-timeline-zigzag .gx-timeline-item:before {
    -webkit-transform: none;
            transform: none;
    top: 25px;
    bottom: -25px;
    left: 45px;
  }
  .gx-timeline-zigzag .gx-timeline-item:last-child:before {
    bottom: 0;
  }
  .gx-timeline-zigzag .gx-timeline-inverted:before {
    -webkit-transform: none;
            transform: none;
  }
  .gx-timeline-center .gx-timeline-time,
  .gx-timeline-center .gx-timeline-inverted .gx-timeline-time {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    margin-bottom: 6px;
  }
  .gx-timeline-center .gx-timeline-time-item .gx-timeline-panel::before,
  .gx-timeline-center .gx-timeline-time-item .gx-timeline-panel::after {
    top: 10px;
  }
}
@media screen and (max-width: 399px) {
  .gx-timeline-left {
    float: none;
    margin-right: 0;
  }
  .gx-timeline-right {
    float: none;
    margin-left: 0;
  }
  .gx-timeline-inverted .gx-timeline-left {
    float: none;
    margin-right: 0;
  }
}
/*Tabs Styles*/
.gx-tabs-half .ant-tabs-bar {
  margin-bottom: 0;
}
.gx-tabs-half .ant-tabs-nav {
  display: block;
}
.gx-tabs-half .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  width: 50%;
  text-align: center;
}
.gx-tabs-half .ant-tabs-nav .ant-tabs-ink-bar {
  width: 50%;
}
.gx-card-tabs .ant-card-head-title {
  text-transform: capitalize;
  font-size: 16px;
}
.gx-card-tabs .ant-card-extra {
  position: relative;
  z-index: 2;
}
.gx-card-tabs .ant-tabs {
  margin-top: -40px;
}
@media screen and (max-width: 575px) {
  .gx-card-tabs .ant-tabs {
    margin-top: 0;
  }
}
.gx-card-tabs .ant-tabs-tab {
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 15px;
}
.gx-card-tabs .ant-tabs-tab-active {
  font-weight: 400;
}
.gx-card-tabs .ant-tabs-nav-container {
  font-size: 16px;
  text-align: center;
}
@media screen and (max-width: 575px) {
  .gx-card-tabs .ant-tabs-nav-container {
    text-align: left;
  }
}
.gx-card-tabs .ant-card-body {
  padding-top: 4px;
}
.gx-card-tabs-right .ant-tabs-nav-container {
  text-align: right;
}
@media screen and (max-width: 575px) {
  .gx-card-tabs-right .ant-tabs-nav-container {
    text-align: left;
  }
}
/* Apps Style */
.gx-app-module {
  position: relative;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -32px -32px 0;
  flex: 1 1;
}
@media screen and (max-width: 575px) {
  .gx-app-module {
    margin: -20px -15px 0;
  }
}
.gx-container-wrap .gx-app-module {
  margin: 0;
  margin-bottom: 32px;
}
.gx-module-side {
  position: relative;
  z-index: 2;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 0 1 230px;
  min-width: 230px;
}
@media screen and (max-width: 991px) {
  .gx-module-side {
    min-width: 260px;
    background-color: #ffffff;
  }
}
.gx-module-side-header {
  padding: 10px;
  min-height: 79px;
  border-bottom: solid 1px #e8e8e8;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-justify-content: center;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  background-color: #fbfbfb;
  color: #545454;
}
@media screen and (max-width: 991px) {
  .gx-module-side-header {
    min-height: 72px;
  }
}
.gx-module-logo {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 20px;
}
.gx-module-logo i {
  font-size: 28px;
  line-height: 18px;
}
.gx-module-user-info,
.gx-module-side-content {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1;
}
.gx-module-user-detail {
  font-size: 12px;
}
.gx-module-add-task {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 24px;
}
.gx-module-add-task .ant-btn {
  margin-bottom: 0;
}
.gx-module-nav {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-module-nav li {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.gx-module-nav li .gx-link {
  padding: 5px 29px;
  color: #999999;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  text-decoration: none;
}
.gx-module-nav li .gx-link:hover,
.gx-module-nav li .gx-link:focus {
  color: #000000;
}
.gx-module-nav li .gx-link.active {
  color: #038fde;
}
.gx-module-nav li i {
  margin-right: 16px;
  font-size: 16px;
}
.gx-module-nav li span {
  display: inline-block;
  vertical-align: middle;
}
.gx-module-nav li.gx-module-nav-label {
  padding: 12px 29px;
}
.gx-module-box {
  position: relative;
  z-index: 2;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  max-width: calc(100% - 231px);
}
@media screen and (max-width: 991px) {
  .gx-module-box {
    max-width: 100%;
  }
}
.gx-module-box-header {
  padding: 10px 26px;
  min-height: 79px;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-justify-content: center;
  justify-content: center;
  background-color: #ffffff;
  border-bottom: solid 1px #e8e8e8;
}
@media screen and (max-width: 991px) {
  .gx-module-box-header {
    position: relative;
    min-height: 58px;
    padding: 5px 26px 5px 110px;
  }
}
@media screen and (max-width: 991px) {
  .gx-module-box-header .gx-drawer-btn {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    border-right: solid 1px #e8e8e8;
    height: 58px;
    width: 98px;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    -ms-justify-content: center;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    border-radius: 0;
    font-size: 22px;
    text-align: center;
  }
}
.gx-module-box-header-inner {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  -ms-justify-content: space-between;
  justify-content: space-between;
}
.gx-module-box-header-right {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  margin-left: auto;
}
.gx-module-box-content {
  background-color: #ffffff;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 auto;
}
.gx-module-box-topbar {
  padding: 8px 28px;
  min-height: 65px;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  border-bottom: solid 1px #e8e8e8;
}
.gx-module-box-topbar label.ant-checkbox-wrapper {
  margin-right: 10px;
}
.gx-module-box-topbar .ant-dropdown-trigger {
  cursor: pointer;
  margin-right: 10px;
}
.gx-module-box-topbar .ant-dropdown-trigger .icon:before {
  position: relative;
  top: 3px;
}
.gx-module-box-topbar-todo {
  padding-left: 28px;
}
@media screen and (max-width: 991px) {
  .gx-module-box-topbar {
    min-height: 58px;
    padding-left: 28px;
    padding-right: 28px;
  }
}
.gx-module-list-icon {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  padding-right: 10px;
}
.gx-module-list-icon .gx-bar-icon .gx-icon-menu:before {
  top: 3px;
  position: relative;
}
.gx-toolbar-separator {
  border-left: solid 1px #e8e8e8;
  height: 100%;
  width: 1px;
  margin: 0 12px;
}
.gx-module-list {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-module-list-item {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  padding: 15px 28px;
  z-index: 5;
  position: relative;
  cursor: pointer;
}
.gx-module-list-item:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
}
.gx-module-list-item.gx-mail-cell {
  -ms-align-items: start;
  align-items: start;
}
@media screen and (max-width: 575px) {
  .gx-module-list-item {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}
.gx-module-detail-header {
  margin-bottom: 10px;
}
@media screen and (max-width: 575px) {
  .gx-module-detail-header {
    margin-bottom: 0;
  }
}
.gx-module-list-info {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-align-items: center;
  align-items: center;
  flex: 1 1 auto;
  max-width: calc(100% - 140px);
}
@media screen and (max-width: 575px) {
  .gx-module-list-info {
    max-width: calc(100% - 100px);
  }
}
.gx-module-todo-content {
  position: relative;
  flex: 1 1 auto;
}
.gx-module-todo-content .gx-subject {
  margin-bottom: 5px;
}
.gx-module-todo-right {
  margin-left: 8px;
}
.gx-module-sidenav {
  background-color: #ffffff;
  border-right: solid 1px #e8e8e8;
  position: relative;
  z-index: 9;
}
.gx-module-sidenav .gx-chat-sidenav-main {
  max-width: 300px;
  min-width: 300px;
  width: 90%;
}
.gx-module-date {
  max-width: 160px;
}
.gx-module-detail-item {
  padding: 10px 28px;
}
.gx-task-title {
  font-size: 18px;
}
@media screen and (max-width: 991px) {
  .gx-task-title {
    font-size: 16px;
  }
}
.gx-chat-todo-avatar {
  margin-right: 15px;
}
.gx-module-side-scroll {
  height: calc(100vh - 201px) !important;
}
.framed-layout .gx-module-side-scroll {
  height: calc(100vh - 241px) !important;
}
@media screen and (max-width: 991px) {
  .gx-module-side-scroll {
    height: calc(100vh - 72px) !important;
  }
  .framed-layout .gx-module-side-scroll {
    height: calc(100vh - 72px) !important;
  }
}
.gx-module-side-scroll > div:first-child {
  overflow-y: scroll !important;
}
.gx-module-content-scroll {
  height: calc(100vh - 266px) !important;
}
.framed-layout .gx-module-content-scroll {
  height: calc(100vh - 306px) !important;
}
@media screen and (max-width: 991px) {
  .gx-module-content-scroll {
    height: calc(100vh - 238px) !important;
  }
  .framed-layout .gx-module-content-scroll {
    height: calc(100vh - 238px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-module-content-scroll {
    height: calc(100vh - 216px) !important;
  }
}
.gx-module-content-scroll > div:first-child {
  overflow-y: scroll !important;
}
.gx-todo-detail-content-scroll {
  height: calc(100vh - 319px) !important;
}
.framed-layout .gx-todo-detail-content-scroll {
  height: calc(100vh - 359px) !important;
}
@media screen and (max-width: 991px) {
  .gx-todo-detail-content-scroll {
    height: calc(100vh - 291px) !important;
  }
  .framed-layout .gx-todo-detail-content-scroll {
    height: calc(100vh - 291px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-todo-detail-content-scroll {
    height: calc(100vh - 270px) !important;
  }
}
.gx-todo-detail-content-scroll > div:first-child {
  overflow-y: scroll !important;
}
.gx-loader-view {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-justify-content: center;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  flex: 1 1 auto;
}
.gx-loader-view .ant-spin {
  margin: 0;
}
.gx-app-module .gx-loader-view {
  height: calc(100vh - 266px) !important;
}
.framed-layout .gx-app-module .gx-loader-view {
  height: calc(100vh - 306px) !important;
}
@media screen and (max-width: 991px) {
  .gx-app-module .gx-loader-view {
    height: calc(100vh - 238px) !important;
  }
  .framed-layout .gx-app-module .gx-loader-view {
    height: calc(100vh - 238px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-app-module .gx-loader-view {
    height: calc(100vh - 244px) !important;
  }
}
.gx-chat-module .gx-loader-view {
  height: calc(100vh - 122px) !important;
}
.framed-layout .gx-chat-module .gx-loader-view {
  height: calc(100vh - 162px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-chat-module .gx-loader-view {
    height: calc(100vh - 122px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-chat-module .gx-loader-view {
    height: calc(100vh - 100px) !important;
  }
}
.gx-module-default {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-justify-content: center;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  height: 100%;
  padding: 15px;
}
.gx-no-content-found {
  flex: 1 1;
  font-size: 16px;
}
.gx-task-des,
.gx-task-input {
  padding-left: 0;
}
/*Mails Apps Styles*/
.gx-module-box-row {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.gx-module-box-column,
.gx-module-list-content {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  flex: 1 1;
}
.gx-mail-list-info {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding-left: 10px;
  max-width: calc(100% - 150px);
  flex: 1 1 auto;
}
@media screen and (max-width: 575px) {
  .gx-mail-list-info {
    max-width: 100%;
    margin-top: 10px;
    padding-left: 0;
  }
}
.gx-mail-user-info {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  margin-left: 10px;
}
.gx-mail-user-info .gx-avatar {
  margin-right: 10px;
}
.gx-mail-user-info .gx-badge {
  margin-bottom: 0;
}
.gx-mail-user-des {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 3px;
}
.gx-mail-user-des .gx-time,
.gx-mail-user-des .gx-icon-btn {
  margin-left: auto;
}
.gx-mail-detail-inner {
  padding: 24px;
}
@media screen and (max-width: 991px) {
  .gx-mail-detail-inner {
    padding-right: 28px;
    padding-left: 28px;
  }
}
.gx-mail-header {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
}
@media screen and (max-width: 498px) {
  .gx-mail-header {
    display: block;
    -ms-align-items: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
.gx-mail-header-content {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.gx-mail-header-content .gx-subject {
  margin-bottom: 5px;
}
.gx-show-link {
  cursor: pointer;
  margin-bottom: 8px;
  color: #038fde;
}
.gx-show-detail {
  margin-bottom: 12px;
}
.gx-module-list-content .gx-subject {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gx-module-list-content .gx-message {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  color: #8d8d8d;
  font-size: 13px;
}
.gx-module-list-content .gx-message p {
  margin-bottom: 8px;
}
.gx-size {
  margin: 5px 0;
}
.gx-sender-name {
  margin-right: 0;
  font-weight: 500;
  font-size: 15px;
}
.gx-send-subject {
  width: calc(100% - 220px);
}
@media screen and (max-width: 575px) {
  .gx-send-subject {
    width: calc(100% - 180px);
    margin-right: 6px;
  }
}
.gx-mail-header-actions {
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  min-width: 100px;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.gx-mail-header-actions .icon-btn:not(:last-child) {
  margin-right: 3px;
}
@media screen and (max-width: 498px) {
  .gx-mail-header-actions {
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    min-width: 10px;
  }
}
.gx-attachment-block {
  position: relative;
}
.gx-attachment-row {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -10px;
}
@media screen and (max-width: 575px) {
  .gx-attachment-row {
    margin: 0 -5px;
  }
}
.gx-attachment-col {
  padding: 0 10px;
}
.gx-attachment-col img {
  width: 80px;
}
.gx-attachment-col .size {
  padding-top: 5px;
}
@media screen and (max-width: 575px) {
  .gx-attachment-col {
    padding: 0 5px;
  }
}
@media screen and (max-width: 575px) {
  .gx-mail-list {
    flex: 1 1 auto;
    max-width: 100%;
    border-right: 0 none;
  }
  .gx-mail-detail {
    flex: 1 1 auto;
    max-width: 100%;
  }
}
/*Chat Apps Styles*/
.gx-chat-module {
  position: relative;
}
.gx-chat-module-box {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  background-color: #fafafa;
  position: relative;
  width: 100%;
}
.gx-chat-sidenav {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 0 1 315px;
  min-width: 315px;
  border-right: solid 1px #e8e8e8;
}
.gx-chat-sidenav-header {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 25px 20px 12px;
  background-color: #fbfbfb;
  border-bottom: solid 1px #e8e8e8;
}
.gx-chat-user-hd {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 25px;
}
.gx-chat-search-wrapper {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 0;
}
.gx-chat-avatar {
  min-width: 50px;
}
.gx-chat-user-row .gx-chat-avatar {
  padding: 0 5px;
  min-width: 10px;
}
.gx-chat-user-hd .gx-chat-avatar {
  cursor: pointer;
}
.gx-chat-sidenav-scroll {
  height: calc(100vh - 295px) !important;
}
.framed-layout .gx-chat-sidenav-scroll {
  height: calc(100vh - 335px) !important;
}
@media screen and (max-width: 991px) {
  .gx-chat-sidenav-scroll {
    height: calc(100vh - 175px) !important;
  }
  .framed-layout .gx-chat-sidenav-scroll {
    height: calc(100vh - 175px) !important;
  }
}
.gx-chat-sidenav-scroll > div:first-child {
  overflow-y: scroll !important;
}
.gx-chat-sidenav-scroll-tab-1,
.gx-chat-sidenav-scroll-tab-2 {
  height: calc(100vh - 320px) !important;
}
.framed-layout .gx-chat-sidenav-scroll-tab-1,
.framed-layout .gx-chat-sidenav-scroll-tab-2 {
  height: calc(100vh - 360px) !important;
}
@media screen and (max-width: 991px) {
  .gx-chat-sidenav-scroll-tab-1,
  .gx-chat-sidenav-scroll-tab-2 {
    height: calc(100vh - 198px) !important;
  }
  .framed-layout .gx-chat-sidenav-scroll-tab-1,
  .framed-layout .gx-chat-sidenav-scroll-tab-2 {
    height: calc(100vh - 198px) !important;
  }
}
.gx-chat-sidenav-scroll-tab-1 > div:first-child,
.gx-chat-sidenav-scroll-tab-2 > div:first-child {
  overflow-y: scroll !important;
}
.gx-chat-list-scroll {
  height: calc(100vh - 268px) !important;
}
.framed-layout .gx-chat-list-scroll {
  height: calc(100vh - 308px) !important;
}
@media screen and (max-width: 1199px) {
  .gx-chat-list-scroll {
    height: calc(100vh - 242px) !important;
  }
  .framed-layout .gx-chat-list-scroll {
    height: calc(100vh - 282px) !important;
  }
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-chat-list-scroll {
    height: calc(100vh - 242px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-chat-list-scroll {
    height: calc(100vh - 220px) !important;
  }
}
.gx-chat-list-scroll > div:first-child {
  overflow-y: scroll !important;
}
.gx-chat-sidenav-content {
  background-color: #ffffff;
}
.gx-chat-sidenav-title {
  padding: 10px 16px;
  font-size: 14px;
  color: #1890ff;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-justify-content: center;
  justify-content: center;
}
@media screen and (max-width: 1199px) {
  .gx-chat-sidenav-title {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .gx-chat-sidenav-title {
    font-size: 15px;
  }
}
.gx-chat-tabs-header {
  background-color: #fbfbfb !important;
}
.gx-chat-user {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.gx-chat-user.gx-chat-user-center {
  -ms-justify-content: center;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-chat-user.gx-chat-user-center .gx-chat-avatar {
  margin-left: auto;
}
.gx-chat-user-item {
  padding: 16px;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-justify-content: center;
  justify-content: center;
  cursor: pointer;
  max-height: 96px;
}
.gx-chat-user-item:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
}
.gx-chat-user-item.active,
.gx-chat-user-item:hover {
  background-color: #e6faff;
}
.gx-chat-user-row {
  margin: 0 -5px;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
}
.gx-chat-info {
  max-width: calc(100% - 80px);
  padding: 0 5px;
  flex: 1 1;
}
.gx-chat-info p {
  margin-bottom: 0;
}
.gx-chat-info .h4 {
  display: block;
  margin-bottom: 3px;
}
.gx-chat-info-des {
  color: #595959;
  font-size: 13px;
}
.gx-chat-contact-col {
  max-width: calc(100% - 50px);
  padding: 0 5px;
  flex: 1 1;
}
.gx-chat-contact-col p {
  margin-bottom: 0;
}
.gx-chat-contact-col .h4 {
  display: block;
  margin-bottom: 3px;
}
.gx-chat-date {
  padding: 0 5px;
  text-align: right;
}
.gx-fs-80 {
  font-size: 80px !important;
  line-height: 80px !important;
}
.gx-comment-box .gx-fs-80 {
  text-align: center;
  margin-bottom: 12px;
}
@media screen and (max-width: 575px) {
  .gx-fs-80 {
    font-size: 60px !important;
    line-height: 60px !important;
  }
}
.gx-comment-box {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-justify-content: center;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  flex: 1 1 auto;
  height: calc(100vh - 122px) !important;
}
.framed-layout .gx-comment-box {
  height: calc(100vh - 162px) !important;
}
@media screen and (max-width: 991px) {
  .framed-layout .gx-comment-box {
    height: calc(100vh - 122px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-comment-box {
    height: calc(100vh - 100px) !important;
  }
}
.gx-chat-box {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
  z-index: 2;
}
.gx-chat-main {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.gx-chat-main-header {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  border-bottom: solid 1px #e8e8e8;
  padding: 16px;
  background-color: #ffffff;
  width: 100%;
}
@media screen and (max-width: 1199px) {
  .gx-chat-main-header {
    padding: 8px 16px;
  }
}
@media screen and (max-width: 991px) {
  .gx-chat-main-header {
    padding: 8px 30px;
  }
}
.gx-chat-main-header-info {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  margin-right: auto;
}
.gx-chat-contact-name {
  font-size: 20px;
  font-weight: 500;
}
@media screen and (max-width: 1199px) {
  .gx-chat-contact-name {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .gx-chat-contact-name {
    font-size: 15px;
  }
}
.gx-chat-main-footer {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border-top: solid 1px #e8e8e8;
  padding: 6px 16px;
  background-color: #ffffff;
  width: 100%;
}
.gx-chat-main-footer .gx-form-group {
  margin-bottom: 0;
}
.gx-chat-item {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 16px;
}
@media screen and (max-width: 991px) {
  .gx-chat-item {
    padding: 16px 30px;
  }
}
.gx-chat-item .gx-bubble-block {
  width: calc(100% - 40px);
}
.gx-chat-item .gx-bubble {
  padding: 12px 15px;
  background-color: #ffffff;
  border-radius: 20px 20px 20px 0px;
  position: relative;
  margin-left: 16px;
  max-width: 600px;
  border: solid 1px #d9d9d9;
  display: inline-block;
}
.gx-chat-item.gx-flex-row-reverse .gx-bubble-block {
  text-align: right;
}
.gx-chat-item.gx-flex-row-reverse .gx-bubble {
  margin-right: 16px;
  margin-left: 0;
  background-color: #e6faff;
  border-radius: 20px 20px 0 20px;
  text-align: left;
}
.gx-chat-item .ant-avatar {
  display: block;
}
.gx-chat-btn {
  font-size: 38px !important;
  margin-right: 20px;
}
textarea.gx-chat-textarea {
  height: 40px;
  box-shadow: none;
  border-radius: 0;
}
.gx-chat-sent {
  min-width: 40px;
  line-height: 40px;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}
.gx-last-message-time {
  font-size: 11px;
  color: #8c8c8c;
}
@media screen and (max-width: 1199px) {
  .gx-chat-main-header .gx-size-60 {
    height: 50px !important;
    width: 50px !important;
    line-height: 50px;
  }
  .gx-chat-main-header button {
    margin-bottom: 0;
  }
  .gx-chat-main-header-info .gx-status-pos {
    max-width: 50px;
  }
}
@media screen and (max-width: 575px) {
  .gx-module-default h1 {
    font-size: 17px;
  }
}
/*Contact Apps Styles*/
.gx-contact-item {
  -ms-align-items: center;
  align-items: center;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px 28px;
}
.gx-contact-item:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
}
.gx-dragndrop-item {
  padding: 24px 16px;
}
.gx-dragndrop-item .gx-draggable-icon {
  margin-left: -16px;
}
@media screen and (max-width: 575px) {
  .gx-dragndrop-item {
    padding-right: 5px;
  }
}
.gx-contact-list-info {
  padding-left: 10px;
}
.gx-contact-list-info .gx-email {
  word-break: break-all;
}
.gx-contact-list-info .gx-toolbar-separator {
  display: inline-block;
}
@media screen and (max-width: 575px) {
  .gx-contact-list-info .gx-toolbar-separator {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .gx-contact-list-info {
    padding-left: 0;
    max-width: calc(100% - 50px);
  }
  .gx-contact-list-info .gx-job-title {
    display: none;
  }
}
.gx-module-contact-content {
  flex: 1 1;
}
.gx-module-contact-right {
  margin-left: 8px;
}
.gx-contact-name {
  font-size: 15px;
}
.gx-draggable-icon {
  cursor: all-scroll;
  color: #8c8c8c;
}
.gx-draggable-icon:hover,
.gx-draggable-icon:focus,
.gx-draggable-icon:active {
  cursor: all-scroll;
  color: #262626;
}
.gx-module-side-nav {
  padding: 20px 0;
}
.gx-modal-box-row {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.gx-modal-box-row .gx-modal-box-avatar {
  -ms-order: 2;
  order: 2;
  margin-left: 16px;
}
.gx-modal-box-row .gx-modal-box-avatar .ant-avatar {
  width: 120px;
  height: 120px;
}
@media screen and (max-width: 575px) {
  .gx-modal-box-row .gx-modal-box-avatar {
    -ms-order: 1;
    order: 1;
    margin-left: 0;
    margin-bottom: 16px;
    text-align: center;
  }
}
.gx-modal-box-row .gx-modal-box-form-item {
  -ms-order: 1;
  order: 1;
  flex: 1 1;
}
@media screen and (max-width: 575px) {
  .gx-modal-box-row .gx-modal-box-form-item {
    -ms-order: 2;
    order: 2;
  }
}
@media screen and (max-width: 575px) {
  .gx-modal-box-row {
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
.gx-dragndrop-actions {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
/* Calendar Apps Style */
.gx-rbc-calendar {
  overflow: hidden;
  padding-bottom: 32px;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
@media screen and (max-width: 575px) {
  .gx-rbc-calendar {
    margin-bottom: 20px;
  }
}
.gx-rbc-calendar .rbc-calendar {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  height: 100%;
}
.rbc-event {
  background-color: #038fde;
}
.rbc-event.rbc-selected {
  background-color: #006eb8;
}
.rbc-slot-selection {
  background-color: #7ddcff;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  color: #ffffff;
  background-image: none;
  box-shadow: none;
  background-color: #52c6f7;
  border-color: #038fde;
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus,
.rbc-toolbar button:focus,
.rbc-toolbar button:hover {
  color: #ffffff;
  background-color: #52c6f7;
  border-color: #038fde;
}
.rbc-month-row {
  min-height: 100px;
}
.rbc-event {
  min-height: 50px;
}
.rbc-btn-group button {
  cursor: pointer;
}
@media screen and (max-width: 867px) {
  .rbc-toolbar {
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    -ms-align-items: flex-start;
    align-items: flex-start;
  }
  .rbc-toolbar .rbc-toolbar-label {
    margin: 8px 0;
  }
}
@media screen and (max-width: 467px) {
  .rbc-toolbar {
    font-size: 12px;
  }
  .rbc-toolbar button {
    padding: 5px 10px;
  }
}
/* Calendar Components Style */
.gx-com-calendar .ant-fullcalendar-header .ant-select {
  margin-left: 8px;
  margin-bottom: 8px;
}
@media screen and (max-width: 349px) {
  .gx-com-calendar {
    margin: 0 -20px;
  }
}
.gx-com-calendar-card {
  border: solid 1px #e8e8e8;
  max-width: 300px;
  border-radius: 4px;
}
@media screen and (max-width: 349px) {
  .gx-com-calendar-card .gx-com-calendar {
    margin: 0;
  }
}
.gx-profileon {
  margin-bottom: 20px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.gx-profileon-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px 20px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
}
.gx-profileon-content * {
  color: #ffffff;
}
.gx-agents-list .gx-profileon-content {
  padding: 10px;
}
.gx-profileon-thumb {
  position: relative;
}
.gx-profileon-thumb > img {
  width: 100%;
}
.gx-profileon-thumb-htctrcrop {
  overflow: hidden;
  padding-bottom: 80%;
  position: relative;
}
.gx-profileon-thumb-htctrcrop > img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 100%;
}
.gx-profileon-title {
  margin-bottom: 5px;
}
.gx-agents-list .gx-profileon {
  margin-bottom: 0;
}
.gx-follower {
  border-bottom: solid 1px #d9d9d9;
  padding-bottom: 20px;
  margin-bottom: 15px;
}
.gx-follower-list {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin: 0 -5px;
}
.gx-profile-banner .gx-follower-list {
  margin: 0 -20px;
}
.gx-follower-list li {
  color: #8c8c8c;
  font-size: 14px;
  padding: 0 5px;
  width: 33.33%;
}
.gx-profile-banner .gx-follower-list li {
  color: #ffffff;
  padding: 0 20px;
  width: auto;
}
.gx-follower-list li:not(:first-child) {
  border-left: solid 1px #d9d9d9;
}
.gx-profile-banner .gx-follower-list li:not(:first-child) {
  border-left-color: #ffffff;
}
.gx-follower-title {
  display: block;
  color: #262626;
  font-size: 14px;
}
.gx-profile-banner .gx-follower-title {
  color: #ffffff;
}
.gx-wall-scroll {
  height: calc(100vh - 154px) !important;
}
.framed-layout .gx-wall-scroll {
  height: calc(100vh - 192px) !important;
}
@media screen and (max-width: 991px) {
  .gx-wall-scroll {
    height: calc(100vh - 124px) !important;
  }
  .framed-layout .gx-wall-scroll {
    height: calc(100vh - 124px) !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-wall-scroll {
    height: calc(100vh - 132px) !important;
  }
}
.gx-wall-postlist {
  padding: 5px;
}
.gx-entry-sec {
  position: relative;
  margin-bottom: 20px;
}
.ant-card .gx-entry-sec,
.gx-card .gx-entry-sec {
  margin-bottom: 0;
}
.gx-entry-title {
  border-bottom: solid 1px #d9d9d9;
  padding-bottom: 15px;
  margin-bottom: 20px;
  font-size: 16px;
  position: relative;
}
.gx-entry-title:before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  z-index: 1;
  height: 3px;
  width: 42px;
  background-color: #038fde;
}
.ant-card .gx-entry-title:before,
.gx-card .gx-entry-title:before {
  display: none;
}
.ant-card .gx-entry-title,
.gx-card .gx-entry-title {
  border-bottom: 0 none;
  margin-bottom: 15px;
  padding-bottom: 0;
}
.gx-fnd-list {
  list-style: none;
  padding-left: 0;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -5px 20px;
}
.gx-fnd-list li {
  width: 33.33%;
  padding: 0 5px;
}
@media (max-width: 399px) {
  .gx-fnd-list li {
    width: 50%;
  }
}
.gx-user-fnd {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.gx-user-fnd > img {
  width: 100%;
}
.gx-user-fnd-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.95));
  color: #ffffff;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.gx-user-fnd-content h6 {
  color: #ffffff;
  align-self: flex-end;
  margin-bottom: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gx-user-fnd-content .ant-badge {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  margin: 0;
  width: 10px;
  height: 10px;
  display: block;
}
.gx-user-fnd-content .ant-badge-status-dot {
  width: 10px;
  height: 10px;
  display: block;
  top: 0;
  border: solid 1px #ffffff;
}
.gx-gallery-list {
  list-style: none;
  padding-left: 0;
  margin: 0 -0.5px 15px;
  border-radius: 10px;
  overflow: hidden;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.gx-gallery-list li {
  padding: 0 0.5px;
  margin-bottom: 1px;
  width: 33.33%;
}
@media (max-width: 399px) {
  .gx-gallery-list li {
    width: 50%;
  }
}
.gx-gallery-list li > img {
  width: 100%;
}
.gx-gallery-thumb {
  position: relative;
  cursor: pointer;
}
.gx-gallery-thumb:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.01);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.95));
}
.gx-gallery-thumb-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  padding: 10px;
  color: #ffffff;
}
.gx-gallery-thumb-content h6 {
  color: #ffffff;
  margin-bottom: 0;
  font-size: 10px;
}
.gx-wall-user-info {
  margin-bottom: 6px;
}
.gx-wall-user-title {
  margin-bottom: 4px;
}
.gx-gallery-item {
  position: relative;
  margin-bottom: 15px;
}
.gx-gallery-item img {
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
}
.gx-gallery-item-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-justify-content: center;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  color: #ffffff;
}
.gx-gallery-grid {
  margin: 0 -5px;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.gx-gallery-grid .gx-gallery-item {
  padding: 0 5px;
}
.gx-gallery-grid .gx-gallery-item-content {
  left: 5px;
  right: 5px;
}
.gx-gallery-2 .gx-gallery-item,
.gx-gallery-4 .gx-gallery-item,
.gx-gallery-4-more .gx-gallery-item {
  width: 50%;
}
.gx-gallery-3 .gx-gallery-item {
  width: 33.33%;
}
.gx-wall-comment-box {
  padding: 0 30px;
}
@media screen and (max-width: 1199px) {
  .gx-wall-comment-box {
    padding: 0 20px;
  }
}
@media screen and (max-width: 1100px) {
  .gx-wall-comment-box {
    padding: 0;
  }
}
@media screen and (max-width: 991px) {
  .gx-wall-comment-box {
    padding: 0 30px;
  }
}
@media screen and (max-width: 767px) {
  .gx-wall-comment-box {
    padding-right: 0;
    padding-left: 15px;
  }
}
.gx-profile-banner {
  padding: 32px;
  padding-bottom: 88px;
  margin: -32px;
  margin-bottom: -58px;
  background-color: #fffffe;
  color: #ffffff;
  position: relative;
}
@media screen and (max-width: 575px) {
  .gx-profile-banner {
    padding: 20px 15px;
    padding-bottom: 83px;
    margin: -20px -15px;
    margin-bottom: -58px;
  }
}
.gx-profile-banner:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.gx-profile-banner > div {
  position: relative;
  z-index: 2;
}
.gx-profile-banner-top {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 40px;
}
@media (max-width: 575px) {
  .gx-profile-banner-top {
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 25px;
  }
}
.gx-profile-banner-top-left {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
}
@media (max-width: 575px) {
  .gx-profile-banner-top-left {
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 25px;
  }
}
.gx-profile-banner-avatar {
  margin-right: 1.5rem;
}
@media (max-width: 575px) {
  .gx-profile-banner-avatar {
    margin-bottom: 8px;
    margin-right: 0;
  }
}
.gx-profile-banner-top-right {
  text-align: center;
  margin-left: auto;
}
@media (max-width: 575px) {
  .gx-profile-banner-top-right {
    margin-left: 0;
  }
}
.gx-profile-banner-bottom {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
}
@media (max-width: 575px) {
  .gx-profile-banner-bottom {
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .gx-profile-banner-bottom .gx-tab-list {
    margin-bottom: 10px;
  }
}
.gx-profile-banner h1,
.gx-profile-banner h2,
.gx-profile-banner h3,
.gx-profile-banner h4,
.gx-profile-banner h5,
.gx-profile-banner h6,
.gx-profile-banner .h1,
.gx-profile-banner .h2,
.gx-profile-banner .h3,
.gx-profile-banner .h4,
.gx-profile-banner .h5,
.gx-profile-banner .h6 {
  color: #ffffff;
}
.gx-profile-banner .gx-link {
  color: #ffffff;
}
.gx-profile-banner .gx-link:hover,
.gx-profile-banner .gx-link:focus {
  color: #fa8c15;
}
.gx-profile-content {
  position: relative;
  z-index: 2;
}
.gx-profile-setting {
  margin-left: auto;
}
@media (max-width: 575px) {
  .gx-profile-setting {
    margin-left: 0;
  }
}
@media (max-width: 575px) {
  .gx-profile-content-right {
    display: -ms-flex !important;
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    -ms-align-items: center !important;
    align-items: center !important;
  }
  .gx-profile-content-right .gx-text-truncate {
    width: auto;
    margin-left: auto;
  }
}
.gx-pro-contact-list:not(:last-child) {
  margin-bottom: 1rem;
}
/*Callout Styles*/
/*Different size images set in Equal block height and width*/
.gx-thumb-equal,
.gx-grid-thumb-equal {
  position: relative;
  padding-bottom: 68%;
  height: 0;
  width: 100%;
  overflow: hidden;
}
.gx-thumb-cover,
.gx-grid-thumb-cover {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
}
.gx-thumb-cover-img,
.gx-grid-thumb-cover img {
  height: auto;
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
  width: 100%;
}
.gx-product-image .gx-grid-thumb-equal {
  padding-bottom: 82%;
}
/*Product listing page styles*/
.gx-product-row {
  position: relative;
  overflow: hidden;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  -ms-justify-content: center;
  justify-content: center;
  text-align: right;
}
.gx-product-row.even {
  text-align: left;
}
.gx-product-col {
  order: 1;
  width: 50%;
}
.odd .gx-product-thumb {
  order: 2;
}
.gx-product-content {
  padding: 20px 55px;
}
.gx-product-content p {
  margin-bottom: 10px;
}
.gx-product-row .gx-grid-thumb-equal {
  padding-bottom: 69%;
}
.gx-product-thumb img {
  width: 100%;
}
.gx-product-content h4 {
  margin-bottom: 25px;
}
@media screen and (max-width: 1230px) {
  .gx-product-row .gx-grid-thumb-equal {
    padding-bottom: 71%;
  }
}
@media screen and (max-width: 1199px) {
  .gx-product-row {
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: center;
  }
  .gx-product-row.even {
    text-align: center;
  }
  .gx-product-content h4 {
    margin-bottom: 10px;
  }
  .gx-product-content {
    padding: 20px 35px;
  }
  .gx-product-col,
  .odd .gx-product-thumb {
    order: 1;
    width: 100%;
  }
  .gx-product-row .gx-grid-thumb-equal {
    padding-bottom: 40%;
  }
}
@media screen and (max-width: 991px) {
  .gx-product-row {
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: right;
  }
  .gx-product-row.even {
    text-align: left;
  }
  .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 120%;
  }
  .gx-product-col,
  .odd .gx-product-thumb {
    order: 1;
    width: 50%;
  }
  .odd .gx-product-thumb {
    order: 2;
  }
  .gx-product-row .gx-grid-thumb-equal {
    padding-bottom: 70%;
  }
}
@media screen and (max-width: 767px) {
  .gx-product-row {
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: center;
  }
  .gx-product-row.even {
    text-align: center;
  }
  .gx-product-row:last-child .gx-product-col {
    padding-bottom: 0;
  }
  .gx-product-row:last-child .ant-btn {
    margin-bottom: 0;
  }
  .gx-product-content {
    padding: 20px 0;
  }
  .gx-product-col,
  .odd .gx-product-thumb {
    order: 1;
    width: 100%;
  }
  .gx-product-row .gx-grid-thumb-equal {
    padding-bottom: 44%;
  }
  .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 44%;
  }
}
/*E-commerce Styles*/
.gx-product-item {
  overflow: hidden;
  background: #fff;
  border-radius: 12px;
  margin-bottom: 32px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
}
@media screen and (max-width: 575px) {
  .gx-product-item {
    margin-bottom: 20px;
  }
}
.gx-product-body {
  padding: 24px;
}
.gx-product-body + .gx-product-footer {
  padding-top: 0;
}
.gx-product-body :last-child {
  margin-bottom: 0;
}
.gx-product-image > img {
  width: 100%;
}
.gx-product-footer {
  padding: 24px;
  padding-bottom: 15px;
}
.gx-product-horizontal {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
}
.gx-product-horizontal .gx-product-image {
  width: 25%;
}
@media screen and (max-width: 1199px) {
  .gx-product-horizontal .gx-product-image {
    width: 33.33333%;
  }
}
@media screen and (max-width: 991px) {
  .gx-product-horizontal .gx-product-image {
    width: 25%;
  }
}
@media screen and (max-width: 767px) {
  .gx-product-horizontal .gx-product-image {
    width: 100%;
  }
}
.gx-product-horizontal .gx-product-body {
  width: 53%;
}
@media screen and (min-width: 1400px) {
  .gx-product-horizontal .gx-product-body {
    width: 55%;
  }
}
@media screen and (max-width: 1199px) {
  .gx-product-horizontal .gx-product-body {
    width: 41.66667%;
  }
}
@media screen and (max-width: 991px) {
  .gx-product-horizontal .gx-product-body {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .gx-product-horizontal .gx-product-body {
    width: 100%;
  }
}
.gx-product-horizontal .gx-product-body + .gx-product-footer {
  padding-top: 24px;
}
@media screen and (max-width: 767px) {
  .gx-product-horizontal .gx-product-body + .gx-product-footer {
    padding-top: 0;
  }
}
.gx-product-horizontal .gx-product-footer {
  width: 22%;
  text-align: center;
}
@media screen and (min-width: 1400px) {
  .gx-product-horizontal .gx-product-footer {
    width: 20%;
  }
}
.gx-product-horizontal .gx-product-footer .ant-btn:not(:last-child) {
  margin-left: 8px;
  margin-right: 8px;
}
@media screen and (max-width: 767px) {
  .gx-product-horizontal .gx-product-footer .ant-btn:not(:last-child) {
    margin-left: 0;
  }
}
.gx-product-horizontal .gx-product-footer .ant-btn:last-child {
  margin-left: 8px;
  margin-right: 8px;
}
@media screen and (max-width: 767px) {
  .gx-product-horizontal .gx-product-footer {
    width: 100%;
    text-align: left;
  }
}
.gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
  padding-bottom: 82%;
}
@media screen and (min-width: 1400px) {
  .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 55%;
  }
}
@media screen and (max-width: 1199px) {
  .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 80%;
  }
}
@media screen and (max-width: 1080px) {
  .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 90%;
  }
}
@media screen and (max-width: 1000px) {
  .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 110%;
  }
  .horizontal-layout .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 80%;
  }
}
@media screen and (max-width: 991px) {
  .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 88%;
  }
  .horizontal-layout .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 128%;
  }
}
@media screen and (max-width: 849px) {
  .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 110%;
  }
}
@media screen and (max-width: 767px) {
  .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 44%;
  }
  .horizontal-layout .gx-product-horizontal .gx-product-image .gx-grid-thumb-equal {
    padding-bottom: 44%;
  }
}
.gx-product-vertical {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: calc(100% - 32px);
}
@media screen and (max-width: 575px) {
  .gx-product-vertical {
    height: calc(100% - 20px);
  }
}
.gx-product-vertical .gx-product-body {
  flex: 1 1;
}
.gx-product-title {
  font-size: 20px;
}
/*Pricing Tables Styles*/
.gx-price-tables {
  position: relative;
}
.gx-package {
  position: relative;
  margin-bottom: 15px;
  overflow: hidden;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  transition: all 0.2s ease-in-out;
  height: calc(100% - 15px);
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}
.gx-package:hover,
.gx-package:focus,
.gx-package:active {
  -webkit-transform: scale(1);
  transform: scale(1);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.55);
}
.gx-pt-dark .gx-package:hover,
.gx-pt-dark .gx-package:focus,
.gx-pt-dark .gx-package:active {
  border-radius: 20px;
}
.gx-pt-classic .gx-package {
  border: 0 none;
  border-radius: 0;
}
.gx-package.gx-highlight {
  -webkit-transform: scale(1);
  transform: scale(1);
  z-index: 2;
}
.gx-package-header {
  padding: 30px;
  text-align: center;
}
.gx-package-header > .gx-price {
  display: inline-block;
  font-size: 36px;
  font-weight: 500;
  margin-left: 0;
  margin-bottom: 12px;
  color: #ffffff;
}
.gx-package-header > .gx-price > i {
  display: inline-block;
  margin-right: 6px;
  font-weight: bold;
}
.gx-package-header h5 {
  color: inherit;
}
.gx-package-header.gx-text-black > .gx-price {
  color: #595959 !important;
}
@media only screen and (max-width: 991px) {
  .gx-package-header {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.gx-package-body {
  padding: 50px 30px;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1;
  -ms-align-items: center;
  align-items: center;
}
@media only screen and (max-width: 991px) {
  .gx-package-body {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.gx-package-items {
  list-style: none;
  font-size: 14px;
  margin: 0 0 20px;
  padding-left: 0;
  flex: 1 1;
}
.gx-package-items li {
  margin-bottom: 16px;
  zoom: 1;
  line-height: inherit;
}
.gx-package-items li::before,
.gx-package-items li::after {
  display: table;
  content: '';
}
.gx-package-items li::after {
  clear: both;
}
.gx-package-items li::before,
.gx-package-items li::after {
  display: table;
  content: '';
}
.gx-package-items li::after {
  clear: both;
}
.gx-package-items li i {
  float: left;
  min-width: 20px;
  margin-right: 16px;
  line-height: inherit;
}
.gx-package-items li span {
  display: block;
  overflow: hidden;
}
.gx-package-footer {
  padding-top: 30px;
}
.gx-package-footer .ant-btn {
  text-transform: capitalize;
  border: 0 none;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .gx-package:hover,
  .gx-package:focus,
  .gx-package:active,
  .gx-package.gx-highlight {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.gx-text-black .gx-text-white {
  color: #595959 !important;
}
.gx-pt-circle .gx-package-header {
  border-radius: 50%;
  height: 224px;
  width: 224px;
  margin: 40px auto 0;
  -ms-align-items: center;
  align-items: center;
}
.gx-pt-circle .gx-package-header .gx-price {
  margin-top: 30px;
}
@media only screen and (max-width: 991px) {
  .gx-pt-circle .gx-package-header .gx-price {
    font-size: 26px;
    margin-top: 12px;
    margin-bottom: 6px;
  }
}
@media only screen and (max-width: 991px) {
  .gx-pt-circle .gx-package-header .gx-letter-spacing-base {
    letter-spacing: 1px;
  }
}
@media only screen and (max-width: 991px) {
  .gx-pt-circle .gx-package-header {
    height: 150px;
    width: 150px;
  }
}
/*Login Styles*/
.gx-login-container {
  position: relative;
  height: 100%;
  padding-bottom: 30px;
  flex: 1 1;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-login-content {
  max-width: 420px;
  width: 94%;
  margin: auto;
  padding: 35px 35px 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  font-size: 14px;
}
.gx-login-content .ant-input {
  background-color: #f5f5f5;
  height: auto;
  padding: 6px 12px;
}
.gx-login-content .ant-input:focus {
  box-shadow: none;
  border-color: #038fde;
}
.gx-login-content .ant-form-item-control-wrapper {
  width: 100%;
}
.gx-login-content .ant-form-item-children {
  display: block;
}
@media screen and (max-width: 575px) {
  .gx-login-content {
    padding: 20px 20px 10px;
  }
}
.gx-login-header {
  margin-bottom: 30px;
}
@media screen and (max-width: 575px) {
  .gx-login-header {
    margin-bottom: 15px;
  }
}
.gx-app-login-wrap {
  height: 100%;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-justify-content: center;
  justify-content: center;
  overflow-x: hidden;
}
@media screen and (max-width: 575px) {
  .gx-app-login-wrap {
    padding-top: 20px;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }
}
.gx-app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto;
}
@media screen and (max-width: 575px) {
  .gx-app-login-container {
    padding-bottom: 20px ;
  }
}
.gx-app-login-container .gx-loader-view {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  z-index: 2;
}
.gx-app-login-main-content {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #ffffff;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  font-size: 14px;
  overflow: hidden;
}
.gx-app-login-content {
  padding: 35px 35px 20px;
  width: 60%;
}
.gx-app-login-content .ant-input {
  background-color: #f5f5f5;
}
.gx-app-login-content .ant-input:focus {
  box-shadow: none;
  border-color: #038fde;
}
.gx-app-login-content .gx-btn {
  padding: 6px 35px !important;
  height: auto;
}
.gx-app-login-content .ant-form-item-control-wrapper {
  width: 100%;
}
.gx-app-login-content .ant-form-item-children {
  display: block;
}
@media screen and (max-width: 575px) {
  .gx-app-login-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
.gx-app-login-header {
  margin-bottom: 30px;
}
@media screen and (max-width: 575px) {
  .gx-app-login-header {
    margin-bottom: 15px;
  }
}
.gx-app-logo-content {
  color: #ffffff;
  padding: 35px 35px 20px;
  width: 40%;
  position: relative;
  overflow: hidden;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.gx-app-logo-content > * {
  position: relative;
  z-index: 2;
}
.gx-app-logo-content h1 {
  color: #ffffff;
}
@media screen and (max-width: 575px) {
  .gx-app-logo-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
.gx-app-logo-content-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.gx-app-logo-content-bg:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  right: 0;
  bottom: 0;
  background-color: rgba(3, 143, 222, 0.7);
}
.gx-app-logo-content-bg img {
  width: 100%;
  height: 100%;
}
.gx-app-logo-wid {
  margin-bottom: auto;
}
.gx-app-social-block {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  -ms-justify-content: space-between;
  justify-content: space-between;
}
.gx-app-social-block .gx-social-link,
.gx-app-social-block .gx-social-link li {
  margin: 0;
}
.gx-app-social-block .gx-social-link span {
  border: solid 1px #038fde;
  border-radius: 50%;
  color: #038fde;
}
.gx-app-social-block .gx-social-link span:hover,
.gx-app-social-block .gx-social-link span:focus {
  color: #ffffff;
  background-color: #038fde;
}
.gx-app-social-block p {
  margin-bottom: 0;
}
/*Dashboard Styles*/
.ant-list-item-meta {
  flex-wrap: wrap;
}
.gx-task-list-item {
  position: relative;
}
.gx-task-list-item:not(:last-child) {
  margin-bottom: 15px;
}
.gx-card-ticketlist .gx-task-list-item:not(:last-child) {
  margin-bottom: 20px;
}
.gx-card-ticketlist .gx-task-list-item .gx-hover {
  display: none;
}
@media screen and (max-width: 575px) {
  .gx-card-ticketlist .gx-task-list-item .gx-hover {
    display: block;
  }
}
@media screen and (max-width: 575px) {
  .gx-card-ticketlist .gx-task-list-item .gx-nonhover {
    display: none;
  }
}
.gx-card-ticketlist .gx-task-list-item:hover .gx-hover {
  display: inline-block;
}
.gx-card-ticketlist .gx-task-list-item:hover .gx-nonhover {
  display: none;
}
.gx-card-ticketlist .gx-task-list-item:hover .gx-task-item-title {
  color: #038fde;
}
.gx-task-list-item:hover {
  cursor: pointer;
}
.gx-task-list-item:hover .gx-text-hover {
  color: #038fde;
}
.gx-task-item-content {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  -ms-justify-content: space-between;
  justify-content: space-between;
  max-width: calc(100% - 40px);
}
.gx-task-item-content-left {
  width: calc(100% - 250px);
}
@media screen and (max-width: 575px) {
  .gx-task-item-content-left {
    width: 100%;
    margin-bottom: 10px;
    padding-right: 60px;
  }
}
.gx-card-ticketlist .gx-task-item-content-left {
  width: calc(100% - 100px);
}
@media screen and (max-width: 575px) {
  .gx-card-ticketlist .gx-task-item-content-left {
    width: 100%;
    padding-right: 0;
  }
}
.gx-task-item-content-right {
  width: 250px;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-align-items: center;
  align-items: center;
  padding-left: 10px;
}
@media screen and (max-width: 575px) {
  .gx-task-item-content-right {
    width: 100%;
    padding-left: 0;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }
}
.gx-card-ticketlist .gx-task-item-content-right {
  padding-top: 8px;
  width: 100px;
}
@media screen and (max-width: 575px) {
  .gx-card-ticketlist .gx-task-item-content-right {
    width: 100%;
    padding-top: 0;
  }
}
.gx-card-ticketlist .gx-task-item-content {
  max-width: calc(100% - 52px);
}
.gx-task-item-content .gx-task-date {
  min-width: 55px;
  text-align: right;
}
.gx-task-item-content .ant-tag {
  min-width: 60px;
  text-align: center;
}
@media screen and (max-width: 575px) {
  .gx-task-item-content {
    flex-direction: column;
    position: relative;
  }
  .gx-task-item-content .gx-task-date {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .gx-card-ticketlist .gx-task-item-content {
    padding-right: 0;
  }
}
.gx-card-ticketlist .gx-link {
  color: #038fde;
}
.gx-card-testimonial {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 12px;
}
.gx-card-testimonial-img {
  margin-right: 10px;
  margin-bottom: 10px;
}
.gx-card-testimonial-content {
  background-color: #f5f5f5;
  padding: 15px;
  position: relative;
  margin-left: 10px;
  width: calc(100% - 70px);
  border-radius: 4px;
}
.gx-card-testimonial-content:before {
  content: "";
  position: absolute;
  left: -10px;
  top: 10px;
  z-index: 1;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 10px solid #f5f5f5;
}
.gx-card-testimonial-content > :last-child {
  margin-bottom: 0;
}
.gx-progress-task {
  position: relative;
}
.gx-progress-task :last-child {
  margin-bottom: 0;
}
.gx-progress-task-list {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 15px;
}
.gx-progress-task-list + .gx-progress-task-list {
  border-top: solid 1px #e8e8e8;
  padding-top: 15px;
}
.gx-progress-task-list .ant-progress {
  margin-bottom: 15px;
}
.gx-progress-task-list-content {
  margin-left: 20px;
  margin-bottom: 15px;
}
@media screen and (max-width: 575px) {
  .gx-progress-task-list {
    margin-bottom: 0;
  }
}
.gx-act-task {
  position: relative;
}
.gx-act-task-cell {
  margin-bottom: 20px;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  -ms-justify-content: space-between;
  justify-content: space-between;
}
@media screen and (max-width: 575px) {
  .gx-act-task-cell {
    margin-bottom: 10px;
  }
}
.gx-act-task-content {
  width: calc(100% - 72px);
}
.gx-act-task-content label.ant-checkbox-wrapper span {
  display: block;
  overflow: hidden;
}
.gx-act-task-content label.ant-checkbox-wrapper span.ant-checkbox {
  float: left;
  padding: 6px 5px 0 0;
}
.gx-act-task-btn-view {
  margin-left: 8px;
  width: 65px;
  text-align: right;
}
@media screen and (max-width: 575px) {
  .gx-act-task-btn-view {
    margin-left: 0;
    margin-top: 10px;
  }
}
.gx-line-indicator {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
.gx-line-indicator li:not(:last-child) {
  margin-bottom: 10px;
}
@media screen and (max-width: 575px) {
  .gx-line-indicator li:not(:last-child) {
    margin-bottom: 5px;
  }
}
.gx-line-indicator li p {
  margin-bottom: 0;
}
.gx-line-indicator-half {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -12px;
}
.gx-line-indicator-half li {
  width: 50%;
  padding: 0 12px;
}
.gx-line-indicator-col {
  width: 25%;
  padding-right: 10px;
}
@media screen and (max-width: 767px) {
  .gx-line-indicator-col {
    width: 100%;
    padding-right: 0;
  }
}
.gx-line-indi {
  height: 3px;
}
.gx-line-indi-info {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  -ms-align-items: center;
  align-items: center;
}
.gx-wel-ema {
  position: relative;
}
.gx-overview-row {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
  .gx-overview-row {
    flex-direction: column;
  }
}
.gx-overview-description {
  width: 32%;
  color: #8c8c8c;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: -24px;
  margin-left: 0;
  border-left: solid 1px #e8e8e8;
  order: 2;
}
@media screen and (max-width: 767px) {
  .gx-overview-description {
    order: 1;
    width: 100%;
    border-left: 0 none !important;
    border-top: solid 1px #e8e8e8;
    border-bottom: solid 1px #e8e8e8;
    margin: 15px 0 0;
  }
}
.gx-map-col {
  flex: 1 1;
  padding: 50px 10px 0;
  order: 1;
}
@media screen and (max-width: 767px) {
  .gx-map-col {
    order: 2;
    padding: 15px 0 0;
  }
}
.gx-revenu {
  flex: 1 1;
  padding: 15px;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-justify-content: center;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}
.gx-revenu-total {
  border-bottom: solid 1px #e8e8e8;
}
.gx-revenu-total h1 {
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 5px;
}
@media screen and (max-width: 767px) {
  .gx-revenu-total h1 {
    font-size: 22px;
  }
}
.gx-revenu-row {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.gx-revenu-col {
  width: 50%;
  padding: 0 15px;
  text-align: center;
}
.gx-revenu-col:not(:last-child) {
  border-right: solid 1px #e8e8e8;
}
.gx-revenu h3 {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 500;
}
.ant-divider-horizontal {
  margin: 12px 0;
}
.gx-visit-col {
  order: 1;
}
@media screen and (max-width: 1199px) {
  .gx-visit-col {
    order: 2;
    margin-top: 20px;
  }
}
.gx-audi-col {
  order: 2;
}
@media screen and (max-width: 1199px) {
  .gx-audi-col {
    order: 1;
  }
}
@media screen and (max-width: 575px) {
  .gx-audi-col {
    margin-top: 20px;
  }
}
.gx-rechart {
  position: relative;
}
.gx-rechart-prod {
  position: absolute;
  left: 30px;
  top: 0;
  z-index: 1;
}
@media (max-width: 1366px) {
  .gx-rechart-prod {
    left: 20px;
  }
}
.gx-pink-purple-gradient {
  background-color: #564093;
  background: -o-linear-gradient(left, #564093, #d2449d);
}
.gx-pink-purple-gradient-reverse {
  background-color: #872776;
  background: -o-linear-gradient(left, #872776, #352883);
}
.gx-blue-cyan-gradient {
  background: linear-gradient(325deg, #45d1e6 0%, #4363c6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@hstartColor', endColorstr='@hendColor', GradientType=0);
}
.gx-app-thumb {
  display: inline-block;
}
.gx-card-cover-black .ant-card-head {
  background-color: #000000;
  color: #ffffff;
  padding-top: 20px;
}
.gx-card-cover-black .ant-card-head h1 {
  color: #ffffff;
}
.gx-card-cover-black .ant-card-head-title {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.gx-card-cover-black .ant-card-body {
  padding: 1rem;
}
.gx-dot-arrow-hover {
  position: relative;
  overflow: hidden;
}
.gx-dot-arrow-hover:hover .gx-hover-arrow {
  left: -15px;
}
.gx-dot-arrow {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  padding-right: 10px;
  cursor: pointer;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-dot-arrow .gx-dot {
  cursor: pointer;
  transition: all 0.3s ease-out;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-justify-content: center;
  justify-content: center;
}
.gx-hover-arrow {
  transition: all 0.3s ease-out;
  cursor: pointer;
  border-radius: 50% 0 0 50%;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-align-items: center;
  align-items: center;
  width: 107px;
  height: 107px;
  position: absolute;
  left: 15px;
  padding: 10px;
  font-size: 20px;
}
.gx-hover-arrow .icon {
  display: flex;
}
.gx-actchart {
  margin-bottom: -4px;
}
.gx-user-wid {
  width: 80px;
  position: relative;
}
.gx-user-wid img {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.gx-user-wid-row {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.gx-user-wid-body {
  flex: 1 1;
}
.boxed-layout .gx-build-box-lay,
.framed-layout .gx-build-box-lay {
  padding: 0 10px !important;
}
.boxed-layout .gx-build-box-lay img,
.framed-layout .gx-build-box-lay img {
  width: 50px;
}
@media (max-width: 1366px) {
  .gx-build-box-lay {
    padding: 0 10px !important;
  }
  .gx-build-box-lay img {
    width: 50px;
  }
}
.gx-build-box-lay-content {
  max-width: calc(100% - 70px);
}
.gx-chart-title {
  font-size: 14px;
}
@media (max-width: 1366px) {
  .gx-chart-title {
    font-size: 12px;
  }
}
.gx-widget-bg {
  background-color: #4c429a;
  background: -o-linear-gradient(left, #4c429a, #9956ce);
  color: #ffffff;
  position: relative;
}
.gx-widget-bg h1 {
  color: #ffffff;
}
.gx-widget-badge {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  background-color: #ff6bb3;
  color: #ffffff;
  padding: 5px 12px;
  border-radius: 0 10px 0 10px;
}
.gx-fnd-content {
  padding-right: 40px;
}
@media (max-width: 1366px) {
  .gx-fnd-content {
    padding-right: 0;
  }
}
@media screen and (max-width: 575px) {
  .gx-fnd-content {
    margin-top: 20px;
  }
}
.gx-fnd-gallery-list {
  list-style: none;
  padding-left: 0;
  margin: 0 -6px;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.gx-fnd-gallery-list li {
  padding: 0 6px;
}
.gx-fnd-gallery-list li > img {
  width: 65px;
}
@media (max-width: 1366px) {
  .gx-fnd-gallery-list li > img {
    width: 50px;
  }
}
.gx-fnd-title {
  font-size: 24px;
  margin-bottom: 15px;
}
@media (max-width: 1366px) {
  .gx-fnd-title {
    font-size: 20px;
    margin-bottom: 5px;
  }
}
.gx-chart-up {
  color: #52c41a;
}
.gx-bg-geekblue .gx-chart-up {
  color: #ffffff;
}
.gx-chart-down {
  color: #f5222d;
}
.gx-chart-down .icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  display: inline-block;
}
.gx-barchart {
  margin-top: -15px;
}
@media screen and (max-width: 1199px) {
  .gx-barchart {
    margin-top: 0;
  }
}
.gx-customers {
  margin-top: -7px;
  margin-bottom: -7px;
}
@media (max-width: 1366px) {
  .gx-customers {
    margin-top: -2px;
    margin-bottom: -2px;
  }
  .gx-customers .gx-size-60 {
    height: 50px !important;
    width: 50px !important;
    line-height: 50px !important;
  }
}
@media (max-width: 575px) {
  .gx-customers {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.gx-fillchart {
  position: relative;
  color: #ffffff;
  min-height: 122px;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: flex-end;
  align-items: flex-end;
  transition: all 0.7s ease-out;
}
.gx-fillchart-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 15px 20px;
}
.gx-overlay-fillchart .gx-fillchart-content {
  background-color: rgba(255, 255, 255, 0.35);
}
.gx-fillchart .ant-card-head-title,
.gx-fillchart h2,
.gx-fillchart .gx-chart-up,
.gx-fillchart .gx-chart-down {
  color: #ffffff;
}
.gx-fillchart .gx-chart-up,
.gx-fillchart .gx-chart-down {
  margin-right: 6px;
}
.gx-fillchart-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.gx-fillchart-btn .icon:before {
  line-height: 30px;
}
.gx-fillchart-btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #038fde;
  background-color: #ffffff;
  width: 30px;
  height: 30px;
  text-align: center;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  transition: all 0.3s ease-out;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-size: 16px;
  border-radius: 50%;
}
.gx-fillchart-btn-close .icon {
  font-size: 24px;
  width: 30px;
  height: 30px;
  line-height: 38px;
}
.gx-fillchart:hover .gx-fillchart-btn {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  visibility: visible;
}
.gx-fillchart-nocontent .gx-fillchart-content,
.gx-fillchart-nocontent .gx-fillchart-btn,
.gx-fillchart-nocontent:hover .gx-fillchart-btn {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  visibility: hidden;
}
.gx-fillchart-nocontent .gx-fillchart-btn-close {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  visibility: visible;
}
.gx-fillchart-nocontent .ant-card-head-title {
  position: absolute;
  left: 20px;
  top: 15px;
  z-index: 1;
}
.gx-agents-list {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -15px;
  list-style: none;
  padding-left: 0;
}
.gx-agents-list li {
  width: 20%;
  padding: 0 15px;
  margin-bottom: 30px;
}
@media screen and (max-width: 1199px) {
  .gx-agents-list li {
    width: 25%;
  }
}
@media screen and (max-width: 991px) {
  .gx-agents-list li {
    width: 20%;
  }
}
@media screen and (max-width: 767px) {
  .gx-agents-list li {
    width: 33.33%;
  }
}
@media screen and (max-width: 575px) {
  .gx-agents-list li {
    width: 50%;
    padding: 0 10px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 575px) {
  .gx-agents-list {
    margin: 0 -10px;
  }
}
.gx-featured-item:not(:last-child) {
  border-bottom: solid 1px #e8e8e8;
  padding-bottom: 10px;
  margin-bottom: 30px;
}
.gx-card-profile .gx-featured-item:not(:last-child) {
  padding-bottom: 8px;
  margin-bottom: 25px;
}
@media (max-width: 575px) {
  .gx-featured-item:not(:last-child) {
    margin-bottom: 27px;
  }
}
.gx-featured-item:last-child .gx-featured-thumb {
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .gx-featured-item:last-child .gx-featured-thumb {
    margin-bottom: 15px;
  }
}
@media (max-width: 575px) {
  .gx-featured-item {
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    -ms-align-items: normal;
    align-items: normal;
  }
}
.gx-featured-thumb {
  position: relative;
  margin-right: 25px;
  margin-bottom: 10px;
}
@media (max-width: 575px) {
  .gx-featured-thumb {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .gx-featured-thumb > img {
    width: 100% !important;
    height: auto !important;
  }
}
.gx-featured-thumb .ant-tag {
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 1;
  margin: 0;
}
.gx-card-profile .gx-featured-thumb {
  max-width: 130px;
}
@media (max-width: 767px) {
  .gx-card-profile .gx-featured-thumb {
    max-width: none;
  }
}
.gx-featured-content {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
@media (max-width: 1366px) {
  .gx-featured-content {
    font-size: 13px;
  }
}
@media (max-width: 1199px) {
  .gx-featured-content {
    font-size: 14px;
  }
  .gx-card-profile .gx-featured-content {
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
@media (max-width: 767px) {
  .gx-featured-content {
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
.gx-featured-content-right {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-left: auto;
  text-align: right;
}
.gx-card-profile .gx-featured-content-right {
  font-size: 14px;
}
@media (max-width: 1199px) {
  .gx-card-profile .gx-featured-content-right {
    text-align: left;
    margin-left: 0;
  }
  .gx-card-profile .gx-featured-content-right h2 {
    font-size: 16px;
  }
  .gx-card-profile .gx-featured-content-right h2 .gx-fs-lg {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .gx-featured-content-right {
    margin-left: 0;
    text-align: left;
    margin-top: 15px;
  }
}
@media (max-width: 575px) {
  .gx-card-profile .gx-featured-content-right h2 {
    font-size: 14px;
  }
}
.gx-news-item {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.gx-news-item:not(:last-child) {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .gx-news-item:not(:last-child) {
    margin-bottom: 26px;
  }
}
@media (max-width: 575px) {
  .gx-news-item:not(:last-child) {
    margin-bottom: 22px;
  }
}
@media (max-width: 575px) {
  .gx-news-item {
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
.gx-news-thumb {
  position: relative;
  margin-right: 25px;
}
@media (max-width: 1366px) {
  .gx-news-thumb {
    margin-right: 15px;
  }
}
@media (max-width: 575px) {
  .gx-news-thumb {
    margin-right: 0;
    margin-bottom: 12px;
  }
  .gx-news-thumb > img {
    width: 100% !important;
    height: auto !important;
  }
}
.gx-news-content {
  color: #686868;
  width: calc(100% - 200px);
}
@media (max-width: 1366px) {
  .gx-news-content {
    width: calc(100% - 190px);
  }
}
@media (max-width: 575px) {
  .gx-news-content {
    width: 100%;
  }
}
.gx-news-tags-row {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
@media (max-width: 1366px) {
  .gx-news-tags-row {
    font-size: 12px;
  }
}
.gx-news-tags-left {
  width: calc(100% - 180px);
}
@media (max-width: 1366px) {
  .gx-news-tags-left {
    width: calc(100% - 130px);
  }
}
.gx-news-tags-right {
  width: 180px;
  padding-left: 8px;
  text-align: right;
}
@media (max-width: 1366px) {
  .gx-news-tags-right {
    width: 130px;
  }
}
.gx-currentplan-row {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.gx-currentplan-col {
  width: 50%;
  padding: 0 5px;
}
@media (max-width: 1366px) {
  .gx-currentplan-col {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .gx-currentplan-col {
    width: 50%;
  }
}
@media (max-width: 575px) {
  .gx-currentplan-col {
    width: 100%;
  }
}
.gx-currentplan-right {
  border-left: solid 1px #e8e8e8;
}
@media (max-width: 1366px) {
  .gx-currentplan-right {
    border-left: 0 none !important;
    border-top: solid 1px #e8e8e8;
    padding-top: 10px;
  }
}
@media (max-width: 991px) {
  .gx-currentplan-right {
    border-left: solid 1px #e8e8e8 !important;
    border-top: 0 none;
    padding-top: 0;
  }
}
@media (max-width: 575px) {
  .gx-currentplan-right {
    border-left: 0 none !important;
    border-top: solid 1px #e8e8e8;
    padding-top: 22px;
  }
}
.gx-currentplan-right-content {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end;
  height: 100%;
  text-align: right;
}
@media (max-width: 1366px) {
  .gx-currentplan-right-content {
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    text-align: left;
  }
}
@media (max-width: 991px) {
  .gx-currentplan-right-content {
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
    text-align: right;
  }
}
@media (max-width: 575px) {
  .gx-currentplan-right-content {
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
    text-align: left;
  }
}
.gx-oth-plans-down {
  display: none;
}
@media (max-width: 1366px) {
  .gx-oth-plans-down {
    display: block;
    margin-top: 15px;
  }
}
@media (max-width: 991px) {
  .gx-oth-plans-down {
    display: none;
    margin-top: 0;
  }
}
@media (max-width: 575px) {
  .gx-oth-plans-down {
    display: block;
    margin-top: 20px;
  }
}
@media (max-width: 1366px) {
  .gx-oth-plans-up {
    display: none;
  }
}
@media (max-width: 991px) {
  .gx-oth-plans-up {
    display: block;
  }
}
@media (max-width: 575px) {
  .gx-oth-plans-up {
    display: none;
  }
}
.gx-revenue-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 1;
  color: #000000;
}
@media (max-width: 1366px) {
  .gx-revenue-title {
    font-size: 28px;
  }
}
@media (max-width: 575px) {
  .gx-revenue-title {
    padding-top: 15px;
  }
}
.gx-dealclose-header {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.gx-dealclose-header-right {
  margin-left: auto;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (max-width: 1366px) {
  .gx-dealclose-header-right {
    margin-left: 0;
    margin-bottom: 20px;
    margin-top: 15px;
  }
}
@media (max-width: 991px) {
  .gx-dealclose-header-right {
    margin-left: auto;
    margin-bottom: 0;
    margin-top: 0;
  }
}
@media (max-width: 575px) {
  .gx-dealclose-header-right {
    margin-left: 0;
  }
}
@media (max-width: 1366px) {
  .gx-dealclose-header {
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
@media (max-width: 991px) {
  .gx-dealclose-header {
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (max-width: 575px) {
  .gx-dealclose-header {
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
@media (max-width: 1366px) {
  .gx-order-history .ant-table {
    font-size: 12px;
  }
}
.gx-icon-img {
  width: 60px;
  height: 60px;
}
@media screen and (max-width: 1366px) {
  .gx-icon-img {
    width: 40px;
    height: 40px;
  }
}
.gx-smart-img {
  max-height: 90px;
  width: 100%;
  object-fit: cover;
}
@media screen and (max-width: 575px) {
  .gx-smart-img {
    max-height: 180px;
  }
}
.gx-ayurveda-thumb {
  max-height: 150px;
}
.gx-ayurveda-thumb > img {
  object-fit: cover;
}
.gx-dash-search {
  position: relative;
}
@media screen and (max-width: 575px) {
  .gx-dash-search {
    padding-right: 30px;
  }
  .gx-dash-search .gx-search-icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
}
.gx-bg-dark-primary {
  background-color: #038fde;
  position: relative;
  overflow: hidden;
}
.gx-bg-dark-primary > * {
  position: relative;
  z-index: 1;
}
.gx-bg-dark-primary:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.72);
}
@media screen and (max-width: 1366px) {
  .recharts-text {
    font-size: 12px;
  }
}
@media screen and (max-width: 575px) {
  .gx-list-item .ant-list-item-content {
    flex-direction: column;
  }
}
/*Algolia Styles*/
.gx-algolia-sidebar {
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  margin-bottom: 32px;
  width: 280px !important;
}
@media screen and (max-width: 767px) {
  .gx-algolia-sidebar {
    width: 100% !important;
  }
}
@media screen and (max-width: 575px) {
  .gx-algolia-sidebar {
    margin-bottom: 20px;
  }
}
.gx-algolia-sidebar [class^="ais-"] {
  font-size: 14px;
}
.gx-algolia-sidebar-content {
  padding: 24px;
}
.gx-algolia-sidebar .ais-ClearRefinements {
  margin-bottom: 20px;
}
.gx-algolia-sidebar .ais-HierarchicalMenu {
  margin-bottom: 20px;
}
.gx-algolia-sidebar .ais-HierarchicalMenu-list li {
  margin-bottom: 5px;
}
.gx-algolia-sidebar .ais-HierarchicalMenu-list li a {
  color: #545454;
}
.gx-algolia-sidebar .ais-HierarchicalMenu-list li a:hover,
.gx-algolia-sidebar .ais-HierarchicalMenu-list li a:focus {
  color: #038fde;
}
.gx-algolia-sidebar .ais-HierarchicalMenu-count {
  padding: 0 5px;
  font-size: 12px;
  color: #8c8c8c;
  background-color: transparent;
  border-radius: 0;
}
.gx-algolia-sidebar .ais-Panel {
  margin-bottom: 20px;
}
.gx-algolia-sidebar .ais-Panel-header {
  margin-bottom: 8px;
  padding-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 0 none;
}
.gx-algolia-sidebar .ais-RefinementList-list li {
  margin-bottom: 5px;
}
.gx-algolia-sidebar .ais-RefinementList-checkbox {
  margin-right: 12px;
}
.gx-algolia-sidebar .ais-RefinementList-labelText {
  font-size: 14px;
}
.gx-algolia-sidebar .ais-RefinementList-count {
  padding: 0 5px;
  font-size: 14px;
  color: #8c8c8c;
  background-color: transparent;
  border-radius: 0;
}
.gx-algolia-sidebar .ais-HierarchicalMenu-list .ais-Breadcrumb-item--selected,
.gx-algolia-sidebar .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item--selected,
.gx-algolia-sidebar .ais-HierarchicalMenu-list .ais-Menu-item--selected {
  font-weight: normal;
}
.gx-algolia-sidebar .ais-HierarchicalMenu-list .ais-Breadcrumb-item--selected > a,
.gx-algolia-sidebar .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item--selected > a,
.gx-algolia-sidebar .ais-HierarchicalMenu-list .ais-Menu-item--selected > a {
  color: #038fde;
}
.gx-algolia-sidebar .ais-RatingMenu-item--selected {
  font-weight: 600;
}
.gx-algolia-layout-has-sider {
  flex-wrap: nowrap;
}
@media screen and (max-width: 767px) {
  .gx-algolia-layout-has-sider {
    flex-wrap: wrap;
  }
}
.gx-algolia-category-title {
  font-size: 14px;
  margin-bottom: 10px;
  color: #8c8c8c;
}
.gx-algolia-main {
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  flex: 1 1;
  padding-left: 30px;
  margin-bottom: 32px;
}
.gx-algolia-main [class^="ais-"] {
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .gx-algolia-main {
    padding-left: 0;
  }
}
@media screen and (max-width: 575px) {
  .gx-algolia-main {
    margin-bottom: 20px;
  }
}
.gx-algolia-header {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.gx-algolia-sort-by {
  margin-left: auto;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
}
.gx-algolia-sort-by label {
  margin-right: 15px;
}
@media screen and (max-width: 400px) {
  .gx-algolia-sort-by {
    margin-left: 0;
  }
}
.gx-algolia-footer {
  padding: 10px;
}
.gx-algolia-footer .ais-Pagination-list {
  flex-wrap: wrap;
}
.gx-algolia-footer .ais-Pagination-list li {
  margin-bottom: 5px;
}
.gx-algolia-content .ais-Stats {
  margin-bottom: 10px;
}
.gx-product-name [class^="ais-"] {
  font-size: 16px;
}
.gx-algolia-refinementList {
  width: 100%;
}
.gx-algolia-refinementList li.ais-RefinementList-item {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ais-RangeInput-submit,
.ais-ClearRefinements-button,
.ais-CurrentRefinements-reset,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset,
.ais-HierarchicalMenu-showMore,
.ais-InfiniteHits-loadMore,
.ais-InfiniteResults-loadMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
  background-color: #038fde;
}
.ais-RangeInput-submit:focus,
.ais-ClearRefinements-button:focus,
.ais-CurrentRefinements-reset:focus,
.ais-GeoSearch-redo:focus,
.ais-GeoSearch-reset:focus,
.ais-HierarchicalMenu-showMore:focus,
.ais-InfiniteHits-loadMore:focus,
.ais-InfiniteResults-loadMore:focus,
.ais-Menu-showMore:focus,
.ais-RefinementList-showMore:focus,
.ais-RangeInput-submit:hover,
.ais-ClearRefinements-button:hover,
.ais-CurrentRefinements-reset:hover,
.ais-GeoSearch-redo:hover,
.ais-GeoSearch-reset:hover,
.ais-HierarchicalMenu-showMore:hover,
.ais-InfiniteHits-loadMore:hover,
.ais-InfiniteResults-loadMore:hover,
.ais-Menu-showMore:hover,
.ais-RefinementList-showMore:hover {
  background-color: #038fde;
}
.ais-Breadcrumb-link,
.ais-HierarchicalMenu-link,
.ais-Menu-link,
.ais-Pagination-link,
.ais-RatingMenu-link {
  color: #038fde;
}
.ais-Breadcrumb-link:focus,
.ais-HierarchicalMenu-link:focus,
.ais-Menu-link:focus,
.ais-Pagination-link:focus,
.ais-RatingMenu-link:focus,
.ais-Breadcrumb-link:hover,
.ais-HierarchicalMenu-link:hover,
.ais-Menu-link:hover,
.ais-Pagination-link:hover,
.ais-RatingMenu-link:hover {
  color: #038fde;
}
.ais-Pagination-item--selected .ais-Pagination-link {
  background-color: #038fde;
  border-color: #038fde;
}
/*Error Styles*/
.gx-page-error-container {
  position: relative;
  height: 100%;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -ms-align-items: center;
  align-items: center;
  -ms-justify-content: center;
  justify-content: center;
  flex: 1 1;
}
.gx-page-error-content {
  margin: 0 auto;
  width: 380px;
  max-width: 94%;
}
.gx-page-error-content h2 {
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .gx-page-error-content h2 {
    margin-bottom: 20px;
  }
}
.gx-error-code {
  color: #595959;
  font-size: 160px;
  text-align: center;
  line-height: 1;
  font-weight: 500;
  text-shadow: 10px 6px 8px rgba(117, 117, 117, 0.8);
}
@media screen and (max-width: 991px) {
  .gx-error-code {
    font-size: 130px;
  }
}
@media screen and (max-width: 767px) {
  .gx-error-code {
    font-size: 100px;
  }
}
@media screen and (max-width: 575px) {
  .gx-error-code {
    font-size: 80px;
  }
}
/*Editor Styles*/
.rdw-editor-toolbar .rdw-inline-wrapper {
  flex-wrap: wrap;
}
@media screen and (max-width: 399px) {
  .rdw-editor-toolbar .rdw-inline-wrapper .rdw-option-wrapper {
    margin-bottom: 8px;
  }
}
/*Testimonial Styles*/
.gx-testimonial {
  margin-bottom: 32px;
}
@media screen and (max-width: 575px) {
  .gx-testimonial {
    margin-bottom: 20px;
  }
}
.gx-classic-testimonial .ant-avatar {
  margin: 0 auto;
  margin-bottom: 30px;
  width: 120px;
  height: 120px;
}
.gx-classic-testimonial .ant-avatar img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 575px) {
  .gx-classic-testimonial .ant-avatar {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
  }
}
.gx-testimonial-bg {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 30px;
  overflow: hidden;
  position: relative;
  border-radius: 6px;
}
@media screen and (max-width: 575px) {
  .gx-testimonial-bg {
    padding: 16px;
  }
}
.gx-testimonial-bg .ant-avatar {
  display: inline-block;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border: 4px solid #038fde;
}
.gx-testimonial-bg .ant-avatar img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 450px) {
  .gx-testimonial-bg .ant-avatar {
    width: 50px;
    height: 50px;
  }
}
.gx-testimonial-bg .gx-description {
  color: #d9d9d9;
  font-style: italic;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 15px;
}
.gx-testimonial-bg .gx-title {
  color: #038fde;
  margin-bottom: 2px;
}
.gx-testimonial-des {
  position: relative;
  padding-left: 30px;
}
.gx-testimonial-des:before {
  font-family: "gaxon";
  font-size: 20px;
  content: "\E066";
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 475px) {
  .gx-standard .gx-media {
    flex-direction: column;
  }
}
/*Base Styles*/
body.dark-theme.framed-layout,
body.dark-theme.boxed-layout {
  background-color: #22282e;
}
.dark-theme {
  color: #e0e0e0;
  background-color: #38424b;
}
.dark-theme .gx-link {
  color: #c7c7c7;
}
.dark-theme .gx-link:hover,
.dark-theme .gx-link:focus {
  color: #e8e8e8;
}
.dark-theme .gx-social-link li .gx-link {
  color: #e0e0e0;
}
.dark-theme .gx-social-link li.active .gx-link,
.dark-theme .gx-social-link li .gx-link:hover,
.dark-theme .gx-social-link li .gx-link:focus {
  color: #ffffff;
}
.dark-theme h1,
.dark-theme h2,
.dark-theme h3,
.dark-theme h4,
.dark-theme h5,
.dark-theme h6,
.dark-theme .h1,
.dark-theme .h2,
.dark-theme .h3,
.dark-theme .h4,
.dark-theme .h5,
.dark-theme .h6 {
  color: #ededed;
}
.dark-theme .ant-modal h1,
.dark-theme .ant-modal h2,
.dark-theme .ant-modal h3,
.dark-theme .ant-modal h4,
.dark-theme .ant-modal h5,
.dark-theme .ant-modal h6,
.dark-theme .ant-modal .h1,
.dark-theme .ant-modal .h2,
.dark-theme .ant-modal .h3,
.dark-theme .ant-modal .h4,
.dark-theme .ant-modal .h5,
.dark-theme .ant-modal .h6 {
  color: #38424b;
}
.dark-theme .gx-drawer-sidebar-dark .ant-drawer-content {
  background-color: #343d45;
  color: #e0e0e0;
}
.dark-theme .ant-drawer-right .ant-drawer-content-wrapper,
.dark-theme .ant-drawer-right .ant-drawer-content {
  background: #38424b;
}
.dark-theme .ant-drawer-close {
  color: #e0e0e0;
}
.dark-theme .ant-drawer-close:hover,
.dark-theme .ant-drawer-close:focus {
  color: #ffffff;
}
.dark-theme .ant-layout,
.dark-theme .ant-layout.ant-layout-has-sider > .ant-layout {
  background: #38424b;
}
.dark-theme .ant-layout-header {
  background: #38424b;
  color: #e0e0e0;
}
.dark-theme .gx-news-content {
  color: #e0e0e0;
}
.dark-theme .gx-header-horizontal {
  background: #2d353c;
}
.dark-theme .gx-header-horizontal-main {
  color: #e0e0e0;
  background: none;
}
.dark-theme .gx-header-horizontal-dark:before {
  background: #434f5a;
}
.dark-theme .gx-header-horizontal-dark .gx-header-search .gx-lt-icon-search-bar-lg input[type="search"] {
  border-color: #e8e8e8;
}
.dark-theme .gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.dark-theme .gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.dark-theme .gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.dark-theme .gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.dark-theme .gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.dark-theme .gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.dark-theme .gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.dark-theme .gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before {
  color: #2d353c;
}
.dark-theme .gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.dark-theme .gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.dark-theme .gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.dark-theme .gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.dark-theme .gx-above-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before {
  color: #fa8c15;
}
.dark-theme .gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.dark-theme .gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
.dark-theme .gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.dark-theme .gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.dark-theme .gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.dark-theme .gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.dark-theme .gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.dark-theme .gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.dark-theme .gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.dark-theme .gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.dark-theme .gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.dark-theme .gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.dark-theme .gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.dark-theme .gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
.dark-theme .gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before,
.dark-theme .gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before {
  color: #38424b;
}
.dark-theme .gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.dark-theme .gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
.dark-theme .gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.dark-theme .gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
.dark-theme .gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.dark-theme .gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
.dark-theme .gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.dark-theme .gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
.dark-theme .gx-below-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
.dark-theme .gx-inside-header-horizontal .gx-header-horizontal-nav-curve .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before {
  color: #fa8c15;
}
.dark-theme .gx-menu-horizontal .ant-menu-submenu:hover > .ant-menu-submenu-title,
.dark-theme .gx-menu-horizontal .ant-menu-item-active > .ant-menu-submenu-title,
.dark-theme .gx-menu-horizontal .ant-menu-submenu-active > .ant-menu-submenu-title,
.dark-theme .gx-menu-horizontal .ant-menu-item-open > .ant-menu-submenu-title,
.dark-theme .gx-menu-horizontal .ant-menu-submenu-open > .ant-menu-submenu-title {
  color: #fa8c15;
}
.dark-theme .ant-layout-footer {
  background: #38424b;
  color: #e0e0e0;
  border-top-color: #495762;
}
.dark-theme .gx-nav-header {
  background-color: #38424b;
  border-bottom-color: #495762;
}
.dark-theme .ant-menu {
  color: #e0e0e0;
  background: #343d45;
}
.dark-theme .ant-menu-inline,
.dark-theme .ant-menu-vertical,
.dark-theme .ant-menu-vertical-left {
  border-right-color: #495762;
}
.dark-theme .ant-menu-dark {
  color: #e0e0e0;
  background: #343d45;
}
.dark-theme .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.dark-theme .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: #ffffff;
}
.dark-theme .ant-menu-submenu-title:hover {
  color: #fa8c15;
}
.dark-theme .ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-title {
  color: #e0e0e0;
}
.dark-theme .ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-title:hover {
  color: #fa8c15;
}
.dark-theme .ant-menu-dark .ant-menu-sub {
  color: #e0e0e0;
  background: none;
}
.dark-theme .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.dark-theme .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
  background: #ffffff;
}
.dark-theme .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.dark-theme .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
  background: #fa8c15;
}
.dark-theme .ant-menu-item > a {
  color: #e0e0e0;
}
.dark-theme .ant-menu-item > a:hover {
  color: #fa8c15;
}
.dark-theme .ant-menu-item-selected > a:hover {
  color: #fa8c15;
}
.dark-theme .ant-menu-dark.ant-menu-submenu-popup,
.dark-theme .ant-menu-submenu-popup {
  background: #38424b;
  border-radius: 10px;
}
.dark-theme .ant-menu-dark.ant-menu-submenu-popup .ant-menu-sub .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.dark-theme .ant-menu-dark.ant-menu-submenu-popup .ant-menu-sub .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
  background: #ffffff;
}
.dark-theme .ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.dark-theme .ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background: #fa8c15;
}
.dark-theme .ant-menu-dark.ant-menu-horizontal {
  border-bottom-color: #343d45;
}
.dark-theme .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.dark-theme .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  border-color: #343d45;
}
.dark-theme .ant-menu-horizontal {
  border-bottom-color: #495762;
  background: #38424b;
}
.dark-theme .gx-header-horizontal-nav .ant-menu-horizontal {
  background: none;
}
.dark-theme .ant-menu-horizontal > .ant-menu-item:hover,
.dark-theme .ant-menu-horizontal > .ant-menu-submenu:hover,
.dark-theme .ant-menu-horizontal > .ant-menu-item-active,
.dark-theme .ant-menu-horizontal > .ant-menu-submenu-active,
.dark-theme .ant-menu-horizontal > .ant-menu-item-open,
.dark-theme .ant-menu-horizontal > .ant-menu-submenu-open,
.dark-theme .ant-menu-horizontal > .ant-menu-item-selected,
.dark-theme .ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom-color: #e0e0e0;
  color: #e0e0e0;
}
.dark-theme .ant-menu-horizontal > .ant-menu-item > a,
.dark-theme .ant-menu-horizontal > .ant-menu-submenu > a {
  color: #e0e0e0;
}
.dark-theme .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #4e5c69;
  color: #e0e0e0;
}
.dark-theme .ant-layout-sider .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .ant-layout-sider.gx-mini-custom-sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.dark-theme .ant-layout-sider.gx-mini-custom-sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a:hover {
  color: #fffffe;
}
.dark-theme .ant-menu-submenu > .ant-menu,
.dark-theme .ant-menu-item:active,
.dark-theme .ant-menu-submenu-title:active {
  background-color: #4e5c69;
  color: #e0e0e0;
}
.dark-theme .ant-layout-sider .ant-menu-submenu > .ant-menu,
.dark-theme .ant-layout-sider .ant-menu-item:active,
.dark-theme .ant-layout-sider .ant-menu-submenu-title:active {
  background-color: transparent;
}
.dark-theme .ant-menu-item-group-title,
.dark-theme .ant-menu-item-selected,
.dark-theme .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #e0e0e0;
}
.dark-theme .ant-menu-item:hover,
.dark-theme .ant-menu-item-active,
.dark-theme .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.dark-theme .ant-menu-submenu-active {
  color: #e0e0e0;
}
.dark-theme .ant-menu-item-disabled,
.dark-theme .ant-menu-submenu-disabled {
  color: #a0a0a0 !important;
}
.dark-theme .ant-menu-dark .ant-menu-item-group-title {
  color: #e0e0e0;
}
.dark-theme .ant-menu-dark .ant-menu-item {
  color: #e0e0e0;
}
.dark-theme .ant-menu-dark .ant-menu-item > a {
  color: #ffffff;
}
.dark-theme .ant-menu-dark .ant-menu-item > a:hover {
  color: #fa8c15;
}
.dark-theme .ant-menu-dark .ant-menu-item-selected > a {
  color: #fa8c15;
}
.dark-theme .ant-menu-submenu-horizontal > .ant-menu-submenu-title {
  color: #e0e0e0;
}
.dark-theme .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.dark-theme .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.dark-theme .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.dark-theme .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.dark-theme .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.dark-theme .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.dark-theme .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.dark-theme .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background: #ffffff;
}
.dark-theme .ant-menu-vertical .ant-menu-item::after,
.dark-theme .ant-menu-vertical-left .ant-menu-item::after,
.dark-theme .ant-menu-vertical-right .ant-menu-item::after,
.dark-theme .ant-menu-inline .ant-menu-item::after {
  border-right-color: #08090a;
}
.dark-theme .ant-layout-sider,
.dark-theme .gx-layout-sider-dark {
  background-color: #343d45;
  color: #a1a1a1;
  box-shadow: 0px 0 4px rgba(0, 0, 0, 0.35);
}
.dark-theme .gx-task-list-item:not(:last-child) {
  border-bottom-color: #495762;
}
.dark-theme .gx-task-list-item:hover .gx-text-hover {
  color: #c7c7c7;
}
.dark-theme .gx-card-ticketlist .gx-task-list-item:hover {
  background-color: transparent;
}
.dark-theme .gx-card-ticketlist .gx-task-list-item:hover .gx-task-item-title {
  color: #e8e8e8;
}
.dark-theme .gx-card-testimonial-content {
  background-color: #38424b;
}
.dark-theme .gx-card-testimonial-content:before {
  border-right-color: #38424b;
}
.dark-theme .gx-progress-task-list + .gx-progress-task-list {
  border-top-color: #495762;
}
.dark-theme .gx-card-ticketlist .gx-link {
  color: #c7c7c7;
}
.dark-theme .gx-overview-description {
  border-left-color: #495762;
}
@media screen and (max-width: 767px) {
  .dark-theme .gx-overview-description {
    border-top-color: #495762;
    border-bottom-color: #495762;
  }
}
.dark-theme .gx-revenu-total {
  border-bottom-color: #495762;
}
.dark-theme .gx-revenu-col:not(:last-child) {
  border-right-color: #495762;
}
.dark-theme .gx-product-item {
  background: #434f5a;
}
.dark-theme .gx-login-content {
  background-color: #434f5a;
}
.dark-theme .gx-login-content .ant-input {
  background-color: #bfbfbf;
}
.dark-theme .gx-login-content .ant-input:focus {
  border-color: #38424b;
}
.dark-theme .gx-app-login-main-content {
  background-color: #434f5a;
}
.dark-theme .gx-app-login-content .ant-input {
  background-color: #bfbfbf;
}
.dark-theme .gx-app-login-content .ant-input:focus {
  border-color: #38424b;
}
.dark-theme .gx-app-logo-content {
  color: #e0e0e0;
}
.dark-theme .gx-app-logo-content:before {
  background-color: rgba(56, 66, 75, 0.7);
}
.dark-theme .gx-app-logo-content h1 {
  color: #e0e0e0;
}
.dark-theme .gx-app-social-block .gx-social-link span {
  border-color: #38424b;
  color: #38424b;
}
.dark-theme .gx-app-social-block .gx-social-link span:hover,
.dark-theme .gx-app-social-block .gx-social-link span:focus {
  color: #e0e0e0;
  background-color: #38424b;
}
.dark-theme .gx-avatar-img,
.dark-theme .gx-avatar,
.dark-theme .gx-badge-outline {
  border-color: #495762;
}
.dark-theme .gx-btn,
.dark-theme .btn {
  color: #545454;
  border-color: #d9d9d9;
}
.dark-theme .gx-sub-popover .gx-btn:focus,
.dark-theme .gx-sub-popover .btn:focus,
.dark-theme .gx-sub-popover .gx-btn:hover,
.dark-theme .gx-sub-popover .btn:hover {
  background-color: #e0e0e0;
}
.dark-theme .gx-btn-link {
  color: #c7c7c7;
  background-color: transparent;
}
.dark-theme .gx-btn-link:hover {
  color: #e8e8e8;
}
.dark-theme .gx-ant-card-actions > li:not(:last-child) {
  border-right-color: #495762;
}
.dark-theme .ant-card {
  color: #e0e0e0;
  background: #434f5a;
}
.dark-theme .ant-card-bordered {
  border-color: #495762;
}
.dark-theme .ant-card-head {
  border-color: #495762;
}
.dark-theme .ant-card-head-title {
  color: #e0e0e0;
}
.dark-theme .ant-card-type-inner .ant-card-head {
  background: #434f5a;
}
.dark-theme .ant-card-meta-title,
.dark-theme .ant-card-meta-description {
  color: #e0e0e0;
}
.dark-theme .ant-card-actions {
  border-top-color: #495762;
  background: #434f5a;
}
.dark-theme .ant-card-actions > li {
  color: #e0e0e0;
}
.dark-theme .ant-card-actions > li:not(:last-child) {
  border-right-color: #495762;
}
.dark-theme .ant-card-actions > li > span:hover {
  color: #e8e8e8;
}
.dark-theme .ant-card-grid {
  box-shadow: 1px 0 0 0 #495762, 0 1px 0 0 #495762, 1px 1px 0 0 #495762, 1px 0 0 0 #495762 inset, 0 1px 0 0 #495762 inset;
}
.dark-theme .ant-card-grid:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.dark-theme .gx-card {
  background-color: #434f5a;
}
.dark-theme .gx-card-overview {
  background-color: #434f5a;
}
.dark-theme .gx-card-metrics,
.dark-theme .gx-card-widget {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
}
.dark-theme .gx-icon-views:hover {
  background-color: #38424b;
  color: #e0e0e0;
}
.dark-theme .gx-search-bar input[type="search"] {
  background: rgba(250, 250, 250, 0.1);
}
.dark-theme .gx-search-bar input[type="search"]:focus {
  background-color: rgba(250, 250, 250, 0.3);
}
.dark-theme .gx-search-bar .gx-search-icon {
  color: #d9d9d9;
}
.dark-theme .gx-chat-search-bar input[type="search"]:focus {
  background-color: #434f5a;
}
.dark-theme .gx-user-list.gx-card-list {
  background: #434f5a;
}
.dark-theme .gx-user-list .gx-link[class*="gx-meta-"] {
  color: #e0e0e0;
}
.dark-theme .gx-card-strip:not(:last-child) {
  border-bottom-color: #495762;
}
.dark-theme .gx-loader-container {
  background-color: #bfbfbf;
}
.dark-theme .gx-table th,
.dark-theme .gx-table td {
  border-top-color: #495762;
}
.dark-theme .gx-table thead th {
  border-bottom-color: #495762;
}
.dark-theme .gx-table tbody + tbody {
  border-top-color: #495762;
}
.dark-theme .gx-table-bordered {
  border-color: #495762;
}
.dark-theme .gx-table-bordered th,
.dark-theme .gx-table-bordered td {
  border-color: #495762;
}
.dark-theme .gx-timeline-item:before {
  border-left-color: #495762;
}
.dark-theme .gx-timeline-badge {
  color: #434f5a;
}
.dark-theme .gx-timeline-badge:after {
  border-color: #495762;
}
.dark-theme .gx-timeline-panel {
  background-color: #434f5a;
  border-color: #495762;
}
.dark-theme .gx-timeline-panel:before {
  border-color: transparent #495762;
}
.dark-theme .gx-timeline-panel:after {
  border-color: transparent #434f5a;
}
.dark-theme .gx-timeline-center .gx-timeline-inverted:before {
  border-right-color: #495762;
}
.dark-theme .gx-timeline-info .ant-timeline-item-content {
  color: #e0e0e0;
}
.dark-theme .gx-timeline-info .ant-timeline-item-content .gx-link {
  color: #d3d3d3;
}
.dark-theme .gx-timeline-info .ant-timeline-item-content .gx-link:hover,
.dark-theme .gx-timeline-info .ant-timeline-item-content .gx-link:focus {
  color: #ffffff;
}
.dark-theme .gx-img-more {
  background-color: #364048;
}
.dark-theme .ant-tabs {
  color: #e0e0e0;
}
.dark-theme .ant-tabs-nav .ant-tabs-tab-active,
.dark-theme .ant-tabs-nav .ant-tabs-tab:hover {
  color: #c7c7c7;
}
.dark-theme .ant-tabs-ink-bar {
  background-color: #c7c7c7;
}
.dark-theme .ant-tabs-bar {
  border-bottom-color: #495762;
}
.dark-theme .ant-tabs-tab-prev,
.dark-theme .ant-tabs-tab-next {
  color: #e0e0e0;
}
.dark-theme .ant-tabs-tab-prev:hover,
.dark-theme .ant-tabs-tab-next:hover {
  color: #ffffff;
}
.dark-theme .ant-tabs-tab-btn-disabled,
.dark-theme .ant-tabs-tab-btn-disabled:hover {
  color: #7a7a7a;
}
.dark-theme .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: #546471;
  border-bottom-color: #434f5a;
  background: #475460;
}
.dark-theme .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  background: #434f5a;
  border-color: #546471;
  border-bottom-color: #434f5a;
  color: #ffffff;
}
.dark-theme .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab .anticon-close {
  color: #e0e0e0;
}
.dark-theme .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab .anticon-close:hover {
  color: #ffffff;
}
.dark-theme .ant-checkbox-wrapper {
  color: #e0e0e0;
}
.dark-theme .ant-checkbox-disabled + span {
  color: #939393;
}
.dark-theme .ant-checkbox-checked::after,
.dark-theme .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.dark-theme .ant-checkbox:hover .ant-checkbox-inner,
.dark-theme .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #6f8294;
}
.dark-theme .ant-checkbox-checked .ant-checkbox-inner,
.dark-theme .ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #6f8294;
  border-color: #6f8294;
}
.dark-theme .ant-timeline {
  color: #e0e0e0;
}
.dark-theme .ant-timeline-item-head {
  background-color: transparent;
}
.dark-theme .ant-timeline-item-tail {
  border-left-color: #5a6c79;
}
.dark-theme .ant-timeline-item-head-blue {
  border-color: #5a6c79;
  color: #e8e8e8;
}
.dark-theme .ant-popover-inner,
.dark-theme .ant-popover-arrow {
  background-color: #434f5a;
}
.dark-theme .ant-popover-inner {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.28);
}
.dark-theme .ant-popover-inner-content,
.dark-theme .ant-popover-message {
  color: #e0e0e0;
}
.dark-theme .ant-popover-title {
  border-bottom-color: #495762;
  color: #e0e0e0;
}
.dark-theme .gx-sub-popover li:not(:last-child),
.dark-theme .gx-popover-header {
  border-bottom-color: #495762;
}
.dark-theme .gx-user-popover li:hover,
.dark-theme .gx-user-popover li:focus {
  background-color: #4e5c69;
}
.dark-theme .ant-divider {
  color: #e0e0e0;
  background: #495762;
}
.dark-theme .ant-divider.ant-divider-with-text,
.dark-theme .ant-divider.ant-divider-with-text-left,
.dark-theme .ant-divider.ant-divider-with-text-right {
  background: none;
}
.dark-theme .gx-card-body-border-top .ant-card-body {
  border-top-color: #495762;
}
.dark-theme .ant-list {
  color: #e0e0e0;
}
.dark-theme .ant-list-split .ant-list-item,
.dark-theme .ant-list-split .ant-list-header,
.dark-theme .ant-list-something-after-last-item .ant-spin-container > .ant-list-item:last-child {
  border-bottom-color: #495762;
}
.dark-theme .ant-list-item-meta-description,
.dark-theme .ant-list-vertical .ant-list-item-content,
.dark-theme .ant-list-item-action > li {
  color: #e0e0e0;
}
.dark-theme .ant-list-bordered {
  border-color: #495762;
}
.dark-theme .ant-list-item-action-split {
  background-color: #495762;
}
.dark-theme .gx-btn-light,
.dark-theme .gx-link.gx-btn-light {
  color: #e0e0e0 !important;
  background-color: #38424b !important;
  border-color: #38424b !important;
}
.dark-theme .gx-btn-light:hover,
.dark-theme .gx-link.gx-btn-light:hover,
.dark-theme .gx-btn-light:focus,
.dark-theme .gx-link.gx-btn-light:focus {
  background-color: #22282e !important;
  border-color: #22282e !important;
}
.dark-theme .ant-table {
  color: #e0e0e0;
}
.dark-theme .ant-table-thead > tr > th {
  background: #4e5c69;
  color: #e0e0e0;
  border-bottom-color: #495762;
}
.dark-theme .ant-table-tbody > tr > td {
  border-bottom-color: #495762;
}
.dark-theme .ant-table-thead > tr.ant-table-row-hover > td,
.dark-theme .ant-table-tbody > tr.ant-table-row-hover > td,
.dark-theme .ant-table-thead > tr:hover > td,
.dark-theme .ant-table-tbody > tr:hover > td {
  background: #4e5c69;
}
.dark-theme .ant-table-tbody > tr.ant-table-row-selected td {
  background: #4e5c69;
}
.dark-theme .ant-table-small {
  border-color: #495762;
}
.dark-theme .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
.dark-theme .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
.dark-theme .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
.dark-theme .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
.dark-theme .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
.dark-theme .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
.dark-theme .ant-table-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
.dark-theme .ant-table-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th {
  background: #4e5c69;
  border-bottom-color: #495762;
}
.dark-theme .ant-table.ant-table-bordered .ant-table-title,
.dark-theme .ant-table-bordered .ant-table-header > table,
.dark-theme .ant-table-bordered .ant-table-body > table,
.dark-theme .ant-table-bordered .ant-table-fixed-left table,
.dark-theme .ant-table-bordered .ant-table-fixed-right table,
.dark-theme .ant-table.ant-table-bordered .ant-table-footer {
  border-color: #495762;
}
.dark-theme .ant-table-header {
  background: #4e5c69;
}
.dark-theme .ant-table-footer {
  background: #4e5c69;
}
.dark-theme .ant-table-footer:before {
  background: transparent;
}
.dark-theme .ant-table-bordered .ant-table-thead > tr > th,
.dark-theme .ant-table-bordered .ant-table-tbody > tr > td {
  border-right-color: #495762;
}
.dark-theme .ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
  border-bottom-color: #495762;
}
.dark-theme .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  background: #434f5a;
}
.dark-theme .ant-table-fixed-left table,
.dark-theme .ant-table-fixed-right table {
  background: #4e5c69;
}
.dark-theme .ant-table-thead > tr > th .anticon-filter,
.dark-theme .ant-table-thead > tr > th .ant-table-filter-icon {
  color: #e0e0e0;
}
.dark-theme .ant-table-thead > tr > th .anticon-filter:hover,
.dark-theme .ant-table-thead > tr > th .ant-table-filter-icon:hover {
  color: #ffffff;
}
.dark-theme .ant-table-column-sorter {
  color: #e0e0e0;
}
.dark-theme .ant-table-column-sorter-up:hover .anticon,
.dark-theme .ant-table-column-sorter-down:hover .anticon,
.dark-theme .ant-table-column-sorter-up.on .anticon-caret-up,
.dark-theme .ant-table-column-sorter-down.on .anticon-caret-up,
.dark-theme .ant-table-column-sorter-up.on .anticon-caret-down,
.dark-theme .ant-table-column-sorter-down.on .anticon-caret-down {
  color: #ffffff;
}
.dark-theme .ant-table-row-expand-icon {
  background: none;
}
.dark-theme tr.ant-table-expanded-row,
.dark-theme tr.ant-table-expanded-row:hover {
  background: #4e5c69;
}
.dark-theme .ant-pagination {
  color: #e0e0e0;
}
.dark-theme .ant-pagination-item {
  background-color: #38424b;
  border-color: #495762;
}
.dark-theme .ant-pagination-item-active,
.dark-theme .ant-pagination-item:hover,
.dark-theme .ant-pagination-item:focus {
  border-color: #495762;
}
.dark-theme .ant-pagination-item-active .gx-link,
.dark-theme .ant-pagination-item:hover .gx-link,
.dark-theme .ant-pagination-item:focus .gx-link {
  color: #e0e0e0;
}
.dark-theme .ant-pagination-disabled a,
.dark-theme .ant-pagination-disabled:hover a,
.dark-theme .ant-pagination-disabled:focus a,
.dark-theme .ant-pagination-disabled .ant-pagination-item-link,
.dark-theme .ant-pagination-disabled:hover .ant-pagination-item-link,
.dark-theme .ant-pagination-disabled:focus .ant-pagination-item-link {
  color: #6d6d6d;
}
.dark-theme .ant-pagination-prev,
.dark-theme .ant-pagination-next,
.dark-theme .ant-pagination-jump-prev,
.dark-theme .ant-pagination-jump-next {
  color: #e0e0e0;
}
.dark-theme .ant-pagination-prev .ant-pagination-item-link,
.dark-theme .ant-pagination-next .ant-pagination-item-link {
  background-color: #38424b;
  border-color: #495762;
}
.dark-theme .ant-pagination-prev:focus .ant-pagination-item-link,
.dark-theme .ant-pagination-next:focus .ant-pagination-item-link,
.dark-theme .ant-pagination-prev:hover .ant-pagination-item-link,
.dark-theme .ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #495762;
  color: #e0e0e0;
}
.dark-theme .ant-pagination-jump-prev:focus:after,
.dark-theme .ant-pagination-jump-next:focus:after,
.dark-theme .ant-pagination-jump-prev:hover:after,
.dark-theme .ant-pagination-jump-next:hover:after {
  color: #e0e0e0;
}
.dark-theme .ant-pagination-simple .ant-pagination-simple-pager input {
  background-color: rgba(250, 250, 250, 0.1);
  border-color: rgba(250, 250, 250, 0.1);
  color: #e0e0e0;
}
.dark-theme .ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: rgba(250, 250, 250, 0.3);
}
.dark-theme .ant-pagination-options-quick-jumper input {
  background-color: rgba(250, 250, 250, 0.1);
  border-color: rgba(250, 250, 250, 0.1);
  color: #e0e0e0;
}
.dark-theme .ant-pagination-options-quick-jumper input:hover {
  border-color: rgba(250, 250, 250, 0.1);
}
.dark-theme .ant-pagination-options-quick-jumper input:focus {
  border-color: #495762;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.dark-theme .ant-badge {
  color: #e0e0e0;
}
.dark-theme .ant-badge-status-text {
  color: #e0e0e0;
}
.dark-theme .ant-badge-count {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
}
.dark-theme .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #495762;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.dark-theme .ant-cascader-menus {
  background: #38424b;
}
.dark-theme .ant-cascader-menu {
  border-right-color: #495762;
}
.dark-theme .ant-cascader-menu-item:hover {
  background: #323a43;
}
.dark-theme .ant-cascader-picker,
.dark-theme .ant-cascader-picker-clear {
  background-color: transparent;
  color: #e0e0e0;
}
.dark-theme .ant-cascader-picker-arrow,
.dark-theme .ant-cascader-menu-item-expand:after {
  color: #e0e0e0;
}
.dark-theme .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.dark-theme .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background: #4e5c69;
}
.dark-theme .ant-form-item,
.dark-theme .ant-form-item-label label,
.dark-theme .ant-form-explain,
.dark-theme .ant-form-extra {
  color: #e0e0e0;
}
.dark-theme .ant-input {
  background-color: rgba(250, 250, 250, 0.1) !important;
  border-color: rgba(250, 250, 250, 0.1);
  color: #e0e0e0;
}
.dark-theme .ant-input:focus,
.dark-theme .ant-input:hover {
  border-color: rgba(245, 245, 245, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.dark-theme .ant-input-number {
  background-color: rgba(250, 250, 250, 0.1);
  border-color: rgba(250, 250, 250, 0.1);
  color: #e0e0e0;
}
.dark-theme .ant-input-number:focus,
.dark-theme .ant-input-number:hover {
  border-color: rgba(245, 245, 245, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.dark-theme .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #495762;
}
.dark-theme .ant-input-group-addon {
  background-color: rgba(250, 250, 250, 0.1);
  border-color: rgba(250, 250, 250, 0.1);
  color: #e0e0e0;
}
.dark-theme .ant-input-group-addon .ant-select-open .ant-select-selection,
.dark-theme .ant-input-group-addon .ant-select-focused .ant-select-selection {
  color: #e0e0e0;
}
.dark-theme .ant-input-search-icon {
  color: #e0e0e0;
}
.dark-theme .ant-input-affix-wrapper .ant-input-prefix,
.dark-theme .ant-input-affix-wrapper .ant-input-suffix {
  color: #e0e0e0;
}
.dark-theme .ant-input-affix-wrapper .ant-input-prefix i,
.dark-theme .ant-input-affix-wrapper .ant-input-suffix i {
  color: #e0e0e0 !important;
}
.dark-theme .ant-input.gx-chat-textarea {
  background-color: #434f5a !important;
  border-color: #434f5a;
  color: #e0e0e0;
}
.dark-theme .ant-input.gx-chat-textarea:focus {
  border-color: #495762;
}
.dark-theme .ant-modal .ant-input {
  background-color: #ffffff !important;
  border-color: #dde2e6;
  color: #595959;
}
.dark-theme .ant-modal .ant-input:focus,
.dark-theme .ant-modal .ant-input:hover {
  border-color: #cfd6db;
}
.dark-theme .ant-modal .ant-upload-list-item-info .anticon-paper-clip {
  color: #595959;
}
.dark-theme .ant-mention-wrapper {
  color: #e0e0e0;
}
.dark-theme .ant-mention-wrapper.disabled .ant-mention-editor {
  background-color: rgba(232, 232, 232, 0.1);
  color: #e0e0e0;
}
.dark-theme .ant-mention-wrapper.ant-mention-active:not(.disabled) .ant-mention-editor {
  border-color: rgba(245, 245, 245, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.dark-theme .ant-mention-wrapper .ant-mention-editor {
  background-color: rgba(250, 250, 250, 0.1);
  border-color: rgba(250, 250, 250, 0.1);
  color: #e0e0e0;
}
.dark-theme .ant-mention-wrapper .ant-mention-editor:focus {
  border-color: rgba(245, 245, 245, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.dark-theme .ant-select {
  color: #e0e0e0;
}
.dark-theme .ant-select-selection {
  background-color: rgba(250, 250, 250, 0.1);
  border-color: rgba(250, 250, 250, 0.1);
}
.dark-theme .ant-select-selection:hover {
  border-color: rgba(250, 250, 250, 0.1);
}
.dark-theme .ant-select-arrow {
  color: #e0e0e0;
}
.dark-theme .ant-select-focused .ant-select-selection,
.dark-theme .ant-select-selection:focus,
.dark-theme .ant-select-selection:active {
  border-color: rgba(245, 245, 245, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.dark-theme .ant-select-dropdown {
  color: #e0e0e0;
  background-color: #38424b;
}
.dark-theme .ant-select-dropdown-menu-item {
  color: #e0e0e0;
}
.dark-theme .ant-select-dropdown-menu-item:hover {
  background-color: #343d46;
}
.dark-theme .ant-select-dropdown-menu-item-selected,
.dark-theme .ant-select-dropdown-menu-item-selected:hover,
.dark-theme .ant-select-dropdown-menu-item-active {
  background-color: #323a43;
  color: #e0e0e0;
}
.dark-theme .ant-select-auto-complete.ant-select .ant-input:focus,
.dark-theme .ant-select-auto-complete.ant-select .ant-input:hover {
  border-color: rgba(250, 250, 250, 0.1);
}
.dark-theme .ant-select-dropdown-menu-item-group-title {
  color: #e0e0e0;
}
.dark-theme .ant-select-auto-complete.ant-select textarea.ant-input {
  background: rgba(250, 250, 250, 0.1) !important;
}
.dark-theme .ant-select-tree,
.dark-theme .ant-select-tree li .ant-select-tree-node-content-wrapper {
  color: #e0e0e0;
}
.dark-theme .ant-select-tree li .ant-select-tree-node-content-wrapper:hover,
.dark-theme .ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #4e5c69;
}
.dark-theme .ant-select-tree-dropdown .ant-select-dropdown-search .ant-select-search__field,
.dark-theme .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner,
.dark-theme .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  border-color: #4e5c69;
  background-color: #4e5c69;
}
.dark-theme .ant-select-show-search .ant-select-selection {
  background-color: transparent;
  box-shadow: none;
}
.dark-theme .ant-transfer-list-search-action,
.dark-theme .ant-transfer-list-search-action i {
  color: #e0e0e0;
}
.dark-theme .ant-transfer-list {
  border-color: #546471;
  color: #e0e0e0;
}
.dark-theme .ant-transfer-list-body-with-search {
  background-color: #4e5c69;
}
.dark-theme .ant-transfer-list-header {
  background-color: #4e5c69;
  border-color: #546471;
  color: #e0e0e0;
}
.dark-theme .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #546471;
}
.dark-theme .ant-transfer-list-footer {
  border-top-color: #546471;
  background-color: #4e5c69;
}
.dark-theme .ant-transfer-list-content-item-disabled {
  color: #7a7a7a;
}
.dark-theme .ant-calendar {
  background-color: #4e5c69;
  border-color: #4e5c69;
  color: #e0e0e0;
}
.dark-theme .ant-calendar-today .ant-calendar-date {
  border-color: #7e8f9f;
  color: #7e8f9f;
  background-color: #647586;
}
.dark-theme .ant-calendar-selected-date .ant-calendar-date,
.dark-theme .ant-calendar-selected-start-date .ant-calendar-date,
.dark-theme .ant-calendar-selected-end-date .ant-calendar-date,
.dark-theme .ant-calendar-selected-day .ant-calendar-date {
  background: #7e8f9f;
  color: #e0e0e0;
}
.dark-theme .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover,
.dark-theme .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
.dark-theme .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
.dark-theme .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover,
.dark-theme .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade,
.dark-theme .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
  background: #7e8f9f;
  color: #e0e0e0;
}
.dark-theme .ant-calendar-month-panel-month:hover,
.dark-theme .ant-calendar-year-panel-year:hover,
.dark-theme .ant-calendar-decade-panel-decade:hover {
  background: #647586;
}
.dark-theme .ant-calendar-input {
  color: #e0e0e0;
  background-color: #4e5c69;
}
.dark-theme .ant-calendar-month-panel,
.dark-theme .ant-calendar-year-panel,
.dark-theme .ant-calendar-decade-panel {
  background-color: #4e5c69;
}
.dark-theme .ant-calendar-header,
.dark-theme .ant-calendar-input-wrap,
.dark-theme .ant-calendar-month-panel-header,
.dark-theme .ant-calendar-year-panel-header,
.dark-theme .ant-calendar-decade-panel-header {
  border-bottom-color: #546471;
}
.dark-theme .ant-calendar-footer,
.dark-theme .ant-calendar-range .ant-calendar-body,
.dark-theme .ant-calendar-range .ant-calendar-month-panel-body,
.dark-theme .ant-calendar-range .ant-calendar-year-panel-body {
  border-top-color: #546471;
}
.dark-theme .ant-calendar-picker-icon,
.dark-theme .ant-calendar-picker-icon:after {
  color: #e0e0e0;
}
.dark-theme .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #495762;
}
.dark-theme .ant-calendar-header .ant-calendar-prev-century-btn,
.dark-theme .ant-calendar-header .ant-calendar-next-century-btn,
.dark-theme .ant-calendar-header .ant-calendar-prev-decade-btn,
.dark-theme .ant-calendar-header .ant-calendar-next-decade-btn,
.dark-theme .ant-calendar-header .ant-calendar-prev-month-btn,
.dark-theme .ant-calendar-header .ant-calendar-next-month-btn,
.dark-theme .ant-calendar-header .ant-calendar-prev-year-btn,
.dark-theme .ant-calendar-header .ant-calendar-next-year-btn,
.dark-theme .ant-calendar-header .ant-calendar-century-select,
.dark-theme .ant-calendar-header .ant-calendar-decade-select,
.dark-theme .ant-calendar-header .ant-calendar-year-select,
.dark-theme .ant-calendar-header .ant-calendar-month-select {
  color: #e0e0e0;
}
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select,
.dark-theme .ant-calendar-month-panel-header .ant-calendar-month-panel-month-select {
  color: #e0e0e0;
}
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-year-select,
.dark-theme .ant-calendar-year-panel-header .ant-calendar-year-panel-month-select {
  color: #e0e0e0;
}
.dark-theme .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
.dark-theme .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
.dark-theme .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
.dark-theme .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
.dark-theme .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn,
.dark-theme .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn,
.dark-theme .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn,
.dark-theme .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
  color: #e0e0e0;
}
.dark-theme .ant-calendar-date {
  color: #e0e0e0;
}
.dark-theme .ant-calendar-date:hover {
  background: #647586;
}
.dark-theme .ant-calendar-last-month-cell .ant-calendar-date,
.dark-theme .ant-calendar-next-month-btn-day .ant-calendar-date,
.dark-theme .ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
.dark-theme .ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year,
.dark-theme .ant-calendar-decade-panel-last-century-cell .ant-calendar-decade-panel-decade,
.dark-theme .ant-calendar-decade-panel-next-century-cell .ant-calendar-decade-panel-decade {
  color: #adadad;
}
.dark-theme .ant-calendar-picker-clear {
  color: #e0e0e0;
  background: none;
}
.dark-theme .ant-calendar .ant-calendar-ok-btn {
  color: #495762 !important;
  background-color: #7e8f9f !important;
  border-color: #7e8f9f !important;
}
.dark-theme .ant-calendar .ant-calendar-ok-btn:hover,
.dark-theme .ant-calendar .ant-calendar-ok-btn:focus {
  color: #38424b !important;
  background-color: #9ba8b5 !important;
  border-color: #38424b !important;
}
.dark-theme .ant-calendar-range .ant-calendar-in-range-cell:before {
  background-color: #52616e;
}
.dark-theme .ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
  background: #52616e;
}
.dark-theme .ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day .ant-calendar-date,
.dark-theme .ant-calendar-week-number .ant-calendar-body tr .ant-calendar-selected-day:hover .ant-calendar-date,
.dark-theme .ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn-disabled {
  color: #e0e0e0;
}
.dark-theme .ant-fullcalendar {
  color: #e0e0e0;
  border-top-color: #495762;
}
.dark-theme .ant-fullcalendar-value {
  color: #e0e0e0;
}
.dark-theme .ant-fullcalendar-value:hover {
  background: #4e5c69;
}
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-month,
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  color: #e0e0e0;
  border-top-color: #546471;
}
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-month:hover,
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover,
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month,
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
  background: #4e5c69;
  color: #e0e0e0;
}
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-month,
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date {
  border-top-color: #546471;
}
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-last-month-cell .ant-fullcalendar-date,
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-date,
.dark-theme .ant-fullcalendar-last-month-cell .ant-fullcalendar-value,
.dark-theme .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-value {
  color: #adadad;
}
.dark-theme .ant-fullcalendar-selected-day .ant-fullcalendar-value,
.dark-theme .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value {
  background: #4e5c69;
  color: #e0e0e0;
}
.dark-theme .ant-fullcalendar-today .ant-fullcalendar-value,
.dark-theme .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value {
  box-shadow: 0 0 0 1px #7e8f9f inset;
  background: #7e8f9f;
}
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value,
.dark-theme .ant-fullcalendar-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-value {
  color: #e0e0e0;
  background-color: transparent;
  box-shadow: none;
}
.dark-theme .ant-time-picker-panel,
.dark-theme .ant-time-picker-icon,
.dark-theme .ant-time-picker-icon:after {
  color: #e0e0e0;
}
.dark-theme .ant-time-picker-panel-inner {
  background-color: #4e5c69;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.dark-theme .ant-time-picker-panel-input-wrap {
  border-bottom-color: #546471;
}
.dark-theme .ant-time-picker-panel-input-wrap .ant-time-picker-panel-input {
  background-color: transparent;
}
.dark-theme .ant-time-picker-panel-input-wrap .gx-link,
.dark-theme .ant-time-picker-panel-input-wrap .gx-link:hover,
.dark-theme .ant-time-picker-panel-input-wrap .gx-link:focus,
.dark-theme .ant-time-picker-panel-input-wrap .gx-link.ant-time-picker-panel-clear-btn:after {
  color: #e0e0e0;
}
.dark-theme .ant-time-picker-input {
  background-color: rgba(250, 250, 250, 0.1);
  border-color: rgba(250, 250, 250, 0.1);
  color: #e0e0e0;
}
.dark-theme .ant-time-picker-input:focus,
.dark-theme .ant-time-picker-input:hover {
  border-color: rgba(245, 245, 245, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}
.dark-theme .ant-time-picker-panel-select {
  border-left-color: #546471;
}
.dark-theme .ant-time-picker-panel-select li:hover {
  background: #52616e;
}
.dark-theme li.ant-time-picker-panel-select-option-selected {
  background: #546471;
}
.dark-theme .ant-slider-mark-text {
  color: #7a7a7a;
}
.dark-theme .ant-slider-mark-text-active {
  color: #ffffff;
}
.dark-theme .ant-slider-track {
  background-color: #4e5c69;
}
.dark-theme .ant-slider-active {
  border-color: #4e5c69;
}
.dark-theme .ant-radio {
  color: #e0e0e0;
}
.dark-theme .ant-radio-wrapper {
  color: #e0e0e0;
}
.dark-theme .ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
.dark-theme .ant-radio:hover .ant-radio-inner,
.dark-theme .ant-radio-focused .ant-radio-inner {
  border-color: #6f8294;
}
.dark-theme .ant-radio-checked:after {
  border-color: #6f8294;
}
.dark-theme .ant-radio-checked .ant-radio-inner {
  border-color: #6f8294;
}
.dark-theme .ant-radio-disabled + span {
  color: #e0e0e0;
}
.dark-theme .ant-radio-button-wrapper {
  color: #6f8294;
  background-color: transparent;
  border-color: #6f8294;
}
.dark-theme .ant-radio-button-wrapper:hover,
.dark-theme .ant-radio-button-wrapper:focus {
  color: #e0e0e0;
}
.dark-theme .ant-radio-button-wrapper-checked {
  box-shadow: none;
  border-color: #c7ced5;
  color: #c7ced5;
}
.dark-theme .ant-radio-button-wrapper-checked:hover,
.dark-theme .ant-radio-button-wrapper-checked:focus {
  color: #e0e0e0;
}
.dark-theme .ant-radio-button-wrapper-checked:first-child {
  border-color: #c7ced5;
  color: #c7ced5;
}
.dark-theme .ant-radio-button-wrapper:not(:first-child):before {
  background-color: #6f8294;
}
.dark-theme .ant-radio-button-wrapper-checked:before {
  background-color: #6f8294 !important;
}
.dark-theme .gx-radio-group-link-bg-light.ant-radio-group .ant-radio-button-wrapper {
  color: #6f8294;
}
.dark-theme .gx-radio-group-link-bg-light.ant-radio-group .ant-radio-button-wrapper:hover,
.dark-theme .gx-radio-group-link-bg-light.ant-radio-group .ant-radio-button-wrapper:focus {
  color: #596977;
}
.dark-theme .gx-radio-group-link-bg-light.ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  color: #e0e0e0;
  background-color: #13c2c2;
}
.dark-theme .gx-radio-group-link-bg-light.ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover,
.dark-theme .gx-radio-group-link-bg-light.ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:focus {
  color: #ffffff;
}
.dark-theme .ant-btn {
  background-color: #38424b;
  border-color: #38424b;
  color: #e0e0e0;
}
.dark-theme .ant-btn:hover,
.dark-theme .ant-btn:focus {
  color: #e0e0e0;
  background-color: #323a43;
  border-color: #323a43;
}
.dark-theme .ant-btn-primary {
  background-color: #323a43 !important;
  border-color: #323a43 !important;
}
.dark-theme .ant-btn-primary:hover,
.dark-theme .ant-btn-primary:focus {
  background-color: #293037 !important;
  border-color: #293037 !important;
}
.dark-theme .ant-btn-primary.disabled,
.dark-theme .ant-btn-primary:disabled {
  background-color: #2d353d !important;
  border-color: #2d353d !important;
}
.dark-theme .ant-btn-primary:not([disabled]):not(.disabled):active,
.dark-theme .ant-btn-primary:not([disabled]):not(.disabled).active {
  background-color: #293037 !important;
  border-color: #293037 !important;
}
.dark-theme .ant-btn-danger {
  color: #f44336;
  background-color: transparent;
  border-color: #f44336;
}
.dark-theme .ant-btn-danger:hover,
.dark-theme .ant-btn-danger:focus {
  color: #ffffff;
  background-color: #f44336;
  border-color: #f44336;
}
.dark-theme .ant-btn-background-ghost.ant-btn-primary {
  background-color: #323a43 !important;
  border-color: #323a43 !important;
  color: #ffffff !important;
}
.dark-theme .ant-btn-background-ghost.ant-btn-primary:hover,
.dark-theme .ant-btn-background-ghost.ant-btn-primary:focus {
  background-color: #293037 !important;
  border-color: #293037 !important;
  color: #ffffff !important;
}
.dark-theme .gx-header-search .ant-select-selection {
  background-color: #2d353d;
  border-color: #e8e8e8;
  color: #e0e0e0;
  margin-left: -1px;
}
.dark-theme .gx-header-search .ant-select-selection:focus {
  color: #e0e0e0;
  background-color: #22292e;
  border-color: #e8e8e8;
}
.dark-theme .gx-header-search .ant-select-arrow {
  color: #e0e0e0;
}
.dark-theme .ant-upload {
  color: #e0e0e0;
}
.dark-theme .ant-upload.ant-upload-drag {
  background-color: #3c4751;
  border-color: #3c4751;
  color: #e0e0e0;
}
.dark-theme .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #e0e0e0;
}
.dark-theme .ant-upload.ant-upload-drag p.ant-upload-text,
.dark-theme .ant-upload.ant-upload-drag p.ant-upload-hint {
  color: #e0e0e0;
}
.dark-theme .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #495762;
}
.dark-theme .ant-upload-list-item a,
.dark-theme .ant-upload-list-item-info .anticon-loading,
.dark-theme .ant-upload-list-item-info .anticon-paper-clip {
  color: #e0e0e0;
}
.dark-theme .ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #4e5c69;
}
.dark-theme .ant-upload.ant-upload-select-picture-card {
  background-color: #3c4751;
  border-color: #3c4751;
}
.dark-theme .ant-upload.ant-upload-select-picture-card:hover {
  border-color: #495762;
}
.dark-theme .ant-switch {
  color: #ffffff;
}
.dark-theme .ant-switch-checked {
  background-color: #38424b;
}
.dark-theme .gx-bg-grey {
  background-color: #999999 !important;
}
.dark-theme .ant-breadcrumb {
  color: #bfbfbf;
}
.dark-theme .ant-breadcrumb-separator,
.dark-theme .ant-breadcrumb > span:last-child {
  color: #bfbfbf;
}
.dark-theme .ant-dropdown {
  color: #e0e0e0;
}
.dark-theme .ant-dropdown-menu {
  background-color: #323a43;
}
.dark-theme .ant-dropdown-menu-item,
.dark-theme .ant-dropdown-menu-submenu-title {
  color: #e0e0e0;
}
.dark-theme .ant-dropdown-menu-item:hover,
.dark-theme .ant-dropdown-menu-submenu-title:hover {
  background-color: #475460;
}
.dark-theme .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow:after,
.dark-theme .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow:after {
  color: #e0e0e0;
}
.dark-theme .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.dark-theme .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after {
  color: #adadad;
}
.dark-theme .ant-steps-item {
  color: #e0e0e0;
}
.dark-theme .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #434f5a;
  border-color: #e0e0e0;
}
.dark-theme .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #e0e0e0;
}
.dark-theme .ant-steps-item-process .ant-steps-item-icon {
  background-color: #4e5c69;
  border-color: #4e5c69;
}
.dark-theme .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #e0e0e0;
}
.dark-theme .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #434f5a;
  border-color: #596977;
}
.dark-theme .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #596977;
}
.dark-theme .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-description,
.dark-theme .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-description,
.dark-theme .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-description {
  color: #adadad;
}
.dark-theme .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title,
.dark-theme .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title,
.dark-theme .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
  color: #e0e0e0;
}
.dark-theme .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title:after,
.dark-theme .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title:after,
.dark-theme .ant-steps-item-process > .ant-steps-item-tail:after,
.dark-theme .ant-steps-item-error > .ant-steps-item-content > .ant-steps-item-title:after {
  background-color: #495762;
}
.dark-theme .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title:after,
.dark-theme .ant-steps-item-finish > .ant-steps-item-tail:after {
  background-color: #e0e0e0;
}
.dark-theme .ant-steps-item-error .ant-steps-item-icon {
  background-color: #434f5a;
}
.dark-theme .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
.dark-theme .ant-steps-item-finish > .ant-steps-item-tail:after,
.dark-theme .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #22282e;
}
.dark-theme .ant-steps-item-process > .ant-steps-item-tail:after,
.dark-theme .ant-steps-item-wait > .ant-steps-item-tail:after {
  background-color: #546471;
}
.dark-theme .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #546471;
}
.dark-theme .steps-content {
  border-color: #495762;
  background-color: #4e5c69;
}
.dark-theme .ant-collapse {
  background-color: #4e5c69;
  border-color: #596977;
  color: #e0e0e0;
}
.dark-theme .ant-collapse.gx-collapse-custom {
  background-color: transparent;
}
.dark-theme .ant-collapse.gx-collapse-custom .ant-collapse-item {
  background-color: #4e5c69;
}
.dark-theme .ant-collapse > .ant-collapse-item {
  border-color: #596977;
}
.dark-theme .ant-collapse .ant-collapse {
  border-color: #758798;
}
.dark-theme .ant-collapse .ant-collapse > .ant-collapse-item {
  border-color: #758798;
}
.dark-theme .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #e0e0e0;
}
.dark-theme .ant-collapse-content {
  color: #e0e0e0;
  background-color: #5d6e7d;
  border-top-color: #596977;
}
.dark-theme .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.dark-theme .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: #7a7a7a;
}
.dark-theme .ant-carousel {
  color: #e0e0e0;
}
.dark-theme .ant-carousel .slick-slide {
  background-color: #4e5c69;
}
.dark-theme .ant-tree {
  color: #e0e0e0;
}
.dark-theme .ant-tree li .ant-tree-node-content-wrapper {
  color: #e0e0e0;
}
.dark-theme .ant-tree li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher),
.dark-theme .ant-tree li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper,
.dark-theme .ant-tree li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
  color: #7a7a7a;
}
.dark-theme .ant-tree li .ant-tree-node-content-wrapper:hover,
.dark-theme .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #4e5c69;
}
.dark-theme .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.dark-theme .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.dark-theme .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #38424b;
}
.dark-theme .ant-tree-checkbox-checked .ant-tree-checkbox-inner,
.dark-theme .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #38424b;
  border-color: #38424b !important;
}
.dark-theme .ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  background: transparent;
  color: #e0e0e0;
}
.dark-theme .ant-tag-checkable {
  color: #e0e0e0;
}
.dark-theme .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #ffffff;
}
.dark-theme .ant-progress-text,
.dark-theme .ant-progress-circle .ant-progress-text {
  color: #e0e0e0;
}
.dark-theme .ant-anchor-wrapper {
  background-color: transparent;
}
.dark-theme .ant-anchor-link-active > .ant-anchor-link-title {
  color: #c7c7c7;
}
.dark-theme .rdw-editor-wrapper {
  color: #474747;
  background-color: #fff;
}
.dark-theme .rdw-editor-wrapper + textarea {
  color: #474747;
}
.dark-theme .gx-contact-item:not(:last-child) {
  border-bottom-color: #495762;
}
.dark-theme .gx-draggable-icon {
  color: #e0e0e0;
}
.dark-theme .gx-draggable-icon:hover,
.dark-theme .gx-draggable-icon:focus,
.dark-theme .gx-draggable-icon:active {
  color: #ffffff;
}
.dark-theme .sweet-alert,
.dark-theme .gx-map-content {
  color: #474747;
}
.dark-theme .sweet-alert h1,
.dark-theme .gx-map-content h1,
.dark-theme .sweet-alert h2,
.dark-theme .gx-map-content h2,
.dark-theme .sweet-alert h3,
.dark-theme .gx-map-content h3,
.dark-theme .sweet-alert h4,
.dark-theme .gx-map-content h4,
.dark-theme .sweet-alert h5,
.dark-theme .gx-map-content h5,
.dark-theme .sweet-alert h6,
.dark-theme .gx-map-content h6,
.dark-theme .sweet-alert .h1,
.dark-theme .gx-map-content .h1,
.dark-theme .sweet-alert .h2,
.dark-theme .gx-map-content .h2,
.dark-theme .sweet-alert .h3,
.dark-theme .gx-map-content .h3,
.dark-theme .sweet-alert .h4,
.dark-theme .gx-map-content .h4,
.dark-theme .sweet-alert .h5,
.dark-theme .gx-map-content .h5,
.dark-theme .sweet-alert .h6,
.dark-theme .gx-map-content .h6 {
  color: #474747;
}
@media screen and (max-width: 991px) {
  .dark-theme .gx-module-side {
    background-color: #ffffff;
  }
}
.dark-theme .gx-module-side-header {
  border-bottom-color: #495762;
  background-color: #434f5a;
  color: #e0e0e0;
}
.dark-theme .gx-module-nav li .gx-link {
  color: #e0e0e0;
}
.dark-theme .gx-module-nav li .gx-link:hover,
.dark-theme .gx-module-nav li .gx-link:focus,
.dark-theme .gx-module-nav li .gx-link.active {
  color: #ffffff;
}
.dark-theme .gx-module-box-header {
  background-color: #434f5a;
  border-bottom-color: #495762;
}
@media screen and (max-width: 991px) {
  .dark-theme .gx-module-box-header .gx-drawer-btn {
    border-right-color: #495762;
  }
}
.dark-theme .gx-module-box-content {
  background-color: #434f5a;
}
.dark-theme .gx-module-box-topbar,
.dark-theme .gx-module-list-item:not(:last-child) {
  border-bottom-color: #495762;
}
.dark-theme .gx-toolbar-separator {
  border-left-color: #495762;
}
.dark-theme .gx-module-sidenav {
  background-color: #434f5a;
  border-right-color: #495762;
}
.dark-theme .rbc-event {
  background-color: #6f8294;
}
.dark-theme .rbc-event.rbc-selected {
  background-color: #2d353d;
}
.dark-theme .rbc-slot-selection {
  background-color: #596977;
}
.dark-theme .rbc-toolbar button {
  color: #e0e0e0;
  border-color: #e0e0e0;
}
.dark-theme .rbc-toolbar button:active,
.dark-theme .rbc-toolbar button.rbc-active,
.dark-theme .rbc-toolbar button:active:hover,
.dark-theme .rbc-toolbar button.rbc-active:hover,
.dark-theme .rbc-toolbar button:active:focus,
.dark-theme .rbc-toolbar button.rbc-active:focus,
.dark-theme .rbc-toolbar button:focus,
.dark-theme .rbc-toolbar button:hover {
  color: #38424b;
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}
.dark-theme .rbc-month-view,
.dark-theme .rbc-time-view,
.dark-theme .rbc-today {
  background: #434f5a;
}
.dark-theme .rbc-date-cell .gx-link {
  color: #e0e0e0;
}
.dark-theme .rbc-date-cell .gx-link:hover,
.dark-theme .rbc-date-cell .gx-link:focus {
  color: #ffffff;
}
.dark-theme .rbc-off-range-bg {
  background: #596977;
}
.dark-theme .rbc-off-range-bg .gx-link {
  color: #7a7a7a;
}
.dark-theme .rbc-off-range-bg .gx-link:hover,
.dark-theme .rbc-off-range-bg .gx-link:focus {
  color: #e0e0e0;
}
.dark-theme .gx-com-calendar-card {
  border-color: #495762;
}
.dark-theme .gx-chat-module-box {
  background-color: #434f5a;
}
.dark-theme .gx-chat-sidenav {
  border-right-color: #495762;
}
.dark-theme .gx-chat-sidenav-header {
  background-color: #4e5c69;
  border-bottom-color: #495762;
}
.dark-theme .gx-chat-sidenav-content {
  background-color: #434f5a;
}
.dark-theme .gx-chat-sidenav-title {
  color: #e0e0e0;
}
.dark-theme .gx-chat-tabs-header {
  background-color: #4e5c69 !important;
}
.dark-theme .gx-chat-user-item:not(:last-child) {
  border-bottom-color: #495762;
}
.dark-theme .gx-chat-user-item.active,
.dark-theme .gx-chat-user-item:hover {
  background-color: #4a5763;
}
.dark-theme .gx-chat-info-des {
  color: #e0e0e0;
}
.dark-theme .gx-chat-main-header {
  border-bottom-color: #495762;
  background-color: #434f5a;
}
.dark-theme .gx-chat-main-footer {
  border-top-color: #495762;
  background-color: #434f5a;
}
.dark-theme .gx-chat-item .gx-bubble {
  background-color: #475460;
  border-color: #495762;
}
.dark-theme .gx-chat-item.gx-flex-row-reverse .gx-bubble {
  background-color: #4e5c69;
}
.dark-theme .gx-error-code {
  color: #e0e0e0;
  text-shadow: 10px 6px 8px rgba(255, 117, 117, 0.8);
}
.dark-theme .gx-btn-yellow,
.dark-theme .gx-link.gx-btn-yellow {
  color: #262626 !important;
  background-color: #fadb14 !important;
  border-color: #fadb14 !important;
}
.dark-theme .gx-btn-yellow:hover,
.dark-theme .gx-link.gx-btn-yellow:hover,
.dark-theme .gx-btn-yellow:focus,
.dark-theme .gx-link.gx-btn-yellow:focus {
  color: #ffffff !important;
  background-color: #d6ba05 !important;
  border-color: #d6ba05 !important;
}
.dark-theme .gx-btn-yellow.disabled,
.dark-theme .gx-link.gx-btn-yellow.disabled,
.dark-theme .gx-btn-yellow:disabled,
.dark-theme .gx-link.gx-btn-yellow:disabled {
  background-color: #fadb14 !important;
  border-color: #fadb14 !important;
}
.dark-theme .gx-btn-yellow:not([disabled]):not(.disabled):active,
.dark-theme .gx-link.gx-btn-yellow:not([disabled]):not(.disabled):active,
.dark-theme .gx-btn-yellow:not([disabled]):not(.disabled).active,
.dark-theme .gx-link.gx-btn-yellow:not([disabled]):not(.disabled).active {
  color: #ffffff !important;
  background-color: #d6ba05 !important;
  border-color: #d6ba05 !important;
}
.dark-theme .gx-package {
  background-color: #ffffff;
  color: #595959;
}
.dark-theme .gx-package-header > .gx-price {
  color: #ffffff;
}
.dark-theme .gx-package-header.gx-text-white {
  color: #595959 !important;
}
.dark-theme .gx-customizer-item:not(:last-child) {
  border-bottom-color: #495762;
}
.dark-theme .ant-layout-sider-dark .gx-sidebar-notifications,
.dark-theme .gx-drawer-sidebar-dark .gx-sidebar-notifications {
  border-bottom-color: #495762;
}
.dark-theme .ant-layout-sider-dark .gx-app-nav,
.dark-theme .gx-drawer-sidebar-dark .gx-app-nav {
  color: #e0e0e0;
}
.dark-theme .gx-text-grey {
  color: #e0e0e0 !important;
}
.dark-theme .gx-currentplan-col .gx-bg-dark {
  background-color: #e0e0e0 !important;
}
.dark-theme .gx-chart-up {
  color: #52c41a;
}
.dark-theme .gx-bg-geekblue .gx-chart-up,
.dark-theme .gx-overlay-primary .gx-chart-up,
.dark-theme .gx-overlay-pink .gx-chart-down {
  color: #ffffff;
}
.dark-theme .gx-chart-down {
  color: #f5222d;
}
.dark-theme .gx-text-black {
  color: #ffffff !important;
}
.dark-theme .gx-algolia-sidebar .ais-HierarchicalMenu-list li a {
  color: #e0e0e0;
}
.dark-theme .gx-algolia-sidebar .ais-HierarchicalMenu-list li a:hover,
.dark-theme .gx-algolia-sidebar .ais-HierarchicalMenu-list li a:focus {
  color: #038fde;
}
.dark-theme .gx-algolia-sidebar .ais-HierarchicalMenu-count {
  color: #e0e0e0;
}
.dark-theme .ais-Breadcrumb,
.dark-theme .ais-ClearRefinements,
.dark-theme .ais-CurrentRefinements,
.dark-theme .ais-GeoSearch,
.dark-theme .ais-HierarchicalMenu,
.dark-theme .ais-Hits,
.dark-theme .ais-Results,
.dark-theme .ais-HitsPerPage,
.dark-theme .ais-ResultsPerPage,
.dark-theme .ais-InfiniteHits,
.dark-theme .ais-InfiniteResults,
.dark-theme .ais-Menu,
.dark-theme .ais-MenuSelect,
.dark-theme .ais-NumericMenu,
.dark-theme .ais-NumericSelector,
.dark-theme .ais-Pagination,
.dark-theme .ais-Panel,
.dark-theme .ais-PoweredBy,
.dark-theme .ais-RangeInput,
.dark-theme .ais-RangeSlider,
.dark-theme .ais-RatingMenu,
.dark-theme .ais-RefinementList,
.dark-theme .ais-RefinementList-count,
.dark-theme .ais-SearchBox,
.dark-theme .ais-SortBy,
.dark-theme .ais-Stats,
.dark-theme .ais-ToggleRefinement,
.dark-theme .ais-RatingMenu-count,
.dark-theme .gx-algolia-category-title {
  color: #e0e0e0;
}
.dark-theme .ais-SearchBox-input,
.dark-theme .ais-RangeInput-input,
.dark-theme .ais-MenuSelect-select,
.dark-theme .ais-NumericSelector-select,
.dark-theme .ais-HitsPerPage-select,
.dark-theme .ais-ResultsPerPage-select,
.dark-theme .ais-SortBy-select,
.dark-theme .gx-link.ais-Pagination-link:hover,
.dark-theme .gx-link.ais-Pagination-link:focus {
  color: #38424b;
}
.dark-theme .gx-link.ais-Pagination-link--selected:hover,
.dark-theme .gx-link.ais-Pagination-link--selected:focus {
  color: #ffffff;
}
.dark-theme .ais-RangeInput-submit,
.dark-theme .ais-ClearRefinements-button,
.dark-theme .ais-CurrentRefinements-reset,
.dark-theme .ais-GeoSearch-redo,
.dark-theme .ais-GeoSearch-reset,
.dark-theme .ais-HierarchicalMenu-showMore,
.dark-theme .ais-InfiniteHits-loadMore,
.dark-theme .ais-InfiniteResults-loadMore,
.dark-theme .ais-Menu-showMore,
.dark-theme .ais-RefinementList-showMore {
  background-color: #e0e0e0;
  color: #38424b;
}
.dark-theme .ais-RangeInput-submit:focus,
.dark-theme .ais-ClearRefinements-button:focus,
.dark-theme .ais-CurrentRefinements-reset:focus,
.dark-theme .ais-GeoSearch-redo:focus,
.dark-theme .ais-GeoSearch-reset:focus,
.dark-theme .ais-HierarchicalMenu-showMore:focus,
.dark-theme .ais-InfiniteHits-loadMore:focus,
.dark-theme .ais-InfiniteResults-loadMore:focus,
.dark-theme .ais-Menu-showMore:focus,
.dark-theme .ais-RefinementList-showMore:focus,
.dark-theme .ais-RangeInput-submit:hover,
.dark-theme .ais-ClearRefinements-button:hover,
.dark-theme .ais-CurrentRefinements-reset:hover,
.dark-theme .ais-GeoSearch-redo:hover,
.dark-theme .ais-GeoSearch-reset:hover,
.dark-theme .ais-HierarchicalMenu-showMore:hover,
.dark-theme .ais-InfiniteHits-loadMore:hover,
.dark-theme .ais-InfiniteResults-loadMore:hover,
.dark-theme .ais-Menu-showMore:hover,
.dark-theme .ais-RefinementList-showMore:hover {
  background-color: #e0e0e0;
}
.dark-theme .ais-Breadcrumb-link,
.dark-theme .ais-HierarchicalMenu-link,
.dark-theme .ais-Menu-link,
.dark-theme .ais-Pagination-link,
.dark-theme .ais-RatingMenu-link {
  color: #38424b;
}
.dark-theme .ais-Breadcrumb-link:focus,
.dark-theme .ais-HierarchicalMenu-link:focus,
.dark-theme .ais-Menu-link:focus,
.dark-theme .ais-Pagination-link:focus,
.dark-theme .ais-RatingMenu-link:focus,
.dark-theme .ais-Breadcrumb-link:hover,
.dark-theme .ais-HierarchicalMenu-link:hover,
.dark-theme .ais-Menu-link:hover,
.dark-theme .ais-Pagination-link:hover,
.dark-theme .ais-RatingMenu-link:hover {
  color: #38424b;
}
.dark-theme .ais-Pagination-item--selected .ais-Pagination-link {
  background-color: #038fde;
  border-color: #495762;
}
.dark-theme .gx-card-profile.gx-card-tabs .ant-card-head,
.dark-theme .gx-featured-item:not(:last-child) {
  border-bottom-color: #495762;
}
.dark-theme .gx-follower-list li,
.dark-theme .gx-follower-title {
  color: #e0e0e0;
}
.dark-theme .gx-header-horizontal-main .gx-header-notifications li > span {
  color: #e0e0e0;
}
.dark-theme .gx-profile-banner {
  background-color: #38424b;
}
.dark-theme .gx-text-primary {
  color: #13c2c2 !important;
}
.dark-theme .gx-text-primary.gx-icon-white {
  color: #ffffff !important;
}
.dark-theme .gx-link.gx-text-primary:hover,
.dark-theme .gx-link.gx-text-primary:focus {
  color: #006d75 !important;
}
.dark-theme .gx-bg-primary {
  background-color: #13c2c2 !important;
}
.dark-theme .gx-link.gx-bg-primary:hover,
.dark-theme .gx-link.gx-bg-primary:focus {
  background-color: #006d75 !important;
}
.dark-theme .gx-border-primary {
  border-color: #13c2c2 !important;
}
.dark-theme .gx-btn-primary,
.dark-theme .gx-link.gx-btn-primary,
.dark-theme .btn-primary {
  background-color: #13c2c2 !important;
  border-color: #13c2c2 !important;
}
.dark-theme .gx-btn-primary:hover,
.dark-theme .gx-link.gx-btn-primary:hover,
.dark-theme .btn-primary:hover,
.dark-theme .gx-btn-primary:focus,
.dark-theme .gx-link.gx-btn-primary:focus,
.dark-theme .btn-primary:focus {
  background-color: #006d75 !important;
  border-color: #006d75 !important;
}
.dark-theme .gx-btn-primary.disabled,
.dark-theme .gx-link.gx-btn-primary.disabled,
.dark-theme .btn-primary.disabled,
.dark-theme .gx-btn-primary:disabled,
.dark-theme .gx-link.gx-btn-primary:disabled,
.dark-theme .btn-primary:disabled {
  background-color: #13c2c2 !important;
  border-color: #13c2c2 !important;
}
.dark-theme .gx-btn-primary:not([disabled]):not(.disabled):active,
.dark-theme .gx-link.gx-btn-primary:not([disabled]):not(.disabled):active,
.dark-theme .btn-primary:not([disabled]):not(.disabled):active,
.dark-theme .gx-btn-primary:not([disabled]):not(.disabled).active,
.dark-theme .gx-link.gx-btn-primary:not([disabled]):not(.disabled).active,
.dark-theme .btn-primary:not([disabled]):not(.disabled).active {
  background-color: #006d75 !important;
  border-color: #006d75 !important;
}
.dark-theme .gx-btn-outline-primary,
.dark-theme .gx-link.gx-btn-outline-primary {
  color: #13c2c2 !important;
  border-color: #13c2c2 !important;
}
.dark-theme .gx-btn-outline-primary:hover,
.dark-theme .gx-link.gx-btn-outline-primary:hover,
.dark-theme .gx-btn-outline-primary:focus,
.dark-theme .gx-link.gx-btn-outline-primary:focus {
  color: #ffffff !important;
  background-color: #13c2c2 !important;
  border-color: #13c2c2 !important;
}
.dark-theme .gx-btn-outline-primary.disabled,
.dark-theme .gx-link.gx-btn-outline-primary.disabled,
.dark-theme .gx-btn-outline-primary:disabled,
.dark-theme .gx-link.gx-btn-outline-primary:disabled {
  color: #13c2c2 !important;
}
.dark-theme .gx-badge-primary,
.dark-theme .gx-entry-title:before {
  background-color: #13c2c2 !important;
}
.dark-theme .gx-bg-white .gx-text-grey {
  color: #38424b !important;
}
@media screen and (max-width: 991px) {
  .dark-theme .gx-popover-horizantal .gx-search-bar input[type="search"] {
    background: none !important;
  }
  .dark-theme .gx-popover-horizantal .gx-search-bar input[type="search"]:focus,
  .dark-theme .gx-popover-horizantal .gx-search-bar input[type="search"]:hover {
    box-shadow: none;
  }
  .dark-theme .gx-popover-horizantal .ant-btn {
    background-color: transparent;
    border-color: transparent;
  }
  .dark-theme .gx-popover-horizantal .ant-btn:hover,
  .dark-theme .gx-popover-horizantal .ant-btn:focus {
    background-color: transparent;
    border-color: transparent;
  }
}
@media screen and (max-width: 767px) {
  .dark-theme .gx-table-responsive .ant-table {
    border: #495762;
  }
  .dark-theme .gx-table-responsive .ant-table.ant-table-bordered .ant-table-footer {
    border-top-color: #495762;
  }
  .dark-theme .ant-card-bordered.gx-card-full .gx-table-responsive .ant-table {
    border-bottom-color: #495762;
  }
  .dark-theme .gx-timeline-center .gx-timeline-inverted:before {
    border-left-color: #495762;
  }
}
@media screen and (max-width: 475px) {
  .dark-theme .ant-input-group.ant-input-group-compact .gx-border-lt-xs {
    border-left-color: #495762 !important;
  }
}
@media screen and (max-width: 1200px) {
  .tickersWrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    position: absolute;
    top: 0.3rem;
    height: 2.5rem;
  }
}
@media screen and (min-width: 700px) and (max-width: 1000px) {
  .tickersWrapper {
    height: 2.2rem;
  }
}
@media screen and (max-width: 700px) {
  .tickersWrapper {
    top: 0.3rem;
    height: 2.2rem;
  }
}
.showWidget {
  display: block;
}
.hideWidget {
  display: none;
}
.gx-card-widget {
  overflow: hidden;
}
/*!
 * Font Awesome Pro 5.13.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(../../static/media/fa-brands-400.4a28c895.eot);
  src: url(../../static/media/fa-brands-400.4a28c895.eot?#iefix) format('embedded-opentype'), url(../../static/media/fa-brands-400.a0f6b35c.woff2) format('woff2'), url(../../static/media/fa-brands-400.d3a718bc.woff) format('woff'), url(../../static/media/fa-brands-400.77ace0fe.ttf) format('truetype'), url(../../static/media/fa-brands-400.82927ee1.svg#fontawesome) format('svg');
}
.fab {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}
/*!
 * Font Awesome Pro 5.13.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Duotone';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(../../static/media/fa-duotone-900.4486ac29.eot);
  src: url(../../static/media/fa-duotone-900.4486ac29.eot?#iefix) format('embedded-opentype'), url(../../static/media/fa-duotone-900.071dad85.woff2) format('woff2'), url(../../static/media/fa-duotone-900.52c4adbc.woff) format('woff'), url(../../static/media/fa-duotone-900.cf3b376d.ttf) format('truetype'), url(../../static/media/fa-duotone-900.5bafbe65.svg#fontawesome) format('svg');
}
.fad {
  position: relative;
  font-family: 'Font Awesome 5 Duotone';
  font-weight: 900;
}
.fad:before {
  position: absolute;
  color: inherit;
  color: var(--fa-primary-color, inherit);
  opacity: 1;
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1);
}
.fad:after {
  color: inherit;
  color: var(--fa-secondary-color, inherit);
  opacity: 0.4;
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4);
}
.fa-swap-opacity .fad:before,
.fad.fa-swap-opacity:before {
  opacity: 0.4;
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4);
}
.fa-swap-opacity .fad:after,
.fad.fa-swap-opacity:after {
  opacity: 1;
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1);
}
.fad.fa-inverse {
  color: #fff;
}
.fad.fa-stack-1x,
.fad.fa-stack-2x {
  position: absolute;
}
.fad.fa-stack-1x:before,
.fad.fa-stack-2x:before,
.fad.fa-fw:before {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.fad.fa-abacus:after {
  content: "\10F640";
}
.fad.fa-acorn:after {
  content: "\10F6AE";
}
.fad.fa-ad:after {
  content: "\10F641";
}
.fad.fa-address-book:after {
  content: "\10F2B9";
}
.fad.fa-address-card:after {
  content: "\10F2BB";
}
.fad.fa-adjust:after {
  content: "\10F042";
}
.fad.fa-air-conditioner:after {
  content: "\10F8F4";
}
.fad.fa-air-freshener:after {
  content: "\10F5D0";
}
.fad.fa-alarm-clock:after {
  content: "\10F34E";
}
.fad.fa-alarm-exclamation:after {
  content: "\10F843";
}
.fad.fa-alarm-plus:after {
  content: "\10F844";
}
.fad.fa-alarm-snooze:after {
  content: "\10F845";
}
.fad.fa-album:after {
  content: "\10F89F";
}
.fad.fa-album-collection:after {
  content: "\10F8A0";
}
.fad.fa-alicorn:after {
  content: "\10F6B0";
}
.fad.fa-alien:after {
  content: "\10F8F5";
}
.fad.fa-alien-monster:after {
  content: "\10F8F6";
}
.fad.fa-align-center:after {
  content: "\10F037";
}
.fad.fa-align-justify:after {
  content: "\10F039";
}
.fad.fa-align-left:after {
  content: "\10F036";
}
.fad.fa-align-right:after {
  content: "\10F038";
}
.fad.fa-align-slash:after {
  content: "\10F846";
}
.fad.fa-allergies:after {
  content: "\10F461";
}
.fad.fa-ambulance:after {
  content: "\10F0F9";
}
.fad.fa-american-sign-language-interpreting:after {
  content: "\10F2A3";
}
.fad.fa-amp-guitar:after {
  content: "\10F8A1";
}
.fad.fa-analytics:after {
  content: "\10F643";
}
.fad.fa-anchor:after {
  content: "\10F13D";
}
.fad.fa-angel:after {
  content: "\10F779";
}
.fad.fa-angle-double-down:after {
  content: "\10F103";
}
.fad.fa-angle-double-left:after {
  content: "\10F100";
}
.fad.fa-angle-double-right:after {
  content: "\10F101";
}
.fad.fa-angle-double-up:after {
  content: "\10F102";
}
.fad.fa-angle-down:after {
  content: "\10F107";
}
.fad.fa-angle-left:after {
  content: "\10F104";
}
.fad.fa-angle-right:after {
  content: "\10F105";
}
.fad.fa-angle-up:after {
  content: "\10F106";
}
.fad.fa-angry:after {
  content: "\10F556";
}
.fad.fa-ankh:after {
  content: "\10F644";
}
.fad.fa-apple-alt:after {
  content: "\10F5D1";
}
.fad.fa-apple-crate:after {
  content: "\10F6B1";
}
.fad.fa-archive:after {
  content: "\10F187";
}
.fad.fa-archway:after {
  content: "\10F557";
}
.fad.fa-arrow-alt-circle-down:after {
  content: "\10F358";
}
.fad.fa-arrow-alt-circle-left:after {
  content: "\10F359";
}
.fad.fa-arrow-alt-circle-right:after {
  content: "\10F35A";
}
.fad.fa-arrow-alt-circle-up:after {
  content: "\10F35B";
}
.fad.fa-arrow-alt-down:after {
  content: "\10F354";
}
.fad.fa-arrow-alt-from-bottom:after {
  content: "\10F346";
}
.fad.fa-arrow-alt-from-left:after {
  content: "\10F347";
}
.fad.fa-arrow-alt-from-right:after {
  content: "\10F348";
}
.fad.fa-arrow-alt-from-top:after {
  content: "\10F349";
}
.fad.fa-arrow-alt-left:after {
  content: "\10F355";
}
.fad.fa-arrow-alt-right:after {
  content: "\10F356";
}
.fad.fa-arrow-alt-square-down:after {
  content: "\10F350";
}
.fad.fa-arrow-alt-square-left:after {
  content: "\10F351";
}
.fad.fa-arrow-alt-square-right:after {
  content: "\10F352";
}
.fad.fa-arrow-alt-square-up:after {
  content: "\10F353";
}
.fad.fa-arrow-alt-to-bottom:after {
  content: "\10F34A";
}
.fad.fa-arrow-alt-to-left:after {
  content: "\10F34B";
}
.fad.fa-arrow-alt-to-right:after {
  content: "\10F34C";
}
.fad.fa-arrow-alt-to-top:after {
  content: "\10F34D";
}
.fad.fa-arrow-alt-up:after {
  content: "\10F357";
}
.fad.fa-arrow-circle-down:after {
  content: "\10F0AB";
}
.fad.fa-arrow-circle-left:after {
  content: "\10F0A8";
}
.fad.fa-arrow-circle-right:after {
  content: "\10F0A9";
}
.fad.fa-arrow-circle-up:after {
  content: "\10F0AA";
}
.fad.fa-arrow-down:after {
  content: "\10F063";
}
.fad.fa-arrow-from-bottom:after {
  content: "\10F342";
}
.fad.fa-arrow-from-left:after {
  content: "\10F343";
}
.fad.fa-arrow-from-right:after {
  content: "\10F344";
}
.fad.fa-arrow-from-top:after {
  content: "\10F345";
}
.fad.fa-arrow-left:after {
  content: "\10F060";
}
.fad.fa-arrow-right:after {
  content: "\10F061";
}
.fad.fa-arrow-square-down:after {
  content: "\10F339";
}
.fad.fa-arrow-square-left:after {
  content: "\10F33A";
}
.fad.fa-arrow-square-right:after {
  content: "\10F33B";
}
.fad.fa-arrow-square-up:after {
  content: "\10F33C";
}
.fad.fa-arrow-to-bottom:after {
  content: "\10F33D";
}
.fad.fa-arrow-to-left:after {
  content: "\10F33E";
}
.fad.fa-arrow-to-right:after {
  content: "\10F340";
}
.fad.fa-arrow-to-top:after {
  content: "\10F341";
}
.fad.fa-arrow-up:after {
  content: "\10F062";
}
.fad.fa-arrows:after {
  content: "\10F047";
}
.fad.fa-arrows-alt:after {
  content: "\10F0B2";
}
.fad.fa-arrows-alt-h:after {
  content: "\10F337";
}
.fad.fa-arrows-alt-v:after {
  content: "\10F338";
}
.fad.fa-arrows-h:after {
  content: "\10F07E";
}
.fad.fa-arrows-v:after {
  content: "\10F07D";
}
.fad.fa-assistive-listening-systems:after {
  content: "\10F2A2";
}
.fad.fa-asterisk:after {
  content: "\10F069";
}
.fad.fa-at:after {
  content: "\10F1FA";
}
.fad.fa-atlas:after {
  content: "\10F558";
}
.fad.fa-atom:after {
  content: "\10F5D2";
}
.fad.fa-atom-alt:after {
  content: "\10F5D3";
}
.fad.fa-audio-description:after {
  content: "\10F29E";
}
.fad.fa-award:after {
  content: "\10F559";
}
.fad.fa-axe:after {
  content: "\10F6B2";
}
.fad.fa-axe-battle:after {
  content: "\10F6B3";
}
.fad.fa-baby:after {
  content: "\10F77C";
}
.fad.fa-baby-carriage:after {
  content: "\10F77D";
}
.fad.fa-backpack:after {
  content: "\10F5D4";
}
.fad.fa-backspace:after {
  content: "\10F55A";
}
.fad.fa-backward:after {
  content: "\10F04A";
}
.fad.fa-bacon:after {
  content: "\10F7E5";
}
.fad.fa-bacteria:after {
  content: "\10F959";
}
.fad.fa-bacterium:after {
  content: "\10F95A";
}
.fad.fa-badge:after {
  content: "\10F335";
}
.fad.fa-badge-check:after {
  content: "\10F336";
}
.fad.fa-badge-dollar:after {
  content: "\10F645";
}
.fad.fa-badge-percent:after {
  content: "\10F646";
}
.fad.fa-badge-sheriff:after {
  content: "\10F8A2";
}
.fad.fa-badger-honey:after {
  content: "\10F6B4";
}
.fad.fa-bags-shopping:after {
  content: "\10F847";
}
.fad.fa-bahai:after {
  content: "\10F666";
}
.fad.fa-balance-scale:after {
  content: "\10F24E";
}
.fad.fa-balance-scale-left:after {
  content: "\10F515";
}
.fad.fa-balance-scale-right:after {
  content: "\10F516";
}
.fad.fa-ball-pile:after {
  content: "\10F77E";
}
.fad.fa-ballot:after {
  content: "\10F732";
}
.fad.fa-ballot-check:after {
  content: "\10F733";
}
.fad.fa-ban:after {
  content: "\10F05E";
}
.fad.fa-band-aid:after {
  content: "\10F462";
}
.fad.fa-banjo:after {
  content: "\10F8A3";
}
.fad.fa-barcode:after {
  content: "\10F02A";
}
.fad.fa-barcode-alt:after {
  content: "\10F463";
}
.fad.fa-barcode-read:after {
  content: "\10F464";
}
.fad.fa-barcode-scan:after {
  content: "\10F465";
}
.fad.fa-bars:after {
  content: "\10F0C9";
}
.fad.fa-baseball:after {
  content: "\10F432";
}
.fad.fa-baseball-ball:after {
  content: "\10F433";
}
.fad.fa-basketball-ball:after {
  content: "\10F434";
}
.fad.fa-basketball-hoop:after {
  content: "\10F435";
}
.fad.fa-bat:after {
  content: "\10F6B5";
}
.fad.fa-bath:after {
  content: "\10F2CD";
}
.fad.fa-battery-bolt:after {
  content: "\10F376";
}
.fad.fa-battery-empty:after {
  content: "\10F244";
}
.fad.fa-battery-full:after {
  content: "\10F240";
}
.fad.fa-battery-half:after {
  content: "\10F242";
}
.fad.fa-battery-quarter:after {
  content: "\10F243";
}
.fad.fa-battery-slash:after {
  content: "\10F377";
}
.fad.fa-battery-three-quarters:after {
  content: "\10F241";
}
.fad.fa-bed:after {
  content: "\10F236";
}
.fad.fa-bed-alt:after {
  content: "\10F8F7";
}
.fad.fa-bed-bunk:after {
  content: "\10F8F8";
}
.fad.fa-bed-empty:after {
  content: "\10F8F9";
}
.fad.fa-beer:after {
  content: "\10F0FC";
}
.fad.fa-bell:after {
  content: "\10F0F3";
}
.fad.fa-bell-exclamation:after {
  content: "\10F848";
}
.fad.fa-bell-on:after {
  content: "\10F8FA";
}
.fad.fa-bell-plus:after {
  content: "\10F849";
}
.fad.fa-bell-school:after {
  content: "\10F5D5";
}
.fad.fa-bell-school-slash:after {
  content: "\10F5D6";
}
.fad.fa-bell-slash:after {
  content: "\10F1F6";
}
.fad.fa-bells:after {
  content: "\10F77F";
}
.fad.fa-betamax:after {
  content: "\10F8A4";
}
.fad.fa-bezier-curve:after {
  content: "\10F55B";
}
.fad.fa-bible:after {
  content: "\10F647";
}
.fad.fa-bicycle:after {
  content: "\10F206";
}
.fad.fa-biking:after {
  content: "\10F84A";
}
.fad.fa-biking-mountain:after {
  content: "\10F84B";
}
.fad.fa-binoculars:after {
  content: "\10F1E5";
}
.fad.fa-biohazard:after {
  content: "\10F780";
}
.fad.fa-birthday-cake:after {
  content: "\10F1FD";
}
.fad.fa-blanket:after {
  content: "\10F498";
}
.fad.fa-blender:after {
  content: "\10F517";
}
.fad.fa-blender-phone:after {
  content: "\10F6B6";
}
.fad.fa-blind:after {
  content: "\10F29D";
}
.fad.fa-blinds:after {
  content: "\10F8FB";
}
.fad.fa-blinds-open:after {
  content: "\10F8FC";
}
.fad.fa-blinds-raised:after {
  content: "\10F8FD";
}
.fad.fa-blog:after {
  content: "\10F781";
}
.fad.fa-bold:after {
  content: "\10F032";
}
.fad.fa-bolt:after {
  content: "\10F0E7";
}
.fad.fa-bomb:after {
  content: "\10F1E2";
}
.fad.fa-bone:after {
  content: "\10F5D7";
}
.fad.fa-bone-break:after {
  content: "\10F5D8";
}
.fad.fa-bong:after {
  content: "\10F55C";
}
.fad.fa-book:after {
  content: "\10F02D";
}
.fad.fa-book-alt:after {
  content: "\10F5D9";
}
.fad.fa-book-dead:after {
  content: "\10F6B7";
}
.fad.fa-book-heart:after {
  content: "\10F499";
}
.fad.fa-book-medical:after {
  content: "\10F7E6";
}
.fad.fa-book-open:after {
  content: "\10F518";
}
.fad.fa-book-reader:after {
  content: "\10F5DA";
}
.fad.fa-book-spells:after {
  content: "\10F6B8";
}
.fad.fa-book-user:after {
  content: "\10F7E7";
}
.fad.fa-bookmark:after {
  content: "\10F02E";
}
.fad.fa-books:after {
  content: "\10F5DB";
}
.fad.fa-books-medical:after {
  content: "\10F7E8";
}
.fad.fa-boombox:after {
  content: "\10F8A5";
}
.fad.fa-boot:after {
  content: "\10F782";
}
.fad.fa-booth-curtain:after {
  content: "\10F734";
}
.fad.fa-border-all:after {
  content: "\10F84C";
}
.fad.fa-border-bottom:after {
  content: "\10F84D";
}
.fad.fa-border-center-h:after {
  content: "\10F89C";
}
.fad.fa-border-center-v:after {
  content: "\10F89D";
}
.fad.fa-border-inner:after {
  content: "\10F84E";
}
.fad.fa-border-left:after {
  content: "\10F84F";
}
.fad.fa-border-none:after {
  content: "\10F850";
}
.fad.fa-border-outer:after {
  content: "\10F851";
}
.fad.fa-border-right:after {
  content: "\10F852";
}
.fad.fa-border-style:after {
  content: "\10F853";
}
.fad.fa-border-style-alt:after {
  content: "\10F854";
}
.fad.fa-border-top:after {
  content: "\10F855";
}
.fad.fa-bow-arrow:after {
  content: "\10F6B9";
}
.fad.fa-bowling-ball:after {
  content: "\10F436";
}
.fad.fa-bowling-pins:after {
  content: "\10F437";
}
.fad.fa-box:after {
  content: "\10F466";
}
.fad.fa-box-alt:after {
  content: "\10F49A";
}
.fad.fa-box-ballot:after {
  content: "\10F735";
}
.fad.fa-box-check:after {
  content: "\10F467";
}
.fad.fa-box-fragile:after {
  content: "\10F49B";
}
.fad.fa-box-full:after {
  content: "\10F49C";
}
.fad.fa-box-heart:after {
  content: "\10F49D";
}
.fad.fa-box-open:after {
  content: "\10F49E";
}
.fad.fa-box-tissue:after {
  content: "\10F95B";
}
.fad.fa-box-up:after {
  content: "\10F49F";
}
.fad.fa-box-usd:after {
  content: "\10F4A0";
}
.fad.fa-boxes:after {
  content: "\10F468";
}
.fad.fa-boxes-alt:after {
  content: "\10F4A1";
}
.fad.fa-boxing-glove:after {
  content: "\10F438";
}
.fad.fa-brackets:after {
  content: "\10F7E9";
}
.fad.fa-brackets-curly:after {
  content: "\10F7EA";
}
.fad.fa-braille:after {
  content: "\10F2A1";
}
.fad.fa-brain:after {
  content: "\10F5DC";
}
.fad.fa-bread-loaf:after {
  content: "\10F7EB";
}
.fad.fa-bread-slice:after {
  content: "\10F7EC";
}
.fad.fa-briefcase:after {
  content: "\10F0B1";
}
.fad.fa-briefcase-medical:after {
  content: "\10F469";
}
.fad.fa-bring-forward:after {
  content: "\10F856";
}
.fad.fa-bring-front:after {
  content: "\10F857";
}
.fad.fa-broadcast-tower:after {
  content: "\10F519";
}
.fad.fa-broom:after {
  content: "\10F51A";
}
.fad.fa-browser:after {
  content: "\10F37E";
}
.fad.fa-brush:after {
  content: "\10F55D";
}
.fad.fa-bug:after {
  content: "\10F188";
}
.fad.fa-building:after {
  content: "\10F1AD";
}
.fad.fa-bullhorn:after {
  content: "\10F0A1";
}
.fad.fa-bullseye:after {
  content: "\10F140";
}
.fad.fa-bullseye-arrow:after {
  content: "\10F648";
}
.fad.fa-bullseye-pointer:after {
  content: "\10F649";
}
.fad.fa-burger-soda:after {
  content: "\10F858";
}
.fad.fa-burn:after {
  content: "\10F46A";
}
.fad.fa-burrito:after {
  content: "\10F7ED";
}
.fad.fa-bus:after {
  content: "\10F207";
}
.fad.fa-bus-alt:after {
  content: "\10F55E";
}
.fad.fa-bus-school:after {
  content: "\10F5DD";
}
.fad.fa-business-time:after {
  content: "\10F64A";
}
.fad.fa-cabinet-filing:after {
  content: "\10F64B";
}
.fad.fa-cactus:after {
  content: "\10F8A7";
}
.fad.fa-calculator:after {
  content: "\10F1EC";
}
.fad.fa-calculator-alt:after {
  content: "\10F64C";
}
.fad.fa-calendar:after {
  content: "\10F133";
}
.fad.fa-calendar-alt:after {
  content: "\10F073";
}
.fad.fa-calendar-check:after {
  content: "\10F274";
}
.fad.fa-calendar-day:after {
  content: "\10F783";
}
.fad.fa-calendar-edit:after {
  content: "\10F333";
}
.fad.fa-calendar-exclamation:after {
  content: "\10F334";
}
.fad.fa-calendar-minus:after {
  content: "\10F272";
}
.fad.fa-calendar-plus:after {
  content: "\10F271";
}
.fad.fa-calendar-star:after {
  content: "\10F736";
}
.fad.fa-calendar-times:after {
  content: "\10F273";
}
.fad.fa-calendar-week:after {
  content: "\10F784";
}
.fad.fa-camcorder:after {
  content: "\10F8A8";
}
.fad.fa-camera:after {
  content: "\10F030";
}
.fad.fa-camera-alt:after {
  content: "\10F332";
}
.fad.fa-camera-home:after {
  content: "\10F8FE";
}
.fad.fa-camera-movie:after {
  content: "\10F8A9";
}
.fad.fa-camera-polaroid:after {
  content: "\10F8AA";
}
.fad.fa-camera-retro:after {
  content: "\10F083";
}
.fad.fa-campfire:after {
  content: "\10F6BA";
}
.fad.fa-campground:after {
  content: "\10F6BB";
}
.fad.fa-candle-holder:after {
  content: "\10F6BC";
}
.fad.fa-candy-cane:after {
  content: "\10F786";
}
.fad.fa-candy-corn:after {
  content: "\10F6BD";
}
.fad.fa-cannabis:after {
  content: "\10F55F";
}
.fad.fa-capsules:after {
  content: "\10F46B";
}
.fad.fa-car:after {
  content: "\10F1B9";
}
.fad.fa-car-alt:after {
  content: "\10F5DE";
}
.fad.fa-car-battery:after {
  content: "\10F5DF";
}
.fad.fa-car-building:after {
  content: "\10F859";
}
.fad.fa-car-bump:after {
  content: "\10F5E0";
}
.fad.fa-car-bus:after {
  content: "\10F85A";
}
.fad.fa-car-crash:after {
  content: "\10F5E1";
}
.fad.fa-car-garage:after {
  content: "\10F5E2";
}
.fad.fa-car-mechanic:after {
  content: "\10F5E3";
}
.fad.fa-car-side:after {
  content: "\10F5E4";
}
.fad.fa-car-tilt:after {
  content: "\10F5E5";
}
.fad.fa-car-wash:after {
  content: "\10F5E6";
}
.fad.fa-caravan:after {
  content: "\10F8FF";
}
.fad.fa-caravan-alt:after {
  content: "\10F900";
}
.fad.fa-caret-circle-down:after {
  content: "\10F32D";
}
.fad.fa-caret-circle-left:after {
  content: "\10F32E";
}
.fad.fa-caret-circle-right:after {
  content: "\10F330";
}
.fad.fa-caret-circle-up:after {
  content: "\10F331";
}
.fad.fa-caret-down:after {
  content: "\10F0D7";
}
.fad.fa-caret-left:after {
  content: "\10F0D9";
}
.fad.fa-caret-right:after {
  content: "\10F0DA";
}
.fad.fa-caret-square-down:after {
  content: "\10F150";
}
.fad.fa-caret-square-left:after {
  content: "\10F191";
}
.fad.fa-caret-square-right:after {
  content: "\10F152";
}
.fad.fa-caret-square-up:after {
  content: "\10F151";
}
.fad.fa-caret-up:after {
  content: "\10F0D8";
}
.fad.fa-carrot:after {
  content: "\10F787";
}
.fad.fa-cars:after {
  content: "\10F85B";
}
.fad.fa-cart-arrow-down:after {
  content: "\10F218";
}
.fad.fa-cart-plus:after {
  content: "\10F217";
}
.fad.fa-cash-register:after {
  content: "\10F788";
}
.fad.fa-cassette-tape:after {
  content: "\10F8AB";
}
.fad.fa-cat:after {
  content: "\10F6BE";
}
.fad.fa-cat-space:after {
  content: "\10F901";
}
.fad.fa-cauldron:after {
  content: "\10F6BF";
}
.fad.fa-cctv:after {
  content: "\10F8AC";
}
.fad.fa-certificate:after {
  content: "\10F0A3";
}
.fad.fa-chair:after {
  content: "\10F6C0";
}
.fad.fa-chair-office:after {
  content: "\10F6C1";
}
.fad.fa-chalkboard:after {
  content: "\10F51B";
}
.fad.fa-chalkboard-teacher:after {
  content: "\10F51C";
}
.fad.fa-charging-station:after {
  content: "\10F5E7";
}
.fad.fa-chart-area:after {
  content: "\10F1FE";
}
.fad.fa-chart-bar:after {
  content: "\10F080";
}
.fad.fa-chart-line:after {
  content: "\10F201";
}
.fad.fa-chart-line-down:after {
  content: "\10F64D";
}
.fad.fa-chart-network:after {
  content: "\10F78A";
}
.fad.fa-chart-pie:after {
  content: "\10F200";
}
.fad.fa-chart-pie-alt:after {
  content: "\10F64E";
}
.fad.fa-chart-scatter:after {
  content: "\10F7EE";
}
.fad.fa-check:after {
  content: "\10F00C";
}
.fad.fa-check-circle:after {
  content: "\10F058";
}
.fad.fa-check-double:after {
  content: "\10F560";
}
.fad.fa-check-square:after {
  content: "\10F14A";
}
.fad.fa-cheese:after {
  content: "\10F7EF";
}
.fad.fa-cheese-swiss:after {
  content: "\10F7F0";
}
.fad.fa-cheeseburger:after {
  content: "\10F7F1";
}
.fad.fa-chess:after {
  content: "\10F439";
}
.fad.fa-chess-bishop:after {
  content: "\10F43A";
}
.fad.fa-chess-bishop-alt:after {
  content: "\10F43B";
}
.fad.fa-chess-board:after {
  content: "\10F43C";
}
.fad.fa-chess-clock:after {
  content: "\10F43D";
}
.fad.fa-chess-clock-alt:after {
  content: "\10F43E";
}
.fad.fa-chess-king:after {
  content: "\10F43F";
}
.fad.fa-chess-king-alt:after {
  content: "\10F440";
}
.fad.fa-chess-knight:after {
  content: "\10F441";
}
.fad.fa-chess-knight-alt:after {
  content: "\10F442";
}
.fad.fa-chess-pawn:after {
  content: "\10F443";
}
.fad.fa-chess-pawn-alt:after {
  content: "\10F444";
}
.fad.fa-chess-queen:after {
  content: "\10F445";
}
.fad.fa-chess-queen-alt:after {
  content: "\10F446";
}
.fad.fa-chess-rook:after {
  content: "\10F447";
}
.fad.fa-chess-rook-alt:after {
  content: "\10F448";
}
.fad.fa-chevron-circle-down:after {
  content: "\10F13A";
}
.fad.fa-chevron-circle-left:after {
  content: "\10F137";
}
.fad.fa-chevron-circle-right:after {
  content: "\10F138";
}
.fad.fa-chevron-circle-up:after {
  content: "\10F139";
}
.fad.fa-chevron-double-down:after {
  content: "\10F322";
}
.fad.fa-chevron-double-left:after {
  content: "\10F323";
}
.fad.fa-chevron-double-right:after {
  content: "\10F324";
}
.fad.fa-chevron-double-up:after {
  content: "\10F325";
}
.fad.fa-chevron-down:after {
  content: "\10F078";
}
.fad.fa-chevron-left:after {
  content: "\10F053";
}
.fad.fa-chevron-right:after {
  content: "\10F054";
}
.fad.fa-chevron-square-down:after {
  content: "\10F329";
}
.fad.fa-chevron-square-left:after {
  content: "\10F32A";
}
.fad.fa-chevron-square-right:after {
  content: "\10F32B";
}
.fad.fa-chevron-square-up:after {
  content: "\10F32C";
}
.fad.fa-chevron-up:after {
  content: "\10F077";
}
.fad.fa-child:after {
  content: "\10F1AE";
}
.fad.fa-chimney:after {
  content: "\10F78B";
}
.fad.fa-church:after {
  content: "\10F51D";
}
.fad.fa-circle:after {
  content: "\10F111";
}
.fad.fa-circle-notch:after {
  content: "\10F1CE";
}
.fad.fa-city:after {
  content: "\10F64F";
}
.fad.fa-clarinet:after {
  content: "\10F8AD";
}
.fad.fa-claw-marks:after {
  content: "\10F6C2";
}
.fad.fa-clinic-medical:after {
  content: "\10F7F2";
}
.fad.fa-clipboard:after {
  content: "\10F328";
}
.fad.fa-clipboard-check:after {
  content: "\10F46C";
}
.fad.fa-clipboard-list:after {
  content: "\10F46D";
}
.fad.fa-clipboard-list-check:after {
  content: "\10F737";
}
.fad.fa-clipboard-prescription:after {
  content: "\10F5E8";
}
.fad.fa-clipboard-user:after {
  content: "\10F7F3";
}
.fad.fa-clock:after {
  content: "\10F017";
}
.fad.fa-clone:after {
  content: "\10F24D";
}
.fad.fa-closed-captioning:after {
  content: "\10F20A";
}
.fad.fa-cloud:after {
  content: "\10F0C2";
}
.fad.fa-cloud-download:after {
  content: "\10F0ED";
}
.fad.fa-cloud-download-alt:after {
  content: "\10F381";
}
.fad.fa-cloud-drizzle:after {
  content: "\10F738";
}
.fad.fa-cloud-hail:after {
  content: "\10F739";
}
.fad.fa-cloud-hail-mixed:after {
  content: "\10F73A";
}
.fad.fa-cloud-meatball:after {
  content: "\10F73B";
}
.fad.fa-cloud-moon:after {
  content: "\10F6C3";
}
.fad.fa-cloud-moon-rain:after {
  content: "\10F73C";
}
.fad.fa-cloud-music:after {
  content: "\10F8AE";
}
.fad.fa-cloud-rain:after {
  content: "\10F73D";
}
.fad.fa-cloud-rainbow:after {
  content: "\10F73E";
}
.fad.fa-cloud-showers:after {
  content: "\10F73F";
}
.fad.fa-cloud-showers-heavy:after {
  content: "\10F740";
}
.fad.fa-cloud-sleet:after {
  content: "\10F741";
}
.fad.fa-cloud-snow:after {
  content: "\10F742";
}
.fad.fa-cloud-sun:after {
  content: "\10F6C4";
}
.fad.fa-cloud-sun-rain:after {
  content: "\10F743";
}
.fad.fa-cloud-upload:after {
  content: "\10F0EE";
}
.fad.fa-cloud-upload-alt:after {
  content: "\10F382";
}
.fad.fa-clouds:after {
  content: "\10F744";
}
.fad.fa-clouds-moon:after {
  content: "\10F745";
}
.fad.fa-clouds-sun:after {
  content: "\10F746";
}
.fad.fa-club:after {
  content: "\10F327";
}
.fad.fa-cocktail:after {
  content: "\10F561";
}
.fad.fa-code:after {
  content: "\10F121";
}
.fad.fa-code-branch:after {
  content: "\10F126";
}
.fad.fa-code-commit:after {
  content: "\10F386";
}
.fad.fa-code-merge:after {
  content: "\10F387";
}
.fad.fa-coffee:after {
  content: "\10F0F4";
}
.fad.fa-coffee-pot:after {
  content: "\10F902";
}
.fad.fa-coffee-togo:after {
  content: "\10F6C5";
}
.fad.fa-coffin:after {
  content: "\10F6C6";
}
.fad.fa-coffin-cross:after {
  content: "\10F951";
}
.fad.fa-cog:after {
  content: "\10F013";
}
.fad.fa-cogs:after {
  content: "\10F085";
}
.fad.fa-coin:after {
  content: "\10F85C";
}
.fad.fa-coins:after {
  content: "\10F51E";
}
.fad.fa-columns:after {
  content: "\10F0DB";
}
.fad.fa-comet:after {
  content: "\10F903";
}
.fad.fa-comment:after {
  content: "\10F075";
}
.fad.fa-comment-alt:after {
  content: "\10F27A";
}
.fad.fa-comment-alt-check:after {
  content: "\10F4A2";
}
.fad.fa-comment-alt-dollar:after {
  content: "\10F650";
}
.fad.fa-comment-alt-dots:after {
  content: "\10F4A3";
}
.fad.fa-comment-alt-edit:after {
  content: "\10F4A4";
}
.fad.fa-comment-alt-exclamation:after {
  content: "\10F4A5";
}
.fad.fa-comment-alt-lines:after {
  content: "\10F4A6";
}
.fad.fa-comment-alt-medical:after {
  content: "\10F7F4";
}
.fad.fa-comment-alt-minus:after {
  content: "\10F4A7";
}
.fad.fa-comment-alt-music:after {
  content: "\10F8AF";
}
.fad.fa-comment-alt-plus:after {
  content: "\10F4A8";
}
.fad.fa-comment-alt-slash:after {
  content: "\10F4A9";
}
.fad.fa-comment-alt-smile:after {
  content: "\10F4AA";
}
.fad.fa-comment-alt-times:after {
  content: "\10F4AB";
}
.fad.fa-comment-check:after {
  content: "\10F4AC";
}
.fad.fa-comment-dollar:after {
  content: "\10F651";
}
.fad.fa-comment-dots:after {
  content: "\10F4AD";
}
.fad.fa-comment-edit:after {
  content: "\10F4AE";
}
.fad.fa-comment-exclamation:after {
  content: "\10F4AF";
}
.fad.fa-comment-lines:after {
  content: "\10F4B0";
}
.fad.fa-comment-medical:after {
  content: "\10F7F5";
}
.fad.fa-comment-minus:after {
  content: "\10F4B1";
}
.fad.fa-comment-music:after {
  content: "\10F8B0";
}
.fad.fa-comment-plus:after {
  content: "\10F4B2";
}
.fad.fa-comment-slash:after {
  content: "\10F4B3";
}
.fad.fa-comment-smile:after {
  content: "\10F4B4";
}
.fad.fa-comment-times:after {
  content: "\10F4B5";
}
.fad.fa-comments:after {
  content: "\10F086";
}
.fad.fa-comments-alt:after {
  content: "\10F4B6";
}
.fad.fa-comments-alt-dollar:after {
  content: "\10F652";
}
.fad.fa-comments-dollar:after {
  content: "\10F653";
}
.fad.fa-compact-disc:after {
  content: "\10F51F";
}
.fad.fa-compass:after {
  content: "\10F14E";
}
.fad.fa-compass-slash:after {
  content: "\10F5E9";
}
.fad.fa-compress:after {
  content: "\10F066";
}
.fad.fa-compress-alt:after {
  content: "\10F422";
}
.fad.fa-compress-arrows-alt:after {
  content: "\10F78C";
}
.fad.fa-compress-wide:after {
  content: "\10F326";
}
.fad.fa-computer-classic:after {
  content: "\10F8B1";
}
.fad.fa-computer-speaker:after {
  content: "\10F8B2";
}
.fad.fa-concierge-bell:after {
  content: "\10F562";
}
.fad.fa-construction:after {
  content: "\10F85D";
}
.fad.fa-container-storage:after {
  content: "\10F4B7";
}
.fad.fa-conveyor-belt:after {
  content: "\10F46E";
}
.fad.fa-conveyor-belt-alt:after {
  content: "\10F46F";
}
.fad.fa-cookie:after {
  content: "\10F563";
}
.fad.fa-cookie-bite:after {
  content: "\10F564";
}
.fad.fa-copy:after {
  content: "\10F0C5";
}
.fad.fa-copyright:after {
  content: "\10F1F9";
}
.fad.fa-corn:after {
  content: "\10F6C7";
}
.fad.fa-couch:after {
  content: "\10F4B8";
}
.fad.fa-cow:after {
  content: "\10F6C8";
}
.fad.fa-cowbell:after {
  content: "\10F8B3";
}
.fad.fa-cowbell-more:after {
  content: "\10F8B4";
}
.fad.fa-credit-card:after {
  content: "\10F09D";
}
.fad.fa-credit-card-blank:after {
  content: "\10F389";
}
.fad.fa-credit-card-front:after {
  content: "\10F38A";
}
.fad.fa-cricket:after {
  content: "\10F449";
}
.fad.fa-croissant:after {
  content: "\10F7F6";
}
.fad.fa-crop:after {
  content: "\10F125";
}
.fad.fa-crop-alt:after {
  content: "\10F565";
}
.fad.fa-cross:after {
  content: "\10F654";
}
.fad.fa-crosshairs:after {
  content: "\10F05B";
}
.fad.fa-crow:after {
  content: "\10F520";
}
.fad.fa-crown:after {
  content: "\10F521";
}
.fad.fa-crutch:after {
  content: "\10F7F7";
}
.fad.fa-crutches:after {
  content: "\10F7F8";
}
.fad.fa-cube:after {
  content: "\10F1B2";
}
.fad.fa-cubes:after {
  content: "\10F1B3";
}
.fad.fa-curling:after {
  content: "\10F44A";
}
.fad.fa-cut:after {
  content: "\10F0C4";
}
.fad.fa-dagger:after {
  content: "\10F6CB";
}
.fad.fa-database:after {
  content: "\10F1C0";
}
.fad.fa-deaf:after {
  content: "\10F2A4";
}
.fad.fa-debug:after {
  content: "\10F7F9";
}
.fad.fa-deer:after {
  content: "\10F78E";
}
.fad.fa-deer-rudolph:after {
  content: "\10F78F";
}
.fad.fa-democrat:after {
  content: "\10F747";
}
.fad.fa-desktop:after {
  content: "\10F108";
}
.fad.fa-desktop-alt:after {
  content: "\10F390";
}
.fad.fa-dewpoint:after {
  content: "\10F748";
}
.fad.fa-dharmachakra:after {
  content: "\10F655";
}
.fad.fa-diagnoses:after {
  content: "\10F470";
}
.fad.fa-diamond:after {
  content: "\10F219";
}
.fad.fa-dice:after {
  content: "\10F522";
}
.fad.fa-dice-d10:after {
  content: "\10F6CD";
}
.fad.fa-dice-d12:after {
  content: "\10F6CE";
}
.fad.fa-dice-d20:after {
  content: "\10F6CF";
}
.fad.fa-dice-d4:after {
  content: "\10F6D0";
}
.fad.fa-dice-d6:after {
  content: "\10F6D1";
}
.fad.fa-dice-d8:after {
  content: "\10F6D2";
}
.fad.fa-dice-five:after {
  content: "\10F523";
}
.fad.fa-dice-four:after {
  content: "\10F524";
}
.fad.fa-dice-one:after {
  content: "\10F525";
}
.fad.fa-dice-six:after {
  content: "\10F526";
}
.fad.fa-dice-three:after {
  content: "\10F527";
}
.fad.fa-dice-two:after {
  content: "\10F528";
}
.fad.fa-digging:after {
  content: "\10F85E";
}
.fad.fa-digital-tachograph:after {
  content: "\10F566";
}
.fad.fa-diploma:after {
  content: "\10F5EA";
}
.fad.fa-directions:after {
  content: "\10F5EB";
}
.fad.fa-disc-drive:after {
  content: "\10F8B5";
}
.fad.fa-disease:after {
  content: "\10F7FA";
}
.fad.fa-divide:after {
  content: "\10F529";
}
.fad.fa-dizzy:after {
  content: "\10F567";
}
.fad.fa-dna:after {
  content: "\10F471";
}
.fad.fa-do-not-enter:after {
  content: "\10F5EC";
}
.fad.fa-dog:after {
  content: "\10F6D3";
}
.fad.fa-dog-leashed:after {
  content: "\10F6D4";
}
.fad.fa-dollar-sign:after {
  content: "\10F155";
}
.fad.fa-dolly:after {
  content: "\10F472";
}
.fad.fa-dolly-empty:after {
  content: "\10F473";
}
.fad.fa-dolly-flatbed:after {
  content: "\10F474";
}
.fad.fa-dolly-flatbed-alt:after {
  content: "\10F475";
}
.fad.fa-dolly-flatbed-empty:after {
  content: "\10F476";
}
.fad.fa-donate:after {
  content: "\10F4B9";
}
.fad.fa-door-closed:after {
  content: "\10F52A";
}
.fad.fa-door-open:after {
  content: "\10F52B";
}
.fad.fa-dot-circle:after {
  content: "\10F192";
}
.fad.fa-dove:after {
  content: "\10F4BA";
}
.fad.fa-download:after {
  content: "\10F019";
}
.fad.fa-drafting-compass:after {
  content: "\10F568";
}
.fad.fa-dragon:after {
  content: "\10F6D5";
}
.fad.fa-draw-circle:after {
  content: "\10F5ED";
}
.fad.fa-draw-polygon:after {
  content: "\10F5EE";
}
.fad.fa-draw-square:after {
  content: "\10F5EF";
}
.fad.fa-dreidel:after {
  content: "\10F792";
}
.fad.fa-drone:after {
  content: "\10F85F";
}
.fad.fa-drone-alt:after {
  content: "\10F860";
}
.fad.fa-drum:after {
  content: "\10F569";
}
.fad.fa-drum-steelpan:after {
  content: "\10F56A";
}
.fad.fa-drumstick:after {
  content: "\10F6D6";
}
.fad.fa-drumstick-bite:after {
  content: "\10F6D7";
}
.fad.fa-dryer:after {
  content: "\10F861";
}
.fad.fa-dryer-alt:after {
  content: "\10F862";
}
.fad.fa-duck:after {
  content: "\10F6D8";
}
.fad.fa-dumbbell:after {
  content: "\10F44B";
}
.fad.fa-dumpster:after {
  content: "\10F793";
}
.fad.fa-dumpster-fire:after {
  content: "\10F794";
}
.fad.fa-dungeon:after {
  content: "\10F6D9";
}
.fad.fa-ear:after {
  content: "\10F5F0";
}
.fad.fa-ear-muffs:after {
  content: "\10F795";
}
.fad.fa-eclipse:after {
  content: "\10F749";
}
.fad.fa-eclipse-alt:after {
  content: "\10F74A";
}
.fad.fa-edit:after {
  content: "\10F044";
}
.fad.fa-egg:after {
  content: "\10F7FB";
}
.fad.fa-egg-fried:after {
  content: "\10F7FC";
}
.fad.fa-eject:after {
  content: "\10F052";
}
.fad.fa-elephant:after {
  content: "\10F6DA";
}
.fad.fa-ellipsis-h:after {
  content: "\10F141";
}
.fad.fa-ellipsis-h-alt:after {
  content: "\10F39B";
}
.fad.fa-ellipsis-v:after {
  content: "\10F142";
}
.fad.fa-ellipsis-v-alt:after {
  content: "\10F39C";
}
.fad.fa-empty-set:after {
  content: "\10F656";
}
.fad.fa-engine-warning:after {
  content: "\10F5F2";
}
.fad.fa-envelope:after {
  content: "\10F0E0";
}
.fad.fa-envelope-open:after {
  content: "\10F2B6";
}
.fad.fa-envelope-open-dollar:after {
  content: "\10F657";
}
.fad.fa-envelope-open-text:after {
  content: "\10F658";
}
.fad.fa-envelope-square:after {
  content: "\10F199";
}
.fad.fa-equals:after {
  content: "\10F52C";
}
.fad.fa-eraser:after {
  content: "\10F12D";
}
.fad.fa-ethernet:after {
  content: "\10F796";
}
.fad.fa-euro-sign:after {
  content: "\10F153";
}
.fad.fa-exchange:after {
  content: "\10F0EC";
}
.fad.fa-exchange-alt:after {
  content: "\10F362";
}
.fad.fa-exclamation:after {
  content: "\10F12A";
}
.fad.fa-exclamation-circle:after {
  content: "\10F06A";
}
.fad.fa-exclamation-square:after {
  content: "\10F321";
}
.fad.fa-exclamation-triangle:after {
  content: "\10F071";
}
.fad.fa-expand:after {
  content: "\10F065";
}
.fad.fa-expand-alt:after {
  content: "\10F424";
}
.fad.fa-expand-arrows:after {
  content: "\10F31D";
}
.fad.fa-expand-arrows-alt:after {
  content: "\10F31E";
}
.fad.fa-expand-wide:after {
  content: "\10F320";
}
.fad.fa-external-link:after {
  content: "\10F08E";
}
.fad.fa-external-link-alt:after {
  content: "\10F35D";
}
.fad.fa-external-link-square:after {
  content: "\10F14C";
}
.fad.fa-external-link-square-alt:after {
  content: "\10F360";
}
.fad.fa-eye:after {
  content: "\10F06E";
}
.fad.fa-eye-dropper:after {
  content: "\10F1FB";
}
.fad.fa-eye-evil:after {
  content: "\10F6DB";
}
.fad.fa-eye-slash:after {
  content: "\10F070";
}
.fad.fa-fan:after {
  content: "\10F863";
}
.fad.fa-fan-table:after {
  content: "\10F904";
}
.fad.fa-farm:after {
  content: "\10F864";
}
.fad.fa-fast-backward:after {
  content: "\10F049";
}
.fad.fa-fast-forward:after {
  content: "\10F050";
}
.fad.fa-faucet:after {
  content: "\10F905";
}
.fad.fa-faucet-drip:after {
  content: "\10F906";
}
.fad.fa-fax:after {
  content: "\10F1AC";
}
.fad.fa-feather:after {
  content: "\10F52D";
}
.fad.fa-feather-alt:after {
  content: "\10F56B";
}
.fad.fa-female:after {
  content: "\10F182";
}
.fad.fa-field-hockey:after {
  content: "\10F44C";
}
.fad.fa-fighter-jet:after {
  content: "\10F0FB";
}
.fad.fa-file:after {
  content: "\10F15B";
}
.fad.fa-file-alt:after {
  content: "\10F15C";
}
.fad.fa-file-archive:after {
  content: "\10F1C6";
}
.fad.fa-file-audio:after {
  content: "\10F1C7";
}
.fad.fa-file-certificate:after {
  content: "\10F5F3";
}
.fad.fa-file-chart-line:after {
  content: "\10F659";
}
.fad.fa-file-chart-pie:after {
  content: "\10F65A";
}
.fad.fa-file-check:after {
  content: "\10F316";
}
.fad.fa-file-code:after {
  content: "\10F1C9";
}
.fad.fa-file-contract:after {
  content: "\10F56C";
}
.fad.fa-file-csv:after {
  content: "\10F6DD";
}
.fad.fa-file-download:after {
  content: "\10F56D";
}
.fad.fa-file-edit:after {
  content: "\10F31C";
}
.fad.fa-file-excel:after {
  content: "\10F1C3";
}
.fad.fa-file-exclamation:after {
  content: "\10F31A";
}
.fad.fa-file-export:after {
  content: "\10F56E";
}
.fad.fa-file-image:after {
  content: "\10F1C5";
}
.fad.fa-file-import:after {
  content: "\10F56F";
}
.fad.fa-file-invoice:after {
  content: "\10F570";
}
.fad.fa-file-invoice-dollar:after {
  content: "\10F571";
}
.fad.fa-file-medical:after {
  content: "\10F477";
}
.fad.fa-file-medical-alt:after {
  content: "\10F478";
}
.fad.fa-file-minus:after {
  content: "\10F318";
}
.fad.fa-file-music:after {
  content: "\10F8B6";
}
.fad.fa-file-pdf:after {
  content: "\10F1C1";
}
.fad.fa-file-plus:after {
  content: "\10F319";
}
.fad.fa-file-powerpoint:after {
  content: "\10F1C4";
}
.fad.fa-file-prescription:after {
  content: "\10F572";
}
.fad.fa-file-search:after {
  content: "\10F865";
}
.fad.fa-file-signature:after {
  content: "\10F573";
}
.fad.fa-file-spreadsheet:after {
  content: "\10F65B";
}
.fad.fa-file-times:after {
  content: "\10F317";
}
.fad.fa-file-upload:after {
  content: "\10F574";
}
.fad.fa-file-user:after {
  content: "\10F65C";
}
.fad.fa-file-video:after {
  content: "\10F1C8";
}
.fad.fa-file-word:after {
  content: "\10F1C2";
}
.fad.fa-files-medical:after {
  content: "\10F7FD";
}
.fad.fa-fill:after {
  content: "\10F575";
}
.fad.fa-fill-drip:after {
  content: "\10F576";
}
.fad.fa-film:after {
  content: "\10F008";
}
.fad.fa-film-alt:after {
  content: "\10F3A0";
}
.fad.fa-film-canister:after {
  content: "\10F8B7";
}
.fad.fa-filter:after {
  content: "\10F0B0";
}
.fad.fa-fingerprint:after {
  content: "\10F577";
}
.fad.fa-fire:after {
  content: "\10F06D";
}
.fad.fa-fire-alt:after {
  content: "\10F7E4";
}
.fad.fa-fire-extinguisher:after {
  content: "\10F134";
}
.fad.fa-fire-smoke:after {
  content: "\10F74B";
}
.fad.fa-fireplace:after {
  content: "\10F79A";
}
.fad.fa-first-aid:after {
  content: "\10F479";
}
.fad.fa-fish:after {
  content: "\10F578";
}
.fad.fa-fish-cooked:after {
  content: "\10F7FE";
}
.fad.fa-fist-raised:after {
  content: "\10F6DE";
}
.fad.fa-flag:after {
  content: "\10F024";
}
.fad.fa-flag-alt:after {
  content: "\10F74C";
}
.fad.fa-flag-checkered:after {
  content: "\10F11E";
}
.fad.fa-flag-usa:after {
  content: "\10F74D";
}
.fad.fa-flame:after {
  content: "\10F6DF";
}
.fad.fa-flashlight:after {
  content: "\10F8B8";
}
.fad.fa-flask:after {
  content: "\10F0C3";
}
.fad.fa-flask-poison:after {
  content: "\10F6E0";
}
.fad.fa-flask-potion:after {
  content: "\10F6E1";
}
.fad.fa-flower:after {
  content: "\10F7FF";
}
.fad.fa-flower-daffodil:after {
  content: "\10F800";
}
.fad.fa-flower-tulip:after {
  content: "\10F801";
}
.fad.fa-flushed:after {
  content: "\10F579";
}
.fad.fa-flute:after {
  content: "\10F8B9";
}
.fad.fa-flux-capacitor:after {
  content: "\10F8BA";
}
.fad.fa-fog:after {
  content: "\10F74E";
}
.fad.fa-folder:after {
  content: "\10F07B";
}
.fad.fa-folder-download:after {
  content: "\10F953";
}
.fad.fa-folder-minus:after {
  content: "\10F65D";
}
.fad.fa-folder-open:after {
  content: "\10F07C";
}
.fad.fa-folder-plus:after {
  content: "\10F65E";
}
.fad.fa-folder-times:after {
  content: "\10F65F";
}
.fad.fa-folder-tree:after {
  content: "\10F802";
}
.fad.fa-folder-upload:after {
  content: "\10F954";
}
.fad.fa-folders:after {
  content: "\10F660";
}
.fad.fa-font:after {
  content: "\10F031";
}
.fad.fa-font-awesome-logo-full:after {
  content: "\10F4E6";
}
.fad.fa-font-case:after {
  content: "\10F866";
}
.fad.fa-football-ball:after {
  content: "\10F44E";
}
.fad.fa-football-helmet:after {
  content: "\10F44F";
}
.fad.fa-forklift:after {
  content: "\10F47A";
}
.fad.fa-forward:after {
  content: "\10F04E";
}
.fad.fa-fragile:after {
  content: "\10F4BB";
}
.fad.fa-french-fries:after {
  content: "\10F803";
}
.fad.fa-frog:after {
  content: "\10F52E";
}
.fad.fa-frosty-head:after {
  content: "\10F79B";
}
.fad.fa-frown:after {
  content: "\10F119";
}
.fad.fa-frown-open:after {
  content: "\10F57A";
}
.fad.fa-function:after {
  content: "\10F661";
}
.fad.fa-funnel-dollar:after {
  content: "\10F662";
}
.fad.fa-futbol:after {
  content: "\10F1E3";
}
.fad.fa-galaxy:after {
  content: "\10F908";
}
.fad.fa-game-board:after {
  content: "\10F867";
}
.fad.fa-game-board-alt:after {
  content: "\10F868";
}
.fad.fa-game-console-handheld:after {
  content: "\10F8BB";
}
.fad.fa-gamepad:after {
  content: "\10F11B";
}
.fad.fa-gamepad-alt:after {
  content: "\10F8BC";
}
.fad.fa-garage:after {
  content: "\10F909";
}
.fad.fa-garage-car:after {
  content: "\10F90A";
}
.fad.fa-garage-open:after {
  content: "\10F90B";
}
.fad.fa-gas-pump:after {
  content: "\10F52F";
}
.fad.fa-gas-pump-slash:after {
  content: "\10F5F4";
}
.fad.fa-gavel:after {
  content: "\10F0E3";
}
.fad.fa-gem:after {
  content: "\10F3A5";
}
.fad.fa-genderless:after {
  content: "\10F22D";
}
.fad.fa-ghost:after {
  content: "\10F6E2";
}
.fad.fa-gift:after {
  content: "\10F06B";
}
.fad.fa-gift-card:after {
  content: "\10F663";
}
.fad.fa-gifts:after {
  content: "\10F79C";
}
.fad.fa-gingerbread-man:after {
  content: "\10F79D";
}
.fad.fa-glass:after {
  content: "\10F804";
}
.fad.fa-glass-champagne:after {
  content: "\10F79E";
}
.fad.fa-glass-cheers:after {
  content: "\10F79F";
}
.fad.fa-glass-citrus:after {
  content: "\10F869";
}
.fad.fa-glass-martini:after {
  content: "\10F000";
}
.fad.fa-glass-martini-alt:after {
  content: "\10F57B";
}
.fad.fa-glass-whiskey:after {
  content: "\10F7A0";
}
.fad.fa-glass-whiskey-rocks:after {
  content: "\10F7A1";
}
.fad.fa-glasses:after {
  content: "\10F530";
}
.fad.fa-glasses-alt:after {
  content: "\10F5F5";
}
.fad.fa-globe:after {
  content: "\10F0AC";
}
.fad.fa-globe-africa:after {
  content: "\10F57C";
}
.fad.fa-globe-americas:after {
  content: "\10F57D";
}
.fad.fa-globe-asia:after {
  content: "\10F57E";
}
.fad.fa-globe-europe:after {
  content: "\10F7A2";
}
.fad.fa-globe-snow:after {
  content: "\10F7A3";
}
.fad.fa-globe-stand:after {
  content: "\10F5F6";
}
.fad.fa-golf-ball:after {
  content: "\10F450";
}
.fad.fa-golf-club:after {
  content: "\10F451";
}
.fad.fa-gopuram:after {
  content: "\10F664";
}
.fad.fa-graduation-cap:after {
  content: "\10F19D";
}
.fad.fa-gramophone:after {
  content: "\10F8BD";
}
.fad.fa-greater-than:after {
  content: "\10F531";
}
.fad.fa-greater-than-equal:after {
  content: "\10F532";
}
.fad.fa-grimace:after {
  content: "\10F57F";
}
.fad.fa-grin:after {
  content: "\10F580";
}
.fad.fa-grin-alt:after {
  content: "\10F581";
}
.fad.fa-grin-beam:after {
  content: "\10F582";
}
.fad.fa-grin-beam-sweat:after {
  content: "\10F583";
}
.fad.fa-grin-hearts:after {
  content: "\10F584";
}
.fad.fa-grin-squint:after {
  content: "\10F585";
}
.fad.fa-grin-squint-tears:after {
  content: "\10F586";
}
.fad.fa-grin-stars:after {
  content: "\10F587";
}
.fad.fa-grin-tears:after {
  content: "\10F588";
}
.fad.fa-grin-tongue:after {
  content: "\10F589";
}
.fad.fa-grin-tongue-squint:after {
  content: "\10F58A";
}
.fad.fa-grin-tongue-wink:after {
  content: "\10F58B";
}
.fad.fa-grin-wink:after {
  content: "\10F58C";
}
.fad.fa-grip-horizontal:after {
  content: "\10F58D";
}
.fad.fa-grip-lines:after {
  content: "\10F7A4";
}
.fad.fa-grip-lines-vertical:after {
  content: "\10F7A5";
}
.fad.fa-grip-vertical:after {
  content: "\10F58E";
}
.fad.fa-guitar:after {
  content: "\10F7A6";
}
.fad.fa-guitar-electric:after {
  content: "\10F8BE";
}
.fad.fa-guitars:after {
  content: "\10F8BF";
}
.fad.fa-h-square:after {
  content: "\10F0FD";
}
.fad.fa-h1:after {
  content: "\10F313";
}
.fad.fa-h2:after {
  content: "\10F314";
}
.fad.fa-h3:after {
  content: "\10F315";
}
.fad.fa-h4:after {
  content: "\10F86A";
}
.fad.fa-hamburger:after {
  content: "\10F805";
}
.fad.fa-hammer:after {
  content: "\10F6E3";
}
.fad.fa-hammer-war:after {
  content: "\10F6E4";
}
.fad.fa-hamsa:after {
  content: "\10F665";
}
.fad.fa-hand-heart:after {
  content: "\10F4BC";
}
.fad.fa-hand-holding:after {
  content: "\10F4BD";
}
.fad.fa-hand-holding-box:after {
  content: "\10F47B";
}
.fad.fa-hand-holding-heart:after {
  content: "\10F4BE";
}
.fad.fa-hand-holding-magic:after {
  content: "\10F6E5";
}
.fad.fa-hand-holding-medical:after {
  content: "\10F95C";
}
.fad.fa-hand-holding-seedling:after {
  content: "\10F4BF";
}
.fad.fa-hand-holding-usd:after {
  content: "\10F4C0";
}
.fad.fa-hand-holding-water:after {
  content: "\10F4C1";
}
.fad.fa-hand-lizard:after {
  content: "\10F258";
}
.fad.fa-hand-middle-finger:after {
  content: "\10F806";
}
.fad.fa-hand-paper:after {
  content: "\10F256";
}
.fad.fa-hand-peace:after {
  content: "\10F25B";
}
.fad.fa-hand-point-down:after {
  content: "\10F0A7";
}
.fad.fa-hand-point-left:after {
  content: "\10F0A5";
}
.fad.fa-hand-point-right:after {
  content: "\10F0A4";
}
.fad.fa-hand-point-up:after {
  content: "\10F0A6";
}
.fad.fa-hand-pointer:after {
  content: "\10F25A";
}
.fad.fa-hand-receiving:after {
  content: "\10F47C";
}
.fad.fa-hand-rock:after {
  content: "\10F255";
}
.fad.fa-hand-scissors:after {
  content: "\10F257";
}
.fad.fa-hand-sparkles:after {
  content: "\10F95D";
}
.fad.fa-hand-spock:after {
  content: "\10F259";
}
.fad.fa-hands:after {
  content: "\10F4C2";
}
.fad.fa-hands-heart:after {
  content: "\10F4C3";
}
.fad.fa-hands-helping:after {
  content: "\10F4C4";
}
.fad.fa-hands-usd:after {
  content: "\10F4C5";
}
.fad.fa-hands-wash:after {
  content: "\10F95E";
}
.fad.fa-handshake:after {
  content: "\10F2B5";
}
.fad.fa-handshake-alt:after {
  content: "\10F4C6";
}
.fad.fa-handshake-alt-slash:after {
  content: "\10F95F";
}
.fad.fa-handshake-slash:after {
  content: "\10F960";
}
.fad.fa-hanukiah:after {
  content: "\10F6E6";
}
.fad.fa-hard-hat:after {
  content: "\10F807";
}
.fad.fa-hashtag:after {
  content: "\10F292";
}
.fad.fa-hat-chef:after {
  content: "\10F86B";
}
.fad.fa-hat-cowboy:after {
  content: "\10F8C0";
}
.fad.fa-hat-cowboy-side:after {
  content: "\10F8C1";
}
.fad.fa-hat-santa:after {
  content: "\10F7A7";
}
.fad.fa-hat-winter:after {
  content: "\10F7A8";
}
.fad.fa-hat-witch:after {
  content: "\10F6E7";
}
.fad.fa-hat-wizard:after {
  content: "\10F6E8";
}
.fad.fa-hdd:after {
  content: "\10F0A0";
}
.fad.fa-head-side:after {
  content: "\10F6E9";
}
.fad.fa-head-side-brain:after {
  content: "\10F808";
}
.fad.fa-head-side-cough:after {
  content: "\10F961";
}
.fad.fa-head-side-cough-slash:after {
  content: "\10F962";
}
.fad.fa-head-side-headphones:after {
  content: "\10F8C2";
}
.fad.fa-head-side-mask:after {
  content: "\10F963";
}
.fad.fa-head-side-medical:after {
  content: "\10F809";
}
.fad.fa-head-side-virus:after {
  content: "\10F964";
}
.fad.fa-head-vr:after {
  content: "\10F6EA";
}
.fad.fa-heading:after {
  content: "\10F1DC";
}
.fad.fa-headphones:after {
  content: "\10F025";
}
.fad.fa-headphones-alt:after {
  content: "\10F58F";
}
.fad.fa-headset:after {
  content: "\10F590";
}
.fad.fa-heart:after {
  content: "\10F004";
}
.fad.fa-heart-broken:after {
  content: "\10F7A9";
}
.fad.fa-heart-circle:after {
  content: "\10F4C7";
}
.fad.fa-heart-rate:after {
  content: "\10F5F8";
}
.fad.fa-heart-square:after {
  content: "\10F4C8";
}
.fad.fa-heartbeat:after {
  content: "\10F21E";
}
.fad.fa-heat:after {
  content: "\10F90C";
}
.fad.fa-helicopter:after {
  content: "\10F533";
}
.fad.fa-helmet-battle:after {
  content: "\10F6EB";
}
.fad.fa-hexagon:after {
  content: "\10F312";
}
.fad.fa-highlighter:after {
  content: "\10F591";
}
.fad.fa-hiking:after {
  content: "\10F6EC";
}
.fad.fa-hippo:after {
  content: "\10F6ED";
}
.fad.fa-history:after {
  content: "\10F1DA";
}
.fad.fa-hockey-mask:after {
  content: "\10F6EE";
}
.fad.fa-hockey-puck:after {
  content: "\10F453";
}
.fad.fa-hockey-sticks:after {
  content: "\10F454";
}
.fad.fa-holly-berry:after {
  content: "\10F7AA";
}
.fad.fa-home:after {
  content: "\10F015";
}
.fad.fa-home-alt:after {
  content: "\10F80A";
}
.fad.fa-home-heart:after {
  content: "\10F4C9";
}
.fad.fa-home-lg:after {
  content: "\10F80B";
}
.fad.fa-home-lg-alt:after {
  content: "\10F80C";
}
.fad.fa-hood-cloak:after {
  content: "\10F6EF";
}
.fad.fa-horizontal-rule:after {
  content: "\10F86C";
}
.fad.fa-horse:after {
  content: "\10F6F0";
}
.fad.fa-horse-head:after {
  content: "\10F7AB";
}
.fad.fa-horse-saddle:after {
  content: "\10F8C3";
}
.fad.fa-hospital:after {
  content: "\10F0F8";
}
.fad.fa-hospital-alt:after {
  content: "\10F47D";
}
.fad.fa-hospital-symbol:after {
  content: "\10F47E";
}
.fad.fa-hospital-user:after {
  content: "\10F80D";
}
.fad.fa-hospitals:after {
  content: "\10F80E";
}
.fad.fa-hot-tub:after {
  content: "\10F593";
}
.fad.fa-hotdog:after {
  content: "\10F80F";
}
.fad.fa-hotel:after {
  content: "\10F594";
}
.fad.fa-hourglass:after {
  content: "\10F254";
}
.fad.fa-hourglass-end:after {
  content: "\10F253";
}
.fad.fa-hourglass-half:after {
  content: "\10F252";
}
.fad.fa-hourglass-start:after {
  content: "\10F251";
}
.fad.fa-house:after {
  content: "\10F90D";
}
.fad.fa-house-damage:after {
  content: "\10F6F1";
}
.fad.fa-house-day:after {
  content: "\10F90E";
}
.fad.fa-house-flood:after {
  content: "\10F74F";
}
.fad.fa-house-leave:after {
  content: "\10F90F";
}
.fad.fa-house-night:after {
  content: "\10F910";
}
.fad.fa-house-return:after {
  content: "\10F911";
}
.fad.fa-house-signal:after {
  content: "\10F912";
}
.fad.fa-house-user:after {
  content: "\10F965";
}
.fad.fa-hryvnia:after {
  content: "\10F6F2";
}
.fad.fa-humidity:after {
  content: "\10F750";
}
.fad.fa-hurricane:after {
  content: "\10F751";
}
.fad.fa-i-cursor:after {
  content: "\10F246";
}
.fad.fa-ice-cream:after {
  content: "\10F810";
}
.fad.fa-ice-skate:after {
  content: "\10F7AC";
}
.fad.fa-icicles:after {
  content: "\10F7AD";
}
.fad.fa-icons:after {
  content: "\10F86D";
}
.fad.fa-icons-alt:after {
  content: "\10F86E";
}
.fad.fa-id-badge:after {
  content: "\10F2C1";
}
.fad.fa-id-card:after {
  content: "\10F2C2";
}
.fad.fa-id-card-alt:after {
  content: "\10F47F";
}
.fad.fa-igloo:after {
  content: "\10F7AE";
}
.fad.fa-image:after {
  content: "\10F03E";
}
.fad.fa-image-polaroid:after {
  content: "\10F8C4";
}
.fad.fa-images:after {
  content: "\10F302";
}
.fad.fa-inbox:after {
  content: "\10F01C";
}
.fad.fa-inbox-in:after {
  content: "\10F310";
}
.fad.fa-inbox-out:after {
  content: "\10F311";
}
.fad.fa-indent:after {
  content: "\10F03C";
}
.fad.fa-industry:after {
  content: "\10F275";
}
.fad.fa-industry-alt:after {
  content: "\10F3B3";
}
.fad.fa-infinity:after {
  content: "\10F534";
}
.fad.fa-info:after {
  content: "\10F129";
}
.fad.fa-info-circle:after {
  content: "\10F05A";
}
.fad.fa-info-square:after {
  content: "\10F30F";
}
.fad.fa-inhaler:after {
  content: "\10F5F9";
}
.fad.fa-integral:after {
  content: "\10F667";
}
.fad.fa-intersection:after {
  content: "\10F668";
}
.fad.fa-inventory:after {
  content: "\10F480";
}
.fad.fa-island-tropical:after {
  content: "\10F811";
}
.fad.fa-italic:after {
  content: "\10F033";
}
.fad.fa-jack-o-lantern:after {
  content: "\10F30E";
}
.fad.fa-jedi:after {
  content: "\10F669";
}
.fad.fa-joint:after {
  content: "\10F595";
}
.fad.fa-journal-whills:after {
  content: "\10F66A";
}
.fad.fa-joystick:after {
  content: "\10F8C5";
}
.fad.fa-jug:after {
  content: "\10F8C6";
}
.fad.fa-kaaba:after {
  content: "\10F66B";
}
.fad.fa-kazoo:after {
  content: "\10F8C7";
}
.fad.fa-kerning:after {
  content: "\10F86F";
}
.fad.fa-key:after {
  content: "\10F084";
}
.fad.fa-key-skeleton:after {
  content: "\10F6F3";
}
.fad.fa-keyboard:after {
  content: "\10F11C";
}
.fad.fa-keynote:after {
  content: "\10F66C";
}
.fad.fa-khanda:after {
  content: "\10F66D";
}
.fad.fa-kidneys:after {
  content: "\10F5FB";
}
.fad.fa-kiss:after {
  content: "\10F596";
}
.fad.fa-kiss-beam:after {
  content: "\10F597";
}
.fad.fa-kiss-wink-heart:after {
  content: "\10F598";
}
.fad.fa-kite:after {
  content: "\10F6F4";
}
.fad.fa-kiwi-bird:after {
  content: "\10F535";
}
.fad.fa-knife-kitchen:after {
  content: "\10F6F5";
}
.fad.fa-lambda:after {
  content: "\10F66E";
}
.fad.fa-lamp:after {
  content: "\10F4CA";
}
.fad.fa-lamp-desk:after {
  content: "\10F914";
}
.fad.fa-lamp-floor:after {
  content: "\10F915";
}
.fad.fa-landmark:after {
  content: "\10F66F";
}
.fad.fa-landmark-alt:after {
  content: "\10F752";
}
.fad.fa-language:after {
  content: "\10F1AB";
}
.fad.fa-laptop:after {
  content: "\10F109";
}
.fad.fa-laptop-code:after {
  content: "\10F5FC";
}
.fad.fa-laptop-house:after {
  content: "\10F966";
}
.fad.fa-laptop-medical:after {
  content: "\10F812";
}
.fad.fa-lasso:after {
  content: "\10F8C8";
}
.fad.fa-laugh:after {
  content: "\10F599";
}
.fad.fa-laugh-beam:after {
  content: "\10F59A";
}
.fad.fa-laugh-squint:after {
  content: "\10F59B";
}
.fad.fa-laugh-wink:after {
  content: "\10F59C";
}
.fad.fa-layer-group:after {
  content: "\10F5FD";
}
.fad.fa-layer-minus:after {
  content: "\10F5FE";
}
.fad.fa-layer-plus:after {
  content: "\10F5FF";
}
.fad.fa-leaf:after {
  content: "\10F06C";
}
.fad.fa-leaf-heart:after {
  content: "\10F4CB";
}
.fad.fa-leaf-maple:after {
  content: "\10F6F6";
}
.fad.fa-leaf-oak:after {
  content: "\10F6F7";
}
.fad.fa-lemon:after {
  content: "\10F094";
}
.fad.fa-less-than:after {
  content: "\10F536";
}
.fad.fa-less-than-equal:after {
  content: "\10F537";
}
.fad.fa-level-down:after {
  content: "\10F149";
}
.fad.fa-level-down-alt:after {
  content: "\10F3BE";
}
.fad.fa-level-up:after {
  content: "\10F148";
}
.fad.fa-level-up-alt:after {
  content: "\10F3BF";
}
.fad.fa-life-ring:after {
  content: "\10F1CD";
}
.fad.fa-light-ceiling:after {
  content: "\10F916";
}
.fad.fa-light-switch:after {
  content: "\10F917";
}
.fad.fa-light-switch-off:after {
  content: "\10F918";
}
.fad.fa-light-switch-on:after {
  content: "\10F919";
}
.fad.fa-lightbulb:after {
  content: "\10F0EB";
}
.fad.fa-lightbulb-dollar:after {
  content: "\10F670";
}
.fad.fa-lightbulb-exclamation:after {
  content: "\10F671";
}
.fad.fa-lightbulb-on:after {
  content: "\10F672";
}
.fad.fa-lightbulb-slash:after {
  content: "\10F673";
}
.fad.fa-lights-holiday:after {
  content: "\10F7B2";
}
.fad.fa-line-columns:after {
  content: "\10F870";
}
.fad.fa-line-height:after {
  content: "\10F871";
}
.fad.fa-link:after {
  content: "\10F0C1";
}
.fad.fa-lips:after {
  content: "\10F600";
}
.fad.fa-lira-sign:after {
  content: "\10F195";
}
.fad.fa-list:after {
  content: "\10F03A";
}
.fad.fa-list-alt:after {
  content: "\10F022";
}
.fad.fa-list-music:after {
  content: "\10F8C9";
}
.fad.fa-list-ol:after {
  content: "\10F0CB";
}
.fad.fa-list-ul:after {
  content: "\10F0CA";
}
.fad.fa-location:after {
  content: "\10F601";
}
.fad.fa-location-arrow:after {
  content: "\10F124";
}
.fad.fa-location-circle:after {
  content: "\10F602";
}
.fad.fa-location-slash:after {
  content: "\10F603";
}
.fad.fa-lock:after {
  content: "\10F023";
}
.fad.fa-lock-alt:after {
  content: "\10F30D";
}
.fad.fa-lock-open:after {
  content: "\10F3C1";
}
.fad.fa-lock-open-alt:after {
  content: "\10F3C2";
}
.fad.fa-long-arrow-alt-down:after {
  content: "\10F309";
}
.fad.fa-long-arrow-alt-left:after {
  content: "\10F30A";
}
.fad.fa-long-arrow-alt-right:after {
  content: "\10F30B";
}
.fad.fa-long-arrow-alt-up:after {
  content: "\10F30C";
}
.fad.fa-long-arrow-down:after {
  content: "\10F175";
}
.fad.fa-long-arrow-left:after {
  content: "\10F177";
}
.fad.fa-long-arrow-right:after {
  content: "\10F178";
}
.fad.fa-long-arrow-up:after {
  content: "\10F176";
}
.fad.fa-loveseat:after {
  content: "\10F4CC";
}
.fad.fa-low-vision:after {
  content: "\10F2A8";
}
.fad.fa-luchador:after {
  content: "\10F455";
}
.fad.fa-luggage-cart:after {
  content: "\10F59D";
}
.fad.fa-lungs:after {
  content: "\10F604";
}
.fad.fa-lungs-virus:after {
  content: "\10F967";
}
.fad.fa-mace:after {
  content: "\10F6F8";
}
.fad.fa-magic:after {
  content: "\10F0D0";
}
.fad.fa-magnet:after {
  content: "\10F076";
}
.fad.fa-mail-bulk:after {
  content: "\10F674";
}
.fad.fa-mailbox:after {
  content: "\10F813";
}
.fad.fa-male:after {
  content: "\10F183";
}
.fad.fa-mandolin:after {
  content: "\10F6F9";
}
.fad.fa-map:after {
  content: "\10F279";
}
.fad.fa-map-marked:after {
  content: "\10F59F";
}
.fad.fa-map-marked-alt:after {
  content: "\10F5A0";
}
.fad.fa-map-marker:after {
  content: "\10F041";
}
.fad.fa-map-marker-alt:after {
  content: "\10F3C5";
}
.fad.fa-map-marker-alt-slash:after {
  content: "\10F605";
}
.fad.fa-map-marker-check:after {
  content: "\10F606";
}
.fad.fa-map-marker-edit:after {
  content: "\10F607";
}
.fad.fa-map-marker-exclamation:after {
  content: "\10F608";
}
.fad.fa-map-marker-minus:after {
  content: "\10F609";
}
.fad.fa-map-marker-plus:after {
  content: "\10F60A";
}
.fad.fa-map-marker-question:after {
  content: "\10F60B";
}
.fad.fa-map-marker-slash:after {
  content: "\10F60C";
}
.fad.fa-map-marker-smile:after {
  content: "\10F60D";
}
.fad.fa-map-marker-times:after {
  content: "\10F60E";
}
.fad.fa-map-pin:after {
  content: "\10F276";
}
.fad.fa-map-signs:after {
  content: "\10F277";
}
.fad.fa-marker:after {
  content: "\10F5A1";
}
.fad.fa-mars:after {
  content: "\10F222";
}
.fad.fa-mars-double:after {
  content: "\10F227";
}
.fad.fa-mars-stroke:after {
  content: "\10F229";
}
.fad.fa-mars-stroke-h:after {
  content: "\10F22B";
}
.fad.fa-mars-stroke-v:after {
  content: "\10F22A";
}
.fad.fa-mask:after {
  content: "\10F6FA";
}
.fad.fa-meat:after {
  content: "\10F814";
}
.fad.fa-medal:after {
  content: "\10F5A2";
}
.fad.fa-medkit:after {
  content: "\10F0FA";
}
.fad.fa-megaphone:after {
  content: "\10F675";
}
.fad.fa-meh:after {
  content: "\10F11A";
}
.fad.fa-meh-blank:after {
  content: "\10F5A4";
}
.fad.fa-meh-rolling-eyes:after {
  content: "\10F5A5";
}
.fad.fa-memory:after {
  content: "\10F538";
}
.fad.fa-menorah:after {
  content: "\10F676";
}
.fad.fa-mercury:after {
  content: "\10F223";
}
.fad.fa-meteor:after {
  content: "\10F753";
}
.fad.fa-microchip:after {
  content: "\10F2DB";
}
.fad.fa-microphone:after {
  content: "\10F130";
}
.fad.fa-microphone-alt:after {
  content: "\10F3C9";
}
.fad.fa-microphone-alt-slash:after {
  content: "\10F539";
}
.fad.fa-microphone-slash:after {
  content: "\10F131";
}
.fad.fa-microphone-stand:after {
  content: "\10F8CB";
}
.fad.fa-microscope:after {
  content: "\10F610";
}
.fad.fa-microwave:after {
  content: "\10F91B";
}
.fad.fa-mind-share:after {
  content: "\10F677";
}
.fad.fa-minus:after {
  content: "\10F068";
}
.fad.fa-minus-circle:after {
  content: "\10F056";
}
.fad.fa-minus-hexagon:after {
  content: "\10F307";
}
.fad.fa-minus-octagon:after {
  content: "\10F308";
}
.fad.fa-minus-square:after {
  content: "\10F146";
}
.fad.fa-mistletoe:after {
  content: "\10F7B4";
}
.fad.fa-mitten:after {
  content: "\10F7B5";
}
.fad.fa-mobile:after {
  content: "\10F10B";
}
.fad.fa-mobile-alt:after {
  content: "\10F3CD";
}
.fad.fa-mobile-android:after {
  content: "\10F3CE";
}
.fad.fa-mobile-android-alt:after {
  content: "\10F3CF";
}
.fad.fa-money-bill:after {
  content: "\10F0D6";
}
.fad.fa-money-bill-alt:after {
  content: "\10F3D1";
}
.fad.fa-money-bill-wave:after {
  content: "\10F53A";
}
.fad.fa-money-bill-wave-alt:after {
  content: "\10F53B";
}
.fad.fa-money-check:after {
  content: "\10F53C";
}
.fad.fa-money-check-alt:after {
  content: "\10F53D";
}
.fad.fa-money-check-edit:after {
  content: "\10F872";
}
.fad.fa-money-check-edit-alt:after {
  content: "\10F873";
}
.fad.fa-monitor-heart-rate:after {
  content: "\10F611";
}
.fad.fa-monkey:after {
  content: "\10F6FB";
}
.fad.fa-monument:after {
  content: "\10F5A6";
}
.fad.fa-moon:after {
  content: "\10F186";
}
.fad.fa-moon-cloud:after {
  content: "\10F754";
}
.fad.fa-moon-stars:after {
  content: "\10F755";
}
.fad.fa-mortar-pestle:after {
  content: "\10F5A7";
}
.fad.fa-mosque:after {
  content: "\10F678";
}
.fad.fa-motorcycle:after {
  content: "\10F21C";
}
.fad.fa-mountain:after {
  content: "\10F6FC";
}
.fad.fa-mountains:after {
  content: "\10F6FD";
}
.fad.fa-mouse:after {
  content: "\10F8CC";
}
.fad.fa-mouse-alt:after {
  content: "\10F8CD";
}
.fad.fa-mouse-pointer:after {
  content: "\10F245";
}
.fad.fa-mp3-player:after {
  content: "\10F8CE";
}
.fad.fa-mug:after {
  content: "\10F874";
}
.fad.fa-mug-hot:after {
  content: "\10F7B6";
}
.fad.fa-mug-marshmallows:after {
  content: "\10F7B7";
}
.fad.fa-mug-tea:after {
  content: "\10F875";
}
.fad.fa-music:after {
  content: "\10F001";
}
.fad.fa-music-alt:after {
  content: "\10F8CF";
}
.fad.fa-music-alt-slash:after {
  content: "\10F8D0";
}
.fad.fa-music-slash:after {
  content: "\10F8D1";
}
.fad.fa-narwhal:after {
  content: "\10F6FE";
}
.fad.fa-network-wired:after {
  content: "\10F6FF";
}
.fad.fa-neuter:after {
  content: "\10F22C";
}
.fad.fa-newspaper:after {
  content: "\10F1EA";
}
.fad.fa-not-equal:after {
  content: "\10F53E";
}
.fad.fa-notes-medical:after {
  content: "\10F481";
}
.fad.fa-object-group:after {
  content: "\10F247";
}
.fad.fa-object-ungroup:after {
  content: "\10F248";
}
.fad.fa-octagon:after {
  content: "\10F306";
}
.fad.fa-oil-can:after {
  content: "\10F613";
}
.fad.fa-oil-temp:after {
  content: "\10F614";
}
.fad.fa-om:after {
  content: "\10F679";
}
.fad.fa-omega:after {
  content: "\10F67A";
}
.fad.fa-ornament:after {
  content: "\10F7B8";
}
.fad.fa-otter:after {
  content: "\10F700";
}
.fad.fa-outdent:after {
  content: "\10F03B";
}
.fad.fa-outlet:after {
  content: "\10F91C";
}
.fad.fa-oven:after {
  content: "\10F91D";
}
.fad.fa-overline:after {
  content: "\10F876";
}
.fad.fa-page-break:after {
  content: "\10F877";
}
.fad.fa-pager:after {
  content: "\10F815";
}
.fad.fa-paint-brush:after {
  content: "\10F1FC";
}
.fad.fa-paint-brush-alt:after {
  content: "\10F5A9";
}
.fad.fa-paint-roller:after {
  content: "\10F5AA";
}
.fad.fa-palette:after {
  content: "\10F53F";
}
.fad.fa-pallet:after {
  content: "\10F482";
}
.fad.fa-pallet-alt:after {
  content: "\10F483";
}
.fad.fa-paper-plane:after {
  content: "\10F1D8";
}
.fad.fa-paperclip:after {
  content: "\10F0C6";
}
.fad.fa-parachute-box:after {
  content: "\10F4CD";
}
.fad.fa-paragraph:after {
  content: "\10F1DD";
}
.fad.fa-paragraph-rtl:after {
  content: "\10F878";
}
.fad.fa-parking:after {
  content: "\10F540";
}
.fad.fa-parking-circle:after {
  content: "\10F615";
}
.fad.fa-parking-circle-slash:after {
  content: "\10F616";
}
.fad.fa-parking-slash:after {
  content: "\10F617";
}
.fad.fa-passport:after {
  content: "\10F5AB";
}
.fad.fa-pastafarianism:after {
  content: "\10F67B";
}
.fad.fa-paste:after {
  content: "\10F0EA";
}
.fad.fa-pause:after {
  content: "\10F04C";
}
.fad.fa-pause-circle:after {
  content: "\10F28B";
}
.fad.fa-paw:after {
  content: "\10F1B0";
}
.fad.fa-paw-alt:after {
  content: "\10F701";
}
.fad.fa-paw-claws:after {
  content: "\10F702";
}
.fad.fa-peace:after {
  content: "\10F67C";
}
.fad.fa-pegasus:after {
  content: "\10F703";
}
.fad.fa-pen:after {
  content: "\10F304";
}
.fad.fa-pen-alt:after {
  content: "\10F305";
}
.fad.fa-pen-fancy:after {
  content: "\10F5AC";
}
.fad.fa-pen-nib:after {
  content: "\10F5AD";
}
.fad.fa-pen-square:after {
  content: "\10F14B";
}
.fad.fa-pencil:after {
  content: "\10F040";
}
.fad.fa-pencil-alt:after {
  content: "\10F303";
}
.fad.fa-pencil-paintbrush:after {
  content: "\10F618";
}
.fad.fa-pencil-ruler:after {
  content: "\10F5AE";
}
.fad.fa-pennant:after {
  content: "\10F456";
}
.fad.fa-people-arrows:after {
  content: "\10F968";
}
.fad.fa-people-carry:after {
  content: "\10F4CE";
}
.fad.fa-pepper-hot:after {
  content: "\10F816";
}
.fad.fa-percent:after {
  content: "\10F295";
}
.fad.fa-percentage:after {
  content: "\10F541";
}
.fad.fa-person-booth:after {
  content: "\10F756";
}
.fad.fa-person-carry:after {
  content: "\10F4CF";
}
.fad.fa-person-dolly:after {
  content: "\10F4D0";
}
.fad.fa-person-dolly-empty:after {
  content: "\10F4D1";
}
.fad.fa-person-sign:after {
  content: "\10F757";
}
.fad.fa-phone:after {
  content: "\10F095";
}
.fad.fa-phone-alt:after {
  content: "\10F879";
}
.fad.fa-phone-laptop:after {
  content: "\10F87A";
}
.fad.fa-phone-office:after {
  content: "\10F67D";
}
.fad.fa-phone-plus:after {
  content: "\10F4D2";
}
.fad.fa-phone-rotary:after {
  content: "\10F8D3";
}
.fad.fa-phone-slash:after {
  content: "\10F3DD";
}
.fad.fa-phone-square:after {
  content: "\10F098";
}
.fad.fa-phone-square-alt:after {
  content: "\10F87B";
}
.fad.fa-phone-volume:after {
  content: "\10F2A0";
}
.fad.fa-photo-video:after {
  content: "\10F87C";
}
.fad.fa-pi:after {
  content: "\10F67E";
}
.fad.fa-piano:after {
  content: "\10F8D4";
}
.fad.fa-piano-keyboard:after {
  content: "\10F8D5";
}
.fad.fa-pie:after {
  content: "\10F705";
}
.fad.fa-pig:after {
  content: "\10F706";
}
.fad.fa-piggy-bank:after {
  content: "\10F4D3";
}
.fad.fa-pills:after {
  content: "\10F484";
}
.fad.fa-pizza:after {
  content: "\10F817";
}
.fad.fa-pizza-slice:after {
  content: "\10F818";
}
.fad.fa-place-of-worship:after {
  content: "\10F67F";
}
.fad.fa-plane:after {
  content: "\10F072";
}
.fad.fa-plane-alt:after {
  content: "\10F3DE";
}
.fad.fa-plane-arrival:after {
  content: "\10F5AF";
}
.fad.fa-plane-departure:after {
  content: "\10F5B0";
}
.fad.fa-plane-slash:after {
  content: "\10F969";
}
.fad.fa-planet-moon:after {
  content: "\10F91F";
}
.fad.fa-planet-ringed:after {
  content: "\10F920";
}
.fad.fa-play:after {
  content: "\10F04B";
}
.fad.fa-play-circle:after {
  content: "\10F144";
}
.fad.fa-plug:after {
  content: "\10F1E6";
}
.fad.fa-plus:after {
  content: "\10F067";
}
.fad.fa-plus-circle:after {
  content: "\10F055";
}
.fad.fa-plus-hexagon:after {
  content: "\10F300";
}
.fad.fa-plus-octagon:after {
  content: "\10F301";
}
.fad.fa-plus-square:after {
  content: "\10F0FE";
}
.fad.fa-podcast:after {
  content: "\10F2CE";
}
.fad.fa-podium:after {
  content: "\10F680";
}
.fad.fa-podium-star:after {
  content: "\10F758";
}
.fad.fa-police-box:after {
  content: "\10F921";
}
.fad.fa-poll:after {
  content: "\10F681";
}
.fad.fa-poll-h:after {
  content: "\10F682";
}
.fad.fa-poll-people:after {
  content: "\10F759";
}
.fad.fa-poo:after {
  content: "\10F2FE";
}
.fad.fa-poo-storm:after {
  content: "\10F75A";
}
.fad.fa-poop:after {
  content: "\10F619";
}
.fad.fa-popcorn:after {
  content: "\10F819";
}
.fad.fa-portal-enter:after {
  content: "\10F922";
}
.fad.fa-portal-exit:after {
  content: "\10F923";
}
.fad.fa-portrait:after {
  content: "\10F3E0";
}
.fad.fa-pound-sign:after {
  content: "\10F154";
}
.fad.fa-power-off:after {
  content: "\10F011";
}
.fad.fa-pray:after {
  content: "\10F683";
}
.fad.fa-praying-hands:after {
  content: "\10F684";
}
.fad.fa-prescription:after {
  content: "\10F5B1";
}
.fad.fa-prescription-bottle:after {
  content: "\10F485";
}
.fad.fa-prescription-bottle-alt:after {
  content: "\10F486";
}
.fad.fa-presentation:after {
  content: "\10F685";
}
.fad.fa-print:after {
  content: "\10F02F";
}
.fad.fa-print-search:after {
  content: "\10F81A";
}
.fad.fa-print-slash:after {
  content: "\10F686";
}
.fad.fa-procedures:after {
  content: "\10F487";
}
.fad.fa-project-diagram:after {
  content: "\10F542";
}
.fad.fa-projector:after {
  content: "\10F8D6";
}
.fad.fa-pump-medical:after {
  content: "\10F96A";
}
.fad.fa-pump-soap:after {
  content: "\10F96B";
}
.fad.fa-pumpkin:after {
  content: "\10F707";
}
.fad.fa-puzzle-piece:after {
  content: "\10F12E";
}
.fad.fa-qrcode:after {
  content: "\10F029";
}
.fad.fa-question:after {
  content: "\10F128";
}
.fad.fa-question-circle:after {
  content: "\10F059";
}
.fad.fa-question-square:after {
  content: "\10F2FD";
}
.fad.fa-quidditch:after {
  content: "\10F458";
}
.fad.fa-quote-left:after {
  content: "\10F10D";
}
.fad.fa-quote-right:after {
  content: "\10F10E";
}
.fad.fa-quran:after {
  content: "\10F687";
}
.fad.fa-rabbit:after {
  content: "\10F708";
}
.fad.fa-rabbit-fast:after {
  content: "\10F709";
}
.fad.fa-racquet:after {
  content: "\10F45A";
}
.fad.fa-radar:after {
  content: "\10F924";
}
.fad.fa-radiation:after {
  content: "\10F7B9";
}
.fad.fa-radiation-alt:after {
  content: "\10F7BA";
}
.fad.fa-radio:after {
  content: "\10F8D7";
}
.fad.fa-radio-alt:after {
  content: "\10F8D8";
}
.fad.fa-rainbow:after {
  content: "\10F75B";
}
.fad.fa-raindrops:after {
  content: "\10F75C";
}
.fad.fa-ram:after {
  content: "\10F70A";
}
.fad.fa-ramp-loading:after {
  content: "\10F4D4";
}
.fad.fa-random:after {
  content: "\10F074";
}
.fad.fa-raygun:after {
  content: "\10F925";
}
.fad.fa-receipt:after {
  content: "\10F543";
}
.fad.fa-record-vinyl:after {
  content: "\10F8D9";
}
.fad.fa-rectangle-landscape:after {
  content: "\10F2FA";
}
.fad.fa-rectangle-portrait:after {
  content: "\10F2FB";
}
.fad.fa-rectangle-wide:after {
  content: "\10F2FC";
}
.fad.fa-recycle:after {
  content: "\10F1B8";
}
.fad.fa-redo:after {
  content: "\10F01E";
}
.fad.fa-redo-alt:after {
  content: "\10F2F9";
}
.fad.fa-refrigerator:after {
  content: "\10F926";
}
.fad.fa-registered:after {
  content: "\10F25D";
}
.fad.fa-remove-format:after {
  content: "\10F87D";
}
.fad.fa-repeat:after {
  content: "\10F363";
}
.fad.fa-repeat-1:after {
  content: "\10F365";
}
.fad.fa-repeat-1-alt:after {
  content: "\10F366";
}
.fad.fa-repeat-alt:after {
  content: "\10F364";
}
.fad.fa-reply:after {
  content: "\10F3E5";
}
.fad.fa-reply-all:after {
  content: "\10F122";
}
.fad.fa-republican:after {
  content: "\10F75E";
}
.fad.fa-restroom:after {
  content: "\10F7BD";
}
.fad.fa-retweet:after {
  content: "\10F079";
}
.fad.fa-retweet-alt:after {
  content: "\10F361";
}
.fad.fa-ribbon:after {
  content: "\10F4D6";
}
.fad.fa-ring:after {
  content: "\10F70B";
}
.fad.fa-rings-wedding:after {
  content: "\10F81B";
}
.fad.fa-road:after {
  content: "\10F018";
}
.fad.fa-robot:after {
  content: "\10F544";
}
.fad.fa-rocket:after {
  content: "\10F135";
}
.fad.fa-rocket-launch:after {
  content: "\10F927";
}
.fad.fa-route:after {
  content: "\10F4D7";
}
.fad.fa-route-highway:after {
  content: "\10F61A";
}
.fad.fa-route-interstate:after {
  content: "\10F61B";
}
.fad.fa-router:after {
  content: "\10F8DA";
}
.fad.fa-rss:after {
  content: "\10F09E";
}
.fad.fa-rss-square:after {
  content: "\10F143";
}
.fad.fa-ruble-sign:after {
  content: "\10F158";
}
.fad.fa-ruler:after {
  content: "\10F545";
}
.fad.fa-ruler-combined:after {
  content: "\10F546";
}
.fad.fa-ruler-horizontal:after {
  content: "\10F547";
}
.fad.fa-ruler-triangle:after {
  content: "\10F61C";
}
.fad.fa-ruler-vertical:after {
  content: "\10F548";
}
.fad.fa-running:after {
  content: "\10F70C";
}
.fad.fa-rupee-sign:after {
  content: "\10F156";
}
.fad.fa-rv:after {
  content: "\10F7BE";
}
.fad.fa-sack:after {
  content: "\10F81C";
}
.fad.fa-sack-dollar:after {
  content: "\10F81D";
}
.fad.fa-sad-cry:after {
  content: "\10F5B3";
}
.fad.fa-sad-tear:after {
  content: "\10F5B4";
}
.fad.fa-salad:after {
  content: "\10F81E";
}
.fad.fa-sandwich:after {
  content: "\10F81F";
}
.fad.fa-satellite:after {
  content: "\10F7BF";
}
.fad.fa-satellite-dish:after {
  content: "\10F7C0";
}
.fad.fa-sausage:after {
  content: "\10F820";
}
.fad.fa-save:after {
  content: "\10F0C7";
}
.fad.fa-sax-hot:after {
  content: "\10F8DB";
}
.fad.fa-saxophone:after {
  content: "\10F8DC";
}
.fad.fa-scalpel:after {
  content: "\10F61D";
}
.fad.fa-scalpel-path:after {
  content: "\10F61E";
}
.fad.fa-scanner:after {
  content: "\10F488";
}
.fad.fa-scanner-image:after {
  content: "\10F8F3";
}
.fad.fa-scanner-keyboard:after {
  content: "\10F489";
}
.fad.fa-scanner-touchscreen:after {
  content: "\10F48A";
}
.fad.fa-scarecrow:after {
  content: "\10F70D";
}
.fad.fa-scarf:after {
  content: "\10F7C1";
}
.fad.fa-school:after {
  content: "\10F549";
}
.fad.fa-screwdriver:after {
  content: "\10F54A";
}
.fad.fa-scroll:after {
  content: "\10F70E";
}
.fad.fa-scroll-old:after {
  content: "\10F70F";
}
.fad.fa-scrubber:after {
  content: "\10F2F8";
}
.fad.fa-scythe:after {
  content: "\10F710";
}
.fad.fa-sd-card:after {
  content: "\10F7C2";
}
.fad.fa-search:after {
  content: "\10F002";
}
.fad.fa-search-dollar:after {
  content: "\10F688";
}
.fad.fa-search-location:after {
  content: "\10F689";
}
.fad.fa-search-minus:after {
  content: "\10F010";
}
.fad.fa-search-plus:after {
  content: "\10F00E";
}
.fad.fa-seedling:after {
  content: "\10F4D8";
}
.fad.fa-send-back:after {
  content: "\10F87E";
}
.fad.fa-send-backward:after {
  content: "\10F87F";
}
.fad.fa-sensor:after {
  content: "\10F928";
}
.fad.fa-sensor-alert:after {
  content: "\10F929";
}
.fad.fa-sensor-fire:after {
  content: "\10F92A";
}
.fad.fa-sensor-on:after {
  content: "\10F92B";
}
.fad.fa-sensor-smoke:after {
  content: "\10F92C";
}
.fad.fa-server:after {
  content: "\10F233";
}
.fad.fa-shapes:after {
  content: "\10F61F";
}
.fad.fa-share:after {
  content: "\10F064";
}
.fad.fa-share-all:after {
  content: "\10F367";
}
.fad.fa-share-alt:after {
  content: "\10F1E0";
}
.fad.fa-share-alt-square:after {
  content: "\10F1E1";
}
.fad.fa-share-square:after {
  content: "\10F14D";
}
.fad.fa-sheep:after {
  content: "\10F711";
}
.fad.fa-shekel-sign:after {
  content: "\10F20B";
}
.fad.fa-shield:after {
  content: "\10F132";
}
.fad.fa-shield-alt:after {
  content: "\10F3ED";
}
.fad.fa-shield-check:after {
  content: "\10F2F7";
}
.fad.fa-shield-cross:after {
  content: "\10F712";
}
.fad.fa-shield-virus:after {
  content: "\10F96C";
}
.fad.fa-ship:after {
  content: "\10F21A";
}
.fad.fa-shipping-fast:after {
  content: "\10F48B";
}
.fad.fa-shipping-timed:after {
  content: "\10F48C";
}
.fad.fa-shish-kebab:after {
  content: "\10F821";
}
.fad.fa-shoe-prints:after {
  content: "\10F54B";
}
.fad.fa-shopping-bag:after {
  content: "\10F290";
}
.fad.fa-shopping-basket:after {
  content: "\10F291";
}
.fad.fa-shopping-cart:after {
  content: "\10F07A";
}
.fad.fa-shovel:after {
  content: "\10F713";
}
.fad.fa-shovel-snow:after {
  content: "\10F7C3";
}
.fad.fa-shower:after {
  content: "\10F2CC";
}
.fad.fa-shredder:after {
  content: "\10F68A";
}
.fad.fa-shuttle-van:after {
  content: "\10F5B6";
}
.fad.fa-shuttlecock:after {
  content: "\10F45B";
}
.fad.fa-sickle:after {
  content: "\10F822";
}
.fad.fa-sigma:after {
  content: "\10F68B";
}
.fad.fa-sign:after {
  content: "\10F4D9";
}
.fad.fa-sign-in:after {
  content: "\10F090";
}
.fad.fa-sign-in-alt:after {
  content: "\10F2F6";
}
.fad.fa-sign-language:after {
  content: "\10F2A7";
}
.fad.fa-sign-out:after {
  content: "\10F08B";
}
.fad.fa-sign-out-alt:after {
  content: "\10F2F5";
}
.fad.fa-signal:after {
  content: "\10F012";
}
.fad.fa-signal-1:after {
  content: "\10F68C";
}
.fad.fa-signal-2:after {
  content: "\10F68D";
}
.fad.fa-signal-3:after {
  content: "\10F68E";
}
.fad.fa-signal-4:after {
  content: "\10F68F";
}
.fad.fa-signal-alt:after {
  content: "\10F690";
}
.fad.fa-signal-alt-1:after {
  content: "\10F691";
}
.fad.fa-signal-alt-2:after {
  content: "\10F692";
}
.fad.fa-signal-alt-3:after {
  content: "\10F693";
}
.fad.fa-signal-alt-slash:after {
  content: "\10F694";
}
.fad.fa-signal-slash:after {
  content: "\10F695";
}
.fad.fa-signal-stream:after {
  content: "\10F8DD";
}
.fad.fa-signature:after {
  content: "\10F5B7";
}
.fad.fa-sim-card:after {
  content: "\10F7C4";
}
.fad.fa-sink:after {
  content: "\10F96D";
}
.fad.fa-siren:after {
  content: "\10F92D";
}
.fad.fa-siren-on:after {
  content: "\10F92E";
}
.fad.fa-sitemap:after {
  content: "\10F0E8";
}
.fad.fa-skating:after {
  content: "\10F7C5";
}
.fad.fa-skeleton:after {
  content: "\10F620";
}
.fad.fa-ski-jump:after {
  content: "\10F7C7";
}
.fad.fa-ski-lift:after {
  content: "\10F7C8";
}
.fad.fa-skiing:after {
  content: "\10F7C9";
}
.fad.fa-skiing-nordic:after {
  content: "\10F7CA";
}
.fad.fa-skull:after {
  content: "\10F54C";
}
.fad.fa-skull-cow:after {
  content: "\10F8DE";
}
.fad.fa-skull-crossbones:after {
  content: "\10F714";
}
.fad.fa-slash:after {
  content: "\10F715";
}
.fad.fa-sledding:after {
  content: "\10F7CB";
}
.fad.fa-sleigh:after {
  content: "\10F7CC";
}
.fad.fa-sliders-h:after {
  content: "\10F1DE";
}
.fad.fa-sliders-h-square:after {
  content: "\10F3F0";
}
.fad.fa-sliders-v:after {
  content: "\10F3F1";
}
.fad.fa-sliders-v-square:after {
  content: "\10F3F2";
}
.fad.fa-smile:after {
  content: "\10F118";
}
.fad.fa-smile-beam:after {
  content: "\10F5B8";
}
.fad.fa-smile-plus:after {
  content: "\10F5B9";
}
.fad.fa-smile-wink:after {
  content: "\10F4DA";
}
.fad.fa-smog:after {
  content: "\10F75F";
}
.fad.fa-smoke:after {
  content: "\10F760";
}
.fad.fa-smoking:after {
  content: "\10F48D";
}
.fad.fa-smoking-ban:after {
  content: "\10F54D";
}
.fad.fa-sms:after {
  content: "\10F7CD";
}
.fad.fa-snake:after {
  content: "\10F716";
}
.fad.fa-snooze:after {
  content: "\10F880";
}
.fad.fa-snow-blowing:after {
  content: "\10F761";
}
.fad.fa-snowboarding:after {
  content: "\10F7CE";
}
.fad.fa-snowflake:after {
  content: "\10F2DC";
}
.fad.fa-snowflakes:after {
  content: "\10F7CF";
}
.fad.fa-snowman:after {
  content: "\10F7D0";
}
.fad.fa-snowmobile:after {
  content: "\10F7D1";
}
.fad.fa-snowplow:after {
  content: "\10F7D2";
}
.fad.fa-soap:after {
  content: "\10F96E";
}
.fad.fa-socks:after {
  content: "\10F696";
}
.fad.fa-solar-panel:after {
  content: "\10F5BA";
}
.fad.fa-solar-system:after {
  content: "\10F92F";
}
.fad.fa-sort:after {
  content: "\10F0DC";
}
.fad.fa-sort-alpha-down:after {
  content: "\10F15D";
}
.fad.fa-sort-alpha-down-alt:after {
  content: "\10F881";
}
.fad.fa-sort-alpha-up:after {
  content: "\10F15E";
}
.fad.fa-sort-alpha-up-alt:after {
  content: "\10F882";
}
.fad.fa-sort-alt:after {
  content: "\10F883";
}
.fad.fa-sort-amount-down:after {
  content: "\10F160";
}
.fad.fa-sort-amount-down-alt:after {
  content: "\10F884";
}
.fad.fa-sort-amount-up:after {
  content: "\10F161";
}
.fad.fa-sort-amount-up-alt:after {
  content: "\10F885";
}
.fad.fa-sort-circle:after {
  content: "\10F930";
}
.fad.fa-sort-circle-down:after {
  content: "\10F931";
}
.fad.fa-sort-circle-up:after {
  content: "\10F932";
}
.fad.fa-sort-down:after {
  content: "\10F0DD";
}
.fad.fa-sort-numeric-down:after {
  content: "\10F162";
}
.fad.fa-sort-numeric-down-alt:after {
  content: "\10F886";
}
.fad.fa-sort-numeric-up:after {
  content: "\10F163";
}
.fad.fa-sort-numeric-up-alt:after {
  content: "\10F887";
}
.fad.fa-sort-shapes-down:after {
  content: "\10F888";
}
.fad.fa-sort-shapes-down-alt:after {
  content: "\10F889";
}
.fad.fa-sort-shapes-up:after {
  content: "\10F88A";
}
.fad.fa-sort-shapes-up-alt:after {
  content: "\10F88B";
}
.fad.fa-sort-size-down:after {
  content: "\10F88C";
}
.fad.fa-sort-size-down-alt:after {
  content: "\10F88D";
}
.fad.fa-sort-size-up:after {
  content: "\10F88E";
}
.fad.fa-sort-size-up-alt:after {
  content: "\10F88F";
}
.fad.fa-sort-up:after {
  content: "\10F0DE";
}
.fad.fa-soup:after {
  content: "\10F823";
}
.fad.fa-spa:after {
  content: "\10F5BB";
}
.fad.fa-space-shuttle:after {
  content: "\10F197";
}
.fad.fa-space-station-moon:after {
  content: "\10F933";
}
.fad.fa-space-station-moon-alt:after {
  content: "\10F934";
}
.fad.fa-spade:after {
  content: "\10F2F4";
}
.fad.fa-sparkles:after {
  content: "\10F890";
}
.fad.fa-speaker:after {
  content: "\10F8DF";
}
.fad.fa-speakers:after {
  content: "\10F8E0";
}
.fad.fa-spell-check:after {
  content: "\10F891";
}
.fad.fa-spider:after {
  content: "\10F717";
}
.fad.fa-spider-black-widow:after {
  content: "\10F718";
}
.fad.fa-spider-web:after {
  content: "\10F719";
}
.fad.fa-spinner:after {
  content: "\10F110";
}
.fad.fa-spinner-third:after {
  content: "\10F3F4";
}
.fad.fa-splotch:after {
  content: "\10F5BC";
}
.fad.fa-spray-can:after {
  content: "\10F5BD";
}
.fad.fa-sprinkler:after {
  content: "\10F935";
}
.fad.fa-square:after {
  content: "\10F0C8";
}
.fad.fa-square-full:after {
  content: "\10F45C";
}
.fad.fa-square-root:after {
  content: "\10F697";
}
.fad.fa-square-root-alt:after {
  content: "\10F698";
}
.fad.fa-squirrel:after {
  content: "\10F71A";
}
.fad.fa-staff:after {
  content: "\10F71B";
}
.fad.fa-stamp:after {
  content: "\10F5BF";
}
.fad.fa-star:after {
  content: "\10F005";
}
.fad.fa-star-and-crescent:after {
  content: "\10F699";
}
.fad.fa-star-christmas:after {
  content: "\10F7D4";
}
.fad.fa-star-exclamation:after {
  content: "\10F2F3";
}
.fad.fa-star-half:after {
  content: "\10F089";
}
.fad.fa-star-half-alt:after {
  content: "\10F5C0";
}
.fad.fa-star-of-david:after {
  content: "\10F69A";
}
.fad.fa-star-of-life:after {
  content: "\10F621";
}
.fad.fa-star-shooting:after {
  content: "\10F936";
}
.fad.fa-starfighter:after {
  content: "\10F937";
}
.fad.fa-starfighter-alt:after {
  content: "\10F938";
}
.fad.fa-stars:after {
  content: "\10F762";
}
.fad.fa-starship:after {
  content: "\10F939";
}
.fad.fa-starship-freighter:after {
  content: "\10F93A";
}
.fad.fa-steak:after {
  content: "\10F824";
}
.fad.fa-steering-wheel:after {
  content: "\10F622";
}
.fad.fa-step-backward:after {
  content: "\10F048";
}
.fad.fa-step-forward:after {
  content: "\10F051";
}
.fad.fa-stethoscope:after {
  content: "\10F0F1";
}
.fad.fa-sticky-note:after {
  content: "\10F249";
}
.fad.fa-stocking:after {
  content: "\10F7D5";
}
.fad.fa-stomach:after {
  content: "\10F623";
}
.fad.fa-stop:after {
  content: "\10F04D";
}
.fad.fa-stop-circle:after {
  content: "\10F28D";
}
.fad.fa-stopwatch:after {
  content: "\10F2F2";
}
.fad.fa-stopwatch-20:after {
  content: "\10F96F";
}
.fad.fa-store:after {
  content: "\10F54E";
}
.fad.fa-store-alt:after {
  content: "\10F54F";
}
.fad.fa-store-alt-slash:after {
  content: "\10F970";
}
.fad.fa-store-slash:after {
  content: "\10F971";
}
.fad.fa-stream:after {
  content: "\10F550";
}
.fad.fa-street-view:after {
  content: "\10F21D";
}
.fad.fa-stretcher:after {
  content: "\10F825";
}
.fad.fa-strikethrough:after {
  content: "\10F0CC";
}
.fad.fa-stroopwafel:after {
  content: "\10F551";
}
.fad.fa-subscript:after {
  content: "\10F12C";
}
.fad.fa-subway:after {
  content: "\10F239";
}
.fad.fa-suitcase:after {
  content: "\10F0F2";
}
.fad.fa-suitcase-rolling:after {
  content: "\10F5C1";
}
.fad.fa-sun:after {
  content: "\10F185";
}
.fad.fa-sun-cloud:after {
  content: "\10F763";
}
.fad.fa-sun-dust:after {
  content: "\10F764";
}
.fad.fa-sun-haze:after {
  content: "\10F765";
}
.fad.fa-sunglasses:after {
  content: "\10F892";
}
.fad.fa-sunrise:after {
  content: "\10F766";
}
.fad.fa-sunset:after {
  content: "\10F767";
}
.fad.fa-superscript:after {
  content: "\10F12B";
}
.fad.fa-surprise:after {
  content: "\10F5C2";
}
.fad.fa-swatchbook:after {
  content: "\10F5C3";
}
.fad.fa-swimmer:after {
  content: "\10F5C4";
}
.fad.fa-swimming-pool:after {
  content: "\10F5C5";
}
.fad.fa-sword:after {
  content: "\10F71C";
}
.fad.fa-sword-laser:after {
  content: "\10F93B";
}
.fad.fa-sword-laser-alt:after {
  content: "\10F93C";
}
.fad.fa-swords:after {
  content: "\10F71D";
}
.fad.fa-swords-laser:after {
  content: "\10F93D";
}
.fad.fa-synagogue:after {
  content: "\10F69B";
}
.fad.fa-sync:after {
  content: "\10F021";
}
.fad.fa-sync-alt:after {
  content: "\10F2F1";
}
.fad.fa-syringe:after {
  content: "\10F48E";
}
.fad.fa-table:after {
  content: "\10F0CE";
}
.fad.fa-table-tennis:after {
  content: "\10F45D";
}
.fad.fa-tablet:after {
  content: "\10F10A";
}
.fad.fa-tablet-alt:after {
  content: "\10F3FA";
}
.fad.fa-tablet-android:after {
  content: "\10F3FB";
}
.fad.fa-tablet-android-alt:after {
  content: "\10F3FC";
}
.fad.fa-tablet-rugged:after {
  content: "\10F48F";
}
.fad.fa-tablets:after {
  content: "\10F490";
}
.fad.fa-tachometer:after {
  content: "\10F0E4";
}
.fad.fa-tachometer-alt:after {
  content: "\10F3FD";
}
.fad.fa-tachometer-alt-average:after {
  content: "\10F624";
}
.fad.fa-tachometer-alt-fast:after {
  content: "\10F625";
}
.fad.fa-tachometer-alt-fastest:after {
  content: "\10F626";
}
.fad.fa-tachometer-alt-slow:after {
  content: "\10F627";
}
.fad.fa-tachometer-alt-slowest:after {
  content: "\10F628";
}
.fad.fa-tachometer-average:after {
  content: "\10F629";
}
.fad.fa-tachometer-fast:after {
  content: "\10F62A";
}
.fad.fa-tachometer-fastest:after {
  content: "\10F62B";
}
.fad.fa-tachometer-slow:after {
  content: "\10F62C";
}
.fad.fa-tachometer-slowest:after {
  content: "\10F62D";
}
.fad.fa-taco:after {
  content: "\10F826";
}
.fad.fa-tag:after {
  content: "\10F02B";
}
.fad.fa-tags:after {
  content: "\10F02C";
}
.fad.fa-tally:after {
  content: "\10F69C";
}
.fad.fa-tanakh:after {
  content: "\10F827";
}
.fad.fa-tape:after {
  content: "\10F4DB";
}
.fad.fa-tasks:after {
  content: "\10F0AE";
}
.fad.fa-tasks-alt:after {
  content: "\10F828";
}
.fad.fa-taxi:after {
  content: "\10F1BA";
}
.fad.fa-teeth:after {
  content: "\10F62E";
}
.fad.fa-teeth-open:after {
  content: "\10F62F";
}
.fad.fa-telescope:after {
  content: "\10F93E";
}
.fad.fa-temperature-down:after {
  content: "\10F93F";
}
.fad.fa-temperature-frigid:after {
  content: "\10F768";
}
.fad.fa-temperature-high:after {
  content: "\10F769";
}
.fad.fa-temperature-hot:after {
  content: "\10F76A";
}
.fad.fa-temperature-low:after {
  content: "\10F76B";
}
.fad.fa-temperature-up:after {
  content: "\10F940";
}
.fad.fa-tenge:after {
  content: "\10F7D7";
}
.fad.fa-tennis-ball:after {
  content: "\10F45E";
}
.fad.fa-terminal:after {
  content: "\10F120";
}
.fad.fa-text:after {
  content: "\10F893";
}
.fad.fa-text-height:after {
  content: "\10F034";
}
.fad.fa-text-size:after {
  content: "\10F894";
}
.fad.fa-text-width:after {
  content: "\10F035";
}
.fad.fa-th:after {
  content: "\10F00A";
}
.fad.fa-th-large:after {
  content: "\10F009";
}
.fad.fa-th-list:after {
  content: "\10F00B";
}
.fad.fa-theater-masks:after {
  content: "\10F630";
}
.fad.fa-thermometer:after {
  content: "\10F491";
}
.fad.fa-thermometer-empty:after {
  content: "\10F2CB";
}
.fad.fa-thermometer-full:after {
  content: "\10F2C7";
}
.fad.fa-thermometer-half:after {
  content: "\10F2C9";
}
.fad.fa-thermometer-quarter:after {
  content: "\10F2CA";
}
.fad.fa-thermometer-three-quarters:after {
  content: "\10F2C8";
}
.fad.fa-theta:after {
  content: "\10F69E";
}
.fad.fa-thumbs-down:after {
  content: "\10F165";
}
.fad.fa-thumbs-up:after {
  content: "\10F164";
}
.fad.fa-thumbtack:after {
  content: "\10F08D";
}
.fad.fa-thunderstorm:after {
  content: "\10F76C";
}
.fad.fa-thunderstorm-moon:after {
  content: "\10F76D";
}
.fad.fa-thunderstorm-sun:after {
  content: "\10F76E";
}
.fad.fa-ticket:after {
  content: "\10F145";
}
.fad.fa-ticket-alt:after {
  content: "\10F3FF";
}
.fad.fa-tilde:after {
  content: "\10F69F";
}
.fad.fa-times:after {
  content: "\10F00D";
}
.fad.fa-times-circle:after {
  content: "\10F057";
}
.fad.fa-times-hexagon:after {
  content: "\10F2EE";
}
.fad.fa-times-octagon:after {
  content: "\10F2F0";
}
.fad.fa-times-square:after {
  content: "\10F2D3";
}
.fad.fa-tint:after {
  content: "\10F043";
}
.fad.fa-tint-slash:after {
  content: "\10F5C7";
}
.fad.fa-tire:after {
  content: "\10F631";
}
.fad.fa-tire-flat:after {
  content: "\10F632";
}
.fad.fa-tire-pressure-warning:after {
  content: "\10F633";
}
.fad.fa-tire-rugged:after {
  content: "\10F634";
}
.fad.fa-tired:after {
  content: "\10F5C8";
}
.fad.fa-toggle-off:after {
  content: "\10F204";
}
.fad.fa-toggle-on:after {
  content: "\10F205";
}
.fad.fa-toilet:after {
  content: "\10F7D8";
}
.fad.fa-toilet-paper:after {
  content: "\10F71E";
}
.fad.fa-toilet-paper-alt:after {
  content: "\10F71F";
}
.fad.fa-toilet-paper-slash:after {
  content: "\10F972";
}
.fad.fa-tombstone:after {
  content: "\10F720";
}
.fad.fa-tombstone-alt:after {
  content: "\10F721";
}
.fad.fa-toolbox:after {
  content: "\10F552";
}
.fad.fa-tools:after {
  content: "\10F7D9";
}
.fad.fa-tooth:after {
  content: "\10F5C9";
}
.fad.fa-toothbrush:after {
  content: "\10F635";
}
.fad.fa-torah:after {
  content: "\10F6A0";
}
.fad.fa-torii-gate:after {
  content: "\10F6A1";
}
.fad.fa-tornado:after {
  content: "\10F76F";
}
.fad.fa-tractor:after {
  content: "\10F722";
}
.fad.fa-trademark:after {
  content: "\10F25C";
}
.fad.fa-traffic-cone:after {
  content: "\10F636";
}
.fad.fa-traffic-light:after {
  content: "\10F637";
}
.fad.fa-traffic-light-go:after {
  content: "\10F638";
}
.fad.fa-traffic-light-slow:after {
  content: "\10F639";
}
.fad.fa-traffic-light-stop:after {
  content: "\10F63A";
}
.fad.fa-trailer:after {
  content: "\10F941";
}
.fad.fa-train:after {
  content: "\10F238";
}
.fad.fa-tram:after {
  content: "\10F7DA";
}
.fad.fa-transgender:after {
  content: "\10F224";
}
.fad.fa-transgender-alt:after {
  content: "\10F225";
}
.fad.fa-transporter:after {
  content: "\10F942";
}
.fad.fa-transporter-1:after {
  content: "\10F943";
}
.fad.fa-transporter-2:after {
  content: "\10F944";
}
.fad.fa-transporter-3:after {
  content: "\10F945";
}
.fad.fa-transporter-empty:after {
  content: "\10F946";
}
.fad.fa-trash:after {
  content: "\10F1F8";
}
.fad.fa-trash-alt:after {
  content: "\10F2ED";
}
.fad.fa-trash-restore:after {
  content: "\10F829";
}
.fad.fa-trash-restore-alt:after {
  content: "\10F82A";
}
.fad.fa-trash-undo:after {
  content: "\10F895";
}
.fad.fa-trash-undo-alt:after {
  content: "\10F896";
}
.fad.fa-treasure-chest:after {
  content: "\10F723";
}
.fad.fa-tree:after {
  content: "\10F1BB";
}
.fad.fa-tree-alt:after {
  content: "\10F400";
}
.fad.fa-tree-christmas:after {
  content: "\10F7DB";
}
.fad.fa-tree-decorated:after {
  content: "\10F7DC";
}
.fad.fa-tree-large:after {
  content: "\10F7DD";
}
.fad.fa-tree-palm:after {
  content: "\10F82B";
}
.fad.fa-trees:after {
  content: "\10F724";
}
.fad.fa-triangle:after {
  content: "\10F2EC";
}
.fad.fa-triangle-music:after {
  content: "\10F8E2";
}
.fad.fa-trophy:after {
  content: "\10F091";
}
.fad.fa-trophy-alt:after {
  content: "\10F2EB";
}
.fad.fa-truck:after {
  content: "\10F0D1";
}
.fad.fa-truck-container:after {
  content: "\10F4DC";
}
.fad.fa-truck-couch:after {
  content: "\10F4DD";
}
.fad.fa-truck-loading:after {
  content: "\10F4DE";
}
.fad.fa-truck-monster:after {
  content: "\10F63B";
}
.fad.fa-truck-moving:after {
  content: "\10F4DF";
}
.fad.fa-truck-pickup:after {
  content: "\10F63C";
}
.fad.fa-truck-plow:after {
  content: "\10F7DE";
}
.fad.fa-truck-ramp:after {
  content: "\10F4E0";
}
.fad.fa-trumpet:after {
  content: "\10F8E3";
}
.fad.fa-tshirt:after {
  content: "\10F553";
}
.fad.fa-tty:after {
  content: "\10F1E4";
}
.fad.fa-turkey:after {
  content: "\10F725";
}
.fad.fa-turntable:after {
  content: "\10F8E4";
}
.fad.fa-turtle:after {
  content: "\10F726";
}
.fad.fa-tv:after {
  content: "\10F26C";
}
.fad.fa-tv-alt:after {
  content: "\10F8E5";
}
.fad.fa-tv-music:after {
  content: "\10F8E6";
}
.fad.fa-tv-retro:after {
  content: "\10F401";
}
.fad.fa-typewriter:after {
  content: "\10F8E7";
}
.fad.fa-ufo:after {
  content: "\10F947";
}
.fad.fa-ufo-beam:after {
  content: "\10F948";
}
.fad.fa-umbrella:after {
  content: "\10F0E9";
}
.fad.fa-umbrella-beach:after {
  content: "\10F5CA";
}
.fad.fa-underline:after {
  content: "\10F0CD";
}
.fad.fa-undo:after {
  content: "\10F0E2";
}
.fad.fa-undo-alt:after {
  content: "\10F2EA";
}
.fad.fa-unicorn:after {
  content: "\10F727";
}
.fad.fa-union:after {
  content: "\10F6A2";
}
.fad.fa-universal-access:after {
  content: "\10F29A";
}
.fad.fa-university:after {
  content: "\10F19C";
}
.fad.fa-unlink:after {
  content: "\10F127";
}
.fad.fa-unlock:after {
  content: "\10F09C";
}
.fad.fa-unlock-alt:after {
  content: "\10F13E";
}
.fad.fa-upload:after {
  content: "\10F093";
}
.fad.fa-usb-drive:after {
  content: "\10F8E9";
}
.fad.fa-usd-circle:after {
  content: "\10F2E8";
}
.fad.fa-usd-square:after {
  content: "\10F2E9";
}
.fad.fa-user:after {
  content: "\10F007";
}
.fad.fa-user-alien:after {
  content: "\10F94A";
}
.fad.fa-user-alt:after {
  content: "\10F406";
}
.fad.fa-user-alt-slash:after {
  content: "\10F4FA";
}
.fad.fa-user-astronaut:after {
  content: "\10F4FB";
}
.fad.fa-user-chart:after {
  content: "\10F6A3";
}
.fad.fa-user-check:after {
  content: "\10F4FC";
}
.fad.fa-user-circle:after {
  content: "\10F2BD";
}
.fad.fa-user-clock:after {
  content: "\10F4FD";
}
.fad.fa-user-cog:after {
  content: "\10F4FE";
}
.fad.fa-user-cowboy:after {
  content: "\10F8EA";
}
.fad.fa-user-crown:after {
  content: "\10F6A4";
}
.fad.fa-user-edit:after {
  content: "\10F4FF";
}
.fad.fa-user-friends:after {
  content: "\10F500";
}
.fad.fa-user-graduate:after {
  content: "\10F501";
}
.fad.fa-user-hard-hat:after {
  content: "\10F82C";
}
.fad.fa-user-headset:after {
  content: "\10F82D";
}
.fad.fa-user-injured:after {
  content: "\10F728";
}
.fad.fa-user-lock:after {
  content: "\10F502";
}
.fad.fa-user-md:after {
  content: "\10F0F0";
}
.fad.fa-user-md-chat:after {
  content: "\10F82E";
}
.fad.fa-user-minus:after {
  content: "\10F503";
}
.fad.fa-user-music:after {
  content: "\10F8EB";
}
.fad.fa-user-ninja:after {
  content: "\10F504";
}
.fad.fa-user-nurse:after {
  content: "\10F82F";
}
.fad.fa-user-plus:after {
  content: "\10F234";
}
.fad.fa-user-robot:after {
  content: "\10F94B";
}
.fad.fa-user-secret:after {
  content: "\10F21B";
}
.fad.fa-user-shield:after {
  content: "\10F505";
}
.fad.fa-user-slash:after {
  content: "\10F506";
}
.fad.fa-user-tag:after {
  content: "\10F507";
}
.fad.fa-user-tie:after {
  content: "\10F508";
}
.fad.fa-user-times:after {
  content: "\10F235";
}
.fad.fa-user-unlock:after {
  content: "\10F958";
}
.fad.fa-user-visor:after {
  content: "\10F94C";
}
.fad.fa-users:after {
  content: "\10F0C0";
}
.fad.fa-users-class:after {
  content: "\10F63D";
}
.fad.fa-users-cog:after {
  content: "\10F509";
}
.fad.fa-users-crown:after {
  content: "\10F6A5";
}
.fad.fa-users-medical:after {
  content: "\10F830";
}
.fad.fa-users-slash:after {
  content: "\10F973";
}
.fad.fa-utensil-fork:after {
  content: "\10F2E3";
}
.fad.fa-utensil-knife:after {
  content: "\10F2E4";
}
.fad.fa-utensil-spoon:after {
  content: "\10F2E5";
}
.fad.fa-utensils:after {
  content: "\10F2E7";
}
.fad.fa-utensils-alt:after {
  content: "\10F2E6";
}
.fad.fa-vacuum:after {
  content: "\10F94D";
}
.fad.fa-vacuum-robot:after {
  content: "\10F94E";
}
.fad.fa-value-absolute:after {
  content: "\10F6A6";
}
.fad.fa-vector-square:after {
  content: "\10F5CB";
}
.fad.fa-venus:after {
  content: "\10F221";
}
.fad.fa-venus-double:after {
  content: "\10F226";
}
.fad.fa-venus-mars:after {
  content: "\10F228";
}
.fad.fa-vhs:after {
  content: "\10F8EC";
}
.fad.fa-vial:after {
  content: "\10F492";
}
.fad.fa-vials:after {
  content: "\10F493";
}
.fad.fa-video:after {
  content: "\10F03D";
}
.fad.fa-video-plus:after {
  content: "\10F4E1";
}
.fad.fa-video-slash:after {
  content: "\10F4E2";
}
.fad.fa-vihara:after {
  content: "\10F6A7";
}
.fad.fa-violin:after {
  content: "\10F8ED";
}
.fad.fa-virus:after {
  content: "\10F974";
}
.fad.fa-virus-slash:after {
  content: "\10F975";
}
.fad.fa-viruses:after {
  content: "\10F976";
}
.fad.fa-voicemail:after {
  content: "\10F897";
}
.fad.fa-volcano:after {
  content: "\10F770";
}
.fad.fa-volleyball-ball:after {
  content: "\10F45F";
}
.fad.fa-volume:after {
  content: "\10F6A8";
}
.fad.fa-volume-down:after {
  content: "\10F027";
}
.fad.fa-volume-mute:after {
  content: "\10F6A9";
}
.fad.fa-volume-off:after {
  content: "\10F026";
}
.fad.fa-volume-slash:after {
  content: "\10F2E2";
}
.fad.fa-volume-up:after {
  content: "\10F028";
}
.fad.fa-vote-nay:after {
  content: "\10F771";
}
.fad.fa-vote-yea:after {
  content: "\10F772";
}
.fad.fa-vr-cardboard:after {
  content: "\10F729";
}
.fad.fa-wagon-covered:after {
  content: "\10F8EE";
}
.fad.fa-walker:after {
  content: "\10F831";
}
.fad.fa-walkie-talkie:after {
  content: "\10F8EF";
}
.fad.fa-walking:after {
  content: "\10F554";
}
.fad.fa-wallet:after {
  content: "\10F555";
}
.fad.fa-wand:after {
  content: "\10F72A";
}
.fad.fa-wand-magic:after {
  content: "\10F72B";
}
.fad.fa-warehouse:after {
  content: "\10F494";
}
.fad.fa-warehouse-alt:after {
  content: "\10F495";
}
.fad.fa-washer:after {
  content: "\10F898";
}
.fad.fa-watch:after {
  content: "\10F2E1";
}
.fad.fa-watch-calculator:after {
  content: "\10F8F0";
}
.fad.fa-watch-fitness:after {
  content: "\10F63E";
}
.fad.fa-water:after {
  content: "\10F773";
}
.fad.fa-water-lower:after {
  content: "\10F774";
}
.fad.fa-water-rise:after {
  content: "\10F775";
}
.fad.fa-wave-sine:after {
  content: "\10F899";
}
.fad.fa-wave-square:after {
  content: "\10F83E";
}
.fad.fa-wave-triangle:after {
  content: "\10F89A";
}
.fad.fa-waveform:after {
  content: "\10F8F1";
}
.fad.fa-waveform-path:after {
  content: "\10F8F2";
}
.fad.fa-webcam:after {
  content: "\10F832";
}
.fad.fa-webcam-slash:after {
  content: "\10F833";
}
.fad.fa-weight:after {
  content: "\10F496";
}
.fad.fa-weight-hanging:after {
  content: "\10F5CD";
}
.fad.fa-whale:after {
  content: "\10F72C";
}
.fad.fa-wheat:after {
  content: "\10F72D";
}
.fad.fa-wheelchair:after {
  content: "\10F193";
}
.fad.fa-whistle:after {
  content: "\10F460";
}
.fad.fa-wifi:after {
  content: "\10F1EB";
}
.fad.fa-wifi-1:after {
  content: "\10F6AA";
}
.fad.fa-wifi-2:after {
  content: "\10F6AB";
}
.fad.fa-wifi-slash:after {
  content: "\10F6AC";
}
.fad.fa-wind:after {
  content: "\10F72E";
}
.fad.fa-wind-turbine:after {
  content: "\10F89B";
}
.fad.fa-wind-warning:after {
  content: "\10F776";
}
.fad.fa-window:after {
  content: "\10F40E";
}
.fad.fa-window-alt:after {
  content: "\10F40F";
}
.fad.fa-window-close:after {
  content: "\10F410";
}
.fad.fa-window-frame:after {
  content: "\10F94F";
}
.fad.fa-window-frame-open:after {
  content: "\10F950";
}
.fad.fa-window-maximize:after {
  content: "\10F2D0";
}
.fad.fa-window-minimize:after {
  content: "\10F2D1";
}
.fad.fa-window-restore:after {
  content: "\10F2D2";
}
.fad.fa-windsock:after {
  content: "\10F777";
}
.fad.fa-wine-bottle:after {
  content: "\10F72F";
}
.fad.fa-wine-glass:after {
  content: "\10F4E3";
}
.fad.fa-wine-glass-alt:after {
  content: "\10F5CE";
}
.fad.fa-won-sign:after {
  content: "\10F159";
}
.fad.fa-wreath:after {
  content: "\10F7E2";
}
.fad.fa-wrench:after {
  content: "\10F0AD";
}
.fad.fa-x-ray:after {
  content: "\10F497";
}
.fad.fa-yen-sign:after {
  content: "\10F157";
}
.fad.fa-yin-yang:after {
  content: "\10F6AD";
}
/*!
 * Font Awesome Pro 5.13.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(../../static/media/fa-solid-900.98d38a5d.eot);
  src: url(../../static/media/fa-solid-900.98d38a5d.eot?#iefix) format('embedded-opentype'), url(../../static/media/fa-solid-900.16e9dbeb.woff2) format('woff2'), url(../../static/media/fa-solid-900.0f7c8f7d.woff) format('woff'), url(../../static/media/fa-solid-900.977e6fae.ttf) format('truetype'), url(../../static/media/fa-solid-900.ff4da5ed.svg#fontawesome) format('svg');
}
.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}
/*!
 * Font Awesome Pro 5.13.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(../../static/media/fa-regular-400.5739d023.eot);
  src: url(../../static/media/fa-regular-400.5739d023.eot?#iefix) format('embedded-opentype'), url(../../static/media/fa-regular-400.dd049a64.woff2) format('woff2'), url(../../static/media/fa-regular-400.f5c3ba79.woff) format('woff'), url(../../static/media/fa-regular-400.6ee0bdf1.ttf) format('truetype'), url(../../static/media/fa-regular-400.606e54f6.svg#fontawesome) format('svg');
}
.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}
/*!
 * Font Awesome Pro 5.13.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url(../../static/media/fa-light-300.1980e028.eot);
  src: url(../../static/media/fa-light-300.1980e028.eot?#iefix) format('embedded-opentype'), url(../../static/media/fa-light-300.68c2f74f.woff2) format('woff2'), url(../../static/media/fa-light-300.f056755c.woff) format('woff'), url(../../static/media/fa-light-300.5bdaa858.ttf) format('truetype'), url(../../static/media/fa-light-300.4433b4f4.svg#fontawesome) format('svg');
}
.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}
/*!
 * Font Awesome Pro 5.13.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.far,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}
.fa-xs {
  font-size: .75em;
}
.fa-sm {
  font-size: .875em;
}
.fa-1x {
  font-size: 1em;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-6x {
  font-size: 6em;
}
.fa-7x {
  font-size: 7em;
}
.fa-8x {
  font-size: 8em;
}
.fa-9x {
  font-size: 9em;
}
.fa-10x {
  font-size: 10em;
}
.fa-fw {
  text-align: center;
  width: 1.25em;
}
.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}
.fa-border {
  border-radius: .1em;
  border: solid 0.08em #eee;
  padding: .2em .25em .15em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}
.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em;
}
.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em;
}
.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
          animation: fa-spin 1s infinite steps(8);
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1);
}
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1);
}
.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  -webkit-filter: none;
          filter: none;
}
.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em;
}
.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #fff;
}
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\F26E";
}
.fa-abacus:before {
  content: "\F640";
}
.fa-accessible-icon:before {
  content: "\F368";
}
.fa-accusoft:before {
  content: "\F369";
}
.fa-acorn:before {
  content: "\F6AE";
}
.fa-acquisitions-incorporated:before {
  content: "\F6AF";
}
.fa-ad:before {
  content: "\F641";
}
.fa-address-book:before {
  content: "\F2B9";
}
.fa-address-card:before {
  content: "\F2BB";
}
.fa-adjust:before {
  content: "\F042";
}
.fa-adn:before {
  content: "\F170";
}
.fa-adobe:before {
  content: "\F778";
}
.fa-adversal:before {
  content: "\F36A";
}
.fa-affiliatetheme:before {
  content: "\F36B";
}
.fa-air-conditioner:before {
  content: "\F8F4";
}
.fa-air-freshener:before {
  content: "\F5D0";
}
.fa-airbnb:before {
  content: "\F834";
}
.fa-alarm-clock:before {
  content: "\F34E";
}
.fa-alarm-exclamation:before {
  content: "\F843";
}
.fa-alarm-plus:before {
  content: "\F844";
}
.fa-alarm-snooze:before {
  content: "\F845";
}
.fa-album:before {
  content: "\F89F";
}
.fa-album-collection:before {
  content: "\F8A0";
}
.fa-algolia:before {
  content: "\F36C";
}
.fa-alicorn:before {
  content: "\F6B0";
}
.fa-alien:before {
  content: "\F8F5";
}
.fa-alien-monster:before {
  content: "\F8F6";
}
.fa-align-center:before {
  content: "\F037";
}
.fa-align-justify:before {
  content: "\F039";
}
.fa-align-left:before {
  content: "\F036";
}
.fa-align-right:before {
  content: "\F038";
}
.fa-align-slash:before {
  content: "\F846";
}
.fa-alipay:before {
  content: "\F642";
}
.fa-allergies:before {
  content: "\F461";
}
.fa-amazon:before {
  content: "\F270";
}
.fa-amazon-pay:before {
  content: "\F42C";
}
.fa-ambulance:before {
  content: "\F0F9";
}
.fa-american-sign-language-interpreting:before {
  content: "\F2A3";
}
.fa-amilia:before {
  content: "\F36D";
}
.fa-amp-guitar:before {
  content: "\F8A1";
}
.fa-analytics:before {
  content: "\F643";
}
.fa-anchor:before {
  content: "\F13D";
}
.fa-android:before {
  content: "\F17B";
}
.fa-angel:before {
  content: "\F779";
}
.fa-angellist:before {
  content: "\F209";
}
.fa-angle-double-down:before {
  content: "\F103";
}
.fa-angle-double-left:before {
  content: "\F100";
}
.fa-angle-double-right:before {
  content: "\F101";
}
.fa-angle-double-up:before {
  content: "\F102";
}
.fa-angle-down:before {
  content: "\F107";
}
.fa-angle-left:before {
  content: "\F104";
}
.fa-angle-right:before {
  content: "\F105";
}
.fa-angle-up:before {
  content: "\F106";
}
.fa-angry:before {
  content: "\F556";
}
.fa-angrycreative:before {
  content: "\F36E";
}
.fa-angular:before {
  content: "\F420";
}
.fa-ankh:before {
  content: "\F644";
}
.fa-app-store:before {
  content: "\F36F";
}
.fa-app-store-ios:before {
  content: "\F370";
}
.fa-apper:before {
  content: "\F371";
}
.fa-apple:before {
  content: "\F179";
}
.fa-apple-alt:before {
  content: "\F5D1";
}
.fa-apple-crate:before {
  content: "\F6B1";
}
.fa-apple-pay:before {
  content: "\F415";
}
.fa-archive:before {
  content: "\F187";
}
.fa-archway:before {
  content: "\F557";
}
.fa-arrow-alt-circle-down:before {
  content: "\F358";
}
.fa-arrow-alt-circle-left:before {
  content: "\F359";
}
.fa-arrow-alt-circle-right:before {
  content: "\F35A";
}
.fa-arrow-alt-circle-up:before {
  content: "\F35B";
}
.fa-arrow-alt-down:before {
  content: "\F354";
}
.fa-arrow-alt-from-bottom:before {
  content: "\F346";
}
.fa-arrow-alt-from-left:before {
  content: "\F347";
}
.fa-arrow-alt-from-right:before {
  content: "\F348";
}
.fa-arrow-alt-from-top:before {
  content: "\F349";
}
.fa-arrow-alt-left:before {
  content: "\F355";
}
.fa-arrow-alt-right:before {
  content: "\F356";
}
.fa-arrow-alt-square-down:before {
  content: "\F350";
}
.fa-arrow-alt-square-left:before {
  content: "\F351";
}
.fa-arrow-alt-square-right:before {
  content: "\F352";
}
.fa-arrow-alt-square-up:before {
  content: "\F353";
}
.fa-arrow-alt-to-bottom:before {
  content: "\F34A";
}
.fa-arrow-alt-to-left:before {
  content: "\F34B";
}
.fa-arrow-alt-to-right:before {
  content: "\F34C";
}
.fa-arrow-alt-to-top:before {
  content: "\F34D";
}
.fa-arrow-alt-up:before {
  content: "\F357";
}
.fa-arrow-circle-down:before {
  content: "\F0AB";
}
.fa-arrow-circle-left:before {
  content: "\F0A8";
}
.fa-arrow-circle-right:before {
  content: "\F0A9";
}
.fa-arrow-circle-up:before {
  content: "\F0AA";
}
.fa-arrow-down:before {
  content: "\F063";
}
.fa-arrow-from-bottom:before {
  content: "\F342";
}
.fa-arrow-from-left:before {
  content: "\F343";
}
.fa-arrow-from-right:before {
  content: "\F344";
}
.fa-arrow-from-top:before {
  content: "\F345";
}
.fa-arrow-left:before {
  content: "\F060";
}
.fa-arrow-right:before {
  content: "\F061";
}
.fa-arrow-square-down:before {
  content: "\F339";
}
.fa-arrow-square-left:before {
  content: "\F33A";
}
.fa-arrow-square-right:before {
  content: "\F33B";
}
.fa-arrow-square-up:before {
  content: "\F33C";
}
.fa-arrow-to-bottom:before {
  content: "\F33D";
}
.fa-arrow-to-left:before {
  content: "\F33E";
}
.fa-arrow-to-right:before {
  content: "\F340";
}
.fa-arrow-to-top:before {
  content: "\F341";
}
.fa-arrow-up:before {
  content: "\F062";
}
.fa-arrows:before {
  content: "\F047";
}
.fa-arrows-alt:before {
  content: "\F0B2";
}
.fa-arrows-alt-h:before {
  content: "\F337";
}
.fa-arrows-alt-v:before {
  content: "\F338";
}
.fa-arrows-h:before {
  content: "\F07E";
}
.fa-arrows-v:before {
  content: "\F07D";
}
.fa-artstation:before {
  content: "\F77A";
}
.fa-assistive-listening-systems:before {
  content: "\F2A2";
}
.fa-asterisk:before {
  content: "\F069";
}
.fa-asymmetrik:before {
  content: "\F372";
}
.fa-at:before {
  content: "\F1FA";
}
.fa-atlas:before {
  content: "\F558";
}
.fa-atlassian:before {
  content: "\F77B";
}
.fa-atom:before {
  content: "\F5D2";
}
.fa-atom-alt:before {
  content: "\F5D3";
}
.fa-audible:before {
  content: "\F373";
}
.fa-audio-description:before {
  content: "\F29E";
}
.fa-autoprefixer:before {
  content: "\F41C";
}
.fa-avianex:before {
  content: "\F374";
}
.fa-aviato:before {
  content: "\F421";
}
.fa-award:before {
  content: "\F559";
}
.fa-aws:before {
  content: "\F375";
}
.fa-axe:before {
  content: "\F6B2";
}
.fa-axe-battle:before {
  content: "\F6B3";
}
.fa-baby:before {
  content: "\F77C";
}
.fa-baby-carriage:before {
  content: "\F77D";
}
.fa-backpack:before {
  content: "\F5D4";
}
.fa-backspace:before {
  content: "\F55A";
}
.fa-backward:before {
  content: "\F04A";
}
.fa-bacon:before {
  content: "\F7E5";
}
.fa-bacteria:before {
  content: "\F959";
}
.fa-bacterium:before {
  content: "\F95A";
}
.fa-badge:before {
  content: "\F335";
}
.fa-badge-check:before {
  content: "\F336";
}
.fa-badge-dollar:before {
  content: "\F645";
}
.fa-badge-percent:before {
  content: "\F646";
}
.fa-badge-sheriff:before {
  content: "\F8A2";
}
.fa-badger-honey:before {
  content: "\F6B4";
}
.fa-bags-shopping:before {
  content: "\F847";
}
.fa-bahai:before {
  content: "\F666";
}
.fa-balance-scale:before {
  content: "\F24E";
}
.fa-balance-scale-left:before {
  content: "\F515";
}
.fa-balance-scale-right:before {
  content: "\F516";
}
.fa-ball-pile:before {
  content: "\F77E";
}
.fa-ballot:before {
  content: "\F732";
}
.fa-ballot-check:before {
  content: "\F733";
}
.fa-ban:before {
  content: "\F05E";
}
.fa-band-aid:before {
  content: "\F462";
}
.fa-bandcamp:before {
  content: "\F2D5";
}
.fa-banjo:before {
  content: "\F8A3";
}
.fa-barcode:before {
  content: "\F02A";
}
.fa-barcode-alt:before {
  content: "\F463";
}
.fa-barcode-read:before {
  content: "\F464";
}
.fa-barcode-scan:before {
  content: "\F465";
}
.fa-bars:before {
  content: "\F0C9";
}
.fa-baseball:before {
  content: "\F432";
}
.fa-baseball-ball:before {
  content: "\F433";
}
.fa-basketball-ball:before {
  content: "\F434";
}
.fa-basketball-hoop:before {
  content: "\F435";
}
.fa-bat:before {
  content: "\F6B5";
}
.fa-bath:before {
  content: "\F2CD";
}
.fa-battery-bolt:before {
  content: "\F376";
}
.fa-battery-empty:before {
  content: "\F244";
}
.fa-battery-full:before {
  content: "\F240";
}
.fa-battery-half:before {
  content: "\F242";
}
.fa-battery-quarter:before {
  content: "\F243";
}
.fa-battery-slash:before {
  content: "\F377";
}
.fa-battery-three-quarters:before {
  content: "\F241";
}
.fa-battle-net:before {
  content: "\F835";
}
.fa-bed:before {
  content: "\F236";
}
.fa-bed-alt:before {
  content: "\F8F7";
}
.fa-bed-bunk:before {
  content: "\F8F8";
}
.fa-bed-empty:before {
  content: "\F8F9";
}
.fa-beer:before {
  content: "\F0FC";
}
.fa-behance:before {
  content: "\F1B4";
}
.fa-behance-square:before {
  content: "\F1B5";
}
.fa-bell:before {
  content: "\F0F3";
}
.fa-bell-exclamation:before {
  content: "\F848";
}
.fa-bell-on:before {
  content: "\F8FA";
}
.fa-bell-plus:before {
  content: "\F849";
}
.fa-bell-school:before {
  content: "\F5D5";
}
.fa-bell-school-slash:before {
  content: "\F5D6";
}
.fa-bell-slash:before {
  content: "\F1F6";
}
.fa-bells:before {
  content: "\F77F";
}
.fa-betamax:before {
  content: "\F8A4";
}
.fa-bezier-curve:before {
  content: "\F55B";
}
.fa-bible:before {
  content: "\F647";
}
.fa-bicycle:before {
  content: "\F206";
}
.fa-biking:before {
  content: "\F84A";
}
.fa-biking-mountain:before {
  content: "\F84B";
}
.fa-bimobject:before {
  content: "\F378";
}
.fa-binoculars:before {
  content: "\F1E5";
}
.fa-biohazard:before {
  content: "\F780";
}
.fa-birthday-cake:before {
  content: "\F1FD";
}
.fa-bitbucket:before {
  content: "\F171";
}
.fa-bitcoin:before {
  content: "\F379";
}
.fa-bity:before {
  content: "\F37A";
}
.fa-black-tie:before {
  content: "\F27E";
}
.fa-blackberry:before {
  content: "\F37B";
}
.fa-blanket:before {
  content: "\F498";
}
.fa-blender:before {
  content: "\F517";
}
.fa-blender-phone:before {
  content: "\F6B6";
}
.fa-blind:before {
  content: "\F29D";
}
.fa-blinds:before {
  content: "\F8FB";
}
.fa-blinds-open:before {
  content: "\F8FC";
}
.fa-blinds-raised:before {
  content: "\F8FD";
}
.fa-blog:before {
  content: "\F781";
}
.fa-blogger:before {
  content: "\F37C";
}
.fa-blogger-b:before {
  content: "\F37D";
}
.fa-bluetooth:before {
  content: "\F293";
}
.fa-bluetooth-b:before {
  content: "\F294";
}
.fa-bold:before {
  content: "\F032";
}
.fa-bolt:before {
  content: "\F0E7";
}
.fa-bomb:before {
  content: "\F1E2";
}
.fa-bone:before {
  content: "\F5D7";
}
.fa-bone-break:before {
  content: "\F5D8";
}
.fa-bong:before {
  content: "\F55C";
}
.fa-book:before {
  content: "\F02D";
}
.fa-book-alt:before {
  content: "\F5D9";
}
.fa-book-dead:before {
  content: "\F6B7";
}
.fa-book-heart:before {
  content: "\F499";
}
.fa-book-medical:before {
  content: "\F7E6";
}
.fa-book-open:before {
  content: "\F518";
}
.fa-book-reader:before {
  content: "\F5DA";
}
.fa-book-spells:before {
  content: "\F6B8";
}
.fa-book-user:before {
  content: "\F7E7";
}
.fa-bookmark:before {
  content: "\F02E";
}
.fa-books:before {
  content: "\F5DB";
}
.fa-books-medical:before {
  content: "\F7E8";
}
.fa-boombox:before {
  content: "\F8A5";
}
.fa-boot:before {
  content: "\F782";
}
.fa-booth-curtain:before {
  content: "\F734";
}
.fa-bootstrap:before {
  content: "\F836";
}
.fa-border-all:before {
  content: "\F84C";
}
.fa-border-bottom:before {
  content: "\F84D";
}
.fa-border-center-h:before {
  content: "\F89C";
}
.fa-border-center-v:before {
  content: "\F89D";
}
.fa-border-inner:before {
  content: "\F84E";
}
.fa-border-left:before {
  content: "\F84F";
}
.fa-border-none:before {
  content: "\F850";
}
.fa-border-outer:before {
  content: "\F851";
}
.fa-border-right:before {
  content: "\F852";
}
.fa-border-style:before {
  content: "\F853";
}
.fa-border-style-alt:before {
  content: "\F854";
}
.fa-border-top:before {
  content: "\F855";
}
.fa-bow-arrow:before {
  content: "\F6B9";
}
.fa-bowling-ball:before {
  content: "\F436";
}
.fa-bowling-pins:before {
  content: "\F437";
}
.fa-box:before {
  content: "\F466";
}
.fa-box-alt:before {
  content: "\F49A";
}
.fa-box-ballot:before {
  content: "\F735";
}
.fa-box-check:before {
  content: "\F467";
}
.fa-box-fragile:before {
  content: "\F49B";
}
.fa-box-full:before {
  content: "\F49C";
}
.fa-box-heart:before {
  content: "\F49D";
}
.fa-box-open:before {
  content: "\F49E";
}
.fa-box-tissue:before {
  content: "\F95B";
}
.fa-box-up:before {
  content: "\F49F";
}
.fa-box-usd:before {
  content: "\F4A0";
}
.fa-boxes:before {
  content: "\F468";
}
.fa-boxes-alt:before {
  content: "\F4A1";
}
.fa-boxing-glove:before {
  content: "\F438";
}
.fa-brackets:before {
  content: "\F7E9";
}
.fa-brackets-curly:before {
  content: "\F7EA";
}
.fa-braille:before {
  content: "\F2A1";
}
.fa-brain:before {
  content: "\F5DC";
}
.fa-bread-loaf:before {
  content: "\F7EB";
}
.fa-bread-slice:before {
  content: "\F7EC";
}
.fa-briefcase:before {
  content: "\F0B1";
}
.fa-briefcase-medical:before {
  content: "\F469";
}
.fa-bring-forward:before {
  content: "\F856";
}
.fa-bring-front:before {
  content: "\F857";
}
.fa-broadcast-tower:before {
  content: "\F519";
}
.fa-broom:before {
  content: "\F51A";
}
.fa-browser:before {
  content: "\F37E";
}
.fa-brush:before {
  content: "\F55D";
}
.fa-btc:before {
  content: "\F15A";
}
.fa-buffer:before {
  content: "\F837";
}
.fa-bug:before {
  content: "\F188";
}
.fa-building:before {
  content: "\F1AD";
}
.fa-bullhorn:before {
  content: "\F0A1";
}
.fa-bullseye:before {
  content: "\F140";
}
.fa-bullseye-arrow:before {
  content: "\F648";
}
.fa-bullseye-pointer:before {
  content: "\F649";
}
.fa-burger-soda:before {
  content: "\F858";
}
.fa-burn:before {
  content: "\F46A";
}
.fa-buromobelexperte:before {
  content: "\F37F";
}
.fa-burrito:before {
  content: "\F7ED";
}
.fa-bus:before {
  content: "\F207";
}
.fa-bus-alt:before {
  content: "\F55E";
}
.fa-bus-school:before {
  content: "\F5DD";
}
.fa-business-time:before {
  content: "\F64A";
}
.fa-buy-n-large:before {
  content: "\F8A6";
}
.fa-buysellads:before {
  content: "\F20D";
}
.fa-cabinet-filing:before {
  content: "\F64B";
}
.fa-cactus:before {
  content: "\F8A7";
}
.fa-calculator:before {
  content: "\F1EC";
}
.fa-calculator-alt:before {
  content: "\F64C";
}
.fa-calendar:before {
  content: "\F133";
}
.fa-calendar-alt:before {
  content: "\F073";
}
.fa-calendar-check:before {
  content: "\F274";
}
.fa-calendar-day:before {
  content: "\F783";
}
.fa-calendar-edit:before {
  content: "\F333";
}
.fa-calendar-exclamation:before {
  content: "\F334";
}
.fa-calendar-minus:before {
  content: "\F272";
}
.fa-calendar-plus:before {
  content: "\F271";
}
.fa-calendar-star:before {
  content: "\F736";
}
.fa-calendar-times:before {
  content: "\F273";
}
.fa-calendar-week:before {
  content: "\F784";
}
.fa-camcorder:before {
  content: "\F8A8";
}
.fa-camera:before {
  content: "\F030";
}
.fa-camera-alt:before {
  content: "\F332";
}
.fa-camera-home:before {
  content: "\F8FE";
}
.fa-camera-movie:before {
  content: "\F8A9";
}
.fa-camera-polaroid:before {
  content: "\F8AA";
}
.fa-camera-retro:before {
  content: "\F083";
}
.fa-campfire:before {
  content: "\F6BA";
}
.fa-campground:before {
  content: "\F6BB";
}
.fa-canadian-maple-leaf:before {
  content: "\F785";
}
.fa-candle-holder:before {
  content: "\F6BC";
}
.fa-candy-cane:before {
  content: "\F786";
}
.fa-candy-corn:before {
  content: "\F6BD";
}
.fa-cannabis:before {
  content: "\F55F";
}
.fa-capsules:before {
  content: "\F46B";
}
.fa-car:before {
  content: "\F1B9";
}
.fa-car-alt:before {
  content: "\F5DE";
}
.fa-car-battery:before {
  content: "\F5DF";
}
.fa-car-building:before {
  content: "\F859";
}
.fa-car-bump:before {
  content: "\F5E0";
}
.fa-car-bus:before {
  content: "\F85A";
}
.fa-car-crash:before {
  content: "\F5E1";
}
.fa-car-garage:before {
  content: "\F5E2";
}
.fa-car-mechanic:before {
  content: "\F5E3";
}
.fa-car-side:before {
  content: "\F5E4";
}
.fa-car-tilt:before {
  content: "\F5E5";
}
.fa-car-wash:before {
  content: "\F5E6";
}
.fa-caravan:before {
  content: "\F8FF";
}
.fa-caravan-alt:before {
  content: "\F900";
}
.fa-caret-circle-down:before {
  content: "\F32D";
}
.fa-caret-circle-left:before {
  content: "\F32E";
}
.fa-caret-circle-right:before {
  content: "\F330";
}
.fa-caret-circle-up:before {
  content: "\F331";
}
.fa-caret-down:before {
  content: "\F0D7";
}
.fa-caret-left:before {
  content: "\F0D9";
}
.fa-caret-right:before {
  content: "\F0DA";
}
.fa-caret-square-down:before {
  content: "\F150";
}
.fa-caret-square-left:before {
  content: "\F191";
}
.fa-caret-square-right:before {
  content: "\F152";
}
.fa-caret-square-up:before {
  content: "\F151";
}
.fa-caret-up:before {
  content: "\F0D8";
}
.fa-carrot:before {
  content: "\F787";
}
.fa-cars:before {
  content: "\F85B";
}
.fa-cart-arrow-down:before {
  content: "\F218";
}
.fa-cart-plus:before {
  content: "\F217";
}
.fa-cash-register:before {
  content: "\F788";
}
.fa-cassette-tape:before {
  content: "\F8AB";
}
.fa-cat:before {
  content: "\F6BE";
}
.fa-cat-space:before {
  content: "\F901";
}
.fa-cauldron:before {
  content: "\F6BF";
}
.fa-cc-amazon-pay:before {
  content: "\F42D";
}
.fa-cc-amex:before {
  content: "\F1F3";
}
.fa-cc-apple-pay:before {
  content: "\F416";
}
.fa-cc-diners-club:before {
  content: "\F24C";
}
.fa-cc-discover:before {
  content: "\F1F2";
}
.fa-cc-jcb:before {
  content: "\F24B";
}
.fa-cc-mastercard:before {
  content: "\F1F1";
}
.fa-cc-paypal:before {
  content: "\F1F4";
}
.fa-cc-stripe:before {
  content: "\F1F5";
}
.fa-cc-visa:before {
  content: "\F1F0";
}
.fa-cctv:before {
  content: "\F8AC";
}
.fa-centercode:before {
  content: "\F380";
}
.fa-centos:before {
  content: "\F789";
}
.fa-certificate:before {
  content: "\F0A3";
}
.fa-chair:before {
  content: "\F6C0";
}
.fa-chair-office:before {
  content: "\F6C1";
}
.fa-chalkboard:before {
  content: "\F51B";
}
.fa-chalkboard-teacher:before {
  content: "\F51C";
}
.fa-charging-station:before {
  content: "\F5E7";
}
.fa-chart-area:before {
  content: "\F1FE";
}
.fa-chart-bar:before {
  content: "\F080";
}
.fa-chart-line:before {
  content: "\F201";
}
.fa-chart-line-down:before {
  content: "\F64D";
}
.fa-chart-network:before {
  content: "\F78A";
}
.fa-chart-pie:before {
  content: "\F200";
}
.fa-chart-pie-alt:before {
  content: "\F64E";
}
.fa-chart-scatter:before {
  content: "\F7EE";
}
.fa-check:before {
  content: "\F00C";
}
.fa-check-circle:before {
  content: "\F058";
}
.fa-check-double:before {
  content: "\F560";
}
.fa-check-square:before {
  content: "\F14A";
}
.fa-cheese:before {
  content: "\F7EF";
}
.fa-cheese-swiss:before {
  content: "\F7F0";
}
.fa-cheeseburger:before {
  content: "\F7F1";
}
.fa-chess:before {
  content: "\F439";
}
.fa-chess-bishop:before {
  content: "\F43A";
}
.fa-chess-bishop-alt:before {
  content: "\F43B";
}
.fa-chess-board:before {
  content: "\F43C";
}
.fa-chess-clock:before {
  content: "\F43D";
}
.fa-chess-clock-alt:before {
  content: "\F43E";
}
.fa-chess-king:before {
  content: "\F43F";
}
.fa-chess-king-alt:before {
  content: "\F440";
}
.fa-chess-knight:before {
  content: "\F441";
}
.fa-chess-knight-alt:before {
  content: "\F442";
}
.fa-chess-pawn:before {
  content: "\F443";
}
.fa-chess-pawn-alt:before {
  content: "\F444";
}
.fa-chess-queen:before {
  content: "\F445";
}
.fa-chess-queen-alt:before {
  content: "\F446";
}
.fa-chess-rook:before {
  content: "\F447";
}
.fa-chess-rook-alt:before {
  content: "\F448";
}
.fa-chevron-circle-down:before {
  content: "\F13A";
}
.fa-chevron-circle-left:before {
  content: "\F137";
}
.fa-chevron-circle-right:before {
  content: "\F138";
}
.fa-chevron-circle-up:before {
  content: "\F139";
}
.fa-chevron-double-down:before {
  content: "\F322";
}
.fa-chevron-double-left:before {
  content: "\F323";
}
.fa-chevron-double-right:before {
  content: "\F324";
}
.fa-chevron-double-up:before {
  content: "\F325";
}
.fa-chevron-down:before {
  content: "\F078";
}
.fa-chevron-left:before {
  content: "\F053";
}
.fa-chevron-right:before {
  content: "\F054";
}
.fa-chevron-square-down:before {
  content: "\F329";
}
.fa-chevron-square-left:before {
  content: "\F32A";
}
.fa-chevron-square-right:before {
  content: "\F32B";
}
.fa-chevron-square-up:before {
  content: "\F32C";
}
.fa-chevron-up:before {
  content: "\F077";
}
.fa-child:before {
  content: "\F1AE";
}
.fa-chimney:before {
  content: "\F78B";
}
.fa-chrome:before {
  content: "\F268";
}
.fa-chromecast:before {
  content: "\F838";
}
.fa-church:before {
  content: "\F51D";
}
.fa-circle:before {
  content: "\F111";
}
.fa-circle-notch:before {
  content: "\F1CE";
}
.fa-city:before {
  content: "\F64F";
}
.fa-clarinet:before {
  content: "\F8AD";
}
.fa-claw-marks:before {
  content: "\F6C2";
}
.fa-clinic-medical:before {
  content: "\F7F2";
}
.fa-clipboard:before {
  content: "\F328";
}
.fa-clipboard-check:before {
  content: "\F46C";
}
.fa-clipboard-list:before {
  content: "\F46D";
}
.fa-clipboard-list-check:before {
  content: "\F737";
}
.fa-clipboard-prescription:before {
  content: "\F5E8";
}
.fa-clipboard-user:before {
  content: "\F7F3";
}
.fa-clock:before {
  content: "\F017";
}
.fa-clone:before {
  content: "\F24D";
}
.fa-closed-captioning:before {
  content: "\F20A";
}
.fa-cloud:before {
  content: "\F0C2";
}
.fa-cloud-download:before {
  content: "\F0ED";
}
.fa-cloud-download-alt:before {
  content: "\F381";
}
.fa-cloud-drizzle:before {
  content: "\F738";
}
.fa-cloud-hail:before {
  content: "\F739";
}
.fa-cloud-hail-mixed:before {
  content: "\F73A";
}
.fa-cloud-meatball:before {
  content: "\F73B";
}
.fa-cloud-moon:before {
  content: "\F6C3";
}
.fa-cloud-moon-rain:before {
  content: "\F73C";
}
.fa-cloud-music:before {
  content: "\F8AE";
}
.fa-cloud-rain:before {
  content: "\F73D";
}
.fa-cloud-rainbow:before {
  content: "\F73E";
}
.fa-cloud-showers:before {
  content: "\F73F";
}
.fa-cloud-showers-heavy:before {
  content: "\F740";
}
.fa-cloud-sleet:before {
  content: "\F741";
}
.fa-cloud-snow:before {
  content: "\F742";
}
.fa-cloud-sun:before {
  content: "\F6C4";
}
.fa-cloud-sun-rain:before {
  content: "\F743";
}
.fa-cloud-upload:before {
  content: "\F0EE";
}
.fa-cloud-upload-alt:before {
  content: "\F382";
}
.fa-clouds:before {
  content: "\F744";
}
.fa-clouds-moon:before {
  content: "\F745";
}
.fa-clouds-sun:before {
  content: "\F746";
}
.fa-cloudscale:before {
  content: "\F383";
}
.fa-cloudsmith:before {
  content: "\F384";
}
.fa-cloudversify:before {
  content: "\F385";
}
.fa-club:before {
  content: "\F327";
}
.fa-cocktail:before {
  content: "\F561";
}
.fa-code:before {
  content: "\F121";
}
.fa-code-branch:before {
  content: "\F126";
}
.fa-code-commit:before {
  content: "\F386";
}
.fa-code-merge:before {
  content: "\F387";
}
.fa-codepen:before {
  content: "\F1CB";
}
.fa-codiepie:before {
  content: "\F284";
}
.fa-coffee:before {
  content: "\F0F4";
}
.fa-coffee-pot:before {
  content: "\F902";
}
.fa-coffee-togo:before {
  content: "\F6C5";
}
.fa-coffin:before {
  content: "\F6C6";
}
.fa-coffin-cross:before {
  content: "\F951";
}
.fa-cog:before {
  content: "\F013";
}
.fa-cogs:before {
  content: "\F085";
}
.fa-coin:before {
  content: "\F85C";
}
.fa-coins:before {
  content: "\F51E";
}
.fa-columns:before {
  content: "\F0DB";
}
.fa-comet:before {
  content: "\F903";
}
.fa-comment:before {
  content: "\F075";
}
.fa-comment-alt:before {
  content: "\F27A";
}
.fa-comment-alt-check:before {
  content: "\F4A2";
}
.fa-comment-alt-dollar:before {
  content: "\F650";
}
.fa-comment-alt-dots:before {
  content: "\F4A3";
}
.fa-comment-alt-edit:before {
  content: "\F4A4";
}
.fa-comment-alt-exclamation:before {
  content: "\F4A5";
}
.fa-comment-alt-lines:before {
  content: "\F4A6";
}
.fa-comment-alt-medical:before {
  content: "\F7F4";
}
.fa-comment-alt-minus:before {
  content: "\F4A7";
}
.fa-comment-alt-music:before {
  content: "\F8AF";
}
.fa-comment-alt-plus:before {
  content: "\F4A8";
}
.fa-comment-alt-slash:before {
  content: "\F4A9";
}
.fa-comment-alt-smile:before {
  content: "\F4AA";
}
.fa-comment-alt-times:before {
  content: "\F4AB";
}
.fa-comment-check:before {
  content: "\F4AC";
}
.fa-comment-dollar:before {
  content: "\F651";
}
.fa-comment-dots:before {
  content: "\F4AD";
}
.fa-comment-edit:before {
  content: "\F4AE";
}
.fa-comment-exclamation:before {
  content: "\F4AF";
}
.fa-comment-lines:before {
  content: "\F4B0";
}
.fa-comment-medical:before {
  content: "\F7F5";
}
.fa-comment-minus:before {
  content: "\F4B1";
}
.fa-comment-music:before {
  content: "\F8B0";
}
.fa-comment-plus:before {
  content: "\F4B2";
}
.fa-comment-slash:before {
  content: "\F4B3";
}
.fa-comment-smile:before {
  content: "\F4B4";
}
.fa-comment-times:before {
  content: "\F4B5";
}
.fa-comments:before {
  content: "\F086";
}
.fa-comments-alt:before {
  content: "\F4B6";
}
.fa-comments-alt-dollar:before {
  content: "\F652";
}
.fa-comments-dollar:before {
  content: "\F653";
}
.fa-compact-disc:before {
  content: "\F51F";
}
.fa-compass:before {
  content: "\F14E";
}
.fa-compass-slash:before {
  content: "\F5E9";
}
.fa-compress:before {
  content: "\F066";
}
.fa-compress-alt:before {
  content: "\F422";
}
.fa-compress-arrows-alt:before {
  content: "\F78C";
}
.fa-compress-wide:before {
  content: "\F326";
}
.fa-computer-classic:before {
  content: "\F8B1";
}
.fa-computer-speaker:before {
  content: "\F8B2";
}
.fa-concierge-bell:before {
  content: "\F562";
}
.fa-confluence:before {
  content: "\F78D";
}
.fa-connectdevelop:before {
  content: "\F20E";
}
.fa-construction:before {
  content: "\F85D";
}
.fa-container-storage:before {
  content: "\F4B7";
}
.fa-contao:before {
  content: "\F26D";
}
.fa-conveyor-belt:before {
  content: "\F46E";
}
.fa-conveyor-belt-alt:before {
  content: "\F46F";
}
.fa-cookie:before {
  content: "\F563";
}
.fa-cookie-bite:before {
  content: "\F564";
}
.fa-copy:before {
  content: "\F0C5";
}
.fa-copyright:before {
  content: "\F1F9";
}
.fa-corn:before {
  content: "\F6C7";
}
.fa-cotton-bureau:before {
  content: "\F89E";
}
.fa-couch:before {
  content: "\F4B8";
}
.fa-cow:before {
  content: "\F6C8";
}
.fa-cowbell:before {
  content: "\F8B3";
}
.fa-cowbell-more:before {
  content: "\F8B4";
}
.fa-cpanel:before {
  content: "\F388";
}
.fa-creative-commons:before {
  content: "\F25E";
}
.fa-creative-commons-by:before {
  content: "\F4E7";
}
.fa-creative-commons-nc:before {
  content: "\F4E8";
}
.fa-creative-commons-nc-eu:before {
  content: "\F4E9";
}
.fa-creative-commons-nc-jp:before {
  content: "\F4EA";
}
.fa-creative-commons-nd:before {
  content: "\F4EB";
}
.fa-creative-commons-pd:before {
  content: "\F4EC";
}
.fa-creative-commons-pd-alt:before {
  content: "\F4ED";
}
.fa-creative-commons-remix:before {
  content: "\F4EE";
}
.fa-creative-commons-sa:before {
  content: "\F4EF";
}
.fa-creative-commons-sampling:before {
  content: "\F4F0";
}
.fa-creative-commons-sampling-plus:before {
  content: "\F4F1";
}
.fa-creative-commons-share:before {
  content: "\F4F2";
}
.fa-creative-commons-zero:before {
  content: "\F4F3";
}
.fa-credit-card:before {
  content: "\F09D";
}
.fa-credit-card-blank:before {
  content: "\F389";
}
.fa-credit-card-front:before {
  content: "\F38A";
}
.fa-cricket:before {
  content: "\F449";
}
.fa-critical-role:before {
  content: "\F6C9";
}
.fa-croissant:before {
  content: "\F7F6";
}
.fa-crop:before {
  content: "\F125";
}
.fa-crop-alt:before {
  content: "\F565";
}
.fa-cross:before {
  content: "\F654";
}
.fa-crosshairs:before {
  content: "\F05B";
}
.fa-crow:before {
  content: "\F520";
}
.fa-crown:before {
  content: "\F521";
}
.fa-crutch:before {
  content: "\F7F7";
}
.fa-crutches:before {
  content: "\F7F8";
}
.fa-css3:before {
  content: "\F13C";
}
.fa-css3-alt:before {
  content: "\F38B";
}
.fa-cube:before {
  content: "\F1B2";
}
.fa-cubes:before {
  content: "\F1B3";
}
.fa-curling:before {
  content: "\F44A";
}
.fa-cut:before {
  content: "\F0C4";
}
.fa-cuttlefish:before {
  content: "\F38C";
}
.fa-d-and-d:before {
  content: "\F38D";
}
.fa-d-and-d-beyond:before {
  content: "\F6CA";
}
.fa-dagger:before {
  content: "\F6CB";
}
.fa-dailymotion:before {
  content: "\F952";
}
.fa-dashcube:before {
  content: "\F210";
}
.fa-database:before {
  content: "\F1C0";
}
.fa-deaf:before {
  content: "\F2A4";
}
.fa-debug:before {
  content: "\F7F9";
}
.fa-deer:before {
  content: "\F78E";
}
.fa-deer-rudolph:before {
  content: "\F78F";
}
.fa-delicious:before {
  content: "\F1A5";
}
.fa-democrat:before {
  content: "\F747";
}
.fa-deploydog:before {
  content: "\F38E";
}
.fa-deskpro:before {
  content: "\F38F";
}
.fa-desktop:before {
  content: "\F108";
}
.fa-desktop-alt:before {
  content: "\F390";
}
.fa-dev:before {
  content: "\F6CC";
}
.fa-deviantart:before {
  content: "\F1BD";
}
.fa-dewpoint:before {
  content: "\F748";
}
.fa-dharmachakra:before {
  content: "\F655";
}
.fa-dhl:before {
  content: "\F790";
}
.fa-diagnoses:before {
  content: "\F470";
}
.fa-diamond:before {
  content: "\F219";
}
.fa-diaspora:before {
  content: "\F791";
}
.fa-dice:before {
  content: "\F522";
}
.fa-dice-d10:before {
  content: "\F6CD";
}
.fa-dice-d12:before {
  content: "\F6CE";
}
.fa-dice-d20:before {
  content: "\F6CF";
}
.fa-dice-d4:before {
  content: "\F6D0";
}
.fa-dice-d6:before {
  content: "\F6D1";
}
.fa-dice-d8:before {
  content: "\F6D2";
}
.fa-dice-five:before {
  content: "\F523";
}
.fa-dice-four:before {
  content: "\F524";
}
.fa-dice-one:before {
  content: "\F525";
}
.fa-dice-six:before {
  content: "\F526";
}
.fa-dice-three:before {
  content: "\F527";
}
.fa-dice-two:before {
  content: "\F528";
}
.fa-digg:before {
  content: "\F1A6";
}
.fa-digging:before {
  content: "\F85E";
}
.fa-digital-ocean:before {
  content: "\F391";
}
.fa-digital-tachograph:before {
  content: "\F566";
}
.fa-diploma:before {
  content: "\F5EA";
}
.fa-directions:before {
  content: "\F5EB";
}
.fa-disc-drive:before {
  content: "\F8B5";
}
.fa-discord:before {
  content: "\F392";
}
.fa-discourse:before {
  content: "\F393";
}
.fa-disease:before {
  content: "\F7FA";
}
.fa-divide:before {
  content: "\F529";
}
.fa-dizzy:before {
  content: "\F567";
}
.fa-dna:before {
  content: "\F471";
}
.fa-do-not-enter:before {
  content: "\F5EC";
}
.fa-dochub:before {
  content: "\F394";
}
.fa-docker:before {
  content: "\F395";
}
.fa-dog:before {
  content: "\F6D3";
}
.fa-dog-leashed:before {
  content: "\F6D4";
}
.fa-dollar-sign:before {
  content: "\F155";
}
.fa-dolly:before {
  content: "\F472";
}
.fa-dolly-empty:before {
  content: "\F473";
}
.fa-dolly-flatbed:before {
  content: "\F474";
}
.fa-dolly-flatbed-alt:before {
  content: "\F475";
}
.fa-dolly-flatbed-empty:before {
  content: "\F476";
}
.fa-donate:before {
  content: "\F4B9";
}
.fa-door-closed:before {
  content: "\F52A";
}
.fa-door-open:before {
  content: "\F52B";
}
.fa-dot-circle:before {
  content: "\F192";
}
.fa-dove:before {
  content: "\F4BA";
}
.fa-download:before {
  content: "\F019";
}
.fa-draft2digital:before {
  content: "\F396";
}
.fa-drafting-compass:before {
  content: "\F568";
}
.fa-dragon:before {
  content: "\F6D5";
}
.fa-draw-circle:before {
  content: "\F5ED";
}
.fa-draw-polygon:before {
  content: "\F5EE";
}
.fa-draw-square:before {
  content: "\F5EF";
}
.fa-dreidel:before {
  content: "\F792";
}
.fa-dribbble:before {
  content: "\F17D";
}
.fa-dribbble-square:before {
  content: "\F397";
}
.fa-drone:before {
  content: "\F85F";
}
.fa-drone-alt:before {
  content: "\F860";
}
.fa-dropbox:before {
  content: "\F16B";
}
.fa-drum:before {
  content: "\F569";
}
.fa-drum-steelpan:before {
  content: "\F56A";
}
.fa-drumstick:before {
  content: "\F6D6";
}
.fa-drumstick-bite:before {
  content: "\F6D7";
}
.fa-drupal:before {
  content: "\F1A9";
}
.fa-dryer:before {
  content: "\F861";
}
.fa-dryer-alt:before {
  content: "\F862";
}
.fa-duck:before {
  content: "\F6D8";
}
.fa-dumbbell:before {
  content: "\F44B";
}
.fa-dumpster:before {
  content: "\F793";
}
.fa-dumpster-fire:before {
  content: "\F794";
}
.fa-dungeon:before {
  content: "\F6D9";
}
.fa-dyalog:before {
  content: "\F399";
}
.fa-ear:before {
  content: "\F5F0";
}
.fa-ear-muffs:before {
  content: "\F795";
}
.fa-earlybirds:before {
  content: "\F39A";
}
.fa-ebay:before {
  content: "\F4F4";
}
.fa-eclipse:before {
  content: "\F749";
}
.fa-eclipse-alt:before {
  content: "\F74A";
}
.fa-edge:before {
  content: "\F282";
}
.fa-edit:before {
  content: "\F044";
}
.fa-egg:before {
  content: "\F7FB";
}
.fa-egg-fried:before {
  content: "\F7FC";
}
.fa-eject:before {
  content: "\F052";
}
.fa-elementor:before {
  content: "\F430";
}
.fa-elephant:before {
  content: "\F6DA";
}
.fa-ellipsis-h:before {
  content: "\F141";
}
.fa-ellipsis-h-alt:before {
  content: "\F39B";
}
.fa-ellipsis-v:before {
  content: "\F142";
}
.fa-ellipsis-v-alt:before {
  content: "\F39C";
}
.fa-ello:before {
  content: "\F5F1";
}
.fa-ember:before {
  content: "\F423";
}
.fa-empire:before {
  content: "\F1D1";
}
.fa-empty-set:before {
  content: "\F656";
}
.fa-engine-warning:before {
  content: "\F5F2";
}
.fa-envelope:before {
  content: "\F0E0";
}
.fa-envelope-open:before {
  content: "\F2B6";
}
.fa-envelope-open-dollar:before {
  content: "\F657";
}
.fa-envelope-open-text:before {
  content: "\F658";
}
.fa-envelope-square:before {
  content: "\F199";
}
.fa-envira:before {
  content: "\F299";
}
.fa-equals:before {
  content: "\F52C";
}
.fa-eraser:before {
  content: "\F12D";
}
.fa-erlang:before {
  content: "\F39D";
}
.fa-ethereum:before {
  content: "\F42E";
}
.fa-ethernet:before {
  content: "\F796";
}
.fa-etsy:before {
  content: "\F2D7";
}
.fa-euro-sign:before {
  content: "\F153";
}
.fa-evernote:before {
  content: "\F839";
}
.fa-exchange:before {
  content: "\F0EC";
}
.fa-exchange-alt:before {
  content: "\F362";
}
.fa-exclamation:before {
  content: "\F12A";
}
.fa-exclamation-circle:before {
  content: "\F06A";
}
.fa-exclamation-square:before {
  content: "\F321";
}
.fa-exclamation-triangle:before {
  content: "\F071";
}
.fa-expand:before {
  content: "\F065";
}
.fa-expand-alt:before {
  content: "\F424";
}
.fa-expand-arrows:before {
  content: "\F31D";
}
.fa-expand-arrows-alt:before {
  content: "\F31E";
}
.fa-expand-wide:before {
  content: "\F320";
}
.fa-expeditedssl:before {
  content: "\F23E";
}
.fa-external-link:before {
  content: "\F08E";
}
.fa-external-link-alt:before {
  content: "\F35D";
}
.fa-external-link-square:before {
  content: "\F14C";
}
.fa-external-link-square-alt:before {
  content: "\F360";
}
.fa-eye:before {
  content: "\F06E";
}
.fa-eye-dropper:before {
  content: "\F1FB";
}
.fa-eye-evil:before {
  content: "\F6DB";
}
.fa-eye-slash:before {
  content: "\F070";
}
.fa-facebook:before {
  content: "\F09A";
}
.fa-facebook-f:before {
  content: "\F39E";
}
.fa-facebook-messenger:before {
  content: "\F39F";
}
.fa-facebook-square:before {
  content: "\F082";
}
.fa-fan:before {
  content: "\F863";
}
.fa-fan-table:before {
  content: "\F904";
}
.fa-fantasy-flight-games:before {
  content: "\F6DC";
}
.fa-farm:before {
  content: "\F864";
}
.fa-fast-backward:before {
  content: "\F049";
}
.fa-fast-forward:before {
  content: "\F050";
}
.fa-faucet:before {
  content: "\F905";
}
.fa-faucet-drip:before {
  content: "\F906";
}
.fa-fax:before {
  content: "\F1AC";
}
.fa-feather:before {
  content: "\F52D";
}
.fa-feather-alt:before {
  content: "\F56B";
}
.fa-fedex:before {
  content: "\F797";
}
.fa-fedora:before {
  content: "\F798";
}
.fa-female:before {
  content: "\F182";
}
.fa-field-hockey:before {
  content: "\F44C";
}
.fa-fighter-jet:before {
  content: "\F0FB";
}
.fa-figma:before {
  content: "\F799";
}
.fa-file:before {
  content: "\F15B";
}
.fa-file-alt:before {
  content: "\F15C";
}
.fa-file-archive:before {
  content: "\F1C6";
}
.fa-file-audio:before {
  content: "\F1C7";
}
.fa-file-certificate:before {
  content: "\F5F3";
}
.fa-file-chart-line:before {
  content: "\F659";
}
.fa-file-chart-pie:before {
  content: "\F65A";
}
.fa-file-check:before {
  content: "\F316";
}
.fa-file-code:before {
  content: "\F1C9";
}
.fa-file-contract:before {
  content: "\F56C";
}
.fa-file-csv:before {
  content: "\F6DD";
}
.fa-file-download:before {
  content: "\F56D";
}
.fa-file-edit:before {
  content: "\F31C";
}
.fa-file-excel:before {
  content: "\F1C3";
}
.fa-file-exclamation:before {
  content: "\F31A";
}
.fa-file-export:before {
  content: "\F56E";
}
.fa-file-image:before {
  content: "\F1C5";
}
.fa-file-import:before {
  content: "\F56F";
}
.fa-file-invoice:before {
  content: "\F570";
}
.fa-file-invoice-dollar:before {
  content: "\F571";
}
.fa-file-medical:before {
  content: "\F477";
}
.fa-file-medical-alt:before {
  content: "\F478";
}
.fa-file-minus:before {
  content: "\F318";
}
.fa-file-music:before {
  content: "\F8B6";
}
.fa-file-pdf:before {
  content: "\F1C1";
}
.fa-file-plus:before {
  content: "\F319";
}
.fa-file-powerpoint:before {
  content: "\F1C4";
}
.fa-file-prescription:before {
  content: "\F572";
}
.fa-file-search:before {
  content: "\F865";
}
.fa-file-signature:before {
  content: "\F573";
}
.fa-file-spreadsheet:before {
  content: "\F65B";
}
.fa-file-times:before {
  content: "\F317";
}
.fa-file-upload:before {
  content: "\F574";
}
.fa-file-user:before {
  content: "\F65C";
}
.fa-file-video:before {
  content: "\F1C8";
}
.fa-file-word:before {
  content: "\F1C2";
}
.fa-files-medical:before {
  content: "\F7FD";
}
.fa-fill:before {
  content: "\F575";
}
.fa-fill-drip:before {
  content: "\F576";
}
.fa-film:before {
  content: "\F008";
}
.fa-film-alt:before {
  content: "\F3A0";
}
.fa-film-canister:before {
  content: "\F8B7";
}
.fa-filter:before {
  content: "\F0B0";
}
.fa-fingerprint:before {
  content: "\F577";
}
.fa-fire:before {
  content: "\F06D";
}
.fa-fire-alt:before {
  content: "\F7E4";
}
.fa-fire-extinguisher:before {
  content: "\F134";
}
.fa-fire-smoke:before {
  content: "\F74B";
}
.fa-firefox:before {
  content: "\F269";
}
.fa-firefox-browser:before {
  content: "\F907";
}
.fa-fireplace:before {
  content: "\F79A";
}
.fa-first-aid:before {
  content: "\F479";
}
.fa-first-order:before {
  content: "\F2B0";
}
.fa-first-order-alt:before {
  content: "\F50A";
}
.fa-firstdraft:before {
  content: "\F3A1";
}
.fa-fish:before {
  content: "\F578";
}
.fa-fish-cooked:before {
  content: "\F7FE";
}
.fa-fist-raised:before {
  content: "\F6DE";
}
.fa-flag:before {
  content: "\F024";
}
.fa-flag-alt:before {
  content: "\F74C";
}
.fa-flag-checkered:before {
  content: "\F11E";
}
.fa-flag-usa:before {
  content: "\F74D";
}
.fa-flame:before {
  content: "\F6DF";
}
.fa-flashlight:before {
  content: "\F8B8";
}
.fa-flask:before {
  content: "\F0C3";
}
.fa-flask-poison:before {
  content: "\F6E0";
}
.fa-flask-potion:before {
  content: "\F6E1";
}
.fa-flickr:before {
  content: "\F16E";
}
.fa-flipboard:before {
  content: "\F44D";
}
.fa-flower:before {
  content: "\F7FF";
}
.fa-flower-daffodil:before {
  content: "\F800";
}
.fa-flower-tulip:before {
  content: "\F801";
}
.fa-flushed:before {
  content: "\F579";
}
.fa-flute:before {
  content: "\F8B9";
}
.fa-flux-capacitor:before {
  content: "\F8BA";
}
.fa-fly:before {
  content: "\F417";
}
.fa-fog:before {
  content: "\F74E";
}
.fa-folder:before {
  content: "\F07B";
}
.fa-folder-download:before {
  content: "\F953";
}
.fa-folder-minus:before {
  content: "\F65D";
}
.fa-folder-open:before {
  content: "\F07C";
}
.fa-folder-plus:before {
  content: "\F65E";
}
.fa-folder-times:before {
  content: "\F65F";
}
.fa-folder-tree:before {
  content: "\F802";
}
.fa-folder-upload:before {
  content: "\F954";
}
.fa-folders:before {
  content: "\F660";
}
.fa-font:before {
  content: "\F031";
}
.fa-font-awesome:before {
  content: "\F2B4";
}
.fa-font-awesome-alt:before {
  content: "\F35C";
}
.fa-font-awesome-flag:before {
  content: "\F425";
}
.fa-font-awesome-logo-full:before {
  content: "\F4E6";
}
.fa-font-case:before {
  content: "\F866";
}
.fa-fonticons:before {
  content: "\F280";
}
.fa-fonticons-fi:before {
  content: "\F3A2";
}
.fa-football-ball:before {
  content: "\F44E";
}
.fa-football-helmet:before {
  content: "\F44F";
}
.fa-forklift:before {
  content: "\F47A";
}
.fa-fort-awesome:before {
  content: "\F286";
}
.fa-fort-awesome-alt:before {
  content: "\F3A3";
}
.fa-forumbee:before {
  content: "\F211";
}
.fa-forward:before {
  content: "\F04E";
}
.fa-foursquare:before {
  content: "\F180";
}
.fa-fragile:before {
  content: "\F4BB";
}
.fa-free-code-camp:before {
  content: "\F2C5";
}
.fa-freebsd:before {
  content: "\F3A4";
}
.fa-french-fries:before {
  content: "\F803";
}
.fa-frog:before {
  content: "\F52E";
}
.fa-frosty-head:before {
  content: "\F79B";
}
.fa-frown:before {
  content: "\F119";
}
.fa-frown-open:before {
  content: "\F57A";
}
.fa-fulcrum:before {
  content: "\F50B";
}
.fa-function:before {
  content: "\F661";
}
.fa-funnel-dollar:before {
  content: "\F662";
}
.fa-futbol:before {
  content: "\F1E3";
}
.fa-galactic-republic:before {
  content: "\F50C";
}
.fa-galactic-senate:before {
  content: "\F50D";
}
.fa-galaxy:before {
  content: "\F908";
}
.fa-game-board:before {
  content: "\F867";
}
.fa-game-board-alt:before {
  content: "\F868";
}
.fa-game-console-handheld:before {
  content: "\F8BB";
}
.fa-gamepad:before {
  content: "\F11B";
}
.fa-gamepad-alt:before {
  content: "\F8BC";
}
.fa-garage:before {
  content: "\F909";
}
.fa-garage-car:before {
  content: "\F90A";
}
.fa-garage-open:before {
  content: "\F90B";
}
.fa-gas-pump:before {
  content: "\F52F";
}
.fa-gas-pump-slash:before {
  content: "\F5F4";
}
.fa-gavel:before {
  content: "\F0E3";
}
.fa-gem:before {
  content: "\F3A5";
}
.fa-genderless:before {
  content: "\F22D";
}
.fa-get-pocket:before {
  content: "\F265";
}
.fa-gg:before {
  content: "\F260";
}
.fa-gg-circle:before {
  content: "\F261";
}
.fa-ghost:before {
  content: "\F6E2";
}
.fa-gift:before {
  content: "\F06B";
}
.fa-gift-card:before {
  content: "\F663";
}
.fa-gifts:before {
  content: "\F79C";
}
.fa-gingerbread-man:before {
  content: "\F79D";
}
.fa-git:before {
  content: "\F1D3";
}
.fa-git-alt:before {
  content: "\F841";
}
.fa-git-square:before {
  content: "\F1D2";
}
.fa-github:before {
  content: "\F09B";
}
.fa-github-alt:before {
  content: "\F113";
}
.fa-github-square:before {
  content: "\F092";
}
.fa-gitkraken:before {
  content: "\F3A6";
}
.fa-gitlab:before {
  content: "\F296";
}
.fa-gitter:before {
  content: "\F426";
}
.fa-glass:before {
  content: "\F804";
}
.fa-glass-champagne:before {
  content: "\F79E";
}
.fa-glass-cheers:before {
  content: "\F79F";
}
.fa-glass-citrus:before {
  content: "\F869";
}
.fa-glass-martini:before {
  content: "\F000";
}
.fa-glass-martini-alt:before {
  content: "\F57B";
}
.fa-glass-whiskey:before {
  content: "\F7A0";
}
.fa-glass-whiskey-rocks:before {
  content: "\F7A1";
}
.fa-glasses:before {
  content: "\F530";
}
.fa-glasses-alt:before {
  content: "\F5F5";
}
.fa-glide:before {
  content: "\F2A5";
}
.fa-glide-g:before {
  content: "\F2A6";
}
.fa-globe:before {
  content: "\F0AC";
}
.fa-globe-africa:before {
  content: "\F57C";
}
.fa-globe-americas:before {
  content: "\F57D";
}
.fa-globe-asia:before {
  content: "\F57E";
}
.fa-globe-europe:before {
  content: "\F7A2";
}
.fa-globe-snow:before {
  content: "\F7A3";
}
.fa-globe-stand:before {
  content: "\F5F6";
}
.fa-gofore:before {
  content: "\F3A7";
}
.fa-golf-ball:before {
  content: "\F450";
}
.fa-golf-club:before {
  content: "\F451";
}
.fa-goodreads:before {
  content: "\F3A8";
}
.fa-goodreads-g:before {
  content: "\F3A9";
}
.fa-google:before {
  content: "\F1A0";
}
.fa-google-drive:before {
  content: "\F3AA";
}
.fa-google-play:before {
  content: "\F3AB";
}
.fa-google-plus:before {
  content: "\F2B3";
}
.fa-google-plus-g:before {
  content: "\F0D5";
}
.fa-google-plus-square:before {
  content: "\F0D4";
}
.fa-google-wallet:before {
  content: "\F1EE";
}
.fa-gopuram:before {
  content: "\F664";
}
.fa-graduation-cap:before {
  content: "\F19D";
}
.fa-gramophone:before {
  content: "\F8BD";
}
.fa-gratipay:before {
  content: "\F184";
}
.fa-grav:before {
  content: "\F2D6";
}
.fa-greater-than:before {
  content: "\F531";
}
.fa-greater-than-equal:before {
  content: "\F532";
}
.fa-grimace:before {
  content: "\F57F";
}
.fa-grin:before {
  content: "\F580";
}
.fa-grin-alt:before {
  content: "\F581";
}
.fa-grin-beam:before {
  content: "\F582";
}
.fa-grin-beam-sweat:before {
  content: "\F583";
}
.fa-grin-hearts:before {
  content: "\F584";
}
.fa-grin-squint:before {
  content: "\F585";
}
.fa-grin-squint-tears:before {
  content: "\F586";
}
.fa-grin-stars:before {
  content: "\F587";
}
.fa-grin-tears:before {
  content: "\F588";
}
.fa-grin-tongue:before {
  content: "\F589";
}
.fa-grin-tongue-squint:before {
  content: "\F58A";
}
.fa-grin-tongue-wink:before {
  content: "\F58B";
}
.fa-grin-wink:before {
  content: "\F58C";
}
.fa-grip-horizontal:before {
  content: "\F58D";
}
.fa-grip-lines:before {
  content: "\F7A4";
}
.fa-grip-lines-vertical:before {
  content: "\F7A5";
}
.fa-grip-vertical:before {
  content: "\F58E";
}
.fa-gripfire:before {
  content: "\F3AC";
}
.fa-grunt:before {
  content: "\F3AD";
}
.fa-guitar:before {
  content: "\F7A6";
}
.fa-guitar-electric:before {
  content: "\F8BE";
}
.fa-guitars:before {
  content: "\F8BF";
}
.fa-gulp:before {
  content: "\F3AE";
}
.fa-h-square:before {
  content: "\F0FD";
}
.fa-h1:before {
  content: "\F313";
}
.fa-h2:before {
  content: "\F314";
}
.fa-h3:before {
  content: "\F315";
}
.fa-h4:before {
  content: "\F86A";
}
.fa-hacker-news:before {
  content: "\F1D4";
}
.fa-hacker-news-square:before {
  content: "\F3AF";
}
.fa-hackerrank:before {
  content: "\F5F7";
}
.fa-hamburger:before {
  content: "\F805";
}
.fa-hammer:before {
  content: "\F6E3";
}
.fa-hammer-war:before {
  content: "\F6E4";
}
.fa-hamsa:before {
  content: "\F665";
}
.fa-hand-heart:before {
  content: "\F4BC";
}
.fa-hand-holding:before {
  content: "\F4BD";
}
.fa-hand-holding-box:before {
  content: "\F47B";
}
.fa-hand-holding-heart:before {
  content: "\F4BE";
}
.fa-hand-holding-magic:before {
  content: "\F6E5";
}
.fa-hand-holding-medical:before {
  content: "\F95C";
}
.fa-hand-holding-seedling:before {
  content: "\F4BF";
}
.fa-hand-holding-usd:before {
  content: "\F4C0";
}
.fa-hand-holding-water:before {
  content: "\F4C1";
}
.fa-hand-lizard:before {
  content: "\F258";
}
.fa-hand-middle-finger:before {
  content: "\F806";
}
.fa-hand-paper:before {
  content: "\F256";
}
.fa-hand-peace:before {
  content: "\F25B";
}
.fa-hand-point-down:before {
  content: "\F0A7";
}
.fa-hand-point-left:before {
  content: "\F0A5";
}
.fa-hand-point-right:before {
  content: "\F0A4";
}
.fa-hand-point-up:before {
  content: "\F0A6";
}
.fa-hand-pointer:before {
  content: "\F25A";
}
.fa-hand-receiving:before {
  content: "\F47C";
}
.fa-hand-rock:before {
  content: "\F255";
}
.fa-hand-scissors:before {
  content: "\F257";
}
.fa-hand-sparkles:before {
  content: "\F95D";
}
.fa-hand-spock:before {
  content: "\F259";
}
.fa-hands:before {
  content: "\F4C2";
}
.fa-hands-heart:before {
  content: "\F4C3";
}
.fa-hands-helping:before {
  content: "\F4C4";
}
.fa-hands-usd:before {
  content: "\F4C5";
}
.fa-hands-wash:before {
  content: "\F95E";
}
.fa-handshake:before {
  content: "\F2B5";
}
.fa-handshake-alt:before {
  content: "\F4C6";
}
.fa-handshake-alt-slash:before {
  content: "\F95F";
}
.fa-handshake-slash:before {
  content: "\F960";
}
.fa-hanukiah:before {
  content: "\F6E6";
}
.fa-hard-hat:before {
  content: "\F807";
}
.fa-hashtag:before {
  content: "\F292";
}
.fa-hat-chef:before {
  content: "\F86B";
}
.fa-hat-cowboy:before {
  content: "\F8C0";
}
.fa-hat-cowboy-side:before {
  content: "\F8C1";
}
.fa-hat-santa:before {
  content: "\F7A7";
}
.fa-hat-winter:before {
  content: "\F7A8";
}
.fa-hat-witch:before {
  content: "\F6E7";
}
.fa-hat-wizard:before {
  content: "\F6E8";
}
.fa-hdd:before {
  content: "\F0A0";
}
.fa-head-side:before {
  content: "\F6E9";
}
.fa-head-side-brain:before {
  content: "\F808";
}
.fa-head-side-cough:before {
  content: "\F961";
}
.fa-head-side-cough-slash:before {
  content: "\F962";
}
.fa-head-side-headphones:before {
  content: "\F8C2";
}
.fa-head-side-mask:before {
  content: "\F963";
}
.fa-head-side-medical:before {
  content: "\F809";
}
.fa-head-side-virus:before {
  content: "\F964";
}
.fa-head-vr:before {
  content: "\F6EA";
}
.fa-heading:before {
  content: "\F1DC";
}
.fa-headphones:before {
  content: "\F025";
}
.fa-headphones-alt:before {
  content: "\F58F";
}
.fa-headset:before {
  content: "\F590";
}
.fa-heart:before {
  content: "\F004";
}
.fa-heart-broken:before {
  content: "\F7A9";
}
.fa-heart-circle:before {
  content: "\F4C7";
}
.fa-heart-rate:before {
  content: "\F5F8";
}
.fa-heart-square:before {
  content: "\F4C8";
}
.fa-heartbeat:before {
  content: "\F21E";
}
.fa-heat:before {
  content: "\F90C";
}
.fa-helicopter:before {
  content: "\F533";
}
.fa-helmet-battle:before {
  content: "\F6EB";
}
.fa-hexagon:before {
  content: "\F312";
}
.fa-highlighter:before {
  content: "\F591";
}
.fa-hiking:before {
  content: "\F6EC";
}
.fa-hippo:before {
  content: "\F6ED";
}
.fa-hips:before {
  content: "\F452";
}
.fa-hire-a-helper:before {
  content: "\F3B0";
}
.fa-history:before {
  content: "\F1DA";
}
.fa-hockey-mask:before {
  content: "\F6EE";
}
.fa-hockey-puck:before {
  content: "\F453";
}
.fa-hockey-sticks:before {
  content: "\F454";
}
.fa-holly-berry:before {
  content: "\F7AA";
}
.fa-home:before {
  content: "\F015";
}
.fa-home-alt:before {
  content: "\F80A";
}
.fa-home-heart:before {
  content: "\F4C9";
}
.fa-home-lg:before {
  content: "\F80B";
}
.fa-home-lg-alt:before {
  content: "\F80C";
}
.fa-hood-cloak:before {
  content: "\F6EF";
}
.fa-hooli:before {
  content: "\F427";
}
.fa-horizontal-rule:before {
  content: "\F86C";
}
.fa-hornbill:before {
  content: "\F592";
}
.fa-horse:before {
  content: "\F6F0";
}
.fa-horse-head:before {
  content: "\F7AB";
}
.fa-horse-saddle:before {
  content: "\F8C3";
}
.fa-hospital:before {
  content: "\F0F8";
}
.fa-hospital-alt:before {
  content: "\F47D";
}
.fa-hospital-symbol:before {
  content: "\F47E";
}
.fa-hospital-user:before {
  content: "\F80D";
}
.fa-hospitals:before {
  content: "\F80E";
}
.fa-hot-tub:before {
  content: "\F593";
}
.fa-hotdog:before {
  content: "\F80F";
}
.fa-hotel:before {
  content: "\F594";
}
.fa-hotjar:before {
  content: "\F3B1";
}
.fa-hourglass:before {
  content: "\F254";
}
.fa-hourglass-end:before {
  content: "\F253";
}
.fa-hourglass-half:before {
  content: "\F252";
}
.fa-hourglass-start:before {
  content: "\F251";
}
.fa-house:before {
  content: "\F90D";
}
.fa-house-damage:before {
  content: "\F6F1";
}
.fa-house-day:before {
  content: "\F90E";
}
.fa-house-flood:before {
  content: "\F74F";
}
.fa-house-leave:before {
  content: "\F90F";
}
.fa-house-night:before {
  content: "\F910";
}
.fa-house-return:before {
  content: "\F911";
}
.fa-house-signal:before {
  content: "\F912";
}
.fa-house-user:before {
  content: "\F965";
}
.fa-houzz:before {
  content: "\F27C";
}
.fa-hryvnia:before {
  content: "\F6F2";
}
.fa-html5:before {
  content: "\F13B";
}
.fa-hubspot:before {
  content: "\F3B2";
}
.fa-humidity:before {
  content: "\F750";
}
.fa-hurricane:before {
  content: "\F751";
}
.fa-i-cursor:before {
  content: "\F246";
}
.fa-ice-cream:before {
  content: "\F810";
}
.fa-ice-skate:before {
  content: "\F7AC";
}
.fa-icicles:before {
  content: "\F7AD";
}
.fa-icons:before {
  content: "\F86D";
}
.fa-icons-alt:before {
  content: "\F86E";
}
.fa-id-badge:before {
  content: "\F2C1";
}
.fa-id-card:before {
  content: "\F2C2";
}
.fa-id-card-alt:before {
  content: "\F47F";
}
.fa-ideal:before {
  content: "\F913";
}
.fa-igloo:before {
  content: "\F7AE";
}
.fa-image:before {
  content: "\F03E";
}
.fa-image-polaroid:before {
  content: "\F8C4";
}
.fa-images:before {
  content: "\F302";
}
.fa-imdb:before {
  content: "\F2D8";
}
.fa-inbox:before {
  content: "\F01C";
}
.fa-inbox-in:before {
  content: "\F310";
}
.fa-inbox-out:before {
  content: "\F311";
}
.fa-indent:before {
  content: "\F03C";
}
.fa-industry:before {
  content: "\F275";
}
.fa-industry-alt:before {
  content: "\F3B3";
}
.fa-infinity:before {
  content: "\F534";
}
.fa-info:before {
  content: "\F129";
}
.fa-info-circle:before {
  content: "\F05A";
}
.fa-info-square:before {
  content: "\F30F";
}
.fa-inhaler:before {
  content: "\F5F9";
}
.fa-instagram:before {
  content: "\F16D";
}
.fa-instagram-square:before {
  content: "\F955";
}
.fa-integral:before {
  content: "\F667";
}
.fa-intercom:before {
  content: "\F7AF";
}
.fa-internet-explorer:before {
  content: "\F26B";
}
.fa-intersection:before {
  content: "\F668";
}
.fa-inventory:before {
  content: "\F480";
}
.fa-invision:before {
  content: "\F7B0";
}
.fa-ioxhost:before {
  content: "\F208";
}
.fa-island-tropical:before {
  content: "\F811";
}
.fa-italic:before {
  content: "\F033";
}
.fa-itch-io:before {
  content: "\F83A";
}
.fa-itunes:before {
  content: "\F3B4";
}
.fa-itunes-note:before {
  content: "\F3B5";
}
.fa-jack-o-lantern:before {
  content: "\F30E";
}
.fa-java:before {
  content: "\F4E4";
}
.fa-jedi:before {
  content: "\F669";
}
.fa-jedi-order:before {
  content: "\F50E";
}
.fa-jenkins:before {
  content: "\F3B6";
}
.fa-jira:before {
  content: "\F7B1";
}
.fa-joget:before {
  content: "\F3B7";
}
.fa-joint:before {
  content: "\F595";
}
.fa-joomla:before {
  content: "\F1AA";
}
.fa-journal-whills:before {
  content: "\F66A";
}
.fa-joystick:before {
  content: "\F8C5";
}
.fa-js:before {
  content: "\F3B8";
}
.fa-js-square:before {
  content: "\F3B9";
}
.fa-jsfiddle:before {
  content: "\F1CC";
}
.fa-jug:before {
  content: "\F8C6";
}
.fa-kaaba:before {
  content: "\F66B";
}
.fa-kaggle:before {
  content: "\F5FA";
}
.fa-kazoo:before {
  content: "\F8C7";
}
.fa-kerning:before {
  content: "\F86F";
}
.fa-key:before {
  content: "\F084";
}
.fa-key-skeleton:before {
  content: "\F6F3";
}
.fa-keybase:before {
  content: "\F4F5";
}
.fa-keyboard:before {
  content: "\F11C";
}
.fa-keycdn:before {
  content: "\F3BA";
}
.fa-keynote:before {
  content: "\F66C";
}
.fa-khanda:before {
  content: "\F66D";
}
.fa-kickstarter:before {
  content: "\F3BB";
}
.fa-kickstarter-k:before {
  content: "\F3BC";
}
.fa-kidneys:before {
  content: "\F5FB";
}
.fa-kiss:before {
  content: "\F596";
}
.fa-kiss-beam:before {
  content: "\F597";
}
.fa-kiss-wink-heart:before {
  content: "\F598";
}
.fa-kite:before {
  content: "\F6F4";
}
.fa-kiwi-bird:before {
  content: "\F535";
}
.fa-knife-kitchen:before {
  content: "\F6F5";
}
.fa-korvue:before {
  content: "\F42F";
}
.fa-lambda:before {
  content: "\F66E";
}
.fa-lamp:before {
  content: "\F4CA";
}
.fa-lamp-desk:before {
  content: "\F914";
}
.fa-lamp-floor:before {
  content: "\F915";
}
.fa-landmark:before {
  content: "\F66F";
}
.fa-landmark-alt:before {
  content: "\F752";
}
.fa-language:before {
  content: "\F1AB";
}
.fa-laptop:before {
  content: "\F109";
}
.fa-laptop-code:before {
  content: "\F5FC";
}
.fa-laptop-house:before {
  content: "\F966";
}
.fa-laptop-medical:before {
  content: "\F812";
}
.fa-laravel:before {
  content: "\F3BD";
}
.fa-lasso:before {
  content: "\F8C8";
}
.fa-lastfm:before {
  content: "\F202";
}
.fa-lastfm-square:before {
  content: "\F203";
}
.fa-laugh:before {
  content: "\F599";
}
.fa-laugh-beam:before {
  content: "\F59A";
}
.fa-laugh-squint:before {
  content: "\F59B";
}
.fa-laugh-wink:before {
  content: "\F59C";
}
.fa-layer-group:before {
  content: "\F5FD";
}
.fa-layer-minus:before {
  content: "\F5FE";
}
.fa-layer-plus:before {
  content: "\F5FF";
}
.fa-leaf:before {
  content: "\F06C";
}
.fa-leaf-heart:before {
  content: "\F4CB";
}
.fa-leaf-maple:before {
  content: "\F6F6";
}
.fa-leaf-oak:before {
  content: "\F6F7";
}
.fa-leanpub:before {
  content: "\F212";
}
.fa-lemon:before {
  content: "\F094";
}
.fa-less:before {
  content: "\F41D";
}
.fa-less-than:before {
  content: "\F536";
}
.fa-less-than-equal:before {
  content: "\F537";
}
.fa-level-down:before {
  content: "\F149";
}
.fa-level-down-alt:before {
  content: "\F3BE";
}
.fa-level-up:before {
  content: "\F148";
}
.fa-level-up-alt:before {
  content: "\F3BF";
}
.fa-life-ring:before {
  content: "\F1CD";
}
.fa-light-ceiling:before {
  content: "\F916";
}
.fa-light-switch:before {
  content: "\F917";
}
.fa-light-switch-off:before {
  content: "\F918";
}
.fa-light-switch-on:before {
  content: "\F919";
}
.fa-lightbulb:before {
  content: "\F0EB";
}
.fa-lightbulb-dollar:before {
  content: "\F670";
}
.fa-lightbulb-exclamation:before {
  content: "\F671";
}
.fa-lightbulb-on:before {
  content: "\F672";
}
.fa-lightbulb-slash:before {
  content: "\F673";
}
.fa-lights-holiday:before {
  content: "\F7B2";
}
.fa-line:before {
  content: "\F3C0";
}
.fa-line-columns:before {
  content: "\F870";
}
.fa-line-height:before {
  content: "\F871";
}
.fa-link:before {
  content: "\F0C1";
}
.fa-linkedin:before {
  content: "\F08C";
}
.fa-linkedin-in:before {
  content: "\F0E1";
}
.fa-linode:before {
  content: "\F2B8";
}
.fa-linux:before {
  content: "\F17C";
}
.fa-lips:before {
  content: "\F600";
}
.fa-lira-sign:before {
  content: "\F195";
}
.fa-list:before {
  content: "\F03A";
}
.fa-list-alt:before {
  content: "\F022";
}
.fa-list-music:before {
  content: "\F8C9";
}
.fa-list-ol:before {
  content: "\F0CB";
}
.fa-list-ul:before {
  content: "\F0CA";
}
.fa-location:before {
  content: "\F601";
}
.fa-location-arrow:before {
  content: "\F124";
}
.fa-location-circle:before {
  content: "\F602";
}
.fa-location-slash:before {
  content: "\F603";
}
.fa-lock:before {
  content: "\F023";
}
.fa-lock-alt:before {
  content: "\F30D";
}
.fa-lock-open:before {
  content: "\F3C1";
}
.fa-lock-open-alt:before {
  content: "\F3C2";
}
.fa-long-arrow-alt-down:before {
  content: "\F309";
}
.fa-long-arrow-alt-left:before {
  content: "\F30A";
}
.fa-long-arrow-alt-right:before {
  content: "\F30B";
}
.fa-long-arrow-alt-up:before {
  content: "\F30C";
}
.fa-long-arrow-down:before {
  content: "\F175";
}
.fa-long-arrow-left:before {
  content: "\F177";
}
.fa-long-arrow-right:before {
  content: "\F178";
}
.fa-long-arrow-up:before {
  content: "\F176";
}
.fa-loveseat:before {
  content: "\F4CC";
}
.fa-low-vision:before {
  content: "\F2A8";
}
.fa-luchador:before {
  content: "\F455";
}
.fa-luggage-cart:before {
  content: "\F59D";
}
.fa-lungs:before {
  content: "\F604";
}
.fa-lungs-virus:before {
  content: "\F967";
}
.fa-lyft:before {
  content: "\F3C3";
}
.fa-mace:before {
  content: "\F6F8";
}
.fa-magento:before {
  content: "\F3C4";
}
.fa-magic:before {
  content: "\F0D0";
}
.fa-magnet:before {
  content: "\F076";
}
.fa-mail-bulk:before {
  content: "\F674";
}
.fa-mailbox:before {
  content: "\F813";
}
.fa-mailchimp:before {
  content: "\F59E";
}
.fa-male:before {
  content: "\F183";
}
.fa-mandalorian:before {
  content: "\F50F";
}
.fa-mandolin:before {
  content: "\F6F9";
}
.fa-map:before {
  content: "\F279";
}
.fa-map-marked:before {
  content: "\F59F";
}
.fa-map-marked-alt:before {
  content: "\F5A0";
}
.fa-map-marker:before {
  content: "\F041";
}
.fa-map-marker-alt:before {
  content: "\F3C5";
}
.fa-map-marker-alt-slash:before {
  content: "\F605";
}
.fa-map-marker-check:before {
  content: "\F606";
}
.fa-map-marker-edit:before {
  content: "\F607";
}
.fa-map-marker-exclamation:before {
  content: "\F608";
}
.fa-map-marker-minus:before {
  content: "\F609";
}
.fa-map-marker-plus:before {
  content: "\F60A";
}
.fa-map-marker-question:before {
  content: "\F60B";
}
.fa-map-marker-slash:before {
  content: "\F60C";
}
.fa-map-marker-smile:before {
  content: "\F60D";
}
.fa-map-marker-times:before {
  content: "\F60E";
}
.fa-map-pin:before {
  content: "\F276";
}
.fa-map-signs:before {
  content: "\F277";
}
.fa-markdown:before {
  content: "\F60F";
}
.fa-marker:before {
  content: "\F5A1";
}
.fa-mars:before {
  content: "\F222";
}
.fa-mars-double:before {
  content: "\F227";
}
.fa-mars-stroke:before {
  content: "\F229";
}
.fa-mars-stroke-h:before {
  content: "\F22B";
}
.fa-mars-stroke-v:before {
  content: "\F22A";
}
.fa-mask:before {
  content: "\F6FA";
}
.fa-mastodon:before {
  content: "\F4F6";
}
.fa-maxcdn:before {
  content: "\F136";
}
.fa-mdb:before {
  content: "\F8CA";
}
.fa-meat:before {
  content: "\F814";
}
.fa-medal:before {
  content: "\F5A2";
}
.fa-medapps:before {
  content: "\F3C6";
}
.fa-medium:before {
  content: "\F23A";
}
.fa-medium-m:before {
  content: "\F3C7";
}
.fa-medkit:before {
  content: "\F0FA";
}
.fa-medrt:before {
  content: "\F3C8";
}
.fa-meetup:before {
  content: "\F2E0";
}
.fa-megaphone:before {
  content: "\F675";
}
.fa-megaport:before {
  content: "\F5A3";
}
.fa-meh:before {
  content: "\F11A";
}
.fa-meh-blank:before {
  content: "\F5A4";
}
.fa-meh-rolling-eyes:before {
  content: "\F5A5";
}
.fa-memory:before {
  content: "\F538";
}
.fa-mendeley:before {
  content: "\F7B3";
}
.fa-menorah:before {
  content: "\F676";
}
.fa-mercury:before {
  content: "\F223";
}
.fa-meteor:before {
  content: "\F753";
}
.fa-microblog:before {
  content: "\F91A";
}
.fa-microchip:before {
  content: "\F2DB";
}
.fa-microphone:before {
  content: "\F130";
}
.fa-microphone-alt:before {
  content: "\F3C9";
}
.fa-microphone-alt-slash:before {
  content: "\F539";
}
.fa-microphone-slash:before {
  content: "\F131";
}
.fa-microphone-stand:before {
  content: "\F8CB";
}
.fa-microscope:before {
  content: "\F610";
}
.fa-microsoft:before {
  content: "\F3CA";
}
.fa-microwave:before {
  content: "\F91B";
}
.fa-mind-share:before {
  content: "\F677";
}
.fa-minus:before {
  content: "\F068";
}
.fa-minus-circle:before {
  content: "\F056";
}
.fa-minus-hexagon:before {
  content: "\F307";
}
.fa-minus-octagon:before {
  content: "\F308";
}
.fa-minus-square:before {
  content: "\F146";
}
.fa-mistletoe:before {
  content: "\F7B4";
}
.fa-mitten:before {
  content: "\F7B5";
}
.fa-mix:before {
  content: "\F3CB";
}
.fa-mixcloud:before {
  content: "\F289";
}
.fa-mixer:before {
  content: "\F956";
}
.fa-mizuni:before {
  content: "\F3CC";
}
.fa-mobile:before {
  content: "\F10B";
}
.fa-mobile-alt:before {
  content: "\F3CD";
}
.fa-mobile-android:before {
  content: "\F3CE";
}
.fa-mobile-android-alt:before {
  content: "\F3CF";
}
.fa-modx:before {
  content: "\F285";
}
.fa-monero:before {
  content: "\F3D0";
}
.fa-money-bill:before {
  content: "\F0D6";
}
.fa-money-bill-alt:before {
  content: "\F3D1";
}
.fa-money-bill-wave:before {
  content: "\F53A";
}
.fa-money-bill-wave-alt:before {
  content: "\F53B";
}
.fa-money-check:before {
  content: "\F53C";
}
.fa-money-check-alt:before {
  content: "\F53D";
}
.fa-money-check-edit:before {
  content: "\F872";
}
.fa-money-check-edit-alt:before {
  content: "\F873";
}
.fa-monitor-heart-rate:before {
  content: "\F611";
}
.fa-monkey:before {
  content: "\F6FB";
}
.fa-monument:before {
  content: "\F5A6";
}
.fa-moon:before {
  content: "\F186";
}
.fa-moon-cloud:before {
  content: "\F754";
}
.fa-moon-stars:before {
  content: "\F755";
}
.fa-mortar-pestle:before {
  content: "\F5A7";
}
.fa-mosque:before {
  content: "\F678";
}
.fa-motorcycle:before {
  content: "\F21C";
}
.fa-mountain:before {
  content: "\F6FC";
}
.fa-mountains:before {
  content: "\F6FD";
}
.fa-mouse:before {
  content: "\F8CC";
}
.fa-mouse-alt:before {
  content: "\F8CD";
}
.fa-mouse-pointer:before {
  content: "\F245";
}
.fa-mp3-player:before {
  content: "\F8CE";
}
.fa-mug:before {
  content: "\F874";
}
.fa-mug-hot:before {
  content: "\F7B6";
}
.fa-mug-marshmallows:before {
  content: "\F7B7";
}
.fa-mug-tea:before {
  content: "\F875";
}
.fa-music:before {
  content: "\F001";
}
.fa-music-alt:before {
  content: "\F8CF";
}
.fa-music-alt-slash:before {
  content: "\F8D0";
}
.fa-music-slash:before {
  content: "\F8D1";
}
.fa-napster:before {
  content: "\F3D2";
}
.fa-narwhal:before {
  content: "\F6FE";
}
.fa-neos:before {
  content: "\F612";
}
.fa-network-wired:before {
  content: "\F6FF";
}
.fa-neuter:before {
  content: "\F22C";
}
.fa-newspaper:before {
  content: "\F1EA";
}
.fa-nimblr:before {
  content: "\F5A8";
}
.fa-node:before {
  content: "\F419";
}
.fa-node-js:before {
  content: "\F3D3";
}
.fa-not-equal:before {
  content: "\F53E";
}
.fa-notes-medical:before {
  content: "\F481";
}
.fa-npm:before {
  content: "\F3D4";
}
.fa-ns8:before {
  content: "\F3D5";
}
.fa-nutritionix:before {
  content: "\F3D6";
}
.fa-object-group:before {
  content: "\F247";
}
.fa-object-ungroup:before {
  content: "\F248";
}
.fa-octagon:before {
  content: "\F306";
}
.fa-odnoklassniki:before {
  content: "\F263";
}
.fa-odnoklassniki-square:before {
  content: "\F264";
}
.fa-oil-can:before {
  content: "\F613";
}
.fa-oil-temp:before {
  content: "\F614";
}
.fa-old-republic:before {
  content: "\F510";
}
.fa-om:before {
  content: "\F679";
}
.fa-omega:before {
  content: "\F67A";
}
.fa-opencart:before {
  content: "\F23D";
}
.fa-openid:before {
  content: "\F19B";
}
.fa-opera:before {
  content: "\F26A";
}
.fa-optin-monster:before {
  content: "\F23C";
}
.fa-orcid:before {
  content: "\F8D2";
}
.fa-ornament:before {
  content: "\F7B8";
}
.fa-osi:before {
  content: "\F41A";
}
.fa-otter:before {
  content: "\F700";
}
.fa-outdent:before {
  content: "\F03B";
}
.fa-outlet:before {
  content: "\F91C";
}
.fa-oven:before {
  content: "\F91D";
}
.fa-overline:before {
  content: "\F876";
}
.fa-page-break:before {
  content: "\F877";
}
.fa-page4:before {
  content: "\F3D7";
}
.fa-pagelines:before {
  content: "\F18C";
}
.fa-pager:before {
  content: "\F815";
}
.fa-paint-brush:before {
  content: "\F1FC";
}
.fa-paint-brush-alt:before {
  content: "\F5A9";
}
.fa-paint-roller:before {
  content: "\F5AA";
}
.fa-palette:before {
  content: "\F53F";
}
.fa-palfed:before {
  content: "\F3D8";
}
.fa-pallet:before {
  content: "\F482";
}
.fa-pallet-alt:before {
  content: "\F483";
}
.fa-paper-plane:before {
  content: "\F1D8";
}
.fa-paperclip:before {
  content: "\F0C6";
}
.fa-parachute-box:before {
  content: "\F4CD";
}
.fa-paragraph:before {
  content: "\F1DD";
}
.fa-paragraph-rtl:before {
  content: "\F878";
}
.fa-parking:before {
  content: "\F540";
}
.fa-parking-circle:before {
  content: "\F615";
}
.fa-parking-circle-slash:before {
  content: "\F616";
}
.fa-parking-slash:before {
  content: "\F617";
}
.fa-passport:before {
  content: "\F5AB";
}
.fa-pastafarianism:before {
  content: "\F67B";
}
.fa-paste:before {
  content: "\F0EA";
}
.fa-patreon:before {
  content: "\F3D9";
}
.fa-pause:before {
  content: "\F04C";
}
.fa-pause-circle:before {
  content: "\F28B";
}
.fa-paw:before {
  content: "\F1B0";
}
.fa-paw-alt:before {
  content: "\F701";
}
.fa-paw-claws:before {
  content: "\F702";
}
.fa-paypal:before {
  content: "\F1ED";
}
.fa-peace:before {
  content: "\F67C";
}
.fa-pegasus:before {
  content: "\F703";
}
.fa-pen:before {
  content: "\F304";
}
.fa-pen-alt:before {
  content: "\F305";
}
.fa-pen-fancy:before {
  content: "\F5AC";
}
.fa-pen-nib:before {
  content: "\F5AD";
}
.fa-pen-square:before {
  content: "\F14B";
}
.fa-pencil:before {
  content: "\F040";
}
.fa-pencil-alt:before {
  content: "\F303";
}
.fa-pencil-paintbrush:before {
  content: "\F618";
}
.fa-pencil-ruler:before {
  content: "\F5AE";
}
.fa-pennant:before {
  content: "\F456";
}
.fa-penny-arcade:before {
  content: "\F704";
}
.fa-people-arrows:before {
  content: "\F968";
}
.fa-people-carry:before {
  content: "\F4CE";
}
.fa-pepper-hot:before {
  content: "\F816";
}
.fa-percent:before {
  content: "\F295";
}
.fa-percentage:before {
  content: "\F541";
}
.fa-periscope:before {
  content: "\F3DA";
}
.fa-person-booth:before {
  content: "\F756";
}
.fa-person-carry:before {
  content: "\F4CF";
}
.fa-person-dolly:before {
  content: "\F4D0";
}
.fa-person-dolly-empty:before {
  content: "\F4D1";
}
.fa-person-sign:before {
  content: "\F757";
}
.fa-phabricator:before {
  content: "\F3DB";
}
.fa-phoenix-framework:before {
  content: "\F3DC";
}
.fa-phoenix-squadron:before {
  content: "\F511";
}
.fa-phone:before {
  content: "\F095";
}
.fa-phone-alt:before {
  content: "\F879";
}
.fa-phone-laptop:before {
  content: "\F87A";
}
.fa-phone-office:before {
  content: "\F67D";
}
.fa-phone-plus:before {
  content: "\F4D2";
}
.fa-phone-rotary:before {
  content: "\F8D3";
}
.fa-phone-slash:before {
  content: "\F3DD";
}
.fa-phone-square:before {
  content: "\F098";
}
.fa-phone-square-alt:before {
  content: "\F87B";
}
.fa-phone-volume:before {
  content: "\F2A0";
}
.fa-photo-video:before {
  content: "\F87C";
}
.fa-php:before {
  content: "\F457";
}
.fa-pi:before {
  content: "\F67E";
}
.fa-piano:before {
  content: "\F8D4";
}
.fa-piano-keyboard:before {
  content: "\F8D5";
}
.fa-pie:before {
  content: "\F705";
}
.fa-pied-piper:before {
  content: "\F2AE";
}
.fa-pied-piper-alt:before {
  content: "\F1A8";
}
.fa-pied-piper-hat:before {
  content: "\F4E5";
}
.fa-pied-piper-pp:before {
  content: "\F1A7";
}
.fa-pied-piper-square:before {
  content: "\F91E";
}
.fa-pig:before {
  content: "\F706";
}
.fa-piggy-bank:before {
  content: "\F4D3";
}
.fa-pills:before {
  content: "\F484";
}
.fa-pinterest:before {
  content: "\F0D2";
}
.fa-pinterest-p:before {
  content: "\F231";
}
.fa-pinterest-square:before {
  content: "\F0D3";
}
.fa-pizza:before {
  content: "\F817";
}
.fa-pizza-slice:before {
  content: "\F818";
}
.fa-place-of-worship:before {
  content: "\F67F";
}
.fa-plane:before {
  content: "\F072";
}
.fa-plane-alt:before {
  content: "\F3DE";
}
.fa-plane-arrival:before {
  content: "\F5AF";
}
.fa-plane-departure:before {
  content: "\F5B0";
}
.fa-plane-slash:before {
  content: "\F969";
}
.fa-planet-moon:before {
  content: "\F91F";
}
.fa-planet-ringed:before {
  content: "\F920";
}
.fa-play:before {
  content: "\F04B";
}
.fa-play-circle:before {
  content: "\F144";
}
.fa-playstation:before {
  content: "\F3DF";
}
.fa-plug:before {
  content: "\F1E6";
}
.fa-plus:before {
  content: "\F067";
}
.fa-plus-circle:before {
  content: "\F055";
}
.fa-plus-hexagon:before {
  content: "\F300";
}
.fa-plus-octagon:before {
  content: "\F301";
}
.fa-plus-square:before {
  content: "\F0FE";
}
.fa-podcast:before {
  content: "\F2CE";
}
.fa-podium:before {
  content: "\F680";
}
.fa-podium-star:before {
  content: "\F758";
}
.fa-police-box:before {
  content: "\F921";
}
.fa-poll:before {
  content: "\F681";
}
.fa-poll-h:before {
  content: "\F682";
}
.fa-poll-people:before {
  content: "\F759";
}
.fa-poo:before {
  content: "\F2FE";
}
.fa-poo-storm:before {
  content: "\F75A";
}
.fa-poop:before {
  content: "\F619";
}
.fa-popcorn:before {
  content: "\F819";
}
.fa-portal-enter:before {
  content: "\F922";
}
.fa-portal-exit:before {
  content: "\F923";
}
.fa-portrait:before {
  content: "\F3E0";
}
.fa-pound-sign:before {
  content: "\F154";
}
.fa-power-off:before {
  content: "\F011";
}
.fa-pray:before {
  content: "\F683";
}
.fa-praying-hands:before {
  content: "\F684";
}
.fa-prescription:before {
  content: "\F5B1";
}
.fa-prescription-bottle:before {
  content: "\F485";
}
.fa-prescription-bottle-alt:before {
  content: "\F486";
}
.fa-presentation:before {
  content: "\F685";
}
.fa-print:before {
  content: "\F02F";
}
.fa-print-search:before {
  content: "\F81A";
}
.fa-print-slash:before {
  content: "\F686";
}
.fa-procedures:before {
  content: "\F487";
}
.fa-product-hunt:before {
  content: "\F288";
}
.fa-project-diagram:before {
  content: "\F542";
}
.fa-projector:before {
  content: "\F8D6";
}
.fa-pump-medical:before {
  content: "\F96A";
}
.fa-pump-soap:before {
  content: "\F96B";
}
.fa-pumpkin:before {
  content: "\F707";
}
.fa-pushed:before {
  content: "\F3E1";
}
.fa-puzzle-piece:before {
  content: "\F12E";
}
.fa-python:before {
  content: "\F3E2";
}
.fa-qq:before {
  content: "\F1D6";
}
.fa-qrcode:before {
  content: "\F029";
}
.fa-question:before {
  content: "\F128";
}
.fa-question-circle:before {
  content: "\F059";
}
.fa-question-square:before {
  content: "\F2FD";
}
.fa-quidditch:before {
  content: "\F458";
}
.fa-quinscape:before {
  content: "\F459";
}
.fa-quora:before {
  content: "\F2C4";
}
.fa-quote-left:before {
  content: "\F10D";
}
.fa-quote-right:before {
  content: "\F10E";
}
.fa-quran:before {
  content: "\F687";
}
.fa-r-project:before {
  content: "\F4F7";
}
.fa-rabbit:before {
  content: "\F708";
}
.fa-rabbit-fast:before {
  content: "\F709";
}
.fa-racquet:before {
  content: "\F45A";
}
.fa-radar:before {
  content: "\F924";
}
.fa-radiation:before {
  content: "\F7B9";
}
.fa-radiation-alt:before {
  content: "\F7BA";
}
.fa-radio:before {
  content: "\F8D7";
}
.fa-radio-alt:before {
  content: "\F8D8";
}
.fa-rainbow:before {
  content: "\F75B";
}
.fa-raindrops:before {
  content: "\F75C";
}
.fa-ram:before {
  content: "\F70A";
}
.fa-ramp-loading:before {
  content: "\F4D4";
}
.fa-random:before {
  content: "\F074";
}
.fa-raspberry-pi:before {
  content: "\F7BB";
}
.fa-ravelry:before {
  content: "\F2D9";
}
.fa-raygun:before {
  content: "\F925";
}
.fa-react:before {
  content: "\F41B";
}
.fa-reacteurope:before {
  content: "\F75D";
}
.fa-readme:before {
  content: "\F4D5";
}
.fa-rebel:before {
  content: "\F1D0";
}
.fa-receipt:before {
  content: "\F543";
}
.fa-record-vinyl:before {
  content: "\F8D9";
}
.fa-rectangle-landscape:before {
  content: "\F2FA";
}
.fa-rectangle-portrait:before {
  content: "\F2FB";
}
.fa-rectangle-wide:before {
  content: "\F2FC";
}
.fa-recycle:before {
  content: "\F1B8";
}
.fa-red-river:before {
  content: "\F3E3";
}
.fa-reddit:before {
  content: "\F1A1";
}
.fa-reddit-alien:before {
  content: "\F281";
}
.fa-reddit-square:before {
  content: "\F1A2";
}
.fa-redhat:before {
  content: "\F7BC";
}
.fa-redo:before {
  content: "\F01E";
}
.fa-redo-alt:before {
  content: "\F2F9";
}
.fa-refrigerator:before {
  content: "\F926";
}
.fa-registered:before {
  content: "\F25D";
}
.fa-remove-format:before {
  content: "\F87D";
}
.fa-renren:before {
  content: "\F18B";
}
.fa-repeat:before {
  content: "\F363";
}
.fa-repeat-1:before {
  content: "\F365";
}
.fa-repeat-1-alt:before {
  content: "\F366";
}
.fa-repeat-alt:before {
  content: "\F364";
}
.fa-reply:before {
  content: "\F3E5";
}
.fa-reply-all:before {
  content: "\F122";
}
.fa-replyd:before {
  content: "\F3E6";
}
.fa-republican:before {
  content: "\F75E";
}
.fa-researchgate:before {
  content: "\F4F8";
}
.fa-resolving:before {
  content: "\F3E7";
}
.fa-restroom:before {
  content: "\F7BD";
}
.fa-retweet:before {
  content: "\F079";
}
.fa-retweet-alt:before {
  content: "\F361";
}
.fa-rev:before {
  content: "\F5B2";
}
.fa-ribbon:before {
  content: "\F4D6";
}
.fa-ring:before {
  content: "\F70B";
}
.fa-rings-wedding:before {
  content: "\F81B";
}
.fa-road:before {
  content: "\F018";
}
.fa-robot:before {
  content: "\F544";
}
.fa-rocket:before {
  content: "\F135";
}
.fa-rocket-launch:before {
  content: "\F927";
}
.fa-rocketchat:before {
  content: "\F3E8";
}
.fa-rockrms:before {
  content: "\F3E9";
}
.fa-route:before {
  content: "\F4D7";
}
.fa-route-highway:before {
  content: "\F61A";
}
.fa-route-interstate:before {
  content: "\F61B";
}
.fa-router:before {
  content: "\F8DA";
}
.fa-rss:before {
  content: "\F09E";
}
.fa-rss-square:before {
  content: "\F143";
}
.fa-ruble-sign:before {
  content: "\F158";
}
.fa-ruler:before {
  content: "\F545";
}
.fa-ruler-combined:before {
  content: "\F546";
}
.fa-ruler-horizontal:before {
  content: "\F547";
}
.fa-ruler-triangle:before {
  content: "\F61C";
}
.fa-ruler-vertical:before {
  content: "\F548";
}
.fa-running:before {
  content: "\F70C";
}
.fa-rupee-sign:before {
  content: "\F156";
}
.fa-rv:before {
  content: "\F7BE";
}
.fa-sack:before {
  content: "\F81C";
}
.fa-sack-dollar:before {
  content: "\F81D";
}
.fa-sad-cry:before {
  content: "\F5B3";
}
.fa-sad-tear:before {
  content: "\F5B4";
}
.fa-safari:before {
  content: "\F267";
}
.fa-salad:before {
  content: "\F81E";
}
.fa-salesforce:before {
  content: "\F83B";
}
.fa-sandwich:before {
  content: "\F81F";
}
.fa-sass:before {
  content: "\F41E";
}
.fa-satellite:before {
  content: "\F7BF";
}
.fa-satellite-dish:before {
  content: "\F7C0";
}
.fa-sausage:before {
  content: "\F820";
}
.fa-save:before {
  content: "\F0C7";
}
.fa-sax-hot:before {
  content: "\F8DB";
}
.fa-saxophone:before {
  content: "\F8DC";
}
.fa-scalpel:before {
  content: "\F61D";
}
.fa-scalpel-path:before {
  content: "\F61E";
}
.fa-scanner:before {
  content: "\F488";
}
.fa-scanner-image:before {
  content: "\F8F3";
}
.fa-scanner-keyboard:before {
  content: "\F489";
}
.fa-scanner-touchscreen:before {
  content: "\F48A";
}
.fa-scarecrow:before {
  content: "\F70D";
}
.fa-scarf:before {
  content: "\F7C1";
}
.fa-schlix:before {
  content: "\F3EA";
}
.fa-school:before {
  content: "\F549";
}
.fa-screwdriver:before {
  content: "\F54A";
}
.fa-scribd:before {
  content: "\F28A";
}
.fa-scroll:before {
  content: "\F70E";
}
.fa-scroll-old:before {
  content: "\F70F";
}
.fa-scrubber:before {
  content: "\F2F8";
}
.fa-scythe:before {
  content: "\F710";
}
.fa-sd-card:before {
  content: "\F7C2";
}
.fa-search:before {
  content: "\F002";
}
.fa-search-dollar:before {
  content: "\F688";
}
.fa-search-location:before {
  content: "\F689";
}
.fa-search-minus:before {
  content: "\F010";
}
.fa-search-plus:before {
  content: "\F00E";
}
.fa-searchengin:before {
  content: "\F3EB";
}
.fa-seedling:before {
  content: "\F4D8";
}
.fa-sellcast:before {
  content: "\F2DA";
}
.fa-sellsy:before {
  content: "\F213";
}
.fa-send-back:before {
  content: "\F87E";
}
.fa-send-backward:before {
  content: "\F87F";
}
.fa-sensor:before {
  content: "\F928";
}
.fa-sensor-alert:before {
  content: "\F929";
}
.fa-sensor-fire:before {
  content: "\F92A";
}
.fa-sensor-on:before {
  content: "\F92B";
}
.fa-sensor-smoke:before {
  content: "\F92C";
}
.fa-server:before {
  content: "\F233";
}
.fa-servicestack:before {
  content: "\F3EC";
}
.fa-shapes:before {
  content: "\F61F";
}
.fa-share:before {
  content: "\F064";
}
.fa-share-all:before {
  content: "\F367";
}
.fa-share-alt:before {
  content: "\F1E0";
}
.fa-share-alt-square:before {
  content: "\F1E1";
}
.fa-share-square:before {
  content: "\F14D";
}
.fa-sheep:before {
  content: "\F711";
}
.fa-shekel-sign:before {
  content: "\F20B";
}
.fa-shield:before {
  content: "\F132";
}
.fa-shield-alt:before {
  content: "\F3ED";
}
.fa-shield-check:before {
  content: "\F2F7";
}
.fa-shield-cross:before {
  content: "\F712";
}
.fa-shield-virus:before {
  content: "\F96C";
}
.fa-ship:before {
  content: "\F21A";
}
.fa-shipping-fast:before {
  content: "\F48B";
}
.fa-shipping-timed:before {
  content: "\F48C";
}
.fa-shirtsinbulk:before {
  content: "\F214";
}
.fa-shish-kebab:before {
  content: "\F821";
}
.fa-shoe-prints:before {
  content: "\F54B";
}
.fa-shopify:before {
  content: "\F957";
}
.fa-shopping-bag:before {
  content: "\F290";
}
.fa-shopping-basket:before {
  content: "\F291";
}
.fa-shopping-cart:before {
  content: "\F07A";
}
.fa-shopware:before {
  content: "\F5B5";
}
.fa-shovel:before {
  content: "\F713";
}
.fa-shovel-snow:before {
  content: "\F7C3";
}
.fa-shower:before {
  content: "\F2CC";
}
.fa-shredder:before {
  content: "\F68A";
}
.fa-shuttle-van:before {
  content: "\F5B6";
}
.fa-shuttlecock:before {
  content: "\F45B";
}
.fa-sickle:before {
  content: "\F822";
}
.fa-sigma:before {
  content: "\F68B";
}
.fa-sign:before {
  content: "\F4D9";
}
.fa-sign-in:before {
  content: "\F090";
}
.fa-sign-in-alt:before {
  content: "\F2F6";
}
.fa-sign-language:before {
  content: "\F2A7";
}
.fa-sign-out:before {
  content: "\F08B";
}
.fa-sign-out-alt:before {
  content: "\F2F5";
}
.fa-signal:before {
  content: "\F012";
}
.fa-signal-1:before {
  content: "\F68C";
}
.fa-signal-2:before {
  content: "\F68D";
}
.fa-signal-3:before {
  content: "\F68E";
}
.fa-signal-4:before {
  content: "\F68F";
}
.fa-signal-alt:before {
  content: "\F690";
}
.fa-signal-alt-1:before {
  content: "\F691";
}
.fa-signal-alt-2:before {
  content: "\F692";
}
.fa-signal-alt-3:before {
  content: "\F693";
}
.fa-signal-alt-slash:before {
  content: "\F694";
}
.fa-signal-slash:before {
  content: "\F695";
}
.fa-signal-stream:before {
  content: "\F8DD";
}
.fa-signature:before {
  content: "\F5B7";
}
.fa-sim-card:before {
  content: "\F7C4";
}
.fa-simplybuilt:before {
  content: "\F215";
}
.fa-sink:before {
  content: "\F96D";
}
.fa-siren:before {
  content: "\F92D";
}
.fa-siren-on:before {
  content: "\F92E";
}
.fa-sistrix:before {
  content: "\F3EE";
}
.fa-sitemap:before {
  content: "\F0E8";
}
.fa-sith:before {
  content: "\F512";
}
.fa-skating:before {
  content: "\F7C5";
}
.fa-skeleton:before {
  content: "\F620";
}
.fa-sketch:before {
  content: "\F7C6";
}
.fa-ski-jump:before {
  content: "\F7C7";
}
.fa-ski-lift:before {
  content: "\F7C8";
}
.fa-skiing:before {
  content: "\F7C9";
}
.fa-skiing-nordic:before {
  content: "\F7CA";
}
.fa-skull:before {
  content: "\F54C";
}
.fa-skull-cow:before {
  content: "\F8DE";
}
.fa-skull-crossbones:before {
  content: "\F714";
}
.fa-skyatlas:before {
  content: "\F216";
}
.fa-skype:before {
  content: "\F17E";
}
.fa-slack:before {
  content: "\F198";
}
.fa-slack-hash:before {
  content: "\F3EF";
}
.fa-slash:before {
  content: "\F715";
}
.fa-sledding:before {
  content: "\F7CB";
}
.fa-sleigh:before {
  content: "\F7CC";
}
.fa-sliders-h:before {
  content: "\F1DE";
}
.fa-sliders-h-square:before {
  content: "\F3F0";
}
.fa-sliders-v:before {
  content: "\F3F1";
}
.fa-sliders-v-square:before {
  content: "\F3F2";
}
.fa-slideshare:before {
  content: "\F1E7";
}
.fa-smile:before {
  content: "\F118";
}
.fa-smile-beam:before {
  content: "\F5B8";
}
.fa-smile-plus:before {
  content: "\F5B9";
}
.fa-smile-wink:before {
  content: "\F4DA";
}
.fa-smog:before {
  content: "\F75F";
}
.fa-smoke:before {
  content: "\F760";
}
.fa-smoking:before {
  content: "\F48D";
}
.fa-smoking-ban:before {
  content: "\F54D";
}
.fa-sms:before {
  content: "\F7CD";
}
.fa-snake:before {
  content: "\F716";
}
.fa-snapchat:before {
  content: "\F2AB";
}
.fa-snapchat-ghost:before {
  content: "\F2AC";
}
.fa-snapchat-square:before {
  content: "\F2AD";
}
.fa-snooze:before {
  content: "\F880";
}
.fa-snow-blowing:before {
  content: "\F761";
}
.fa-snowboarding:before {
  content: "\F7CE";
}
.fa-snowflake:before {
  content: "\F2DC";
}
.fa-snowflakes:before {
  content: "\F7CF";
}
.fa-snowman:before {
  content: "\F7D0";
}
.fa-snowmobile:before {
  content: "\F7D1";
}
.fa-snowplow:before {
  content: "\F7D2";
}
.fa-soap:before {
  content: "\F96E";
}
.fa-socks:before {
  content: "\F696";
}
.fa-solar-panel:before {
  content: "\F5BA";
}
.fa-solar-system:before {
  content: "\F92F";
}
.fa-sort:before {
  content: "\F0DC";
}
.fa-sort-alpha-down:before {
  content: "\F15D";
}
.fa-sort-alpha-down-alt:before {
  content: "\F881";
}
.fa-sort-alpha-up:before {
  content: "\F15E";
}
.fa-sort-alpha-up-alt:before {
  content: "\F882";
}
.fa-sort-alt:before {
  content: "\F883";
}
.fa-sort-amount-down:before {
  content: "\F160";
}
.fa-sort-amount-down-alt:before {
  content: "\F884";
}
.fa-sort-amount-up:before {
  content: "\F161";
}
.fa-sort-amount-up-alt:before {
  content: "\F885";
}
.fa-sort-circle:before {
  content: "\F930";
}
.fa-sort-circle-down:before {
  content: "\F931";
}
.fa-sort-circle-up:before {
  content: "\F932";
}
.fa-sort-down:before {
  content: "\F0DD";
}
.fa-sort-numeric-down:before {
  content: "\F162";
}
.fa-sort-numeric-down-alt:before {
  content: "\F886";
}
.fa-sort-numeric-up:before {
  content: "\F163";
}
.fa-sort-numeric-up-alt:before {
  content: "\F887";
}
.fa-sort-shapes-down:before {
  content: "\F888";
}
.fa-sort-shapes-down-alt:before {
  content: "\F889";
}
.fa-sort-shapes-up:before {
  content: "\F88A";
}
.fa-sort-shapes-up-alt:before {
  content: "\F88B";
}
.fa-sort-size-down:before {
  content: "\F88C";
}
.fa-sort-size-down-alt:before {
  content: "\F88D";
}
.fa-sort-size-up:before {
  content: "\F88E";
}
.fa-sort-size-up-alt:before {
  content: "\F88F";
}
.fa-sort-up:before {
  content: "\F0DE";
}
.fa-soundcloud:before {
  content: "\F1BE";
}
.fa-soup:before {
  content: "\F823";
}
.fa-sourcetree:before {
  content: "\F7D3";
}
.fa-spa:before {
  content: "\F5BB";
}
.fa-space-shuttle:before {
  content: "\F197";
}
.fa-space-station-moon:before {
  content: "\F933";
}
.fa-space-station-moon-alt:before {
  content: "\F934";
}
.fa-spade:before {
  content: "\F2F4";
}
.fa-sparkles:before {
  content: "\F890";
}
.fa-speakap:before {
  content: "\F3F3";
}
.fa-speaker:before {
  content: "\F8DF";
}
.fa-speaker-deck:before {
  content: "\F83C";
}
.fa-speakers:before {
  content: "\F8E0";
}
.fa-spell-check:before {
  content: "\F891";
}
.fa-spider:before {
  content: "\F717";
}
.fa-spider-black-widow:before {
  content: "\F718";
}
.fa-spider-web:before {
  content: "\F719";
}
.fa-spinner:before {
  content: "\F110";
}
.fa-spinner-third:before {
  content: "\F3F4";
}
.fa-splotch:before {
  content: "\F5BC";
}
.fa-spotify:before {
  content: "\F1BC";
}
.fa-spray-can:before {
  content: "\F5BD";
}
.fa-sprinkler:before {
  content: "\F935";
}
.fa-square:before {
  content: "\F0C8";
}
.fa-square-full:before {
  content: "\F45C";
}
.fa-square-root:before {
  content: "\F697";
}
.fa-square-root-alt:before {
  content: "\F698";
}
.fa-squarespace:before {
  content: "\F5BE";
}
.fa-squirrel:before {
  content: "\F71A";
}
.fa-stack-exchange:before {
  content: "\F18D";
}
.fa-stack-overflow:before {
  content: "\F16C";
}
.fa-stackpath:before {
  content: "\F842";
}
.fa-staff:before {
  content: "\F71B";
}
.fa-stamp:before {
  content: "\F5BF";
}
.fa-star:before {
  content: "\F005";
}
.fa-star-and-crescent:before {
  content: "\F699";
}
.fa-star-christmas:before {
  content: "\F7D4";
}
.fa-star-exclamation:before {
  content: "\F2F3";
}
.fa-star-half:before {
  content: "\F089";
}
.fa-star-half-alt:before {
  content: "\F5C0";
}
.fa-star-of-david:before {
  content: "\F69A";
}
.fa-star-of-life:before {
  content: "\F621";
}
.fa-star-shooting:before {
  content: "\F936";
}
.fa-starfighter:before {
  content: "\F937";
}
.fa-starfighter-alt:before {
  content: "\F938";
}
.fa-stars:before {
  content: "\F762";
}
.fa-starship:before {
  content: "\F939";
}
.fa-starship-freighter:before {
  content: "\F93A";
}
.fa-staylinked:before {
  content: "\F3F5";
}
.fa-steak:before {
  content: "\F824";
}
.fa-steam:before {
  content: "\F1B6";
}
.fa-steam-square:before {
  content: "\F1B7";
}
.fa-steam-symbol:before {
  content: "\F3F6";
}
.fa-steering-wheel:before {
  content: "\F622";
}
.fa-step-backward:before {
  content: "\F048";
}
.fa-step-forward:before {
  content: "\F051";
}
.fa-stethoscope:before {
  content: "\F0F1";
}
.fa-sticker-mule:before {
  content: "\F3F7";
}
.fa-sticky-note:before {
  content: "\F249";
}
.fa-stocking:before {
  content: "\F7D5";
}
.fa-stomach:before {
  content: "\F623";
}
.fa-stop:before {
  content: "\F04D";
}
.fa-stop-circle:before {
  content: "\F28D";
}
.fa-stopwatch:before {
  content: "\F2F2";
}
.fa-stopwatch-20:before {
  content: "\F96F";
}
.fa-store:before {
  content: "\F54E";
}
.fa-store-alt:before {
  content: "\F54F";
}
.fa-store-alt-slash:before {
  content: "\F970";
}
.fa-store-slash:before {
  content: "\F971";
}
.fa-strava:before {
  content: "\F428";
}
.fa-stream:before {
  content: "\F550";
}
.fa-street-view:before {
  content: "\F21D";
}
.fa-stretcher:before {
  content: "\F825";
}
.fa-strikethrough:before {
  content: "\F0CC";
}
.fa-stripe:before {
  content: "\F429";
}
.fa-stripe-s:before {
  content: "\F42A";
}
.fa-stroopwafel:before {
  content: "\F551";
}
.fa-studiovinari:before {
  content: "\F3F8";
}
.fa-stumbleupon:before {
  content: "\F1A4";
}
.fa-stumbleupon-circle:before {
  content: "\F1A3";
}
.fa-subscript:before {
  content: "\F12C";
}
.fa-subway:before {
  content: "\F239";
}
.fa-suitcase:before {
  content: "\F0F2";
}
.fa-suitcase-rolling:before {
  content: "\F5C1";
}
.fa-sun:before {
  content: "\F185";
}
.fa-sun-cloud:before {
  content: "\F763";
}
.fa-sun-dust:before {
  content: "\F764";
}
.fa-sun-haze:before {
  content: "\F765";
}
.fa-sunglasses:before {
  content: "\F892";
}
.fa-sunrise:before {
  content: "\F766";
}
.fa-sunset:before {
  content: "\F767";
}
.fa-superpowers:before {
  content: "\F2DD";
}
.fa-superscript:before {
  content: "\F12B";
}
.fa-supple:before {
  content: "\F3F9";
}
.fa-surprise:before {
  content: "\F5C2";
}
.fa-suse:before {
  content: "\F7D6";
}
.fa-swatchbook:before {
  content: "\F5C3";
}
.fa-swift:before {
  content: "\F8E1";
}
.fa-swimmer:before {
  content: "\F5C4";
}
.fa-swimming-pool:before {
  content: "\F5C5";
}
.fa-sword:before {
  content: "\F71C";
}
.fa-sword-laser:before {
  content: "\F93B";
}
.fa-sword-laser-alt:before {
  content: "\F93C";
}
.fa-swords:before {
  content: "\F71D";
}
.fa-swords-laser:before {
  content: "\F93D";
}
.fa-symfony:before {
  content: "\F83D";
}
.fa-synagogue:before {
  content: "\F69B";
}
.fa-sync:before {
  content: "\F021";
}
.fa-sync-alt:before {
  content: "\F2F1";
}
.fa-syringe:before {
  content: "\F48E";
}
.fa-table:before {
  content: "\F0CE";
}
.fa-table-tennis:before {
  content: "\F45D";
}
.fa-tablet:before {
  content: "\F10A";
}
.fa-tablet-alt:before {
  content: "\F3FA";
}
.fa-tablet-android:before {
  content: "\F3FB";
}
.fa-tablet-android-alt:before {
  content: "\F3FC";
}
.fa-tablet-rugged:before {
  content: "\F48F";
}
.fa-tablets:before {
  content: "\F490";
}
.fa-tachometer:before {
  content: "\F0E4";
}
.fa-tachometer-alt:before {
  content: "\F3FD";
}
.fa-tachometer-alt-average:before {
  content: "\F624";
}
.fa-tachometer-alt-fast:before {
  content: "\F625";
}
.fa-tachometer-alt-fastest:before {
  content: "\F626";
}
.fa-tachometer-alt-slow:before {
  content: "\F627";
}
.fa-tachometer-alt-slowest:before {
  content: "\F628";
}
.fa-tachometer-average:before {
  content: "\F629";
}
.fa-tachometer-fast:before {
  content: "\F62A";
}
.fa-tachometer-fastest:before {
  content: "\F62B";
}
.fa-tachometer-slow:before {
  content: "\F62C";
}
.fa-tachometer-slowest:before {
  content: "\F62D";
}
.fa-taco:before {
  content: "\F826";
}
.fa-tag:before {
  content: "\F02B";
}
.fa-tags:before {
  content: "\F02C";
}
.fa-tally:before {
  content: "\F69C";
}
.fa-tanakh:before {
  content: "\F827";
}
.fa-tape:before {
  content: "\F4DB";
}
.fa-tasks:before {
  content: "\F0AE";
}
.fa-tasks-alt:before {
  content: "\F828";
}
.fa-taxi:before {
  content: "\F1BA";
}
.fa-teamspeak:before {
  content: "\F4F9";
}
.fa-teeth:before {
  content: "\F62E";
}
.fa-teeth-open:before {
  content: "\F62F";
}
.fa-telegram:before {
  content: "\F2C6";
}
.fa-telegram-plane:before {
  content: "\F3FE";
}
.fa-telescope:before {
  content: "\F93E";
}
.fa-temperature-down:before {
  content: "\F93F";
}
.fa-temperature-frigid:before {
  content: "\F768";
}
.fa-temperature-high:before {
  content: "\F769";
}
.fa-temperature-hot:before {
  content: "\F76A";
}
.fa-temperature-low:before {
  content: "\F76B";
}
.fa-temperature-up:before {
  content: "\F940";
}
.fa-tencent-weibo:before {
  content: "\F1D5";
}
.fa-tenge:before {
  content: "\F7D7";
}
.fa-tennis-ball:before {
  content: "\F45E";
}
.fa-terminal:before {
  content: "\F120";
}
.fa-text:before {
  content: "\F893";
}
.fa-text-height:before {
  content: "\F034";
}
.fa-text-size:before {
  content: "\F894";
}
.fa-text-width:before {
  content: "\F035";
}
.fa-th:before {
  content: "\F00A";
}
.fa-th-large:before {
  content: "\F009";
}
.fa-th-list:before {
  content: "\F00B";
}
.fa-the-red-yeti:before {
  content: "\F69D";
}
.fa-theater-masks:before {
  content: "\F630";
}
.fa-themeco:before {
  content: "\F5C6";
}
.fa-themeisle:before {
  content: "\F2B2";
}
.fa-thermometer:before {
  content: "\F491";
}
.fa-thermometer-empty:before {
  content: "\F2CB";
}
.fa-thermometer-full:before {
  content: "\F2C7";
}
.fa-thermometer-half:before {
  content: "\F2C9";
}
.fa-thermometer-quarter:before {
  content: "\F2CA";
}
.fa-thermometer-three-quarters:before {
  content: "\F2C8";
}
.fa-theta:before {
  content: "\F69E";
}
.fa-think-peaks:before {
  content: "\F731";
}
.fa-thumbs-down:before {
  content: "\F165";
}
.fa-thumbs-up:before {
  content: "\F164";
}
.fa-thumbtack:before {
  content: "\F08D";
}
.fa-thunderstorm:before {
  content: "\F76C";
}
.fa-thunderstorm-moon:before {
  content: "\F76D";
}
.fa-thunderstorm-sun:before {
  content: "\F76E";
}
.fa-ticket:before {
  content: "\F145";
}
.fa-ticket-alt:before {
  content: "\F3FF";
}
.fa-tilde:before {
  content: "\F69F";
}
.fa-times:before {
  content: "\F00D";
}
.fa-times-circle:before {
  content: "\F057";
}
.fa-times-hexagon:before {
  content: "\F2EE";
}
.fa-times-octagon:before {
  content: "\F2F0";
}
.fa-times-square:before {
  content: "\F2D3";
}
.fa-tint:before {
  content: "\F043";
}
.fa-tint-slash:before {
  content: "\F5C7";
}
.fa-tire:before {
  content: "\F631";
}
.fa-tire-flat:before {
  content: "\F632";
}
.fa-tire-pressure-warning:before {
  content: "\F633";
}
.fa-tire-rugged:before {
  content: "\F634";
}
.fa-tired:before {
  content: "\F5C8";
}
.fa-toggle-off:before {
  content: "\F204";
}
.fa-toggle-on:before {
  content: "\F205";
}
.fa-toilet:before {
  content: "\F7D8";
}
.fa-toilet-paper:before {
  content: "\F71E";
}
.fa-toilet-paper-alt:before {
  content: "\F71F";
}
.fa-toilet-paper-slash:before {
  content: "\F972";
}
.fa-tombstone:before {
  content: "\F720";
}
.fa-tombstone-alt:before {
  content: "\F721";
}
.fa-toolbox:before {
  content: "\F552";
}
.fa-tools:before {
  content: "\F7D9";
}
.fa-tooth:before {
  content: "\F5C9";
}
.fa-toothbrush:before {
  content: "\F635";
}
.fa-torah:before {
  content: "\F6A0";
}
.fa-torii-gate:before {
  content: "\F6A1";
}
.fa-tornado:before {
  content: "\F76F";
}
.fa-tractor:before {
  content: "\F722";
}
.fa-trade-federation:before {
  content: "\F513";
}
.fa-trademark:before {
  content: "\F25C";
}
.fa-traffic-cone:before {
  content: "\F636";
}
.fa-traffic-light:before {
  content: "\F637";
}
.fa-traffic-light-go:before {
  content: "\F638";
}
.fa-traffic-light-slow:before {
  content: "\F639";
}
.fa-traffic-light-stop:before {
  content: "\F63A";
}
.fa-trailer:before {
  content: "\F941";
}
.fa-train:before {
  content: "\F238";
}
.fa-tram:before {
  content: "\F7DA";
}
.fa-transgender:before {
  content: "\F224";
}
.fa-transgender-alt:before {
  content: "\F225";
}
.fa-transporter:before {
  content: "\F942";
}
.fa-transporter-1:before {
  content: "\F943";
}
.fa-transporter-2:before {
  content: "\F944";
}
.fa-transporter-3:before {
  content: "\F945";
}
.fa-transporter-empty:before {
  content: "\F946";
}
.fa-trash:before {
  content: "\F1F8";
}
.fa-trash-alt:before {
  content: "\F2ED";
}
.fa-trash-restore:before {
  content: "\F829";
}
.fa-trash-restore-alt:before {
  content: "\F82A";
}
.fa-trash-undo:before {
  content: "\F895";
}
.fa-trash-undo-alt:before {
  content: "\F896";
}
.fa-treasure-chest:before {
  content: "\F723";
}
.fa-tree:before {
  content: "\F1BB";
}
.fa-tree-alt:before {
  content: "\F400";
}
.fa-tree-christmas:before {
  content: "\F7DB";
}
.fa-tree-decorated:before {
  content: "\F7DC";
}
.fa-tree-large:before {
  content: "\F7DD";
}
.fa-tree-palm:before {
  content: "\F82B";
}
.fa-trees:before {
  content: "\F724";
}
.fa-trello:before {
  content: "\F181";
}
.fa-triangle:before {
  content: "\F2EC";
}
.fa-triangle-music:before {
  content: "\F8E2";
}
.fa-tripadvisor:before {
  content: "\F262";
}
.fa-trophy:before {
  content: "\F091";
}
.fa-trophy-alt:before {
  content: "\F2EB";
}
.fa-truck:before {
  content: "\F0D1";
}
.fa-truck-container:before {
  content: "\F4DC";
}
.fa-truck-couch:before {
  content: "\F4DD";
}
.fa-truck-loading:before {
  content: "\F4DE";
}
.fa-truck-monster:before {
  content: "\F63B";
}
.fa-truck-moving:before {
  content: "\F4DF";
}
.fa-truck-pickup:before {
  content: "\F63C";
}
.fa-truck-plow:before {
  content: "\F7DE";
}
.fa-truck-ramp:before {
  content: "\F4E0";
}
.fa-trumpet:before {
  content: "\F8E3";
}
.fa-tshirt:before {
  content: "\F553";
}
.fa-tty:before {
  content: "\F1E4";
}
.fa-tumblr:before {
  content: "\F173";
}
.fa-tumblr-square:before {
  content: "\F174";
}
.fa-turkey:before {
  content: "\F725";
}
.fa-turntable:before {
  content: "\F8E4";
}
.fa-turtle:before {
  content: "\F726";
}
.fa-tv:before {
  content: "\F26C";
}
.fa-tv-alt:before {
  content: "\F8E5";
}
.fa-tv-music:before {
  content: "\F8E6";
}
.fa-tv-retro:before {
  content: "\F401";
}
.fa-twitch:before {
  content: "\F1E8";
}
.fa-twitter:before {
  content: "\F099";
}
.fa-twitter-square:before {
  content: "\F081";
}
.fa-typewriter:before {
  content: "\F8E7";
}
.fa-typo3:before {
  content: "\F42B";
}
.fa-uber:before {
  content: "\F402";
}
.fa-ubuntu:before {
  content: "\F7DF";
}
.fa-ufo:before {
  content: "\F947";
}
.fa-ufo-beam:before {
  content: "\F948";
}
.fa-uikit:before {
  content: "\F403";
}
.fa-umbraco:before {
  content: "\F8E8";
}
.fa-umbrella:before {
  content: "\F0E9";
}
.fa-umbrella-beach:before {
  content: "\F5CA";
}
.fa-underline:before {
  content: "\F0CD";
}
.fa-undo:before {
  content: "\F0E2";
}
.fa-undo-alt:before {
  content: "\F2EA";
}
.fa-unicorn:before {
  content: "\F727";
}
.fa-union:before {
  content: "\F6A2";
}
.fa-uniregistry:before {
  content: "\F404";
}
.fa-unity:before {
  content: "\F949";
}
.fa-universal-access:before {
  content: "\F29A";
}
.fa-university:before {
  content: "\F19C";
}
.fa-unlink:before {
  content: "\F127";
}
.fa-unlock:before {
  content: "\F09C";
}
.fa-unlock-alt:before {
  content: "\F13E";
}
.fa-untappd:before {
  content: "\F405";
}
.fa-upload:before {
  content: "\F093";
}
.fa-ups:before {
  content: "\F7E0";
}
.fa-usb:before {
  content: "\F287";
}
.fa-usb-drive:before {
  content: "\F8E9";
}
.fa-usd-circle:before {
  content: "\F2E8";
}
.fa-usd-square:before {
  content: "\F2E9";
}
.fa-user:before {
  content: "\F007";
}
.fa-user-alien:before {
  content: "\F94A";
}
.fa-user-alt:before {
  content: "\F406";
}
.fa-user-alt-slash:before {
  content: "\F4FA";
}
.fa-user-astronaut:before {
  content: "\F4FB";
}
.fa-user-chart:before {
  content: "\F6A3";
}
.fa-user-check:before {
  content: "\F4FC";
}
.fa-user-circle:before {
  content: "\F2BD";
}
.fa-user-clock:before {
  content: "\F4FD";
}
.fa-user-cog:before {
  content: "\F4FE";
}
.fa-user-cowboy:before {
  content: "\F8EA";
}
.fa-user-crown:before {
  content: "\F6A4";
}
.fa-user-edit:before {
  content: "\F4FF";
}
.fa-user-friends:before {
  content: "\F500";
}
.fa-user-graduate:before {
  content: "\F501";
}
.fa-user-hard-hat:before {
  content: "\F82C";
}
.fa-user-headset:before {
  content: "\F82D";
}
.fa-user-injured:before {
  content: "\F728";
}
.fa-user-lock:before {
  content: "\F502";
}
.fa-user-md:before {
  content: "\F0F0";
}
.fa-user-md-chat:before {
  content: "\F82E";
}
.fa-user-minus:before {
  content: "\F503";
}
.fa-user-music:before {
  content: "\F8EB";
}
.fa-user-ninja:before {
  content: "\F504";
}
.fa-user-nurse:before {
  content: "\F82F";
}
.fa-user-plus:before {
  content: "\F234";
}
.fa-user-robot:before {
  content: "\F94B";
}
.fa-user-secret:before {
  content: "\F21B";
}
.fa-user-shield:before {
  content: "\F505";
}
.fa-user-slash:before {
  content: "\F506";
}
.fa-user-tag:before {
  content: "\F507";
}
.fa-user-tie:before {
  content: "\F508";
}
.fa-user-times:before {
  content: "\F235";
}
.fa-user-unlock:before {
  content: "\F958";
}
.fa-user-visor:before {
  content: "\F94C";
}
.fa-users:before {
  content: "\F0C0";
}
.fa-users-class:before {
  content: "\F63D";
}
.fa-users-cog:before {
  content: "\F509";
}
.fa-users-crown:before {
  content: "\F6A5";
}
.fa-users-medical:before {
  content: "\F830";
}
.fa-users-slash:before {
  content: "\F973";
}
.fa-usps:before {
  content: "\F7E1";
}
.fa-ussunnah:before {
  content: "\F407";
}
.fa-utensil-fork:before {
  content: "\F2E3";
}
.fa-utensil-knife:before {
  content: "\F2E4";
}
.fa-utensil-spoon:before {
  content: "\F2E5";
}
.fa-utensils:before {
  content: "\F2E7";
}
.fa-utensils-alt:before {
  content: "\F2E6";
}
.fa-vaadin:before {
  content: "\F408";
}
.fa-vacuum:before {
  content: "\F94D";
}
.fa-vacuum-robot:before {
  content: "\F94E";
}
.fa-value-absolute:before {
  content: "\F6A6";
}
.fa-vector-square:before {
  content: "\F5CB";
}
.fa-venus:before {
  content: "\F221";
}
.fa-venus-double:before {
  content: "\F226";
}
.fa-venus-mars:before {
  content: "\F228";
}
.fa-vhs:before {
  content: "\F8EC";
}
.fa-viacoin:before {
  content: "\F237";
}
.fa-viadeo:before {
  content: "\F2A9";
}
.fa-viadeo-square:before {
  content: "\F2AA";
}
.fa-vial:before {
  content: "\F492";
}
.fa-vials:before {
  content: "\F493";
}
.fa-viber:before {
  content: "\F409";
}
.fa-video:before {
  content: "\F03D";
}
.fa-video-plus:before {
  content: "\F4E1";
}
.fa-video-slash:before {
  content: "\F4E2";
}
.fa-vihara:before {
  content: "\F6A7";
}
.fa-vimeo:before {
  content: "\F40A";
}
.fa-vimeo-square:before {
  content: "\F194";
}
.fa-vimeo-v:before {
  content: "\F27D";
}
.fa-vine:before {
  content: "\F1CA";
}
.fa-violin:before {
  content: "\F8ED";
}
.fa-virus:before {
  content: "\F974";
}
.fa-virus-slash:before {
  content: "\F975";
}
.fa-viruses:before {
  content: "\F976";
}
.fa-vk:before {
  content: "\F189";
}
.fa-vnv:before {
  content: "\F40B";
}
.fa-voicemail:before {
  content: "\F897";
}
.fa-volcano:before {
  content: "\F770";
}
.fa-volleyball-ball:before {
  content: "\F45F";
}
.fa-volume:before {
  content: "\F6A8";
}
.fa-volume-down:before {
  content: "\F027";
}
.fa-volume-mute:before {
  content: "\F6A9";
}
.fa-volume-off:before {
  content: "\F026";
}
.fa-volume-slash:before {
  content: "\F2E2";
}
.fa-volume-up:before {
  content: "\F028";
}
.fa-vote-nay:before {
  content: "\F771";
}
.fa-vote-yea:before {
  content: "\F772";
}
.fa-vr-cardboard:before {
  content: "\F729";
}
.fa-vuejs:before {
  content: "\F41F";
}
.fa-wagon-covered:before {
  content: "\F8EE";
}
.fa-walker:before {
  content: "\F831";
}
.fa-walkie-talkie:before {
  content: "\F8EF";
}
.fa-walking:before {
  content: "\F554";
}
.fa-wallet:before {
  content: "\F555";
}
.fa-wand:before {
  content: "\F72A";
}
.fa-wand-magic:before {
  content: "\F72B";
}
.fa-warehouse:before {
  content: "\F494";
}
.fa-warehouse-alt:before {
  content: "\F495";
}
.fa-washer:before {
  content: "\F898";
}
.fa-watch:before {
  content: "\F2E1";
}
.fa-watch-calculator:before {
  content: "\F8F0";
}
.fa-watch-fitness:before {
  content: "\F63E";
}
.fa-water:before {
  content: "\F773";
}
.fa-water-lower:before {
  content: "\F774";
}
.fa-water-rise:before {
  content: "\F775";
}
.fa-wave-sine:before {
  content: "\F899";
}
.fa-wave-square:before {
  content: "\F83E";
}
.fa-wave-triangle:before {
  content: "\F89A";
}
.fa-waveform:before {
  content: "\F8F1";
}
.fa-waveform-path:before {
  content: "\F8F2";
}
.fa-waze:before {
  content: "\F83F";
}
.fa-webcam:before {
  content: "\F832";
}
.fa-webcam-slash:before {
  content: "\F833";
}
.fa-weebly:before {
  content: "\F5CC";
}
.fa-weibo:before {
  content: "\F18A";
}
.fa-weight:before {
  content: "\F496";
}
.fa-weight-hanging:before {
  content: "\F5CD";
}
.fa-weixin:before {
  content: "\F1D7";
}
.fa-whale:before {
  content: "\F72C";
}
.fa-whatsapp:before {
  content: "\F232";
}
.fa-whatsapp-square:before {
  content: "\F40C";
}
.fa-wheat:before {
  content: "\F72D";
}
.fa-wheelchair:before {
  content: "\F193";
}
.fa-whistle:before {
  content: "\F460";
}
.fa-whmcs:before {
  content: "\F40D";
}
.fa-wifi:before {
  content: "\F1EB";
}
.fa-wifi-1:before {
  content: "\F6AA";
}
.fa-wifi-2:before {
  content: "\F6AB";
}
.fa-wifi-slash:before {
  content: "\F6AC";
}
.fa-wikipedia-w:before {
  content: "\F266";
}
.fa-wind:before {
  content: "\F72E";
}
.fa-wind-turbine:before {
  content: "\F89B";
}
.fa-wind-warning:before {
  content: "\F776";
}
.fa-window:before {
  content: "\F40E";
}
.fa-window-alt:before {
  content: "\F40F";
}
.fa-window-close:before {
  content: "\F410";
}
.fa-window-frame:before {
  content: "\F94F";
}
.fa-window-frame-open:before {
  content: "\F950";
}
.fa-window-maximize:before {
  content: "\F2D0";
}
.fa-window-minimize:before {
  content: "\F2D1";
}
.fa-window-restore:before {
  content: "\F2D2";
}
.fa-windows:before {
  content: "\F17A";
}
.fa-windsock:before {
  content: "\F777";
}
.fa-wine-bottle:before {
  content: "\F72F";
}
.fa-wine-glass:before {
  content: "\F4E3";
}
.fa-wine-glass-alt:before {
  content: "\F5CE";
}
.fa-wix:before {
  content: "\F5CF";
}
.fa-wizards-of-the-coast:before {
  content: "\F730";
}
.fa-wolf-pack-battalion:before {
  content: "\F514";
}
.fa-won-sign:before {
  content: "\F159";
}
.fa-wordpress:before {
  content: "\F19A";
}
.fa-wordpress-simple:before {
  content: "\F411";
}
.fa-wpbeginner:before {
  content: "\F297";
}
.fa-wpexplorer:before {
  content: "\F2DE";
}
.fa-wpforms:before {
  content: "\F298";
}
.fa-wpressr:before {
  content: "\F3E4";
}
.fa-wreath:before {
  content: "\F7E2";
}
.fa-wrench:before {
  content: "\F0AD";
}
.fa-x-ray:before {
  content: "\F497";
}
.fa-xbox:before {
  content: "\F412";
}
.fa-xing:before {
  content: "\F168";
}
.fa-xing-square:before {
  content: "\F169";
}
.fa-y-combinator:before {
  content: "\F23B";
}
.fa-yahoo:before {
  content: "\F19E";
}
.fa-yammer:before {
  content: "\F840";
}
.fa-yandex:before {
  content: "\F413";
}
.fa-yandex-international:before {
  content: "\F414";
}
.fa-yarn:before {
  content: "\F7E3";
}
.fa-yelp:before {
  content: "\F1E9";
}
.fa-yen-sign:before {
  content: "\F157";
}
.fa-yin-yang:before {
  content: "\F6AD";
}
.fa-yoast:before {
  content: "\F2B1";
}
.fa-youtube:before {
  content: "\F167";
}
.fa-youtube-square:before {
  content: "\F431";
}
.fa-zhihu:before {
  content: "\F63F";
}
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.selectedCauseRegion {
  overflow-y: auto;
  padding: 14px;
}
.customImpactPointImage {
  float: left;
  margin-right: 5px;
  border: 2px solid #eaeaea;
}
.floatRight {
  float: right;
}
.noMarginBottom {
  margin-bottom: 0px;
}
#radarChart {
  width: 100%;
  height: 350px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #535353 !important;
  -webkit-user-select: none;
  user-select: none;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
  color: #535353 !important;
}
.gx-drawer-sidebar-dark .gx-layout-sider-header,
.gx-layout-sider-dark .gx-layout-sider-header {
  background-color: #3357ff;
}
.background_reset {
  background-color: white !important;
  border-color: #d9d9d9 !important;
  color: #545454 !important;
}
.background_reset:hover,
.background_reset:focus,
.background_reset:focus-within,
.background_reset:active {
  color: white !important;
  border-color: red !important;
  background-color: red !important;
}
.background_x {
  background-color: #3357ff !important;
  border-color: #3357ff !important;
}
.background_esg {
  background-color: #3357ff !important;
  border-color: #3357ff !important;
}
.background_veg {
  background-color: #01a901 !important;
  border-color: #01a901 !important;
}
.background_equ {
  background-color: #ff3a21 !important;
  border-color: #ff3a21 !important;
}
.background_pope {
  background-color: #911eb4 !important;
  border-color: #911eb4 !important;
}
.background_psych {
  background-color: #911eb4 !important;
  border-color: #911eb4 !important;
}
.background_sdg {
  background-color: #5b92e5 !important;
  border-color: #5b92e5 !important;
}
.fontGrey {
  color: #545454;
}
.logoText {
  color: white;
  font-size: 1.4rem;
  font-weight: 300;
  white-space: nowrap;
}
.logoText2 {
  font-weight: 100;
}
.buttonDbSwitch {
  font-weight: 100;
  font-size: 1rem;
  color: #545454 !important;
  box-shadow: none;
}
.buttonDbSwitch:focus {
  color: #545454 !important;
  background-color: white !important;
}
.buttonDbSwitch:hover,
.buttonDbSwitch:active {
  color: black !important;
  background-color: white !important;
}
.Sub {
  vertical-align: sub;
}
.logoTextImg {
  margin-left: 0.3em;
}
.logoTextImg img {
  height: 30px;
}
.ant-collapse > .ant-collapse-item:last-child {
  border-radius: 0;
}
.gx-linebar i:hover {
  color: black !important;
}
.ant-modal-confirm-content {
  margin-left: 0 !important;
  text-align: justify;
}
.gx-layout-sider-scrollbar > div {
  margin-bottom: -17px !important;
  -webkit-overflow-scrolling: touch;
}
.ant-slider:hover .ant-slider-track {
  background-color: #e8e8e8;
}
.ant-slider:hover .ant-slider-track:hover {
  background: none;
}
.ant-slider-rail {
  background-color: #e8e8e8;
}
.ant-slider-track {
  background-color: #e8e8e8;
}
.ant-slider-track:hover {
  background: none;
}
.ant-slider-handle {
  margin-top: -11px;
  width: 25px;
  height: 25px;
  background-color: #e8e8e8;
  border-color: grey;
}
.ant-slider-handle:focus {
  background-color: grey;
  border-color: grey;
}
.ant-slider-dot {
  top: -3px !important;
}
.ant-slider-step:hover {
  background: none;
}
.ant-slider-step .ant-slider-dot {
  width: 10px;
  height: 10px;
}
.ant-slider-step .ant-slider-dot-active {
  border-color: #e8e8e8;
}
.sliderTitle {
  display: flex;
}
.infoIcon {
  margin-left: auto;
}
.infoIcon:hover {
  cursor: pointer;
  color: #038fdd;
}
.ant-modal-confirm-body {
  text-align: center;
}
.ant-modal-confirm-body img {
  width: 50px;
  height: 50px;
}
.ant-modal-confirm-body img.company-logo {
  height: 16px;
  width: auto;
  display: inline !important;
}
.ant-modal-confirm-body img.side-by-side {
  width: 50%;
  height: auto;
  display: inline !important;
}
.ant-modal-confirm-body img.float-left {
  width: 60%;
  height: auto;
  float: left;
  display: inline !important;
}
.ant-modal-confirm-body img.float-right {
  width: 60%;
  height: auto;
  float: right;
  display: inline !important;
}
.ant-modal-confirm-content {
  margin-left: 0;
}
.ant-modal-bocdy img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.partnerLogosOriginal {
  cursor: pointer;
}
.partnerLogosOriginal img {
  margin-right: 0.8rem;
  border-radius: 4px;
}
.partnerLogosOriginal img:last-child {
  margin-right: 0.8rem;
}
.partnerLogoContainer {
  text-align: center;
  vertical-align: middle;
  color: white;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  border-radius: 4px;
}
.partnerLogoContainer img {
  border-radius: 4px;
  width: 28px;
  height: auto;
}
.confirmModalBody {
  text-align: center;
}
.confirmModal-perf {
  text-align: center;
  border-radius: 4px;
  border: 1px solid grey;
  margin: 10px 20%;
  padding-top: 5px;
  padding-bottom: 5px;
}
.confirmModal-perf:hover {
  cursor: pointer;
  color: white;
  background-color: rgba(51, 87, 255, 0.8);
}
.confirmModal-respInv:hover {
  cursor: pointer;
  color: white;
  background-color: rgba(51, 87, 255, 0.8);
}
.noBorder {
  border: none;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  color: #000;
  background-color: rgba(240, 240, 240, 0.7);
  text-align: center;
  border-radius: 6px;
  border: 2px solid #f0f0f0;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #f0f0f0;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.createLogoImage {
  text-align: center;
  vertical-align: middle;
  color: white;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  border-radius: 4px;
  height: 28px;
  min-width: 28px;
  line-height: 28px;
}
.createLogoImage img {
  width: 28px;
  height: auto;
}
.titleSubsectionContainer0 {
  width: 100%;
  display: flex;
  float: left;
  justify-content: center;
}
.titleSubsectionContainer {
  display: flex;
  float: left;
  justify-content: center;
}
.createLogoContainer {
  position: relative;
  text-align: center;
  vertical-align: middle;
  color: white;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  border-radius: 4px;
  height: 28px;
  min-width: 28px;
  line-height: 28px;
}
.createLogoContainer img {
  width: 28px;
  height: auto;
}
.createLogoContainerNoMobile {
  position: relative;
  text-align: center;
  vertical-align: middle;
  color: white;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  border-radius: 4px;
  height: 28px;
  min-width: 28px;
  line-height: 28px;
}
.createLogoContainerNoMobile img {
  width: 28px;
  height: auto;
}
.createLogoText {
  font-size: 14px;
}
.partnerLogos {
  cursor: pointer;
  margin: auto !important;
}
.invertImg {
  -webkit-filter: invert(1);
  filter: invert(1);
}
.bCorpImg {
  height: 28px !important;
  width: auto !important;
}
.createLogoTextOnImage {
  font-size: 12px;
  position: absolute;
  min-width: 28px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  white-space: nowrap;
}
.createLogoTextOnImage img {
  height: auto;
  width: 20px;
}
.centerThis {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.versionFont {
  font-size: 12px;
  color: #ebedef;
  text-align: center;
}
.sidebarLinksDiv {
  text-align: center;
}
.sidebarLinks {
  font-size: 12px;
  text-decoration: underline;
}
.logoImage40 {
  width: 40px;
  height: 40px;
  min-width: 40px;
  line-height: 40px;
}
.logoImage40 img {
  width: 40px;
  height: auto;
  padding: 0px 1px 2px 2px;
}
.modalIframe iframe {
  display: block;
  margin: auto;
}
.insideModalDiv {
  padding: 10px 5%;
}
.insideModalDiv a {
  color: lightgray;
}
.insideModalDivOutline {
  padding: 20px 10%;
  border: 2px solid #eaeaea;
  border-radius: 4px;
  font-size: 1.1rem;
  text-align: center;
}
.insideModalEmphasis {
  text-align: center;
  font-size: 1.4rem;
}
.headerModals {
  margin-top: 0.5rem;
}
.displayNone {
  display: none;
}
.BarChartTooltip {
  font-weight: 500;
  color: black;
  text-align: right;
  text-shadow: white 0px 0px 4px;
}
.BarChartTooltip p {
  line-height: 0.5;
}
.recharts-default-legend {
  margin-left: 50px !important;
}
.barChartWrapper {
  width: 100%;
}
.barChartWrapper .firstChart {
  margin-left: -1rem;
  padding-left: 0.6rem;
}
.barChartWrapper .recharts-surface {
  margin-left: -1rem;
}
.radarChartImpact {
  margin-top: -1.5rem;
}
@media screen and (max-width: 600px) {
  .gx-header-notifications {
    margin-right: -0.5rem !important;
  }
  .gx-mr-3 {
    margin-right: 0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .createLogoText {
    font-size: 12px;
  }
  .partnerLogoContainer {
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    border-radius: 4px;
  }
  .partnerLogoContainer img {
    border-radius: 4px;
    width: 20px;
  }
  .createLogoContainer {
    height: 20px;
    min-width: 20px;
    line-height: 20px;
  }
  .createLogoContainer img {
    width: 20px;
  }
  .bCorpImg {
    height: 20px !important;
  }
  .confirmModal-perf {
    margin: 6px 5%;
  }
  .insideModalEmphasis {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 320px) {
  .createLogoText {
    font-size: 10px;
  }
  .partnerLogoContainer {
    margin-right: 0.05rem;
    margin-left: 0.05rem;
    border-radius: 3px;
  }
  .partnerLogoContainer img {
    border-radius: 3px;
    width: 18px;
  }
  .createLogoContainer {
    height: 18px;
    min-width: 18px;
    line-height: 18px;
  }
  .createLogoContainer img {
    width: 18px;
  }
  .bCorpImg {
    height: 18px !important;
  }
}
.chartTile {
  font-size: 18px;
  text-align: center;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: "tnum", "tnum", "tnum";
          font-feature-settings: "tnum", "tnum", "tnum";
  line-height: 1.3;
  color: #545454;
}
.extraMarginTop {
  margin-top: 3em;
}
.extraPaddingTop {
  padding-top: 3em;
}
.extraPaddingBottom {
  padding-bottom: 3em;
}
div span.superSM {
  vertical-align: super;
  font-size: 0.5em;
  line-height: 1em;
}
.chartSubIncluded {
  line-height: 1.1;
}
.chartSubIncluded button {
  width: 100%;
}
.chartSubtitle {
  font-size: 12px;
}
.addMoreChoices {
  font-size: 12px;
  text-align: center;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: "tnum", "tnum", "tnum";
          font-feature-settings: "tnum", "tnum", "tnum";
  line-height: 1.3;
  color: #545454;
}
.addMoreChoices:hover {
  cursor: pointer;
  color: #038fdd;
}
.fullWidth {
  width: 100%;
  height: 100%;
}
.sidebarBackgroundColor {
  background-color: rgba(255, 255, 254, 0.5);
}
.hideThisDiv {
  display: none;
}
.otherCategories {
  white-space: nowrap;
}
.optionSubmitButton {
  margin: 0 !important;
  padding: 0 5px;
}
.optionSubmitButton:disabled {
  background-color: transparent !important;
  border: none;
}

